import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../components/common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import "../ManageTime/ManageTime.scss";
import React, { useState, useEffect, useRef } from "react";
import { buildQueryString, DayViewColDef } from "./DayViewColDef";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { manageTime } from "../../services/ManageTimeApi";
import jsUtils from "../../utils/jsUtils";
import permissionsState from "../../store/ClientMatterRolesPermissions.ts";
import { useSubscribe } from "../../store/ActionStore";
import { dayViewTableSortMap} from "../../config/vars";
import storage from "../../utils/storage";
import { useDispatch } from "react-redux";
import { refreshStoreOnError } from "../../actions/AppAction";
import { ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME, NO_OF_ROWS_IN_GRID, PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME } from "../../config/messages";
import CustomTable from "../common/DataTable/CustomTable";
import { setCheckedBillable, setCheckedNonBillable, setCheckedUnreleased, setDateSelectedDayView, setCheckedStateDayView } from "./../../actions/DayViewAction";
import moment from "moment";
import { HeaderView } from "../../config/literalCodes";
import ManageTimeCard from "../ManageTime/ManageTimeCard";
import DayViewHeader from "../ManageTime/DayViewHeader";
import useSelectedFeatureMenus from "../ManageTime/useSelectedFeatureMenus";
import DraggableColumnSelector from "../common/DraggableColumnSelector/DraggableColumnSelector";
import DropDownMenuV2 from "../common/DropDownMenuV2/DropDownMenuV2";


export default function DayViewTable({editClick,cloneClick,setDeleteData,releaseClick,setDisplayConfirmation,setValidationMessage,setConfirmAction,onBulkEdit,onBulkUpdate,onBulkRelease,onBulkDelete,onBulkTransfer,onCombineClick,onTransferClick,onLoadingcard,onExportClick,refreshCounter=0,columnDef=[],setColumnDef=()=>{},DataFromAPI=[],setDataFromAPI=()=>{},bulkSelectToggle,gridReset,loading,setLoading=()=>{},getFeatureNames=()=>{},getAccessInfo,columnsData,columnsDataBackup,onApplyColumnChanges,setCheckBoxRefreshCounter=()=>{},setCheckAll=()=>{}}) {

  const [searchCriteria, setSearchCriteria] = useState(null);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [DBCurrentDate, setDBCurrentDate] = useState("");
  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);

  const { delegateId, timeKeeperList } = useSelector(({TimeManagementReducer}) => ({ 
    delegateId: TimeManagementReducer.delegateId,
    timeKeeperList: TimeManagementReducer.timeKeeperList
   }));
  const dateSelectedDayView = useSelector(state => state.DayViewReducer.date);
 
  
  const { permissionsOfUser } = useSelector(({AppReducer}) => ({
     permissionsOfUser: AppReducer.fieldnames ,
     wobo: AppReducer.wobo
    }));
  useSubscribe([permissionsState]);

  const [firstCallView,setFirstCallView]=useState(true);

  const { checkedBillable, checkedNonBillable, checkedUnreleased } = useSelector(
    ({ DayViewReducer }) => ({
      checkedBillable: DayViewReducer.checkBillable,
      checkedNonBillable: DayViewReducer.checkNonBillable,
      checkedUnreleased: DayViewReducer.checkUnreleased,
    })
  );

  const [selectedMenus,enableDropDownMenu]=useSelectedFeatureMenus(DataFromAPI,permissionsOfUser,onBulkEdit,onBulkUpdate,onBulkRelease,onBulkDelete,onBulkTransfer,onCombineClick,onExportClick,getAccessInfo,getFeatureNames,timeKeeperList,delegateId)


  const [totalTECount, setTotalTECount] = useState(0);
  const [dayViewTotalTECount, setDayViewTotalTECount] = useState(0);
  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [closeAllCardsRefresh, setCloseAllCardsRefresh] = useState(0);
  const dispatch = useDispatch();
  const[moveScrollToTop , setMoveScrollToTop] = useState(false);
  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");

  const [scrollNoMore, setScrollNoMore] = useState(false);
 // const [DataFromAPIBackup, setDataFromAPIBackup] = useState([]);
  const [activeTimerId] = useState(0);
  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });
  const [showBillableAmount,setShowBillableAmount] = useState(false);
  
  /**
    *  the state is updated with the new filters
    * @param {String} key -  column name
    * @param {String} value - searched text or toggle value
    * @param {Number} index -index of the filter column 
    */
  let setScrollToTop = () => {
    setMoveScrollToTop(true);
  }

  let setFilters = (value, index) => {
    let state = columnDef;
    columnDef[index].filter.val = value;
    setColumnDef([...state]);
  };
  /*
  * removes all the filters from grid and adds default values
  */
  // let resetFilters = () => {
  //   setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
  //   sort.index = -1;
  //   setSort({ index: -1, isAsc: true, propName: "" });
  //   setScrollToTop();
  // };

  const onDateChangeRefresh = (date) => {
    dispatch(setDateSelectedDayView(date));    
  }
  const onCheckBillable = (val) => {
    dispatch(setCheckedBillable(val))
  }
  const onCheckNonBillable = (val) => {
    dispatch(setCheckedNonBillable(val))
  }
  const onCheckUnreleased = (val) => {
    dispatch(setCheckedUnreleased(val))
  }
  const onCheckedState = (billable, nonBillable, unreleased, date) => {
    dispatch(setCheckedStateDayView(billable, nonBillable, unreleased, date))
  }
  /*
  * On component load or change in delegate in work on behalf we call the client matters api to load the grid
  */

  useEffect(() => {
    DayViewColDef[0].column.val = false;
    setCheckBoxRefreshCounter(0);
    setCheckAll(false);

    if (delegateId !== "" && delegateId !== null) {
      setSort({ index: -1, isAsc: true, propName: "" });
      setScrollToTop();
      loadData(true, searchCriteria);
      setScrollToTop();
    }

  }, [delegateId, refreshCounter,gridReset,dateSelectedDayView, checkedBillable, checkedNonBillable, checkedUnreleased]);

  useEffect(()=>{
    let colDef=[...columnDef];
    if(colDef.length){
      colDef[colDef.length-1].column.renderElement = ()=>{
  
        return <div style={{display: "flex", float:"right"}}>
           <DraggableColumnSelector
          columnSelectionData={columnsData}
          columnSelectionDataBackup={columnsDataBackup}
          onApply={onApplyColumnChanges}
          />
          <DropDownMenuV2
              enableDropDown={enableDropDownMenu}
              menuItems={selectedMenus|| [{
                key:"default",
                label: "default",
                icon: "default",
                command: () => {}
            }]}
        />
        </div>}
     setColumnDef(colDef)
    }

},[selectedMenus,columnsData,enableDropDownMenu])

  /*
  * On component unmounting reset the filters on the grid
  */
 
  useEffect(() => {
    const currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    const currentDateFormat = currentDate && moment(`${currentDate.slice(0, 10)}`).format("MM/DD/YYYY");
    setDBCurrentDate(currentDateFormat);
    return () => {
      dispatch(setCheckedStateDayView(true, true, false, new Date(currentDateFormat)));           
    };
  }, []);

  const toast = useRef(null);

  const calculateTotalsDayView=(timeentries,settings)=>{
    let totalAmount=0;
    let totalnonBillableHours=0;
    let totalbillableHours=0;
    let totalunreleased=0;
    let includeInvalidBillableHrs= settings && settings.invalidBillableHrs==="1"?true:false
    timeentries && timeentries.forEach((item)=>{
      if(item.status.toLowerCase()==="invalid" || item.status.toLowerCase()==="ready") 
      {
        totalunreleased+=item.billableDuration;
      }
      if (item.isBillable) {
        if (item.status.toLowerCase() === "invalid") {
          totalbillableHours = includeInvalidBillableHrs ? (totalbillableHours + item.billableDuration) : totalbillableHours;
          totalAmount = includeInvalidBillableHrs ? (item.amount + totalAmount) : totalAmount;
        }
        else {
          totalbillableHours = totalbillableHours + item.billableDuration;
          totalAmount += item.amount;
        }
      }
      else {
        totalnonBillableHours = totalnonBillableHours + item.billableDuration;
      }
    })
    setBillableHours(totalbillableHours);
    setNonBillableHours(totalnonBillableHours);
    setAmount(totalAmount);
    setUnreleasedHours(totalunreleased);
  }

  const filterDataForDayView = (responseList, settings) => {

    let filteredData = []

    if (checkedBillable && checkedNonBillable) {
      if (checkedUnreleased) {
        let selectedData = responseList.filter((item) => (item.status.toLowerCase() === "invalid" || item.status.toLowerCase() === "ready"))
        filteredData = selectedData;
      }
      else {
        filteredData = responseList;
      }
    }
    else if (checkedBillable) {
      let selectedData = responseList.filter((item) => item.isBillable === 1)
      if (checkedUnreleased) {
        filteredData = selectedData.filter((item) => (item.status.toLowerCase() === "invalid" || item.status.toLowerCase() === "ready"))

      }
      else {
        filteredData = selectedData;
      }
    }
    else if (checkedNonBillable) {
      let selectedData = responseList.filter((item) => item.isBillable !== 1)
      if (checkedUnreleased) {
        filteredData = selectedData.filter((item) => (item.status.toLowerCase() === "invalid" || item.status.toLowerCase() === "ready") && item.isBillable !== 1)
      }
      else {
        filteredData = selectedData;
      }
    }
    else {
      filteredData = []
    }

    if (filteredData.length === 0) {
      setNoItemsFoundMessage(true);
      setNoItemsFoundMessageText({
        titleText: jsUtils.getLiteralValue("NO_TIME_ENTRY_RECORDS_FOUND") !== "NO_TIME_ENTRY_RECORDS_FOUND" ? jsUtils.getLiteralValue("NO_TIME_ENTRY_RECORDS_FOUND") : storage.getObject("literals")["NO_TIME_ENTRY_RECORDS_FOUND"],
        buttonText: null,
      });
    }

    setDataFromAPI(JSON.parse(JSON.stringify(filteredData)));
    //setDataFromAPIBackup(JSON.parse(JSON.stringify(filteredData)));


    calculateTotalsDayView(filteredData, settings);

  }

  const getCodeValue=(codeKey,_rowData)=>{
    let value= _rowData.timeEntryDetail?.find((item)=>item.fieldName===codeKey)?.values?.display_value || "";
    return value;
  }

 const manageTimeApiCall=(reset,searchParams,sortName,sortby)=>{

    manageTime
        .get(delegateId, 1 , searchParams, null, null,  0 , "dayview", dateSelectedDayView || null, sortName, sortby,firstCallView?HeaderView.ManageTimeViewApiCallHeaderName:null)
        .then((res) => {
          setLoading(false);
          setLoadingError(false);
          if(res.totalCount >= 0) {
            setDayViewTotalTECount(res.totalCount);
          } else if(res.searchTotalCount >= 0) {           
            if(totalTECount === 0){
              setTotalTECount(res.searchTotalCount);
            }        
          }           
          //res.nextPageOffset ? setPageOffset(res.nextPageOffset) : setPageOffset(0);
          setDataLoaded(true);
          res &&
            res.timeentries &&
            res.timeentries.forEach((item) => {
              item["rowId"] = item.timeEntryId;
              item["clientIdWithName"] =
                item.clientName && item.clientNumber
                  ? item.clientName + " (" + item.clientNumber + ")"
                  : item.clientName
                    ? item.clientName
                    : item.clientNumber
                      ? " (" + item.clientNumber + ")"
                      : " ";

              item["clientIdWithName"] = item["clientIdWithName"] === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"] : item["clientIdWithName"];
              
              // adding subclient part
              // item["subClientNumberWithName"] = 
              //   item.aubClientName && item.subClientNumber
              //   ? item.subClientName + " (" + item.subClientNumber + ")"
              //   : item.subClientName
              //     ? item.subClientName
              //     : item.subClientNumber
              //       ? " (" + item.subClientNumber + ")"
              //       : " ";

              item["matterIdWithDesc"] =
                item.matterName && item.matterNumber
                  ? item.matterName + " (" + item.matterNumber + ")"
                  : item.matterName
                    ? item.matterName
                    : item.matterNumber
                      ? " (" + item.matterNumber + ")"
                      : " ";
              item["matterIdWithDesc"] = item["matterIdWithDesc"] === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"] : item["matterIdWithDesc"];
              item["city"] = getCodeValue("CITY_CODE",item);
              item["rate"] = getCodeValue("RATE_CODE",item);
              item["phase"] = getCodeValue("PHASE_CODE",item);
              item["service"] = getCodeValue("SERVICE_CODE",item);
              item["task"] = getCodeValue("TASK_CODE",item);
              item["makenocharge"] = getCodeValue("MAKE_NO_CHARGE",item);
              item["activity"] = getCodeValue("ACTIVITY_CODE",item);
              item["udf2"] = getCodeValue("UDF2",item);
              item["udf3"] = getCodeValue("UDF3",item);
              item["generaltime"] = item.isGeneral?"Yes":"No";
              reset && setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
              item["isChecked"]=false;
            });
            
          /**
           * if reset is true reset the data in the state with the response data as the api call is for 1st page of list
           * else if reset is false that means the res is from lazy loading and is from 2nd page or higher.So append the 
           * data to existing state
          */
          if (reset) {
              res.settings && res.settings.showBillableAmtDayView === "1" ? setShowBillableAmount(true) : setShowBillableAmount(false);
              filterDataForDayView(res.timeentries, res.settings);
            if (res.timeentries.length === 0) {
              setNoItemsFoundMessage(true);
              setNoItemsFoundMessageText({
                titleText: jsUtils.getLiteralValue("NO_TIME_ENTRY_RECORDS_FOUND") !== "NO_TIME_ENTRY_RECORDS_FOUND" ? jsUtils.getLiteralValue("NO_TIME_ENTRY_RECORDS_FOUND") : storage.getObject("literals")["NO_TIME_ENTRY_RECORDS_FOUND"],
                buttonText: null,
              });
            }
            else {
              if (res.nextPageOffset) {
                if (res.timeentries.length < 20) {
                  setScrollNoMore(true);
                }
                else {
                  setScrollNoMore(false);
                }
              }
              else {
                page = 1
                if (res.timeentries.length < (page * NO_OF_ROWS_IN_GRID)) {
                  setScrollNoMore(true);
                } else {
                  setPageNumber(page + 1);
                  setScrollNoMore(false);
                }
              }
            }
          } 
        })
        .catch((error) => {
          dispatch(refreshStoreOnError({ refresh: new Date() }));
          setLoadingError(error || "Error");
        })
        .finally(() => {
          setDataLoaded(true);
        });
        setFirstCallView(false);
  }

  /**
   * manage time api that loads the grid with created time entries  
   * @param {boolean} reset - true for the first page load and false for 2nd and more onwards
   */
  const loadData = (reset = true, params, x, onreset, sortName = "", sortby = "") => {
    setLoading(true);
    setDataLoaded(true);
    if (reset) {
      setPageNumber(1);
    }

    /**
     * build query string
      */

    let searchParams;
    if (params) {
      searchParams = params;
    } else {
      if (onreset) {
        searchParams = null;
      }
      else if (isSearchClicked) {
        searchParams = searchCriteria;
      }
      else {
        searchParams = buildQueryString(delegateId, sortName, sortby);
      }
    }
    if (searchParams && searchParams.sortName !== "" || searchParams && searchParams.sortby !== "") {
      searchParams.sort = sortName;
      searchParams.sort_type = sortby;
    }

    if(timeKeeperList[0]?.userDelegateId === delegateId){
      const generalTimeRolePermissionLoggedInUser=permissionsOfUser?.find((item) => item.id==="generaltimeentries")?.fields.find((item)=>item.id ==="generaltimentry")?.actionable || false;
      const addTimeFeaturePermissionLoggedInUser= permissionsOfUser?.find((item) => item.id==="managetimegrid")?.fields.find((item)=>item.id ==="edittime")?.display || false;
      const _timekeeper=timeKeeperList[0].timekeeper;
      if( (_timekeeper && addTimeFeaturePermissionLoggedInUser ) || generalTimeRolePermissionLoggedInUser )
      {
        manageTimeApiCall(reset,searchParams,sortName,sortby)
      }
      else
      { 
        setDataLoaded(true);
        setLoading(false);
        setDataFromAPI([]);
        //setDataFromAPIBackup([]);
        setNoItemsFoundMessage(true);
        setNoItemsFoundMessageText({
          titleText: jsUtils.getLiteralValue("NOT_TIMEKEEPER")
        }); 
      }
    }
    else{
      const featureList=getFeatureNames(timeKeeperList,delegateId) || [];
      const generalTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME)!==-1;
      const addManageFeatureTimePermission=featureList.findIndex((item)=>item===PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME)!==-1;
      if(generalTimeFeaturePermission || addManageFeatureTimePermission)
      {
        manageTimeApiCall(reset,searchParams,sortName,sortby)
      }
      else{
        setDataLoaded(true);
        setLoading(false);
        setDataFromAPI([]);
        //setDataFromAPIBackup([]);
        setNoItemsFoundMessage(true);
        setNoItemsFoundMessageText({
          titleText: jsUtils.getLiteralValue("NOT_TIMEKEEPER")
        }); 
      }
    }
  };

  /** 
   *  sorts the columns of the grid individually
   * @param {Array} data - array of objects for grid of time entry
   * @param {Object} localSort - contains the sort object
   */
  const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc ? "asc" : "desc";
      let sortName = dayViewTableSortMap[localSort.propName] || "";
      setSortByField(sortBy);
      setSortNameField(sortName);

      if(sortName !== ""){
        loadData(true, searchCriteria, undefined, false, sortName, sortBy)
      }
    }
  };

  /**
   * manage time api is called with the selected filters as query params
   * @param {String} key - column name
    * @param {String} value - searched text or toggle value
    * @param {Number} index -index of the filter column 
    */

  const onFilterChange = (key, value, index) => {
    setPageNumber(1)
    //setPageOffset(0);
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
    setScrollNoMore(false);
    setFilters(value, index);
    jsUtils.debounce(loadData, 400, true, sortNameField, sortByField);
    setScrollToTop();
  };

  // day view changes start***********************
  const [nonBillableHours, setNonBillableHours] = useState(0);
  const [billableHours, setBillableHours] = useState(0);
  const [amount, setAmount] = useState(0);
  const [unreleasedHours, setUnreleasedHours] = useState(0);
  
  // useEffect(() => {
  //   if (delegateId !== "" && delegateId !== null ) {
  //     setSort({ index: -1, isAsc: true, propName: "" });
  //     setScrollToTop();
  //     loadData(true);
  //   }
  // }, [dateSelectedDayView, checkedBillable, checkedNonBillable, checkedUnreleased])

  return (
    <div>
      <Toast ref={toast} />
      <DayViewHeader
        onDateChange={onDateChangeRefresh}
        nonBillableHours={nonBillableHours}
        billableHours={billableHours}
        amount={amount}
        unreleasedHours={unreleasedHours}
        checkedBillable={checkedBillable}
        checkedNonBillable={checkedNonBillable}
        checkedUnreleased={checkedUnreleased}
        onCheckBillable={onCheckBillable}
        onCheckNonBillable={onCheckNonBillable}
        onCheckUnreleased={onCheckUnreleased}
        onCheckedState={onCheckedState}
        dateSelectedDayView={dateSelectedDayView}
        showBillableAmount={showBillableAmount}
        currentDbDate={DBCurrentDate}
        entriesInfo={`${dayViewTotalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`}
      />

      <div className="datatable-filter-MT">
        <div className="card">
          {delegateId && (
            <CustomTable
              moduleName={"dayview"}
              rows={NO_OF_ROWS_IN_GRID}
              colDef={columnDef}
              parentMethods={{
                editClick,
                cloneClick,
                setDeleteData,
                releaseClick,
                bulkSelectToggle,
                setDisplayConfirmation,
                setValidationMessage,
                setConfirmAction,
                onBulkEdit,
                onBulkUpdate,
                onBulkRelease,
                onBulkDelete,
                onBulkTransfer,
                onCombineClick,
                onTransferClick,
                onExportClick
              }}
              data={DataFromAPI}
              activeTimerId={activeTimerId}
              dataLoaded={dataLoaded}
              isLoading={loading}
              closeAllRefresh={closeAllCardsRefresh}
              loadingCard={false}
              rowExpansionElement={(data) => {
                return (
                  <div>
                    <ManageTimeCard Carditem={data} onLoadingCard={onLoadingcard} />
                  </div>
                );
              }}
              loadingError={loadingError}
              noItemsFoundMessage={noItemsFoundMessage}
              noItemsFoundMessageText={noItemsFoundMessageText}
              onFilterChange={onFilterChange}
              onLazyScroll={() => {
                
              }}
              onSort={(index, isAsc, propName) => {
                setSort({ index, isAsc, propName });
                sortData({ index, isAsc, propName });
              }}
              sort={sort}
              pageNumber={pageNumber}
              scrollNoMore={scrollNoMore}
              moveScrollToTop={moveScrollToTop}
              resetScrollFlag={() => setMoveScrollToTop(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
