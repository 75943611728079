import React, { useEffect, useState } from "react";
import "./CalendarIntegrationInfo.scss";
import Spinner from "../../../../../common/Spinner/Spinner";
import { InputText } from "primereact/inputtext";
import PasswordField from "../../../../../common/PasswordField/PasswordField";
import jsUtils from "../../../../../../utils/jsUtils";
import { setErrorMessage } from "../../../../../../actions/AppAction";
import { useDispatch } from "react-redux";
import { setUnsavedChanges } from "../../../../../../actions/ConfirmationAction";
import { CalendarIntg } from "../../../../../../services/ConfigurationTabApi";
import { setPageHelpLink } from "../../../../../../actions/LandingPageAction";
import { helpPageUrl } from "../../../../../../config";
import { HELP_LINKS } from "../../../../../../config/vars";

export default function CalendarIntegrationInfo( ) {
    const [loader, setLoader] = useState(false);
    const [clientSecret , setClientSecret] = useState("")
    const [objectId , setObjectId] = useState("")
    const [applicationId , setApplicationId] = useState("")
    const [directoryId , setDirectoryId] = useState("");
    const [saveButtonDisable, setSaveButtonDisable] = useState(true)
 
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.SYSTEM_CONFIG_CALENDAR}`))
    }, []);

    useEffect (()=>{
      let changeDetection = (clientSecret !=="" || objectId !== "" || applicationId !== "" || directoryId !== "")
    if (changeDetection){
       dispatch(setUnsavedChanges(true))
       setSaveButtonDisable(false);
    }
    else{
      dispatch(setUnsavedChanges(false));
      setSaveButtonDisable(true);
    }
    },[clientSecret,objectId,applicationId,directoryId])

    const ResetScreen=()=>{
      setClientSecret("") ;
      setObjectId("") ;
      setApplicationId("") ;
      setDirectoryId("")
    }

    const onSave = ()=>{
      setLoader(true);
      let payload = {
        "clientSecret": clientSecret,
        "clientId": applicationId,
        "objectId": objectId,
        "tenantId": directoryId
      };
      CalendarIntg.put(payload)
        .then((res) => {     
          dispatch(setUnsavedChanges(false))
          setLoader(false);
          dispatch(setErrorMessage({ severity: 'success', message: (res && jsUtils.getLiteralValue(res.message)), errorCode:res.responseCode}));
         ResetScreen();
        })
        .catch((err) => {        
          setLoader(false);
         dispatch(setErrorMessage({ severity: 'error', message: (err && jsUtils.getLiteralValue(err.message)), errorCode:err.responseCode }));
        });
    }
    const setClientSecretCode = (updatedValue) =>{
      setClientSecret(updatedValue)
    }
    const checkFieldEntered=()=>{
      if (clientSecret=== "" || objectId=== "" || applicationId=== "" || directoryId === ""){
        dispatch(setErrorMessage({ severity: 'error', message: jsUtils.getLiteralValue("ALL_FOUR_FIELDS_REQUIRED"), errorCode:400}));
      }
      else{
         onSave();
      }
    }
  return (
    <div className="CalendarIntg-screen">
    <div className="loader-class ">{loader ? <Spinner /> : ""}</div>
      {/* <Toast ref={toast} /> */}
      <div className="CalendarInfo-grid">
        <div className="Calendar-info-data">
          <div className="Calendar-info-field">
              <div className="info-column">
              <div className="info-text"> {jsUtils.getLiteralValue("CLIENT_SECRET")}</div> 
              <div className="info-input-field"> 
             <PasswordField
                
                inputPassword={clientSecret}
                onChangePassword={setClientSecretCode}
                placeholder=""
                calendarCustom={true}
                autoComplete="new-password"
              />
            </div>  
             </div>
            <div className="info-column">
              <div  className="info-text">{jsUtils.getLiteralValue("OBJECT_ID")}</div> 
              <div className="info-input-field">    
              <InputText
              data-testid="dataId_2"
              value={objectId}
              onChange={(event) => {setObjectId(event.target.value)}}
              placeholder=""
              autoComplete="new-password"
            />
             </div>
          </div>
          </div>
          <div className="Calendar-info-field">
          <div className="info-column">
              <div  className="info-text"> {jsUtils.getLiteralValue("APPLICATION_ID")}</div> 
              <div className="info-input-field">   
              <InputText
              value={applicationId}
              onChange={(event) => {setApplicationId(event.target.value)}}
              placeholder=""
              autoComplete="new-password"
            />
            </div>
             </div>
            <div className="info-column">
              <div  className="info-text">{jsUtils.getLiteralValue("DIRECTORY_ID")}</div>    
              <div className="info-input-field">
              <InputText
              value={directoryId}
              onChange={(event) => {setDirectoryId(event.target.value)}}
              placeholder=""
              autoComplete="new-password"
            />
            </div>
             
          </div>

          </div>
          </div>
          <div className="btn-pannel">
          {!saveButtonDisable && <button
              className="btn-cancel"
              onClick={
                ()=>{ResetScreen(); dispatch(setUnsavedChanges(false))}
              }
            >
              {jsUtils.getLiteralValue("CANCEL")}
            </button>
            }
            <button
              data-testid="save"
              className="btn-save"
              onClick={() => {
                checkFieldEntered();
              }}
              disabled={saveButtonDisable}
            >
              {jsUtils.getLiteralValue("SAVE")}
            </button>
          </div>
        
      </div>

    </div>
    
  );
}
