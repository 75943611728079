import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./ConfirmationButton.scss";

function ConfirmationButton(props) {
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const dialogFooter = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={(e) => {
          setDisplayConfirmation(false);
          e.stopPropagation();
        }}
        className="p-button-text no-button"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={(e) => {
          props.onYesClick(props.rowData, null, props.searchCriteria);
          setDisplayConfirmation(false);
          e.stopPropagation();
        }}
        className="yes-button"
      />
    </div>
  );
  return (
    <span className="Confirmation-popup">
      <Dialog
        closable={false}
        header="Confirmation"
        visible={displayConfirmation}
        modal
        footer={dialogFooter}
        onHide={() => setDisplayConfirmation(false)}
      >
        <div className="confirmation-content">
          <div>
            <img
              title=""
              alt=""
              className="af-warning"
              src="/images/icon_warning.svg"
            />
          </div>
          <div className="confirmation-msg">{props.message}</div>
        </div>
      </Dialog>
      {/* <img
        alt=""
        onClick={(e) => {
          setDisplayConfirmation(true);
          e.stopPropagation();
        }}
        style={props.style}
        src={props.src}
        title={props.tooltip}
      /> */}
      <i
       onClick={(e) => {
        !props.disableClick && setDisplayConfirmation(true);
        e.stopPropagation();
      }}
      style={props.style}
      title={props.tooltip}
      className={props.className}
      ></i>
    </span>
  );
}

export default ConfirmationButton;
