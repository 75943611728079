import React, { useState, useEffect, useRef } from "react";
import { AuthService, resetCounterFailure } from "../../services/authService";
import { useHistory } from "react-router-dom";
import "./LoginPage.scss";
import { InputText } from "primereact/inputtext";
import Spinner from "../common/Spinner/Spinner";
import validator from "validator";
import PasswordField from "../common/PasswordField/PasswordField";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { setErrorMessage } from "../../actions/AppAction";
import { useDispatch , useSelector} from "react-redux";
import { TOOLTIPS_VALUES } from "../../config/vars";
import storage from "../../utils/storage";
import * as literalCodes from '../../config/literalCodes';
import { resetAuthDetails, setAuthDetails } from "../../actions/AuthAction";
import { ResetReducers } from "../../actions/GlobalAction";
import { setSelectedTimeKeeperFeature } from "../../actions/TimeManagementAction";
import { userApi } from "../../services/userapis";
//import { env_name } from "../../config";

export default (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [login, setLogin] = useState(true);
  const [authenticationCode, setAuthenticationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetUser, setResetUser] = useState(true);
  const [loader, setLoader] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [userAttributes, setUserAttributes] = useState(null);
  const [userNamePool, setUserNamePool] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [MfaEnabled, setMfaEnabled] = useState(false);
  const [MfaCode, setMfaCode] = useState("");
  const [passwordExpiry , setPasswordExpiry] = useState(false);
  const [passwordExpMessage , setPasswordExpMessage] = useState(false);

  const AuthReducer = useSelector((state) => state.AuthReducer);
  const PasswordReminder = useSelector((state) => state.PreferenceReducer.expiryReminder );
  const PasswordReminderMessage = useSelector((state) => state.PreferenceReducer.expiryReminderMessage );
  const enableSendResetCode = useSelector((state) => state.AppReducer.enabled );
  const statusCheckEmail = useSelector((state) => state.AppReducer.success );
  const mfaUser = useSelector((state) => state.AppReducer.isMFA )
  const dispatch = useDispatch();
  const toast = useRef(null);
  let history = useHistory();

   const [resetMfaAuth,setResetMfaAuth]=useState(false);

  const getCode = (key) => {
    return literalCodes.codes[key] ? literalCodes.codes[key] : key
  };

  const onSubmit = (event) => {
    //const d = new Date();
    // only gets called once
    setLoader(true);
    !MfaEnabled && event && event.preventDefault();
    AuthService.authenticate(
      email,
      password,
      async function (Mfa, err, data, cognitoUserMfa, username, userAttributesMfa, requiredAttributes, userNamePoolMfa) {
        
        if (err) {
          if(data){
            onSubmit(event);
          }
          else{
            AuthService.postLogin(email, false);       // postLogin Api call on cognito error
          //Change congnito message for wrong username or password
          let error_message = err.message === "Incorrect username or password." ? "Invalid email or password" : err.message;
          toast.current.show({
            severity: "error",
            detail: getCode(error_message),  // Toast message for MFA authentication success
            life: 5000,
          });
          }
         
        } else {
          if (!Mfa && data) {
           //localStorage.setItem("user", JSON.stringify(cognitoUserMfa));
            localStorage.setItem("username", username);
            localStorage.setItem("token", data.idToken.jwtToken);
            sessionStorage.setItem("token", data.idToken.jwtToken);
            localStorage.setItem("idTokenExp", data.idToken.payload.exp || "");
            localStorage.setItem("accessTokenExp", data.accessToken.payload.exp || "");
            localStorage.setItem("refreshToken", data.refreshToken.token || "");
            localStorage.setItem("accessToken", data.accessToken.jwtToken || "");
            localStorage.setItem("userEmail", username);
            localStorage.setItem("passwordReminderShow" , "0")
            storage.setCookie("AF_UserAuthTokenCookie", data.idToken.jwtToken);
            storage.setCookie("AF_UserAccessTokenCookie", data.accessToken.jwtToken);
            AuthService.postLogin(email, true);  // postLogin Api call on successful login            
            localStorage.setItem("cognito_username",cognitoUserMfa.username);
            localStorage.setItem("cognito_email", email);
            localStorage.removeItem("Logout_cause");

            dispatch(setAuthDetails({ username: cognitoUserMfa.username  }, email));
            setLoader(false);
            window.location.reload();
          }
          else if (Mfa) {
            //  MFA functionality
            setMfaEnabled(true);
            localStorage.setItem("MFAuser" , true)
            setUserNamePool(userNamePoolMfa);
            setCognitoUser(userNamePoolMfa);
            toast.current.show({
              severity: "success",

              detail: getCode("Authentication Code Sent. "),  // Toast message for MFA authentication success

              life: 3000,
            });
          }
          else {
            setIsFirstLogin(true);
            setUserAttributes(userAttributesMfa);
            setUserNamePool(userNamePoolMfa);
            setResetUser(false);
            setLogin(false);

          }

        }

        setLoader(false);
        // window.location.reload();
      }
    ).catch((err) => {
      // console.log("pool api failed: ", err);
      localStorage.setItem("Authenticating User", "Pool API failed "+err);
      // if redirectToReset flag is true, user will be redirected to reset password screen
      if (err && err.redirectToReset) {
        setResetUser(err.redirectToReset);
        setLogin(false);
        setEmail("");
        if(err.message ===  "USER_PASSWORD_EXPIRED"){
          setPasswordExpMessage(true);
        }
      }
      toast.current.show({
        severity: "error",
        detail: getCode(err && err.message),  // Toast message for MFA authentication success
        life: 5000,
      });
      setLoader(false);
    })
  };
  const validateEmail = (e) => {
    let enteredEmail = e.target.value;
    resetCounterFailure();
    setEmail(enteredEmail);

  };
  const checkEmail = (e) => {
    let enteredEmail = e.target.value;
    if(validator.isEmail(enteredEmail)){
    dispatch(AuthService.getCheckEmail(enteredEmail));  
    }
  };
  const updatePassword = (updatedPassword) => {
    resetCounterFailure();
    setPassword(updatedPassword);
  };
  const updateNewPassword = (updatedPassword) => {
    setNewPassword(updatedPassword);
    checkPassword(updatedPassword)
      ? setErrorDisplay(false)
      : setErrorDisplay(true);
  };

  const updateNewMFAPassword = (updatedPassword) => {
    setNewPassword(updatedPassword);
    checkMFAPassword(updatedPassword)
      ? setErrorDisplay(false)
      : setErrorDisplay(true);
  };
  const updateConfirmPassword = (updatedPassword) => {
    setConfirmPassword(updatedPassword);
  };

  const PasswordReminderChange = (_PasswordReminderMessage) => {
    if (_PasswordReminderMessage > 1){
     return `${getCode("YOUR_PASSWORD_WILL_EXPIRE_IN")} ${_PasswordReminderMessage} ${getCode("DAYS")}`
    }
    else if (_PasswordReminderMessage === 1){
      return `${getCode("YOUR_PASSWORD_WILL_EXPIRE_IN")} ${_PasswordReminderMessage} ${getCode("DAY")}`
    }
    else if (_PasswordReminderMessage === 0){
      return `${getCode("YOUR_PASSWORD_WILL_EXPIRE_TODAY")}`
    }
    else if (_PasswordReminderMessage < 0){
      return getCode("YOUR_PASSWORD_HAS_EXPIRED")
    }
  };
  // Password Validation expression
  const checkPassword = (inputtxt) => {
    let a = "(?=.*[a-z])", b = "(?=.*[A-Z])", c = "(?=.*[0-9])", d = ".{8";
    var passw = new RegExp("^" + a + b + c + d + ",}$"); //hot spot fix
    //var passw = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$");
    return inputtxt.match(passw);
  };

  const checkMFAPassword = (inputtxt) => {
    let a = "(?=.*[a-z])", b = "(?=.*[A-Z])", c = "(?=.*[0-9])", d= "(?=.*?[#?!@$%^&*-])" ,e = ".{14";
    var passw = new RegExp("^" + a + b + c + d + e + ",}$"); //hot spot fix
    //var passw = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$");
    return inputtxt.match(passw);
  };

  useEffect(() => {
    document.title = getCode("ATTORNEYFLOW_LOG_IN");
    setEmail("");
    setPassword("");

    
    // if(pendo){pendo.initialize({
    //   visitor: {
    //     id : "" ,// logged-in user ID
    //     clientCode : "" , 
    //     env : env_name , 
    //     device: 'web' 
    //       // email:        // Recommended if using Pendo Feedback, or NPS Email
    //       // full_name:    // Recommended if using Pendo Feedback
    //       // role:         // Optional
    
    //       // You can add any additional visitor level key-values here,
    //       // as long as it's not one of the above reserved names.
    //   },
    
    //   account: {
    //       id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback
    //   //     // name:         // Optional
    //   //     // is_paying:    // Recommended if using Pendo Feedback
    //   //     // monthly_value:// Recommended if using Pendo Feedback
    //   //     // planLevel:    // Optional
    //   //     // planPrice:    // Optional
    //   //     // creationDate: // Optional
    
    //   //     // You can add any additional account level key-values here,
    //   //     // as long as it's not one of the above reserved names.
    //    }
    // });}

  }, []);

  useEffect(() => {
    if(PasswordReminder === true){
   setPasswordExpiry(props.setPasswordExpiry);
   setLogin(props.setLogin);
   setResetUser(props.resetUser)
    }
  }, [PasswordReminder]);

  useEffect(() => {
  if(window.location.href && window.location.href.toString().toLocaleLowerCase().includes("/resetpassword")){
    let url_string = window.location.href.toString()
    let url = new URL(url_string);    
    let _email = url.searchParams.get("email");
    let decodedEmail = Buffer.from(_email, 'base64').toString("ascii");

    setEmail(decodedEmail) ;
    setLogin(false);
    setResetUser(false);
  }

  }, [window.location.href]);


  const onSetStorageToken=(res,email,userNamePool)=>{
    localStorage.setItem("token", res.idToken.jwtToken);
          sessionStorage.setItem("token", res.idToken.jwtToken);     
          localStorage.setItem("idTokenExp", res.idToken.payload.exp || "");
          localStorage.setItem("accessTokenExp", res.accessToken.payload.exp || "");
          localStorage.setItem("refreshToken", res.refreshToken.token || "");
          localStorage.setItem("accessToken", res.accessToken.jwtToken || "");
          localStorage.setItem("userEmail", email);
          localStorage.setItem("passwordReminderShow" , "0");
          storage.setCookie("AF_UserAuthTokenCookie", res.idToken.jwtToken);
          storage.setCookie("AF_UserAccessTokenCookie", res.accessToken.jwtToken);
          localStorage.setItem("cognito_email", email);
          localStorage.setItem("cognito_username",userNamePool.username);
          localStorage.removeItem("Logout_cause");

  }

  const validateMFA = () => {
    setLoader(true);
    AuthService.handleMfaLogin(MfaCode, userNamePool,email,
      async function (res,email,userNamePool, err) {
        if (res) {
            onSetStorageToken(res,email,userNamePool) 
            AuthService.postLogin(email, true);
         //window.location.reload();
            dispatch(setAuthDetails({ username: userNamePool.username  }, email));
            setLoader(false);
      }
        else {
          setLoader(false);
          toast.current.show({
            severity: "error",
            detail: getCode(err.message),  // Toast message for MFA authentication success
            life: 5000,
          });
        }
      })
  }


  const resetPasswordMFAUser = async (userName, userEmail, userNewPassword) => {
    setLoader(true);
    let data = {
      username: userName,
      email: userEmail,
      verification_code: authenticationCode,
      new_password: userNewPassword    
    };
    await userApi
      .updatePassword(data)
      .then((res) => {
        dispatch(setErrorMessage({ severity: 'success', message: (res && getCode(res.message)), errorCode: res.responseCode }));
        setTimeout(() => {
          AuthService.signOut(data.email, localStorage.getItem("refreshToken")).then(() => {
            dispatch(resetAuthDetails());
            dispatch(ResetReducers());
            dispatch(setSelectedTimeKeeperFeature("",""));
            sessionStorage.removeItem("token");
            storage.clearLocalStorge();
            history.push("/");
          });
        }, 4000);

      })
      .catch((err) => {
        dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: err.responseCode }));
      })
      .finally(() => {
        setLoader(false);
      });
  };
  
  const resetPassword = async () => {
    setLoader(true);
    if (!isFirstLogin) {
      await AuthService.resetPassword(email, authenticationCode, confirmPassword)
        .then((res) => {
          setLoader(false);
          setLogin(true);
          history.push("/")
          setResetUser(true);
          dispatch(setErrorMessage({ severity: 'success', message: (res && getCode(res.message)), errorCode: res.responseCode }));
        })
        .catch((err) => {
          setLoader(false);
          dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: (err && err.responseCode) }));

        });
    }
    else {
      await AuthService.handleNewPassword(email, confirmPassword, password).then((res) => {
        setLoader(false);
        setLogin(true);
        setResetUser(true);
        dispatch(setErrorMessage({ severity: 'success', message: (res && getCode(res.message)), errorCode: res.responseCode }));
        
      }).catch((err) => {
        setLoader(false);
        dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: err.responseCode }));
      })
    }
  };
  // function for sending the authentication code on entering e-mail

  const getAuthenticationCode = async () => {
    setLoader(true);
    await AuthService.getAuthenticationCode(email)
      .then((res) => {
        setLoader(false);
        setResetUser(false);
        dispatch(setErrorMessage({ severity: 'success', message: (res && getCode(res.message)), errorCode: res.responseCode }));
      })

      .catch((err) => {
        setLoader(false);
        setResetUser(true);
        dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: (err && err.responseCode) }));
      });
  };
  
  return (
    <div className="login-main ">
      <Toast ref={toast} />
      <div className="loader-class ">{loader ? <Spinner /> : ""}</div>
      {login ? MfaEnabled ? (
        <form autoComplete="off" className="login-box">
          <img
            alt=""
            src="/images/svg/SurePoint_Logo_Trademark.svg"
            className="form-signin-logo"
          />

          <div className="reset-user-text">
            {getCode("ENTER_RECEIVED_AUTHENTICATION_CODE_OR_CLICK_THE_REFRESH_ICON_TO_GET_ONE")}
          </div>

          <span className="p-input-icon-left signin-field">
            <span>
              <img alt="" className="af-dialpad" src="images/svg/dialpad.svg" />
              <InputText
                value={MfaCode}
                onChange={(event) => setMfaCode(event.target.value)}
                placeholder="received authentication code"
                autoComplete="off"
                data-testid="mfaInput"
              />
            </span>
            <span>
              <img
                alt=""
                title={TOOLTIPS_VALUES.RESEND_CODE}
                className="af-resend"
                src="/images/svg/resend-yellow.svg"
                onClick={() => {
                  !loader && onSubmit();
                }}
                data-testid="mfaResendSubmit"
              />
            </span>
          </span>

          <Button
            disabled={MfaCode && MfaCode.length > 0 ? false : true}
            label="Authenticate"
            onClick={(e) => {
              !loader && validateMFA();
              e.preventDefault();
            }}
            className="reset-code-button"
            data-testid="mfaValidate"
          />

          <a
            className="signin-reset-link"
            onClick={() => {
              setLogin(true);
              setMfaEnabled(false);
              setMfaCode(null);
            }}
            data-testid="cancel"
          >
            {getCode("CANCEL")}
          </a>

        </form>
        ) : (
        <form autoComplete="off" onSubmit={onSubmit} className="login-box">
          <img
            alt=""
            src="/images/svg/SurePoint_Logo_Trademark.svg"
            className="form-signin-logo"
          />
          <span className="p-input-icon-left signin-field">
            <img
              alt=""
              className="af-uname"
              src="/images/landing/account_small.svg"
            />
            <InputText
              value={email}
              onChange={(event) => validateEmail(event)}
              placeholder="email"
              autoComplete="new-password"
            />
          </span>
          <span className="p-input-icon-left signin-field">
            <PasswordField
              inputPassword={password}
              onChangePassword={updatePassword}
              placeholder="password"
            />
          </span>
          <Button
            type="submit"
            className="signIn-button"
            disabled={
              email &&
                password &&
                (email.toString().trim() != "" ||
                  password.toString().trim() != "")
                ? false
                : true
            }
          >
            {getCode("LOGIN")}
          </Button>
          <a
            className="signin-reset-link"
            onClick={() => {
              setLogin(false);
              setEmail("");
              setPassword("");
            }}
            data-testid="resetPassword"
          >
            {getCode("RESET_PASSWORD")}
          </a>
          {/* <div className="version-info">
            V14.4 03/02/2021 for AF Sprint 2021 03/02- 03/15
          </div> */}
        </form>
       ) : resetUser ? (
        <form autoComplete="off" className="login-box">
          <img
            alt=""
            src="images/svg/SurePoint_Logo_Trademark.svg"
            className="form-signin-logo"
          />
        {passwordExpMessage ? <div className="password-message">{getCode("YOUR_PASSWORD_HAS_EXPIRED")}</div> :!enableSendResetCode && statusCheckEmail ?<div className="password-message">{getCode("USE_TEMP_PASSWORD_SENT_BY_ADMIN")}</div> : <></>}
        <div className="reset-user-text">
            {getCode("ENTER_YOUR_EMAIL_ADDRESS_TO_RECEIVE_YOUR_AUTHENTICATION_CODE")}
          </div>
          <span className="p-input-icon-left signin-field">
            <img
              alt=""
              className="af-uname"
              src="images/landing/account_small.svg"
            />
            <InputText
              value={email}
              onChange={(event) => {setEmail(event.target.value); checkEmail(event)}}
              placeholder="email"
              autoComplete="new-password"
            />
          </span>

          <Button
            disabled={enableSendResetCode && statusCheckEmail ? false : true}
            label="Send Password Reset Code"
            onClick={(e) => {
              !loader && getAuthenticationCode();
              e.preventDefault();
            }}
            className="reset-code-button"
            data-testid="reset-code-button"
          />

          <a
            className="signin-reset-link"
            onClick={() => {
              setLogin(true);
              setResetUser(true);
              setNewPassword(null);
              setConfirmPassword(null);
              setAuthenticationCode(null);
              setEmail(null);
              setPassword(null);
              window.location.reload();
            }}
            data-testid="cancel"
          >
            {getCode("CANCEL")}
          </a>

          </form>
        ) : passwordExpiry ?
      
          <form  className="login-box">
          <img
            alt=""
            src="images/svg/SurePoint_Logo_Trademark.svg"
            className="form-signin-logo"
          />
          <div className="password-expiry">
            {PasswordReminderChange(PasswordReminderMessage)}
          </div>

          <Button
            className = "signIn-button"
            label= {getCode("CONTINUE")}
            onClick={(e) => {
              !loader && localStorage.setItem("passwordReminderShow" , 1);
               e.stopPropagation();
            }}
            data-testid="continue"
          />

          <a
            className="signin-reset-link"
            onClick={() => {
              AuthService.signOut(
                AuthReducer.email,
                localStorage.getItem("refreshToken")
              ).then(() => {
                dispatch(resetAuthDetails());
                
                dispatch(setSelectedTimeKeeperFeature("",""));
                storage.removeAllCookies();
                sessionStorage.removeItem("token");
                storage.clearLocalStorge();
                history.push("/");
                dispatch(ResetReducers());
                //window.location.reload();
              setPasswordExpiry(false)
              setLogin(false);
              setResetUser(true);
              });
              
             
            }}
            data-testid="resetPassword"
          >
            {getCode("RESET_PASSWORD")}
          </a>

        </form>
      
       : (
        <div className="login-box">
          <img
            alt=""
            src="images/svg/SurePoint_Logo_Trademark.svg"
            className="form-signin-logo"
          />
          {!isFirstLogin &&
            <span className="p-input-icon-left signin-field">
              <span>
                <img alt="" className="af-dialpad" src="images/svg/dialpad.svg" />
                <InputText
                  type="text"
                  value={authenticationCode}
                  onChange={(event) => setAuthenticationCode(event.target.value)}
                  placeholder="received code"
                  autoComplete="new-password"
                />
              </span>
              <span>
                <img
                  alt=""
                  title={TOOLTIPS_VALUES.RESEND_CODE}
                  className="af-resend"
                  src="images/svg/resend.svg"
                  onClick={() => {
                    !loader && getAuthenticationCode();
                  }}
                  data-testid="resendAuthCode"
                />
              </span>
            </span>
          }
          {errorDisplay ? (
            <span className="p-input-icon-left signin-field-error">
              {" "}
              <PasswordField
                inputPassword={newPassword}
                onChangePassword={mfaUser ? updateNewMFAPassword : updateNewPassword}
                placeholder="new password"
              />
            </span>
          ) : (
            <span className="p-input-icon-left signin-field">
              {" "}
              <PasswordField
                inputPassword={newPassword}
                onChangePassword={mfaUser ? updateNewMFAPassword : updateNewPassword}
                placeholder="new password"
              />
            </span>
          )}

          {confirmPassword && (newPassword !== confirmPassword) ? (
            <span className="p-input-icon-left signin-field-error">
              <PasswordField
                inputPassword={confirmPassword}
                onChangePassword={updateConfirmPassword}
                placeholder="confirm new password"
              />
            </span>
          ) : (
            <span className="p-input-icon-left signin-field">
              <PasswordField
                inputPassword={confirmPassword}
                onChangePassword={updateConfirmPassword}
                placeholder="confirm new password"
              />
            </span>
          )}

          { confirmPassword && newPassword !== confirmPassword ? (
            <div className="validation-error">{getCode("NEW_PASSWORD_AND_CONFIRM_NEW_PASSWORD_MUST_BE_THE_SAME")}  </div>
          ) : (
            <></>
          )}
          {errorDisplay ? (
            <div className="validation-error">
              {" "}
              {mfaUser?getCode("PASSWORD_14_CHARACTERS_1_NUMBER_1_UPPERCASE_1_LOWERCASE") :getCode("PASSWORD_8_CHARACTERS_1_NUMBER_1_UPPERCASE_1_LOWERCASE")}
            </div>
          ) : ""}

          <Button
            className="reset-code-button"
            disabled={errorDisplay || (newPassword !== confirmPassword) || !(authenticationCode || isFirstLogin) || newPassword ==="" || confirmPassword ===""}
            onClick={(e) => {
              if(newPassword.includes(" ")){
                dispatch(setErrorMessage({ severity: 'error', message: getCode("PASSWORD_CANNOT_CONTAIN_SPACES"), errorCode: 500 }))
              }
            else{
              !loader && resetPassword();
              e.preventDefault();
            }
            }}
            data-testid="reset-code-button2"
          >
            {getCode("RESET_PASS")}
          </Button>
          <a
            className="signin-reset-link"
            onClick={() => {
              setLogin(true);
              setResetUser(true);
              setNewPassword(null);
              setConfirmPassword(null);
              setAuthenticationCode(null);
              setEmail(null);
              setPassword(null);
              history.push("/")
              window.location.reload();
            }}
            data-testid="cancel2"
          >
            {getCode("CANCEL")}
          </a>

        </div>
      )}
    </div>
  );
};
