import { SET_SESSION_TIMEOUT, SET_PREFERENCE_SETTINGS, SET_PREFERENCE_API_DATA, SET_USER_ME_DATA } from "./ActionType";
export var setSessionTimeout = function setSessionTimeout(userSessionTimeout) {
  return {
    type: SET_SESSION_TIMEOUT,
    data: {
      userSessionTimeout: userSessionTimeout
    }
  };
};
export var setUserPreferenceDetailsAction = function setUserPreferenceDetailsAction(payload) {
  return {
    type: SET_PREFERENCE_SETTINGS,
    data: payload.userData.preference,
    value: payload
  };
};
export var setUserDataDetailsAction = function setUserDataDetailsAction(payload) {
  return {
    type: SET_USER_ME_DATA,
    data: payload.userData.user,
    value: payload
  };
};
export var setPreferenceSettingsAction = function setPreferenceSettingsAction(payload) {
  return {
    type: SET_PREFERENCE_API_DATA,
    data: payload.preferencesData
  };
};