import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import utils from "../../../utils/jsUtils";

import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./RadioButton.scss";
import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Spinner from "../Spinner/Spinner";
import storage from "../../../utils/storage";
import { TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../config/vars";


/**
 *  Reusable component that is used as a search and inputbox combined .It handles data with lazy loading as well
 * @param {Array} $column - it is a table definition prop that contains column names for the list of search values
 * @param string $displayType value-(text or button) - text for input text and button for radiobutton
 * @param onChange $onChange
 * @returns html
 */
function RadioButton({
  columns = [],
  displayType = "text",
  textDisplayPropName = "desc",
  onChange = () => { },
  listSource = async () => { },
  noScroll,
  data = {},
  placeholder,
  fieldName = false, // Defualt false if API is responding 20 records in each page response
  tabOrder
}) {
  
  const containerRef = useRef(null);
  const [writableMode, setWritableMode] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noScrollLoad, setNoScrollLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [inputTextValue, setInputTextValue] = useState(null);

  const loadList = function (_searchText = "", _page = 1) {
    setLoading(true);
    setNoScrollLoad(false);
    if (noScroll) {
      setNoScrollLoad(true);
    }
    setPage(_page);

    listSource(_searchText, _page, pageOffset)
      .then((res) => {
        let _data;
        if (fieldName) {
          _data = res[`${fieldName}`];

          if (pageOffset === 0) {
            setList(_data);
          }
          else {
            setList([...list, ..._data]);
          }
          if (res.nextPageOffset) {
            setPageOffset(res.nextPageOffset);
          }

          if ((_data && _data.length < 20) || noScroll) {
            setNoScrollLoad(true);
          } else {
            setNoScrollLoad(false);
          }
        }
        else {
          _data = res;
          if (_page === 1) {
            setList(_data);
          }
          else {
            setList([...list, ..._data]);
          }

          if ((_data && _data.length === 0) || noScroll) {
            setNoScrollLoad(true);
          } else {
            setNoScrollLoad(false);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClickOutside = (e) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target)
    ) {
      setSearchText(null);
      setPageOffset(0);
      setWritableMode(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("focusout", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("focusout", handleClickOutside);
    }
  }, [])

  useEffect(() => {
    if(searchText){
       utils.debounce(loadList, 400, searchText);
    }  
  }, [searchText]);

  const handleSelection = (
    option,
    skipModeChange,
    ignoreIfAlreadySelected = false
  ) => {
    !skipModeChange && setWritableMode(!writableMode);
    if (onChange) {
      onChange(data && data.field, option, ignoreIfAlreadySelected);
    }
  };
  const showOptionsPopup = () => {
    if (!writableMode) {
      loadList();
    }
    setWritableMode(!writableMode);
  };
  const radioButtonScroll = React.useRef(null);

  useEffect(() => {
    let value;
    if(data && data.selectedValue) {
      value = data.selectedValue === "Blank" ? "" : data.selectedValue;
    } else if(data && data.values && data.values[0].id === "" && data.values[0].code === undefined) {
      value = "";
    } else if(data && data.values && data.values[0].code && columns[1] !== " " && data.values[0].id !== "") {
      value = `${data && data.values && data.values[0][textDisplayPropName]}${" (" + data.values[0].code + ")"}`;
    } else {
      value = `${data && data.values && data.values[0][textDisplayPropName]} `;
    }
  setInputTextValue(value);
  },[data]);
  
  return (
    <div className="radio-container" ref={containerRef}>
      <div className="p-buttonset af-btn-group ">
        {!writableMode ? (
          displayType === "text" ? (
            <InputText
              tabIndex={tabOrder}
              placeholder={placeholder}
              onClick={() => {
                showOptionsPopup();
              }}
              onKeyPress={() => {
                showOptionsPopup();
              }}
              onKeyUp={() => {
                showOptionsPopup();
              }} 
              className="p-inputtext p-component p-filled radioButtonInput"
               value={inputTextValue}   
              autoComplete="off"           
            />
          ) : (
            <div className="radio-button-container">
              {data.values.slice(0, 4).map((btn, index) => {
                return (
                  <Button
                    tabIndex={tabOrder}
                    key={index}
                    onClick={() => {
                      handleSelection(btn, true, false);
                    }}
                    value={btn.value}
                    className={
                      btn.isDefault === true
                        ? "p-button p-component p-button-label p-c btn-selected"
                        : "p-button p-component p-button-label p-c"
                    }
                  >
                    {btn.label || btn.displayCode}
                  </Button>
                );
              })}
            </div>
          )
        ) : (
          <>
            <InputText 
              autoFocus
              type="text"
              tabIndex={tabOrder}
              placeholder={placeholder}
              className="p-inputtext p-component p-filled radioButtonInput"
              value={searchText}
              onChange={(e) => {
                radioButtonScroll.current.scrollTop = 0;               
                setSearchText(e.currentTarget.value);
                setPage(1);
                setPageOffset(0);
              }}
              autoComplete="off"
            />
            <div
              ref={radioButtonScroll}
              onScroll={(e) => {
                if (!noScrollLoad) {
                  if (
                    e.currentTarget.scrollHeight -
                    (e.currentTarget.offsetHeight +
                      e.currentTarget.scrollTop) <
                    40
                  ) {
                    loadList(searchText, page + 1);
                    setNoScrollLoad(true);
                  }
                }
              }}
              className="radio-options-table"
            >
              <table>
                <thead className="thead-addtime">
                  <tr>
                    {columns.map((d, index) => (
                      <td
                        key={index}
                        className="radio-options-table-headercode"
                      >
                        {d}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.map((option, index) => {
                      return (
                        <tr
                          key={index}
                          onClick={() => {
                            handleSelection(option, false, true);
                          }}
                          className="radio-options-table-row"
                        >
                          <td>
                            {option.label ||                              
                              option.displayCode ||
                              option.code ||
                              option.subClientNumber}
                          </td>
                          <td>
                            {option.desc ||
                              option.description || 
                              option.client_name ||
                              option.subClientName }
                          </td>
                        </tr>
                      );
                    })}
                  {loading && (
                    <tr>
                      {" "}
                      <td colSpan={2}>
                        <div className="af-spinner">
                          {" "}
                          <Spinner />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {listSource && (
          <img
            title={storage.getObject("literals") && storage.getObject("literals")[TOOLTIPS_KEYS.TOOLTIP_SEARCH] ? storage.getObject("literals")[TOOLTIPS_KEYS.TOOLTIP_SEARCH] : [TOOLTIPS_VALUES.SEARCH]}
            className="af-icon-search"
            alt=""
            src="/images/svg/search.svg"
            onClick={() => {
              setSearchText("");
              showOptionsPopup();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RadioButton;
