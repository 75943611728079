import React, { useEffect } from "react";
import * as NarrativeHelper from "../NarrativeHelper";
import Popup from "../Popup";
import RestrictedWordPopup from "../../../Account/AutoText/RestrictedWordPopup";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import { Tooltip } from "primereact/tooltip";

const initialStyle = {
  height: "140px",
  width: "280px",
  position: "absolute",    
  top: "180px",
  left: "1120px",
  zIndex: 50,
};

const Narrative = ({
  module,
  onChangeNarrative,
  onReplaceAll,
  value,
  isNarrativeDisabled,
  onNarrativeCorrection,
  setKeyPressed,
  rows,
  id,
  onChange,
  containerDivClass,  
  spellCheckToggle,
  rwAction,
  rwActionHandler,
  setSelectedRestrictedWordHandler,
  setAutotextValueHandler,
  setHighlightRestrictedWordsHandler,
  selectedWord,
  highlightRestrictedWords,
  AutotextReducer,
  PlugInNarrative,
  closeIcon,
  resetTabToCrossButtonNarrative = () => {},
  tabOrder = 0
}) => {

  useEffect(() => {
   //highlightRestrictedWords && highlightRestrictedWords.map(rWord => (rWord.enhancement = ToolTipComponent));
   let colorWords = module === "Autotext" ? getErrorWords(): getAllRWords();
    if(rwAction === "Ignore All") {
    onNarrativeCorrection(
      "Ignore All",
      null,
      colorWords
    );
    } else if(rwAction === "Replace All") {
      onChangeNarrative(null, colorWords, rwAction);
    }  

   //console.log("highlightRestrictedWords ",highlightRestrictedWords);
  }, [highlightRestrictedWords]);
  
  useEffect(() => {
    document.addEventListener("keydown", detectKeycode);   
    return (() => {
      document.removeEventListener("keydown", detectKeycode);
      //dispatch(setSelectedRestrictedWord({ ...selectedWord, showPopup: false }));
     setSelectedRestrictedWordHandler({ ...selectedWord, showPopup: false });
      }
    )    
  },[]);

useEffect(() => {
    if(PlugInNarrative && highlightRestrictedWords){
        highlightRestrictedWords.map(rWord => (rWord.enhancement = ToolTipComponent));
    }
      }, [highlightRestrictedWords]);
     const ToolTipComponent = (props) => {
      
          // const dispatch = useDispatch();
          // const { highlightRestrictedWords } = useSelector(({ TimeManagementReducer }) => ({ highlightRestrictedWords: TimeManagementReducer.highlightRestrictedWords}));
        
          //const highlightRestrictedWords = storage.getSessionStorageValue("highlightRestrictedWords")
          //const highlightRestrictedWords = window.highlightRestrictedWords
          const onClick = (e) => {
            let wordEntity = highlightRestrictedWords && highlightRestrictedWords.find((obj) => obj.restrictedWord && props.data.text && obj.restrictedWord.trim().toUpperCase() === props.data.text.trim().toUpperCase());
            if (wordEntity) {
              wordEntity.beginIndex = props && props.data && props.data.beginIndex;
              wordEntity.endIndex = props && props.data && props.data.endIndex;
              wordEntity.restrictedWord = props.data.text;
            
              let newStyle;
           
               let top = 30
              // e.target.offsetParent.offsetParent.offsetHeight - e.target.offsetTop < 140
              // ? e.target.offsetParent.offsetParent.offsetHeight - 140
              // : e.target.offsetTop + 20;
              let left = 20
                // e.target.offsetParent.offsetParent.offsetWidth - e.target.offsetLeft < 280
                //   ? e.target.offsetParent.offsetParent.offsetWidth - 120
                //   : e.target.offsetLeft + 20;
              
                newStyle = { ...initialStyle, top: `${top}px`, left: `${left}px` };
                wordEntity = {...wordEntity, showPopup: true, replaceTo: wordEntity.suggestedReplacement, style: newStyle};
            
             //let fieldId = document.getElementsByClassName(containerDivClass)[0].id;
             let fieldId = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id || 
             e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id;
                wordEntity.fieldId = fieldId;
              //dispatch(setSelectedRestrictedWord(wordEntity)); 
              //sessionStorage.setItem("setSelectedRestrictedWord" , wordEntity)
              //window.setSelectedRestrictedWord = wordEntity
              //storage.setSessionStorageValue("setSelectedRestrictedWord" , wordEntity)
              setSelectedRestrictedWordHandler(wordEntity);
            }
          };
      
          const getErrorMessage = (word) => {
            let wordEntity = highlightRestrictedWords && highlightRestrictedWords.find((obj) => obj.restrictedWord && word && obj.restrictedWord.trim().toUpperCase() === word.trim().toUpperCase());
            return wordEntity && wordEntity.errorMessage || "";
          };
      
          const overlayStyle = {
            zIndex: 1,
            cursor: "pointer",
            color: "black"
          };
         
          let rWordId = props && props.data && props.data.text.replace(/[^a-zA-Z0-9]/g, '');
          return (
            <span>
              <Tooltip
                target={`#id_${rWordId}`}
                position="top"
                autoHide={false}
                content={getErrorMessage(props && props.data && props.data.text)}
              />
              <props.MarkView id={`id_${rWordId}`} style={overlayStyle} onClick={onClick} />
            </span>
          );
        }

  const onClose = () => {
    //dispatch(setSelectedRestrictedWord({ ...selectedWord, showPopup: false }));
    setSelectedRestrictedWordHandler({...selectedWord, showPopup: false})

  };
  
  const getAllRWords = () => {
    let reds = document.getElementsByClassName("red");
    reds = Array.from(reds);
    reds = reds.map(word => word.innerText);

    let yellows = document.getElementsByClassName("yellow");
    yellows = Array.from(yellows);
    yellows = yellows.map(word => word.innerText);
    return [...reds, ...yellows];
  };

  const getErrorWords = () => {
    let reds = document.getElementsByClassName("red");
    reds = Array.from(reds);
    reds = reds.map(word => word.innerText);
    return reds;
  };

  useEffect(() => {
    let enteredRWords;
    if(module === "Autotext") {
      enteredRWords = getErrorWords();
      onChangeNarrative(value, enteredRWords, null);
      //dispatch(setAutotextValue(id, value));
      setAutotextValueHandler(id, value);
    } else {
      enteredRWords = getAllRWords();
      onChangeNarrative(value, enteredRWords, null);
    }

  }, [value]);

  /**
   * function to detect key pressed
   * @param {event} ev 
   */
  const detectKeycode = (ev) => {
    ev = ev || window.event; // Event object 'ev'
    var key = ev.which || ev.keyCode; // Detecting keyCode    
    // Detecting Ctrl
    var ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false;
    // If key pressed is V and if ctrl is true.
    if (key === 86 && ctrl) {
      setKeyPressed("Ctrl+V");
    } else if (key === 67 && ctrl) {
      setKeyPressed("Ctrl+C");
    } else if (key === 32 || key === 229 ) {
      setKeyPressed("space");
    } else if (key === 8) {
      setKeyPressed("back_space");
    } else if(key === 191 && ev.key === "?") {
      setKeyPressed("question_mark");
    }
    else if(key === 49 && ev.key === "!") {
      setKeyPressed("exclamation");
    }
    else if(key === 190 && ev.key === ".") {
      setKeyPressed("dot");
    }
    else if(key === 13 && ev.key === "Enter") {
      setKeyPressed("enter");
    }
    else {
      setKeyPressed(key);
    }
  }

  const replaceWordsInNarrative = (_value, _selectedWord, _replaceTo, action) => {
    if (action === "Replace All") {
      const {
        narrativeTextNew
      } = NarrativeHelper.replaceWordsInNarrative(
        _value,
        _selectedWord,
        _replaceTo
      );

      onChangeNarrative(narrativeTextNew, null, action);
    }
  }

  const replaceWordsInAutotext = (_value, _selectedWord, _replaceTo, action) => {
    const {
        narrativeTextNew
      } = NarrativeHelper.replaceWordsInNarrative(
        _value,
        _selectedWord,
        _replaceTo
      );
    return narrativeTextNew;
  }

  const ignoreWordsInNarrative = (_value, _selectedWord, action) => {
    if (action === "Ignore All") {
      const {
        restrictedWordsNew
      } = NarrativeHelper.ignoreWordsInNarrative(
        _value,
        highlightRestrictedWords,        
        _selectedWord
      );
      
    //dispatch(setHighlightRestrictedWords(restrictedWordsNew));    
    setHighlightRestrictedWordsHandler(restrictedWordsNew);
    onNarrativeCorrection(
        action,
        restrictedWordsNew,
        null
    );
    }
  }

  const onButtonClick = (action) => {
    if (action === "Replace All") {
      replaceWordsInNarrative(value, selectedWord, selectedWord.replaceTo, action);
    } else if (action === "Ignore All") {
      rwActionHandler(action);
      ignoreWordsInNarrative(value, selectedWord, action);
    }
   // dispatch(setSelectedRestrictedWord({ ...selectedWord, showPopup: false }));
    setSelectedRestrictedWordHandler({ ...selectedWord, showPopup: false });
  };

  const onButtonClickAutotext = (action) => {
    if (action === "Replace All") {
      let fieldId =  selectedWord.fieldId;
      let currenttext = AutotextReducer[fieldId];
      let replacedText = replaceWordsInAutotext(currenttext, selectedWord, selectedWord.replaceTo, action);
      onReplaceAll({ rowId: parseInt(fieldId.slice(5, fieldId.length)), [fieldId]: replacedText,
         fieldId: fieldId, fieldType: fieldId.includes('shrt') ? "shortcut" : "fulltext" });
    } else if (action === "Ignore All") {
      rwActionHandler(action);
      ignoreWordsInNarrative(value, selectedWord, action);
    }
    //dispatch(setSelectedRestrictedWord({ ...selectedWord, showPopup: false }));
    setSelectedRestrictedWordHandler({ ...selectedWord, showPopup: false });
  };

  const onChangeReplaceTo = (newText) => {
    //dispatch(setSelectedRestrictedWord({ ...selectedWord, replaceTo: newText }));
    setSelectedRestrictedWordHandler({ ...selectedWord, replaceTo: newText });
  }

  return (
    <>
      <div className="container-border">
        <div className={containerDivClass} id={id}>
          {module === "Autotext" && <RestrictedWordPopup 
           selectedWord={selectedWord}
           showPopup={selectedWord && selectedWord.showPopup}
           replaceTo={selectedWord && selectedWord.replaceTo}
            onChangeReplaceTo={onChangeReplaceTo}
            onButtonClick={onButtonClickAutotext}
            onClose={onClose}
            style={selectedWord && selectedWord.style}
           />}
          {module !== "Autotext" &&  <Popup
            selectedWord={selectedWord}
            showPopup={selectedWord && selectedWord.showPopup}
            replaceTo={selectedWord && selectedWord.replaceTo}
            onChangeReplaceTo={onChangeReplaceTo}
            onButtonClick={onButtonClick}
            onClose={onClose}
            style={selectedWord && selectedWord.style}
            PlugInNarrative={PlugInNarrative}
            closeIcon={closeIcon}
          />}
          {highlightRestrictedWords &&<HighlightWithinTextarea            
            value={value}
            highlight={highlightRestrictedWords}
            onChange={(event) => { onChange(event) }}
            onKeyDown={e => { detectKeycode(e); resetTabToCrossButtonNarrative(e)}}
            rows={rows}
            containerClassName="narrative-textarea"
            style={{ width: "100%", borderColor: "white", outline: "none", border: '0' }}
            disabled={isNarrativeDisabled}   
            spellCheck={spellCheckToggle+""}         
            data-testid="editTextArea"
            tabIndex={tabOrder} 
            id="Narrative"
            //maxLength={maxLimit}
          />}
        </div>
      </div>
    </>
  );
};

export default Narrative;
