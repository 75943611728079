import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import storage from "./storage";
import tzmoment from "moment-timezone";
import { trcHeader } from "../config/literalCodes";
var jsUtils = {
  debFlag: null,
  debounce: function debounce(doSomething, time) {
    for (var _len = arguments.length, params = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      params[_key - 2] = arguments[_key];
    }
    if (jsUtils.debFlag) {
      window.clearTimeout(jsUtils.debFlag);
      jsUtils.debFlag = null;
    }
    if (!jsUtils.debFlag) {
      jsUtils.debFlag = window.setTimeout(function () {
        jsUtils.debFlag = null;
        doSomething && doSomething.apply(void 0, params);
      }, time);
    }
  },
  objToQS: function objToQS(obj) {
    return Object.keys(obj).reduce(function (a, b) {
      return obj[b] ? a + b + "=" + obj[b] + "&" : a;
    }, "");
  },
  sortTable: function sortTable(localSort, dataBackup) {
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    if (localSort.propName !== "" && localSort.index !== -1) {
      data = data.sort(function (a, b) {
        return localSort.isAsc ? a[localSort.propName] && a[localSort.propName].toString().toLowerCase() > b[localSort.propName] && b[localSort.propName].toString().toLowerCase() ? 1 : -1 : a[localSort.propName] && a[localSort.propName].toString().toLowerCase() > b[localSort.propName] && b[localSort.propName].toString().toLowerCase() ? -1 : 1;
      });
      return JSON.parse(JSON.stringify(data));
    } else {
      return JSON.parse(JSON.stringify(dataBackup));
    }
  },
  updateRowData: function updateRowData(rowId, newRow, data, dataBackup, setHook, setHookBackup) {
    // takes aray of rows, and updates one row.
    dataBackup = JSON.parse(JSON.stringify(dataBackup));
    dataBackup.forEach(function (item, index) {
      if (item.rowId === rowId) {
        dataBackup[index] = newRow;
      }
    });
    data.forEach(function (item, index) {
      if (item.rowId === rowId) {
        data[index] = newRow;
      }
    });
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
    setHook && setHook(JSON.parse(JSON.stringify(data)));
  },
  deleteRow: function deleteRow(rowId, data, dataBackup, setHook, setHookBackup) {
    data = data.filter(function (d) {
      return d.rowId !== rowId;
    });
    dataBackup && (dataBackup = dataBackup.filter(function (d) {
      return d.rowId !== rowId;
    }));
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
    setHook && setHook(JSON.parse(JSON.stringify(data)));
  },
  addRow: function addRow(rowToAdd, data, dataBackup, setHook, setHookBackup) {
    data.unshift(rowToAdd);
    dataBackup.unshift(rowToAdd);
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
    setHook && setHook(JSON.parse(JSON.stringify(data)));
  },
  editMode: function editMode(rowId, _editMode, data, dataBackup, setHook, setHookBackup, editStartDate) {
    data.forEach(function (item, index) {
      if (item.rowId === rowId) {
        data[index] = _objectSpread(_objectSpread({}, data[index]), {}, {
          editMode: _editMode,
          editStartDate: editStartDate
        });
      }
    });
    dataBackup.forEach(function (item, index) {
      if (item.rowId === rowId) {
        dataBackup[index] = _objectSpread(_objectSpread({}, dataBackup[index]), {}, {
          editMode: _editMode,
          editStartDate: editStartDate
        });
      }
    });
    setHook && setHook(JSON.parse(JSON.stringify(data)));
    setHookBackup && setHookBackup(JSON.parse(JSON.stringify(dataBackup)));
  },
  updateACellOrProp: function updateACellOrProp(rowId, propName, newData, data, setHook) {
    data = JSON.parse(JSON.stringify(data));
    data.forEach(function (item, index) {
      if (item.rowId === rowId) {
        item[propName] = newData;
      }
    });
    setHook && setHook(JSON.parse(JSON.stringify(data))); // console.log(data);
  },
  getRandomNumber: function getRandomNumber() {
    var crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    return crypto && crypto.getRandomValues(array)[0];
  },
  setDataToSessionStorage: function setDataToSessionStorage(clientItem) {
    var now = new Date();
    clientItem["fetchedTimeInSecs"] = Math.round(now.getTime() / 1000);
    sessionStorage.setItem("timerKeyLazy", JSON.stringify(clientItem));
  },
  getDataFromSessionStorage: function getDataFromSessionStorage() {
    var getTimer = sessionStorage.getItem("timerKeyLazy");
    console.log("getTimer", getTimer);
    if (getTimer) {
      return JSON.parse(getTimer);
    } else {
      return null;
    }
  },
  updatedTime: function updatedTime(runningTimer, additionalTimeInSeconds) {
    var _runningTimer$split = runningTimer.split(":"),
      _runningTimer$split2 = _slicedToArray(_runningTimer$split, 3),
      hh = _runningTimer$split2[0],
      mm = _runningTimer$split2[1],
      ss = _runningTimer$split2[2];
    var finalSeconds, finalHours, finalMins;
    var startTime = Number(hh) * 3600 + Number(mm) * 60 + Number(ss) + additionalTimeInSeconds;
    finalHours = Math.trunc(startTime / 3600);
    finalMins = Math.trunc(startTime % 3600 / 60);
    finalSeconds = Math.trunc(startTime % 3600 % 60);
    var finalTime = (('0' + finalHours).length < 3 ? '0' + finalHours : finalHours) + ":" + ('0' + finalMins).slice(-2) + ":" + ('0' + finalSeconds).slice(-2);
    return finalTime;
  },
  getLiteralValue: function getLiteralValue(key) {
    return storage.getObject("literals") && storage.getObject("literals")[key] ? storage.getObject("literals")[key] : key;
  },
  getWeekDayArrayInNumberFormats: function getWeekDayArrayInNumberFormats(weekDays) {
    var selectedWeekNumberArray = [];
    weekDays.forEach(function (day) {
      if (day === "Sun") {
        selectedWeekNumberArray.push(0);
      }
      if (day === "Mon") {
        selectedWeekNumberArray.push(1);
      }
      if (day === "Tue") {
        selectedWeekNumberArray.push(2);
      }
      if (day === "Wed") {
        selectedWeekNumberArray.push(3);
      }
      if (day === "Thu") {
        selectedWeekNumberArray.push(4);
      }
      if (day === "Fri") {
        selectedWeekNumberArray.push(5);
      }
      if (day === "Sat") {
        selectedWeekNumberArray.push(6);
      }
    });
    return selectedWeekNumberArray;
  },
  getCurrentServerDate: function getCurrentServerDate(timezone) {
    var finalCurrDate = null;
    if (timezone) {
      var currentDate = tzmoment().tz(timezone).format();
      var currDate = currentDate.substring(0, 10).split("-");
      finalCurrDate = currDate[0] + "/" + currDate[1] + "/" + currDate[2];
    }
    return finalCurrDate;
  },
  checkIfCustomTableFilterChanged: function checkIfCustomTableFilterChanged(_colDef, _defaultValue) {
    var status = true;
    _colDef.forEach(function (item, index) {
      if (item.filter.val !== null ? item.filter.val !== _defaultValue[index] : false) {
        status = false;
        return status;
      }
    });
    return status;
  },
  getHeader: function getHeader(headerBtn, headerView, screenName) {
    if (!headerView && !headerBtn) {
      return null;
    }
    var header = {
      "x-spc-trk-channel": "web"
    };
    if (screenName) {
      header["x-spc-trk-module"] = trcHeader[screenName].moduleName;
      header["x-spc-trk-feature"] = trcHeader[screenName].featureName;
    }
    if (headerBtn) {
      header["x-spc-trk-btn"] = headerBtn;
    }
    if (headerView) {
      header["x-spc-trk-view"] = headerView;
    }
    return header;
  },
  getDatePlusMinus: function getDatePlusMinus(date, days) {
    var dt = new Date(date);
    var newdate = new Date(dt);
    newdate.setDate(newdate.getDate() + days);
    return newdate;
  },
  getTimeZoneFormatDate: function getTimeZoneFormatDate(date) {
    if (typeof date === "string" && date.includes("-")) {
      var formattedDate = date.substring(0, 10).split("-");
      formattedDate = formattedDate[1] + "/" + formattedDate[2] + "/" + formattedDate[0];
      return formattedDate;
    } else {
      return date;
    }
  },
  getMinDate: function getMinDate(currentDate, startDate, mode) {
    var mindate = null;
    if (mode === "edit") {
      mindate = new Date(jsUtils.getTimeZoneFormatDate(startDate)) > new Date(jsUtils.getTimeZoneFormatDate(currentDate)) ? new Date(jsUtils.getTimeZoneFormatDate(startDate)) : new Date(jsUtils.getTimeZoneFormatDate(currentDate));
    } else {
      mindate = new Date(jsUtils.getTimeZoneFormatDate(startDate)) > new Date(jsUtils.getTimeZoneFormatDate(currentDate)) ? new Date(jsUtils.getDatePlusMinus(jsUtils.getTimeZoneFormatDate(startDate), 1)) : new Date(jsUtils.getDatePlusMinus(jsUtils.getTimeZoneFormatDate(currentDate), 1));
    }
    return mindate;
  },
  getMaxDate: function getMaxDate(currentDate, checkedDate) {
    var maxDate = null;
    if (checkedDate && currentDate) {
      maxDate = new Date(jsUtils.getTimeZoneFormatDate(checkedDate)) > new Date(jsUtils.getTimeZoneFormatDate(currentDate)) ? new Date(jsUtils.getTimeZoneFormatDate(checkedDate)) : new Date(jsUtils.getTimeZoneFormatDate(currentDate));
    } else {
      if (currentDate) {
        maxDate = new Date(jsUtils.getTimeZoneFormatDate(currentDate));
      } else {
        maxDate = new Date(jsUtils.getTimeZoneFormatDate(checkedDate));
      }
    }
    return maxDate;
  },
  getValidatedColumns: function getValidatedColumns(_DayViewColDef, _selectedColumns) {
    var newColumnDef = _DayViewColDef.filter(function (item) {
      return _selectedColumns.findIndex(function (finalCol) {
        return finalCol.columnName === item.column.columnName && finalCol.col_enable;
      }) !== -1;
    }) || [];
    var sortedColumnDef = [];
    _selectedColumns.forEach(function (item) {
      var searchedColumn = newColumnDef.find(function (newColumn) {
        return newColumn.column.columnName === item.columnName;
      });
      if (searchedColumn) {
        sortedColumnDef.push(searchedColumn);
      }
    });
    sortedColumnDef = _toConsumableArray(sortedColumnDef);
    return sortedColumnDef;
  },
  parseHash: function parseHash() {
    return window.location.hash.replace('#', '').split('&').reduce(function (prev, item) {
      return Object.assign(_defineProperty({}, item.split('=')[0], decodeURIComponent(item.split('=')[1])), prev);
    }, {});
  },
  generateRandomValue: function generateRandomValue() {
    var crypto = window.crypto || window.msCrypto;
    var D = new Uint32Array(2);
    crypto.getRandomValues(D);
    return D[0].toString(36);
  }
};
export default jsUtils;