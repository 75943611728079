import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../TimeManagement/TimeManagement.scss";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { useHistory } from "react-router-dom";
import "../../common/BreadCrumbComp/BreadCrumbComp.scss";
import {setBreadCrumb, setPageHelpLink } from "../../../actions/LandingPageAction";
import { setSelectedTimeKeeperFeature } from "../../../actions/TimeManagementAction";
import {HELP_LINKS} from "../../../config/vars";
import { helpPageUrl } from "../../../config";
import Spinner from "../../common/Spinner/Spinner";
import jsUtils from "../../../utils/jsUtils";
import ConfigurationScreen from "./Configuration/ConfigurationScreen";
import NarrativeControls from "./NarrativeControls/NarrativeControls";
import ErrorPage from "../../common/ErrorPage";

function SystemManagement(props) {
  let history=useHistory()
  const dispatch = useDispatch();
  const loadingClient = useSelector(
    (state) => state.TimeManagementReducer.loading
  );
  const loadingTimeKeeper = useSelector(
    (state) => state.TimeManagementReducer.loadingTimeKeeper
  );
  const error = useSelector((state) => state.TimeManagementReducer.error);
  const itemdata = useSelector((state) => state.LandingPageReducer.items);
  const [signingOut] = useState(false);
  const [pageTitle, setpageTitle] = useState(`${jsUtils.getLiteralValue("ATTORNEYFLOW_")+" "+jsUtils.getLiteralValue("SYSTEM_CONFIGURATION")}`);
  
  useEffect(() => {
    dispatch(setBreadCrumb(["MG-Settings", "MG-Settings-MD-System"]))

  }, []);
  useEffect(() => {
    const moduleName = itemdata[0] ?.landingPageData?.moduleGroups?.find((groupObj)=> groupObj.moduleGroupName ==="MG-Settings")?.modules.find((moduleObj)=>moduleObj.moduleName === "MG-Settings-MD-System")
      let isConfiguration=moduleName?.menus?.find((item)=>item.menuName==="MG-Settings-MD-System-MN-MSCalIntg");
      let isNarrativeControl=moduleName?.menus?.find((item)=>item.menuName==="MG-Settings-MD-System-MN-Narrative-Ctrls");
    switch ( props?.match?.params?.MODE && props?.match?.params?.MODE?.toLowerCase().trim())
    {
    case "narrativecontrols":
      if(isNarrativeControl){
        dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.RESTRICTED_WORDS}`))
        setpageTitle(`${jsUtils.getLiteralValue("ATTORNEYFLOW_")+" "+jsUtils.getLiteralValue("MG-Settings-MD-System-MN-Narrative-Ctrls")}`);
        history.push(isNarrativeControl.url)
      }
      else{
        history.push("/System/unauthorized")
      }
     break;
     
     case "configuration":
      if(isConfiguration){
     setpageTitle(`${jsUtils.getLiteralValue("ATTORNEYFLOW_")+" "+jsUtils.getLiteralValue("SYSTEM_CONFIGURATION")}`);
     history.push(isConfiguration.url) 
    }
      else{
        history.push("/System/unauthorized")
      }
     break;
      case "unauthorized":
        setpageTitle(`${jsUtils.getLiteralValue("ATTORNEYFLOW_")}`);
        break;
     default:
      if(isConfiguration){
        setpageTitle(`${jsUtils.getLiteralValue("ATTORNEYFLOW_")+" "+jsUtils.getLiteralValue("SYSTEM_CONFIGURATION")}`);
        history.push(isConfiguration.url)
      }
      else{
        if(isNarrativeControl){
          dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.RESTRICTED_WORDS}`))
          setpageTitle(`${jsUtils.getLiteralValue("ATTORNEYFLOW_")+" "+jsUtils.getLiteralValue("MG-Settings-MD-System-MN-Narrative-Ctrls")}`);
          history.push(isNarrativeControl.url)
        }
        else{
          history.push("/System")
        }
      }
       
    }
  }, [props?.match?.params?.MODE,itemdata])

  useEffect(() => {
    document.title = jsUtils.getLiteralValue(pageTitle);
  }, [pageTitle]);

  useEffect(() => {
  const moduleName = itemdata[0] ?.landingPageData?.moduleGroups?.find((groupObj)=> groupObj.moduleGroupName ==="MG-Settings")?.modules.find((moduleObj)=>moduleObj.moduleName === "MG-Settings-MD-System")
    
  let featureNameList=[];
  let featureIdList=[];
if (props?.match?.params?.MODE?.toLowerCase().trim() === "configuration"){
  let menu = moduleName?.menus?.find((item)=> item.menuName ==="MG-Settings-MD-System-MN-MSCalIntg");
  let menuFeatures = menu?.features;
  if(menuFeatures) {
    menuFeatures.forEach((featureItem)=>{
    featureNameList.push(featureItem.featureName);
    featureIdList.push(featureItem.featureId)
  })
 }
}
  else if (props?.match?.params?.MODE?.toLowerCase().trim() === "narrativecontrols"){
    let menu = moduleName?.menus?.find((item)=> item.menuName ==="MG-Settings-MD-System-MN-Narrative-Ctrls");
    let menuFeatures = menu?.features;
    if(menuFeatures) {
      menuFeatures.forEach((featureItem)=>{
      featureNameList.push(featureItem.featureName);
      featureIdList.push(featureItem.featureId)
    })
   }
  }

  dispatch(setSelectedTimeKeeperFeature(
    featureNameList,
    featureIdList
  ))
    
  }, [itemdata , props?.match?.params?.MODE]);



  if (signingOut) {
    return (
      <div className="spinner-popup">
        <Spinner />
      </div>
    );
  } else {
    return (
      <>

        {loadingClient === false &&
        loadingTimeKeeper === false &&
        error === "" ?(
            <div className="p-tabview p-component">
               {props?.match?.params?.MODE?.toLowerCase() === "narrativecontrols" ? <NarrativeControls pageTitle={pageTitle}/>
            :props?.match?.params?.MODE?.toLowerCase() === "configuration"?<ConfigurationScreen pageTitle={pageTitle} />:<div><ErrorPage onErrorPageHandler={props.onErrorPageHandler}/></div>
              }
            </div>
        ) : (
          <div>{jsUtils.getLiteralValue("NETWORK_ERROR")}</div>
        )}
      </>
    );
  }
}

export default SystemManagement;
