import DurationState from "../store/DurationState.ts";
import FieldState from "../components/common/AddTimePopup/AddTimePopup.State.ts";
import jsUtils from "./jsUtils";
var validationUtils = {
  validateDurationFieldAddTime: function validateDurationFieldAddTime() {
    var maxBillableDuration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Number(DurationState.maxBillableDuration);
    var maxHoursPerDay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Number(DurationState.maxHoursPerDay);
    DurationState.update(DurationState.setHHMMDurationDefault);
    var isDurationValid = true;
    var duration = Number(DurationState.duration);
    var time = duration.toFixed(2).split(".");
    var minutes = time.map(function (d) {
      return Number(d) / 100;
    })[1]; //let maxHoursPerDay = Number(DurationState.maxHoursPerDay);
    var totalDurationPerDay = Number(DurationState.totalBillableDurationPerDay); //let maxBillableDuration = Number(DurationState.maxBillableDuration);
    isDurationValid = DurationState.durationValidation && DurationState.durationValidation.length > 0 && !DurationState.durationValidation.includes(minutes) ? false : true;
    if (duration > maxBillableDuration) {
      FieldState.update(FieldState.setDurationError, [jsUtils.getLiteralValue("INVALID_HOURS_TOTAL_ENTERED_FOR_THIS_DAY_EXCEEDS") + " " + maxBillableDuration + " " + jsUtils.getLiteralValue("HOURS")]);
    } else if (totalDurationPerDay + duration > maxHoursPerDay) {
      FieldState.update(FieldState.setDurationError, [jsUtils.getLiteralValue("INVALID_HOURS_TOTAL_ENTERED_FOR_THIS_DAY_EXCEEDS") + " " + maxHoursPerDay + " " + jsUtils.getLiteralValue("HOURS")]);
    } else if (!isDurationValid) {
      FieldState.update(FieldState.setDurationError, [jsUtils.getLiteralValue("THE_HOUR_INCREMENTS_DONT_COMPLY_WITH_THE_CLIENT_MATTER")]);
      DurationState.update(DurationState.setIsDurationValid, [false]);
    } else {
      FieldState.update(FieldState.setDurationError, "");
      DurationState.update(DurationState.setIsDurationValid, [true]);
    }
  },
  validateDurationFieldDraftTimeGT: function validateDurationFieldDraftTimeGT() {
    DurationState.update(DurationState.setHHMMDurationDefault);
    var duration = Number(DurationState.duration);
    var maxHoursPerDay = Number(DurationState.maxHoursPerDay);
    var totalDurationPerDay = Number(DurationState.totalBillableDurationPerDay);
    var maxBillableDuration = Number(DurationState.maxBillableDuration);
    if (maxBillableDuration && duration > maxBillableDuration) {
      FieldState.update(FieldState.setDurationError, [jsUtils.getLiteralValue("INVALID_HOURS_TOTAL_ENTERED_FOR_THIS_DAY_EXCEEDS") + " " + maxBillableDuration + " " + jsUtils.getLiteralValue("HOURS")]);
      DurationState.update(DurationState.setIsDurationValid, [false]);
    } else if (totalDurationPerDay + duration > maxHoursPerDay) {
      FieldState.update(FieldState.setDurationError, [jsUtils.getLiteralValue("INVALID_HOURS_TOTAL_ENTERED_FOR_THIS_DAY_EXCEEDS") + " " + maxHoursPerDay + " " + jsUtils.getLiteralValue("HOURS")]);
    } else {
      FieldState.update(FieldState.setDurationError, "");
      DurationState.update(DurationState.setIsDurationValid, [true]);
    }
  },
  formatPhoneNumber: function formatPhoneNumber(value) {
    if (!value) return value;
    var phoneNumber = value.replace(/[^\d]/g, '');
    var phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return "".concat(phoneNumber.slice(0, 3), "-").concat(phoneNumber.slice(3));
    }
    return "".concat(phoneNumber.slice(0, 3), "-").concat(phoneNumber.slice(3, 6), "-").concat(phoneNumber.slice(6, 10));
  }
};
export default validationUtils;