import ActionStore from "./ActionStore";
class DurationState extends ActionStore {
  #hh: number = 0;
  #mm: number = 0;
  #ss: number = 0;
  #duration: string = "0.00";
  increments: number[] = [6, 12, 15, 30, 45];
  rounding: number = 1; // 0 normal, 1 up , -1 down;
  maxBillableDuration: number = 0;
  maxHoursPerDay: number = 0;
  totalBillableDurationPerDay: number = 0;
  durationValidation: number[] = [];
  durationRoundingRange: number[] = [
    0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
    0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0
  ];
  isDurationValid: boolean = true;

  setRounding = (rounding = "NORMAL") => {
    this.rounding = rounding === "NORMAL" ? 0 : rounding === "UP" ? 1 : -1;
  };
  setIncrements = (incrs) => {
    this.increments = incrs;
  };
  setHH = (hh) => {
    this.#hh = hh;
  };
  setMM = (mm) => {
    if (isNaN(mm) || mm == "") {
      mm = 0;
    }
    this.#mm = Math.round(Number(mm));
  };

  setMMonChange = (mm) => {
    this.#mm = mm;
  };

  setSS = (ss) => {
    this.#ss = ss;
  };

  setDuration = (duration) => {
    this.#duration = duration;
  };

  setMaxBillableDuration = (duration) => {
    this.maxBillableDuration = duration;
  };

  setMaxHoursPerDay = (duration) => {
    this.maxHoursPerDay = duration;
  };

  setTotalBillableDurationPerDay = (duration) => {
    this.totalBillableDurationPerDay = duration;
  };

  setDurationValidation = (allowedDurations) => {
    this.durationValidation = allowedDurations;
  };

  setIsDurationValid = (flag) => {
    this.isDurationValid = flag;
  };

  onIncrement = (incrementValue) => {
    let newDuration = incrementValue / 60 + Number(this.duration);
    let temp = this.percentageToHours(Number(newDuration));
    let newHH = temp[0];
    let newMM = temp[1];
    let newSS = 0;
    this.setSS(newSS);
    this.setMM(newMM);
    this.setHH(newHH);

    this.setDuration(this.hoursToPercentage(newHH, newMM));
  };

  onDurationChange = (
    newDuration: number | string,
    newHH: number | string = "",
    newMM: number | string = "",
    newSS = 0
  ) => {
    if (newMM === "" || !newMM) {
      newMM = 0;
    } else {
      newMM = Number(newMM) || this.mm;
    }
    if (newHH === "" || !newHH) {
      newHH = 0;
    } else {
      newHH = Number(newHH) || this.hh;
    }
    newSS = Number(newSS) || this.#ss || 0;

    if (newDuration) {
      let temp = this.percentageToHours(Number(newDuration));
      newHH = temp[0];
      newMM = temp[1];
      newSS = 0;
    }

    if (newMM > 59) {
      newMM = newMM - 60;
      newHH = newHH + 1;
    }
    newHH = newHH > 999 ? 999 : newHH;
    this.setHH(newHH);
    this.setMM(newMM);
    this.setSS(newSS);
    this.setDuration(this.hoursToPercentage(newHH, newMM, newSS));
  };

  onMinutesOrHoursChange = (newHH, newMM) => {
    this.setHH(newHH);
    this.setMMonChange(newMM);
    this.setSS(0);
    //this.setDuration(this.hhmmToDuration(newHH, newMM));
    let time = this.hoursToPercentage(newHH, newMM, 0)
    .toString()
    .split(".");
    this.setDuration(this.getRoundedValueOfDuration(time, newHH, newMM, 0));
  }; 

  setHHMMDurationDefault = () => {
    if (!this.#hh) this.setHH(0);
    if (!this.#mm) this.setMM(0);
    if (!this.duration) this.setDuration("0.00");
  }; 

  onDurationFieldChange = (newDuration: number | string) => {
    if (newDuration) {
      this.setDuration(newDuration);
      let temp = this.percentageToHours(Number(newDuration));
      let newHH = temp[0];
      let newMM = temp[1];
      let newSS = 0;
      this.setSS(newSS);
      this.setHH(newHH);
      this.setMM(newMM);
    } else {
      this.setDuration(newDuration);
      this.setSS(0);
      this.setHH(0);
      this.setMM(0);
    }
  };

  setHHMMDurationByBillableDuration = (
    newDuration: number | string,
    newHH: number | string = "",
    newMM: number | string = "",
    newSS = 0
  ) => {
    if (newDuration && !newHH && !newMM) {
      this.setDuration(newDuration);
      let temp = this.percentageToHours(Number(newDuration));
      newHH = temp[0];
      newMM = temp[1];
      newSS = 0;
      this.setHH(newHH);
      this.setMM(newMM);
      this.setSS(newSS);
    } else {
      this.setDuration(newDuration);
      this.setHH(Number(newHH));
      this.setMM(Number(newMM));
      this.setSS(newSS);
    }
  };

  calculateRoundingOffDuration = (
    newDuration: number | string,
    newHH: number | string = "",
    newMM: number | string = "",
    newSS = 0
  ) => {
    if (newMM === "" || !newMM) {
      newMM = 0;
    } else {
      newMM = Number(newMM) || this.mm;
    }
    if (newHH === "" || !newHH) {
      newHH = 0;
    } else {
      newHH = Number(newHH) || this.hh;
    }
    newSS = Number(newSS) || 0;
    let time = this.hoursToPercentageForRounding(newHH, newMM, newSS)
      .toString()
      .split(".");
    this.setDuration(this.getRoundedValueOfDuration(time, newHH, newMM, newSS));
    // if (this.#duration) {
    //   let temp = this.percentageToHours(Number(this.#duration));
    //   newHH = temp[0];
    //   newMM = temp[1];
    //   newSS = 0;
    //   this.setSS(newSS);
    //   this.setHH(newHH);
    //   this.setMM(newMM);
    // }
  };

  /**
   * calculates rounding value based on rounding method
   * @param time
   * @param newHH
   * @param newMM
   * @param newSS
   * @returns
   */
  getRoundedValueOfDuration(time, newHH, newMM, newSS) {
    let hourlyMinutes;
    if (Number(newSS) === 0) {
      hourlyMinutes = time.map((d) => Number(d) / 100)[1];
    } else {
      hourlyMinutes = time.map((d) => Number(d) / 10000)[1];
    }
    if (!this.durationValidation) {
      //Rounding logic will not be applied for matter group 0 or durationValidation is null
      return (Number(newHH) + Number(hourlyMinutes)).toFixed(2);
    }

    if (hourlyMinutes > 0) {
      if (hourlyMinutes < this.durationValidation[0]) {
        hourlyMinutes = this.durationValidation[0];
      }
      // else if (
      //   hourlyMinutes >
      //   this.durationValidation[this.durationValidation.length - 1]
      // ) {
      //   hourlyMinutes =
      //     this.durationValidation[this.durationValidation.length - 1];
      // }
       else if (hourlyMinutes > this.durationValidation[this.durationValidation.length - 1]) {
         // hourlyMinutes = this.durationValidation[this.durationValidation.length - 1];
        let first = this.durationValidation[this.durationValidation.length - 1];
        let second = 1;
        if (hourlyMinutes > first && hourlyMinutes < second) {
          if (this.rounding === -1) {
            hourlyMinutes = first;
          } else if (this.rounding === 1) {
            hourlyMinutes = 0;
            newHH = Number(newHH) + 1;
          } else if (this.rounding === 0){
            let median = (first + second) / 2;
            if (hourlyMinutes < median) {
              hourlyMinutes = first;
            } else {
              hourlyMinutes = 0;
            newHH = Number(newHH) + 1;
            }
          }
        }
      } else if (this.durationValidation) {
        for (
          let i = 0, j = 1;
          i < this.durationValidation.length - 1;
          i++, j++
        ) {
          let first = this.durationValidation[i];
          let second = this.durationValidation[j];
          if (hourlyMinutes > first && hourlyMinutes < second) {
            if (this.rounding === -1) {
              console.log(
                "DOWN hourlyMinutes, rounding to",
                hourlyMinutes,
                first
              );
              hourlyMinutes = first;
            } else if (this.rounding === 1) {
              console.log(
                "UP hourlyMinutes, rounding to",
                hourlyMinutes,
                second
              );
              hourlyMinutes = second;
            } else if (this.rounding === 0){
              let median = (first + second) / 2;
              if (hourlyMinutes < median) {
                hourlyMinutes = first;
              } else {
                hourlyMinutes = second;
              }
            }
            break;
          }
        }
      }
    }
    return (Number(newHH) + Number(hourlyMinutes)).toFixed(2);
  }

  /* commented on 3rd June as discussed with QA 
  getRoundedValueOfDuration(time, newHH, newMM, newSS){
    let hourlyMinutes;
    if (Number(newSS) === 0) {
      hourlyMinutes = time.map((d) => Number(d) / 100)[1];
    } else {
      hourlyMinutes = time.map((d) => Number(d) / 10000)[1];
    }
    
    if (hourlyMinutes > 0) {
      if(!this.durationValidation){
        //Rounding logic will not be applied for matter group 0 or durationValidation is null
        return (newHH + hourlyMinutes).toFixed(2);
      } else
      if (hourlyMinutes < this.durationValidation[0]
      ) {
        hourlyMinutes = this.durationValidation[0];
      } else if (hourlyMinutes >
          this.durationValidation[this.durationValidation.length - 1]
      ) {
        hourlyMinutes = this.durationValidation[
          this.durationValidation.length - 1
        ];
        // let first = this.durationValidation[this.durationValidation.length - 1];
        // let second = 1;
        // if (hourlyMinutes > first && hourlyMinutes < second) {
        //   if (this.rounding === -1) {
        //     hourlyMinutes = first;
        //   } else if (this.rounding === 1) {
        //     hourlyMinutes = 0;
        //     newHH = newHH + 1;
        //     this.setMM(newMM);
        //     this.setHH(newHH);
        //   } else {
        //     let median = (first + second) / 2;
        //     if (hourlyMinutes < median) {
        //       hourlyMinutes = first;
        //     } else {
        //       hourlyMinutes = 0;
        //     newHH = newHH + 1;
        //     this.setMM(newMM);
        //     this.setHH(newHH);
        //     }
        //   }
         
        // }
      } else if(this.durationValidation) {
        for (
          let i = 0, j = 1;
          i < this.durationValidation.length - 1;
          i++, j++
        ) {
          let first = this.durationValidation[i];
          let second = this.durationValidation[j];
          if (hourlyMinutes > first && hourlyMinutes < second) {
            if (this.rounding === -1) {
              console.log("DOWN hourlyMinutes, rounding to",hourlyMinutes, first);
              hourlyMinutes = first;
            } else if (this.rounding === 1) {
              console.log("UP hourlyMinutes, rounding to",hourlyMinutes, second);
              hourlyMinutes = second;
            } else {
              let median = (first + second) / 2;
              if (hourlyMinutes < median) {
                hourlyMinutes = first;
              } else {
                hourlyMinutes = second;
              }
            }
            break;
          }
        }
       }
      }
    return (newHH + hourlyMinutes).toFixed(2);
  } */

  get hh() {
    return this.#hh;
  }
  get mm() {
    return this.#mm;
  }
  get duration() {
    return this.#duration;
  }
  percentageToHours = (value) => {
    let temp = Number(value)
      .toFixed(2)
      .split(".")
      .map((d) => Number(d));
    return [temp[0], Number(Number(temp[1].toFixed(2)) * 0.6)];
  };

  hoursToPercentage = (hh = 0, mm = 0, ss = 0) => {
    mm = Number(mm) + Number(ss / 60);
    mm = Number(mm / 60);
    let duration = Number(hh + mm).toFixed(2);
    return duration;
  };

  hhmmToDuration = (hh = 0, mm = 0) => {
    return (Number(hh) + Number(mm/60)).toFixed(2);    
  };

  hoursToPercentageForRounding = (hh = 0, mm = 0, ss = 0) => {
    let duration;
    mm = mm + Number(ss / 60);
    mm = Number(mm / 60);
    duration = Number(hh + mm).toFixed(4);
    return duration;
  };
}

export default new DurationState();
