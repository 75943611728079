import React, { useEffect, useRef, useState } from "react";
import "./TimeManagement.scss";
import { useDispatch, useSelector } from "react-redux";
import TimeManagenmentClientMatterTable from "../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable";
import { useHistory } from "react-router-dom";
import Spinner from "../common/Spinner/Spinner";
import { setSelectedTimeEntries, setSelectedTimeKeeperFeature, setShowEditTimeEntryPage } from "../../actions/TimeManagementAction";
import { DayViewDefaultColumns, HELP_LINKS, ManageTimeDefaultColumns, TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../config/vars";
import storage from "../../utils/storage";
import jsUtils from "../../utils/jsUtils";
import { helpPageUrl } from "../../config";
import { setBreadCrumb, setPageHelpLink } from "../../actions/LandingPageAction";
import MonthView from "../ManageTime/MonthView";
import EditTimeEntry from "../common/EditTimeEntry/EditTimeEntry";
import { MTColDef } from "../ManageTime/MTColDef";
import { DayViewColDef } from "../DayView/DayViewColDef";
import { ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME, ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME, MAX_ATTACHMENT_LIMIT, PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME } from "../../config/messages";
import ConfirmationButton from "../Account/AutoText/ConfirmationButton";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../common/TimeManagenmentClientMatterTable//TimeManagenmentClientMatterTable.scss";
import "../ManageTime/ManageTime.scss";
import DayViewTable from "../DayView/DayViewTable";
import ManageTimeTable from "../ManageTime/ManageTimeTable";
import { Preferences } from "../../services/PreferencesApi";
import AttorneySelectionBox from "../ManageTime/AttorneySelectionBox";
import GeneralTimePopup from "../common/AddTimePopup/GeneralTimePopup";
import ConfirmationDialogBox from "../common/ConfirmationBox/ConfirmationDialogBox";
import DialogWithTableSelection from "../common/DialogWithTableSelection/DialogWithTableSelection";
import { manageTime } from "../../services/ManageTimeApi";
import AddTimePopup from "../common/AddTimePopup/AddTimePopup";
import { setErrorMessage, setWoboAction } from "../../actions/AppAction";
import { setEthicalWallUsers, setShowAttorneyListPopupFlag, setTransferFeatureName } from "../../actions/DayViewAction";
import { dayViewApi } from "../../services/dayViewApi";
import moment from "moment";
import DurationState from "../../store/DurationState.ts";


function TimeManagement(props) {
  const { loadingClient, loadingTimeKeeper, selectedTimeEntries, itemData, error } = useSelector(
    ({ TimeManagementReducer, LandingPageReducer }) => ({
      loadingClient: TimeManagementReducer.loading,
      loadingTimeKeeper: TimeManagementReducer.loadingTimeKeeper,       
      selectedTimeEntries: TimeManagementReducer.selectedTimeEntries,
      error: TimeManagementReducer.error,
      itemData: LandingPageReducer.items
    })
  );

    const { delegateId, timeKeeperList,  } = useSelector(({TimeManagementReducer}) => ({ 
      delegateId: TimeManagementReducer.delegateId,
      timeKeeperList: TimeManagementReducer.timeKeeperList,
     })); 
    
    const { permissionsOfUser, wobo } = useSelector(({AppReducer}) => ({
       permissionsOfUser: AppReducer.fieldnames ,
       wobo: AppReducer.wobo
      }));
   const [signingOut] = useState(false);
  
  const [pageTitle] = useState("ATTORNEYFLOW_ADD_TIME");
  const dispatch = useDispatch();
  const [, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP],
  });
  /** set tooltips from storage on screen load
   *  literals -variale to store the array of literals
   */
  const [codeFromAPI, setCodeFromAPI] = useState([]);

  const [refreshCounter, setRefreshCounter] = useState(1);
  /**
   * get the codes from the literals
   * @param {String} key 
   * @returns labels 
   */
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };

  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP],
      });
      setCodeFromAPI(literals);
    }
    
    dispatch(setBreadCrumb(["MG-Practice", "MG-Practice-MD-TM"]))
    dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.ADD_TIME}`))
    if(!sessionStorage.getItem("show-add-time-popup")) {
    document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME");
    }
}, []);

  
  useEffect(() => {
    sessionStorage.setItem("prev_title", document.title);
    switch ( props.match.params.MODE && props.match.params.MODE.toLowerCase().trim())
    {
      case "managetime":
        dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
     // setActiveIndex(1);
     document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME");
     break;
     case "dayview":
      dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.DAY_VIEW}`))
      // setActiveIndex(0);
      document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_DAY_VIEW");
     break;
     case "monthview":
       dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MONTH_VIEW}`))
       // setActiveIndex(0);
       document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_MONTH_VIEW");
      break;
     case "":
     case "addtime":
      dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.ADD_TIME}`))
      // setActiveIndex(0);
      document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME");
     break;
     default:
       history.push("/SureTime")
    }
    MTColDef[0].column.val=DayViewColDef[0].column.val=false;
  }, [props.match.params.MODE])
  
  useEffect(() => {
     //Calculates totalbillable Duration hours per day for delegateId 
     if(delegateId) {
      let totalbillableHours =0;
      manageTime.getCurrentDayTimeEntries(delegateId, new Date()).then(res => {
        res && res.timeentries.forEach(item => {
          totalbillableHours = totalbillableHours + item.billableDuration;
        });
        DurationState.update(DurationState.setTotalBillableDurationPerDay, [
          totalbillableHours
        ]);
      });
     }
  },[delegateId]);

  /**
   * get the modules data
   */

  useEffect(()=>{
    let moduleGroup=(itemData && itemData[0]?.landingPageData?.moduleGroups?.find((groupObj)=>groupObj.moduleGroupName === "MG-Practice")) || [];
    let moduleObject=moduleGroup?.modules?.find((moduleObj)=>moduleObj.moduleName === "MG-Practice-MD-TM") || [];
    let featureNameList=[];
    let featureIdList=[];

    let moduleFeatures = moduleObject?.menus?.[0]?.features;
    moduleFeatures && moduleFeatures.forEach((featureItem)=>{
      featureNameList.push(featureItem.featureName);
      featureIdList.push(featureItem.featureId)
    })

    dispatch(setSelectedTimeKeeperFeature(
      featureNameList,
      featureIdList
    ))
    
  },[itemData]);


  const onSaveHandlerAddTimeBulkChange = (screen, afterSavingPreference) => {
    if (afterSavingPreference === "keep_te_open") {
      return;
   } else if(afterSavingPreference === "return_to_addTime") {
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME");  
      sessionStorage.removeItem(screen);
      return history.push("/SureTime/AddTime");
   } else if (afterSavingPreference === "show_manageTime") {
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME");  
      sessionStorage.removeItem(screen);
      if(history && history.location && history.location.pathname !== '/SureTime/ManageTime') {
        return history.push("/SureTime/ManageTime");
      }  
   }

   sessionStorage.removeItem(screen);
   setRefreshCounter(refreshCounter + 1);
   return history.push(sessionStorage.getItem("navigated-from")); 
  }


/************************************************************************************************************************************************************************************************************/


const [gridReset, setGridReset] = useState(0);
const [functionalityName, setFunctionalityName] = useState(null);
const [colDef, setColDef] = useState([]);

const { preferenceApiData, hasMultipleSubClients } = useSelector(
  ({ PreferenceReducer }) => ({
    preferenceApiData: PreferenceReducer.preferenceApiData,
    hasMultipleSubClients: PreferenceReducer.hasMultipleSubClients
  })
);

const [columnsData,setColumnsData]=useState([]);
const [columnsDataBackup,setColumnsDataBackup]=useState([]);

const [checkboxRefreshCounter,setCheckBoxRefreshCounter]=useState(0);
const [entriesInfo, setEntriesInfo] = useState("");
let history = useHistory();
const resetSelectToggle = () => {
  let state = colDef;
  state[0].column.val = false;
  setColDef([...state]);
  setCheckAll(false);
}


const onApplyColumnChanges=(finalcolumns,selectColumns)=>{
  setLoading(true);
  let finalColDef = MTColDef;
  let columnKey="MANAGE_TIME_COLUMNS";
  let menuId=2;
  if(props.match.params.MODE && props.match.params.MODE.toLowerCase() ==="dayview"){
      finalColDef=DayViewColDef;
      columnKey="DAY_VIEW_COLUMNS";
      menuId=33
  }
  if(!hasMultipleSubClients) {
    finalColDef = finalColDef.filter(item => item.column.label && item.column.label.toUpperCase() !== "SUB");
  }

  let payload={params:[{param_key:columnKey,param_value:JSON.stringify({"screenName":props.match.params.MODE.toLowerCase(),"columnData": finalcolumns}),preference_menu_id:menuId}]};
  Preferences.post(payload).then((res)=>{
    let clonedArray=[...finalcolumns]
    document.getElementById("columnselectorbutton").click();

    // as the state does not update when we reference the same array. 
    //we are strigifying and parsing it to make sure the state updates with the updated value.

    let backupArrayString=JSON.stringify([...finalcolumns]);
    let backupArrayParsed=JSON.parse(backupArrayString);
    setColumnsDataBackup(backupArrayParsed);

    let sortedColumnDef=jsUtils.getValidatedColumns(finalColDef,selectColumns);
    sortedColumnDef=[colDef[0],...sortedColumnDef,colDef[colDef.length-1]];
    setColDef([...sortedColumnDef]);
    setColumnsData([...clonedArray]);
    setLoading(false);
  }).catch((err)=>{
    console.log(err);
    setLoading(false);
  })
}


/**
  *  - checks and unchecks the header/rowData of ready entries for releasing
  * @param {Boolean} deselectColumn - toggle to select and deselect column
  * @param {object} rowItem - contains the ready item checked for being released
 */
const bulkSelectToggle = (deselectColumn, rowItem) => {
  let data = DataFromAPI;
  if (deselectColumn) {
    if (colDef[0].column.val) {
      let state = colDef;
      state[0].column.val = !state[0].column.val;
      setColDef([...state]);
      setCheckAll(false);
    }
    data.forEach((item) => {
      if (item.rowId === rowItem.rowId) {
        item.isChecked = rowItem.isChecked;
      }
    });

  } else {
    if (!colDef[0].column.val) {
      data.forEach((item) => {
          item.isChecked = true;
      });
      setCheckAll(true);
    } else {
      data.forEach((item) => {
          item.isChecked = false;
      });
      setCheckAll(false);
    }
    let state = colDef;
    state[0].column.val = !state[0].column.val;
    setColDef([...state]);
  }

  setDataFromAPI(JSON.parse(JSON.stringify(data)));
  setDataFromAPIBackup(JSON.parse(JSON.stringify(data)));
  setCheckBoxRefreshCounter(checkboxRefreshCounter+1);
};

/**
 * bulk release of the selected ready time entries
 */
const onBulkRelease =(data,isTEValid)=>{
  if(isTEValid){
  setValidationMessage(jsUtils.getLiteralValue("RELEASE_CHECKBOX_VALIDATION_MESSAGE"))
  }
  else{
  setValidationMessage(jsUtils.getLiteralValue("ARE_YOU_SURE_YOU_WANT_TO_RELEASE_THE_SELECTED_TIME_ENTRIES"))
  }
  setConfirmAction("release");
  if(data.length)
  {
  
  setReleaseTimeentryList(data);
  setDisplayConfirmation(true);
  }
  else {
    dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("NO_READY_TIME_ENTRIES_SELECTED")), errorCode: 400 }));
  }
}
const onBulkDelete=(data,isTEValid)=>{
  
 if(isTEValid){
   setValidationMessage(jsUtils.getLiteralValue("DELETE_CHECKBOX_VALIDATION_MESSAGE"))
  }
  else{
    setValidationMessage(jsUtils.getLiteralValue("ARE_YOU_SURE_YOU_WANT_TO_DELETE"))
  }
  setConfirmAction("delete");
  if(data.timeEntryIds.length || data.draftTimeEntries.length || data.generalTimeEntryIds.length)
  {
  setDeleteData(data);
  setDisplayConfirmation(true);
  }
  else{
    dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES_CANNOT_BE_DELETED")), errorCode: 400 }));
  }
}
const onBulkEdit=(data,isTEValid)=>{
  setValidationMessage(jsUtils.getLiteralValue("EDIT_CHECKBOX_VALIDATION_MESSAGE"))
  setConfirmAction("edit");
  
  if(data.length){
    if(!isTEValid)
    { 
      editClick(data[0])
      setBulkEdit(true)
      setEditTimeEntryList(data);
      resetSelectToggle()
    }
    else {
      setEditTimeEntryList(data);
      setDisplayConfirmation(true)
    }

  }
  else{
    dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES_CANNOT_BE_EDITED")), errorCode: 400}));
  }
  // setDisplayEditConfirmation(true)
  setFunctionalityName("updateEachSelectedTE")
}

const onBulkUpdate=(data,isTEValid)=>{ 
  setValidationMessage(jsUtils.getLiteralValue("BULK_EDIT_CHECKBOX_VALIDATION_MESSAGE"))
  setConfirmAction("bulkChange");
  if(data.length ){
    if(isTEValid){
      setEditTimeEntryList(data);
      setDisplayConfirmation(true);
    } else {
      dispatch(setSelectedTimeEntries(data));
      //dispatch(setShowEditTimeEntryPage(true));
      sessionStorage.setItem("show-bulk-update-popup" , true);
      sessionStorage.setItem("navigated-from" , window.location.pathname);
      //history.push("/SureTime/ManageTime");
    }
  }
  else {
    dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES_CANNOT_BE_BULK_CHANGED")), errorCode: 400}));
  }   
}

const [validationPermissionMessage,setValidationPermissionMessage]=useState("");
const [showPermissionValidatePopup,setShowPermissionValidatePopup]=useState(false);
const [actionPermissionCheck,setActionPermissionCheck]=useState("");
const [disableConfirmButton,setDisableConfirmButton] = useState(false);
const [validationCheck, setValidationCheck ] =useState(false);
const [temporaryEntriesData,setTemporaryEntriesData ] =useState([]);
const [totalTECount, setTotalTECount] = useState(0);
const [operation, setOperation] = useState(null);

const onBulkTransfer=(data,isTEValid,showRegularEntryErrorPopup,showGeneralEntryErrorPopup)=>{

  setActionPermissionCheck("transfer");
  setValidationCheck(isTEValid);
  setTemporaryEntriesData(data);
  if(showRegularEntryErrorPopup || showGeneralEntryErrorPopup )
  {
    if(showRegularEntryErrorPopup && showGeneralEntryErrorPopup){ 
      setDisableConfirmButton(true);
      setValidationPermissionMessage("PERMISSION_RESTRICT_MESSAGE");
    }
    else{
      if(showRegularEntryErrorPopup){
        dispatch(setTransferFeatureName([PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME]))
      }
      else{
        dispatch(setTransferFeatureName([ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME]))
      }
      setValidationPermissionMessage("PERMISSION_REQUEST_MESSAGE");
      setDisableConfirmButton(false);
    }
      setShowPermissionValidatePopup(true);
  }
  
  else
  {

    setValidationMessage(jsUtils.getLiteralValue("TRANSFER_CHECKBOX_VALIDATION_MESSAGE"))
    setConfirmAction("transfer");
    if(data.length){

    let ethicalWallPayload={data:[]}
    let timeEntryIds = [];
      let draftTimeEntryIds = [];
      let generalTimeEntryIds = [];
      data.forEach(timeEntry => { 
        if(timeEntry.is_draft_time === "Y") {
          draftTimeEntryIds.push(timeEntry.timeEntryId);
          ethicalWallPayload.data.push({clientId:timeEntry.clientId,
          subClientId:timeEntry.subClientId,
          matterId:timeEntry.matterId,
          timeEntryId:timeEntry.timeEntryId})
        } else if(timeEntry.isGeneral){
          generalTimeEntryIds.push(timeEntry.timeEntryId);
          ethicalWallPayload.data.push({clientId:timeEntry.clientId,
            subClientId:timeEntry.subClientId,
            matterId:timeEntry.matterId,
            timeEntryId:timeEntry.timeEntryId})
        }
        else {
          timeEntryIds.push(timeEntry.timeEntryId);
          ethicalWallPayload.data.push({clientId:timeEntry.clientId,
            subClientId:timeEntry.subClientId,
            matterId:timeEntry.matterId,
            timeEntryId:timeEntry.timeEntryId})
        }
    });

    setLoading(true)
      manageTime.ethicalWallUsers(ethicalWallPayload).then((res)=>{
        setLoading(false);

              let filteredTransferList=[];
              let ethicalWallEntries=[]
              res.response && res.response.length>0 && res.response.forEach((item)=>{ethicalWallEntries.push(Number(Object.keys(item)[0]))});
              filteredTransferList= data.filter((item)=>ethicalWallEntries.indexOf(item.timeEntryId)===-1);
              
              if(filteredTransferList.length>0){
                timeEntryIds=filteredTransferList.filter((item)=>(!item.is_draft_time || item.is_draft_time !== "Y") && !item.isGeneral)?.map((itemdata)=>itemdata.timeEntryId) || [];
                draftTimeEntryIds=filteredTransferList.filter((item)=>{return item.is_draft_time && item.is_draft_time === "Y"})?.map((itemdata)=>itemdata.timeEntryId) || [];
                dispatch(setSelectedTimeEntries({ timeEntryIds: timeEntryIds, draftTimeEntryIds: draftTimeEntryIds,generalTimeEntryIds: generalTimeEntryIds }))


                if((timeEntryIds.length>0 || draftTimeEntryIds.length>0) && (generalTimeEntryIds.length>0)){
                  dispatch(setTransferFeatureName([ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME,PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME]))
                }
                else{
                  if((timeEntryIds.length>0 || draftTimeEntryIds.length>0))
                  {
                    dispatch(setTransferFeatureName([PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME]))
                  }
                  if(generalTimeEntryIds.length>0)
                  {
                    dispatch(setTransferFeatureName([ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME]))
                  }
                }


                if(isTEValid || ethicalWallEntries.length) {
                  setDisplayConfirmation(true)
                }
                else{
                  dispatch(setShowAttorneyListPopupFlag(true));
                }
                setOperation("transfer");
              }
              else {
                dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES_CANNOT_BE_TRANSFERED")), errorCode: 500, life : 7000 }));
              }
        

            }).catch((err)=>{
              setLoading(false)
              dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("Error")), errorCode: 500, life : 7000 }));
            })
        }

    else{
      dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES_CANNOT_BE_TRANSFERED")), errorCode: 400}));

    }
  }
}


const [loading, setLoading] = useState(false);
const [modeState, setModeState] = useState("");
const [deleteData, setDeleteData] = useState({ timeEntryIds: [], draftTimeEntries: [] });
const [releaseTimeentryList, setReleaseTimeentryList] = useState([]);
const [editTimeEntryList, setEditTimeEntryList] = useState([]);

const [closeAllCardsRefresh, setCloseAllCardsRefresh] = useState(0)
const [loadingCard, setLoadingCard] = useState(false)
const [bulkEdit, setBulkEdit] = useState(false)

const[moveScrollToTop , setMoveScrollToTop] = useState(false);
const[editPosition,setEditPosition] = useState(0);
const [validationMessage,setValidationMessage]=useState("")
const [confirmAction,setConfirmAction]=useState("")

const [selectedRowData, setSelectedRowData] = useState({
  selectedClient: { id: "", description: "", code: "" },
  selectedMatter: { id: "", description: "", code: "" },
  selectedSubClient: { id: "", description: "", code: "" },
  billableDuration: "",
  time: "00:00:00",
  timeEntryId: null,
  date: "",
  timeEntryDetail: [],
  errors: null,
  narrativeId: "",
  narrative: "",
  is_draft_time: ""
});

const [DataFromAPI, setDataFromAPI] = useState([]);
const [DataFromAPIBackup, setDataFromAPIBackup] = useState([]);

const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });

const [checkAll, setCheckAll] = useState(false);

/**
  *  the state is updated with the new filters
  * @param {String} key -  column name
  * @param {String} value - searched text or toggle value
  * @param {Number} index -index of the filter column 
  */
let setScrollToTop = () => {
  setMoveScrollToTop(true);
}
/*
* removes all the filters from grid and adds default values
*/
let resetFilters = () => {
  setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
  sort.index = -1;
  setSort({ index: -1, isAsc: true, propName: "" });
  setScrollToTop();
};

const [showAddTimeDialog, setShowAddTimeDialog] = useState(false);
const [showGeneralTimeDialog, setShowGeneralTimeDialog] = useState(false);

const getFeatureNames=(list,userId)=>{
  let featureNames=[];
  list.forEach((item)=>{
    if(item.userDelegateId===userId){
      featureNames.push(item.featureName)
    }
  })
  return featureNames;
}


useEffect(()=> {
    //clean up
    return(() => {
      setOperation(null);
      setEntriesInfo("");
      dispatch(setWoboAction({ action: ""}));
    });
}, []);


const getAccessInfo=(generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,addTimeFeaturePermission,addTimeLoggedInUserPermission,isLoggedInUser,generalTimeEntryPresent,regularTimeEntryPresent)=>{
  let regularEntryRole=true;
  let generalEntryRole=true;
  if(isLoggedInUser){
    if(!generalTimeRolePermissionLoggedInUser && generalTimeEntryPresent){
      generalEntryRole=false;
    }
    if(!addTimeLoggedInUserPermission && regularTimeEntryPresent){
      regularEntryRole=false;
    }
  }
  else{
    if(!addTimeFeaturePermission && regularTimeEntryPresent ){
      regularEntryRole=false
    }
    if(!generalTimeFeaturePermission && generalTimeEntryPresent){
      generalEntryRole=false
    }
  }
  return {regularEntryActionable:regularEntryRole,generalEntryActionable:generalEntryRole}
}


const getMenuItems=()=>{
  
  let generalTimeEntryPresent=DataFromAPI.findIndex((item)=>item.isChecked && item.isGeneral===true)!== -1 || false;
  let regularTimeEntryPresent=DataFromAPI.findIndex((item)=>item.isChecked && item.isGeneral===false)!== -1 || false;

  const permissionsUser = permissionsOfUser?.find( (item) => item.id==="managetimegrid" );
  const releasePermission = permissionsUser?.fields.find((item)=>item.id ==="releasetime")?.actionable;
  const editPermission = permissionsUser?.fields.find((item)=>item.id ==="edittime")?.actionable;
  const clonePermission = permissionsUser?.fields.find((item)=>item.id ==="clonetime")?.actionable;
  const deletePermission = permissionsUser?.fields.find((item)=>item.id ==="deletetime")?.actionable;

  const loggedInUserAddTimePermissionCombined = editPermission && clonePermission && deletePermission;
  let featureList=getFeatureNames(timeKeeperList,delegateId) || [];
  
  const generalTimeRolePermissionLoggedInUser = permissionsOfUser?.find((item) => item.id==="generaltimeentries")?.fields.find((item)=>item.id ==="generaltimentry")?.actionable || false;
  const generalTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME)!==-1;
  const addTimeFeaturePermission=featureList.findIndex((item)=>item===PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME) !==-1;
  const releaseTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME)!==-1;

  let {regularEntryActionable,generalEntryActionable} = getAccessInfo(generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,addTimeFeaturePermission ,editPermission,timeKeeperList[0]?.userDelegateId === delegateId,generalTimeEntryPresent,regularTimeEntryPresent)
  let _selectedTimeEntries = DataFromAPI.filter(item => item.isChecked ) || [];
          const ManageTimeItems= [
            {
              key:"showCount",
              label: `${jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES")} ${_selectedTimeEntries.length}`,
              showMe: true,
              className:"show-count"
          },{
            key:"Update",
            label: jsUtils.getLiteralValue("UPDATE_EACH_SELECTED_TIME_ENTRY"),
            icon: 'icon-icon_edit',
            showMe:timeKeeperList[0]?.userDelegateId === delegateId? regularTimeEntryPresent && editPermission: regularTimeEntryPresent && addTimeFeaturePermission,
            command: () => {
                let selectedEntries=[];
                let isTEValid=false;
                DataFromAPI.forEach((item) => {
                    if(item.isChecked ){
                      if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && item.isChecked && !item.ethicalWallFlag && !item.isGeneral) {
                        
                         selectedEntries.push(item); 
                        
                      }
                      else{
                        isTEValid=true;
                      }
                    }
                    });
                onBulkEdit(selectedEntries,isTEValid)
            }
        },
        {
            key:"Bulk Update",
            label: jsUtils.getLiteralValue("BULK_CHANGE_SELECTED_TIME_ENTRIES"),
            icon: 'icon-icon_bulkupdatetimeentry',
            showMe:timeKeeperList[0]?.userDelegateId === delegateId? regularTimeEntryPresent && editPermission: regularTimeEntryPresent && addTimeFeaturePermission,
            command: () => {
                let selectedEntries=[];
                let isTEValid=false;
                DataFromAPI.forEach((item) => {
                    //general timeentry validation to be added
                    if(item.isChecked ){
                      if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && !item.ethicalWallFlag && !item.isGeneral) {
                        regularEntryActionable && selectedEntries.push(item); 
                      }
                      else{
                        isTEValid=true;
                      }
                    }
                    });
                onBulkUpdate(selectedEntries,isTEValid);
            }
        },
        {
            key:"Delete",
            label: jsUtils.getLiteralValue('DELETE_SELECTED_TIME_ENTRIES'),
            icon: 'icon-icon_trash',
            showMe:timeKeeperList[0]?.userDelegateId === delegateId? (generalTimeRolePermissionLoggedInUser && generalTimeEntryPresent) || (regularTimeEntryPresent && deletePermission): (generalTimeFeaturePermission && generalTimeEntryPresent) || (addTimeFeaturePermission && regularTimeEntryPresent),
            className:"lineSeparator",
            command: () => {
                let selectedEntries={timeEntryIds:[],draftTimeEntries:[],generalTimeEntryIds:[]};
                let isTEValid=false;
                DataFromAPI.forEach((item) => {
                    if(item.isChecked ){
                      if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid" )) {    
                        if(!item.isGeneral)   
                        {
                          item.is_draft_time ==="Y"? regularEntryActionable && selectedEntries.draftTimeEntries.push(item.timeEntryId):regularEntryActionable && selectedEntries.timeEntryIds.push(item.timeEntryId);
                        } 
                        else
                        {
                          generalEntryActionable && selectedEntries.generalTimeEntryIds.push(item.timeEntryId);
                        }                        
                      }
                      else{
                        isTEValid=true
                      }
                    }
                    });
                onBulkDelete(selectedEntries,isTEValid)
            }
        },
        {
            key:"Release",
            label: jsUtils.getLiteralValue('RELEASE_SELECTED_TIME_ENTRIES'),
            icon: 'icon-icon_release',
            showMe:timeKeeperList[0]?.userDelegateId === delegateId ? releasePermission && regularTimeEntryPresent : releaseTimeFeaturePermission && regularTimeEntryPresent,
            className:"lineSeparator",
            command: () => {
                let selectedEntries=[];
                let isTEValid=false;
                DataFromAPI.forEach((item) => {
                    //general timeentry validation to be added
                    if(item.isChecked ){
                      if ((item.status.toLowerCase() === "ready") && !item.isGeneral) {
                            selectedEntries.push(item.timeEntryId);
                      }
                      else{
                        isTEValid=true;
                      }
                    }                          
                    });
                onBulkRelease(selectedEntries,isTEValid)
            }
        },
        
        {
            key:"Combine",
            label: jsUtils.getLiteralValue('COMBINE_SELECTED_TIME_ENTRIES'),
            icon: 'icon-icon-combineTimeEntry',
            showMe:timeKeeperList[0]?.userDelegateId === delegateId? (generalTimeRolePermissionLoggedInUser && generalTimeEntryPresent) || (regularTimeEntryPresent && loggedInUserAddTimePermissionCombined ): (generalTimeFeaturePermission && generalTimeEntryPresent) || (addTimeFeaturePermission && regularTimeEntryPresent),
            className:"lineSeparator",
            command: () => {
                let selectedEntries=[];
                let isTEValid=false;
                DataFromAPI.forEach((item) => {
                    //general timeentry validation to be added
                    if(item.isChecked ){
                      if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && item.isChecked && !item.ethicalWallFlag ) {
                          if(!item.isGeneral){
                          regularEntryActionable && selectedEntries.push(item);
                          }
                          else{
                            generalEntryActionable && selectedEntries.push(item);
                          }
                        }
                        else{
                          isTEValid=true;
                        }
                    }               
                    });
                onCombineClick(selectedEntries,isTEValid,!regularEntryActionable && regularTimeEntryPresent)
            }
        },
        {   
            key:"Transfer",
            label: jsUtils.getLiteralValue('TRANSFER_SELECTED_TIME_ENTRIES'),
            icon: 'icon-icon-transfertimeentry',
            showMe:timeKeeperList[0]?.userDelegateId === delegateId? (generalTimeRolePermissionLoggedInUser && generalTimeEntryPresent) || (regularTimeEntryPresent && loggedInUserAddTimePermissionCombined ): (generalTimeFeaturePermission && generalTimeEntryPresent) || (addTimeFeaturePermission && regularTimeEntryPresent),
            command: () => {
                let selectedEntries=[];
                let isTEValid=false;
                DataFromAPI.forEach((item) => {
                    //general timeentry validation to be added
                    if(item.isChecked ){
                      if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && !item.ethicalWallFlag ) {
                          
                        if(!item.isGeneral){
                          regularEntryActionable && selectedEntries.push(item);
                          }
                          else{
                            selectedEntries.push(item);
                          }
                      }
                        else
                        {
                          isTEValid=true;
                        }
                          
                    }                     
                    });

                onBulkTransfer(selectedEntries,isTEValid,!regularEntryActionable && regularTimeEntryPresent,!generalEntryActionable && generalTimeEntryPresent);
            }
        },
        {
            key:"ExportExcel",
            label: jsUtils.getLiteralValue('EXPORT_SELECTED_TIME_ENTRIES_TO_EXCEL'),
            icon: 'icon-export_excel iconmargin',
            showMe:true,
            command: () => {
                let selectedEntries=[];

                DataFromAPI.forEach((item) => {
                      if (item.isChecked) {

                          selectedEntries.push(item.timeEntryId);
                      
                      }                         
                    });
                onExportClick(selectedEntries,"excel")
            }
        },
        {
            key:"ExportCSV",
            label: jsUtils.getLiteralValue('EXPORT_SELECTED_TIME_ENTRIES_TO_CSV'),
            icon: 'icon-export_csv iconmargin',
            showMe:true,
            command: () => {
                let selectedEntries=[];
                DataFromAPI.forEach((item) => {
                      if (item.isChecked) {

                          selectedEntries.push(item.timeEntryId);
                        
                      }                         
                    });
                onExportClick(selectedEntries,"csv")
            }
        },
        {
            key:"ExportPdf",
            label: jsUtils.getLiteralValue('EXPORT_SELECTED_TIME_ENTRIES_TO_PDF'),
            icon: 'icon-export_pdf iconmargin',
            showMe:true,
            command: () => {
                let selectedEntries=[];

                DataFromAPI.forEach((item) => {
                      if (item.isChecked) {

                          selectedEntries.push(item.timeEntryId);
                      
                      }                         
                    });
                onExportClick(selectedEntries,"pdf")
            }
        }]

  return ManageTimeItems.filter((item)=>item.showMe===true);
      
}



const toast = useRef(null);
const [displayConfirmation, setDisplayConfirmation] = useState(false);
const [processedCombineList,setProcessedCombineList] = useState([]);
const [displayCombineConfirmation,setDisplayCombineConfirmation] = useState(false);
const [selectedListData,setSelectedListData] = useState(null);


const [spellCheckToggle,setSpellCheckToggle] = useState(false);
const [combineEntries,setCombineEntries]=useState({timeEntryIds:[]});
//SEBI-1394 commented as it was making duplicate api call
// useEffect(() => {
//     const action = Preferences.getPreferenceSettings();
//     dispatch(action);
// }, [props.match.params.MODE]);

const getSubValidatedColumns=(_columns,_hasMultipleSubClients)=>{
    let listOfColumns=_columns;
    let subIndex = listOfColumns.findIndex((item)=>item.label.toUpperCase()==="SUB");
    if(subIndex!==-1){
      listOfColumns[subIndex]["disable"]=!_hasMultipleSubClients;
    }
    return listOfColumns;
}

const setColumnsOnScreenLoad=(screenName,_preferenceApiData,_hasMultipleSubClients)=>{
  let finalColDef=[];
  let savedPreferenceColumns=[];
  let columns=[];
  if(screenName && screenName.toLowerCase()==="managetime")
  {
   finalColDef = MTColDef;
  savedPreferenceColumns=_preferenceApiData?.find((item)=>item.param_key==="MANAGE_TIME_COLUMNS");
  }
  else{
   finalColDef = DayViewColDef;
   savedPreferenceColumns=_preferenceApiData?.find((item)=>item.param_key==="DAY_VIEW_COLUMNS");
  }

  if(!_hasMultipleSubClients) {
    finalColDef = finalColDef.filter(item => item.column.label && item.column.label.toUpperCase() !== "SUB");
    
  }

  if(savedPreferenceColumns){

      try{
        columns=JSON.parse(savedPreferenceColumns.param_value);
      }
      catch(err){
        dispatch(setErrorMessage({severity: "error",
        message: "columns error",
        errorCode: 500,}))
      }
      if(columns && Array.isArray(columns.columnData)){
        let listOfColumns=getSubValidatedColumns(columns.columnData,_hasMultipleSubClients);
        setColumnsData([...listOfColumns]);
         // as the state does not update when we reference the same array. we are strigifying and parsing it to make sure the state updates with the updated value
        let backupArrayString=JSON.stringify([...listOfColumns])
        let backupArrayParsed=JSON.parse(backupArrayString);
        setColumnsDataBackup(backupArrayParsed);

        let sortedColumnDef=jsUtils.getValidatedColumns(finalColDef,listOfColumns);
        sortedColumnDef=screenName==="managetime"?[MTColDef[0],...sortedColumnDef,MTColDef[MTColDef.length-1]]:[DayViewColDef[0],...sortedColumnDef,DayViewColDef[DayViewColDef.length-1]]
        setColDef(sortedColumnDef);
      }
    }
    else{
      if(screenName==="managetime")
      {
      setColumnsData([...ManageTimeDefaultColumns]);
      setColumnsDataBackup([...ManageTimeDefaultColumns]);
      }
      else{
      setColumnsData([...DayViewDefaultColumns]);
      setColumnsDataBackup([...DayViewDefaultColumns]);
      }
      setColDef(finalColDef);
    }
}

useEffect(() => {
  let permissions = permissionsOfUser.find((item) => item.id === "practice");
  if (preferenceApiData ) {
    if(permissions){
    let spellCheck_setting = preferenceApiData.find(
      (preference) => preference.param_key === "SPELL_CHECK"
    );
      if (spellCheck_setting) {
          let spell_values = spellCheck_setting.param_value;
          let spell_obj = JSON.parse(spell_values);
          setSpellCheckToggle(spell_obj.spell_check_toggle ? spell_obj.spell_check_toggle : false);
      }
    }
    setColumnsOnScreenLoad(props.match.params.MODE,preferenceApiData,hasMultipleSubClients)
  }
},[preferenceApiData,hasMultipleSubClients,props.match.params.MODE]); 

const onChange=()=>{
  if(confirmAction==="edit"){
    
    setBulkEdit(true)
        editClick(editTimeEntryList[0])
        resetSelectToggle();
        setDisplayConfirmation(false)
    
  }
  else if(confirmAction==="bulkChange"){
    dispatch(setSelectedTimeEntries(editTimeEntryList));
        dispatch(setShowEditTimeEntryPage(true));
        sessionStorage.setItem("show-bulk-update-popup" , true);
      sessionStorage.setItem("navigated-from" , window.location.pathname);  
      setDisplayConfirmation(false)
  }
  else if(confirmAction==="delete"){
    deleteTimeEntry(deleteData);
    setDisplayConfirmation(false)
  }
  else if(confirmAction==="release"){
    releaseClick(null, releaseTimeentryList);
    setDisplayConfirmation(false)
  }
  else if(confirmAction==="combine"){
    onCombineClick(combineEntries,false,false);
    setDisplayConfirmation(false); 
  }
  else if(confirmAction==="transfer"){
    dispatch(setShowAttorneyListPopupFlag(true));
    setDisplayConfirmation(false)
  }
}

const onTraverseNext = (rowData) => {
  editClick(rowData)
}
/**
 * open the edit time entry dialog popup and populate with the selected current timeentry fields and values
 * @param {Object} rowData - current selected rowdata 
  */
const editClick = (rowData) => {
  
  // Gneral Time Entry
  if(rowData.isGeneral) {
    let narrative = rowData.narrative;
    if (rowData.narrative && rowData.ethicalWallFlag) {
      let value = storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"]
      narrative = rowData.narrative.replaceAll("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS", value)
    }
    let selectedRowGte = {
      clientId: rowData.clientId,
      subClientId: rowData.subClientId,
      matterId: rowData.matterId,
      billableDuration: rowData.billableDuration,
      timerStartDatetime: rowData.date,
      time:
        rowData.recordedDurationHours.toString() +
        ":" +
        rowData.recordedDurationMinutes.toString(),
      timeEntryId: rowData.timeEntryId,
      amount: rowData.amount,
      narrative: narrative,
      narrativeId: rowData.narrativeId,
      timeEntryDetail: rowData.timeEntryDetail,
      errors: rowData.errors,
      is_draft_time: rowData.is_draft_time,
      ethicalWallFlag: rowData.ethicalWallFlag,
      attachments: rowData?.tme_attchements?.map(a => {
        return {
          ...a,
          'title': a.filename,
          'created_on': a.created_date && moment(new Date(a.created_date).toISOString()).format("MM/DD/YYYY"),
          'put_signed_url': a.tm_timeentry_attachment_url,
          'attachment_id': a.tm_timeentry_attachment_id,
          'dms_source': a.s3_filename
        }
      })
    };
    setSelectedRowData(selectedRowGte);
    setShowGeneralTimeDialog(true);
  }
  else{
  let narrative = rowData.narrative;
  if (rowData.narrative && rowData.ethicalWallFlag) {
    let value = storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"]
    narrative = rowData.narrative.replaceAll("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS", value)
  }
  let selectedRow = {
    selectedClient: {
      id: rowData.clientId,
      description: rowData.clientName === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"] : rowData.clientName,
      code: rowData.clientNumber,
    },
    selectedMatter: {
      id: rowData.matterId,
      description: rowData.matterName === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"] : rowData.matterName,
      code: rowData.matterNumber,
    },

    selectedSubClient: {
      id: rowData.subClientId,
      description: rowData.subClientName,
      code: rowData.subClient,
    },
    billableDuration: rowData.billableDuration,
    timerStartDatetime: rowData.date,
    time:
      rowData.recordedDurationHours.toString() +
      ":" +
      rowData.recordedDurationMinutes.toString(),
    timeEntryId: rowData.timeEntryId,
    amount: rowData.amount,
    narrative: narrative,
    narrativeId: rowData.narrativeId,
    timeEntryDetail: rowData.timeEntryDetail,
    errors: rowData.errors,
    is_draft_time: rowData.is_draft_time,
    ethicalWallFlag: rowData.ethicalWallFlag,
    status:rowData.status
  };
  setShowAddTimeDialog(true);

  document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_TIME_ENTRY");
  if (rowData && rowData.status && rowData.status.toString().toLowerCase() === "invalid" && rowData.is_draft_time !== 'Y') {
    let timeEntryId = rowData.timeEntryId;
    let narrativeId = rowData.narrativeId;
    manageTime.getUpdatedInvalidTimeEntry(timeEntryId, delegateId, narrativeId, rowData.ethicalWallFlag || 0, spellCheckToggle).then((res) => {
      let narrativeCompliance = res.narrative;
      if (res.narrative && rowData.ethicalWallFlag) {
        let value = storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"]
        narrativeCompliance = res.narrative.replaceAll("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS", value)
      }
      
      setSelectedRowData({
        selectedClient: {
          id: res.clientId,
          description: res.clientName === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"] : res.clientName,
          code: res.clientNumber,
        },
        selectedMatter: {
          id: res.matterId,
          description: res.matterName === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"] : res.matterName,
          code: res.matterNumber,
        },

        selectedSubClient: {
          id: res.subClientId,
          description: res.subClientName === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"] : res.subClientName === null ? "" : res.subClientName,
          code: res.subClient,
        },
        billableDuration: res.billableDuration,
        timerStartDatetime: res.date,
        time: "0:0",
        timeEntryId: res.timeEntryId,
        amount: res.amount,
        narrative: narrativeCompliance,
        narrativeId: res.narrativeId,
        timeEntryDetail: res.timeEntryDetail,
        errors: res.errors,
        is_draft_time: res.is_draft_time,
        ethicalWallFlag: rowData.ethicalWallFlag
      });
    }).catch((err) => {
      setSelectedRowData(selectedRow)
    })
  }
  else {
    setShowAddTimeDialog(true);
    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_TIME_ENTRY");
    setSelectedRowData(selectedRow);
  }

  
}
setModeState("edit");
};

/**
* open the clone time entry dialog popup and populate with the selected current timeentry fields and values
 * @param {Object} rowData - current selected rowdata 
 */
const cloneClick = (rowData) => {
  if(rowData.isGeneral) {
    let narrative = rowData.narrative;
    if (rowData.narrative && rowData.ethicalWallFlag) {
      let value = storage.getObject("literals")["INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS"]
      narrative = rowData.narrative.replaceAll("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS", value)
    }
    let selectedRowGte = {
      clientId: rowData.clientId,
      subClientId: rowData.subClientId,
      matterId: rowData.matterId,
      billableDuration: rowData.billableDuration,
      timerStartDatetime: rowData.date,
      time:
        rowData.recordedDurationHours.toString() +
        ":" +
        rowData.recordedDurationMinutes.toString(),
      timeEntryId: rowData.timeEntryId,
      amount: rowData.amount,
      narrative: narrative,
      narrativeId: rowData.narrativeId,
      timeEntryDetail: rowData.timeEntryDetail,
      errors: rowData.errors,
      is_draft_time: rowData.is_draft_time,
      ethicalWallFlag: rowData.ethicalWallFlag,
      attachments: [],
    };
    setSelectedRowData(selectedRowGte);
    setShowGeneralTimeDialog(true);
  }
  else{

  setShowAddTimeDialog(true);
  document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_TIME_ENTRY");
  setSelectedRowData({
    selectedClient: {
      id: rowData.clientId,
      description: rowData.clientName,
      code: rowData.clientNumber,
    },
    selectedMatter: {
      id: rowData.matterId,
      description: rowData.matterName,
      code: rowData.matterNumber,
    },

    selectedSubClient: {
      id: rowData.subClientId,
      description: rowData.subClientName,
      code: rowData.subClient,
    },
    billableDuration: rowData.billableDuration,
    timerStartDatetime: rowData.date,
    time:
      rowData.recordedDurationHours.toString() +
      ":" +
      rowData.recordedDurationMinutes.toString(),
    timeEntryId: rowData.timeEntryId,
    amount: rowData.amount,
    narrative: rowData.narrative,
    narrativeId: rowData.narrativeId,
    timeEntryDetail: rowData.timeEntryDetail,
    is_draft_time: rowData.is_draft_time,
    status: rowData.status
  });
}
  setModeState("clone");
};

/**
* delete the selected time entry
 * @param {Object} rowData - current selected rowdata 
 */
const deleteTimeEntry = (deletePayload) => {
  let payload = {}
  let deletedTimeEntriesCount = 0;
  if(deletePayload.draftTimeEntries.length > 0)
  {
    payload["draftTimeEntries"]=deletePayload.draftTimeEntries;
    deletedTimeEntriesCount = deletePayload.draftTimeEntries.length;
  }
  if(deletePayload.timeEntryIds.length > 0)
  {
    payload["timeEntryIds"]=deletePayload.timeEntryIds;
    deletedTimeEntriesCount += deletePayload.timeEntryIds.length;
  }
  if(deletePayload.generalTimeEntryIds.length > 0)
  {
    payload["generalTimeEntryIds"]=deletePayload.generalTimeEntryIds;
    deletedTimeEntriesCount += deletePayload.generalTimeEntryIds.length;
  }
  setLoading(true);
  manageTime
    .delete(payload)
    .then((res) => {

      dispatch(setErrorMessage({ severity: 'success', message: (res && jsUtils.getLiteralValue(res.message)), errorCode: res.responseCode, life: 7000 }));
      //setLoading(false);
      setCheckBoxRefreshCounter(0);
      // loadData(true, searchCriteria);
      setScrollToTop();
      setGridReset(gridReset + 1);
      setOperation("delete");
      setTotalTECount(totalTECount-deletedTimeEntriesCount);
    })
    .catch((err) => {
      setLoading(false);
      dispatch(setErrorMessage({ severity: 'error', message: (err && jsUtils.getLiteralValue(err.message)), errorCode: err.responseCode }));
    });
};

/**
 * release the selected time entry
  * @param {Object} rowData - current selected rowdata 
  * @param {Array} selectedRelease - array of release entry rowids
  */
const releaseClick = (rowData, selectedRelease, _searchCriteria) => {
  setLoading(true);
  let payload = {};
  if (rowData) {
    payload = {
      delegateUserId: delegateId,
      mode: "release",
      timeEntryIds: [rowData.timeEntryId],
      clientId: rowData.clientId,
      matterId: rowData.matterId,
      subClientId: rowData.subClientId,
      selectAll: false,
    };
  } else {
    if (selectedRelease.length > 0) {
      payload = {
        delegateUserId: delegateId,
        mode: "release",
        timeEntryIds: selectedRelease,
        selectAll: false,
      };
    } else {
      payload = {
        delegateUserId: delegateId,
        mode: "release",
        timeEntryIds: [],
        selectAll: false,
      };
    }
  }

  manageTime
    .post(payload)
    .then((res) => {

      dispatch(setErrorMessage({ severity: 'success', message: (res && jsUtils.getLiteralValue(res.message)), errorCode: res.responseCode, life: 7000 }));
      // setLoading(false);
      //loadData(true, _searchCriteria);
      setScrollToTop();
      setCheckBoxRefreshCounter(0);
      setGridReset(gridReset + 1);
    })
    .catch((err) => {
      setLoading(false);
      if (err.message && Array.isArray(err.message)) {
        dispatch(setErrorMessage({ severity: 'error', message: (err && err.message[0] && err.message[0].message && jsUtils.getLiteralValue(err.message[0].message)), errorCode: err.responseCode }));
      }
      else {
        dispatch(setErrorMessage({ severity: 'error', message: (err && err.message && jsUtils.getLiteralValue(err.message)), errorCode: err.responseCode }));

      }
    });
};

const finalCombineCall = () => {
  setLoading(true);
  let entries=combineEntries;
  entries["delegateUserId"] = delegateId;
  entries["combineTo"] = selectedListData?.timeEntryId;
  dayViewApi.combineTE(entries).then((res) => {
    setOperation("combine");
    dispatch(setErrorMessage({ severity: 'success', message: (res && jsUtils.getLiteralValue(res.message)), errorCode: res.responseCode, life: 7000 }));
    //loadData(true, searchCriteria);
    setSelectedListData(null)
    setScrollToTop();
    setCheckBoxRefreshCounter(0);
    setGridReset(gridReset + 1);
    setTotalTECount(totalTECount-( getDeductedTimeEntries(combineEntries) -1));
  }).catch((err) => {
    setLoading(false);
    dispatch(setErrorMessage({ severity: 'error', message: (err && jsUtils.getLiteralValue(err.message)), errorCode: err.responseCode, life: 7000 }));

  })
}
const onLoadingcard = (loadCard) => {
  setLoadingCard(loadCard)
}


const { showAttorneyListPopup, transferTE } = useSelector(
  ({ DayViewReducer }) => ({
    showAttorneyListPopup: DayViewReducer.showAttorneyListPopup,
    transferTE: DayViewReducer.transferTE
  })
);

const getDeductedTimeEntries =(payload) => {
  let deductedTimeEntries = 0;
  if(payload.draftTimeEntryIds.length > 0)
  {
    deductedTimeEntries = payload.draftTimeEntryIds.length;
  }
  if(payload.timeEntryIds.length > 0)
  {
    deductedTimeEntries += payload.timeEntryIds.length;
  }
  if(payload.generalTimeEntryIds.length > 0)
  {
    deductedTimeEntries += payload.generalTimeEntryIds.length;
  }
  return deductedTimeEntries;
}

useEffect(() => {
  if (transferTE && transferTE.status === "successful") {
    //loadData(true, searchCriteria);
    setCheckBoxRefreshCounter(0);
    setScrollToTop();
    setGridReset(gridReset + 1);
    setOperation("transfer");
    setTotalTECount(totalTECount - getDeductedTimeEntries(selectedTimeEntries));
  }
},[dispatch, transferTE]);

const onTransferClick =(entry) => {
dispatch(setEthicalWallUsers([]));
    let ethicalWallPayload={data:[{clientId:entry.clientId,
      subClientId:entry.subClientId,
      matterId:entry.matterId,
      timeEntryId:entry.timeEntryId}]}

    let timeEntryIds = [];
    let draftTimeEntryIds = [];
    let generalTimeEntryIds=[];
    if(entry.is_draft_time && entry.is_draft_time === "Y"){ 
      draftTimeEntryIds.push(entry.timeEntryId)
    }
    else{
      if(!entry.isGeneral){
        timeEntryIds.push(entry.timeEntryId)
      }
      else{
        generalTimeEntryIds.push(entry.timeEntryId)
      }
    }
    if(!entry.isGeneral){ 
      dispatch(setTransferFeatureName([PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME]))
    }
    else{
      dispatch(setTransferFeatureName([ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME]))
    }
  setLoading(true)
  manageTime.ethicalWallUsers(ethicalWallPayload).then((res)=>{
  setLoading(false);
  dispatch(setSelectedTimeEntries({ timeEntryIds: timeEntryIds, draftTimeEntryIds: draftTimeEntryIds ,generalTimeEntryIds: generalTimeEntryIds}))  
  res.response && res.response[0] && dispatch(setEthicalWallUsers(Object.values(res.response[0])[0]));
  dispatch(setShowAttorneyListPopupFlag(true));
      
}).catch((err)=>{
  setLoading(false)
  dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("Error")), errorCode: 500, life : 7000 }));

})

}

const onCombineClick=(entries,isTEValid,showRegularEntryErrorPopup)=>{
  setActionPermissionCheck("combine");
  setConfirmAction("combine");
  setCombineEntries(entries)
  if(showRegularEntryErrorPopup)
  {
    setValidationPermissionMessage("PERMISSION_RESTRICT_MESSAGE");
     setDisableConfirmButton(true);
    setShowPermissionValidatePopup(true);
  }
  else{

    if(isTEValid){
      setValidationMessage(jsUtils.getLiteralValue("COMBINE_CHECKBOX_VALIDATION_MESSAGE"))
      setDisplayConfirmation(true);
    }
    else{
      if(entries && entries.length>1){
        let entry =entries[0];
        let allMatch=true;
        entries.forEach((combineEntry)=>{
          if(allMatch && (combineEntry.clientId!==entry.clientId || combineEntry.matterId!==entry.matterId || combineEntry.subClientId!==entry.subClientId || combineEntry.date!==entry.date)){
            allMatch=false;
          }
        })

        if(allMatch){
          let generalTimeEntryIds=entries.filter((item)=>item.isGeneral===true)?.map((itemdata)=>itemdata.timeEntryId) || [];
          let regularTimeEntryIds=entries.filter((item)=>!item.isGeneral && (!item.is_draft_time || item.is_draft_time !== "Y"))?.map((itemdata)=>itemdata.timeEntryId) || [];
          let draftTimeEntryIds=entries.filter((item)=>{return item.is_draft_time && item.is_draft_time === "Y"})?.map((itemdata)=>itemdata.timeEntryId) || [];
          let attachmentsCount=0;
          
          if(generalTimeEntryIds.length)
          {
            entries.forEach((item)=>{
              attachmentsCount=attachmentsCount + item.tme_attchements.length;
            })
          }
          if(generalTimeEntryIds.length && (attachmentsCount>MAX_ATTACHMENT_LIMIT))
          {
            setValidationPermissionMessage("ATTACHMENT_LIMIT_VALIDATION_MESSAGE");
            setDisableConfirmButton(true);
            setShowPermissionValidatePopup(true);
          }
          else{
          let processedList=[]
          entries.forEach((item)=>{
            let singleTimeEntry={"timeEntryId":item.timeEntryId};
            item.timeEntryDetail.forEach((code)=>{
              singleTimeEntry[code.fieldName]=code?.values?.display_value;
              singleTimeEntry["header"]=jsUtils.getLiteralValue(code.fieldName);
            })
            processedList.push(singleTimeEntry);
          }) 
          setProcessedCombineList(processedList);
          setCombineEntries({draftTimeEntryIds:draftTimeEntryIds,timeEntryIds:regularTimeEntryIds,generalTimeEntryIds:generalTimeEntryIds})
          setDisplayCombineConfirmation(true);
          }
        }
        else {
            dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES_DO_NOT_MATCH")), errorCode: 500, life: 7000 }));
        }
      }
      else{
        
          dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES_CANNOT_BE_COMBINED")), errorCode: 500, life : 7000 }));

      }

    }
  }
}

const onExportClick=(entries,type)=>{
  setLoading(true)
  let payload = {
    timeEntryIds: entries,
    delegateUserId: delegateId,
  }
  type === "excel" ? payload["type"] = "exl" : type === "pdf" ? payload["type"] = "pdf" : payload["type"] = "csv"
  manageTime.export(payload).then((res) => {
    setLoading(false);
    res && res.message && res.message.Payload && window.downloadAttachment(JSON.parse(res.message.Payload), "export")
  }).catch((err) => {
    setLoading(false)
    dispatch(setErrorMessage({ severity: 'error', message: (jsUtils.getLiteralValue("EXPORT_FAILED")), errorCode: 500, life: 7000 }));
  })
}

const onPermissionCheck=()=>{
  setShowPermissionValidatePopup(false);
  if(actionPermissionCheck==="transfer"){
    onBulkTransfer(temporaryEntriesData,validationCheck,false,false);
  }
}

const closePopupBasedOnPreference = (afterSavingPreference) => {
  if(afterSavingPreference === "keep_te_open") {
    return;
  } else if(afterSavingPreference === "return_to_addTime") {
    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME"); 
    history.push("/SureTime/AddTime");
    setShowGeneralTimeDialog(false);
  } else if(afterSavingPreference === "show_manageTime") {
    if(props.match.params.MODE && props.match.params.MODE.toLowerCase() === "dayview") {        
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME");  
      history.push("/SureTime/ManageTime");  
    }
   
    setShowGeneralTimeDialog(false);
  }                           
  setShowAddTimeDialog(false);
  setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
  resetFilters();
  setScrollToTop();
  setBulkEdit(false);
  setEditPosition(0);
  setEditTimeEntryList([]);
  setCheckBoxRefreshCounter(0); 
  
}

const onSaveHandlerAddTime = (afterSavingPreference) => {
  if(afterSavingPreference === "keep_te_open") {
    return;
  } else if(afterSavingPreference === "show_manageTime"){       
    if(props.match.params.MODE && props.match.params.MODE.toLowerCase() === "dayview") {        
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME");  
      history.push("/SureTime/ManageTime");  
    } 
  } else if(afterSavingPreference === "return_to_addTime") {
    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME")
    history.push("/SureTime/AddTime");
  }
   setShowAddTimeDialog(false);
   setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
   resetFilters();
   setScrollToTop();
   setBulkEdit(false);
   setEditPosition(0);
   setEditTimeEntryList([]);
   setCheckBoxRefreshCounter(0);
   setGridReset(gridReset+1)

}


  const getTitleClassNameDisable =(action,rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)=>{
    if(action==="edit" || action==="transfer")
    {
      if(rowItem.status.toLowerCase()==="posted" || rowItem.status.toLowerCase()==="released" || rowItem.status.toLowerCase()==="completed"){
        return true;
      } 
      else {
        if((rowItem.isGeneral && (generalTimeRolePermissionLoggedInUser || generalTimeFeaturePermission)) || (!rowItem.isGeneral && !rowItem.ethicalWallFlag && editPermission )){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(action==="clone"){
      return !(!rowItem.ethicalWallFlag && clonePermission );
    }
    else if(action==="delete")    
    {
      if(rowItem.status.toLowerCase()!=="invalid" && rowItem.status.toLowerCase()!=="ready"){
        return true;
      }
      else{
        return !deletePermission;
      }
    }        
  }



  useEffect(()=>{

    const permissionsUser = permissionsOfUser?.find( (item) => item.id==="managetimegrid" );
    const releasePermission = permissionsUser?.fields.find((item)=>item.id ==="releasetime")?.actionable;
    const editPermission = permissionsUser?.fields.find((item)=>item.id ==="edittime")?.actionable;
    const clonePermission = permissionsUser?.fields.find((item)=>item.id ==="clonetime")?.actionable;
    const deletePermission = permissionsUser?.fields.find((item)=>item.id ==="deletetime")?.actionable;

    let isLoggedInUserSelected=timeKeeperList[0]?.userDelegateId === delegateId;
      
    let generalTimeRolePermissionLoggedInUser=false;
    let generalTimeFeaturePermission=false;

    let releaseTimeFeaturePermission=false;
    
    if(isLoggedInUserSelected){
      generalTimeRolePermissionLoggedInUser=permissionsOfUser?.find((item) => item.id==="generaltimeentries")?.fields.find((item)=>item.id ==="generaltimentry")?.actionable || false;
    }
    else{
      const featureList=getFeatureNames(timeKeeperList,delegateId) || [];
      generalTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME)!==-1;
      releaseTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME)!==-1;
    }
    let deleteValidationMessage=jsUtils.getLiteralValue("ARE_YOU_SURE_YOU_WANT_TO_DELETE");
    let releaseValidationMessage=jsUtils.getLiteralValue("ARE_YOU_SURE_YOU_WANT_TO_RELEASE_THE_SELECTED_TIME_ENTRY");
    let releaseTooltip=jsUtils.getLiteralValue("RELEASE");
    let editTooltip=jsUtils.getLiteralValue("EDIT");
    let transferTooltip=jsUtils.getLiteralValue("TRANSFER");
    let copyTooltip=jsUtils.getLiteralValue("COPY");
    let deleteTooltip=jsUtils.getLiteralValue("DELETE");
    MTColDef[MTColDef.length-1].data.renderElement = DayViewColDef[DayViewColDef.length-1].data.renderElement = (rowItem)=>{

      return (
        <div>
            <>
                 <span className="MTGrid-release">
                  <ConfirmationButton
                    onYesClick={((isLoggedInUserSelected && releasePermission) || (!isLoggedInUserSelected && releaseTimeFeaturePermission)) && releaseClick}
                    rowData={rowItem}
                    disableClick={!(((isLoggedInUserSelected && releasePermission) || (!isLoggedInUserSelected && releaseTimeFeaturePermission)) && (rowItem.status.toLowerCase()==="ready"))}
                    message={releaseValidationMessage}
                    //src="/images/svg/icon_release.svg"
                    className={rowItem.status.toLowerCase()!=="ready"? "icon-icon_release icon-hide":
                    ((isLoggedInUserSelected && releasePermission) || (!isLoggedInUserSelected && releaseTimeFeaturePermission)) 
                        ? "icon-icon_release"
                        : "icon-icon_release icon-hide"
                    }
                    style={{ marginRight: "0.5rem", width: "1.8rem" }}
                    tooltip={(rowItem.status.toLowerCase()==="ready") && ((isLoggedInUserSelected && releasePermission) || (!isLoggedInUserSelected && releaseTimeFeaturePermission)) ? releaseTooltip:null}
                    searchCriteria={null}
                  ></ConfirmationButton>
                  {/* <i className="icon-icon_release"></i> */}
                </span>

                <span className="MTGrid-btn">
                 <i
                      data-testid="edit1"
                      className={getTitleClassNameDisable("edit",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)?"icon-icon_edit icon-hide":"icon-icon_edit"}
                      title={!getTitleClassNameDisable("edit",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)? editTooltip:null}
                      onClick={(e) => {
                        rowItem.status.toLowerCase()!=="posted" && rowItem.status.toLowerCase()!=="released" &&
                        ((rowItem.isGeneral && (rowItem.status.toLowerCase()!=="completed") && (generalTimeRolePermissionLoggedInUser || generalTimeFeaturePermission)) || (!rowItem.isGeneral && !rowItem.ethicalWallFlag && editPermission )) && editClick(rowItem)
                        e.stopPropagation();
                      }}
                    ></i>
                  </span>
                  <span className="MTGrid-btn">
                    <i
                    className={getTitleClassNameDisable("transfer",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)?"icon-icon-transfertimeentry icon_thin icon-hide":"icon-icon-transfertimeentry icon_thin"}
                    title={!getTitleClassNameDisable("transfer",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)? transferTooltip:null}
                     onClick={(e) => {
                      if( rowItem.status.toLowerCase()!=="posted" && rowItem.status.toLowerCase()!=="released" && rowItem.status.toLowerCase()!=="completed" && 
                          ((rowItem.isGeneral && (generalTimeRolePermissionLoggedInUser || generalTimeFeaturePermission)) || (!rowItem.isGeneral && !rowItem.ethicalWallFlag && editPermission )))
                          { 
                           onTransferClick(rowItem)
                          }
                       e.stopPropagation();
                     }}
                     style={{ width: "1.7rem" }}
                   ></i>
                 </span>
                 <span className="MTGrid-clone-btn">
                    <i
                      className={getTitleClassNameDisable("clone",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)?"icon-icon_clone icon-hide":"icon-icon_clone"}
                      title={!getTitleClassNameDisable("clone",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)?copyTooltip:null}
                      onClick={(e) => {
                         (!rowItem.ethicalWallFlag && clonePermission ) && cloneClick(rowItem)
                        e.stopPropagation();
                      }}
                    ></i>
                  </span>
                  <span className="MTGrid-btn">
                   <i
                    className={getTitleClassNameDisable("delete",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)?"icon-icon_trash icon-hide":"icon-icon_trash"}
                    title={!getTitleClassNameDisable("delete",rowItem,generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,editPermission,deletePermission,clonePermission)? deleteTooltip:null}

                    onClick={(e) => {
                      // enableDelete && setDeleteData(rowItem.timeEntryId);
                      // enableDelete && setDisplayDeleteConfirmation(true);
                      if((rowItem.status.toLowerCase()==="invalid" || rowItem.status.toLowerCase()==="ready") && deletePermission)
                     {
                        if(rowItem.is_draft_time === "Y"){
                          setDeleteData({timeEntryIds:[],draftTimeEntries:[rowItem.timeEntryId],generalTimeEntryIds:[]});
                        }
                        else{
                          if(rowItem.isGeneral)
                          {
                            setDeleteData({timeEntryIds:[],draftTimeEntries:[],generalTimeEntryIds:[rowItem.timeEntryId]});
                          }
                          else{
                            setDeleteData({timeEntryIds:[rowItem.timeEntryId],draftTimeEntries:[],generalTimeEntryIds:[]})
                          }
                        }
                     setValidationMessage(deleteValidationMessage);
                     setConfirmAction("delete");
                     setDisplayConfirmation(true);
                     }
                      e.stopPropagation();
                    }}
                    style={{ width: "1.7rem" }}
                  ></i>
                </span>
                  
           </>

        </div>
      );
    }
   
  },[delegateId,permissionsOfUser,timeKeeperList])


  
  if (signingOut) {
    return (
      <div className="spinner-popup">
        <Spinner />
      </div>
    );
  } else {
    return (
      <>        
        {loadingClient === false &&
          loadingTimeKeeper === false &&
          error === "" ? (          
           
            <div className="p-tabview p-component">
            
            {props.match.params.MODE && props.match.params.MODE.toLowerCase() === "managetime" ? <ManageTimeTable
                editClick={editClick}
                cloneClick={cloneClick}
                setDeleteData={setDeleteData}
                releaseClick={releaseClick}
                setDisplayConfirmation={setDisplayConfirmation}
                setValidationMessage={setValidationMessage}
                setConfirmAction={setConfirmAction}
                onBulkEdit={onBulkEdit}
                onBulkUpdate={onBulkUpdate}
                onBulkRelease={onBulkRelease}
                onBulkDelete={onBulkDelete}
                onBulkTransfer={onBulkTransfer}
                onCombineClick={onCombineClick}
                onTransferClick={onTransferClick}
                onLoadingcard={onLoadingcard}
                onExportClick={onExportClick}        
                refreshCounter={refreshCounter}
                checkboxRefreshCounter={checkboxRefreshCounter}
                setCheckBoxRefreshCounter={setCheckBoxRefreshCounter}
                columnDef={colDef}
                setColumnDef={setColDef}
                moveScrollToTop={moveScrollToTop}
                setMoveScrollToTop={setScrollToTop}
                DataFromAPI={DataFromAPI}
                setDataFromAPI={setDataFromAPI}
                bulkSelectToggle={bulkSelectToggle}
                gridReset={gridReset}
                checkAll={checkAll}
                setCheckAll={setCheckAll}
                operation={operation}
                setOperation={setOperation}
                totalTECount={totalTECount}
                setTotalTECount={setTotalTECount}
                loading={loading}
                setLoading={setLoading}
                getFeatureNames={getFeatureNames}
                getAccessInfo={getAccessInfo}
                timeKeeperList={timeKeeperList}
                delegateId={delegateId}
                columnsData={columnsData}
                columnsDataBackup={columnsDataBackup}
                onApplyColumnChanges={onApplyColumnChanges}
            />: props.match.params.MODE && props.match.params.MODE.toLowerCase() === "dayview"? <DayViewTable
                editClick={editClick}
                cloneClick={cloneClick}
                setDeleteData={setDeleteData}
                releaseClick={releaseClick}
                setDisplayConfirmation={setDisplayConfirmation}
                setValidationMessage={setValidationMessage}
                setConfirmAction={setConfirmAction}
                onBulkEdit={onBulkEdit}
                onBulkUpdate={onBulkUpdate}
                onBulkRelease={onBulkRelease}
                onBulkDelete={onBulkDelete}
                onBulkTransfer={onBulkTransfer}
                onCombineClick={onCombineClick}
                onTransferClick={onTransferClick}
                onLoadingcard={onLoadingcard}
                onExportClick={onExportClick}        
                refreshCounter={refreshCounter}
                setCheckBoxRefreshCounter={setCheckBoxRefreshCounter}
                columnDef={colDef}
                setColumnDef={setColDef}
                moveScrollToTop={moveScrollToTop}
                setMoveScrollToTop={setScrollToTop}
                DataFromAPI={DataFromAPI}
                setDataFromAPI={setDataFromAPI}
                bulkSelectToggle={bulkSelectToggle}
                gridReset={gridReset}
                checkAll={checkAll}
                setCheckAll={setCheckAll}
                loading={loading}
                setLoading={setLoading}
                getFeatureNames={getFeatureNames}
                getAccessInfo={getAccessInfo}
                timeKeeperList={timeKeeperList}
                delegateId={delegateId}
                columnsData={columnsData}
                columnsDataBackup={columnsDataBackup}
                onApplyColumnChanges={onApplyColumnChanges}
            />: props.match.params.MODE && props.match.params.MODE.toLowerCase() === "monthview" ? <MonthView/>

              : <TimeManagenmentClientMatterTable pageTitle={pageTitle} />}
              {/* bulk update commponent */}
            {sessionStorage.getItem("show-bulk-update-popup") && <EditTimeEntry 
             show={sessionStorage.getItem("show-bulk-update-popup")? true : false}
             closed={() => {
              sessionStorage.removeItem("show-bulk-update-popup")
              setRefreshCounter(refreshCounter+1);
              document.title = sessionStorage.getItem("prev_title");
             }}
             onSaveHandler={afterSavingPreference => onSaveHandlerAddTimeBulkChange("show-bulk-update-popup", afterSavingPreference)}
             data={null}
             pageTitle={getCode("ATTORNEYFLOW_BULK_UPDATE")} 
             breadcrumbLabel1={getCode("TIME_MANAGEMENT")}
             breadcrumbLabel2={`${getCode("TIME_ENTRY")} (${getCode("BULK_CHANGE")})`}
             selectedTimeEntries={selectedTimeEntries}
             screen={"bulkUpdate"}
             />}  

              {/* plus button commponent to add time */}
             {sessionStorage.getItem("show-add-time-popup") && <EditTimeEntry 
             show={sessionStorage.getItem("show-add-time-popup")? true : false}
             closed={() => {
                sessionStorage.removeItem("show-add-time-popup");
                setRefreshCounter(refreshCounter + 1);
                document.title = sessionStorage.getItem("prev_title");
                return history.push(sessionStorage.getItem("navigated-from"));               
              }}            
             onSaveHandler={afterSavingPreference => onSaveHandlerAddTimeBulkChange("show-add-time-popup", afterSavingPreference)}
             data={null}
             pageTitle={getCode("ATTORNEYFLOW_TIME_ENTRY")} 
             breadcrumbLabel1={getCode("TIME_MANAGEMENT")}
             breadcrumbLabel2={getCode("TIME_ENTRY")}
             screen={"addTime"}
             />}  


            {showAttorneyListPopup && 
              <AttorneySelectionBox 
              showPopup={showAttorneyListPopup} 
              onCancel={() => dispatch(setShowAttorneyListPopupFlag(false))} 
              />}

              {showAddTimeDialog && (
              <AddTimePopup
                functionality={functionalityName}
                show={showAddTimeDialog}
                closed={() => {
                  document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME")
                  setShowAddTimeDialog(false);
                  setSelectedRowData({
                    selectedClient: { id: "", description: "", code: "" },
                    selectedMatter: { id: "", description: "", code: "" },
                    time: "00:00:00",
                    selectedSubClient: { id: "", description: "", code: "" },
                    billableDuration: "",
                    is_draft_time: ""
                  });
                  setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
                  resetFilters();
                  setCheckBoxRefreshCounter(0);
                  setGridReset(gridReset+1);
                  setScrollToTop();
                  setBulkEdit(false);
                  setEditPosition(0);
                  setEditTimeEntryList([]);
                  setFunctionalityName(null);
                }}
                onSaveHandler={afterSavingPreference => onSaveHandlerAddTime(afterSavingPreference)}
                bulkEdit={bulkEdit}
                editPosition={editPosition}
                setEditPosition={setEditPosition}
                editTimeEntryList={editTimeEntryList}
                pageTitle={"ATTORNEYFLOW_TIME_ENTRY"}
                data={selectedRowData}
                mode={modeState}
                onTraverseNext={onTraverseNext}
                
              />)}

              {showGeneralTimeDialog && (
                  <GeneralTimePopup
                  show={showGeneralTimeDialog}
                  closed={() => {
                      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME")
                      setShowGeneralTimeDialog(false);
                      setSelectedRowData({
                      selectedClient: { id: "", description: "", code: "" },
                      selectedMatter: { id: "", description: "", code: "" },
                      time: "00:00:00",
                      selectedSubClient: { id: "", description: "", code: "" },
                      billableDuration: "",
                      is_draft_time: "",
                      attachments: []
                      });
                      setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
                      resetFilters();
                      setCheckBoxRefreshCounter(0)
                      setGridReset(gridReset+1);
                      setBulkEdit(false);
                      setEditPosition(0);
                      setEditTimeEntryList([])
                  }}
                  onSaveHandler={(afterSavingPreference) => {
                      closePopupBasedOnPreference(afterSavingPreference);
                  }}
                  pageTitle={"ATTORNEYFLOW_MANAGE_TIME"}
                  data={selectedRowData}
                  mode = {modeState}
                  /> )}

                  <span className="Confirmation-popup">
                  {displayConfirmation && (  
                      <ConfirmationDialogBox
                          visible={displayConfirmation}
                          message={validationMessage}
                          header="Confirmation"            
                          onCancel={()=>{
                          setDisplayConfirmation(false)
                          }}
                          onConfirm={()=>{
                          onChange();
                          }}
                          data-testid="id1"
                          cancelButtonLabel={jsUtils.getLiteralValue("NO")}
                          confirmButtonLabel={jsUtils.getLiteralValue("YES")}
                          cancelButtonClassName={"p-button-text no-button"}
                          confirmButtonClassName={"yes-button"}
                          imageClassName={"af-warning"}
                          imageSrc={"/images/icon_warning.svg"}
                      />
                  )}  
                </span>

                <span className="Confirmation-popup">
                      {showPermissionValidatePopup && (
                      <ConfirmationDialogBox
                          visible={showPermissionValidatePopup}
                          message={validationPermissionMessage}
                          header="Confirmation"            
                          onCancel={()=>{
                          setShowPermissionValidatePopup(false);
                          }}
                          onConfirm={()=>{
                          onPermissionCheck();
                          }}
                          disableConfirmButton={disableConfirmButton}
                          cancelButtonLabel={disableConfirmButton?jsUtils.getLiteralValue("OK"):jsUtils.getLiteralValue("NO")}
                          confirmButtonLabel={jsUtils.getLiteralValue("YES")}
                          cancelButtonClassName={"p-button-text no-button"}
                          confirmButtonClassName={"yes-button"}
                          imageClassName={"af-warning"}
                          imageSrc={"/images/icon_warning.svg"}
                      />
                      )} 
                  </span>

                  <DialogWithTableSelection 
                      selectionMode={"single"}
                      visible={displayCombineConfirmation}
                      setVisible={setDisplayCombineConfirmation}
                      onOkay={()=>{
                          finalCombineCall();
                          setDisplayCombineConfirmation(false);
                          }}
                      onCancel={()=>{
                          setDisplayCombineConfirmation(false);
                          setSelectedListData(null)
                          }}
                      labelYes={jsUtils.getLiteralValue("COMBINE")}
                      labelNo={jsUtils.getLiteralValue("CANCEL")}
                      message={jsUtils.getLiteralValue("COMBINE_ENTRY_SELECTION_QUESTION")}
                      header={jsUtils.getLiteralValue("COMBINE_SELECTED_TIME_ENTRIES")}
                      columnData={[{field:"RATE_CODE",header:jsUtils.getLiteralValue("RATE")},
                                  {field:"TASK_CODE",header:jsUtils.getLiteralValue("TASK")},
                                  {field:"SERVICE_CODE",header:jsUtils.getLiteralValue("SERVICE")},
                                  {field:"ACTIVITY_CODE",header:jsUtils.getLiteralValue("ACTIVITY")},
                                  {field:"PHASE_CODE",header:jsUtils.getLiteralValue("PHASE")},
                                  {field:"CITY_CODE",header:jsUtils.getLiteralValue("CITY")},
                                  {field:"MAKE_NO_CHARGE",header:jsUtils.getLiteralValue("MAKE_NO_CHARGE")},
                                  {field:"UDF2",header:jsUtils.getLiteralValue("UDF2_LITERAL")},
                                  {field:"UDF3",header:jsUtils.getLiteralValue("UDF3_LITERAL")},
                                  ]}
                      rowData={processedCombineList}
                      selectedListData={selectedListData}
                      setSelectedListData={setSelectedListData} 
                      dataKey={"timeEntryId"}/>
            </div>
              
        ) : (
          <div>{getCode("NETWORK_ERROR")}</div>
        )}
      </>
    );
  }
}

export default TimeManagement;
