import React, { useState, useEffect } from "react";
import gStore from "../../../store/globalStore";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./Timer.scss";
import { useSubscribe } from "../../../store/ActionStore";
import permissionsState from "../../../store/ClientMatterRolesPermissions.ts";
import jsUtils from "../../../utils/jsUtils";
export default function ({
  rowData,
  stop_delete_timer,
  pause_timer,
  isActionable,
  alertDeleteMessage,
  NoButtonText,
  YesButtonText,
  clearTimeToolTip,
  stopTimeTooltip,
  pauseTimeToolTip
}) {
  let timer = [];
  if (rowData && rowData.timeDuration) {
    timer = rowData.timeDuration.split(":").map((d) => Number(d));
  }
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const dialogFuncMap = {
    displayConfirmation: setDisplayConfirmation,
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const [hh, setH] = useState(timer[0] || 0);
  const [mm, setM] = useState(timer[1] || 0);
  const [ss, setS] = useState(timer[2] || 0);
  const [duration, setDuration] = useState(
    (rowData && rowData.timeDuration) || "00:00:00"
  );
  
  const [play, setPlay] = useState(true);
  useSubscribe([permissionsState]);

  // useEffect(() => {
  //   let permissions = permissionsState.clientMaterTablePermissions;
  //   permissions &&
  //     permissions.forEach((element) => {
  //       if (element.id === "timer" && !element.actionable) {
  //         setTimerDisable(element.actionable);
  //       }
  //     });
  // });

  /** set tooltips from storage on screen load
   * literals -variale to store the array of literals
   */
  // const [codeFromAPI, setCodeFromAPI] = useState([]);

  // const getCode = (key) => {
  //   return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  // };
  // useEffect(() => {
  //   let literals = storage.getObject("literals");
  //   if (literals) {
  //     setTooltips({
  //       [TOOLTIPS_KEYS.TOOLTIP_PAUSE]: literals[TOOLTIPS_KEYS.TOOLTIP_PAUSE],
  //       [TOOLTIPS_KEYS.TOOLTIP_CLEARTIME]:
  //         literals[TOOLTIPS_KEYS.TOOLTIP_CLEARTIME],
  //       [TOOLTIPS_KEYS.TOOLTIP_STOP]: literals[TOOLTIPS_KEYS.TOOLTIP_STOP],
  //     });
  //     setCodeFromAPI(literals);
  //   } 
  // }, []);

  useEffect(() => {
     let time = window.setTimeout(function () {
      if (play) {
        if (ss === 59) {
          updateTImerSeconds(0);
          if (mm + 1 === 60) {
            setM(0);
            setH(hh + 1);
          } else {
            setM(mm + 1);
          }
        } else {
          updateTImerSeconds(ss + 1);
        }

        setDuration(
          numToString(hh) + ":" + numToString(mm) + ":" + numToString(ss)
        );
        gStore.timeDuration =
          numToString(hh) + ":" + numToString(mm) + ":" + numToString(ss);
      }
    }, 995);

    return () => {
      window.clearTimeout(time);
    };
  }, [ss, play]);

  const onFocus = () => {
    if (!document.hidden) {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);
      const timeValue = sessionStorage.getItem("timerecord");      
      const secondsToBeAdded = secondsSinceEpoch - Number(timeValue);
      //localStorage.removeItem("timerecord");

      let newTime=jsUtils.updatedTime(hh+":"+mm+":"+ss,secondsToBeAdded);
      if(newTime){
        let [newhh,newmm,newss] = newTime.split(":");
        setH(Number(newhh));
        setM(Number(newmm));
        setS(Number(newss));
      }
      setPlay(true);
    } else {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);
      console.log("time then ",secondsSinceEpoch);
      sessionStorage.setItem("timerecord", secondsSinceEpoch);
      setPlay(false);
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("visibilitychange", onFocus, false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", onFocus, false);
  }, [document.hidden]);

  let numToString = (digit) => {
    digit = digit.toString();
    if (digit.length === 1) return "0" + digit;
    else return digit;
  };

  let updateTImerSeconds = (newSeconds) => {
    setS(Number(newSeconds));
  };

  //
  let updateStatus = (status) => {
    if (isActionable) {
      gStore.timeDuration =
        numToString(hh) + ":" + numToString(mm) + ":" + numToString(ss);
      if (status === "paused") {
        pause_timer(status, rowData);
      } else {
        stop_delete_timer(status, rowData);
      }
    }
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label={NoButtonText}
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label={YesButtonText}
          icon="pi pi-check"
          onClick={() => {
            updateStatus("deleted");
          }}
          className="yes-button"
          autoFocus
        />
      </div>
    );
  };

  return (
    <div
      className={
        isActionable ? "timerClass " : "timerClass timer-disable-hide"
      }>
      <i
        className="at-icon-pause af-pause"
        title={pauseTimeToolTip}
        alt=""
        onClick={() => {
          updateStatus("paused");
        }
      }
      ></i>
      <span> {duration} </span>
      <i
        title={clearTimeToolTip}
        className="at-icon-delete af-delete"
        alt=""
        onClick={() => isActionable && onClick("displayConfirmation")}
      ></i>
       <i
        title={stopTimeTooltip}
        className="at-icon-stop af-stop"
        alt=""
        onClick={() => {
          updateStatus("stopped");
        }}
      ></i>

      <div className="timerClass-dialog">
        <Dialog
          closable={false}
          header="Confirmation"
          visible={displayConfirmation}
          modal
          footer={renderFooter("displayConfirmation")}
          onHide={() => onHide("displayConfirmation")}>
          <div className="confirmation-content">
            <img
              title=""
              alt=""
              className="af-warning"
              src="/images/icon_warning.svg"
            />
            <span>{alertDeleteMessage}</span>
          </div>
        </Dialog>
      </div>
    </div>
  );
}