var initialState = {
  LandingPageReducer: {
    loading: false,
    items: [],
    error: "",
    PreferenceMenuObj: [],
    delegates: {
      userDelegates: []
    },
    allowMounting: false,
    breadcrumbData: [],
    tabItems: [],
    helpLink: ""
  },
  TimeManagementReducer: {
    loading: false,
    loadingTimeKeeper: false,
    timeKeeperList: [],
    clientitems: [],
    error: "",
    errorTimeKeeper: "",
    clientFilteredData: [],
    userID: "",
    delegateId: "",
    dateFormat: null,
    attorneyCode: "",
    timeKeeperFeatureName: "",
    timeKeeperFeatureId: "",
    userDelegateRoundingMethod: "NORMAL",
    timekeeper: false,
    allRestrictedWords: {},
    loggedInUserToggle: false,
    selectedTimeEntries: [],
    showEditTimeEntryPage: false,
    afterSavingPreference: "keep_te_open"
  },
  AuthReducer: {
    user: null,
    email: null,
    isAuth: false
  },
  AppReducer: {
    apiResponse: {},
    loading: false,
    showMessage: false,
    signingOut: false,
    refresh: {},
    enabled: false,
    success: false,
    isMFA: false,
    selectedWord: null,
    fieldnames: [],
    wobo: {
      action: ""
    }
  },
  ConfirmationReducer: {
    show: false,
    route: "/",
    unsavedChanges: false
  },
  PreferenceReducer: {
    userSessionTimeout: 15,
    expiryReminder: false,
    expiryReminderMessage: "",
    preferenceApiData: [],
    preferenceSettings: null,
    meUserData: {
      user_timezone: ""
    },
    firstName: "",
    lastName: "",
    userName: "",
    hasMultipleSubClients: false
  },
  AcrossTabReducer: {
    sessionExpired: false,
    minutes: 2,
    seconds: 59
  },
  AutotextReducer: {
    autotextDetails: {},
    autotextDetailsShortcut: null,
    enteredRestrictedWordsFulltext: null,
    autotextAction: {}
  },
  ManageTimeReducer: {
    clients: [],
    //   [{clientId: '', code: '', client_name: 'Blank'}],
    subClients: [],
    // [{clientId: '', subClientId: '', subClientNumber: 'Blank'}],
    matters: [],
    //  [{subClientId: '', matterId: '', code: '', description: 'Blank'}],
    status: [{
      id: 1,
      value: 'All'
    }, {
      id: 2,
      value: 'Ready'
    }, {
      id: 3,
      value: 'Invalid'
    }, {
      id: 4,
      value: 'Released'
    }, {
      id: 5,
      value: 'Posted'
    }, {
      id: 6,
      value: 'Completed'
    }],
    narrative: "",
    fromDate: "",
    fromTo: "",
    cityCodes: [{
      description: 'Blank',
      id: 0,
      display_value: ''
    }],
    phaseCodes: [{
      description: "Blank",
      id: 0,
      display_value: ""
    }],
    fromDuration: "",
    toDuration: "",
    serviceCodes: [{
      description: 'Blank',
      id: 0,
      display_value: ''
    }],
    taskCodes: [{
      description: 'Blank',
      id: 0,
      display_value: ''
    }],
    rateCodes: [{
      description: 'Blank',
      id: 0,
      display_value: ''
    }],
    makeNoCharge: [{
      id: 1,
      value: "No"
    }, {
      id: 2,
      value: "Yes"
    }],
    predictive: [{
      id: 1,
      value: "No"
    }, {
      id: 2,
      value: "Yes"
    }],
    gte: [{
      id: 1,
      value: "No"
    }, {
      id: 2,
      value: "Yes"
    }],
    activityCodes: [{
      description: 'Blank',
      id: 0,
      display_value: ''
    }],
    udf2Codes: [{
      description: 'Blank',
      id: 0,
      display_value: ''
    }],
    udf3Codes: [{
      description: 'Blank',
      id: 0,
      display_value: ''
    }],
    manegeTimePageUnmount: false,
    pageOffset: 0,
    loading: false
  },
  DayViewReducer: {
    transferTE: null,
    ethicalWallUserIds: [],
    showAttorneyListPopup: false,
    checkBillable: true,
    checkNonBillable: true,
    checkUnreleased: false,
    date: new Date(),
    transferFeatureName: []
  }
};
export default initialState;