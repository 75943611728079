import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./Timer.scss";

export default function ({
  rowData,
  stop_delete_timer,
  start_or_resume_timer,
  isActionable,
  alertDeleteMessage,
  NoButtonText,
  YesButtonText,
  clearTimeToolTip,
  stopTimeTooltip,
  resumeTimeToolTip
}) {
  //const [showPopup, setShowPopup] = useState(false)
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const dialogFuncMap = {
    displayConfirmation: setDisplayConfirmation,
  };
 
  /** set tooltips from storage on screen load
  * @param literals -variale to store the array of literals
  */
 
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  let updateStatus = (status) => {
    if (isActionable) {
      if (status === "resumed") {
        start_or_resume_timer(rowData, "resumed");
      } else {
        stop_delete_timer(status, rowData);
      }
    }
  };

  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label={NoButtonText}
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label={YesButtonText}
          icon="pi pi-check"
          onClick={() => {
            updateStatus("deleted");
          }}
          className="yes-button"
          autoFocus
        />
      </div>
    );
  };

  return (
    <div className="timerClass">
       <i
        className="at-icon-iconresume af-play"
        onClick={() => {
          updateStatus("resumed");
        }}
        alt=""
        title={resumeTimeToolTip}
      ></i>
      <span> {rowData && rowData.timeDuration} </span>
      <>
         <i
          className="at-icon-delete af-delete"
          onClick={() => isActionable && onClick("displayConfirmation")}
          alt=""
          title={clearTimeToolTip}
        ></i>
        <i
          className="at-icon-stop af-stop"
          onClick={() => {
            updateStatus("stopped");
          }}
          alt=""
          title={stopTimeTooltip}
        ></i>
      </>

      <div className="timerClass-dialog">
        <Dialog
          closable={false}
          header="Confirmation"
          visible={displayConfirmation}
          modal
          footer={renderFooter("displayConfirmation")}
          onHide={() => onHide("displayConfirmation")}
        >
          <div className="confirmation-content">
            <img
              title=""
              alt=""
              className="af-warning"
              src="/images/icon_warning.svg"
            />
            <span>{alertDeleteMessage}</span>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
