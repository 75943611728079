import React from 'react';
import { useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import RestrictedWordsGrid from "./Tabs/RestrictedWords/RestrictedWordsGrid";
import CharacterLengthGrid from "./Tabs/CharacterLength/CharacterLengthGrid";
import '../Configuration/ConfigurationScreen.scss';
import jsUtils from "../../../../utils/jsUtils"

const NarrativeControls = () => {

    const  InformalSearchTabMenus= [
        { id: 1, label: jsUtils.getLiteralValue('RESTRICTED_WORDS') },
        { id: 2, label: jsUtils.getLiteralValue('CHARACTER_LENGTH') }
    ]
    const [activeItem, setActiveItem] = useState(1);

    const tabChange = (evt) => {
        setActiveItem(evt.value.id);
    }
    const loadDynamicTabComponent = () => {
        switch (activeItem) {
            case 1:
                return <RestrictedWordsGrid />
            case 2:
                return <CharacterLengthGrid />
            default:    
                return <RestrictedWordsGrid />
        }
    }

    return (
        <div className="config-container">
            <div>
            <TabMenu
                    model={InformalSearchTabMenus}
                    activeItem={activeItem === 1 ? InformalSearchTabMenus[0]:InformalSearchTabMenus[1]}
                    onTabChange={(e) => tabChange(e)} />
            
            </div>
            <div className="tab-body-container">
                {loadDynamicTabComponent()}
            </div>
        </div>
    )
}

export default NarrativeControls;