import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";



const ConfirmationBox = ({ message, showRWValidationMsg ,setPopupVisibleToggle,imageSrc,setIgnoreClicked , showOkButton , showIgnoreButton , showCancelButton , type}) => {
 
  const onOkay = () => {
    setPopupVisibleToggle(false);
  };
  const onCancel = () => {
    setPopupVisibleToggle(false);
  };
  const onIgnore = () =>{
    setPopupVisibleToggle(false);
    setIgnoreClicked(true);
  }


  const renderFooter = (name) => {
    return (
      <div>
       {showOkButton && <Button
          label="OK"
          icon="pi pi-check"
          onClick={onOkay}
          autoFocus
          data-testid="confirmationOk"
        /> }  
        {showCancelButton && <Button
          className="p-button-text"
          label="Cancel"
          icon="pi pi-times"
          onClick={onCancel}
          autoFocus
        />}
        {showIgnoreButton && <Button
          label="Ignore"
          icon="pi pi-check"
          onClick={onIgnore}
          autoFocus
        /> }   
      </div>
    );
  };

  return (
    <div className="af-confirmation">
      <div className="af-confirmation-dialog">
        <Dialog
          closable={false}
          header={type === "attachmentError" ? "Note" : "Confirmation"}
          visible={showRWValidationMsg}
          modal
          footer={renderFooter("displayConfirmation")}
          onHide={onOkay}>
          <div className="confirmation-content">
            <img
              title=""
              alt=""
              className="af-warning"
              src={imageSrc ? imageSrc : "/images/icon_warning.svg" }
            />
            <span>{message}</span>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ConfirmationBox;
