import { SET_AUTOTEXT_DETAILS, SET_AUTOTEXT_ACTION, SET_AUTOTEXT_DETAILS_SHORTCUT, SET_AUTOTEXT_VALUE } from "./ActionType";
export var setAutotextDetails = function setAutotextDetails(payload) {
  return {
    type: SET_AUTOTEXT_DETAILS,
    data: payload
  };
};
export var setAutotextAction = function setAutotextAction(payload) {
  return {
    type: SET_AUTOTEXT_ACTION,
    data: payload
  };
};
export var setAutotextDetailsShortcutAction = function setAutotextDetailsShortcutAction(payload) {
  return {
    type: SET_AUTOTEXT_DETAILS_SHORTCUT,
    data: payload
  };
};
export var setAutotextValue = function setAutotextValue(key, payload) {
  return {
    type: SET_AUTOTEXT_VALUE,
    fieldId: key,
    data: payload
  };
};