import React, { useState, useEffect, useRef, useCallback } from "react";

import { InputText } from "primereact/inputtext";

import { Calendar } from "primereact/calendar";

import { Button } from "primereact/button";

import "./AddTimePopup.scss";
import { manageTime } from "../../../services/ManageTimeApi";

import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";

import { addTime } from "../../../services/AddTimeApi";

import { shortcut } from "../../../services/AutoTextApi";

import moment from "moment";

import * as NarrativeHelper from "./NarrativeHelper";

import { Toast } from "primereact/toast";
import FieldState from "./AddTimePopup.State.ts";
import DurationState from "../../../store/DurationState.ts";

import { useSubscribe } from "../../../store/ActionStore";
import { helpPageUrl } from '../../../config';
import Spinner from "../Spinner/Spinner";
import * as message from "./../../../config/messages";
import ConfirmationBox from "./../ConfirmationBox/ConfirmationBox";
import { fetchAllRestrictedWords } from "../../../services/RestrictedWordsApi";
import { HELP_LINKS, TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../config/vars";
import storage from "../../../utils/storage";
import { setErrorMessage } from "../../../actions/AppAction";
import Transcribe from "../AWSTranscribe/Transcribe";
import { AuthService } from "../../../services/authService";
import { setDurationToggle } from "../../../actions/TimeManagementAction";
import { refreshStoreOnError } from "../../../actions/AppAction";
import permissionsState from "../../../store/ClientMatterRolesPermissions.ts";
import { Preferences } from "../../../services/PreferencesApi";
import jsUtils from "../../../utils/jsUtils";
import RepeatTimeEntry from "./RepeatTimeEntry/RepeatTimeEntry";
import { setHighlightRestrictedWords } from "../../../actions/AddTimeActions";
import { ToolTipWrapper } from "./ToolTipWrapper";
import validationUtils from "../../../utils/validationUtils";
import NarrativeWrapper from "./Narrative/NarrativeWrapper";
import { ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME } from "../../../config/messages";
import {CheckBox} from "@attorneyflow-mf/styleguide"
import RadioDropDownComponent from "../RadioButton/RadioDropDownComponent";
import ToggleButtonComponent from "../ToggleButtonComponent";

function AddTimePopup({
  selectedClient = { id: "", description: "" },

  closed,
  onSaveHandler,
  show,

  data,

  mode,

  pageTitle,

  selectedMatter = { id: "", description: "" },
  selectedSubClient = { id: "", description: "" },
  bulkEdit,
  editTimeEntryList,
  editPosition,
  setEditPosition,
  onTraverseNext,
  functionality
}) {
  const dispatch = useDispatch();
  const [codeFromAPI, setCodeFromAPI] = useState([]);


  /**
   *   gets the labels/tooltips/error codes from state codeFromAPI
   * @param {String} key - literals key
   * @returns the value associated for that particular key and if it does not find the key then return the key itself
   */
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };

  /**
  *   gets the labels from state codeFromAPI
  * @param {String} key - literals key
  * @returns the value associated for that particular key and if it does not find the key then return the key itself
  */
  const getCodeLabels = (key) => {
    return storage.getObject("literals") && storage.getObject("literals")[key] ? storage.getObject("literals")[key] : key
  };

  //sets the state with the literals on component load
  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setCodeFromAPI(literals);
    }
  }, []);

  

  // condition to check and store  release time = true/false
  useSubscribe([permissionsState]);

  const [isDraftTime, setIsDraftTime] = useState(false)


  const {
    userDelegateRoundingMethod,
    delegateId,
    dateFormat,
    attorneyCode,
    allRestrictedWords,
    afterSavingPreference
  } = useSelector(({ TimeManagementReducer }) => ({
    userDelegateRoundingMethod:
      TimeManagementReducer.userDelegateRoundingMethod,
    delegateId: TimeManagementReducer.delegateId,
    dateFormat: TimeManagementReducer.dateFormat,
    attorneyCode: TimeManagementReducer.attorneyCode,
    allRestrictedWords: TimeManagementReducer.allRestrictedWords,
    afterSavingPreference: TimeManagementReducer.afterSavingPreference
  }));

  useSubscribe([FieldState, DurationState]);
  DurationState.setRounding(userDelegateRoundingMethod);

  const [Loading, setLoading] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const dialogFuncMap = {
    displayConfirmation: setDisplayConfirmation,
  };

  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const dialogFuncMapClose = {
    closeConfirmation: setCloseConfirmation,
  };
  //const narrativeRef = useRef(null);
  const [autoCapStatus, setAutoCapStatus] = useState("true");
  const [isNarrativeDisabled, setIsNarrativeDisabled] = useState(false);
  const [showRWValidationMsg, setShowRWValidationMsg] = useState(false);
  const [enteredRestrictedWords, setEnteredRestrictedWords] = useState([]);
  const highlightDefault = [{ highlight: "" }];
  const [restrictedWords, setRestrictedWords] = useState(highlightDefault);
  const [buttonClicked, setButtonClicked] = useState(() => { });
  const [maxChars, setMaxChars] = useState(5000);
  const [keyPressed, setKeyPressed] = useState(null);
  const [onClickCross, setOnClickCross] = useState(false);
  const [spellCheckToggle, setSpellCheckToggle] = useState(true);
  const [forceInvalidStatusToggle, setForceInvalidStatusToggle] = useState(false);
  const [maximumLimitType ,setMaximumLimitType] = useState("E");
  const [maximumLimitMsg , setMaximumLimitMsg ] = useState("");
  const [showMaxLimitMsg , setShowMaxLimitMsg] = useState(false);
  const [ignoreClicked , setIgnoreClicked ] = useState(false);
  const [releaseButtonHide, setReleaseButtonHide] = useState(true);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);
  const [DBCurrentDate, setDBCurrentDate] = useState("");
  const timeKeeperList = useSelector((state) => state.TimeManagementReducer.timeKeeperList);
  const permissionsOfUser = useSelector((state) => state.AppReducer && state.AppReducer.fieldnames);
  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);
  
  const getFeatureNames = (list, userId) => {
    let featureNames = [];
    list.forEach((item) => {
      if (item.userDelegateId === userId) {
        featureNames.push(item.featureName)
      }
    })
    return featureNames;
  }

  useEffect(() => {
    const permissionsUser = permissionsOfUser?.find((item) => item.id === "managetimegrid");
    const releasetimePermission = permissionsUser?.fields.find((item) => item.id === "releasetime")?.actionable;
    let featureList = getFeatureNames(timeKeeperList, delegateId) || [];
    const releaseTimeFeaturePermission = featureList.findIndex((item) => item === ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME) !== -1;
    const isLoggedInUserSelected = (timeKeeperList && timeKeeperList[0]?.userDelegateId === delegateId)

    if ((!isLoggedInUserSelected && releaseTimeFeaturePermission) || (isLoggedInUserSelected && releasetimePermission)) {
      setReleaseButtonHide(false);
    }

  }, [permissionsOfUser, delegateId, timeKeeperList]);


  const [client, setClient] = useState({
    field: "client",

    isDisplay: true,

    isMandatory: true,

    displayLabel: getCodeLabels("CLIENT"),

    values: [
      {
        id: selectedClient.id,

        label: selectedClient.description,

        desc: selectedClient.description,

        code: selectedClient.code,

        isDefault: true,
      },
    ],
  });

  const [subClient, setSubClient] = useState({
    field: "subClient",

    isDisplay: true,

    isMandatory: true,

    displayLabel: getCodeLabels("SUB"),

    values: [
      {
        id: selectedSubClient.id,

        label: selectedSubClient.description,        

        desc: selectedSubClient.description,

        code: selectedSubClient.code, 

        isDefault: true,
      },
    ],
  });
 
  const [matter, setMatter] = useState({
    field: "matter",

    isDisplay: true,

    isMandatory: true,

    displayLabel: getCodeLabels("MATTER"),

    values: [
      {
        id: selectedMatter.id,

        label: selectedMatter.description,

        desc: selectedMatter.description,

        code: selectedMatter.code,
      },
    ],
  });

  // const { transcript, resetTranscript } = useSpeechRecognition();
  const [date, setDate] = useState(null);

  const [narrativeValue, setNarrativeValue] = useState("");

  const [autoCorrectDictionary, setAutoCorrectDictionary] = useState(null);

  const [narrativeLabelClass, setNarrativeLabelClass] = useState("af-msg");

  const [noOfChars, setNoOfChars] = useState(0);

  const [toggleSpeechRecog, setToggleSpeechRecog] = useState(true);

  const [mapCode, setMapCode] = useState(true);

  const [changed, setChanged] = useState(false);

  const [durationToggleClick, setDurationToggleClick] = useState(false);
  const [isTaskDisplayed, setIsTaskDisplayed] = useState(false);
  const [isActivityDisplayed, setIsActivityDisplayed] = useState(false);
  const [phaseCode, setPhaseCode] = useState(null);
  const [taskCode, setTaskCode] = useState(null);
  const [rateCode, setRateCode] = useState(null);
  const [showRateAmount, setShowRateAmount] = useState(false);
  const [rateAmountValue, setRateAmountValue] = useState("");
  const [onClientSelectedRefresh, setOnClientSelectedRefresh] = useState(0);
  const [transcribeReady, settranscribeReady] = useState(false);
  const [presignedURL, setpresignedURL] = useState("");
  const [unmappedComplianceError, setUnmappedComplianceError] = useState([]);
  const [fieldsMap, setFieldsMap] = useState(new Map());
  const recordDuration = useSelector((state) => state.TimeManagementReducer.loggedInUserToggle)
  const [repeatTimeEntryFlag, setRepeatTimeEntryFlag] = useState(false);
  const [checkboxDays, setCheckboxDays] = useState([]);
  const [endDateValue, setEndDateValue] = useState(null);
  const [traversalState, setTraversalState] = useState(null)
  const [showErrorRepeatTimeEntry, setShowErrorRepeatTimeEntry] = useState(false)
  const [repeatTimeEntryHide, setRepeatTimeEntryHide] = useState(false);
  const [clickStatus, setClickStatus] = useState("");
  const [complianceCheck, setComplianceErrorCheck] = useState(false);
  /*
   //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
  const [showInformationalPopup,setShowInformationalPopup] = useState(false)
  const [informationalMessage,setInformationalMessage] = useState("");
*/
  //remove all the fields ,duration values and errors when unmounting the component
  useEffect(() => {
    return () => {
      FieldState.update(FieldState.resetPayLoad);
      DurationState.update(DurationState.onDurationChange, [null, "", ""]);
      FieldState.update(FieldState.removeError);
      DurationState.update(DurationState.setDurationValidation, [null]);
    };
  }, []);
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: [TOOLTIPS_VALUES.SELECT_DATE],
    [TOOLTIPS_KEYS.TOOLTIP_CLEAR]: [TOOLTIPS_VALUES.CLEAR],
    [TOOLTIPS_KEYS.TOOLTIP_DICTATE]: [TOOLTIPS_VALUES.DICTATE],
    [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: [TOOLTIPS_VALUES.CLOSE],
    [TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]: [TOOLTIPS_VALUES.SWITCH_DURATION_FORMAT],
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP]
  })

  /** set tooltips from storage on screen load
    * literals -variale to store the array of literals
    */

  useEffect(() => {
    const currentDate = meUserData && meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setDBCurrentDate(currentDate);
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: literals[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE],
        [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: literals[TOOLTIPS_KEYS.TOOLTIP_SEARCH],
        [TOOLTIPS_KEYS.TOOLTIP_CLEAR]: literals[TOOLTIPS_KEYS.TOOLTIP_CLEAR],
        [TOOLTIPS_KEYS.TOOLTIP_DICTATE]: literals[TOOLTIPS_KEYS.TOOLTIP_DICTATE],
        [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: literals[TOOLTIPS_KEYS.TOOLTIP_CLOSE],
        [TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]: literals[TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT],
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP]
      })
    }

  }, []);

  const onSetShowLimitMsg = (isVisible) =>{
    setShowMaxLimitMsg(isVisible);
  }

  useEffect(() => {
    const { highlightJson, maxLimit , maxLimitType , maxLimitMsg } = NarrativeHelper.onLoadRestrictedWords(allRestrictedWords);
    //highlightJson.map(rWord => (rWord.component = ToolTipWrapper)); //2.1.1
    highlightJson.map(rWord => (rWord.enhancement = ToolTipWrapper));
    setRestrictedWords(highlightJson);

    dispatch(setHighlightRestrictedWords(highlightJson));
    setMaxChars(maxLimit);
    setMaximumLimitType(maxLimitType);
    setMaximumLimitMsg(maxLimitMsg)
    }, [allRestrictedWords]);


  useEffect(() => {
    if(maximumLimitType === "E"){
    if (maxChars - noOfChars === 0) {
      setNarrativeLabelClass("af-msg1");
      setToggleSpeechRecog(false);
      setNarrativeValue((prev) => {
        return prev;
      });
      
    } }
  }, [maximumLimitType]);
  useEffect(()=>{
    if (ignoreClicked){
      !Loading && onSave();
    }
  },[ignoreClicked])
  useEffect(() => {
    show &&
      shortcut.get("page=1&firmlevel=all").then((res) => {
        let autoDictonary = {};
        res.forEach((element) => {
          if (autoDictonary[element.shortcut]) {
            if (element.firmlevel !== 1) {
              let arr;

              if (typeof autoDictonary[element.shortcut] === "string") {
                arr = [autoDictonary[(element.shortcut).trim()]];
              } else {
                arr = [...autoDictonary[(element.shortcut).trim()]];
              }

              arr.push(element.fulltext);

              autoDictonary[(element.shortcut).trim()] = arr;
            }
          } else {
            autoDictonary[(element.shortcut).trim()] = element.fulltext;
          }
        });

        setAutoCorrectDictionary(autoDictonary);
      });

  }, [show]);

  const HandleClick = async (e) => {
    setChanged(true);
    setToggleSpeechRecog(!toggleSpeechRecog);

    if (toggleSpeechRecog) {
      //SpeechRecognition.startListening({ continuous: true });
      // narrativeRef.current.disabled = true;
      await AuthService.getPresinedUrlForAWSTranscribe().then((res) => {
        if (res.responseCode === 200) {
          setpresignedURL(res.presignedURL);
          settranscribeReady(true);
        }
      })
      setIsNarrativeDisabled(true);
    } else {
      // narrativeRef.current.disabled = false;
      setIsNarrativeDisabled(false);
      settranscribeReady(false);
      setpresignedURL("");
      //SpeechRecognition.abortListening();
      // setNarrativeValue((prev) => {
      //   return prev;
      // });
    }
    //resetTranscript();
  };

  const toggleClick = () => {
    setDurationToggleClick(!durationToggleClick);
  };

  const autoCapitalize = (value) => {
    if (keyPressed === "question_mark" || keyPressed === "exclamation" || keyPressed === "dot" || keyPressed === "enter") {
      const { formattedStr } = NarrativeHelper.sentenceCase(value);
      setNarrativeValue(formattedStr);
      setKeyPressed(null);
    }
  }

  const hOnChange = (value, rwAction, lastWord, lastWordStartIndex) => {
   let isAutoTextModified;
   
    if (keyPressed === "space") {
      const { isModified, sentence } = NarrativeHelper.getAutoCorrectedValueExtWithWord(
        value,
        autoCorrectDictionary,
        lastWord,
        lastWordStartIndex
      );
      isAutoTextModified = isModified;
      if (isModified) { // && maxChars - noOfChars - (newLength - prevLength) > 1) {
        value = sentence;
        setNoOfChars(value ? Number(value.length) : 0);
      }
    }
    if(maximumLimitType === "E"){
    if (Number(value.length) > maxChars) {
      setNarrativeLabelClass("af-msg1");
      if (
        keyPressed === "Ctrl+V" ||
        keyPressed === null ||
        (rwAction && rwAction === "Replace All")
      ) {
        setNoOfChars(value.length);
        //when user copy pastes text or replace all rw
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
        return;
      } else if (keyPressed === "space" && isAutoTextModified) {
        //when user types space for autotext
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
        return;
      } else if (keyPressed !== "Ctrl+V" && keyPressed !== "back_space") {
        return;
      }}
     
    else {
      setNoOfChars(value.length);
      setNarrativeLabelClass("af-msg");
      // FieldState.update(FieldState.setNarrativeError);
    }}
    else if (maximumLimitType === "W"){
      setNoOfChars(value.length);
      setNarrativeLabelClass("af-msg");
    }
    setNarrativeValue(value);
    // autoCapStatus !== "false" ? autoCapitalize(value): null;
    if (autoCapStatus !== "false") {
      autoCapitalize(value);
    }
    setKeyPressed(null);
  };
  const traverseDown = (forceChange) => {
    if (editTimeEntryList.length > 0 && (editPosition + 1) < editTimeEntryList.length) {
      setTraversalState("DOWN")
      if (!changed || forceChange) {
        setCheckboxDays([]);
        setRepeatTimeEntryFlag(false);
        setEditPosition(editPosition + 1)
        setMapCode(true)
        FieldState.update(FieldState.resetPayLoad);
        FieldState.update(FieldState.removeError);
         setTraversalState(null);
        setIsDraftTime(false);
        onTraverseNext(editTimeEntryList[editPosition + 1]);
      }
      else {
        setCloseConfirmation(true)
      }

    }
  }
  const traverseUp = (forceChange) => {
    if (editTimeEntryList.length > 0 && (editPosition - 1) >= 0) {
      setTraversalState("UP")
      if (!changed || forceChange) {
        setCheckboxDays([]);
        setRepeatTimeEntryFlag(false);
        setEditPosition(editPosition - 1)
        setMapCode(true)
        FieldState.update(FieldState.resetPayLoad);
        FieldState.update(FieldState.removeError);
        setTraversalState(null);
        setRepeatTimeEntryFlag(false);
        setEndDateValue(null);
        setCheckboxDays([]);
        setIsDraftTime(false);
        onTraverseNext(editTimeEntryList[editPosition - 1]);
      }
      else {

        setCloseConfirmation(true)
      }
    }
  }
  /**
  *  in case of edit and clone entries it gets the pre created data from the timeentry and sets it to corresponding fields in popup
  * data - contains the data of created time entry from the previous manage time screen
  */
  useEffect(() => {
    if (data && data.selectedClient) {
      data.is_draft_time === "Y" && setIsDraftTime(true)
      if (data.timerStartDatetime && mode !== "clone") {
        let startdate = data.timerStartDatetime.substring(0, 10).split("-")
        startdate = startdate[1] + "/" + startdate[2] + "/" + startdate[0];
        setDate(new Date(startdate));

      } else {
        let newdate =  new Date(DBCurrentDate);

        setDate(newdate);
      }

      setClient({
        ...client,

        values: [
          {
            isDefault: true,

            id: data.selectedClient.id,

            label: data.selectedClient.description,

            desc: data.selectedClient.description || '',

            code: data.selectedClient.code,
          },
        ],
      });
      setSubClient({
        ...subClient,

        values: [
          {
            isDefault: true,

            id: data.selectedSubClient.id,

            label: data.selectedSubClient.description,

            desc: data.selectedSubClient.description || '',

            code: data.selectedSubClient.code,
          },
        ],
      });

      setMatter({
        ...matter,

        values: [
          {
            isDefault: true,

            id: data.selectedMatter.id,

            label: data.selectedMatter.description,

            desc: data.selectedMatter.description || '',

            code: data.selectedMatter.code,
          },
        ],
      });

    }
    if (data && mode === "edit") {
      setRepeatTimeEntryHide(true);
    }
  }, [data , DBCurrentDate]);

  /** 
   *  call the fields api and load component with fields data whenever the matter is changed
   * */

  useEffect(() => {
    if (matter.values[0].id) {
      loadTimeEntriesPayload(
        client.values[0].id,
        matter.values[0].id
        //delegateId
      );

    }
  }, [matter]);

  useEffect(() => {
    setDurationToggleClick(recordDuration)
  }, [])

  const { isAuth, preferenceApiData, hasMultipleSubClients } = useSelector(
    ({ AuthReducer, PreferenceReducer }) => ({
      isAuth: AuthReducer.isAuth,
      preferenceApiData: PreferenceReducer.preferenceApiData,
      hasMultipleSubClients: PreferenceReducer.hasMultipleSubClients
    })
  );

  useEffect(() => {
    let permissions = permissionsOfUser.find((item) => item.id === "practice");
    if (isAuth && permissions) {
      const action = Preferences.getPreferenceSettings();
      dispatch(action);
    }
  }, [isAuth]);

  useEffect(() => {
    // let permissions = permissionsOfUser.find((item) => item.id === "practice");
    if (preferenceApiData) {
      let autoCap_setting = preferenceApiData.find(
        (preference) => preference.param_key === "AUTO_CAPITALIZE"
      );
      setAutoCapStatus(autoCap_setting && autoCap_setting.param_value);

      let spellCheck_setting = preferenceApiData.find(
        (preference) => preference.param_key === "SPELL_CHECK"
      );
      if (spellCheck_setting) {
        let spell_values = spellCheck_setting.param_value;
        let spell_obj = JSON.parse(spell_values);
        setSpellCheckToggle(Object.keys(spell_obj)?.find((item)=> item === "spell_check_toggle") ? spell_obj.spell_check_toggle : true);
        setForceInvalidStatusToggle(spell_obj.force_invalid_status_toggle);
      }
    }
  }, [preferenceApiData]);


  useEffect(() => {
    let togglePayload = { isRecordedDurationSelected: durationToggleClick }
    addTime.durationToogleSet(togglePayload).then((res) => {
      dispatch(setDurationToggle(durationToggleClick))

    }).catch((err) => {
      dispatch(refreshStoreOnError({ refresh: new Date() }));
    })
  }, [durationToggleClick])
  /* 
  * makes api call to fetch restricted words whenever any of the client, subcli or matter value is changed and has truthy value
  */
  useEffect(() => {
    let clientId = client && client.values && client.values[0].id;
    let subClientId = subClient && subClient.values && subClient.values[0].id;
    let matterObj = matter && matter.values && matter.values[0];
    
    if (clientId && subClientId && matterObj.id) {
      dispatch(
        fetchAllRestrictedWords(`clientId=${clientId}&subClientId=${subClientId}&matterId=${matterObj.id}`)
      );
    }
    else if (clientId && subClientId) {
      dispatch(
        fetchAllRestrictedWords(`clientId=${clientId}&subClientId=${subClientId}`)
      );
    }
    else if (clientId) {
      dispatch(
        fetchAllRestrictedWords(`clientId=${clientId}`)
      );
    }
  }, [matter, client, subClient]);

  /**
   * whenever there is a client change we call the subclient api and if there is only one subclient we set it to subclient field.
   */
  useEffect(() => {
    setLoading(true);
    onClientSelectedRefresh > 0 &&
      loadSubClients("", 1)
        .then((res) => {
          setLoading(false);
          if (res && res.subClients && res.subClients.length === 1) {
            setSubClient({
              ...subClient,
              values: [
                {
                  id: res.subClients[0].subClientId,
                  desc: res.subClients[0].subClientName ? res.subClients[0].subClientName : "",
                  label: res.subClients[0].subClientName,

                  // added code.
                  code: res.subClients[0].subClientNumber,
                },
              ],
            });
          } else {
            setSubClient({
              ...subClient,
              values: [{ id: "", desc: "", label: "" }],
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setSubClient({
            ...subClient,
            values: [{ id: "", desc: "", label: "" }],
          });
        });
  }, [onClientSelectedRefresh]);


  /**
    *  makes an api call to get all the clients on basis of the search text and the page   
    * @param {String} searchText - contains the user typed searchtext
    * @param {Number} page - contains the page number 
    * @return It returns a promise that contains the success or failure object
  */

  const loadClients = (searchText, page = 1, pageOffset = 0) => {
    return addTime.clients(delegateId, page, searchText, pageOffset);
  };

  /**
  *  makes an api call to get all the subClients on basis of the search text and the page   
  * @param {String} searchText - contains the user typed searchtext
  * @param {Number} page - contains the page number 
  * @return It returns a promise that contains the success or failure object
  */
  const loadSubClients = (searchText, page = 1, pageOffset = 0) => {
    return addTime.subClients(
      client.values[0].id,
      delegateId,
      page,
      searchText,
      pageOffset
    );
  };

  /**
  *  makes an api call to get all the matters on basis of the search text and the page   
  * @param {String} searchText - contains the user typed searchtext
  * @param {Number} page - contains the page number 
  * @return It returns a promise that contains the success or failure object
  */
  const loadMatters = (searchText, page = 1, pageOffset = 0) => {
    return addTime.matters(
      subClient.values[0].id,
      delegateId,
      page,
      searchText,
      pageOffset
    );
  };

  /**
  *  makes an api call to get all the individual field values on basis of the search text and the page   
  * @param {String} searchText - contains the user typed searchtext
  * @param {Number} page - contains the page number 
  * @return It returns a promise that contains the success or failure object
  */
  const loadCodes = (fieldName, searchText, page) => {
    let input = {};

    if (fieldName === "TASK_CODE" && isTaskDisplayed) {
      input = {
        matterId: matter.values[0].id,

        field: fieldName,

        parentFieldId: phaseCode,

        page: page,
      };
    } else if (fieldName === "ACTIVITY_CODE" && isActivityDisplayed) {
      input = {
        matterId: matter.values[0].id,

        field: fieldName,

        parentFieldId: taskCode,

        page: page,
      };
    } else {
      input = {
        matterId: matter.values[0].id,
        field: fieldName,
        page: page,
      };
    }


    searchText && (input.searchText = searchText);

    return addTime.search(input, delegateId);
  };


  /**
  *  on selection of a client from the dropdown we update client field with the selected value 
  *  after setting the client it resets the subclient and matter    
  * @param {Object} field - contains selected field object 
  * @param {Object} value - contains the selected client value 
  */
  const onClientSelection = (field, value) => {
    if (value) {
      setClient({
        ...client,

        values: [
          {
            id: value.clientId,

            label: value.client_name,

            desc: value.client_name,

            code: value.code,

            isDefault: true,
          },
        ],
      });
      setOnClientSelectedRefresh(onClientSelectedRefresh + 1);

      setMatter({ ...matter, values: [{ id: "", desc: "", label: "" }] });

      // setClientNumber(value.)

      // dispatchPayload({

      //   type: TYPES.RESET_PAYLOAD,

      // });
      setIsDraftTime(false)
      setChanged(true);
    }
  };

  /**
  *  on selection of a subclient from the dropdown we update subclient field with the selected value 
  *  after setting the subclient it resets matter field 
  * @param {Object} field - contains selected field object 
  * @param {Object} value - contains the selected subclient value 
  */
  const onSubClientSelection = (field, value) => {
    setSubClient({
      ...subClient,

      values: [
        {
          id: value.subClientId,

          desc: value.subClientName,

          label: value.subClientName,

          code: value.subClientNumber,

          // added subclient, changed number to name.
    
        },
      ],
    });
    setMatter({ ...matter, values: [{ id: "", desc: "", label: "" }] });
    setIsDraftTime(false)
    setChanged(true);
    //
  };

  /**
  *  on selection of a matter from the dropdown we update matter field with the selected value 
  * @param {Object} field - contains selected field object 
  * @param {Object} value - contains the selected subclient value 
  */
  const onMatterSelection = (field, value) => {
    setMatter({
      ...matter,

      values: [
        {
          id: value.matterId,

          desc: value.description,

          label: value.description,

          code: value.code,
        },
      ],
    });
    setIsDraftTime(false)
    setChanged(true);
    //
  };


  /**
  *  on selection of a specific code value from the dropdown or by clicking on the radiobutton we update the specific field with the selected value 
  *  It updates the custom store FieldState with the updated selected value for the given field
  * @param {Object} field - contains selected field object 
  * @param {Object} value - contains the selected subclient value 
  */
  const onCodesSelected = (field, value, ignoreIfAlreadySelected) => {
    let fieldcode;
    if (ignoreIfAlreadySelected === true) {
      fieldcode = Object.assign({}, { field: field, selected: "selected" });
    } else {
      fieldcode = Object.assign({}, { field: field, selected: value && value.isDefault ? "deselected" : "selected" });
    }
    let name = field.split("_")[0];
    FieldState.update(FieldState.updatePayload, [
      field,
      value,
      ignoreIfAlreadySelected,
    ]);

    if (fieldsMap && fieldsMap.get(field) && fieldsMap.get(field).dependentChild === true) {
      invokeTypeCodes(name, value.id, matter.values[0].id, "", fieldcode);
    }

    if (name === "RATE") {
      setRateAmountValue("");
      setRateCode(value.id);
      invokeRateCode(value.id, date, delegateId);
    }
    setChanged(true);
  };

  const resetFieldsOnSelectDeselect = (fieldcode, code) => {
    if (fieldcode.selected === "deselected") {
      if (fieldcode.field === "PHASE_CODE") {
        FieldState.update(FieldState.removeField, [{ ...fieldsMap.get("TASK_CODE") }]);
        FieldState.update(FieldState.removeField, [{ ...fieldsMap.get("ACTIVITY_CODE") }]);
      } else if (fieldcode.field === "TASK_CODE") {
        FieldState.update(FieldState.removeField, [{ ...fieldsMap.get("ACTIVITY_CODE") }]);
      }
    } else if (fieldcode.selected === "selected") {
      if (fieldcode.field === "PHASE_CODE") {
        FieldState.update(FieldState.removeField, [{ ...fieldsMap.get("ACTIVITY_CODE") }]);
      }
    }
  }

  const invokeTypeCodes = async (name, value, matterId, mapping, fieldcode) => {

    if (name === "PHASE") {
      setLoading(true);
      setPhaseCode(value);

      await addTime
        .phaseCode(value, matterId, delegateId)
        .then((res) => {
          if (res.fields.length > 0) {
            let task = res.fields;
            task.forEach((code) => {
              fieldsMap.set(code.field, code);
              setFieldsMap(fieldsMap);
              FieldState.update(FieldState.replaceCodeType, [code]);
              //on code deselect/select
              resetFieldsOnSelectDeselect(fieldcode, code);
            });
          }
          setLoading(false);
        })
        .catch(() => {
          dispatch(refreshStoreOnError({ refresh: new Date() }));
          setLoading(false);
        });
    } else if (name === "TASK") {
      if (isActivityDisplayed || mapping === "mapping") {
        setLoading(true);
        setTaskCode(value);
        await addTime
          .taskCode(value, matterId, delegateId)
          .then((res) => {
            if (res.fields.length > 0) {
              fieldsMap.set(res.fields[0].field, res.fields[0]);
              setFieldsMap(fieldsMap);
              FieldState.update(FieldState.replaceCodeType, [res.fields[0]]);
              //on code deselect/select
              resetFieldsOnSelectDeselect(fieldcode, res.fields[0]);
            }
            setLoading(false);
          })
          .catch(() => {
            dispatch(refreshStoreOnError({ refresh: new Date() }));
            setLoading(false);
          });
      }
    }
    if (data.timeEntryId && mode && mode === "edit" && mapCode) {
      data.errors &&
        FieldState.update(FieldState.addError, [JSON.parse(data.errors)]);
      let unmappedError = getComplianceErrorsUnmapped(JSON.parse(data.errors))

      setUnmappedComplianceError(unmappedError);
    }
    setMapCode(false);

  };


  /**
   *  on change of selected rate value or date we make an api call to get the keyextendedflag
   * @param {String} newDate 
   */
  const checkRateAmount = async (newDate) => {
    invokeRateCode(rateCode, newDate, delegateId);
  };


  /**
   *  get keyextended rate value on selecting a rate value or date and set it to the state to display the amount field or not
   * @param {} idvalue 
   * @param {String} _date 
   * @param {Number} delegateID 
   */
  const invokeRateCode = async (idvalue, _date, delegateID) => {
    setLoading(true);
    await addTime
      .rateCode(
        idvalue,
        moment(new Date(_date).toISOString()).format("YYYY-MM-DD"),
        delegateID
      )
      .then((res) => {
        if (res.keyExtendedAmountFlag) {
          setShowRateAmount(true);
        } else {
          setShowRateAmount(false);
        }
        setLoading(false);
      })
      .catch(() => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoading(false);
      });
  };

  /**
  *  api call to get all the fields and their corresponding values 
  * @param {Number} clientd - contains selected clientId 
  * @param {Number} matterId - contains the selected matterId 
  */
  const loadTimeEntriesPayload = async (clientId, matterId) => {
    setLoading(true);
    let codeDependent = false;
    await addTime
      .getFields(matterId)
      .then(async (res) => {
        let codeFields = res.fields.filter(item => item.isDisplay);
        let fieldsData = await addTime.getFieldData(clientId, matterId, delegateId, codeFields);
        fieldsData = fieldsData.filter(item => !Array.isArray(item) );
        setRateAmountValue("");
        setIsTaskDisplayed(false);
        setIsActivityDisplayed(false);
        if (fieldsData.length) {
          fieldsData.forEach((code) => {
            fieldsMap.set(code.field, code);
            setFieldsMap(fieldsMap);

            if (code.field === "RATE_CODE") {
              if (data.timeEntryId && mapCode) {
                let rateItem = data.timeEntryDetail.find((item) => item.fieldName === "RATE_CODE");
                if (rateItem) {
                  setRateCode(rateItem.values.sys_field_value_id);
                  invokeRateCode(rateItem.values.sys_field_value_id, date, delegateId);
                }
              }
              else {
                let idvalue = code.values
                  .slice(0, 3)
                  .find((d) => d.isDefault === true);
                if (idvalue) {
                  setRateCode(idvalue.id); //   // console.log(d);
                  invokeRateCode(idvalue.id, date, delegateId);
                }
              }
            }
            if (
              code.dependentChild !== undefined &&
              code.dependentChild === true &&
              code.isDisplay === true
            ) {

              if (code.field === "PHASE_CODE") {
                codeDependent = true;
                setIsTaskDisplayed(true);
                setIsActivityDisplayed(true);
                let idvalue = code.values
                  .slice(0, 3)
                  .find((d) => d.isDefault === true);
                if (idvalue) {
                  setPhaseCode(idvalue.id);
                }
              }

              if (code.field === "TASK_CODE") {
                codeDependent = true;
                setIsActivityDisplayed(true);
                let idvalue = code.values
                  .slice(0, 3)
                  .find((d) => d.isDefault === true);
                if (idvalue) {
                  setTaskCode(idvalue.id);
                }
              }
            }
          });

          FieldState.update(FieldState.setPayload, [fieldsData]);
          res.hoursIncrementConfig &&
            DurationState.update(DurationState.setIncrements, [
              res.hoursIncrementConfig
                .map((d) => {
                  return Number(d.substr(1)) * 60;
                })
                .sort((a, b) => a - b),
            ]);

          if (res.durationValidation && Array.isArray(res.durationValidation)) {
            DurationState.update(DurationState.setDurationValidation, [
              res.durationValidation
                .map((d) => {
                  return Number(d);
                })
                .sort((a, b) => a - b),
            ]);
          } else {
            DurationState.update(DurationState.setDurationValidation, [null]);
          }
          //data.time ="24:04:41";
          DurationState.update(DurationState.setMaxBillableDuration, [
            res.maxBillableDuration,
          ]);
          DurationState.update(DurationState.setMaxHoursPerDay, [
            res.maxHoursPerDay,
          ]);

          let temp = data.time.split(":");
          if (data.status && data.status === "stopped") {
            DurationState.update(DurationState.onDurationChange, [
              null,
              temp[0],
              temp[1],
              temp[2],
            ]);
            DurationState.update(DurationState.calculateRoundingOffDuration, [
              null,
              temp[0],
              temp[1],
              temp[2],
            ]);
            validationUtils.validateDurationFieldAddTime(res.maxBillableDuration, res.maxHoursPerDay);
            setChanged(true)
          }

          // if we are coming from manage time we need to map the fields from the created timeentry to the existing fields
          if (data.timeEntryId && mapCode) {
            data.is_draft_time && data.is_draft_time === 'Y' && DurationState.update(DurationState.setIncrements, [[3, 6, 15, 30, 60]]);
            if (data && data.billableDuration > 0 && data.time === "0:0") {
              DurationState.update(
                DurationState.setHHMMDurationByBillableDuration,
                [data.billableDuration,
                  null,
                  null
                ])
            } else {
              DurationState.update(
                DurationState.setHHMMDurationByBillableDuration,
                [data.billableDuration,
                temp[0],
                temp[1]
                ]);
            }
            data.amount && setRateAmountValue(data.amount);
            setNarrativeValue(data.narrative);

            let _phaseCode = data.timeEntryDetail.find((item) => item.fieldName === "PHASE_CODE");
            let _taskCode = data.timeEntryDetail.find((item) => item.fieldName === "TASK_CODE");
            if (codeDependent === true) {
              if (_phaseCode) {
                setIsTaskDisplayed(true);
                setIsActivityDisplayed(true);
                invokeTypeCodes("PHASE", _phaseCode.values.sys_field_value_id, matterId).then(() => {
                  if (_taskCode && fieldsMap && fieldsMap.get("TASK_CODE") && fieldsMap.get("TASK_CODE").dependentChild === true) {
                    setIsActivityDisplayed(true);
                    invokeTypeCodes("TASK", _taskCode.values.sys_field_value_id, matterId, "mapping").then(() => {
                      FieldState.update(FieldState.setSelectedPayload, [
                        data.timeEntryDetail,
                      ]);
                    })
                  }
                  else {
                    FieldState.update(FieldState.setSelectedPayload, [
                      data.timeEntryDetail,
                    ]);
                  }
                })
              }
              if (!_phaseCode && _taskCode) {
                setIsActivityDisplayed(true);
                invokeTypeCodes("TASK", _taskCode.values.sys_field_value_id, matterId, "mapping").then(() => {
                  FieldState.update(FieldState.setSelectedPayload, [
                    data.timeEntryDetail,
                  ]);
                })

              }
            }
            else {

              FieldState.update(FieldState.setSelectedPayload, [
                data.timeEntryDetail,
              ]);
            }


          }

          // if we are coming from manage time we need to map the compliance errors from the created timeentry to the existing fields only in case of edit time entry

          if (data.timeEntryId && mode && mode === "edit" && mapCode && !codeDependent) {

            if (data.errors) {
              let complianceErrors = JSON.parse(data.errors);
              FieldState.update(FieldState.addError, [complianceErrors]);
              complianceErrors.forEach((item) => {
                if (item.field === "narrative") {
                  FieldState.update(FieldState.setNarrativeError, [item.message])
                }
                if (item.field === "hours") {
                  FieldState.update(FieldState.setDurationError, [item.message])
                }
                if (item.field === "date") {
                  FieldState.update(FieldState.setDateError, [item.message])
                }
              })
              let unmappedError = getComplianceErrorsUnmapped(complianceErrors)

              setUnmappedComplianceError(unmappedError);
            }
            setMapCode(false)
          }
        }
        setLoading(false);
      })
      .catch(() => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoading(false);
      });
  };

  const onHideSave = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onHideClose = (name) => {
    dialogFuncMapClose[`${name}`](false);
  };

  const onSetshowRWValidationMsg = (isVisible) => {
    setShowRWValidationMsg(isVisible);
    const { isNarrativeWordsValid } = validateNarrative();

    if (isNarrativeWordsValid) {
      buttonClicked();
    }
  };

  const validateDateRangeWithWeekDays = (startDateString, endDateString, weekDays) => {
    let startDate = moment(new Date(startDateString).toISOString()).format("YYYY-MM-DD")

    let endDate = moment(new Date(endDateString).toISOString()).format("YYYY-MM-DD");

    const oneDayTotalMilliseconds = 1000 * 60 * 60 * 24;

    const diffInTimeInMilliseconds = (new Date(endDate)).getTime() - (new Date(startDate)).getTime();

    const totalDaysInBetween = Math.round(diffInTimeInMilliseconds / oneDayTotalMilliseconds);

    if (totalDaysInBetween > 6) {
      return true;
    }
    else {
      let weekDaysArrayInNumber = jsUtils.getWeekDayArrayInNumberFormats(weekDays) || [];

      let dayNumber = moment(new Date(startDate + " 00:00:00")).add(1, "days").day(); //Sun-Sat -> 0-6
      let daysInBetweenList = [];
      for (let i = 0; i < totalDaysInBetween; i++) {
        daysInBetweenList.push((dayNumber + i) % 7);
      }
      let validationRule = true;
      for (let count = 0; count < weekDaysArrayInNumber.length; count++) {
        if (daysInBetweenList.findIndex((item) => weekDaysArrayInNumber[count] === item) === -1) {
          return false;
        }
      }
      return validationRule

    }

  }

  const onSaveWithErrors = () => {
    setLoading(true);
    setChanged(false);
    let payload = createPayload();

    if (data.timeEntryId && mode !== "clone") {
      payload.mode = "edit";
      payload.saveWithErrors = true;
      payload.narrativeId = data.narrativeId;
      manageTime
        .post(payload, data.timeEntryId)
        .then((res) => {
          setLoading(false);
          let showInformationDialog=false;
          FieldState.removeError();
          /*
           //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
          if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
            onSetInformationalMessage(res)
            showInformationDialog=true;
          }
           */
         if(bulkEdit) {
              if(traversalState && traversalState === "UP"){
                traverseUp(true);
              }
              else{
                traverseDown(true);
              }   
          }
          
          resetNarrativeAndDuration();
          setShowErrorRepeatTimeEntry(false);
          setRepeatTimeEntryFlag(false);
          setEndDateValue(null);
          setCheckboxDays([]);
          if (!showInformationDialog && functionality !== "updateEachSelectedTE") {
            onSaveHandler(afterSavingPreference);
          }  
          dispatch(setErrorMessage({ severity: 'success', message: getCode("TIME_ENTRY_UPDATED_SUCCESSFULLY"), errorCode: 200, life: 5000 }));
        })
        .catch((err) => {
          setLoading(false);
          dispatch(setErrorMessage({ severity: 'error', message: getCode("UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));
        });
    } else {
      let showInformationDialog=false;
      repeatTimeEntryFlag && clickStatus === "saveAndRelease" && !complianceCheck ? payload.mode = "saveAndRelease" : payload.mode = "save"
      payload.saveWithErrors = true;
      addTime
        .post(payload)
        .then((res) => {
          setLoading(false);
          FieldState.removeError();
          setUnmappedComplianceError([])
          /*
           //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
          if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
            onSetInformationalMessage(res);
            showInformationDialog=true;
          }
          */    
          setShowErrorRepeatTimeEntry(false);
          setRepeatTimeEntryFlag(false);
          setEndDateValue(null);
          setCheckboxDays([]) ;
          resetNarrativeAndDuration();
          if (!showInformationDialog && functionality !== "updateEachSelectedTE") {
            onSaveHandler(afterSavingPreference);
          }  
          dispatch(setErrorMessage({ severity: 'success', message: getCode("TIME_ENTRY_ADDED_SUCCESSFULLY"), errorCode: 200, life: 5000 }));
        })
        .catch((err) => {

          setLoading(false);
          dispatch(setErrorMessage({ severity: 'error', message: getCode("UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));
        });
    }
  };

  const renderFooter = (name) => {
    return (
      <div>

        <Button
          label={getCode("STAY")}
          icon="pi pi-times"
          onClick={() => {onHideSave(name); setOnClickCross(false)}}
          className="p-button-text"
          data-testid="stay"
        />

        {(!repeatTimeEntryFlag || (repeatTimeEntryFlag && !(!endDateValue || checkboxDays.length === 0))) && <Button
          label={getCode("SAVE_WITH_ERRORS")}
          icon="pi pi-check"
          onClick={() => {
            onSaveWithErrors();
            onHideSave(name);
          }}
          autoFocus
          data-testid="saveWithError"
        />}
      </div>
    );
  };

  const renderFooterClose = (name) => {
    return (
      <div>
        <Button
          label={getCode("LEAVE")}
          onClick={() => {
            if (traversalState) {
              setChanged(false);
              traversalState === "UP" ? traverseUp(true) : traverseDown(true);
              onHideClose(name);
            }
            else {
              hideDialog();
              onHideClose(name);
            }

          }}
          className="p-button-text"
          data-testid="leave"
        />
        <Button
          label={getCode("SAVE")}
          onClick={() => {
            // setOnClickCross(true);
          if((maximumLimitType === "W" && (maxChars- noOfChars) < 0) && !ignoreClicked){
              setShowMaxLimitMsg(true);
           }
           else{
            onSave();}
            onHideClose(name); 
          }}
          autoFocus
          data-testid="save"
        />
      </div>
    );
  };
  /**
   *  creates a payload object from the all the selected fields in the popup
   * @returns a payload object that contains all the selected fields data
   */
  const createPayload = () => {
    let savePayload = {
      clientId: client.values[0].id,

      subClientId: subClient.values[0].id,
      subClient: subClient.values[0].code,
      matterId: matter.values[0].id,

      dateTime: moment(new Date(date).toISOString()).format("YYYY-MM-DD"),

      recorded_duration_hrs: DurationState.hh, //parseInt(time.split(":")[0]) ||

      recorded_duration_mins: DurationState.mm, //parseInt(time.split(":")[1]) ||

      billing_duration: DurationState.duration,

      client: client.values[0].code,

      matter: matter.values[0].code,

      narrative: narrativeValue,

      attorney: attorneyCode,

      delegateUserId: delegateId,

      mode: "",
      is_draft_time: "",
      saveWithErrors: "",
      spellCheck: forceInvalidStatusToggle ? spellCheckToggle : false,
      forceInvalid: forceInvalidStatusToggle,
      draftToRealTimeEntry: (!isDraftTime && data.is_draft_time === "Y") || false
    };
    if (endDateValue) {
      savePayload["endDateTime"] = moment(new Date(endDateValue).toISOString()).format("YYYY-MM-DD");

      savePayload["is_recurring"] = true;

      savePayload["recurring_days"] = checkboxDays;
    }
    if (data.ethicalWallFlag) {
      savePayload["ethicalWallFlag"] = data.ethicalWallFlag
    }
    FieldState.payload.forEach((code) => {
      let selected =
        code.isDisplay === true &&
        code.values.find((d) => d.isDefault === true);
      if (selected) {
        if (code.field === "MAKE_NO_CHARGE") {

          savePayload["makeNoChargeId"] = code.fieldId;

          savePayload["makeNoChargeValueId"] = selected.id;

          savePayload["makeNoCharge"] = selected.displayCode.toString().slice(0, 1);
        }
        else {
          let label = code.field.toLowerCase().split("_");

          let valueId = label[0] + "ValueId";

          let id = label[0] + "Id";

          let displayCode = label[0] + "Code";

          savePayload[valueId] = selected.id;

          savePayload[id] = code.fieldId;

          savePayload[displayCode] = selected.displayCode;
        }
      }
    });
    if (showRateAmount) {
      savePayload["amount"] = parseInt(rateAmountValue);
    }
    else {
      savePayload["amount"] = null;
    }
    return savePayload;
  };

  const onNarrativeCorrection = (action, restrictedWordsNew, enteredRestrictedWordsNew) => {
    if (action === "Ignore All") {
      if (restrictedWordsNew) {
        //setRestrictedWords(restrictedWordsNew);
        dispatch(setHighlightRestrictedWords(restrictedWordsNew));
      }
      if (enteredRestrictedWordsNew)
        setEnteredRestrictedWords(enteredRestrictedWordsNew);
    }
    //commented as per changes in AF-1779
    // else if (action === "Ignore All") {
    //   setRestrictedWords([]);
    //   setEnteredRestrictedWords([]);
    // }
  }

  const validateNarrative = () => {

    let isNarrativeWordsValid = true, isNarrativeCharLimitValid = true;
    if (
      narrativeValue !== "" &&
      enteredRestrictedWords &&
      enteredRestrictedWords.length > 0
    ) {
      isNarrativeWordsValid = false;
    }
    if(maximumLimitType === "E"){
    if ((maxChars - noOfChars) < 0) {
      isNarrativeCharLimitValid = false;
      FieldState.update(FieldState.setNarrativeError, [
        getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
      ]);
    }}
    else if(maximumLimitType === "W"){
      if ((maxChars- (maxChars- noOfChars)) > 5000) {
        isNarrativeCharLimitValid = false;
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
      } 
    }
    return { isNarrativeWordsValid, isNarrativeCharLimitValid };
  }
  /** set compliance errors for the unmapped codes on screen
      * @param {Array} res -array of compliance errors
      */
  const getComplianceErrorsUnmapped = (res) => {
    let unmappedError = [];
    let Fields = [];
    FieldState.payload.forEach((code) => {

      if (code.field === "MAKE_NO_CHARGE") {
        Fields.push("makenocharge");
      }
      if (code.field === "UDF2") {
        Fields.push("time_usercode2")
      }
      if (code.field === "UDF3") {
        Fields.push("time_usercode3")
      }
      if (code.field === "ACTIVITY_CODE") {
        Fields.push("time_usercode1")
      }
      if (code.field === "TASK_CODE") {
        Fields.push("submatter")
      }
      if (code.field === "PHASE_CODE") {
        Fields.push("phase")
      }
      if (code.field === "CITY_CODE") {
        Fields.push("city")
      }
      if (code.field === "SERVICE_CODE") {
        Fields.push("servicecode")
      }
      else {
        Fields.push(code.field.toLowerCase().replace("_", ""))
      }
    });

    /** check for whether the compliance fields matches any existing fields
     * if they match or are constant fields like client matter date hours and narrative then do not set any code to unmapped error array
     * else set all the items to the unmapped array which in turn would be set to the state
    * @param {Array} res -array of compliance errors
    * @param {Array} Fields - array of codes that we get from fields api
    */
    res && res.forEach((item) => {
      let index = Fields.findIndex((code) => code === item.field)
      if (item.field === "ratecode") {
        if (Fields.findIndex((code) => code === "ratecode") === -1) {
          unmappedError.push(item);
        }

      }
      if (index === -1 && item.field !== "client" && item.field !== "matter" && item.field !== "date" && item.field !== "hours" && item.field !== "narrative" && item.field !== "amount") {
        unmappedError.push(item);
      }
    })
    return unmappedError;
  }

  const saveNewTimeEntry = (savePayload) => {
    setLoading(true);
    if ((mode === "clone" || mode === "edit") && isDraftTime) {
      savePayload.mode = "draft";
      savePayload.is_draft_time = "Y";
    }

    endDateValue ? savePayload.saveWithErrors = true : savePayload.saveWithErrors = false;
    addTime
      .post(savePayload)
      .then((res) => {
        setLoading(false);
        let showInformationDialog = false;

        let isSuccess = (res && res.message.length && res.message[0].message) || (res && res.message.length && res.message[0].errors && res.message[0].errors.length) ? false : true;
        if (!isSuccess) {
          let unmappedError = endDateValue ? getComplianceErrorsUnmapped(res.message[0].errors || res.message) : getComplianceErrorsUnmapped(res.message);
          setUnmappedComplianceError(unmappedError);
          /*
           //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
          if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0) && endDateValue){
            onSetInformationalMessage(res);
          }
          */
          if(endDateValue) {
            dispatch(setErrorMessage({ severity: 'success', message: jsUtils.getLiteralValue(message.REPEATED_TIME_ENTRY_ERROR), errorCode: 200, life: 5000 }));
            setChanged(false)
            data.timeEntryId && hideDialog();
          } else {
            FieldState.update(FieldState.addError, [res.message]);
            setDisplayConfirmation(true);
          }
          
        } else {
          
          setChanged(false);
          
          setShowErrorRepeatTimeEntry(false)
          setRepeatTimeEntryFlag(false);
          setEndDateValue(null);
          setCheckboxDays([]) ;
          resetNarrativeAndDuration();
          /*
           //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
          if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
            onSetInformationalMessage(res)
            showInformationDialog=true
          }
          */
          if (!showInformationDialog && functionality !== "updateEachSelectedTE") {
          onSaveHandler(afterSavingPreference);
          }
          dispatch(setErrorMessage({ severity: 'success', message: (savePayload.mode === "save" || savePayload.mode === "draft") ? getCode("TIME_ENTRY_ADDED_SUCCESSFULLY") : getCode("TIME_ENTRY_SAVED_AND_RELEASED"), errorCode: 200, life: 5000 }));
        }
      })

      .catch((err) => {
        setLoading(false);
        dispatch(setErrorMessage({ severity: 'error', message: getCode((err && err.message && err.message[0].message) || (err && err.message) || "UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));

      });

  }

  /** 
   * To Save the time entry on save button clicked.
   * 
   * @param {Boolean} toggleSpeechRecog
   * 
   */

  const onSave = () => {
    if (!toggleSpeechRecog) {
      setToggleSpeechRecog(!toggleSpeechRecog);
      setIsNarrativeDisabled(false);
      settranscribeReady(false);
      setpresignedURL("");
    }
    setButtonClicked(() => saveApiCall);
    const { isNarrativeWordsValid, isNarrativeCharLimitValid } = validateNarrative();
    if (!isNarrativeWordsValid) {
      setShowRWValidationMsg(true);
    } else if (!isNarrativeCharLimitValid) {
      return;
    } else {
      saveApiCall();
    }
  }


  const getDatePlusOneCopy = (_date) => {
    let dt = new Date(_date);
    let newdate = new Date(dt);
    newdate.setDate(newdate.getDate() + 1);
    return moment(new Date(newdate).toISOString()).format("YYYY-MM-DD")
  };
/*
 //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
  const onSetInformationalMessage=(res)=>{
    let amountInformation=res.informationalMessage.amount_information || "";
          let hoursInformation=res.informationalMessage.hours_information || "";
          let disclaimerInformation=res.informationalMessage.disclaimer || "";
          let finalMessage=amountInformation+"\n"+hoursInformation;
          if( disclaimerInformation && (disclaimerInformation !=="")){
            finalMessage=finalMessage+"\n"+"Disclaimer : "+disclaimerInformation;
          }
          setInformationalMessage(finalMessage);
          setShowInformationalPopup(true);
  }
*/
  const saveApiCall = () => {
    let savePayload = createPayload();
    setLoading(true);
    setUnmappedComplianceError([])
    if (data.timeEntryId && mode !== "clone") {
      savePayload.mode = "edit";
      if (isDraftTime) {
        savePayload.is_draft_time = "Y";
      }
      endDateValue ? savePayload.saveWithErrors = true : savePayload.saveWithErrors = false;
      savePayload.narrativeId = data.narrativeId;
      savePayload["endDateTime"] = "";

      savePayload["is_recurring"] = false;

      savePayload["recurring_days"] = [];
      manageTime

        .post(savePayload, data.timeEntryId)

        .then((res) => {
          let showInformationDialog=false;
          setLoading(false);

          let isSuccess = (res && res.message.length && res.message[0].message) || (res && res.message.length && res.message[0].errors && res.message[0].errors.length) ? false : true;
          if (!isSuccess) {
            setComplianceErrorCheck(true);
            /*
            * If we have any compliance errors we can update the Fields with corresponding errors
            */
            setUnmappedComplianceError(endDateValue ? getComplianceErrorsUnmapped(res.message[0].errors || res.message) : getComplianceErrorsUnmapped(res.message) );
            if(endDateValue) {
              FieldState.update(FieldState.addError, [res.message[0].errors || res.message]);              
              
                if(bulkEdit) {
                  if(traversalState === "UP") {
                    traverseUp(true);
                  } else {
                    traverseDown(true);
                  } 
                }
                dispatch(setErrorMessage({ severity: 'success', message: jsUtils.getLiteralValue(message.REPEATED_TIME_ENTRY_ERROR), errorCode: 200, life: 5000 })); 
               if (!showInformationDialog && functionality !== "updateEachSelectedTE") {
                onSaveHandler(afterSavingPreference);
               } else if (functionality === "updateEachSelectedTE" && onClickCross) {
                 hideDialog(); 
               }
               
            } else {
              FieldState.update(FieldState.addError, [res.message]);
              setDisplayConfirmation(true);
            }
            
          } else {
            setComplianceErrorCheck(false);
            setChanged(false);
            setUnmappedComplianceError([])
            
            setShowErrorRepeatTimeEntry(false)
            setRepeatTimeEntryFlag(false);
            setEndDateValue(null);
            setCheckboxDays([]) ;
            resetNarrativeAndDuration();
            /*
            //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
            if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
              onSetInformationalMessage(res)
              showInformationDialog=true
              }
            */
            if(bulkEdit) {
              if(traversalState === "UP"){
                traverseUp(true);
              }
              else{
                traverseDown(true);
              }
            }
            dispatch(setErrorMessage({ severity: 'success', message: getCode("TIME_ENTRY_UPDATED_SUCCESSFULLY"), errorCode: 200, life: 5000 }));
            if (!showInformationDialog && functionality !== "updateEachSelectedTE") {
              onSaveHandler(afterSavingPreference);
            } else if (functionality === "updateEachSelectedTE" && onClickCross) {
              hideDialog(); 
            } 
            
          }
        })

        .catch((err) => {
          setLoading(false);
          dispatch(setErrorMessage({ severity: 'error', message: getCode((err && err.message && err.message[0].message) || (err && err.message) || "UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));
        });

    } else {
      savePayload.mode = "save"

      saveNewTimeEntry(savePayload)
    }
  };

  /** 
   * To Save the time entry on saveAndRelase button clicked.
   * 
   * @param {Boolean} toggleSpeechRecog
   * 
   */

  const onSaveAndRelease = () => {
    if (!toggleSpeechRecog) {
      setToggleSpeechRecog(!toggleSpeechRecog);
      setIsNarrativeDisabled(false);
      settranscribeReady(false);
      setpresignedURL("");
    }

    setButtonClicked(() => saveAndReleaseApiCall);
    const { isNarrativeWordsValid, isNarrativeCharLimitValid } = validateNarrative();
    if (!isNarrativeWordsValid) {
      setShowRWValidationMsg(true);
    } else if (!isNarrativeCharLimitValid) {
      return;
    } else {
      saveAndReleaseApiCall();
    }
  }

  const saveAndReleaseApiCall = () => {
    let savePayload = createPayload();
    setLoading(true);
    setUnmappedComplianceError([])

    savePayload.mode = "saveAndRelease";
    endDateValue ? savePayload.saveWithErrors = true : savePayload.saveWithErrors = false;
    if (data.timeEntryId && mode !== "clone") {
      savePayload.narrativeId = data.narrativeId;
      savePayload.timeEntryId = data.timeEntryId;
      savePayload["endDateTime"] = "";

      savePayload["is_recurring"] = false;

      savePayload["recurring_days"] = [];
      manageTime

        .post(savePayload, data.timeEntryId)

        .then((res) => {
          setLoading(false);
          let showInformationDialog=false;
          let isSuccess = (res && res.message.length && res.message[0].message) || (res && res.message.length && res.message[0].errors && res.message[0].errors.length) ? false : true;

          if (!isSuccess) {
            setComplianceErrorCheck(true);
            let unmappedError = endDateValue ? getComplianceErrorsUnmapped(res.message[0].errors || res.message) : getComplianceErrorsUnmapped(res.message);
            setUnmappedComplianceError(unmappedError);
            if(endDateValue) {
              FieldState.update(FieldState.addError, [res.message[0].errors || res.message]);
              
             
              if(bulkEdit) {
                if(traversalState === "UP") {
                  traverseUp(true);
                } else {
                  traverseDown(true);
                }
              } 
              if (!showInformationDialog && functionality !== "updateEachSelectedTE") {
                onSaveHandler(afterSavingPreference);
              }  
              dispatch(setErrorMessage({ severity: 'success', message: jsUtils.getLiteralValue(message.REPEATED_TIME_ENTRY_ERROR), errorCode: 200, life: 5000 }));
            } else {
              FieldState.update(FieldState.addError, [res.message]);
              setDisplayConfirmation(true);
            }  

            //1 api error
          } else {
            setComplianceErrorCheck(false);
            setChanged(false);
            setUnmappedComplianceError([])
            
            resetNarrativeAndDuration();
            setShowErrorRepeatTimeEntry(false);
            setRepeatTimeEntryFlag(false);
            setEndDateValue(null);
            setCheckboxDays([]) ;
            /*
            //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
            if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
              onSetInformationalMessage(res)
              showInformationDialog=true
              }
           */
            if(bulkEdit) {
              if(traversalState && traversalState === "UP"){
                traverseUp(true)
              }
              else{
                traverseDown(true)
              }
            }
            if (!showInformationDialog && functionality !== "updateEachSelectedTE") {
              onSaveHandler(afterSavingPreference);   
            }
            dispatch(setErrorMessage({ severity: 'success', message: getCode("TIME_ENTRY_SAVED_AND_RELEASED"), errorCode: "X", life: 5000 }));         
          }

        })

        .catch((err) => {
          setLoading(false);
          dispatch(setErrorMessage({ severity: 'error', message: getCode((err && err.message && err.message[0].message) || (err && err.message) || "UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));
         });

    } else {
      saveNewTimeEntry(savePayload)
    }
  };

  const toast = useRef(null);

  const hideDialog = () => {
    if (!Loading) {
      closed();
    }
  };


  useEffect(() => {
    document.title = jsUtils.getLiteralValue(pageTitle);
    if(mode === 'edit'){ //button will be enabled only if any field value is changed
      setSaveButtonDisable(true);
    } else if (mode ==="clone") { // button will never be disabled
      setSaveButtonDisable(false);
    }
  }, []);


  const onChangeMM = (e) => {
    let minutes = e.currentTarget.value.toString();
    if (minutes.length > 2 || minutes > 59 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    FieldState.update(FieldState.setDurationError);
    DurationState.update(DurationState.onMinutesOrHoursChange, [
      DurationState.hh,
      minutes,
    ]);
  };

  const onChangeHH = (e) => {
    let hours = e.currentTarget.value.toString();
    if (hours.length > 2 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    FieldState.update(FieldState.setDurationError);
    DurationState.update(DurationState.onMinutesOrHoursChange, [
      hours,
      DurationState.mm,
    ]);
  };

  const onChangeDuration = (e) => {
    let duration = e.currentTarget.value.toString();
    if (duration.length > 7 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    DurationState.update(DurationState.onDurationFieldChange, [duration]);
  };

  const selectedClientID =
    data && data.selectedClient && data.selectedClient.id;

  const onChange = (event) => {
    FieldState.update(FieldState.setNarrativeError, [""]);
    let text = event.target.value;
    setChanged(true)
    const { lastWord, lastWordStartIndex } = NarrativeHelper.getLastWordAndIndex(event);
    onChangeNarrative(text, [], null, lastWord, lastWordStartIndex);
    //event.preventDefault();
  };

  const onChangeNarrative = (text, _enteredRestrictedWords, action, lastWord, lastWordStartIndex) => {
    if (text !== null) {
      hOnChange(text, action, lastWord, lastWordStartIndex);
    }
    if (_enteredRestrictedWords) {
      setEnteredRestrictedWords(_enteredRestrictedWords);
    }
  };

  //resets values of Narrative and Duration fileds to default values
  const resetNarrativeAndDuration = useCallback(() => {
    setNarrativeValue("");
    DurationState.update(DurationState.setDuration, ["0.00"]);
    DurationState.update(DurationState.setHH, [0]);
    DurationState.update(DurationState.setMM, [0]);
  }, [changed]);

  const onChangeCheckboxDays = (days) => {
    setShowErrorRepeatTimeEntry(false);
    setCheckboxDays(days)
  }

  useEffect(()=>{
    if(mode === 'edit'){
      getSaveButtonStatus();
    }
    
  },[changed]);

  const getSaveButtonStatus = () => {
      changed ? setSaveButtonDisable(false):setSaveButtonDisable(true);
  }

  const rwActionChanges = (status) => setChanged(status);

  
  const resetTabToCrossButton = (evnt) => {    
    if(evnt.key==="Tab") {
      document.querySelector(".p-dialog-header-icons > button").focus();
      evnt.preventDefault();
    }
  }

  const resetTabToCrossButtonNarrative = (evnt) => {    
    if(saveButtonDisable && evnt.key==="Tab") {
      document.querySelector(".p-dialog-header-icons > button").focus();
      evnt.preventDefault();
    }
  }
  
  
  return (
    <div className="af-modal-dialog-addtime">
      <Toast ref={toast} />

      <Dialog
        header=""
        visible={show}
        className="af-modal-dialog"
        closable={false}
        onHide={()=>{}}
      >
        <div className="p-dialog-header">
          <a href={`${helpPageUrl}${HELP_LINKS.TIME_ENTRY}`} target="_blank"><img
            title={tooltips[TOOLTIPS_KEYS.TOOLTIP_HELP]}
            className="af-help-addtime"
            src="/images/svg/Help_icon.svg"
            alt=""
            data-testid="help-id"
          /></a>
          <div className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
              <li className="">
                <span className="p-menuitem-link">
                  <span className="p-menuitem-text">{getCode("TIME_MANAGEMENT")}</span>
                </span>
              </li>

              <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>

              <li className="">
                <span className="p-menuitem-link">
                  <span className="p-menuitem-text">{getCode("TIME_ENTRY")}</span>
                </span>
              </li>
            </ul>
          </div>

          {bulkEdit && <div className="dialog-title" style={{ float: "right" }}>
            <span>{`${editPosition + 1} of ${editTimeEntryList.length}`}</span>
            <span
              tabIndex={0}
              className="pi pi-chevron-up"
              onClick={() => {
                traverseUp();
              }}
              onKeyPress={(e) => {traverseUp();}} 
              data-testid="traverseUp"             
            ></span>
            <span
              tabIndex={0}
              className="pi pi-chevron-down"
              onClick={() => {
                traverseDown();
              }}
              onKeyPress={(e) => {traverseDown();}} 
              data-testid="traverseDown"                   
            ></span>
          </div>}

           <div className="p-dialog-header-icons">
            <button onClick={() => {
              if (!changed) { // If no field is changed/modified
                hideDialog()
              }
              else {
                setOnClickCross(true);
                setCloseConfirmation(true); 
              }
            }}
            tabIndex={0}
            type="button" className="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close" title={tooltips[TOOLTIPS_KEYS.TOOLTIP_CLOSE]}>
              <span className="p-dialog-header-close-icon pi pi-times"></span>
            </button>
          </div> 
        </div>
        {Loading && (
          <div className="af-spinner">
            <Spinner />
          </div>
        )}

        <div className="af-popup-body">
          <div className="p-grid">
            <div className="p-sm-12 p-md-12 p-lg-8">
              <div className="at-dbcol">
                <div className="af-popup-cnt af-client-bg">
                  <div className="af-btn-sect">
                    <label htmlFor="icon">
                      {`${client.displayLabel} `}

                      <span className="af-req-star">*</span>
                    </label>

                    <div className="af-error-msg-display">
                      {FieldState.clientError}
                    </div>

                    {selectedClientID ? (
                      <RadioDropDownComponent
                      
                        columns={["Number", "Client"]}
                        displayType="text"
                        textDisplayPropName="desc"
                        onChange={(field, value) => {
                          onClientSelection(field, value);
                          FieldState.update(FieldState.setClientError);
                        }}
                        listSource={async (searchText, page, pageOffset) => {
                          let list = await loadClients(searchText, page, pageOffset);
                        
                          return list;
                        }}
                        data={client}
                        fieldName="clients" // Pass this param if API is not responding 20records in each response
                        tabOrder={0}
                     
                      />
                    ) : (
                      <input                      
                        tabIndex={0}
                        type="text"
                        placeholder={getCode("ENTER_UP_TO_10_CHARACTERS")}
                        className="time-draft-input"
                        id="time-draft-input-client"
                        maxLength={message.MAX_CHAR_LIMIT_CLIENT_MATTER}
                        autocomplete="off"
                      />
                    )}
                  </div>
                  <div className="af-btn-sect">
                  { hasMultipleSubClients &&  <><label htmlFor="icon">
                      {`${subClient.displayLabel} `}

                      <span className="af-req-star">*</span>
                    </label>
 
                    <RadioDropDownComponent
                    // data-testid = "inputDropdown-id"
                      columns={["Number", "Sub-Client"]}                     
                      
                      displayType="text"
                      textDisplayPropName="desc"
                      onChange={(field, value) => {
                        onSubClientSelection(field, value);
                      }}
                      listSource={async (searchText, page, pageOffset) => {
                        let list = await loadSubClients(searchText, page, pageOffset);                        

                        return list;
                      }}
                      data={subClient}          
                     

                      fieldName="subClients" // Pass this param if API is not responding 20records in each response
                      tabOrder={0}
                   
                    /></> }
                  </div>

                  <div className="af-btn-sect">
                    <label htmlFor="icon">
                      {`${matter.displayLabel} `}

                      <span className="af-req-star">*</span>
                    </label>

                    <div className="af-error-msg-display">
                      {FieldState.matterError}
                    </div>

                    {selectedClientID ? (
                      <RadioDropDownComponent
                      // data-testid = "inputDropdown-id"
                        columns={[getCode("NUMBER"), getCode("MATTER_DESCRIPTION")]}
                        displayType="text"
                        textDisplayPropName="desc"
                        onChange={(field, value) => {
                          onMatterSelection(field, value);
                          FieldState.update(FieldState.setMatterError);
                        }}
                        listSource={async (searchText, page, pageOffset) => {
                          let list = await loadMatters(searchText, page, pageOffset);                  
                          return list;                          
                        }}
                        data={matter}
                        fieldName="matters" // Pass this param if API is not responding 20records in each response
                        tabOrder={0}
                        
                      />
                    ) : (
                      <input
                        tabIndex={0}
                        type="text"
                        placeholder={getCode("ENTER_UP_TO_10_CHARACTERS")}
                        className="time-draft-input"
                        id="time-draft-input-matter"
                        maxLength={message.MAX_CHAR_LIMIT_CLIENT_MATTER}
                        autocomplete="off"
                      />
                    )}
                  </div>

                  <div className="af-error-msg-display">
                    {FieldState.dateError}
                  </div>

                  <div className="af-lookup-cont">
                    <div style={{ display: "flex" }}>
                      <label htmlFor="icon">
                        {getCode("DATE")} <span className="af-req-star">*</span>
                      </label>

                    </div>
                    <div className="p-field">
                      <Calendar                      
                        tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                        tooltipOptions={{ className: 'white-tooltip', position: 'top', showDelay: 700 }}
                        placeholder={dateFormat}
                        appendTo={document.body}
                        dateFormat={
                          dateFormat &&
                          dateFormat

                            .toLowerCase()

                            .replace("yyyy", "yy")
                        }
                        readOnlyInput
                        yearNavigator
                        yearRange="1600:6030"
                        id="icon"
                        value={date}
                        showIcon={true}
                        maxDate={endDateValue && new Date(moment(new Date(endDateValue)).subtract(1, "days"))}
                        onChange={(e) => {
                          getDatePlusOneCopy(e.value);

                          setChanged(true);
                          // console.log(`${moment(new Date(e.value)).format("YYYY-MM-DD")}`)
                          setDate(e.value);
                          checkRateAmount(e.value);
                          FieldState.update(FieldState.setDateError);
                          document.querySelector('#icon > input').focus(); 
                        }}
                      />

                    </div>
                    {showErrorRepeatTimeEntry ? (!endDateValue || checkboxDays.length === 0) ? <div className="af-error-msg-display">
                      {jsUtils.getLiteralValue('DAYS_OF_WEEK_AND_END_DATE_MUST_BE_CHECKED_OR_REPEAT_TIME_ENTRY_CLEARED')}
                    </div> :
                      <div className="af-error-msg-display">
                        {jsUtils.getLiteralValue('SELECTED_DAYS_NOT_IN_RANGE')}
                      </div> : <></>
                    }
                    <div style={{ display: "flex", paddingLeft: "1rem" }}>
                      {!repeatTimeEntryHide && <div>
                        <CheckBox tabOrder={0} id={"repeattime"} onSelectionChange={() => { 
                          setShowErrorRepeatTimeEntry(false);
                          setRepeatTimeEntryFlag(!repeatTimeEntryFlag); 
                          if(repeatTimeEntryFlag) {
                            setEndDateValue(null); setCheckboxDays([]);
                          }  }} checked={repeatTimeEntryFlag} />
                      </div>}
                      {!repeatTimeEntryHide && <div style={{ color: "#0050c3" }}>
                        <label className={!repeatTimeEntryFlag ? "repeatTimeEntryLabel" : "repeatTimeEntryLabelSelected"} htmlFor={"repeattime"}>{jsUtils.getLiteralValue("REPEAT_TIME_ENTRY")}</label>
                      </div>}
                    </div>
                    {repeatTimeEntryFlag && <RepeatTimeEntry onChange={onChangeCheckboxDays} startDate={date} endDateValue={endDateValue} setEndDateValue={setEndDateValue} setShowErrorRepeatTimeEntry={setShowErrorRepeatTimeEntry} setChanged={setChanged}/>}
                  </div>
                  <div className="af-error-msg-display">
                    {FieldState.durationError}
                  </div>
                  <div className="af-lookup-cont">
                    <div className="af-duration-header">
                      {durationToggleClick ? (
                        <div className="af-time-label-duration">
                          <label htmlFor="time">
                            {" "}
                            {getCode("DURATION")} <span className="af-req-star">*</span>
                          </label>
                        </div>
                      ) : (
                        <div className="recoded-duration af-time-post">
                          <div className="af-time-label">
                            <label htmlFor="time">
                              {" "}
                              {getCode("RECORDED_DURATION")}{" "}
                              <span className="af-req-star">*</span>
                            </label>
                          </div>
                          <div className="af-time-label-bd af-time-post">
                            <label htmlFor="time">
                              {" "}
                              {getCode("BILLABLE_DURATION")}{" "}
                              <span className="af-req-star">*</span>
                            </label>
                          </div>
                        </div>
                      )}

                      <ToggleButtonComponent toggleClick={toggleClick} title={tooltips[TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]} />
                    </div>

                    <div className="af-input-lookup">
                      {!durationToggleClick ? (
                        <div className="billable-duration-col">
                          <div className="hour-col">
                            <InputText
                              tabIndex={0}
                              data-testid = "hour-id"
                              id="hourstxt"
                              type="number"
                              value={DurationState.hh}
                              onKeyPress={(e) => (e.key === "e" || e.key === "E") ? e.preventDefault() : e}
                              onChange={onChangeHH}
                              onBlur={() =>  validationUtils.validateDurationFieldAddTime()}
                              autoComplete="off"
                            />{" "}
                            <p>{getCode("HH")}</p> <span className="sp-coln">:</span>
                          </div>

                          <div className="min-col">
                            <InputText
                              tabIndex={0}
                              data-testid = "min-id"
                              id="mintxt"
                              type="number"
                              value={DurationState.mm}
                              onKeyPress={(e) => (e.key === "e" || e.key === "E") ? e.preventDefault() : e}
                              onChange={onChangeMM}
                              onBlur={() =>  validationUtils.validateDurationFieldAddTime()}
                              autoComplete="off"
                            />{" "}
                            <p>{getCode("MM")}</p>
                            <span className="sp-eql">=</span>
                          </div>

                          <div className="bil-col">
                            {" "}
                            <InputText
                              value={DurationState.duration}
                              disabled
                              id="billTxt"
                              type="text"
                              autoComplete="off"
                            />{" "}
                            <p>x.xx</p>
                          </div>
                        </div>
                      ) : (
                        <div className="duration-col">
                          <span className="duration-input-margin">
                            <InputText
                            data-testid = "duration-txt"
                             tabIndex={0}
                              type="number"
                              id="durationtxt"
                              value={DurationState.duration}
                              placeholder="0.00"
                              onKeyPress={(e) => (e.key === "e" || e.key === "E") ? e.preventDefault() : e}
                              onChange={onChangeDuration}
                              onBlur={() =>  validationUtils.validateDurationFieldAddTime()}
                              autoComplete="off"
                            />
                          </span>

                          <div className="af-timer-btn-panel">
                            {DurationState.increments.map((d) => (
                              <button
                              data-testid = "duration-btn"
                                tabIndex={0}
                                className="p-button p-component af-btn-default"
                                onClick={() => {
                                  setChanged(true);
                                  FieldState.update(
                                    FieldState.setDurationError
                                  );
                                  DurationState.update(
                                    DurationState.onIncrement,
                                    [d]
                                  );
                                  validationUtils.validateDurationFieldAddTime();
                                }}
                                key={d}
                              >
                                <span className="p-button-label p-c">
                                  +{(d / 60).toFixed(2)}
                                </span>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}


                    </div>
                  </div>

                  {unmappedComplianceError && unmappedComplianceError.map((item) => {
                    if (item.message) {
                      return <div className="af-error-msg-display">{item.message}</div>
                    }
                  })
                  }
                </div>

                <div className="af-popup-codetypes">
                  {client.values[0].id &&
                    FieldState.payload.map((item, index) => {
                      let selected =
                        item.values && item.values.find((d) => d.isDefault);

                      return (
                        item.isDisplay && (
                          <div className="af-btn-sect" key={index}>
                            <h3>
                              {item.displayLabel}
                              {item.isMandatory && (
                                <span className="af-req-star">*</span>
                              )}
                            </h3>

                            <span className="radio-msg">
                              {selected && selected.description}
                            </span>

                            <div className="af-error-msg-display">
                              {item.errorMessage}
                            </div>

                            <RadioDropDownComponent
                              tabOrder={0}
                          
                              columns={["Code", "Description"]}
                              displayType="button"
                              textDisplayPropName="description"
                              onChange={(
                                field,
                                option,
                                ignoreIfAlreadySelected
                              ) => {
                                if (item.errorMessage)
                                  FieldState.update(FieldState.addError, [
                                    [
                                      {
                                        field: item.field
                                          .toLowerCase()
                                          .replace("_", ""),
                                        message: "",
                                      },
                                    ],
                                  ]);
                                onCodesSelected(
                                  field,
                                  option,
                                  ignoreIfAlreadySelected
                                );
                              }}
                              listSource={
                                item.field === "MAKE_NO_CHARGE"
                                  ? null
                                  : async (searchText, page) => {
                                    let list = await loadCodes(
                                      item.field,

                                      searchText,

                                      page
                                    );

                                    return list;
                                  }
                              }
                              data={item}
                            />

                            {item.field === "RATE_CODE" && showRateAmount && (
                              <div className="af-amount af-input-lookup">
                                <div className="af-amount-field">
                                  {getCode("ENTER_AMOUNT")}
                                </div>{" "}
                                <span className="af-req-star">*</span>
                                <div className="af-amount-field">
                                  <InputText
                                    id="rateInputAmount"
                                    value={rateAmountValue}
                                    onChange={(event) =>
                                      setRateAmountValue(event.target.value)
                                    }
                                    type="number"
                                    autoComplete="off"
                                    data-testid="rateInputAmount"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="p-sm-12 p-md-12 p-lg-4">
              <div className="af-popup-narrative">
                <label>{getCode("NARRATIVE")}</label>

                <div className="af-input-lookup ">
                  <p id="narrativeTextbox" className={narrativeLabelClass}>
                    {getCode("TYPE_OR_SPEAK_YOUR_NARRATIVE")} (
                    {maxChars - noOfChars}{" "}
                    {getCode("CHARS_REMAINING")})
                  </p>

                  <div className="af-nar-btnpanel">
                    <button
                      tabIndex={0}
                      className="p-button p-component btn-Icon"
                      onClick={HandleClick}
                      data-testid="micButton"
                    >
                      {toggleSpeechRecog && !transcribeReady ? <img
                        title={tooltips[TOOLTIPS_KEYS.TOOLTIP_DICTATE]}
                        alt=""
                        src={"/images/svg/icon_mic.svg"}
                      /> : transcribeReady ? <img
                        title={tooltips[TOOLTIPS_KEYS.TOOLTIP_DICTATE]}
                        alt=""
                        src={"/images/u1513.svg"}
                      /> : <Spinner />}
                    </button>
                  </div>
                </div>

                <div className="af-error-msg-display">
                  {FieldState.narrativeError}
                </div>

                <div className="af-narrative">
                  <NarrativeWrapper
                    module="AddTime"
                    onChangeNarrative={onChangeNarrative}
                    onNarrativeCorrection={onNarrativeCorrection}
                    value={narrativeValue}
                    restrictedWords={restrictedWords}
                    enteredRestrictedWords={enteredRestrictedWords}
                    isNarrativeDisabled={isNarrativeDisabled}
                    setKeyPressed={setKeyPressed}
                    rows="22"
                    onChange={onChange}
                    containerDivClass="narrative-container"
                    spellCheckToggle={spellCheckToggle}
                    resetTabToCrossButtonNarrative={(e) => resetTabToCrossButtonNarrative(e)}
                    rwActionChanges={rwActionChanges}
                  />
                </div>

                {selectedClientID ? (
                  <div className="af-btn-panel">
                    {(releaseButtonHide || isDraftTime) ?
                      <></> :
                       <button
                       
                       tabIndex={0}
                        className="p-button p-component af-btn-default btn-outline" style={{ marginRight: '22px' }}
                        disabled={saveButtonDisable}
                        onClick={() => {
                          setClickStatus("saveAndRelease");
                          if (repeatTimeEntryFlag && (!endDateValue || checkboxDays.length === 0)) {
                            setShowErrorRepeatTimeEntry(true);
                            setDisplayConfirmation(true)
                        }
                        else if(repeatTimeEntryFlag && !validateDateRangeWithWeekDays(date,endDateValue,checkboxDays)){
                          setShowErrorRepeatTimeEntry(true);
                          setDisplayConfirmation(true);
                      }
                        else if(!client.values[0].id  || !matter.values[0].id){
                          let clientErrorMsg=getCode("CLIENT_MUST_BE_ENTERED");
                        let matterErrorMsg=getCode("MATTER_MUST_BE_ENTERED");
                          if(!client.values[0].id  && !matter.values[0].id){FieldState.update(FieldState.addError, [
                            [
                              {
                                field: "client",
                                message: clientErrorMsg,
                              },
                            ],
                          ]) ; FieldState.update(FieldState.addError, [
                            [
                              {
                                field: "matter",
                                message: matterErrorMsg,
                              },
                            ],
                          ]);}
                          else if(!matter.values[0].id){FieldState.update(FieldState.addError, [
                            [
                              {
                                field: "matter",
                                message: matterErrorMsg,
                              },
                            ],
                          ])}
                          else {FieldState.update(FieldState.addError, [
                            [
                              {
                                field: "client",
                                message: clientErrorMsg,
                              },
                            ],
                          ]) ;}
                          
                        }
                        else if((maximumLimitType === "W" && (maxChars- noOfChars) < 0) && !ignoreClicked){
                          setShowMaxLimitMsg(true);
                       }
                        else{
                        !Loading && onSaveAndRelease();
                        }
                      }}
                      data-testid="saveAndRelease"
                    >
                      <span className="p-button-label p-c">{getCode("SAVE_&_RELEASE")}</span>
                    </button> 
                    }

                    <button
                    
                    tabIndex={0}
                    
                      className="p-button p-component af-btn-default"
                      disabled={saveButtonDisable}
                      onKeyDown={(e)=>{resetTabToCrossButton(e)}}
                      onClick={() => {
                        setClickStatus("save")
                        if (repeatTimeEntryFlag && ( (!endDateValue || checkboxDays.length === 0) || !validateDateRangeWithWeekDays(date, endDateValue, checkboxDays))) {
                          setShowErrorRepeatTimeEntry(true);
                          setDisplayConfirmation(true);
                        }                        
                        else if(!client.values[0].id  || !matter.values[0].id){
                        let clientErrorMsg=getCode("CLIENT_MUST_BE_ENTERED");
                        let matterErrorMsg=getCode("MATTER_MUST_BE_ENTERED");
                        if(!client.values[0].id  && !matter.values[0].id){FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "client",
                              message: clientErrorMsg,
                            },
                          ],
                        ])  ; FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "matter",
                              message: matterErrorMsg,
                            },
                          ],
                        ]);}
                        else if(!matter.values[0].id){FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "matter",
                              message: matterErrorMsg,
                            },
                          ],
                        ]);}
                        else {FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "client",
                              message: clientErrorMsg,
                            },
                          ],
                        ]) ;}
                        
                      }
                      else if((maximumLimitType === "W" && (maxChars- noOfChars) < 0) && !ignoreClicked){
                        setShowMaxLimitMsg(true);
                     }
                      else{
                        !Loading && onSave();
                      }
                      }}
                      // onKeyDown={(ev) => { 
                      //   if(ev.key === "Tab") {
                      //     //document.getElementsByClassName('p-dialog-header-icon p-dialog-header-close p-link')[0].focus();
                      //     document.querySelector('.p-dialog-header-icons > button').focus();
                      //   }
                      // }}
                      data-testid="saveButton"
                    >
                      <span className="p-button-label p-c">{getCode("SAVE")}</span>
                    </button>
                  </div>
                ) : (
                  <div className="af-btn-panel">
                    <button
                    tabIndex={0}
                      className="p-button p-component af-btn-default"
                      disabled={saveButtonDisable}
                      onKeyDown={(e)=>{resetTabToCrossButton(e)}}
                      onClick={() => {
                        setClickStatus("save")
                        if (repeatTimeEntryFlag && (!endDateValue || checkboxDays.length === 0)) {
                          setShowErrorRepeatTimeEntry(true);
                          setDisplayConfirmation(true)
                      }
                      else if(repeatTimeEntryFlag && !validateDateRangeWithWeekDays(date,endDateValue,checkboxDays)){
                        setShowErrorRepeatTimeEntry(true);
                        setDisplayConfirmation(true);
                    }
                      else if(!client.values[0].id  || !matter.values[0].id){
                        let clientErrorMsg=getCode("CLIENT_MUST_BE_ENTERED");
                        let matterErrorMsg=getCode("MATTER_MUST_BE_ENTERED");
                        if(!client.values[0].id  && !matter.values[0].id){FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "client",
                              message: clientErrorMsg,
                            },
                          ],
                        ]) ; FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "matter",
                              message: matterErrorMsg,
                            },
                          ],
                        ]);}
                        else if(!matter.values[0].id){FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "matter",
                              message: matterErrorMsg,
                            },
                          ],
                        ]);}
                        else {FieldState.update(FieldState.addError, [
                          [
                            {
                              field: "client",
                              message: clientErrorMsg,
                            },
                          ],
                        ]) ;}
                        
                      }
                      else if((maximumLimitType === "W" && (maxChars- noOfChars) < 0) && !ignoreClicked){
                        setShowMaxLimitMsg(true);
                     }
                      else{
                        !Loading && onSave();
                      }
                      }}
                      // onKeyDown={(ev) => { 
                      //   if(ev.key === "Tab") {
                      //     document. querySelector('[aria-label="Close"]').focus();
                      //   }
                      // }}
                    >
                      <span className="p-button-label p-c">{getCode("SAVE")}</span>
                    </button>
                  </div>
                )}
                
              </div>
            </div>
          </div>
        </div>
        <div className="af-confirmation">
          <div className="af-confirmation-dialog">
            <Dialog
              closable={false}
              header="Confirmation"
              visible={displayConfirmation}
              modal
              footer={renderFooter("displayConfirmation")}
              onHide={() => onHideSave("displayConfirmation")}
            >
              <div className="confirmation-content">
                <img
                  title=""
                  alt=""
                  className="af-warning"
                  src="/images/icon_warning.svg"
                />
                <span>{getCode("YOU_HAVE_ERRORS_ON_THE_SCREEN")}</span>
              </div>
            </Dialog>
          </div>
        </div>
        <div className="af-confirmation">
          <div className="af-confirmation-dialog">
            <Dialog
              closable={false}
              header="Confirmation"
              visible={closeConfirmation}
              modal
              footer={renderFooterClose("closeConfirmation")}
              onHide={() => onHideClose("closeConfirmation")}
            >
              <div className="confirmation-content">
                <img
                  title=""
                  alt=""
                  className="af-warning"
                  src="/images/icon_warning.svg"
                />
                <div>
                  <span>
                    {getCode("YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_SAVE")}
                  </span>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </Dialog>
      {showRWValidationMsg && (
        <ConfirmationBox
          message={
            getCode(
              "YOUR_NARRATIVE_INCLUDES_RESTRICTED_WORDS_YOU_SHOULD_REPLACE_ALL_RESTRICTED_WORD_ERRORS_BEFORE_SAVING"
            )
          }
          showRWValidationMsg={showRWValidationMsg}
          setPopupVisibleToggle={(flag)=>{onSetshowRWValidationMsg(flag)}}
          showOkButton = {true}

        />
      )}
       {showMaxLimitMsg && !ignoreClicked &&(
        <ConfirmationBox
          message={maximumLimitMsg}
          showRWValidationMsg={showMaxLimitMsg}
          setPopupVisibleToggle={(flag)=>{onSetShowLimitMsg(flag)}}
          setIgnoreClicked = {setIgnoreClicked}
          showIgnoreButton = {true}
          showCancelButton = {true}
        />
      )}

      {/*
       //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
        <span className="Confirmation-popup">
        {showInformationalPopup && (
          
          <ConfirmationDialogBox
            visible={showInformationalPopup}
            message={informationalMessage}
            header="Confirmation"            
            onConfirm={()=>{
              setShowInformationalPopup(false);
              onSaveHandler(afterSavingPreference);
            }}
            disableConfirmButton={false}
            disableCancelButton={true}
            confirmButtonLabel={jsUtils.getLiteralValue("OK")}
            confirmButtonClassName={"yes-button"}
            imageClassName={"af-warning"}
            imageSrc={"/images/icon_warning.svg"}
          />
        )}
        
      </span>
          */}
      <Transcribe
        toggleSpeechRecog={toggleSpeechRecog}
        setTransValue={setNarrativeValue}
        narrativeValue={narrativeValue}
        presignedURL={presignedURL} 
        maxChars={maximumLimitType==="E" ? maxChars : 5000}
        autoCorrectDictionary = {autoCorrectDictionary}
      />
    </div>
  );
}

export default AddTimePopup;