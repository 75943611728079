import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../TimeManagement/TimeManagement.scss";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../common/BreadCrumbComp/BreadCrumbComp.scss";
import {setBreadCrumb, setPageHelpLink } from "../../../actions/LandingPageAction";
import PreferencesPracticeGrid from "./PreferencesPracticeGrid";
import { setSelectedTimeKeeperFeature } from "../../../actions/TimeManagementAction";
import {
  HELP_LINKS,
  TOOLTIPS_KEYS,
  TOOLTIPS_VALUES,
} from "../../../config/vars";
import storage from "../../../utils/storage";
import { helpPageUrl } from "../../../config";
import Spinner from "../../common/Spinner/Spinner";
import jsUtils from "../../../utils/jsUtils";

function Preferences(props) {
  const dispatch = useDispatch();
  const loadingClient = useSelector(
    (state) => state.TimeManagementReducer.loading
  );
  const loadingTimeKeeper = useSelector(
    (state) => state.TimeManagementReducer.loadingTimeKeeper
  );
  const error = useSelector((state) => state.TimeManagementReducer.error);
  const [practiceObject, setPracticeObject] = useState(null);
  const [, setSystemObject] = useState(null);
  const itemdata = useSelector((state) => state.LandingPageReducer.items);
  const [signingOut] = useState(false);
  const [, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP],
  });

  /** set tooltips from storage on screen load
   *  literals -variale to store the array of literals
   */
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [retainTemporaryData, setRetainTemporaryData] = useState(null);
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };
  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP],
      });
      setCodeFromAPI(literals);
    }

    dispatch(setBreadCrumb(["MG-Account", "MG-Account-MD-Preferences"]))
    dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.PREFERENCE_PRACTICE}`))

  }, []);


  useEffect(()=>{
    document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_PREFERENCES");
  },[])

 
  useEffect(() => {
    itemdata &&
      itemdata[0] &&
      itemdata[0].landingPageData.moduleGroups.forEach((groupObj) => {
        groupObj.modules.forEach((moduleObj) => {
          if (moduleObj.moduleID === 10) {
            moduleObj.menus.forEach((menuObj) => {
              if (menuObj.menuName === "MG-Account-MD-Preferences-MN-Practice") {
                setPracticeObject(menuObj)
              }
              if (menuObj.menuName === "MG-Account-MD-Preferences-MN-System") {
                setSystemObject(menuObj)
              }
            })
            dispatch(
              setSelectedTimeKeeperFeature(
                "",
                ""
              )
            );
          }
        });
      });
  }, [itemdata]);

  const onRetainTemporaryData = (data) => {
    setRetainTemporaryData(data);
  }


  /**
   * get the codes from the literals
   * @param {String} key 
   * @returns labels 
   */

 // const [, setCloseConfirmation] = useState(false);

  if (signingOut) {
    return (
      <div className="spinner-popup">
        <Spinner />
      </div>
    );
  } else {
    return (
      <>

        {loadingClient === false &&
        loadingTimeKeeper === false &&
        error === "" ?(
            <div className="p-tabview p-component">
              <PreferencesPracticeGrid practiceObj={practiceObject} onRetainData={onRetainTemporaryData} retainTemporaryData={retainTemporaryData} />
            </div>


        ) : (
          <div>{getCode("NETWORK_ERROR")}</div>
        )}
      </>
    );
  }
}

export default Preferences;
