import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import utils from "../../../utils/jsUtils";

import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./RadioButton.scss";
import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Spinner from "../Spinner/Spinner";


/**
 *  Reusable component that is used as a search and inputbox combined .It handles data with lazy loading as well
 * @param {Array} $column - it is a table definition prop that contains column names for the list of search values
 * @param string $displayType value-(text or button) - text for input text and button for radiobutton
 * @param onChange $onChange
 * @returns html
 */
function CustomRadioButton({
  columns = [],
  displayType = "text",
  textDisplayPropName = "desc",
  onChange = () => { },
  listSource = async () => { },
  onEditableChange = () => { },
  noScroll,
  data = {},
  src,
  placeholder,
  displayDataFor = "TM",
  disabled = false,
  persistSearchLoad = false,
  characterLimit,
  tabOrder
}) {
  const containerRef = useRef(null);
  const [writableMode, setWritableMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noScrollLoad, setNoScrollLoad] = useState(false);
  const [page, setPage] = useState(1);
  const customRadioButtonScroll= React.useRef(null);

  const loadList = async function (_searchText = "", _page = 1) {
    setLoading(true);
    setNoScrollLoad(false);
    try {
      if (noScroll) {
      setNoScrollLoad(true);
    }
    setPage(_page);

    const res= await listSource(_searchText, _page)
      
        if (_page === 1) setList(res.data);
        else setList([...list, ...res.data]);

        if ((res.data && res.data.length === 0) || noScroll) {
          setNoScrollLoad(true);
        } else {
          setNoScrollLoad(false);
        }
      
      setLoading(false);
    }
    catch{
      setLoading(false);
    }
    finally{
      setLoading(false);
    }
  };

  const handleClickOutside = (e) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target)
    ) {
      setWritableMode(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("focusout", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("focusout", handleClickOutside);
    }
  }, [])

  const handleSelection = (
    option,
    skipModeChange,
    ignoreIfAlreadySelected = false
  ) => {
    !skipModeChange && setWritableMode(!writableMode);
    if (onChange) {
      setSearchText(option.client_number || option.matter_number || searchText)
      onChange(data.field, option, ignoreIfAlreadySelected);
    }
  };
  const showOptionsPopup = () => {
    if (!writableMode) {
      persistSearchLoad ? loadList(searchText) : loadList();
    }
    setWritableMode(!writableMode);
  };

  const formatAndDisplayData = () => {
    switch (displayDataFor) {
      case "TM":
         if(typeof (data) === "object"){
         return <span>
          {data && data.values && data.values[0][textDisplayPropName]}
          {data && data.values && data.values[0].code && columns[1] !== " " &&
            data.values[0].id !== "" ? " (" + data.values[0].code + ")" : " "}
        </span>;
         }
         else{
           return data;
         }
      case "CM":
        if (typeof (data) === "object") {
          return `${data.label || ''}${data?.number && data?.number !== '' ? " (" + data.number + ")" : ""}`
        } else {
          return data;
        }
    }
  }
  return (
    <div className="radio-container" ref={containerRef}>
      <div className="p-buttonset af-btn-group ">
        {!writableMode ? (
          displayType === "text" ? (
            <InputText
            data-testid = "draftDropdownid"
              placeholder={placeholder}
              onClick={() => {
                showOptionsPopup();
              }}
              onChange={() => {
                showOptionsPopup();
              }}              
              className="p-inputtext p-component p-filled radioButtonInput"
              value={formatAndDisplayData()}
              disabled={disabled}
              tabIndex={tabOrder}
              autoComplete="off"
            />
          ) : (
            <div className="radio-button-container">
              {data.values.slice(0, 4).map((btn, index) => {
                return (
                  <Button
                  data-testid = "radioButton"
                  tabIndex={tabOrder+index}
                    key={index}
                    onClick={() => {
                      handleSelection(btn, true, false);
                    }}
                    value={btn.value}
                    className={
                      btn.isDefault === true
                        ? "p-button p-component p-button-label p-c btn-selected"
                        : "p-button p-component p-button-label p-c"
                    }
                  >
                    {btn.label || btn.displayCode}
                  </Button>
                );
              })}
            </div>
          )
        ) : (
          <>
            <InputText
              autoFocus
              tabIndex={tabOrder}
              placeholder={placeholder}
              type="text"
              className="p-inputtext p-component p-filled radioButtonInput"
              value={searchText}
              onChange={(e) => {
                customRadioButtonScroll.current.scrollTop = 0;
                setSearchText(e.currentTarget.value.slice(0,characterLimit));
                setPage(1);
                (searchText!==e.currentTarget.value.slice(0,characterLimit)) && utils.debounce(loadList, 400, e.currentTarget.value.slice(0,characterLimit));
                onEditableChange && onEditableChange(e.currentTarget.value.slice(0,characterLimit));
              }}
              disabled={disabled}
              autoComplete="off"
            />
            <div
              ref={customRadioButtonScroll}
              onScroll={(e) => {
                if (!noScrollLoad) {
                  if (
                    e.currentTarget.scrollHeight -
                    (e.currentTarget.offsetHeight +
                      e.currentTarget.scrollTop) <
                    40
                  ) {
                    loadList(searchText, page + 1);
                    setNoScrollLoad(true);
                  }
                }
              }}
              className="radio-options-table"
            >
              <ul>
                 <li className="custom-dropdown-header">
                    {columns.map((d, index) => (
                      <span
                        key={index}
                        className="radio-options-table-headercode"
                      >
                        {d}
                      </span>
                    ))}
                  </li>
                  {list &&
                    list.map((option, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => {
                            handleSelection(option, false, true);
                          }}
                          onKeyPress={(e) => {
                            handleSelection(option, false, true);
                          }}
                         // className="radio-options-table-row"
                          className="custom-dropdown-row"
                          tabIndex={tabOrder}
                          role="option"
                        >
                          <span>
                            {option.label ||
                              option.subClientNumber || option.client_number || option.matter_number ||
                              option.displayCode ||
                              option.code || option.featureKey}
                          </span>
                          <span>
                            {option.desc ||
                              option.description ||
                              ( option.featureValue)}
                          </span>
                        </li>
                      );
                    })}
                  {loading && (
                    <li>
                      {" "}
                      <span colSpan={2}>
                        <div className="af-spinner">
                          {" "}
                          <Spinner />
                        </div>
                      </span>
                    </li>
                  )}
                
              </ul>
                {/* <DataTable tabIndex="1" onRowClick={(row) => {
                  handleSelection(row.data, false, true);
                }} className="" value={list} className='radio-options-table-row' scrollHeight="10rem">
                  {columns.map((d, index) => (
                    <Column field={index === 0 ? "featureKey" : 'featureValue'} header={d}></Column>
                  ))}
                </DataTable> */}
            </div>
          </>
        )}
        {listSource && (
          <img
            className={`af-icon-search ${disabled ? 'img-disabled' : ''}`}
            alt=""
            src={src}
            onClick={() => {
              showOptionsPopup();
            }}
            onKeyPress={() => {
              setSearchText("");
              showOptionsPopup();
            }}
            tabIndex={tabOrder}
            data-testid="searchIcon"
          />
        )}
      </div>
    </div>
  );
}

export default CustomRadioButton;
