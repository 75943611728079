import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { SET_LOOKUP_LIST, RESET_MANAGETIMESTORE, SET_DATA_PAGE_UNMOUNT, SET_LOADING_FLAG } from "../actions/ActionType";
import initialState from "../store/InitialState";
var ManageTimeReducer = function ManageTimeReducer() {
  var _objectSpread2;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.ManageTimeReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case SET_LOADING_FLAG:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: action.loading
      });
    case SET_LOOKUP_LIST:
      return _objectSpread(_objectSpread({}, state), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, action.listKey, getFinalListWithBlankValue(state, action.listKey, action.data[action.listKey])), _defineProperty(_objectSpread2, action.pageOffsetKey, action.pageOffset), _defineProperty(_objectSpread2, "loading", false), _objectSpread2));
    case RESET_MANAGETIMESTORE:
      return _objectSpread({}, initialState.ManageTimeReducer);
    case SET_DATA_PAGE_UNMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        manegeTimePageUnmount: action.data
      });
    default:
      return state;
  }
};
var getFinalListWithBlankValue = function getFinalListWithBlankValue(state, key, data) {
  if (key === "subClients") {
    data = data.filter(function (obj) {
      return obj.subClientNumber !== "" && obj.subClientNumber !== null;
    });
    return [].concat(_toConsumableArray(state[key]), _toConsumableArray(data));
  } else if (key === "clients" || key === 'matters') {
    data = data.filter(function (obj) {
      return obj.code !== "" && obj.code !== null;
    });
    return [].concat(_toConsumableArray(state[key]), _toConsumableArray(data));
  } // else if (key === "matters") {
  //   data = data.filter((obj) => obj.code !== "" && obj.code !== null);
  //   return [...state[key],...data];
  // }  
  else {
    data = data.filter(function (obj) {
      return obj.display_value !== "" && obj.display_value !== null;
    });
    return [state[key][0]].concat(_toConsumableArray(data));
  }
};
export default ManageTimeReducer;