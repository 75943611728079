import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./MonthView.scss";
import "./fullCalendar.scss";

import { useHistory } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import jsUtils from "../../utils/jsUtils";
import { useDispatch } from "react-redux";
import { setCheckedStateDayView } from "./../../actions/DayViewAction";

const MonthViewCalendar = (props) => {
  let history = useHistory();
  const calendarRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.calendarDate !== null) {
      let calendarApi = calendarRef.current.getApi();
      let changeGridCalendarDate = moment(new Date(props.calendarDate)).format(
        "YYYY-MM-DD"
      );
      calendarApi.gotoDate(changeGridCalendarDate);
    }
  }, [props.calendarDate]);

  const handleDatesSet = (args) => {
    // if (calendarRef.current) {
    //   let calendarApi = calendarRef.current.getApi();
    //   props.onDateChange(new Date(moment(calendarApi.currentDataManager.data.currentDate).add(7, 'days')), args);
    // }
    props.onDateChange(new Date(moment(args.start).add(7, 'days')),args);
  };

  const onEventClick = (value, args) => {
    const UTCOffset = moment(args.event._instance.range.start).utcOffset();

    let date = moment(
      new Date(UTCOffset >= 0 || UTCOffset === -0 ? args.event._instance.range.start : args.event._instance.range.end).toISOString()
    ).format("YYYY-MM-DD");
    switch (value) {
      case "unreleased":
        dispatch(
          setCheckedStateDayView(true, true, true, new Date(date + " 00:00:00"))
        );
        break;

      case "billable":
        dispatch(
          setCheckedStateDayView(
            true,
            false,
            false,
            new Date(date + " 00:00:00")
          )
        );
        break;

      default:
        dispatch(
          setCheckedStateDayView(
            false,
            true,
            false,
            new Date(date + " 00:00:00")
          )
        );
    }

    history.push("DayView");
  };

  /*
   *  Click on any date to route to dayview
   */
  const handleDateClick = (args) => {
    dispatch(
      setCheckedStateDayView(true, true, false, new Date(args.dateStr + " 00:00:00"))
    );
    history.push("DayView");
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        {eventInfo && eventInfo.event.extendedProps !== undefined}
        <div className="divTableBody">
        
            <div
            className={`divTableRow ${props.isSmallGrid > 5 ? "" : "extraPaddingTop"}`}
              title={jsUtils.getLiteralValue("UNRELEASED_TIME")}
              onClick={() => {
                onEventClick("unreleased", eventInfo);
              }}
            >
              <div className="divTableLabel af-mv-unreleased">
                {jsUtils.getLiteralValue("U_LABEL")}
              </div>
              <div className="divTableCell af-mv-unreleased-time">
                {" "}
                {(eventInfo.event.extendedProps.unreleasedTime / 60).toFixed(2)}
              </div>
            </div>
            <div
              className="divTableRow"
              title={jsUtils.getLiteralValue("MISSING_TIME")}
            >
              <div className="divTableLabel af-mv-missing">?</div>
              <div className="divTableCell af-mv-missingAmount">
                {" "}
                {eventInfo.event.extendedProps.missedTime ? (eventInfo.event.extendedProps.missedTime / 60).toFixed(2):jsUtils.getLiteralValue("NWD_LABEL")}
              </div>
            </div>

          <div className={`af-bill-nonbill-row  ${props.isSmallGrid > 5 ? "" : "extraPadding"}`}>
              <div
                className="divTableRow"
                title={jsUtils.getLiteralValue("BILLABLE_HOURS_MONTH")}
                onClick={() => {
                  onEventClick("billable", eventInfo);
                }}
              >
                <div className="divTableLabel af-mv-billable">
                  {jsUtils.getLiteralValue("B_LABEL")}
                </div>
                <div className="divTableCell">
                  {(eventInfo.event.extendedProps.billableTime / 60).toFixed(2)}
                </div>
              </div>
              <div
                className="divTableRow"
                title={jsUtils.getLiteralValue("NON_BILLABLE_HOURS_MONTH ")}
                onClick={() => {
                  onEventClick("nonBillable", eventInfo);
                }}
              >
              <div className="divTableLabel af-mv-nonbillable">
                  {jsUtils.getLiteralValue("N_LABEL")}
                </div>
                <div className="divTableCell af-mv-nonbillableAmout">
                  {" "}
                  {(eventInfo.event.extendedProps.nonBillableTime / 60).toFixed(
                    2
                  )}
                </div>
              </div>
            </div>
          </div>
        
      </>
    );
  };

  return (
    <div className="card">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        timeZone="local"
        headerToolbar={{
          left: "",
          center: "prev title next",
          right: "",
        }}
        initialDate={props.date}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        height={580}
        fixedWeekCount={false}
        dateClick={handleDateClick}
        ref={calendarRef}
        eventContent={renderEventContent}
        displayEventTime={false}
        //  eventClick = {onCalendarEventClick}
        events={!props.isLoading && props.monthlyTime}
        datesSet={handleDatesSet}
      />
    </div>
  );
};

export default MonthViewCalendar;
