import React, { useState, useEffect, useRef, useCallback } from "react";
import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";

import "./AddTimePopup.scss";

import { Dialog } from "primereact/dialog";

import { useSelector } from "react-redux";

import { addTime } from "../../../services/AddTimeApi";
import { draftTimeApi } from "../../../services/DraftTimeApi";
import { shortcut } from "../../../services/AutoTextApi";

import { AuthService } from "../../../services/authService";

import moment from "moment";
import * as NarrativeHelper from "./NarrativeHelper";

//import RadioButton from "../RadioButton/RadioButton";

import { Toast } from "primereact/toast";
import FieldState from "./AddTimePopup.State.ts";
import DurationState from "../../../store/DurationState.ts";
import { helpPageUrl } from '../../../config';
import { useSubscribe } from "../../../store/ActionStore";
import { setErrorMessage } from "./../../../actions/AppAction";
import Spinner from "../Spinner/Spinner";
import { HELP_LINKS, TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../config/vars";
import storage from "../../../utils/storage";
import Transcribe from "../AWSTranscribe/Transcribe";
import { useDispatch } from "react-redux";
import ConfirmationBox from "./../ConfirmationBox/ConfirmationBox";
import { fetchAllRestrictedWords } from "../../../services/RestrictedWordsApi";
import { refreshStoreOnError } from "../../../actions/AppAction";
import { setDurationToggle } from "../../../actions/TimeManagementAction";
import jsUtils from "../../../utils/jsUtils";
import { Preferences } from "../../../services/PreferencesApi";
import RepeatTimeEntry from "./RepeatTimeEntry/RepeatTimeEntry";
import { ToolTipWrapper } from "./ToolTipWrapper";
import { setHighlightRestrictedWords } from "../../../actions/AddTimeActions";
import CustomRadioButton from "../RadioButton/CustomRadioButton";
import { manageTime } from "../../../services/ManageTimeApi";
import validationUtils from "../../../utils/validationUtils";
import NarrativeWrapper from "./Narrative/NarrativeWrapper";
import ConfirmationDialogBox from "../../common/ConfirmationBox/ConfirmationDialogBox";
import {CheckBox} from "@attorneyflow-mf/styleguide";
import RadioDropDownComponent from "../RadioButton/RadioDropDownComponent";
import { Calendar } from "primereact/calendar";
import ToggleButtonComponent from "../ToggleButtonComponent";

function DraftTimePopup({
  closed,
  onSaveHandler,
  show,

  data,
  pageTitle,
}) {

  const [client, setClient] = useState("");
  const [matter, setMatter] = useState("");
  const [disableSaveButton, setDisableSaveButton] =  useState(true);
  const [subClient, setSubClient] = useState("");
  const {
    userDelegateRoundingMethod,
    delegateId,
    dateFormat,
    attorneyCode,
    allRestrictedWords,
    afterSavingPreference
  } = useSelector(({ TimeManagementReducer }) => ({
    userDelegateRoundingMethod:
      TimeManagementReducer.userDelegateRoundingMethod,
    delegateId: TimeManagementReducer.delegateId,
    dateFormat: TimeManagementReducer.dateFormat,
    attorneyCode: TimeManagementReducer.attorneyCode,
    allRestrictedWords: TimeManagementReducer.allRestrictedWords,
    afterSavingPreference: TimeManagementReducer.afterSavingPreference
  }));

  useSubscribe([FieldState, DurationState]);
  DurationState.setRounding(userDelegateRoundingMethod);

  const [TimeDialog, setTimeDialog] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const dialogFuncMap = {
    displayConfirmation: setDisplayConfirmation,
  };

  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const dialogFuncMapClose = {
    closeConfirmation: setCloseConfirmation,
  };

  const [date, setDate] = useState("");

  const [narrativeValue, setNarrativeValue] = useState("");

  const [autoCorrectDictionary, setAutoCorrectDictionary] = useState(null);

  const [narrativeLabelClass, setNarrativeLabelClass] = useState("af-msg");

  const [noOfChars, setNoOfChars] = useState(0);

  const [toggleSpeechRecog, setToggleSpeechRecog] = useState(true);

  const [changed, setChanged] = useState(false);

  const [presignedURL, setpresignedURL] = useState("");

  const[showClientError , setClientShowError] = useState(false);
  const[showMatterError , setMatterShowError] = useState(false);
  const [durationToggleClick, setDurationToggleClick] = useState(true);
  const [rateCode, setRateCode] = useState(null);
  const [showRateAmount, setShowRateAmount] = useState(false);
  const [rateAmountValue, setRateAmountValue] = useState(null);
  const [transcribeReady, settranscribeReady] = useState(false);
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: [TOOLTIPS_VALUES.SELECT_DATE],
    [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: [TOOLTIPS_VALUES.SEARCH],
    [TOOLTIPS_KEYS.TOOLTIP_CLEAR]: [TOOLTIPS_VALUES.CLEAR],
    [TOOLTIPS_KEYS.TOOLTIP_DICTATE]: [TOOLTIPS_VALUES.DICTATE],
    [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: [TOOLTIPS_VALUES.CLOSE],
    [TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]: [TOOLTIPS_VALUES.SWITCH_DURATION_FORMAT],
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP]

  });
  const [isNarrativeDisabled, setIsNarrativeDisabled] = useState(false);
  const [showRWValidationMsg, setShowRWValidationMsg] = useState(false);
  const [enteredRestrictedWords, setEnteredRestrictedWords] = useState([]);
  //const highlightDefault = [{ highlight: "" }];
  //const [ setRestrictedWords] = useState(highlightDefault);
  const [buttonClicked, setButtonClicked] = useState(() => { });
  const [maxChars, setMaxChars] = useState(5000);
  const [maximumLimitType ,setMaximumLimitType] = useState("E");
  const [maximumLimitMsg , setMaximumLimitMsg ] = useState("");
  const [showMaxLimitMsg , setShowMaxLimitMsg] = useState(false);
  const [keyPressed, setKeyPressed] = useState(null);
  const recordDuration = useSelector((state) => state.TimeManagementReducer.loggedInUserToggle)
  const [autoCapStatus, setAutoCapStatus] = useState("true");
  const [repeatTimeEntryFlag,setRepeatTimeEntryFlag] = useState(false);
  const [checkboxDays,setCheckboxDays] = useState([]);
  const [endDateValue,setEndDateValue] = useState(null);

  const [spellCheckToggle, setSpellCheckToggle] = useState(false);
  const [forceInvalidStatusToggle, setForceInvalidStatusToggle] = useState(false);
  const [searchClientsList,setSearchClientsList] = useState([]);
  const [showErrorRepeatTimeEntry,setShowErrorRepeatTimeEntry]=useState(false);
  const [ignoreClicked , setIgnoreClicked ] = useState(false);
  const [DBCurrentDate, setDBCurrentDate] = useState("");

  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);
  /*
  //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
  const [showInformationalPopup,setShowInformationalPopup] = useState(false);
  //const [onClickCross, setOnClickCross] = useState(false);
  const [informationalMessage,setInformationalMessage] = useState("");
*/
  useEffect(() => {
    
    return () => {
      FieldState.update(FieldState.resetPayLoad);
      DurationState.update(DurationState.onDurationChange, [null, "", ""]);
      FieldState.update(FieldState.removeError);
      DurationState.update(DurationState.setDurationValidation, [null]);
    };
  }, []);

   /** Autocapitalize */
   
  const { isAuth, preferenceApiData, hasMultipleSubClients } = useSelector(
    ({ AuthReducer, PreferenceReducer }) => ({
      isAuth: AuthReducer.isAuth,
      preferenceApiData: PreferenceReducer.preferenceApiData,
      hasMultipleSubClients: PreferenceReducer.hasMultipleSubClients
    })
  );

  const permissionsOfUser = useSelector((state) => state.AppReducer && state.AppReducer.fieldnames);
  useEffect(() => {
    let permissions = permissionsOfUser.find( (item) => item.id==="practice");
    if(isAuth && permissions){
      const action = Preferences.getPreferenceSettings();
      dispatch(action);
    }  
  },[isAuth]);

  useEffect(() => {
    //let permissions = permissionsOfUser.find( (item) => item.id==="practice");
    if(preferenceApiData) {
      let setting = preferenceApiData.find(
        (preference) => preference.param_key === "AUTO_CAPITALIZE"
      ); 
      setAutoCapStatus(setting && setting.param_value);

      let spellCheck_setting = preferenceApiData.find(
        (preference) => preference.param_key === "SPELL_CHECK"
      ); 
      if(spellCheck_setting){
        let spell_values = spellCheck_setting.param_value;
        let spell_obj = JSON.parse(spell_values);
        setSpellCheckToggle(Object.keys(spell_obj)?.find((item)=> item === "spell_check_toggle") ? spell_obj.spell_check_toggle : true);
        setForceInvalidStatusToggle(spell_obj.force_invalid_status_toggle);
      }
    }
  },[preferenceApiData]); 

  const autoCapitalize = (value) => {
    if (keyPressed === "question_mark" || keyPressed === "exclamation" || keyPressed === "dot"|| keyPressed === "enter") {
      const { formattedStr } = NarrativeHelper.sentenceCase(value);
      setNarrativeValue(formattedStr);
      setKeyPressed(null);
    }
  }

  /*
    Save Button Validation
  */
   const saveButtonValidation = (_client="", _matter="", _date="") => {
    if(_client === "" || _matter === "" || _date === ""){
      setDisableSaveButton(true);
    }
    else{
      setDisableSaveButton(false);
    }
   }


  /** set tooltips from storage on screen load
  * literals -variale to store the array of literals
  */
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const dispatch = useDispatch();
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };
  useEffect(() => {
    const currentDate = meUserData && meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setDBCurrentDate(currentDate);
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: literals[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE],
        [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: literals[TOOLTIPS_KEYS.TOOLTIP_SEARCH],
        [TOOLTIPS_KEYS.TOOLTIP_CLEAR]: literals[TOOLTIPS_KEYS.TOOLTIP_CLEAR],
        [TOOLTIPS_KEYS.TOOLTIP_DICTATE]: literals[TOOLTIPS_KEYS.TOOLTIP_DICTATE],
        [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: literals[TOOLTIPS_KEYS.TOOLTIP_CLOSE],
        [TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]: literals[TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT],
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP]
      })
      setCodeFromAPI(literals);
    }
    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_DRAFT_TIME_ENTRY");
    //fetches firm level restricted words
    dispatch(
      fetchAllRestrictedWords("clientId=null&subClientId=null&matterId=null")
    );
  }, []);

  useEffect(() => {
    if(maximumLimitType === "E"){
    if (maxChars - noOfChars === 0) {
      setNarrativeLabelClass("af-msg1");
      setToggleSpeechRecog(false);
      setNarrativeValue((prev) => {
        return prev;
      });
      //resetTranscript();
    } else if (maxChars - noOfChars < 0) {
      //resetTranscript();
    }}
  }, []);
  useEffect(()=>{
    if (ignoreClicked){
      !Loading && onSave();
    }
  },[ignoreClicked])

  useEffect(() => {
    show &&
      shortcut.get("page=1&firmlevel=all").then((res) => {
        let autoDictonary = {};
        res && res.forEach((element) => {
          if (autoDictonary[element.shortcut]) {
            if (element.firmlevel !== 1) {
              let arr;
              if (typeof autoDictonary[element.shortcut] === "string") {
                arr = [autoDictonary[element.shortcut]];
              } else {
                arr = [...autoDictonary[element.shortcut]];
              }
              arr.push(element.fulltext);
              autoDictonary[element.shortcut] = arr;
            }
          } else {
            autoDictonary[element.shortcut] = element.fulltext;
          }
        });

        setAutoCorrectDictionary(autoDictonary);
      });

    setTimeDialog(show);
  }, [show]);

  const HandleClick = async (e) => {
    setChanged(true);
    setToggleSpeechRecog(!toggleSpeechRecog);
    if (toggleSpeechRecog) {
      //SpeechRecognition.startListening({ continuous: true });
      await AuthService.getPresinedUrlForAWSTranscribe().then((res) => {
        if (res.responseCode === 200) {
          setpresignedURL(res.presignedURL);
          settranscribeReady(true);
        }
      })
      setIsNarrativeDisabled(true);
    } else {
      setIsNarrativeDisabled(false);
      settranscribeReady(false);
      setpresignedURL("");

      //SpeechRecognition.abortListening();
      // setNarrativeValue((prev) => {
      //   return prev + transcript;
      // });
    }

    //resetTranscript();
  };

  const toggleClick = () => {
    setDurationToggleClick(!durationToggleClick);
  };

  const hOnChange = (value, rwAction, lastWord, lastWordStartIndex) => {
    let isAutoTextModified;
    if (keyPressed === "space") {
      const { isModified, sentence } = NarrativeHelper.getAutoCorrectedValueExtWithWord(
        value,
        autoCorrectDictionary,
        lastWord,
        lastWordStartIndex
      );
      isAutoTextModified = isModified;
      if (isModified) { // && maxChars - noOfChars - (newLength - prevLength) > 1) {
        value = sentence;
        setNoOfChars(value ? Number(value.length) : 0);
      }
    }
    if(maximumLimitType === "E"){
    if (Number(value.length) > maxChars) {
      setNarrativeLabelClass("af-msg1");
      if (
        keyPressed === "Ctrl+V" ||
        keyPressed === null ||
        (rwAction && rwAction === "Replace All")
      ) {
        setNoOfChars(value.length);
        //when user copy pastes text or replace all rw
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
        return;
      } else if (keyPressed === "space" && isAutoTextModified) {
        //when user types space for autotext
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
        return;
      } else if (keyPressed !== "Ctrl+V" && keyPressed !== "back_space") {
        return;
      }
    }
     else {
      setNoOfChars(value.length);
      setNarrativeLabelClass("af-msg");
      FieldState.update(FieldState.setNarrativeError);
    }}

    else if (maximumLimitType === "W"){
      setNoOfChars(value.length);
      setNarrativeLabelClass("af-msg");
    }
    setNarrativeValue(value);
   // autoCapStatus !== "false" ? autoCapitalize(value): null;
   if( autoCapStatus !== "false") {
    autoCapitalize(value);
   }
    setKeyPressed(null);
  };

  const onChange = (event) => {
    let text = event.target.value;
    setChanged(true);
    const { lastWord, lastWordStartIndex } = NarrativeHelper.getLastWordAndIndex(event);
    onChangeNarrative(text, [], null, lastWord, lastWordStartIndex);
  };

  useEffect(() => {
    let newdate =  new Date(DBCurrentDate);
    setDate(newdate);

    //setTime(data.time);

    DurationState.update(DurationState.onDurationChange, [null, 0, 0]);

    //setBillableDuration(data.billableDuration);
    //setTimeentry
    //setSubClientId(data.selectedSubClientId);
  }, [data ,  DBCurrentDate]);

  useEffect(() => {

    setDurationToggleClick(recordDuration)

  }, [])

  useEffect(() => {
    let togglePayload = { isRecordedDurationSelected: durationToggleClick }
    addTime.durationToogleSet(togglePayload).then((res) => {
      dispatch(setDurationToggle(durationToggleClick))

    }).catch((err) => {
      dispatch(refreshStoreOnError({ refresh: new Date() }));
    })
  }, [durationToggleClick])

  /**
 *  makes an api call to get all the individual field values on basis of the search text and the page   
 * @param {String} searchText - contains the user typed searchtext
 * @param {integer} page - contains the page number 
 * @return It returns a promise that contains the success or failure object
*/
  const loadCodes = (fieldName, searchText, page, delegateID) => {
    return draftTimeApi.draftConfigSearch(fieldName, page, searchText, delegateID);
  };

  /**
   *  - update the field with the selected value
   * @param {String} field - contains the field name
   * @param {Object} value - contains the selected code
   * @param {Boolean} ignoreIfAlreadySelected - true or false
   */
  const onCodesSelected = (field, value, ignoreIfAlreadySelected) => {
    //console.log(field.split("_")[0], value.displayCode, value);
    FieldState.update(FieldState.updatePayload, [
      field,
      value,
      ignoreIfAlreadySelected,
    ]);
    let name = field.split("_")[0];
    if (name === "RATE") {
      setRateAmountValue("");
      setRateCode(value.id); //   // console.log(d);
      invokeRateCode(value.id, date, delegateId);
    }

    setChanged(true);
  };

  //api call

  // const onNarrativeChange = () => {};
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onHideClose = (name) => {
    dialogFuncMapClose[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label={getCode("STAY")}
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
          data-testid="stay"
        />
        {client && matter && (!repeatTimeEntryFlag || (repeatTimeEntryFlag && !(!endDateValue || checkboxDays.length===0)))  && <Button
          label={getCode("SAVE_WITH_ERRORS")}
          icon="pi pi-check"
          onClick={() => {
            onSave();
            onHide(name);
          }}
          autoFocus
          data-testid = "saveWithError"
        />}
      </div>
    );
  };

  const renderFooterClose = (name) => {
    return (
      <div>
        <Button
          label={getCode("LEAVE")}
          onClick={() => {
            hideDialog();
            onHideClose(name);
          }}
          className="p-button-text"
          data-testId="leave"
        />
        <Button
          label={getCode("SAVE")}
          onClick={() => {
            onSave();
            onHideClose(name);
          }}
          autoFocus
          data-testid="save-render"
        />
      </div>
    );
  };

  /**
   *  creates a payload object from the all the selected fields in the popup
   * @returns a payload object that contains all the selected fields data
   */
  const createPayload = () => {
    let savePayload = {
      client: typeof (client)==="object" ? client.client_number:client,

      subClient: subClient,

      matter: typeof (matter)==="object" ? matter.matter_number:matter,

      dateTime: moment(new Date(date).toISOString()).format("YYYY-MM-DD"),

      recorded_duration_hrs: DurationState.hh, //parseInt(time.split(":")[0]) ||

      recorded_duration_mins: DurationState.mm, //parseInt(time.split(":")[1]) ||

      billing_duration: DurationState.duration,

      narrative: narrativeValue,

      attorney: attorneyCode,

      delegateUserId: delegateId,

      mode: "",

      saveWithErrors: true,
      spellCheck: forceInvalidStatusToggle ? spellCheckToggle : false,
      forceInvalid:forceInvalidStatusToggle
    };
    if(endDateValue)
    {
      savePayload["endDateTime"] = moment(new Date(endDateValue).toISOString()).format("YYYY-MM-DD");

      savePayload["is_recurring"] = true ;

      savePayload["recurring_days"] =checkboxDays;
    }
    FieldState.payload.forEach((code) => {
      let selected =
        code.isDisplay === true &&
        code.values.find((d) => d.isDefault === true);
      if (selected) {
        if (code.field === "MAKE_NO_CHARGE") {
          
          savePayload["makeNoChargeId"] = code.fieldId;

          savePayload["makeNoChargeValueId"] = selected.id;

          savePayload["makeNoCharge"] = selected.displayCode.toString().slice(0, 1);
        } 
        else 
        {
        let label= code.field.toLowerCase().split("_");

        let valueId = label[0] + "ValueId";

        let id = label[0] + "Id";

        let displayCode = label[0] + "Code";

        savePayload[valueId] = selected.id;

        savePayload[id] = code.fieldId;

        savePayload[displayCode] = selected.displayCode;
      }
      }
    });
    if (showRateAmount) {
      savePayload["amount"] = parseInt(rateAmountValue);
    }
    else {
      savePayload["amount"] = null;
    }
    return savePayload;
  };
/*
//SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
  const onSetInformationalMessage=(res)=>{
    let amountInformation=res.informationalMessage.amount_information || "";
          let hoursInformation=res.informationalMessage.hours_information || "";
          let disclaimerInformation=res.informationalMessage.disclaimer || "";
          let message=amountInformation+"\n"+hoursInformation;
          if( disclaimerInformation && (disclaimerInformation !=="")){
            message=message+"\n"+"Disclaimer : "+disclaimerInformation;
          }
          setInformationalMessage(message);
          setShowInformationalPopup(true);
  }
  */
  /** 
  * To Save the time entry on save button clicked.
  * 
  * @param {Boolean} toggleSpeechRecog
  * 
  */

  const onSave = () => {
    if (!toggleSpeechRecog) {
      setToggleSpeechRecog(!toggleSpeechRecog);
      setIsNarrativeDisabled(false);
      settranscribeReady(false);
      setpresignedURL("");
    }
    setButtonClicked(() => saveApiCall);
    const { isNarrativeWordsValid, isNarrativeCharLimitValid } = validateNarrative();
    if (!isNarrativeWordsValid) {
      setShowRWValidationMsg(true);
    } else if (!isNarrativeCharLimitValid) {
      return;
    } else {
      if(client === "" || matter === "" ){
        if(client === "" && matter === ""){setClientShowError(true) ; setMatterShowError(true);}
        else if(matter === ""){setMatterShowError(true)}
        else {setClientShowError(true)}
        
      }
      else{
      saveApiCall();
      }
    }
  }

  const saveApiCall = () => {
    let savePayload = createPayload();
    console.log(savePayload);
    setLoading(true);
    savePayload.mode = "draft";
    addTime

      .post(savePayload)

      .then((res) => {
        setLoading(false);
        setChanged(false);
        let showInformationDialog=false;
        /*
        //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
        if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
          onSetInformationalMessage(res);
          showInformationDialog=true
          }
          */
        if(!showInformationDialog){ 
          onSaveHandler(afterSavingPreference);
        }

        dispatch(setErrorMessage({ severity: 'success', message: getCode("TIME_ENTRY_ADDED_SUCCESSFULLY"), errorCode: 200, life: 5000 }));
        resetNarrativeAndDuration();
      })
      .catch((err) => {
        setLoading(false);
        // toast.current.show({
        //   severity: "error",

        //   summary: "Error in saving the fields",

        //   detail: "",

        //   life: 5000,
        // });
        dispatch(setErrorMessage({ severity: 'error', message: getCode(( err && err.message && err.message[0].message) || (err &&err.message) ||"UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));
      });
  };

  const toast = useRef(null);



  const hideDialog = () => {
    if (!Loading) {
      closed();

      setTimeDialog(false);

      document.title = jsUtils.getLiteralValue(pageTitle);
    }
  };

  /**
   *  on change of selected rate value or date we make an api call to get the keyextendedflag
   * @param {String} newDate 
   */
  const checkRateAmount = async (newDate) => {
    invokeRateCode(rateCode, newDate, delegateId);
  };

  /**
  *  get keyextended rate value on selecting a rate value or date and set it to the state to display the amount field or not
  * @param {} idvalue 
  * @param {String} date 
  * @param {Number} delegateId 
  */
  const invokeRateCode = async (idvalue, _date, _delegateId) => {
    setLoading(true);
    await addTime
      .rateCode(
        idvalue,
        moment(new Date(_date).toISOString()).format("YYYY-MM-DD"),
        _delegateId
      )
      .then((res) => {

        if (res.keyExtendedAmountFlag) {
          setShowRateAmount(true);
        } else {
          setShowRateAmount(false);
        }
        setLoading(false);
      })
      .catch(() => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTimeEntriesPayload();
  }, []);

  /**
  *  api call to get all the fields and their corresponding values and the fieldstate and duration states 
*/
  const loadTimeEntriesPayload = async () => {
    await draftTimeApi.getDraftConfig(delegateId).then((res) => {

      if (res && res.fields) {
        let rate = res.fields.find((item) => item.field === "RATE_CODE");
        if (rate) {
          let val = rate.values.find((item) => item.isDefault === true);
          
          if (val) {
            setRateCode(val.id);
            invokeRateCode(val.id, new Date(), delegateId);
          }
        }
      }

      FieldState.update(FieldState.setPayload, [res.fields]);
      DurationState.update(DurationState.setIncrements, [[3, 6, 15, 30, 60]]);
      DurationState.update(DurationState.setMaxBillableDuration, [
        res.maxBillableDuration,
      ]);
      DurationState.update(DurationState.setMaxHoursPerDay, [
        res.maxHoursPerDay,
      ]);
    });
    // });
  };

  //resets values of Narrative and Duration fileds to default values
  const resetNarrativeAndDuration = useCallback(() => {
    setNarrativeValue("");
    DurationState.update(DurationState.setDuration, ["0.00"]);
    DurationState.update(DurationState.setHH, [0]);
    DurationState.update(DurationState.setMM, [0]);
  });

  useEffect(() => {
    setTimeDialog(show);
  }, [show]);

  const onChangeNarrative = (text, _enteredRestrictedWords, action, lastWord, lastWordStartIndex) => {

    hOnChange(text, action, lastWord, lastWordStartIndex);
    setEnteredRestrictedWords(_enteredRestrictedWords);
  };

  const onSetshowRWValidationMsg = (isVisible) => {
    setShowRWValidationMsg(isVisible);
    const { isNarrativeWordsValid } = validateNarrative();

    if (isNarrativeWordsValid) {
      buttonClicked();
    }
  };

  const onSetShowLimitMsg = (isVisible) =>{
    setShowMaxLimitMsg(isVisible);
  }

  useEffect(() => {
    const { highlightJson, maxLimit , maxLimitType , maxLimitMsg } = NarrativeHelper.onLoadRestrictedWords(allRestrictedWords);
     //highlightJson.map(rWord => (rWord.component = ToolTipWrapper)); //2.1.1
     highlightJson.map(rWord => (rWord.enhancement = ToolTipWrapper));
    dispatch(setHighlightRestrictedWords(highlightJson));
    setMaxChars(maxLimit);
    setMaximumLimitType(maxLimitType);
    setMaximumLimitMsg(maxLimitMsg)
  }, [allRestrictedWords]);

  const onNarrativeCorrection = (action, restrictedWordsNew, enteredRestrictedWordsNew) => {
    if (action === "Ignore All") {
      if(restrictedWordsNew) {
        //setRestrictedWords(restrictedWordsNew);
        dispatch(setHighlightRestrictedWords(restrictedWordsNew));
      }
     
      if(restrictedWordsNew) {
        setEnteredRestrictedWords(enteredRestrictedWordsNew);
      }     
    }
  }

  /* checks if narrative as restricted words */
  const validateNarrative = () => {

    let isNarrativeWordsValid = true, isNarrativeCharLimitValid = true;
    if (
      narrativeValue !== "" &&
      enteredRestrictedWords &&
      enteredRestrictedWords.length > 0
    ) {
      isNarrativeWordsValid = false;
    }
    if(maximumLimitType === "E"){
    if ((maxChars - noOfChars) < 0) {
      isNarrativeCharLimitValid = false;
      FieldState.update(FieldState.setNarrativeError, [
        getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
      ]);
    }}
    else if(maximumLimitType === "W"){
      if ((maxChars- (maxChars- noOfChars)) > 5000) {
        isNarrativeCharLimitValid = false;
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
      } 
    }
    return { isNarrativeWordsValid, isNarrativeCharLimitValid };
  }

  const onChangeMM = (e) => {
    let minutes = e.currentTarget.value.toString();
    if (minutes.length > 2 || minutes > 59 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    FieldState.update(FieldState.setDurationError);
    DurationState.update(DurationState.onMinutesOrHoursChange, [
      DurationState.hh,
      minutes,
    ]);
  };

  const onChangeHH = (e) => {
    let hours = e.currentTarget.value.toString();
    if (hours.length > 2 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    FieldState.update(FieldState.setDurationError);
    DurationState.update(DurationState.onMinutesOrHoursChange, [
      hours,
      DurationState.mm,
    ]);
  };

  const onChangeDuration = (e) => {
    let duration = e.currentTarget.value.toString();
    if (duration.length > 7 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    DurationState.update(DurationState.onDurationFieldChange, [duration]);
  };
  const onChangeCheckboxDays=(days)=>{
    setShowErrorRepeatTimeEntry(false);
    setCheckboxDays(days)
  }

  const isDraftExactMatchFound=(newVal, prospectiveClients)=>{
    if(newVal!=="")
    {
    const clientFound= prospectiveClients.find((item)=>item.client_number.toLowerCase().trim() === newVal.toLowerCase().trim());
    return clientFound ;
    }
    else
    {
      return "";
    }
  }


 

  const validateDateRangeWithWeekDays =(startDateString,endDateString,weekDays) =>{
    let startDate = moment(new Date(startDateString).toISOString()).format("YYYY-MM-DD")
    
      let endDate = moment(new Date(endDateString).toISOString()).format("YYYY-MM-DD");
  
      const oneDayTotalMilliseconds = 1000 * 60 * 60 * 24;
  
      const diffInTimeInMilliseconds = (new Date(endDate)).getTime() - (new Date(startDate)).getTime() ;
  
      const totalDaysInBetween = Math.round(diffInTimeInMilliseconds / oneDayTotalMilliseconds);
  
      if(totalDaysInBetween>6){
        return true;
      }
      else{
          let weekDaysArrayInNumber=jsUtils.getWeekDayArrayInNumberFormats(weekDays) || [];
          
          let dayNumber=moment(new Date(startDate +" 00:00:00")).add(1,"days").day(); //Sun-Sat -> 0-6
          let firstDayOfRange=dayNumber; let lastDayOfRange=dayNumber;
          for(let i=0;i<totalDaysInBetween;i++){
            firstDayOfRange=((dayNumber+i)%7)<firstDayOfRange? ((dayNumber+i)%7):firstDayOfRange;
            lastDayOfRange=((dayNumber+i)%7)>lastDayOfRange? ((dayNumber+i)%7):lastDayOfRange;
          }
          let validationRule=true;
          weekDaysArrayInNumber.forEach((item)=>{
            if((item<firstDayOfRange || item>lastDayOfRange) && validationRule ) {
              validationRule=false;
            }
          })
          return validationRule
        
      }
      
  }
 
  const resetTabToCrossButton = (evnt) => {    
    if(evnt.key==="Tab") {
      let cross = document.querySelector(".p-dialog-header-icons > button");
      cross.focus();
      evnt.preventDefault();
    }
  }

  const resetTabToCrossButtonNarrative = (evnt) => {    
    if((Loading || disableSaveButton) && evnt.key==="Tab") {
      document.querySelector(".p-dialog-header-icons > button").focus();
      evnt.preventDefault();
    }
  }

  return (
    <div className="af-modal-dialog-addtime">
      <Toast ref={toast} />

      <Dialog
        onHide={() => { }}
        header=""
        closable={false}
        visible={TimeDialog}
        className="af-modal-dialog">
        <div className="p-dialog-header">
          <a
            href={`${helpPageUrl}${HELP_LINKS.DRAFT_TIME_ENTRY}`}
            target="_blank">
            <img
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_HELP]}
              className="af-help-addtime"
              src="/images/svg/Help_icon.svg"
              alt=""
            />
          </a>
          <div className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
              <li className="">
                <span className="p-menuitem-link">
                  <span className="p-menuitem-text">
                    {getCode("TIME_MANAGEMENT")}
                  </span>
                </span>
              </li>

              <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>

              <li className="">
                <span className="p-menuitem-link">
                  <span className="p-menuitem-text">
                    {getCode("DRAFT_TIME_ENTRY")}
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <span className="p-dialog-title"></span>
          <div className="p-dialog-header-icons">
            <button
              onClick={() => {
                if (!changed) {
                  hideDialog()
                }
                else {
                  setCloseConfirmation(true);
                }
              }}
              type="button"
              tabIndex={0}
              className="p-dialog-header-icon p-dialog-header-close p-link"
              aria-label="Close"
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_CLOSE]}>
              <span className="p-dialog-header-close-icon pi pi-times"></span>
            </button>
          </div>
        </div>
        {Loading && (
          <div className="af-spinner">
            <Spinner />
          </div>
        )}

        <div className="af-popup-body">
          <div className="p-grid">
            <div className="p-sm-12 p-md-12 p-lg-8">
              <div className="at-dbcol">
                <div className="af-popup-cnt af-client-bg">
                  <div className="af-btn-sect">
                    <label htmlFor="icon">{getCode("CLIENT")} <span className="af-req-star">*</span></label>
                    
                  { showClientError ? <div className="af-error-msg-display">
                    {getCode("CLIENT_MUST_BE_ENTERED")}
                    </div> :<></> }
                    <CustomRadioButton
                                    columns={["Draft Client"]}
                                    displayType="text"
                                    onChange={(field, item) => {
                                        setClient(item);
                                        setChanged(true);
                                        setClientShowError(false);
                                        saveButtonValidation(item,matter,date);
                                       
                                    }}
                                    onEditableChange={(newVal) => {
                                        setClient(newVal);
                                        setChanged(true);
                                        setClientShowError(false);
                                        saveButtonValidation(newVal,matter,date);
                                    }}
                                    listSource={async (searchText, page) => {
                                        setSearchClientsList([])
                                        const items=await draftTimeApi.draftClientList(page,searchText,delegateId);
                                      
                                        items ? setSearchClientsList(items.data):setSearchClientsList([]);
                                        return items;
                                      
                                    }}
                                    data={(typeof (client) === "object" ? (client.client_number === null ? "" : client.client_number) : client)}
                                    displayDataFor="TM"
                                    placeholder={ getCode("ENTER_UP_TO_10_CHARACTERS")}
                                    src="/images/svg/search.svg"
                                    persistSearchLoad={true}
                                    characterLimit={50}
                                  
                                    tabOrder={0}
                                />
                  </div>

                  <div className="af-btn-sect">
                  { hasMultipleSubClients &&  <><label htmlFor="icon">{getCode("SUB")}</label>

                    <InputText
                    data-testid = "draft-sub"
                     tabIndex={0}
                      type="text"
                      placeholder={getCode("ENTER_UP_TO_3_CHARECTERS")}
                      className="time-draft-input radioButtonInput af-draft-sub"
                      id="time-draft-input-matter"
                      maxLength="3"
                      onChange={(event) => {
                        setSubClient(event.target.value)
                        setChanged(true);
                        }
                      }
                      autoComplete="off"
                    /></> }
                  </div>
                  <div className="af-btn-sect">
                    <label htmlFor="icon">{getCode("MATTER")} <span className="af-req-star">*</span></label>
                    { showMatterError ? <div className="af-error-msg-display">
                    {getCode("MATTER_MUST_BE_ENTERED")}
                    </div> :<></> }
                    <CustomRadioButton
                                  
                                     tabOrder={0}
                                    columns={["Draft Matter"]}
                                    displayType="text"
                                    onChange={(field, item) => {
                                      setMatter(item);
                                      setChanged(true);
                                      setMatterShowError(false);
                                      saveButtonValidation(client,item,date);
                                    }}
                                    onEditableChange={(newVal) => {
                                        setMatter(newVal)
                                        setChanged(true);
                                        setMatterShowError(false);
                                        saveButtonValidation(client,newVal,date);
                                    }}
                                    listSource={async (searchText, page) => {
                                        let searchby = "";
                                        if( typeof(client) === 'string' )
                                        {
                                          searchby=isDraftExactMatchFound(client,searchClientsList) || ""
                                        }
                                        else{
                                          searchby=client;
                                        }
                                        console.log("searchby",searchby)
                                        const items = draftTimeApi.draftMatterList(page,searchText,searchby,delegateId)
                                        return items;
                                    }}
                                    data={(typeof (matter) === "object" ? (matter.matter_number === null ? "" : matter.matter_number) : matter)}
                                    displayDataFor="TM"
                                    placeholder={getCode("ENTER_UP_TO_10_CHARACTERS")}
                                    src="/images/svg/search.svg"
                                    persistSearchLoad={true}
                                    characterLimit={50}
                                />
                  </div>

                  <div className="af-lookup-cont">
                  <div style={{display:"flex"}}>
                      <label htmlFor="icon">
                      {getCode("DATE")} <span className="af-req-star">*</span>
                    </label>
                    
                  </div>

                    <div className="p-field">

                      {/* <CalendarComponent 
                      tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE].toString()}
                      tooltipOptions={{
                        className: "white-tooltip",
                        position: "top",
                        showDelay: 700,
                      }}
                      maxDate={endDateValue}
                      placeholder={dateFormat}
                      dateFormat={
                        dateFormat &&
                        dateFormat

                          .toLowerCase()

                          .replace("yyyy", "yy")
                      }
                      readOnlyInput
                      //monthNavigator
                      yearNavigator
                      yearRange="1600:6030"
                      id="icon"
                      appendTo={document.body}
                      value={date}
                      showIcon={true}
                      onChange={(e) => {
                        setChanged(true);
                        setDate(e.value);
                        checkRateAmount(e.value);
                        FieldState.update(FieldState.setDateError);
                        saveButtonValidation(client,matter,e.value);
                      }}
                      /> */}
                       <Calendar
                        tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE].toString()}
                        tooltipOptions={{
                          className: "white-tooltip",
                          position: "top",
                          showDelay: 700,
                        }}
                        maxDate={endDateValue}
                        placeholder={dateFormat}
                        dateFormat={
                          dateFormat &&
                          dateFormat

                            .toLowerCase()

                            .replace("yyyy", "yy")
                        }
                        readOnlyInput
                        //monthNavigator
                        yearNavigator
                        yearRange="1600:6030"
                        id="icon"
                        appendTo={document.body}
                        value={date}
                        showIcon={true}
                        onChange={(e) => {
                          setChanged(true);
                          setDate(e.value);
                          checkRateAmount(e.value);
                          FieldState.update(FieldState.setDateError);
                          saveButtonValidation(client,matter,e.value);
                          document.querySelector('#icon > input').focus();                         
                        }}
                      /> 
                    </div>
                    { showErrorRepeatTimeEntry ? (!endDateValue || checkboxDays.length===0) ? <div className="af-error-msg-display">
                      {jsUtils.getLiteralValue('DAYS_OF_WEEK_AND_END_DATE_MUST_BE_CHECKED_OR_REPEAT_TIME_ENTRY_CLEARED')}
                    </div>:
                    <div className="af-error-msg-display">
                     {jsUtils.getLiteralValue('SELECTED_DAYS_NOT_IN_RANGE')}
                  </div>:<></>
                    }
                    <div style={{display:"flex",paddingLeft:"1rem"}}>
                      <div>
                      <CheckBox tabOrder={0} id={"repeattime"}
                       onSelectionChange={()=>{setShowErrorRepeatTimeEntry(false); setRepeatTimeEntryFlag(!repeatTimeEntryFlag);repeatTimeEntryFlag && setEndDateValue(null) && setCheckboxDays([])}}
                       checked={repeatTimeEntryFlag}/>
                      </div>
                      <div style={{color:"#0050c3"}}>
                      <label className={!repeatTimeEntryFlag?"repeatTimeEntryLabel":"repeatTimeEntryLabelSelected"} htmlFor={"repeattime"}>{jsUtils.getLiteralValue("REPEAT_TIME_ENTRY")}</label>
                      </div>
                    </div>
                    {repeatTimeEntryFlag && <RepeatTimeEntry onChange={onChangeCheckboxDays} startDate={date}
                     endDateValue={endDateValue} setEndDateValue={setEndDateValue}
                     setShowErrorRepeatTimeEntry={setShowErrorRepeatTimeEntry}
                     setChanged={setChanged} />}
                  </div>
                  <div className="af-error-msg-display">
                    {/* {DurationState.error} */}
                    {FieldState.durationError}
                  </div>
                  <div className="af-lookup-cont af-time-post">
                    <div className="af-duration-header">
                      {durationToggleClick ? (
                        <div className="af-time-label-duration">
                          <label htmlFor="time">{getCode("DURATION")}</label>
                        </div>
                      ) : (
                        <div className="recoded-duration">
                          <div className="af-time-label">
                            <label htmlFor="time">
                              {" "}
                              {getCode("RECORDED_DURATION")}
                            </label>
                          </div>
                          <div className="af-time-label-bd">
                            <label htmlFor="time">
                              {" "}
                              {getCode("BILLABLE_DURATION")}
                            </label>
                          </div>
                        </div>
                      )}
                      <ToggleButtonComponent toggleClick={toggleClick} title={tooltips[TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]} />
                    </div>

                    <div className="af-input-lookup">
                      {!durationToggleClick ? (
                        <div className="billable-duration-col">
                          <div className="hour-col">
                            <InputText
                              tabIndex={0}
                              data-testid = "hour-id"
                              id="hourstxt"
                              type="number"
                              value={DurationState.hh}
                              onKeyPress={(e) =>
                                e.key === "e" || e.key === "E"
                                  ? e.preventDefault()
                                  : e
                              }
                              onChange={onChangeHH}
                              onBlur={validationUtils.validateDurationFieldDraftTimeGT}
                              autoComplete="off"
                            />{" "}
                            <p>{getCode("HH")}</p>{" "}
                            <span className="sp-coln">:</span>
                          </div>

                          <div className="min-col">
                            <InputText
                              tabIndex={0}
                              data-testid = "min-id"
                              id="mintxt"
                              type="number"
                              value={DurationState.mm}
                              onKeyPress={(e) =>
                                e.key === "e" || e.key === "E"
                                  ? e.preventDefault()
                                  : e
                              }
                              onChange={onChangeMM}
                              onBlur={validationUtils.validateDurationFieldDraftTimeGT}
                              autoComplete="off"
                            />{" "}
                            <p>{getCode("MM")}</p>
                            <span className="sp-eql">=</span>
                          </div>

                          <div className="bil-col">
                            {" "}
                            <InputText
                              value={DurationState.duration}
                              disabled
                              id="billTxt"
                              type="text"
                              autoComplete="off"
                            />{" "}
                            <p>x.xx</p>
                          </div>
                        </div>
                      ) : (
                        <div className="duration-col">
                          <span className="duration-input-margin">
                            <InputText
                            data-testid = "duration-txt"
                              tabIndex={0}
                              type="number"
                              id="durationtxt"
                              value={DurationState.duration}
                              maxLength="7"
                              placeholder="0.00"
                              onKeyPress={(e) =>
                                e.key === "e" || e.key === "E"
                                  ? e.preventDefault()
                                  : e
                              }
                              onChange={onChangeDuration}
                              onBlur={validationUtils.validateDurationFieldDraftTimeGT}
                              autoComplete="off"
                            />
                          </span>

                          <div className="af-timer-btn-panel">
                            {DurationState.increments.map((d) => (
                              <button
                              data-testid = "duration-btn"
                                tabIndex={0}
                                className="p-button p-component af-btn-default"
                                onClick={() => {
                                  setChanged(true);
                                  FieldState.update(
                                    FieldState.setDurationError
                                  );
                                  DurationState.update(
                                    DurationState.onIncrement,
                                    [d]
                                  );
                                  validationUtils.validateDurationFieldDraftTimeGT();
                                }} key={`_${d}`}>
                                <span className="p-button-label p-c" key={`k${d}`}>
                                  +{(d / 60).toFixed(2)}
                                </span>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* middle secion start */}                    
                <div className="af-popup-codetypes">
                  {FieldState.payload.map((item, index) => {
                    let selected =
                      item.values && item.values.find((d) => d.isDefault);

                    return (
                      item.isDisplay && (
                        <div className="af-btn-sect" key={index}>
                          <h3>
                            {item.displayLabel}
                            {item.isMandatory && (
                              <span className="af-req-star">*</span>
                            )}
                          </h3>

                          <span className="radio-msg">
                            {selected && selected.description}
                          </span>

                          <div className="af-error-msg-display">
                            {item.errorMessage}
                          </div>

                          <RadioDropDownComponent
                            tabOrder={0}
                           
                            columns={["Code", "Description"]}
                            displayType="button"
                            textDisplayPropName="description"
                            onChange={(
                              field,
                              option,
                              ignoreIfAlreadySelected
                            ) => {
                              if (item.errorMessage)
                                FieldState.update(FieldState.addError, [
                                  [
                                    {
                                      field: item.field
                                        .toLowerCase()
                                        .replace(/ /g, ""),
                                      message: "",
                                    },
                                  ],
                                ]);
                              onCodesSelected(
                                field,
                                option,
                                ignoreIfAlreadySelected
                              );
                            }}
                            listSource={
                              item.field === "MAKE_NO_CHARGE"
                                ? null
                                : async (searchText, page) => {
                                  let list = await loadCodes(
                                    item.field,

                                    searchText,

                                    page,

                                    delegateId
                                  );
                                  return list.fields.length > 0
                                    ? list.fields[0].values
                                    : [];
                                }
                            }
                            data={item}
                          />
                          {item.field === "RATE_CODE" && showRateAmount && (
                            <div className="af-amount af-input-lookup">
                              <div className="af-amount-field">
                                {getCode("ENTER_AMOUNT")}
                              </div>{" "}
                              <div className="af-amount-field">
                                <InputText
                                  id="rateInputAmount"
                                  value={rateAmountValue}
                                  onChange={(event) =>
                                    setRateAmountValue(event.target.value)
                                  }
                                  type="number"
                                  autoComplete="off"
                                  data-testid="rateInputAmount"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
                {/* middle secion end */}                    
              </div>
            </div>
            <div className="p-sm-12 p-md-12 p-lg-4">
              <div className="af-popup-narrative">
                <label>{getCode("NARRATIVE")}</label>

                <div className="af-input-lookup ">
                  <p id="narrativeTextbox" className={narrativeLabelClass}>
                    {getCode("TYPE_OR_SPEAK_YOUR_NARRATIVE")} (
                    {maxChars - noOfChars} {getCode("CHARS_REMAINING")})
                  </p>

                  <div className="af-nar-btnpanel">
                    <button
                      tabIndex={0}
                      className="p-button p-component btn-Icon"
                      onClick={HandleClick}
                      data-testid = "micButton">
                        
                      {toggleSpeechRecog && !transcribeReady ? (
                        <img
                          title={tooltips[TOOLTIPS_KEYS.TOOLTIP_DICTATE]}
                          alt=""
                          src={"/images/svg/icon_mic.svg"}
                        />
                      ) : transcribeReady ? (
                        <img
                          title={tooltips[TOOLTIPS_KEYS.TOOLTIP_DICTATE]}
                          alt=""
                          src={"/images/u1513.svg"}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </button>
                  </div>
                </div>

                <div className="af-error-msg-display">
                  {FieldState.narrativeError}
                </div>

                <div className="af-narrative">
                  <NarrativeWrapper
                    module="DraftTime"
                    onChangeNarrative={onChangeNarrative}
                    onNarrativeCorrection={onNarrativeCorrection}
                    value={narrativeValue}
                    isNarrativeDisabled={isNarrativeDisabled}
                    setKeyPressed={setKeyPressed}
                    rows={22}
                    onChange={onChange}
                    containerDivClass="narrative-container"
                    spellCheckToggle = {spellCheckToggle}
                    resetTabToCrossButtonNarrative={(e) => resetTabToCrossButtonNarrative(e)}
                  />
                </div>

                <div className="af-btn-panel">
                  <button
                    tabIndex={0}
                    className="p-button p-component af-btn-default"
                    // data-testid="save"
                    disabled={Loading || disableSaveButton ? true : false}
                    onKeyDown={(e)=>{resetTabToCrossButton(e)}}
                    onClick={() => {
                      if(repeatTimeEntryFlag && (!endDateValue || checkboxDays.length===0)){
                        setShowErrorRepeatTimeEntry(true);
                        setDisplayConfirmation(true)
                    }
                    else if(repeatTimeEntryFlag && !validateDateRangeWithWeekDays(date,endDateValue,checkboxDays)){
                      setShowErrorRepeatTimeEntry(true);
                      setDisplayConfirmation(true);
                    }
                    else if((maximumLimitType === "W" && (maxChars- noOfChars) < 0) && !ignoreClicked){
                           setShowMaxLimitMsg(true);
                        }

                    else{
                      !Loading && onSave();
                    }
                    }}
                    data-testid="saveButton"
                    >
                    <span className="p-button-label p-c">
                      {getCode("SAVE")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="timerClass">
          <div className="timerClass-dialog">
            <Dialog
              closable={false}
              header="Confirmation"
              visible={displayConfirmation}
              modal
              footer={renderFooter("displayConfirmation")}
              onHide={() => onHide("displayConfirmation")}>
              <div className="confirmation-content">
                <img
                  title=""
                  alt=""
                  className="af-warning"
                  src="/images/icon_warning.svg"
                />
                <span>{getCode("YOU_HAVE_ERRORS_ON_THE_SCREEN")}</span>
              </div>
            </Dialog>
          </div>
        </div>
        <div className="timerClass">
          <div className="timerClass-dialog">
            <Dialog
              closable={false}
              header="Confirmation"
              visible={closeConfirmation}
              modal
              footer={renderFooterClose("closeConfirmation")}
              onHide={() => onHideClose("closeConfirmation")}>
              <div className="confirmation-content">
                <img
                  title=""
                  alt=""
                  className="af-warning"
                  src="/images/icon_warning.svg"
                />
                <div>
                  <span>
                    {getCode(
                      "YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_SAVE"
                    )}
                  </span>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </Dialog>
      {showRWValidationMsg && (
        <ConfirmationBox
          message={
            getCode(
              "YOUR_NARRATIVE_INCLUDES_RESTRICTED_WORDS_YOU_SHOULD_REPLACE_ALL_RESTRICTED_WORD_ERRORS_BEFORE_SAVING"
            )
          }
          showRWValidationMsg={showRWValidationMsg}
          setPopupVisibleToggle={(flag)=>{onSetshowRWValidationMsg(flag)}}
          showOkButton = {true}

        />
      )}
      {showMaxLimitMsg && !ignoreClicked &&(
        <ConfirmationBox
          message={maximumLimitMsg}
          showRWValidationMsg={showMaxLimitMsg}
          setPopupVisibleToggle={(flag)=>{onSetShowLimitMsg(flag)}}
          setIgnoreClicked = {setIgnoreClicked}
          showIgnoreButton = {true}
          showCancelButton = {true}
        />
      )}
      {/*
      //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
        <span className="Confirmation-popup">
        {showInformationalPopup && (
          
          <ConfirmationDialogBox
            visible={showInformationalPopup}
            message={informationalMessage}
            header="Confirmation"            
            onConfirm={()=>{
              setShowInformationalPopup(false);
              onSaveHandler(afterSavingPreference);
            }}
            disableConfirmButton={false}
            disableCancelButton={true}
            confirmButtonLabel={jsUtils.getLiteralValue("OK")}
            confirmButtonClassName={"yes-button"}
            imageClassName={"af-warning"}
            imageSrc={"/images/icon_warning.svg"}
          />
        )}
        
      </span>
          */}
      
      <Transcribe
        toggleSpeechRecog={toggleSpeechRecog}
        setTransValue={setNarrativeValue}
        narrativeValue={narrativeValue}
        presignedURL={presignedURL}
        maxChars={maximumLimitType==="E" ? maxChars : 5000}
        autoCorrectDictionary = {autoCorrectDictionary}
      />
    </div>
  );
}

export default DraftTimePopup;