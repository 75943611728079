import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { SET_ERROR_MESSAGE, SET_SIGNING_OUT, RESET_SIGNING_OUT, REFRESH_STORE_ON_ERROR, SET_CURRENT_TAB_TITLE, SESSION_EXPIRED, SET_COUNTDOWN_MINUTES_AND_SECONDS, SET_COUNTDOWN_MINUTES, SET_COUNTDOWN_SECONDS, SET_CHECK_EMAIL, SET_SELECTED_RESTRICTED_WORD, SET_PERMISSION_OF_USER, SET_WOBO_ACTION } from "../actions/ActionType";
import initialState from "../store/InitialState";
var AppReducer = function AppReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.AppReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        apiResponse: action.data,
        showMessage: true
      });
    case SET_SIGNING_OUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        signingOut: true
      });
    case RESET_SIGNING_OUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        signingOut: false
      });
    case REFRESH_STORE_ON_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        refresh: action.data
      });
    case SET_CURRENT_TAB_TITLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        title: action.data
      });
    case SESSION_EXPIRED:
      return _objectSpread(_objectSpread({}, state), {}, {
        sessionExpired: action.data.sessionExpired
      });
    case SET_COUNTDOWN_MINUTES_AND_SECONDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        minutes: action.data.minutes,
        seconds: action.data.seconds
      });
    case SET_COUNTDOWN_MINUTES:
      return _objectSpread(_objectSpread({}, state), {}, {
        minutes: action.data.minutes
      });
    case SET_COUNTDOWN_SECONDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        seconds: action.data.seconds
      });
    case SET_CHECK_EMAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        enabled: action.data.enabled,
        success: action.data.success,
        isMFA: action.data.isMFA
      });
    case SET_SELECTED_RESTRICTED_WORD:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedWord: action.data
      });
    case SET_PERMISSION_OF_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        fieldnames: action.data
      });
    case SET_WOBO_ACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        wobo: action.data
      });
    default:
      return state;
  }
};
export default AppReducer;