import React, { useEffect, useState } from "react";
import jsUtils from "../../utils/jsUtils";
import "./ManageTime.scss"
import "./narrativeOncard.scss"
const NarrativeOnCard=({errors,cardItem})=>{

    return  (<div style={{marginBottom:"1rem"}}>
         <div className="MT-narrative">
    {errors && errors.find((error) => error.field === "narrative") ? (
      <>
        <img
          alt=""
          className="imageInvalid"
          src={`/images/svg/icon_invalid.svg`}
        ></img>
        <strong style={{ color: "#bb0000" }}>{jsUtils.getLiteralValue("NARRATIVE")}</strong>
      </>
    ) : (
      <strong>{jsUtils.getLiteralValue("NARRATIVE")}</strong>
    )}
  </div>
  <div className="MT-narrative-notes">
    {cardItem && cardItem.ethicalWallFlag ? cardItem.narrative && cardItem.narrative.replaceAll("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS",jsUtils.getLiteralValue("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS")):cardItem.narrative}
  </div>
  </div>)
}
export default NarrativeOnCard;