import React from "react";
import { MultiSelectPrime } from "@attorneyflow-mf/styleguide";

const MultiSelectDropDown = ({ label, filterBy, value, options, onChange, onFocus, optionLabel, itemTemplate, tabIndex}) => {
  return (
    <>
      <span className="p-float-label">
        <span className = 'af-adv-sreach-label'>{label}</span>
        <MultiSelectPrime
          inputId="multiselect"
          value={value}
          options={options}
          onChange={onChange}
          onFocus={onFocus}
          optionLabel={optionLabel}
          filter
          filterBy={filterBy}
          itemTemplate={itemTemplate}
          //tabIndex={tabIndex}
        />
      </span>
    </>
  );
};

export default MultiSelectDropDown;
