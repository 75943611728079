import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { SET_AUTH_DETAILS, RESET_AUTH_DETAILS } from "../../actions/ActionType";
import initialState from "../../store/InitialState";
import { GetSetDelegateData, GetSetPermissionsData, GetSetLiteralData, GetSetMeData, GetSetMenuData } from "@attorneyflow-mf/styleguide";
var AuthReducer = function AuthReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.AuthReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case SET_AUTH_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: action && action.data && action.data.user,
        email: action && action.data && action.data.email,
        isAuth: true
      });
    case RESET_AUTH_DETAILS:
      GetSetDelegateData(null, true);
      GetSetPermissionsData(null, true);
      GetSetLiteralData(null, true);
      GetSetMeData(null, true);
      GetSetMenuData(null, true);
      return _objectSpread(_objectSpread({}, state), {}, {
        user: null,
        email: null,
        isAuth: false
      });
    default:
      return state;
  }
};
export default AuthReducer;