import RadioButton from "../../../../../common/RadioButton/RadioButton";
import React, { useEffect, useState } from "react";
import jsUtils from "../../../../../../utils/jsUtils";
import { InputSwitch } from "primereact/inputswitch";
import "../../../../../common/AddTimePopup/AddTimePopup.scss"
import "./RestrictedWordsGrid.scss"

export default function RestrictedWordsLeftBodyControl({toggleVal,setToggle,onClientSelection,clientSelected,matterSelected,onMatterSelection,subClientSelected,onSubClientSelection,loadClients,loadSubClients,loadMatters,mode,showSubClient}){

    const [selectedClient,setSelectedClient]=useState(clientSelected);
    const [selectedSubClient,setSelectedSubClient]=useState(subClientSelected);
    const [selectedMatter,setSelectedMatter]=useState(matterSelected);

    useEffect(()=>{
        setSelectedClient(clientSelected);
        setSelectedMatter(matterSelected);
        setSelectedSubClient(subClientSelected)
    },[clientSelected,subClientSelected,matterSelected])

    return <div className= {mode!=="edit" ?"af-popup-cnt af-client-bg rw-left-section":"af-popup-cnt af-client-bg rw-left-section disableClick"}>
    <div className="af-btn-sect">
    
        <div className="toggle-dialog-control">
            <span className="text">{jsUtils.getLiteralValue("FIRM")} &nbsp;</span>
            <InputSwitch
            checked={toggleVal}
            onChange={setToggle}
            />
            <span className="text">&nbsp;&nbsp; {jsUtils.getLiteralValue("CLIENT")}/{jsUtils.getLiteralValue("MATTER")}</span>
        </div>
        
        
       { toggleVal && <div className="af-btn-sect">
        <label htmlFor="icon">{jsUtils.getLiteralValue("CLIENT")} <span className="af-req-star">*</span></label>

        <RadioButton
            columns={[jsUtils.getLiteralValue("NUMBER"), jsUtils.getLiteralValue("CLIENT")]}
            displayType="text"
            textDisplayPropName="desc"
            onChange={(field, value) => {
                onClientSelection(field, value);
            }}
            listSource={async (searchText, page, pageOffset) => {
                let list = await loadClients(searchText, page, pageOffset);
                return list;
            }}
            data={selectedClient}
            fieldName="clients" // Pass this param if API is not responding 20records in each response
            tabOrder="1"
            />

        </div>}
        
        {toggleVal && showSubClient && <div className="af-btn-sect">
        <label htmlFor="icon">{jsUtils.getLiteralValue("SUB")}</label>

        <RadioButton
            columns={[jsUtils.getLiteralValue("NUMBER"), jsUtils.getLiteralValue("SUB-CLIENT")]}
            displayType="text"
            textDisplayPropName="desc"
            onChange={(field, value) => {
                onSubClientSelection(field, value);
            }}
            listSource={async (searchText, page, pageOffset) => {
                let list = await loadSubClients(searchText, page, pageOffset);
                return list;
            }}
            data={selectedSubClient}
            fieldName="subClients" // Pass this param if API is not responding 20records in each response
            tabOrder="1"
            />

         </div>}

        {toggleVal && <div className="af-btn-sect">
        <label htmlFor="icon">{jsUtils.getLiteralValue("MATTER")} </label>
           
        <RadioButton
            columns={[jsUtils.getLiteralValue("NUMBER"), jsUtils.getLiteralValue("MATTER_DESCRIPTION")]}
            displayType="text"
            textDisplayPropName="desc"
            onChange={(field, value) => {
                onMatterSelection(field, value);
            }}
            listSource={async (searchText, page, pageOffset) => {
                let list = await loadMatters(searchText, page, pageOffset);
                return list;
            }}
            data={selectedMatter}
            fieldName="matters" // Pass this param if API is not responding 20records in each response
            tabOrder="1"
            />
         </div>}

    </div>
  </div>
}