import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { FETCH_CLIENTITEMS_REQUEST, FETCH_CLIENTITEMS_SUCCESS, FETCH_CLIENTITEMS_FAILURE, FETCH_TIMEKEEPERLIST_SUCCESS, FETCH_TIMEKEEPERLIST_REQUEST, SET_SELECTED_TIMEKEEPER, SET_ROUNDING_METHOD, SET_FEATURE, FETCH_ALL_RESTRICTED_WORDS_SUCCESS, SET_DURATION_TOGGLE, SET_HIGHLIGHT_RESTRICTED_WORDS, SHOW_EDIT_TIME_ENTRY, SET_SELECTED_TE, SET_AFTER_SAVING_PREFERENCE } from "../../actions/ActionType";
import initialState from "../../store/InitialState";
var TimeManagementReducer = function TimeManagementReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.TimeManagementReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action && action.type) {
    case FETCH_CLIENTITEMS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case FETCH_CLIENTITEMS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        clientitems: action.data,
        error: ""
      });
    case FETCH_CLIENTITEMS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        clientitems: [],
        error: action.data
      });
    case FETCH_TIMEKEEPERLIST_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingTimeKeeper: true
      });
    case FETCH_TIMEKEEPERLIST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingTimeKeeper: false,
        timeKeeperList: action.data,
        error: ""
      });
    case SET_SELECTED_TIMEKEEPER:
      return _objectSpread(_objectSpread({}, state), {}, {
        delegateId: action.data.delegateId,
        dateFormat: action.data.dateFormat,
        attorneyCode: action.data.attorneyCode,
        userDelegateRoundingMethod: action.data.userDelegateRoundingMethod,
        timekeeper: action.data.timekeeper
      });
    case SET_ROUNDING_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        userDelegateRoundingMethod: action.data.userDelegateRoundingMethod
      });
    case SET_FEATURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        timeKeeperFeatureName: action.data.FeatureName,
        timeKeeperFeatureId: action.data.FeatureId
      });
    case FETCH_ALL_RESTRICTED_WORDS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        allRestrictedWords: action.data.restrictedWord
      });
    case SET_HIGHLIGHT_RESTRICTED_WORDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        highlightRestrictedWords: action.data
      });
    case SET_DURATION_TOGGLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggedInUserToggle: action.data.loggedInUserToggle
      });
    case SHOW_EDIT_TIME_ENTRY:
      return _objectSpread(_objectSpread({}, state), {}, {
        showEditTimeEntryPage: action.data
      });
    case SET_SELECTED_TE:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedTimeEntries: action.data
      });
    case SET_AFTER_SAVING_PREFERENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        afterSavingPreference: action.data
      });
    default:
      return state;
  }
};
export default TimeManagementReducer;