import React from "react";
import "./RestrictedWordsCard.scss";
import jsUtils from "../../../../../../utils/jsUtils";

export default function RestrictedWordsCard(props) {
 
  return (
    <>     
          <div className="RW-card">
            <div className="accordion-header">{jsUtils.getLiteralValue("MESSAGE")} </div>
            <div>{props?.rowData?.errorMessage} </div>
          </div>
    </>
  );
}
