import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import "./RepeatTimeEntry.scss";
import jsUtils from "../../../../utils/jsUtils";
import moment from "moment";
import {CheckBox} from "@attorneyflow-mf/styleguide"
const RepeatTimeEntry = ({onChange,startDate,setShowErrorRepeatTimeEntry,
  endDateValue,setEndDateValue,setChanged,days=["Mon","Tue","Wed","Thu","Fri","Sat","Sun"], tabOrder=0}) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [minDate,setMinDate] = useState(null)
  const dateFormat=useSelector((state)=>state.TimeManagementReducer.dateFormat)
  useEffect(()=>{
    startDate && setMinDate(new Date(moment(new Date(startDate)).add(1,"days")))
  },[startDate])
  const onDayChange=(e)=>{
      let _days=[...selectedDays];
      let index=selectedDays.findIndex((item)=>e.value===item);
    if(index!=-1)
    {
        _days.splice(index,1);
    }
    else{
        _days.push(e.value)
    }
    onChange(_days)
    setSelectedDays(_days)

  }
  return (
    <div className="repeatTimeEntryField" >
      <div className="labelTextDate">
        {jsUtils.getLiteralValue("TO_THE_FOLLOWING_DATE_ON_THE_SELECTED_DAYS")}
      </div>
      <div className="RepeatTimeEntryCalendar">
      <Calendar id="repeatte-icon" 
      placeholder="mm/dd/yyyy" 
      dateFormat={
        dateFormat &&
        dateFormat

          .toLowerCase()

          .replace("yyyy", "yy")
      }
      readOnlyInput
      yearNavigator 
      yearRange="2000:2050"
      showIcon={true} 
      value={endDateValue}
      minDate={minDate} 
      onChange={(e)=>{
        setEndDateValue(e.value); setChanged(true); setShowErrorRepeatTimeEntry(false);
        document.querySelector('#repeatte-icon > button').focus(); 
        document.querySelector('#repeatte-icon > input').focus(); 
      }}
      tabIndex={tabOrder}
      />
      <span className="af-req-star-repeatTime">*</span>
       </div>
       <div className="alignlabels">
      <div className="labelTextDate">  
        {jsUtils.getLiteralValue("DAYS_OF_WEEK")}
        <span className="af-req-star-repeatTime">*</span>
        <div className="labelMandatory">
        {jsUtils.getLiteralValue("AT_LEAST_ONE_DAY_MUST_BE_CHECKED")}
      </div>
      </div>
      </div>
      <div className="repeatTimeCheckboxDays">
          {days.map((item,index)=>{
             return <div className={index!==0 ?"checkBoxDay":""} style={{display:"grid"}}>
              <CheckBox  tabOrder={tabOrder} value={item} checked={selectedDays.indexOf(item) !== -1} 
              onSelectionChange={onDayChange}/>
              <label className="daysLabel" htmlFor={item}>{item.slice(0,1)}</label>
              </div>
          })}
      </div>

    </div>
  );
};
export default RepeatTimeEntry;
