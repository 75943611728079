import React, { useEffect, useState, useRef } from "react";
import "./AnalyticsDashboard.scss";
import { useHistory } from "react-router-dom";
import Spinner from "../common/Spinner/Spinner";
import { TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../config/vars";
import storage from "../../utils/storage";
import jsUtils from "../../utils/jsUtils";
import { Toast } from "primereact/toast";
import { sisenseApi } from "../../services/sisenseApi";

function AnalyticsDashboard(props) {

  let history = useHistory();

  /** set tooltips from storage on screen load
   *  literals -variable to store the array of literals
   */
  const [signingOut] = useState(false);
  const [, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP],
  });

  const [iframeUrl, setIframeUrl] = useState([]);
  const [fetchingUrl, setFetchingUrl] = useState(true);
  const toast = useRef(null);

  const dashboardName = props.match.params.MODE;

  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP],
      });
    }

    // Set title
    switch (dashboardName && dashboardName.toLowerCase().trim()) {
      case "bi-ba":
        // dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_BILLING_ATTORNEY_DASHBOARD");
        break;
      case "bi-cfo":
        // dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_CFO_DASHBOARD");
        break;
      case "bi-mp":
        // dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGING_PARTNER_DASHBOARD");
        break;
      case "bi-sassoc":
        // dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_SUPERVISING_ASSOCIATE_DASHBOARD");
        break;
      case "bi-spart":
        // dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_SUPERVISING_PARTNER_DASHBOARD");
        break;
      case "bi-wassoc":
        // dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_WORKING_ASSOCIATE_DASHBOARD");
        break;
      case "bi-wpart":
        // dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.MANAGE_TIME}`))
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_WORKING_PARTNER_DASHBOARD");
        break;
    }
  }, []);

  // Retrieve dashboard url
  useEffect(() => {
    sisenseApi.getDashboard(dashboardName).then(resp => {
      const sisenseDashboard = resp.dashboard.url;
      setIframeUrl(sisenseDashboard);
      setFetchingUrl(false);
    }).catch((e) => {
      console.log(e);
      toast &&
        toast.current &&
        toast.current.show({
          severity: "error",
          detail: jsUtils.getLiteralValue("ERROR_LOADING_REQUESTED_DASHBOARD"),
          life: 3000
        })
      setTimeout(() => {
        history.push("/");
      }, 3000);
    });
  }, []);

  return (
    <div className='af-analytics'>
      <Toast ref={toast} />
      {fetchingUrl || signingOut ?
        <div className='spinner-popup'>
          <Spinner />
        </div>
        :
        <iframe className='iframe' src={iframeUrl} />
      }
    </div>
  );
}

export default AnalyticsDashboard;
