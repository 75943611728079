import { Dialog } from "primereact/dialog";
import React from "react";
import jsUtils from "../../../utils/jsUtils";
import ConfirmationDialogBox from "../ConfirmationBox/ConfirmationDialogBox";
import  "./DialogControl.scss";

export default function DialogControl({
  getHeader,
  getLeftBodySection,
  getMiddleBodySection,
  getRightBodySection,
  setToggle,
  onClientSelection,
  onSubClientSelection,
  onMatterSelection,
  clientSelected,
  matterSelected,
  subClientSelected,
  loadClients,
  loadSubClients,
  loadMatters,
  toggleVal,
  visible,
  setVisible,
  restrictedWord,
  onRestrictedWordChange,
  suggestedReplacement,
  onSuggestedReplacementChange,
  type,
  onTypeChange,
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
  narrativeValue,
  onNarrativeChange,
  noOfChars,
  onSave,
  enableSaveButton,
  limit,
  mode,
  onLimitChange,
  displayConfirmation,
  setDisplayConfirmation,
  setChanged,
  hideDialog,
  startDateDisable,
  DBCurrentDate,
  showSubClient
}){


    return <div className="af-modal-dialog-dialogcontrol"><Dialog
            onHide={() => { }}
            header=""
            closable={false}
            visible={visible}
            className="af-modal-dialog">

            {getHeader(setVisible)}

            <div className="af-popup-body">
                <div className="p-grid">

                    <div className="p-sm-12 p-md-12 p-lg-8">
                        <div className="at-dbcol">
                          {getLeftBodySection(toggleVal,setToggle,onClientSelection,clientSelected,matterSelected,onMatterSelection,subClientSelected,onSubClientSelection,loadClients,loadSubClients,loadMatters,mode,showSubClient)}
                          {getMiddleBodySection(restrictedWord,onRestrictedWordChange,suggestedReplacement,onSuggestedReplacementChange,type,onTypeChange,startDate,endDate,onStartDateChange,onEndDateChange,limit,onLimitChange,mode,startDateDisable, DBCurrentDate)}
                        </div>
                    </div>

                    <div className="p-sm-12 p-md-12 p-lg-4">
                        {getRightBodySection(narrativeValue,onNarrativeChange,noOfChars,onSave,enableSaveButton,mode)}
                    </div>
                </div>
            </div>
        
    </Dialog>
    <span className="Confirmation-popup">
        {displayConfirmation && (
          
          <ConfirmationDialogBox
            visible={displayConfirmation}
            message={jsUtils.getLiteralValue("YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_STAY")}
            header="Confirmation"            
            onCancel={()=>{
              setDisplayConfirmation(false);
              
            }}
            onConfirm={()=>{
                setChanged(false);
                setDisplayConfirmation(false);
                hideDialog(true);
            }}
            cancelButtonLabel={jsUtils.getLiteralValue("STAY")}
            confirmButtonLabel={jsUtils.getLiteralValue("LEAVE")}
            cancelButtonClassName={"p-button-text no-button"}
            confirmButtonClassName={"yes-button"}
            imageClassName={"af-warning"}
            imageSrc={"/images/icon_warning.svg"}
          />
        )}
        
      </span>
    </div>
}