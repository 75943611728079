import React from "react";

class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error) {
    console.log("getDerivedStateFromError ", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service    
  }

  render() {
    if (this.state.hasError) {
      return <div data-testid="errorboundary">Something went wrong.</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryComponent;
