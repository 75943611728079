import React from "react";
import jsUtils from "../../../utils/jsUtils";

const AfterSavingRadioButtons = ({
  afterSavingValue,
  onRadiobuttonSelection,
}) => {
  return (
    <div className="pref-row-new">
      <div className="row-title">{jsUtils.getLiteralValue("AFTER_SAVING")}</div>
      <div className="row-radio-btn-after-saving">
        <input
          onChange={() => onRadiobuttonSelection("keep_te_open")}
          type="radio"
          value="keep_te_open"
          name="afterSaving"
          checked={afterSavingValue === "keep_te_open" ? true : false}
          autocomplete="off"
          data-testid = "afterSavingRadio1"
        />
        <div className="row-radio-element">
          {" "}{jsUtils.getLiteralValue("KEEP_TIME_ENTRY_OPEN")}{" "}
        </div>
        <input
          onChange={() => onRadiobuttonSelection("return_to_addTime")}
          type="radio"
          value="return_to_addTime"
          name="afterSaving"
          checked={afterSavingValue === "return_to_addTime" ? true : false}
          autocomplete="off"
          data-testid = "afterSavingRadio2"
        />{" "}
        <div className="row-radio-element">
          {" "}{jsUtils.getLiteralValue("RETURN_TO_ADD_TIME")}
        </div>
        <input
          onChange={() => onRadiobuttonSelection("show_manageTime")}
          type="radio"
          value="show_manageTime"
          name="afterSaving"
          checked={afterSavingValue === "show_manageTime" ? true : false}
          autocomplete="off"
          data-testid = "afterSavingRadio3"
        />{" "}
        <div className="row-radio-element">
          {" "}{jsUtils.getLiteralValue("SHOW_MANAGE_TIME")}
        </div>
      </div>
    </div>
  );
};

export default AfterSavingRadioButtons;
