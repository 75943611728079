import { SET_UNSAVED_CHANGES, SET_ROUTE, SET_SHOW_CONFIRMATION } from "./ActionType";
export var setUnsavedChanges = function setUnsavedChanges(isUnsavedChange) {
  return {
    type: SET_UNSAVED_CHANGES,
    data: isUnsavedChange
  };
};
export var setRoute = function setRoute(route) {
  return {
    type: SET_ROUTE,
    data: route
  };
};
export var setShowConfirmation = function setShowConfirmation(showConfirmation) {
  return {
    type: SET_SHOW_CONFIRMATION,
    data: showConfirmation
  };
};