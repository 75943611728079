import React from "react";
import jsUtils from "../../utils/jsUtils";
import "./ManageTime.scss";
export default function UserDetailsOnCard({cardItem,createdDate,createdTime,createdName,upOnDate,upTime,upName}){
    return <div>
    <div className="user-details">
                  <div className="user-update-on">
                      
                      <div className="card-update">
                       {jsUtils.getLiteralValue("CREATED_ON")}{" "}
                        <span className="user-card-dates">
                        {cardItem.createdDate ?<span>{createdDate} {createdTime} </span>:<></>}<span className="user-by"> {jsUtils.getLiteralValue("BY")} </span>{ cardItem.createdBy?<span>{createdName}</span>:<></>}
                        </span>
                        </div>
                      
                        <div className="card-update">
                          {jsUtils.getLiteralValue("UPDATE_ON")}{" "}
                          <span className="user-card-dates">
         
                         {cardItem.updatedDate ? <span>{upOnDate} {upTime} </span>:<></>}<span className="user-by"> {jsUtils.getLiteralValue("BY")} </span>{cardItem.updatedBy?<span>{upName}</span>:<></>}
                          </span>
                        </div>
                  </div>
                </div>
  </div>
}