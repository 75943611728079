import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";
import jsUtils from "../../../../../../utils/jsUtils";
import "../RestrictedWords/RestrictedWordsGrid.scss"
export default function CharacterLengthMiddleBodyControl({type,onTypeChange,onStartDateChange,onEndDateChange,startDate,endDate,limitValue,onLimitValueChange,mode,startDateDisable , minDate}){

    return <div className="af-popup-codetypes rw-middle-section">
                
                <div className="af-btn-sect" style={{ paddingTop: '0px',width:"91%",display:"block"}}>
                    <label htmlFor="icon">{jsUtils.getLiteralValue("Limit (1-5000)")} <span className="af-req-star">*</span></label>
                        <span className="p-float-label-nested">
                            <InputText value={limitValue}
                                style={{width: "100%",borderRadius: "10px"}}
                                type={"number"}
                                name={"points"}
                                step={1}
                                min={1}
                                onChange={(e)=>{
                                    onLimitValueChange(e.target.value)
                                }}
                                />
                        </span>
                 </div>

                <div className="af-btn-sect" style={{ paddingTop: '0px',width:"91%"}}>
                    <label htmlFor="icon">{jsUtils.getLiteralValue("TYPE")} <span className="af-req-star">*</span></label>
                        <span className="p-float-label-nested">
                            <Dropdown value={type}
                                options={[{state:"Warning"},{state:"Error"}]} 
                                onChange={(e)=>{onTypeChange(e.value)}}
                                optionLabel="state" 
                                placeholder="" />
                        </span>
                 </div>  

                <div className="af-btn-sect" style={{ paddingTop: '0px',width:"91%",display:"flex"}}>
                    <div>
                        <label htmlFor="icon">{jsUtils.getLiteralValue("START_DATE")} <span className="af-req-star">*</span></label>
                            <span className="p-float-label-nested calendarRw">
                                <Calendar
                                    showIcon={true} 
                                    value={startDate}
                                    disabled={startDateDisable}
                                    onChange={(e)=>{ onStartDateChange(e.value)}}
                                    minDate={new Date(minDate)}
                                    maxDate={endDate !==null ?new Date(jsUtils.getDatePlusMinus(endDate,-1)):null}
                                    onClearButtonClick={(e)=>{onStartDateChange("")}}
                                    tooltip={jsUtils.getLiteralValue("SELECT_DATE")}
                                    tooltipOptions={{
                                    className: "white-tooltip",
                                    position: "top",
                                    showDelay: 700,
                                    }}
                                    placeholder={jsUtils.getLiteralValue("MM_DD_YYYY")}
                                    //className="role-start-date"
                                    id="icon1"
                                    appendTo={document.body}
                                    readOnlyInput
                                    //disabled={}
                                    showButtonBar
                                    yearNavigator
                                    yearRange="1970:2100"
                                    />
                            </span>
                    </div>
                    <div>
                        <label htmlFor="icon">{jsUtils.getLiteralValue("END_DATE")}</label>
                            <span className="p-float-label-nested calendarRw">
                                <Calendar
                                    showIcon={true} 
                                    value={endDate}
                                    onChange={(e)=>{onEndDateChange(e.value)}}
                                    minDate={new Date(jsUtils.getMinDate(minDate,startDate,mode))}
                                    disabled={!((mode!=="edit") ||  !endDate || ((new Date(endDate)).setHours(0,0,0,0) >= (new Date()).setHours(0,0,0,0)))}
                                     // maxDate={enDate}
                                    tooltip={jsUtils.getLiteralValue("SELECT_DATE")}
                                    tooltipOptions={{
                                        className: "white-tooltip",
                                        position: "top",
                                        showDelay: 700,
                                        }}
                                        placeholder={jsUtils.getLiteralValue("MM_DD_YYYY")}
                                        //className="role-start-date"
                                        id="icon1"
                                        appendTo={document.body}
                                        readOnlyInput
                                        //disabled={}
                                        showButtonBar
                                        yearNavigator
                                        yearRange="1970:2100"
                                    />
                            </span>
                    </div>
                </div>

            </div>
}