import ActionStore from "./ActionStore";

class RoleAccessPermissions extends ActionStore {

  manageTimeState = [];
  state = [
    {
      "id": "",
      "fields": [
        {
          "id": "",
          "display": false,
          "actionable": false
        }

      ],
      "display": true
    }]

  get clientMaterTablePermissions() {
    return this.state.find(d => d.id === "clientmattersgrid")?.fields;
  }

  get manageTimePermissions() {
    return this.state.find(d => d.id === "managetimegrid")?.fields;

  }

  get autotextPermissions() {
    return this.state.find(d => d.id === "autotextgrid")?.fields;

  }

  get firmautotextPermissions() {
    return this.state.find(d => d.id === "firmautotextgrid")?.fields;

  }



  get rolesPermissions() {
    return this.state.find(d => d.id === "rolesgrid")?.fields;

  }

  get usersPermissions() {
    return this.state.find(d => d.id === "usergird")?.fields;

  }

  get preferencesPermissions() {
    return this.state.find(d => d.id === "practice")?.fields;

  }
  setState = (value) => {
    this.state = value;
  }
}

export default new RoleAccessPermissions();