import React, { useEffect, useState } from "react";
import "../../../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import "./RolesTable.scss";
import { userApi } from "../../../../services/userapis";
import Spinner from "../../../common/Spinner/Spinner";
import moment from "moment";
import storage  from "./../../../../utils/storage";
import { refreshStoreOnError } from "../../../../actions/AppAction";
import { useDispatch } from "react-redux";

export default function RolesCard(props) {
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateOn, setUpdateOn] = useState("");
  const [createdOn, setCreatedOn] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    loadRolesCard(props.id);
  }, [props.id]);

  /** set tooltips from storage on screen load
   * @param literals -variale to store the array of literals
   */
  useEffect(()=>{
    let literals  = storage.getObject("literals");
    if(literals)
    {
      setCodeFromAPI(literals);
    }
        
  },[]);

  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };

  /** 
  * @description loads roles data in card
  * @param {Number} persona_id - get Card data based on pesona ID
  */
  const loadRolesCard = (persona_id) => {
    setLoading(true);
    props.onLoadingCard(true)
    userApi
      .roleCardExpand(persona_id)
      .then((res) => {
        props.onLoadingCard(false)

        if (res && res.persona_details) {
          setRoleData(res.persona_details[0]);

          if(isValidDate(res.persona_details[0].updated_date)){
            let upDate = res.persona_details[0].updated_date.substring(0, 10).split("-");
            let upOnDate = upDate[1] + "/" + upDate[2] + "/" + upDate[0];

            let upTime = res.persona_details[0].updated_date.substring(11, 19);
            setUpdateOn(upOnDate+" "+upTime);
          }
          else{
            setUpdateOn("");
          }
          if(isValidDate(res.persona_details[0].created_date)){
            let createDate = res.persona_details[0].created_date.substring(0, 10).split("-");
            let createdDate = createDate[1] + "/" + createDate[2] + "/" + createDate[0];

            let createdTime = res.persona_details[0].created_date.substring(11, 19);
            setCreatedOn(createdDate+" "+createdTime);
          }
          else{
            setCreatedOn("");
          }
        }
      })
      .catch((error) => {
        props.onLoadingCard(false)

         dispatch(refreshStoreOnError({ refresh: new Date() }));

      })
      .finally(() => {
        setLoading(false);
      });
  };
  
   /**
   * @description - Check if date is valid (like not empty/undefined/null)
   * @param {Object} date - date for validation
   */

  const isValidDate = (date) => {
    let status = moment(
      moment(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      "DD/MM/YYYY",
      true
    ).isValid();
    return status;
  };

  return (
    <>
      {loading ? (
        <div className="usercard-spinner">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="roles-card">
            <div>
                <h4>{getCode("SCREENS")}</h4>
              <table className="rolesCard-table">
                <thead>
                  <tr>
                    <th>{getCode("SUITE")}</th>
                    <th>{getCode("MODULE")}</th>
                    <th>{getCode("FEATURE")}</th>
                    <th>{getCode("ACCESS")}</th>
                    <th>{getCode("FROM")}</th>
                    <th>{getCode("TO")}</th>
                  </tr>
                </thead>
                <tbody>
                  {roleData &&
                    roleData.rules &&
                    roleData.rules.map((data, key) => {
                      let fromtDate = 
                      data.FromDate ? moment(`${data.FromDate.slice(0, 10)}`).format("MM/DD/YYYY"): 
                      moment(
                        data.FromDate,
                        "YYYY-MM-DD"
                      ).format("MM/DD/YYYY");

                      let toDate = 
                      data.ToDate ? moment(`${data.ToDate.slice(0, 10)}`).format("MM/DD/YYYY"):
                      moment(data.ToDate, "YYYY-MM-DD").format(
                        "MM/DD/YYYY"
                      );
                      let fromtDateStatus = isValidDate(data.FromDate);
                      let toDateStatus = isValidDate(data.ToDate);

                      return (
                        <tr key={key}>
                          <td>{data.Suite}</td>
                          <td>{data.Module}</td>
                          <td>{data.Feature}</td>
                          <td>{data.Access === "FULL" || data.Access === "Full" ? "Full": "Inquiry"}</td>
                          <td>{fromtDateStatus && fromtDate}</td>
                          <td>{toDateStatus && toDate}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {roleData && roleData.dashboards && roleData.dashboards[0] &&
                  <>
                    <br />
                    <h4>{getCode("DASHBOARDS")}</h4>
                    <table className="rolesCard-table">
                      <thead>
                        <tr>
                          <th>{getCode("MODULE")}</th>
                          <th>{getCode("FROM")}</th>
                          <th>{getCode("TO")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleData && roleData.dashboards.map((data, key) => {
                          let fromtDate =
                            data.effective_start_date ? moment(`${data.effective_start_date.slice(0, 10)}`).format("MM/DD/YYYY") :
                              moment(
                                data.effective_start_date,
                                "YYYY-MM-DD"
                              ).format("MM/DD/YYYY");

                          let toDate =
                            data.effective_end_date ? moment(`${data.effective_end_date.slice(0, 10)}`).format("MM/DD/YYYY") :
                              moment(data.effective_end_date, "YYYY-MM-DD").format(
                                "MM/DD/YYYY"
                              );
                          let fromtDateStatus = isValidDate(data.effective_start_date);
                          let toDateStatus = isValidDate(data.effective_end_date);

                          return (
                            <tr key={key}>
                              <td>{data.module_name_key}</td>
                              <td>{fromtDateStatus && fromtDate}</td>
                              <td>{toDateStatus && toDate}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                }
            </div>
            <div className="user-details">
                      <div className="user-update-on">
                        <div className="update-row">
                        {getCode("CREATED_ON")}{" "}
                            <span className="user-card-dates">
                            {roleData && roleData.created_date ?<span>{createdOn} </span>:<></>}<span className="user-by"> {getCode("BY")} </span>{ roleData.created_by?<span>{roleData.created_by}</span>:<></>}
                            </span>
                            </div> 
                           <div className="update-row">
                              {getCode("UPDATE_ON")}{" "}
                              <span className="user-card-dates">
             
                              {roleData && roleData.updated_date ?<span>{updateOn} </span>:<></>}<span className="user-by"> {getCode("BY")} </span>{ roleData.updated_by?<span>{roleData.updated_by}</span>:<></>}
                              </span>
                            </div>   
                            </div>
                    </div>
          </div>
        </>
      )}
    </>
  );
}
