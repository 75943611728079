import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRestrictedWord } from "../../../actions/AppAction";
import { Tooltip } from "primereact/tooltip";

const initialStyle = {
  height: "140px",
  width: "280px",
  position: "absolute",    
  top: "180px",
  left: "1120px",
  zIndex: 50,
};

export const ToolTipWrapper = (props) => {

    const dispatch = useDispatch();
    const { highlightRestrictedWords } = useSelector(({ TimeManagementReducer }) => ({ highlightRestrictedWords: TimeManagementReducer.highlightRestrictedWords}));
  
    
    const onClick = (e) => {
      let wordEntity = highlightRestrictedWords && highlightRestrictedWords.find((obj) => obj.restrictedWord && props.data.text && obj.restrictedWord.trim().toUpperCase() === props.data.text.trim().toUpperCase());
      if (wordEntity) {
        wordEntity.beginIndex = props && props.data && props.data.beginIndex;
        wordEntity.endIndex = props && props.data && props.data.endIndex;
        wordEntity.restrictedWord = props.data.text;
      
        let newStyle;
     
        let top =
        e.target.offsetParent.offsetParent.offsetHeight - e.target.offsetTop < 140
        ? e.target.offsetParent.offsetParent.offsetHeight - 140
        : e.target.offsetTop + 20;
        let left =
          e.target.offsetParent.offsetParent.offsetWidth - e.target.offsetLeft < 280
            ? e.target.offsetParent.offsetParent.offsetWidth - 280
            : e.target.offsetLeft + 20;
        
          newStyle = { ...initialStyle, top: `${top}px`, left: `${left}px` };
          wordEntity = {...wordEntity, showPopup: true, replaceTo: wordEntity.suggestedReplacement, style: newStyle};
      
       //let fieldId = document.getElementsByClassName(containerDivClass)[0].id;
       let fieldId = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id || 
       e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id;
          wordEntity.fieldId = fieldId;
        dispatch(setSelectedRestrictedWord(wordEntity)); 
      }
    };

    const getErrorMessage = (word) => {
      let wordEntity = highlightRestrictedWords && highlightRestrictedWords.find((obj) => obj.restrictedWord && word && obj.restrictedWord.trim().toUpperCase() === word.trim().toUpperCase());
      return wordEntity && wordEntity.errorMessage || "";
    };

    const overlayStyle = {
      zIndex: 1,
      cursor: "pointer",
      color: "black"
    };
   
    let rWordId = props && props.data && props.data.text.replace(/[^a-zA-Z0-9]/g, '');
    return (
      <span>
        <Tooltip
          target={`#id_${rWordId}`}
          position="top"
          autoHide={false}
          content={getErrorMessage(props && props.data && props.data.text)}
        />
        <props.MarkView id={`id_${rWordId}`} style={overlayStyle} onClick={onClick} />
      </span>
    );
  }
  /* 2.1.1 
  //   const onClick = (e) => {
  //     let wordEntity =
  //       highlightRestrictedWords &&
  //       highlightRestrictedWords.find(obj => obj.restrictedWord && props.decoratedText &&
  //           obj.restrictedWord.trim().toUpperCase() === props.decoratedText.trim().toUpperCase()
  //       );
  //     if (wordEntity) {     
  //       wordEntity.restrictedWord = props.decoratedText; 
  //       const initialStyle = {
  //         height: "140px",
  //         width: "280px",
  //         position: "absolute",    
  //         top: "180px",
  //         left: "1120px",
  //         zIndex: 50,
  //       };
  //       let newStyle;
  //        // let top =
  //         e.target.offsetParent.offsetParent.offsetHeight - e.target.offsetTop < 140
  //         ? e.target.offsetParent.offsetParent.offsetHeight - 140
  //         : e.target.offsetTop + 20;
          
  //         let textAreaTop = e.target.offsetParent.offsetTop;
  //         let rwSpanTop = e.target.offsetTop;
  //         const textAreaHeight = e.target.offsetParent.offsetHeight;
  
  //         if(rwSpanTop < 20)
  //           top = rwSpanTop + 20;
  //         else if(rwSpanTop < (textAreaHeight - 140) >= 20)
  //           top = rwSpanTop + 20;  
  //         else if(rwSpanTop > (textAreaHeight - 140))
  //           top = (rwSpanTop - textAreaTop) - 140;           
  //         else
  //           top = rwSpanTop;                
  //         newStyle = { ...initialStyle, top: `${top}px`, left: `${85}px`};
  //         wordEntity = {...wordEntity, showPopup: true, replaceTo: wordEntity.suggestedReplacement, style: newStyle};
  //         let fieldId = e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.id;
  //         wordEntity.fieldId = fieldId;
  //         dispatch(setSelectedRestrictedWord(wordEntity));        
  //     }
  //   };
  
  //   const getErrorMessage = (word) => {
  //     let wordEntity = highlightRestrictedWords && highlightRestrictedWords.find((obj) => obj.restrictedWord && word && 
  //         obj.restrictedWord.trim().toUpperCase() === word.trim().toUpperCase());
  //     return wordEntity && wordEntity.errorMessage || "";
  //   };
  //   if (getErrorMessage(props.decoratedText)) {
  //     return (
  //       <Tippy
  //         content={getErrorMessage(props.decoratedText)}
  //         arrow={true}
  //         placement="top">
  //         <mark
  //           className={props.className}
  //           style={{ zIndex: 1, cursor: "pointer", color: "black" }}
  //           onClick={onClick}>
  //           {props.children}
  //         </mark>
  //       </Tippy>
  //     );
  //   } else {
  //     return (
  //       <mark
  //         className={props.className}
  //         style={{ zIndex: 1, cursor: "pointer", color: "black" }}
  //         onClick={onClick}>
  //         {props.children}
  //       </mark>
  //     );
  //   }
   
  // }; */