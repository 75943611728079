import { Calendar } from "primereact/calendar";
import "./DayViewHeader.scss";
import DayViewcard from "./DayViewcard";
import React from "react";
import jsUtils from "../../utils/jsUtils";
import { useSelector } from "react-redux";
export default function DayViewHeader({ onDateChange, billableHours, nonBillableHours, amount, unreleasedHours, onCheckBillable,
    onCheckNonBillable, onCheckUnreleased, onCheckedState, showBillableAmount, currentDbDate, entriesInfo }) {

    const { checkedBillable, checkedNonBillable, checkedUnreleased, dateSelectedDayView } = useSelector(
        ({ DayViewReducer }) => ({
            checkedBillable: DayViewReducer.checkBillable,
            checkedNonBillable: DayViewReducer.checkNonBillable,
            checkedUnreleased: DayViewReducer.checkUnreleased,
            dateSelectedDayView: DayViewReducer.date
        })
    );

    const getFormattedNumber = (number) => {
        if (number || number === 0) {
            const numberFormatter = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
            const formattedNumber = numberFormatter.format(number);
            return formattedNumber;
        }
    }

    return <div className="parentWrapper">

        {showBillableAmount && <div className="p-col-2 amountField">
            <span className="cardText">{jsUtils.getLiteralValue("AMOUNT")}:</span><span className="valueText"> ${getFormattedNumber(amount || 0)}</span>
        </div>}

        <div className="p-col-7 centerCalcDiv" >
            <DayViewcard labelhtml={"billable"}
                onChange={() => {
                    checkedBillable && !checkedNonBillable ? onCheckedState(!checkedBillable, !checkedNonBillable, checkedUnreleased, dateSelectedDayView) : onCheckBillable(!checkedBillable);
                }}
                checked={checkedBillable}
                value={billableHours.toFixed(2)}
                label={jsUtils.getLiteralValue("BILLABLE_HOURS")} />

            <DayViewcard labelhtml={"nonbillable"}
                onChange={() => {
                    !checkedBillable && checkedNonBillable ? onCheckedState(!checkedBillable, !checkedNonBillable, checkedUnreleased, dateSelectedDayView) : onCheckNonBillable(!checkedNonBillable)
                }}
                checked={checkedNonBillable}
                value={nonBillableHours.toFixed(2)}
                label={jsUtils.getLiteralValue("NON_BILLABLE_HOURS")} />

            <DayViewcard labelhtml={"unreleased"}
                onChange={() => { onCheckUnreleased(!checkedUnreleased) }}
                checked={checkedUnreleased}
                value={unreleasedHours.toFixed(2)}
                label={jsUtils.getLiteralValue("UNRELEASED_TIME")} />
        </div>
        <div className="p-col-3 calendarRefresh" style={{ float: "right" }}>
            {<span style={{ paddingTop: '0.5rem', marginRight: '0.5rem' }}>{entriesInfo}</span>}
            <Calendar
                value={dateSelectedDayView}
                onChange={(e) => {
                    onDateChange(e.value);
                }}
                onClearButtonClick={() => {
                    let currentDate = new Date(currentDbDate);
                    onDateChange(currentDate);
                }}
                placeholder="mm/dd/yyyy"
                id="day_view_icon"
                showIcon
                appendTo={document.body}
                readOnlyInput
                showButtonBar
                //monthNavigator
                yearNavigator
                yearRange="1970:2060"
                style={{ width: "150px", marginRight: "1rem" }}

            />
        </div>
    </div>
}