import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import "../../../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import "./UserCard.scss";
import { InputSwitch } from "primereact/inputswitch";
import { userApi } from "../../../../services/userapis";
import Spinner from "../../../common/Spinner/Spinner";
import moment from "moment";
import storage  from "./../../../../utils/storage";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../actions/AppAction";

export default function UserCard(props) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateOn, setUpdateOn] = useState("");
  const [createdOn, setCreatedOn] = useState("");

  //Load user data for user card
  useEffect(() => {
    loadUserCard(props.userid);
  }, [props.userid]);

  /** 
  * @description loads all user data and roles date 
  * @param {Number} userid - user id to get the user data
  */
  const loadUserCard = (userid) => {
    setLoading(true);
    props.onLoadingCard(true)
    userApi
      .userCard(userid)
      .then((res) => {
        props.onLoadingCard(false)
        if (res && res.userData) {
          setCardData(res.userData);

          if(isValidDate(res.userData.user.updated_date)){
            let upDate = res.userData.user.updated_date.substring(0, 10).split("-");
            let upOnDate = upDate[1] + "/" + upDate[2] + "/" + upDate[0];

            let upTime = res.userData.user.updated_date.substring(11, 19);
            setUpdateOn(upOnDate+" "+upTime);
          }
          else{
            setUpdateOn("");
          }
          if(isValidDate(res.userData.user.created_date)){
            let createDate = res.userData.user.created_date.substring(0, 10).split("-");
            let createdDate = createDate[1] + "/" + createDate[2] + "/" + createDate[0];

            let createdTime = res.userData.user.created_date.substring(11, 19);
            setCreatedOn(createdDate+" "+createdTime);
          }
          else{
            setCreatedOn("");
          }
        }
      })
      .catch((err) => {
        props.onLoadingCard(false)
        dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode:err.responseCode}));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /** set tooltips from storage on screen load
   * @param literals -variale to store the array of literals
   */
  useEffect(()=>{
    let literals  = storage.getObject("literals");
    if(literals)
    {
      setCodeFromAPI(literals);
    }
    
    
  },[]);

  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const dispatch = useDispatch();
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };

  /**
   * @description - Check if date is valid (like not empty/undefine/null)
   * @param {Object} date - date for validation
   */
  const isValidDate = (date) => {
    let status = moment(
      moment(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      "DD/MM/YYYY",
      true
    ).isValid();
    return status;
  };

  return (
    <>
      {loading ? (
        <div className="usercard-spinner">
          <Spinner />
        </div>
      ) : (
        <>
          {" "}
          {cardData.user && (
            <div className="p-grid">
              <div className="p-sm-12 p-md-12 p-lg-8">
                <div className="user-card">
                  <div className="Card-col1">
                    <div className="uGrid-profileIcon">
                      <Avatar
                        size="100"
                        round={true}
                        className="uCard-Avatar"
                        name={cardData.user.user_profile_pic_s3_url ? null :
                          cardData.user.firstname + " " + cardData.user.lastname
                        }
                        src={cardData.user.user_profile_pic_s3_url}
                          background="#0050c3"
                      />
                    </div>
                  </div>
                  <div className="Card-col2">
                    <table className="usercard-table">
                      <thead>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{getCode("TIMEKEEPER")}</td>
                          <td>
                            <div className="user-card-radio">
                              <InputSwitch
                                checked={
                                  cardData.user.is_timekeeper ? true : false
                                }
                                disabled={
                                  cardData.user.is_timekeeper ? true : false
                                }
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>{getCode("START_DATE")}</td>
                          <td>
                            {isValidDate(cardData.user.effective_start_date)?
                               moment(`${cardData.user.effective_start_date.slice(0, 10)}`).format("MM/DD/YYYY")
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>{getCode("END_DATE")}</td>
                          <td>
                            {isValidDate(cardData.user.effective_end_date)? 
                              moment(`${cardData.user.effective_end_date.slice(0, 10)}`).format("MM/DD/YYYY")
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="Card-col3">
                    <table className="usercard-table">
                      <thead>
                        <tr>
                          <th>{getCode("ROLES")}</th>
                          <th>{getCode("FROM")}</th>
                          <th>{getCode("TO")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cardData.roles &&
                          cardData.roles
                          // .sort(function (a, b) {
                          //   if (a.Role === b.Role) {
                          //       return 0;
                          //   }
                          //   if (typeof a.Role === typeof b.Role) {
                          //       return a.Role < b.Role ? -1 : 1;
                          //   }
                          //   return typeof a.Role < typeof b.Role ? -1 : 1;
                          // })
                          // .sort(function (a, b) {
                          //   return new Date(moment(b.From, "YYYY-MM-DD").format("YYYY-MM-DD")) - new Date(moment(a.From, "YYYY-MM-DD").format("YYYY-MM-DD"));
                          // })
                          .map((data, key) => {
                            return (
                              <tr key={key}>
                                <td>{data.Role}</td>
                                <td className="user-card-dates">
                                  {isValidDate(data.From)? 
                                    moment(`${data.From.slice(0, 10)}`).format("MM/DD/YYYY")
                                    : ""}
                                </td>
                                <td className="user-card-dates">
                                  {isValidDate(data.To)? 
                                    moment(`${data.To.slice(0, 10)}`).format("MM/DD/YYYY")
                                    : ""}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="p-sm-12 p-md-12 p-lg-4">
                <div className="Card-col4">
                  <div className="user-card-heading">{getCode("NOTES")}</div>
                  {cardData.user.notes && (
                    <div className="user-card-notes">{cardData.user.notes}</div>
                  )}

                    <div className="user-details">
                      <div className="user-update-on">
                        <div className="update-row">
                        {getCode("CREATED_ON")}{" "}
                            <span className="user-card-dates">
                            {cardData.user.created_date ?<span>{createdOn} </span>:<></>}<span className="user-by"> {getCode("BY")} </span>{ cardData.user.created_by?<span>{cardData.user.created_by}</span>:<></>}
                            </span>
                            </div> 
                           <div className="update-row">
                              {getCode("UPDATE_ON")}{" "}
                              <span className="user-card-dates">
             
                              {cardData.user.updated_date ?<span>{updateOn} </span>:<></>}<span className="user-by"> {getCode("BY")} </span>{ cardData.user.updated_by?<span>{cardData.user.updated_by}</span>:<></>}
                              </span>
                            </div>   
                            </div>
                    </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
