import React from "react";
const Row=React.memo(({rowItem,colDef,rowExpansionId,loadBodyElement,rowClicked,internalRowClicked,enableRowExpansion,rowExpansionElement,index})=>{

    return <React.Fragment>
    <tr key={index}
        className=""
        // key={rowItem.rowId}
        onClick={() => {
            rowClicked && rowClicked(rowItem);
            internalRowClicked && internalRowClicked(rowItem);
        }} data-testid="tableRow">
        {Array.isArray(colDef) && colDef.map((col, cIndex) => {
            const { data: _data, column } = col;
            return <td                                            
                style={column.style}
                key={cIndex}>
                {loadBodyElement(rowItem, _data )}

            </td>
        })}
        </tr>
    { enableRowExpansion && rowExpansionId[rowItem.rowId] && (
    <>
        <tr key={index + "s"}></tr>
        <tr key={index}>
                <td colSpan={colDef.length}>
            {rowExpansionElement && rowExpansionElement(rowItem)}
        </td>
        </tr>
    </>
    )}
</React.Fragment>
});
export default Row;