import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import "./Delegates.scss";
import React, { useState, useEffect, useRef } from "react";
import { DelegatesColDef } from "./DelegatesColDef";
import { buildQueryString } from "./DelegatesColDef";
import { delegates } from "../../../services/DelegatesApi";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import jsUtils from "../../../utils/jsUtils";
import moment from "moment";
import { setSelectedTimeKeeperFeature } from "../../../actions/TimeManagementAction";
import {
  HELP_LINKS,
  TOOLTIPS_KEYS,
  TOOLTIPS_VALUES,
} from "../../../config/vars";
import storage from "../../../utils/storage";
import { setErrorMessage } from "../../../actions/AppAction";
import { helpPageUrl } from "../../../config";
import Spinner from "../../common/Spinner/Spinner";
import { refreshStoreOnError } from "../../../actions/AppAction";
import {setUnsavedChanges } from "../../../actions/ConfirmationAction";
import { NO_OF_ROWS_IN_GRID } from "../../../config/messages";
import CustomTable from "../../common/DataTable/CustomTable";
import { setBreadCrumb, setPageHelpLink } from "../../../actions/LandingPageAction";
import _ from "lodash";
import { HeaderView } from "../../../config/literalCodes";
export const textBoxValuesWhileEditing = {
  //
};
export const textBoxValueEdited = (value, propName, rowId) => {
  if (!textBoxValuesWhileEditing[rowId]) {
    textBoxValuesWhileEditing[rowId] = {};
  }
  textBoxValuesWhileEditing[rowId][propName] = value;
};

export default function Delegates(props) {

  /** set tooltips from storage on screen load
   *  literals -variale to store the array of literals
   */
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const dispatch = useDispatch();
  const [DBCurrentDate, setDBCurrentDate] = useState("");

  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };


  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP],
      });
      setCodeFromAPI(literals);
    } 
    
  }, []);

  const delegateId = useSelector(
    (state) => state.TimeManagementReducer.delegateId
  );
  const attorneyCode = useSelector((state) => {
    if (
      state.TimeManagementReducer.timeKeeperList[0] &&
      state.TimeManagementReducer.timeKeeperList[0].attorneyCode
    ) {
      return state.TimeManagementReducer.timeKeeperList[0].attorneyCode;
    }
  });

  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);

  useEffect(()=>{
    document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_DELEGATES");
    const currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setDBCurrentDate(currentDate);
  },[])


  const [colDef, setColDef] = useState(DelegatesColDef);
  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [moveScrollToTop, setMoveScrollToTop] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [signingOut] = useState(false);
  const [firstCallView,setFirstCallView]=useState(true);
  const [scrollNoMore, setScrollNoMore] = useState(false);
  const [DataFromAPI, setDataFromAPI] = useState([]);
  const [DataFromAPIBackup, setDataFromAPIBackup] = useState([]);
  const itemdata = useSelector((state) => state.LandingPageReducer.items);
  const [feature, setFeature] = useState({
    id: "",
    featureName: "",
    featureKey: "",
  });
  const [delegate, setDelegate] = useState({
    id: "",
    firstName: "",
    LastName: "",
    email: "",
  });
  const [featureList, setFeatureList] = useState([]);
  const [delegateList, setDelegateList] = useState([]);
  const [newDelegatesTempRowEnable, setNewDelegatesTempRowEnable] = useState(
    true
  );
  const loadingClient = useSelector(
    (state) => state.TimeManagementReducer.loading
  );

  const [, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP],
  });

  const [delegateDropdown, setDelegateDropDown] = useState(0);
  const [countEditOrNewRow, setCountEditOrNewRow] = useState(0);

  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [isResetButtonActive, setIsResetButtonActive] = useState(true);

  /**
   * update the work on behalf of dropdown list with the delegates those have delegation for that particular feature
   */

  useEffect(()=>{
    let moduleGroup=(itemdata && itemdata[0]?.landingPageData?.moduleGroups?.find((groupObj)=>groupObj.moduleGroupName === "MG-Account")) || [];
    let moduleObject=moduleGroup?.modules?.find((moduleObj)=>moduleObj.moduleName === "MG-Account-MD-Delegates") || [];
    let featureNameList=[];
    let featureIdList=[];

    let moduleFeatures = moduleObject && moduleObject.menus && moduleObject.menus[0]?.features;
    if(moduleFeatures) {
      moduleFeatures.forEach((featureItem)=>{
      featureNameList.push(featureItem.featureName);
      featureIdList.push(featureItem.featureId)
      })
    }
    dispatch(setSelectedTimeKeeperFeature(
      featureNameList,
      featureIdList
    ))
    
  },[itemdata])

  useEffect(() => {
    if (feature.id != "") {
      updateACellOrProp(DataFromAPI[0].rowId, "delegateUserName", "")
      setDelegateDropDown(delegateDropdown + 1)
    }
  }, [feature])

  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });

  let setScrollToTop = () => {
    setMoveScrollToTop(true);
  }
  /** set filters on the grid
  * colDef is the column definition for the datatable
   */

   useEffect(() => {
   dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.DELEGATES}`))
   dispatch(setBreadCrumb(["MG-Account","MG-Account-MD-Delegates"]))
  }, [])

  let setFilters = (key, value, index) => {
    let state = [...colDef];
    state[index].filter.val = value;
    setColDef([...state]);
  };

  /*
  * removes all the filters from grid and adds default values
  */
  let resetFilters = () => {
    let state = [...colDef];
    state[0].filter.val = "";
    state[1].filter.val = "";
    state[2].filter.val = "";
    state[3].filter.val = "";
    sort.index = -1;
    setSort({ index: -1, isAsc: true, propName: "" });
    setColDef([...state]);
    setNewDelegatesTempRowEnable(true);
    loadData(true);
    setScrollToTop();
    setIsResetButtonActive(true);
  };

  let clearFilterCache = () => {
    let state = [...colDef];
    state[0].filter.val = "";
    state[1].filter.val = "";
    state[2].filter.val = "";
    state[3].filter.val = "";
    sort.index = -1;
    setSort({ index: -1, isAsc: true, propName: "" });
    setColDef([...state]);
    setNewDelegatesTempRowEnable(true);
    setScrollToTop();
  };

  useEffect(() => {
    return () => {
      clearFilterCache();
    };
  }, []);


  /*
  * On component load we call the delegates api to load the grid
  */
  useEffect(() => {
    // if (delegateId !== "" && delegateId !== null) {
    //   loadData(true);
    // }
    loadData(true);
  }, []);

  const toast = useRef(null);

  DelegatesColDef[DelegatesColDef.length - 1].filter.onClick = () => {
      resetFilters();
    setIsResetButtonActive(true);
  };

  DelegatesColDef[DelegatesColDef.length - 1].column.onClick = () => {

    if (newDelegatesTempRowEnable) {
      let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
      const newRow = {
        rowId: jsUtils.getRandomNumber(),
        featureId: "",
        featureName: "",
        delegeteUserId: "",
        delegateUserName: "",
        featureKeyToName: "",
        userName: "",
        startDate: moment(new Date(currentDate).toISOString()).format(
          "MM/DD/YYYY"
        ),
        endDate: "",
        originalStartDate: moment(new Date(currentDate).toISOString()).format(
          "MM/DD/YYYY"
        ),
        originalEndDate:"",
        sys_delegate_id: "",
        editAll: true,
        editStartDate:true,
        editMode: 1,
        tempRow: true,
      };
      addRow(newRow);
      setNewDelegatesTempRowEnable(false);
    }
  };

  const loadData = async (reset = true, sortName="", sortby="") => {

    setLoading(true);
    setDataLoaded(true);
    let page = 1;
    // build query string
    if (reset) {
     if(DataFromAPI && DataFromAPI[0] && DataFromAPI[0].editMode && DataFromAPI[0].tempRow)
     { 
       setNewDelegatesTempRowEnable(false)
     }
     else{
      setNewDelegatesTempRowEnable(true);
     }
      setPageNumber(1);
    } else {
      // page = pageNumber + 1;
      // setPageNumber(pageNumber + 1);
    }

    let searchParams = buildQueryString(page,sortName,sortby);

    delegates
     .get(searchParams, firstCallView ? HeaderView.DelegatesViewApiCallHeaderName : null, sortName, sortby, reset ? 1 : pageNumber)
      .then((data) => {
        setLoadingError(false);
        setDataLoaded(true);
        data.forEach((item) => {
          item["featureKeyToName"] = item.featureValue;
          item.startDate = 
          item.startDate !== null &&
          item.startDate !== "" &&
          item.startDate !== "0000-00-00 00:00:00"
          ? moment(`${item.startDate.slice(0, 10)}`).format("MM/DD/YYYY")
          // ? moment(new Date(item.endDate).toISOString()).format(
          //     "MM/DD/YYYY"
          //   )
          : "";
          // moment(
          //   new Date(item.startDate).toISOString()
          // ).format("MM/DD/YYYY");
          item.endDate =
            item.endDate !== null &&
              item.endDate !== "" &&
              item.endDate !== "0000-00-00 00:00:00"
              ? moment(`${item.endDate.slice(0, 10)}`).format("MM/DD/YYYY")
              // ? moment(new Date(item.endDate).toISOString()).format(
              //     "MM/DD/YYYY"
              //   )
              : "";
          item["originalStartDate"]=item.startDate !== null && item.startDate !== "" && item.startDate !== "0000-00-00 00:00:00" ? moment(`${item.startDate.slice(0, 10)}`).format("MM/DD/YYYY")
          // ? moment(new Date(item.endDate).toISOString()).format(
          //     "MM/DD/YYYY"
          //   )
              : "";
          item["originalEndDate"]=item.endDate !== null && item.endDate !== "" && item.endDate !== "0000-00-00 00:00:00" ? moment(`${item.endDate.slice(0, 10)}`).format("MM/DD/YYYY")
          // ? moment(new Date(item.endDate).toISOString()).format(
          //     "MM/DD/YYYY"
          //   )
          : "";

        });

        /**
         * if reset is true reset the data in the state with the response data as the api call is for 1st page of list
         * else if reset is false that means the res is from lazy loading and is from 2nd page or higher.So append the 
         * data to existing state
        */
        if (reset) {
          let captureTemporaryEditedData = (DataFromAPI && DataFromAPI[0] && DataFromAPI[0].editMode && DataFromAPI[0].tempRow)?[DataFromAPI[0]]:[]
          setDataFromAPI(JSON.parse(JSON.stringify([...captureTemporaryEditedData,...data])));
          setDataFromAPIBackup(JSON.parse(JSON.stringify([...captureTemporaryEditedData,...data])));
          // sortData(JSON.parse(JSON.stringify(formattedJson.data)));
          if (data.length === 0) {
            setNoItemsFoundMessage(true);
            setNoItemsFoundMessageText({
              // titleText: getCode("NOT_FOUND"),
              titleText: "Delegates not found",
              buttonText: null,
            });
            //  setScrollNoMore(true)
          }
          else {
            page = 1
            if (data.length < (page * NO_OF_ROWS_IN_GRID)) {
              setScrollNoMore(true);
              //setPageNumber(pageNumber)
            } else {

              setPageNumber(page + 1);
              setScrollNoMore(false);
              // setReset(false);
            }

          }
          //setScrollNoMore(false);
        } else {
          setDataFromAPI(JSON.parse(JSON.stringify([...DataFromAPI, ...data])));
          setDataFromAPIBackup(
            JSON.parse(JSON.stringify([...DataFromAPIBackup, ...data]))
          );
          // sortData(JSON.parse(JSON.stringify([...DataFromAPIBackup, ...formattedJson.data])));
          // data.length === 0 && setScrollNoMore(true);
          page = pageNumber
          if ((data.length + DataFromAPIBackup.length) < (pageNumber * NO_OF_ROWS_IN_GRID)) {
            setScrollNoMore(true)
          } else {
            setScrollNoMore(false);
            setPageNumber(page + 1);
          }
          // sortData([...DataFromAPI, ...data], sort)

        }
      })
      .catch((errorResponse) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoadingError(errorResponse || "Error");
      })
      .finally(() => {
        setLoading(false);
        setDataLoaded(true);
      });
      setFirstCallView(false);
  };

  /** 
   * sorts the columns of the grid individually
  */
   const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc? "asc":"desc";
      
      let sortName = "";
        switch(localSort.propName) {        
          case 'featureKeyToName':
            sortName = "feature"
            break;
          case 'delegateUserName':
            sortName = "delegate"
            break;
          case 'startDate':
            sortName = "effective_start_date"
            break;
          case 'endDate':
            sortName = "effective_end_date"
            break;
          default : 
            sortName = ""
        }

      setSortByField(sortBy);
      setSortNameField(sortName);

      loadData(true, sortName,sortBy)
    }
  };

  /**
   * autotext api is called with the selected filters as query params
   * @param value - searched text or toggle value
   * @param index -index of the filter column 
   */

  const onFilterChange = (key, value, index) => {
    setPageNumber(1)
    setFilters(key, value, index);
    setScrollNoMore(false);
    jsUtils.debounce(loadData, 400, true, sortNameField, sortByField);
    setScrollToTop();
    let resetSortParams = {
      index: -1,
      isAsc: true,
      propName: "",
    }
    const defaultValue = ["","","",""];
    let filterStatus = jsUtils.checkIfCustomTableFilterChanged(colDef,defaultValue)
    let sortStatus =  _.isEqual(sort, resetSortParams);
    setIsResetButtonActive(filterStatus && sortStatus);
  };

  /**
  * it updates the cell
  * @param propName - column name
  * @param newData - edited or updated data 
  */
  const updateACellOrProp = (rowId, propName, newData) => {

    jsUtils.updateACellOrProp(
      rowId,
      propName,
      newData,
      DataFromAPI,
      setDataFromAPI
    );
  };

  /**
   * add a new empty row
   * @param rowToAdd - new empty row 
   */

  const addRow = (rowToAdd) => {
    if (!loading) {
      setCountEditOrNewRow(countEditOrNewRow + 1);
      dispatch(setUnsavedChanges(true))
      jsUtils.addRow(
        rowToAdd,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        setDataFromAPIBackup
      );
    }
  };


  /**
   * make a row edittable/non editable
   * @param rowId - current rowId of the edited row
   * @param editModeValue - value that has 1/0 where 1 is for editable and 0 for non-editable
   */

  const editMode = (rowId, editModeVal) => {
    let updatedRow = {
      ...DataFromAPI.find((element) => element.rowId === rowId),
    };
    let today = new Date(DBCurrentDate);
    let editStartDate;
    today.setHours(0, 0, 0, 0);
    if(updatedRow.startDate && (new Date(jsUtils.getTimeZoneFormatDate(updatedRow.startDate)) > today))
    {
      editStartDate= true
    }
    
    
    if (
      updatedRow.endDate === null ||
      updatedRow.endDate === "" ||
      new Date(jsUtils.getTimeZoneFormatDate(updatedRow.endDate)) > today
    ) {
      setCountEditOrNewRow(countEditOrNewRow + 1);
      dispatch(setUnsavedChanges(true))
      jsUtils.editMode(
        rowId,
        editModeVal,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        setDataFromAPIBackup,
        editStartDate
      );
    } else {
      dispatch(
        setErrorMessage({
          severity: "error",
          message: getCode("DELEGATE_CANNOT_BE_CHANGED"),
          errorCode: 400,
        })
      );
    }
  };

  /**
  * remove the newRow or cancel the changes to the current row 
  * @param rowItem - contains the details of the selected row
  */

  const cancel = async (rowItem) => {

    if (rowItem.tempRow) {
      if ((countEditOrNewRow - 1) === 0) {
        dispatch(setUnsavedChanges(false))
      }
      setCountEditOrNewRow(countEditOrNewRow - 1);
      setFeatureList([]);
      setDelegateList([]);
      setFeature({
        id: "",
        featureName: "",
        featureKey: "",
      });
      setDelegate({ id: "", email: "", firstName: "", LastName: "", userName: "" })
      jsUtils.deleteRow(
        rowItem.rowId,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        setDataFromAPIBackup
      );
      setNewDelegatesTempRowEnable(true);
    } else {
      //
      editMode(rowItem.rowId, 0);
      let oldRow = DataFromAPIBackup.find(
        (element) => element.rowId === rowItem.rowId
      );
      oldRow.editMode = 0;
      oldRow.editStartDate=false;
      if ((countEditOrNewRow - 1) === 0) {
        dispatch(setUnsavedChanges(false))
      }
      setCountEditOrNewRow(countEditOrNewRow - 1);
      jsUtils.updateRowData(
        rowItem.rowId,
        oldRow,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        null
      );
    }
  };

  const getValidatedDate=(date)=>{
    let validatedDate= date !== null && date !== ""
            ? moment(new Date(date).toISOString()).format(
              "YYYY-MM-DD HH:mm:ss"
            )
            : "";
        return validatedDate;
  }
  /**
  * saves a delegation
  */

  const save = async (rowId) => {
    setLoading(true);
    let updatedRow = {
      ...DataFromAPI.find((element) => element.rowId === rowId),
    };
    // make the api call
    let errorUpdating = false;
    if (updatedRow.tempRow) {
      if (
        delegate.id !== "" &&
        feature.id !== "" &&
        updatedRow.startDate !== "" &&
        updatedRow.startDate !== null
      ) {
        let postData = {
          userId: delegate.id,
          featureId: feature.id,
          username: delegate.userName,
          featureKeyToName: getCode(feature.featureKey),
          featureName: feature.featureKey,
          featureKey: feature.featureKey,
          attorneyCode: attorneyCode,
          startDate:
            updatedRow.startDate !== null && updatedRow.startDate !== ""
              ? moment(new Date(updatedRow.startDate).toISOString()).format(
                "YYYY-MM-DD"
              )
              : "",
          endDate:
            updatedRow.endDate !== null && updatedRow.endDate !== ""
              ? moment(new Date(updatedRow.endDate).toISOString()).format(
                "YYYY-MM-DD"
              )
              : "",
        };
        await delegates
          .post(postData)
          .then((d) => {
            if ((countEditOrNewRow - 1) === 0) {
              dispatch(setUnsavedChanges(false))
            }
            setCountEditOrNewRow(countEditOrNewRow - 1);
            setLoading(false);
            if (d.success) {
              updatedRow.editAll = false;
              updatedRow.tempRow = false;
              updatedRow.delegeteUserId = delegate.id;
              updatedRow.featureId = feature.id;
              updatedRow.featureKeyToName = getCode(feature.featureKey);
              updatedRow.featureKey = feature.featureKey;
              updatedRow.sys_delegate_id = d.sys_delegate_id;
              updatedRow.originalStartDate = updatedRow.startDate;
              updatedRow.originalEndDate = updatedRow.endDate;
              setDelegate({ id: "", firstName: "", LastName: "", email: "" });
              setFeature({ id: "", featureName: "" });
              setNewDelegatesTempRowEnable(true);
              dispatch(
                setErrorMessage({
                  severity: "success",
                  message: d && getCode(d.message),
                  errorCode: d.responseCode,
                })
              );
            } else {
              errorUpdating = true;
              dispatch(
                setErrorMessage({
                  severity: "error",
                  message: d && getCode(d.message),
                  errorCode: d.responseCode,
                })
              );
            }
          })
          .catch((e) => {
            setLoading(false);
            // handleAPIErrors("Error saving the data", e);
            dispatch(
              setErrorMessage({
                severity: "error",
                message: e && getCode(e.message),
                errorCode: e.responseCode,
              })
            );
            errorUpdating = true;
          });
      } else {
        setLoading(false);
        errorUpdating = true;
      }
    } else {
      if (updatedRow.startDate !== "" &&
        updatedRow.startDate !== null) {
        let postData = {
          userId: updatedRow.delegeteUserId,
          featureId: updatedRow.featureId,
          featureName: updatedRow.featureName,
          featureKey: updatedRow.featureKey,
          attorneyCode: attorneyCode,
          sys_delegate_id: updatedRow.sys_delegate_id,
        };
        if(updatedRow.originalStartDate !== updatedRow.startDate){
          postData["startDate"]=getValidatedDate(updatedRow.startDate); 
        }
        if(updatedRow.originalEndDate !== updatedRow.endDate){
            postData["endDate"]=getValidatedDate(updatedRow.endDate);
        }
        
        await delegates
          .post(postData)
          .then((d) => {
            if ((countEditOrNewRow - 1) === 0) {
              dispatch(setUnsavedChanges(false))
            }
            setCountEditOrNewRow(countEditOrNewRow - 1);
            setLoading(false);
            if (d.success) {
              updatedRow.editAll = false;
              updatedRow.tempRow = false;
              updatedRow.originalStartDate = updatedRow.startDate;
              updatedRow.originalEndDate = updatedRow.endDate;
              dispatch(
                setErrorMessage({
                  severity: "success",
                  message: d && getCode(d.message),
                  errorCode: d.responseCode,
                })
              );
            } else {
              errorUpdating = true;
              dispatch(
                setErrorMessage({
                  severity: "error",
                  message: d && getCode(d.message),
                  errorCode: d.responseCode,
                })
              );
            }
          })
          .catch((err) => {

            setLoading(false);
            // handleAPIErrors("Error saving the data", e);
            dispatch(
              setErrorMessage({
                severity: "error",
                message: err && getCode(err.message),
                errorCode: err.responseCode,
              })
            );
            errorUpdating = true;
          });
      }
      else {
        setLoading(false);
        errorUpdating = true;
      }
    }

    if (!errorUpdating) {
      updatedRow.editMode = 0;
      updatedRow.editStartDate=false;

      //console.log(updatedRow);
      jsUtils.updateRowData(
        rowId,
        updatedRow,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        setDataFromAPIBackup
      );
    }
  };

  /**
  * calls the feature api to load a list of features with the searchText
  * @param searchtext - contains the details of the searched text
  */

  const loadFeatures = (searchText) => {
    if (searchText !== "") {
      return featureList.filter((res) => {
        return (
          res.featureKey &&
          jsUtils.getLiteralValue(res.featureKey).toLowerCase().includes(searchText.toLowerCase())
        );
      });
    } else {
      return delegates.featuresSelect().then((res) => {
        res.sort((a, b) => (jsUtils.getLiteralValue( a.featureKey) < jsUtils.getLiteralValue( b.featureKey) ? -1 : 1));
        setFeatureList(res);
        return res;
      }).catch((err) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
      });
    }
  };

  /**
  * calls the delegates api to load a list of delegates based on the feature selected and the searchText
  * @param searchtext - contains the details of the searched text
  */

  const loadDelegates = (searchText) => {
    if (searchText !== "") {
      return delegateList.filter((res) => {
        return (
          (res.email &&
            res.email.toLowerCase().includes(searchText.toLowerCase())) ||
          (res.firstName &&
            res.firstName.toLowerCase().includes(searchText.toLowerCase())) ||
          (res.lastName &&
            res.lastName.toLowerCase().includes(searchText.toLowerCase()))
        );
      });
    } else {
      return delegates.delegatesSelect(feature.id).then((res) => {
        setDelegateList(res);
        return res;
      }).catch((err) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
      });
    }
  };

  /**
   * updates the new row with the selected delegate from the list of delegates
   */
  const setSelectedDelegate = (delegateSelected) => {
    setDelegate({
      id: delegateSelected.userId,
      email: delegateSelected.email,
      firstName: delegateSelected.firstName,
      LastName: delegateSelected.lastName,
      userName: delegateSelected.username,
    });
  };

  /**
   * updates the new row with the selected feature from the list of features
   */
  const setSelectedFeature = (featureSelected, rowId) => {
    setFeature({
      id: featureSelected.featureId,
      featureName: featureSelected.featureName,
      featureKey: featureSelected.featureKey,
    });
    setDelegate({ id: "", email: "", firstName: "", LastName: "", userName: "" })

  };

  const ondisableScroll = (disableScroll) => {

    //setScrollNoMore(disableScroll)

  }
  if (signingOut) {
    return (
      <div className="spinner-popup">
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className='af-delegates-page'>
        <Toast ref={toast} />


        {loadingClient === false &&
          //  loadingTimeKeeper === false &&
         (
            <div className="p-tabview p-component">
              <div className="datatable-filter-demo">
                <div className="delegate-grid">
                  {delegateId && (
                    <CustomTable
                      rows={NO_OF_ROWS_IN_GRID}
                      colDef={colDef}
                      parentMethods={{
                        updateRowData: jsUtils.updateRowData,
                        addRow,
                        editMode,
                        updateACellOrProp,
                        save,
                        cancel,
                        loadFeatures,
                        loadDelegates,
                        setSelectedFeature,
                        setSelectedDelegate,
                        setFeatureList,
                        setDelegateList,
                        setDataFromAPI,
                        setDataFromAPIBackup,
                        ondisableScroll,
                        delegateDropdown,
                        DBCurrentDate
                      }}
                      data={DataFromAPI}
                      dataLoaded={dataLoaded}
                      isLoading={loading}
                      enableRowExpansion={false}
                      loadingError={loadingError}
                      noItemsFoundMessage={noItemsFoundMessage}
                      noItemsFoundMessageText={noItemsFoundMessageText}
                      onFilterChange={onFilterChange}
                      onLazyScroll={() => {
                        if (loading === false && !scrollNoMore) {
                          loadData(false, sortNameField, sortByField);
                        }
                      }}
                      onSort={(index, isAsc, propName) => {
                        //console.log("sort after parent", index, isAsc, propName);
                        setSort({ index, isAsc, propName });
                        sortData({ index, isAsc, propName });
                        setIsResetButtonActive(false);
                      }}
                      sort={sort}
                      pageNumber={pageNumber}
                      scrollNoMore={scrollNoMore}
                      moveScrollToTop={moveScrollToTop}
                      resetScrollFlag={() => setMoveScrollToTop(false)}
                      resetButtonActive={isResetButtonActive}
                    />
                  )}
                </div>
              </div>
            </div>
         )
         }
      </div>
    );
  }
}