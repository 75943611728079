import _typeof from "@babel/runtime/helpers/esm/typeof";
import Cookies from "universal-cookie";
import { domainUrl } from '../config';
var cookies = new Cookies();
var isJson = function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  if (_typeof(item) === "object" && item !== null) {
    return true;
  }
  return false;
};
var storage = {
  html5StorageEnabled: window.localStorage && window.sessionStorage,
  // html5StorageEnabled: false,
  appStoragePrefix: "_",
  set: function set(name, value, isForSession) {
    if (storage.html5StorageEnabled) {
      if (isForSession) {
        sessionStorage.setItem(storage.appStoragePrefix + name, value);
      } else {
        localStorage.setItem(storage.appStoragePrefix + name, value);
      }
    } else {
      if (isForSession) {
        cookies.set(storage.appStoragePrefix + name, value, {
          expires: 0
        });
      } else cookies.set(storage.appStoragePrefix + name, value);
    }
  },
  setCookie: function setCookie(name, value) {
    cookies.set(storage.appStoragePrefix + name, value, {
      path: '/',
      domain: domainUrl
    });
  },
  setObject: function setObject(name, value, isForSession) {
    localStorage.setItem(storage.appStoragePrefix + name, JSON.stringify(value));
  },
  setObjectjUtil: function setObjectjUtil(name, value, isForSession) {
    var validateValue = isJson(value);
    if (validateValue) {
      if (storage.html5StorageEnabled) {
        if (isForSession) {
          sessionStorage.setItem(storage.appStoragePrefix + name, JSON.stringify(value));
          document.cookie = storage.appStoragePrefix + name + "=" + JSON.stringify(value);
        } else {
          localStorage.setItem(storage.appStoragePrefix + name, JSON.stringify(value));
        }
      } else {
        if (isForSession) {
          if (JSON.stringify(value).length > 4000) console.error("Cookie is not set as cookie length too long...");else cookies.set(storage.appStoragePrefix + name, JSON.stringify(value), {
            expires: 0
          });
        } else {
          if (JSON.stringify(value).length > 4000) console.error("Cookie is not set as cookie length too long...");else cookies.set(storage.appStoragePrefix + name, JSON.stringify(value));
        }
      }
    } else {
      console.error(value + " is not a JSON object");
    }
  },
  // getter for the set key value pair
  get: function get(name) {
    if (storage.html5StorageEnabled) {
      var sessionValue = sessionStorage.getItem(storage.appStoragePrefix + name);
      if (sessionValue != null) {
        return sessionValue;
      }
      return localStorage.getItem(storage.appStoragePrefix + name);
    } else {
      return cookies.get(storage.appStoragePrefix + name);
    }
  },
  getCookie: function getCookie(name) {
    return cookies.get(name);
  },
  //getter for a set object
  getObject: function getObject(name) {
    var localValue = localStorage.getItem(storage.appStoragePrefix + name);
    if (localValue != null) {
      if (isJson(localValue)) return JSON.parse(localValue);else console.error(localValue + " is not a JSON object");
    }
  },
  //getter for a set object
  getObjectUtil: function getObjectUtil(name) {
    if (storage.html5StorageEnabled) {
      var sessionValue = sessionStorage.getItem(storage.appStoragePrefix + name);
      if (sessionValue != null) {
        if (isJson(sessionValue)) return JSON.parse(sessionValue);else console.error(sessionValue + " is not a JSON object");
      }
      var localValue = localStorage.getItem(storage.appStoragePrefix + name);
      if (localValue != null) {
        if (isJson(localValue)) return JSON.parse(localValue);else console.error(localValue + " is not a JSON object");
      }
    } else {
      var cookieValue = cookies.get(storage.appStoragePrefix + name);
      if (cookieValue != null) {
        if (isJson(cookieValue)) return JSON.parse(cookieValue);else console.error(cookieValue + " is not a JSON object");
      }
    }
  },
  //removing a name
  remove: function remove(name, isForSession, isForLocal) {
    if (storage.html5StorageEnabled) {
      if (isForSession && isForLocal) {
        sessionStorage.removeItem(storage.appStoragePrefix + name);
        localStorage.removeItem(storage.appStoragePrefix + name);
        document.cookie = storage.appStoragePrefix + name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      } else if (isForSession) sessionStorage.removeItem(storage.appStoragePrefix + name);else localStorage.removeItem(storage.appStoragePrefix + name);
    } else {
      document.cookie = storage.appStoragePrefix + name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
  },
  removeAllCookies: function removeAllCookies() {
    var removeAllcookies = document.cookie.split(";");
    if (removeAllcookies.length > 0) {
      for (var i = 0; i < removeAllcookies.length; i++) {
        var removeCookie = removeAllcookies[i];
        var eqPos = removeCookie.indexOf("=");
        var name = eqPos > -1 ? removeCookie.substr(0, eqPos) : removeCookie; // if (name.search(storage.appStoragePrefix) !== -1) {
        // document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        //let ifname = document.cookie.indexOf(" " + name + "=");
        if (name != undefined && name != null) {
          if (name !== "") cookies.remove(name, {
            path: '/',
            domain: domainUrl
          });
        } //console.info(removeCookie+" removed");
        // }
      }
    }
  },
  removeCookieByName: function removeCookieByName(name) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },
  //show all the storage objects set in application context
  show: function show() {
    if (sessionStorage.length > 0) {
      for (var i = 0; i < sessionStorage.length; i++) {
        var key = sessionStorage.key(i);
        var value = sessionStorage[key];
        if (key.search(storage.appStoragePrefix) !== -1) {
          console.info(key, " : ", value);
        }
      }
    }
    if (localStorage.length > 0) {
      for (var _i = 0; _i < localStorage.length; _i++) {
        var _key = localStorage.key(_i);
        var _value = localStorage[_key];
        if (_key.search(storage.appStoragePrefix) !== -1) {
          console.info(_key, " : ", _value);
        }
      }
    }
    var getAllcookies = document.cookie.split(";");
    if (getAllcookies.length > 0) {
      for (var _i2 = 0; _i2 < getAllcookies.length; _i2++) {
        var getCookie = getAllcookies[_i2];
        if (getCookie.search(storage.appStoragePrefix) !== -1) console.info(getCookie);
      }
    }
  },
  flush: function flush(isForSession) {
    if (storage.html5StorageEnabled) {
      if (sessionStorage.length > 0) sessionStorage.clear();
      if (!isForSession) {
        if (localStorage.length > 0) localStorage.clear();
      }
      storage.removeAllCookies();
    } else {
      storage.removeAllCookies();
    }
  },
  clearLocalStorge: function clearLocalStorge() {
    Object.keys(localStorage).forEach(function (key) {
      if (key !== "devtools" && !key.includes("import-map-override")) localStorage.removeItem(key); //localStorage.removeItem('persist:root')
    });
  },
  setSessionStorageValue: function setSessionStorageValue(key, value) {
    if (value && isJson(value)) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
  },
  getSessionStorageValue: function getSessionStorageValue(key) {
    var sessionValue = sessionStorage.getItem(key);
    if (!sessionValue) return sessionValue;
    if (isJson(sessionValue)) return JSON.parse(sessionValue);else return sessionValue;
  }
};
export default storage;