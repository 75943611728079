import React from "react";
import { Calendar } from "primereact/calendar";

const CalendarInput = ({ label, value, tooltipValue, minDate, maxDate, onChange, onClearButtonClick}) => {
  return (
    <div className="adv-search-date-field">
        <span className = 'af-adv-sreach-label'>{label}</span>
       <span>
          <Calendar
            tooltip={tooltipValue}
            tooltipOptions={{ className: 'white-tooltip', position: 'top', showDelay: 700 }}
            id="navigators"
            readOnlyInput
            appendTo={document.body}
            view="date"
            yearNavigator
            minDate={minDate}
            maxDate={maxDate}
            yearRange="1600:6030"
            value={value}
            showButtonBar
            onChange={onChange}
            onClearButtonClick={onClearButtonClick}
            showIcon              
          />
        </span>
    </div>    
  );
};

export default CalendarInput;
