import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./index";
var lifecycles = singleSpaReact({
  React: React,
  ReactDOM: ReactDOM,
  rootComponent: Root,
  errorBoundary: function errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  }
});
var bootstrap = lifecycles.bootstrap,
  mount = lifecycles.mount,
  unmount = lifecycles.unmount;
export { bootstrap, mount, unmount };