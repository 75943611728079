import { FETCH_ALL_RESTRICTED_WORDS_SUCCESS, SET_HIGHLIGHT_RESTRICTED_WORDS } from "./ActionType";
export var fetchRestrictedWords = function fetchRestrictedWords(payload) {
  return {
    type: FETCH_ALL_RESTRICTED_WORDS_SUCCESS,
    data: payload
  };
};
export var setHighlightRestrictedWords = function setHighlightRestrictedWords(payload) {
  return {
    type: SET_HIGHLIGHT_RESTRICTED_WORDS,
    data: payload
  };
};