export var IS_LOADING = "isLoading";
export var FETCH_ITEMS_REQUEST = "FETCH_ITEMS_REQUEST";
export var FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export var FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE";
export var SET_DELEGATES_ON_LOGIN = "SET_DELEGATES_ON_LOGIN";
export var SET_BREADCRUMB_TABITEMS_DATA = "SET_BREADCRUMB_TABITEMS_DATA";
export var SET_PAGE_HELP_LINK = "SET_PAGE_HELP_LINK";
export var FETCH_CLIENTITEMS_REQUEST = "FETCH_CLIENTITEMS_REQUEST";
export var FETCH_CLIENTITEMS_SUCCESS = "FETCH_CLIENTITEMS_SUCCESS";
export var FETCH_CLIENTITEMS_FAILURE = "FETCH_CLIENTITEMS_FAILURE";
export var FETCH_TIMEKEEPERLIST_REQUEST = "FETCH_TIMEKEEPERLIST_REQUEST";
export var FETCH_TIMEKEEPERLIST_SUCCESS = "FETCH_TIMEKEEPERLIST_SUCCESS";
export var FETCH_TIMEKEEPERLIST_FAILURE = "FETCH_TIMEKEEPERLIST_FAILURE";
export var SET_SELECTED_TIMEKEEPER = "SET_SELECTED_TIMEKEEPER";
export var SET_AUTH_DETAILS = "SET_AUTH_DETAILS";
export var RESET_AUTH_DETAILS = "RESET_AUTH_DETAILS";
export var SET_ROUNDING_METHOD = "SET_ROUNDING_METHOD";
export var SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export var SET_FEATURE = "SET_FEATURE";
export var SET_SIGNING_OUT = "SET_SIGNING_OUT";
export var RESET_SIGNING_OUT = "RESET_SIGNING_OUT";
export var FETCH_ALL_RESTRICTED_WORDS_SUCCESS = "FETCH_ALL_RESTRICTED_WORDS_SUCCESS";
export var FETCH_ALL_RESTRICTED_WORDS_FAILURE = "FETCH_ALL_RESTRICTED_WORDS_FAILURE";
export var SET_DURATION_TOGGLE = "SET_DURATION_TOGGLE";
export var REFRESH_STORE_ON_ERROR = "REFRESH_STORE_ON_ERROR";
export var SET_SHOW_CONFIRMATION = "SET_SHOW_CONFIRMATION";
export var SET_ROUTE = "SET_ROUTE";
export var SET_UNSAVED_CHANGES = "SET_UNSAVED_CHANGES";
export var SET_URL_ACCESS_MAP = "SET_URL_ACCESS_MAP";
export var SET_SESSION_TIMEOUT = "SET_SESSION_TIMEOUT";
export var SESSION_EXPIRED = "SESSION_EXPIRED";
export var SET_CURRENT_TAB_TITLE = "SET_CURRENT_TAB_TITLE";
export var SET_PREFERENCE_SETTINGS = "SET_PREFERENCE_SETTINGS";
export var SET_USER_ME_DATA = "SET_USER_ME_DATA";
export var RESET_REDUCERS = "RESET_REDUCERS";
export var SET_COUNTDOWN_TIME = "SET_COUNTDOWN_TIME";
export var SET_COUNTDOWN_MINUTES_AND_SECONDS = "SET_COUNTDOWN_MINUTES_AND_SECONDS";
export var SET_COUNTDOWN_MINUTES = "SET_COUNTDOWN_MINUTES";
export var SET_COUNTDOWN_SECONDS = "SET_COUNTDOWN_SECONDS";
export var SET_AUTOTEXT_DETAILS = "SET_AUTOTEXT_DETAILS";
export var SET_AUTOTEXT_ACTION = "SET_AUTOTEXT_ACTION";
export var SET_AUTOTEXT_DETAILS_SHORTCUT = "SET_AUTOTEXT_DETAILS_SHORTCUT";
export var PASSWORD_EXPIRY_REMINDER = "PASSWORD_EXPIRY_REMINDER";
export var ALLOW_MOUNTING = "ALLOW_MOUNTING";
export var SET_CHECK_EMAIL = "SET_CHECK_EMAIL";
export var SET_LOOKUP_LIST = "SET_LOOKUP_LIST";
export var SET_SELECTED_RESTRICTED_WORD = "SET_SELECTED_RESTRICTED_WORD";
export var SET_HIGHLIGHT_RESTRICTED_WORDS = "SET_HIGHLIGHT_RESTRICTED_WORDS";
export var RESET_MANAGETIMESTORE = "RESET_MANAGETIMESTORE";
export var SET_PERMISSION_OF_USER = "SET_PERMISSION_OF_USER";
export var SET_AUTOTEXT_VALUE = "SET_AUTOTEXT_VALUE";
export var SET_PREFERENCE_API_DATA = "SET_PREFERENCE_API_DATA";
export var SET_DATA_PAGE_UNMOUNT = "SET_DATA_PAGE_UNMOUNT";
export var SET_LOADING_FLAG = "SET_LOADING_FLAG";
export var SET_SELECTED_TE = "SET_SELECTED_TE";
export var TRANSFER_TE_SUCCESSFUL = "TRANSFER_TE_SUCCESSFUL";
export var TRANSFER_TE_FAILED = "TRANSFER_TE_FAILED";
export var SET_SHOWATTORNEYLIST_POPUP_FLAG = "SET_SHOWATTORNEYLIST_POPUP_FLAG";
export var SET_CHECKEDBILLABLE = "SET_CHECKEDBILLABLE";
export var SET_CHECKEDNONBILLABLE = "SET_CHECKEDNONBILLABLE";
export var SET_CHECKEDUNRELEASED = "SET_CHECKEDUNRELEASED";
export var SET_CHECKEDSTATE = "SET_CHECKEDSTATE";
export var SET_DATE = "SET_DATE";
export var SHOW_EDIT_TIME_ENTRY = "SHOW_EDIT_TIME_ENTRY";
export var SET_ETHICALWALLUSERIDS = "SET_ETHICALWALLUSERIDS";
export var SET_TRANSFERFEATURENAME = "SET_TRANSFERFEATURENAME";
export var SET_WOBO_ACTION = "SET_WOBO_ACTION";
export var SET_AFTER_SAVING_PREFERENCE = "SET_AFTER_SAVING_PREFERENCE";
export var RESET_TRANSFER_TE_STATUS = "RESET_TRANSFER_TE_STATUS";