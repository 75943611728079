import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { setErrorMessage } from "../actions/AppAction";
import { transferTimeEntriesFailed, transferTimeEntriesSuccessful } from "../actions/DayViewAction";
import { baseUrl } from "../config";
import { ButtonCall } from "../config/literalCodes";
import jsUtils from "../utils/jsUtils";
import { Fetch } from "./fetch";
var pathName = "/core/attorney/api/v1/timeentry";
export var dayViewApi = {
  transferTE: function transferTE(payload) {
    return function (dispatch) {
      return Fetch(pathName + "/transfer", _objectSpread({}, payload), "post", "transfer", true, true, null, baseUrl, jsUtils.getHeader(ButtonCall.TransferAPICallButtonHeaderName, null, "manageTime")).then(function (res) {
        dispatch(transferTimeEntriesSuccessful());
        dispatch(setErrorMessage({
          severity: "success",
          message: "Time entries were successfully transferred",
          errorCode: 200,
          life: 5000
        }));
      }).catch(function (err) {
        dispatch(transferTimeEntriesFailed());
        dispatch(setErrorMessage({
          severity: "error",
          message: "Time entry transfer failed",
          errorCode: 500,
          life: 5000
        }));
      });
    };
  },
  combineTE: function combineTE(payload) {
    return Fetch(pathName + "/combine", _objectSpread({}, payload), "post", "combine", true, true, null, baseUrl, jsUtils.getHeader(ButtonCall.CombineAPICallButtonHeaderName, null, "manageTime"));
  }
};