import React, { useEffect, useState, useRef } from "react";
import { Preferences } from "../../../services/PreferencesApi";
import "./PreferencesPracticeGrid.scss";
import { useSelector, useDispatch } from "react-redux";
import { setAfterSavingPreferenceAction } from "../../../actions/TimeManagementAction";
import { Toast } from "primereact/toast";
import Spinner from "../../common/Spinner/Spinner";
import { setErrorMessage } from "../../../actions/AppAction";
import { setPreferenceSettingsAction } from "../../../actions/PreferenceAction";
import storage from "../../../utils/storage";
import { setUnsavedChanges } from "../../../actions/ConfirmationAction";
import { InputSwitch } from "primereact/inputswitch";
import { HeaderView } from "../../../config/literalCodes";
import RadioButtonsGroup from "../../common/RadioButton/AfterSavingRadioButtons";

export default function PreferencesPracticeGrid(props) {
  const [value, setValue] = useState("NORMAL");
  const [outlookSelected, setOutlookSelected] = useState(false);
  const [googleSelected, setGoogleSelected] = useState(false);
  const [cloudCalendarChosen, setCloudCalendarChosen] = useState("NONE");
  const [cloudCalendarSwitch, setCloudCalendarSwitch] = useState(false);
  const [autoCapitalizeSwitch, setAutoCapitalizeSwitch] = useState(true);
  const [spellCheckSwitch, setSpellCheckSwitch] = useState(true);
  const [forceInvalidSwitch, setForceInvalidSwitch] = useState(false);
  const [firstCallView, setFirstCallView] = useState(true);
  const [changedValueMap, setChangedValueMap] = useState([]);
  const [initialValueMap, setInitialValueMap] = useState([]);
  const [preferenceFormState, setPreferenceFormState] = useState({
    roundingValue: "NORMAL",
    cloudCalendarChosen: "NONE",
    autoCapitalizeSwitch: true,
    spellCheckSwitch: true,
    cloudCalendarSwitch: false,
    forceInvalidSwitch: false,
  });

  const [loader, setLoader] = useState(true);

  const { afterSavingPreference } = useSelector(({TimeManagementReducer}) => ({ 
    afterSavingPreference: TimeManagementReducer.afterSavingPreference }));

  const preferenceValueFromApi = useSelector(
    (state) =>
      state.PreferenceReducer && state.PreferenceReducer.preferenceApiData
  );

  const dispatch = useDispatch();
  const toast = useRef(null);
  useEffect(() => {
    if (props.retainTemporaryData) {
      setValue(props.retainTemporaryData.timerRounding);
      setCloudCalendarChosen(props.retainTemporaryData.cloudCalendarOption);
      setCloudCalendarSwitch(props.retainTemporaryData.cloudCalendarToggle);
      setAutoCapitalizeSwitch(props.retainTemporaryData.autoCapitalizeToggle);
      setSpellCheckSwitch(props.retainTemporaryData.spellCheckToggle);
      setForceInvalidSwitch(props.retainTemporaryData.forceInvalidToggle);
      dispatch(setAfterSavingPreferenceAction(props.retainTemporaryData.afterSavingValue));
      setLoader(false);
      setChangedValueMap(props.retainTemporaryData);
    } else {
      Preferences.get(
        firstCallView ? HeaderView.PreferenceViewApiCallHeaderName : null
      )
        .then((res) => {
          setLoader(false);
          dispatch(setPreferenceSettingsAction(res));
          let valueRounding = res.preferencesData.find(
            (item) => item.param_key === "BILLABLE_DURATION_ROUNDING_METHOD"
          );
          let calendarOption = res.preferencesData.find(
            (item) => item.param_key === "CLOUD_CALENDAR"
          );
          let autoCapitalizeOption = res.preferencesData.find(
            (item) => item.param_key === "AUTO_CAPITALIZE"
          );
          let spellCheckOption = res.preferencesData.find(
            (item) => item.param_key === "SPELL_CHECK"
          );
          let calendarValue =
            calendarOption && JSON.parse(calendarOption.param_value);
          let spellCheckValue =
            spellCheckOption && JSON.parse(spellCheckOption.param_value);
          let autoCapitalizeValue =
            autoCapitalizeOption.param_value &&
            autoCapitalizeOption.param_value.toLowerCase() === "true"
              ? true
              : false;
          valueRounding && setValue(valueRounding.param_value);
          calendarValue && setGoogleSelected(calendarValue.Google);
          calendarValue && setOutlookSelected(calendarValue.Outlook);
          calendarValue && setCalendarOption(calendarValue);
          calendarValue &&
            setCloudCalendarSwitch(calendarValue.tentative_meetings_toggle);
          autoCapitalizeValue && setAutoCapitalizeSwitch(autoCapitalizeValue);
          spellCheckValue &&
            setSpellCheckSwitch(spellCheckValue.spell_check_toggle);
          spellCheckValue &&
            setForceInvalidSwitch(spellCheckValue.force_invalid_status_toggle);
          
          let afterSavingSelectedValue = getAfterSavingValue(res.preferencesData);
          dispatch(setAfterSavingPreferenceAction(afterSavingSelectedValue));
          props.onRetainData({
            timerRounding: valueRounding.param_value,
            cloudCalendarOption: calendarValue
              ? getCalendarOption(calendarValue)
              : "NONE",
            cloudCalendarToggle:
              calendarValue && calendarValue.tentative_meetings_toggle,
            autoCapitalizeToggle: autoCapitalizeValue,
            spellCheckToggle: spellCheckValue.spell_check_toggle,
            forceInvalidToggle: spellCheckValue.force_invalid_status_toggle,
            afterSavingValue: afterSavingSelectedValue
          });
          //setting default state for page
          setPreferenceFormState({
            roundingValue: valueRounding && valueRounding.param_value,
            cloudCalendarChosen:
              calendarValue && calendarValue.Outlook === true
                ? "OUTLOOK"
                : "NONE",
            cloudCalendarSwitch:
              calendarValue && calendarValue.tentative_meetings_toggle,
            autoCapitalizeSwitch: autoCapitalizeValue,
            spellCheckSwitch:
              spellCheckValue && spellCheckValue.spell_check_toggle,
            forceInvalidSwitch:
              spellCheckValue && spellCheckValue.force_invalid_status_toggle,
            afterSavingValue: afterSavingSelectedValue
          });
        })
        .catch((err) => {
          setLoader(false);
          dispatch(
            setErrorMessage({
              severity: "error",
              message: err && getCode(err.message),
              errorCode: err.responseCode,
            })
          );
        });
      setFirstCallView(false);
    }
  }, [props.retainTemporaryData]);

  /** set tooltips from storage on screen load
   * @param literals -variale to store the array of literals
   */
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setCodeFromAPI(literals);
    }
  }, []);
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };

  const toggleField = (event) => {
    setSpellCheckSwitch(event);
    setForceInvalidSwitch(false);
  };
  const getCalendarOption = (calendarValue) => {
    if (calendarValue.Google) {
      return "GOOGLE";
    } else if (calendarValue.Outlook) {
      return "OUTLOOK";
    } else {
      return "NONE";
    }
  };

  const setCalendarOption = (calendarValue) => {
    if (calendarValue.Google) {
      setCloudCalendarChosen("GOOGLE");
    } else if (calendarValue.Outlook) {
      setCloudCalendarChosen("OUTLOOK");
    } else {
      setCloudCalendarChosen("NONE");
    }
  };

  const getAfterSavingValue = (apiResponse) => {
    let afterSavingOption = apiResponse.find(
      (item) => item.param_key === "AFTER_SAVING"
    );
    let afterSavingOptions = afterSavingOption && JSON.parse(afterSavingOption.param_value);
    let _value = 'keep_te_open';
    if(afterSavingOptions) {
      Object.keys(afterSavingOptions).forEach(item => {
        if(afterSavingOptions[item] === true){
          _value = item;       
        }
      });
     } 
     return _value;
  }

  useEffect(() => {
    if (preferenceValueFromApi) {
      let valueRounding = preferenceValueFromApi.find(
        (item) => item.param_key === "BILLABLE_DURATION_ROUNDING_METHOD"
      );
      let calendarOption = preferenceValueFromApi.find(
        (item) => item.param_key === "CLOUD_CALENDAR"
      );
      let autoCapitalizeOption = preferenceValueFromApi.find(
        (item) => item.param_key === "AUTO_CAPITALIZE"
      );
      let spellCheckOption = preferenceValueFromApi.find(
        (item) => item.param_key === "SPELL_CHECK"
      );
     
      let calendarValue =
        calendarOption && JSON.parse(calendarOption.param_value);
      let spellCheckValue =
        spellCheckOption && JSON.parse(spellCheckOption.param_value);
      let autoCapitalizeValue =
        autoCapitalizeOption &&
        autoCapitalizeOption.param_value &&
        autoCapitalizeOption.param_value.toLowerCase() === "true"
          ? true
          : false;
      let afterSavingSelectedValue = getAfterSavingValue(preferenceValueFromApi);
      dispatch(setAfterSavingPreferenceAction(afterSavingSelectedValue));
      setInitialValueMap([
        valueRounding && valueRounding.param_value,
        calendarValue ? getCalendarOption(calendarValue) : "NONE",
        calendarValue && calendarValue.tentative_meetings_toggle,
        autoCapitalizeValue,
        spellCheckValue && spellCheckValue.spell_check_toggle,
        spellCheckValue && spellCheckValue.force_invalid_status_toggle,
        afterSavingSelectedValue,
      ]);
     
    }
  }, [preferenceValueFromApi]);

  const generateDynamicPayload = () => {
    let createPayload = [];
    if (
      initialValueMap &&
      initialValueMap[0] !== (changedValueMap && changedValueMap.timerRounding)
    ) {
      createPayload.push({
        param_key: "BILLABLE_DURATION_ROUNDING_METHOD",
        param_value: value,
        preference_menu_id: props.practiceObj.menuId,
      });
    }
    if (initialValueMap && ( initialValueMap[1] !== (changedValueMap && changedValueMap.cloudCalendarOption)) ||
      (initialValueMap[2] !== (changedValueMap && changedValueMap.cloudCalendarToggle))
    ) {
      createPayload.push({
        param_key: "CLOUD_CALENDAR",
        param_value: JSON.stringify({
          Google: googleSelected,
          Outlook: outlookSelected,
          tentative_meetings_toggle: cloudCalendarSwitch,
        }),
        preference_menu_id: props.practiceObj.menuId,
      });
    }
    if (
      initialValueMap &&
      initialValueMap[3] !==
        (changedValueMap && changedValueMap.autoCapitalizeToggle)
    ) {
      createPayload.push({
        param_key: "AUTO_CAPITALIZE",
        param_value: autoCapitalizeSwitch,
        preference_menu_id: props.practiceObj.menuId,
      });
    }
    if ( initialValueMap && (
        initialValueMap[4] !==
          (changedValueMap && changedValueMap.spellCheckToggle)) ||
      (initialValueMap[5] !==
          (changedValueMap && changedValueMap.forceInvalidToggle))
    ) {
      createPayload.push({
        param_key: "SPELL_CHECK",
        param_value: JSON.stringify({
          spell_check_toggle: spellCheckSwitch,
          force_invalid_status_toggle: forceInvalidSwitch,
        }),
        preference_menu_id: props.practiceObj.menuId,
      });
    }
    createPayload.push({
      param_key: "AFTER_SAVING",
      param_value: JSON.stringify({
        keep_te_open: afterSavingPreference === "keep_te_open",
        return_to_addTime: afterSavingPreference === "return_to_addTime",
        show_manageTime: afterSavingPreference === "show_manageTime",
      }),
      preference_menu_id: props.practiceObj.menuId,
    });
    return createPayload;
  };

  useEffect(() => {
    let changeDetection =
      initialValueMap &&
      (initialValueMap[0] ===
        (changedValueMap && changedValueMap.timerRounding) &&
      initialValueMap[1] ===
        (changedValueMap && changedValueMap.cloudCalendarOption) &&
      initialValueMap[2] ===
        (changedValueMap && changedValueMap.cloudCalendarToggle) &&
      initialValueMap[3] ===
        (changedValueMap && changedValueMap.autoCapitalizeToggle) &&
      initialValueMap[4] ===
        (changedValueMap && changedValueMap.spellCheckToggle) &&
      initialValueMap[5] ===
        (changedValueMap && changedValueMap.forceInvalidToggle) &&
      initialValueMap[6] ===
        (changedValueMap && changedValueMap.afterSavingValue)  );
    if (changeDetection) {
      dispatch(setUnsavedChanges(false));
    }
  }, [changedValueMap, initialValueMap]);

  const saveTimerRounding = () => {
    setLoader(true);

    let payload = {
      params: generateDynamicPayload(),
    };
    Preferences.post(payload)
      .then((res) => {
        dispatch(setUnsavedChanges(false));
        setLoader(false);
        dispatch(
          setErrorMessage({
            severity: "success",
            message: res && getCode(res.message),
            errorCode: res.responseCode,
          })
        );
        
        dispatch(Preferences.getPreferenceSettings());
      })
      .catch((err) => {
        setLoader(false);
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
      });
  };
  const onRadiobuttonSelection = (selectedValue) => {
    dispatch(setUnsavedChanges(true));
    dispatch(setAfterSavingPreferenceAction(selectedValue));    
    props.onRetainData({
      ...props.retainTemporaryData,
      afterSavingValue: selectedValue,
    });
  };

  return (
    <div className="Pref-Practice-screen">
      <div className="loader-class ">{loader ? <Spinner /> : ""}</div>
      <Toast ref={toast} />
        <div className="Pref-Practice-Grid">
          <div className="pref-data">
            <div className="pref-title">{getCode("PRACTICE_MANAGEMENT")}</div>
            <div className="pref-data-rows">
              <div className="pref-row-practice"></div>
              <RadioButtonsGroup
                afterSavingValue={afterSavingPreference}
                onRadiobuttonSelection={onRadiobuttonSelection}
              />
                <div className="pref-row-new">
                  <div className="row-title">{getCode("CLOUD_CALENDAR")}</div>
                  <div className="row-radio-btn">
                    <input
                      onChange={() => {
                        dispatch(setUnsavedChanges(true));
                        props.onRetainData({
                          ...props.retainTemporaryData,
                          cloudCalendarOption: "OUTLOOK",
                          cloudCalendarToggle: false,
                          outlookCalendar: true,
                          googleCalendar: false,
                        });
                        setCloudCalendarChosen("OUTLOOK");
                        setCloudCalendarSwitch(false);
                        setOutlookSelected(true);
                        setGoogleSelected(false);
                      }}
                      type="radio"
                      value="OUTLOOK"
                      name="CloudCalendar"
                      checked={cloudCalendarChosen === "OUTLOOK" ? true : false}
                      autocomplete="off"   
                      data-testid = "cloudCalRadio1"                   
                    />
                    <div className="row-radio-element-first">
                      {" "}
                      {getCode("OUTLOOK")}{" "}
                    </div>
                    <input
                      onChange={() => {
                        dispatch(setUnsavedChanges(true));
                        props.onRetainData({
                          ...props.retainTemporaryData,
                          cloudCalendarOption: "NONE",
                          cloudCalendarToggle: false,
                          googleCalendar: false,
                          outlookCalendar: false,
                        });
                        setCloudCalendarChosen("NONE");
                        setCloudCalendarSwitch(false);
                        setGoogleSelected(false);
                        setOutlookSelected(false);
                      }}
                      type="radio"
                      value="NONE"
                      name="CloudCalendar"
                      checked={cloudCalendarChosen === "NONE" ? true : false}
                      autocomplete="off"
                      data-testid = "cloudCalRadio2"
                    />{" "}
                    <div className="row-radio-element"> {getCode("NONE")}</div>
                  </div>
                  {cloudCalendarChosen === "GOOGLE" ||
                  cloudCalendarChosen === "OUTLOOK" ? (
                    <div className="row-toggle-btn">
                      <div className="toggle-text">
                        {getCode(
                          "INCLUDE_TENTATIVE_MEETINGS_WHEN_PREDICTING_TIME_ENTRIES"
                        )}
                      </div>
                      <div className="toggle-center">
                        <span className="text">{getCode("NO")} &nbsp;</span>
                        <InputSwitch  
                          data-testid = "tentativeMeet"                      
                          checked={cloudCalendarSwitch}
                          onChange={(e) => {
                            setCloudCalendarSwitch(e.value);
                            dispatch(setUnsavedChanges(true));
                            props.onRetainData({
                              ...props.retainTemporaryData,
                              cloudCalendarToggle: e.value,
                            });
                          }}
                        />
                        <span className="text">&nbsp; {getCode("YES")}</span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="pref-row-new">
                  <div className="row-title">{getCode("AUTO_CAPITALIZE")}</div>

                  <div className="row-radio-btn">
                    <div>
                      <span className="text">{getCode("NO")} &nbsp;</span>
                      <InputSwitch
                      data-testid = "autoCap"
                        checked={autoCapitalizeSwitch}
                        onChange={(e) => {
                          setAutoCapitalizeSwitch(e.value);
                          dispatch(setUnsavedChanges(true));
                          props.onRetainData({
                            ...props.retainTemporaryData,
                            autoCapitalizeToggle: e.value,
                          });
                        }}
                      />
                      <span className="text">&nbsp; {getCode("YES")}</span>
                    </div>
                  </div>
                </div>
                <div className="pref-row-new">
                  <div className="row-title">{getCode("SPELL_CHECK")}</div>

                  <div className="row-radio-btn">
                    <div>
                      <span className="text">{getCode("NO")} &nbsp;</span>
                      <InputSwitch
                      data-testid = "spellCheck"
                        checked={spellCheckSwitch}
                        onChange={(e) => {
                          toggleField(e.value);
                          dispatch(setUnsavedChanges(true));
                          props.onRetainData({
                            ...props.retainTemporaryData,
                            spellCheckToggle: e.value,
                            forceInvalidToggle: false,
                          });
                        }}
                      />
                      <span className="text">&nbsp; {getCode("YES")}</span>
                    </div>
                  </div>
                  {spellCheckSwitch ? (
                    <div className="row-toggle-btn">
                      <div className="toggle-text">
                        {getCode("FORCE_INVALID_STATUS_FOR_SPELLING_ERRORS")}
                      </div>
                      <div>
                        <span className="text">{getCode("NO")} &nbsp;</span>
                        <InputSwitch
                        data-testid = "forceCheck"
                          checked={forceInvalidSwitch}
                          onChange={(e) => {
                            setForceInvalidSwitch(e.value);
                            dispatch(setUnsavedChanges(true));
                            props.onRetainData({
                              ...props.retainTemporaryData,
                              forceInvalidToggle: e.value,
                            });
                          }}
                        />
                        <span className="text">&nbsp; {getCode("YES")}</span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
            </div>
          </div>
          <div className="btn-pannel">
            {!(
              initialValueMap &&
              ( initialValueMap[0] ===
                (changedValueMap && changedValueMap.timerRounding) &&
              initialValueMap[1] ===
                (changedValueMap && changedValueMap.cloudCalendarOption) &&
              initialValueMap[2] ===
                (changedValueMap && changedValueMap.cloudCalendarToggle) &&
              initialValueMap[3] ===
                (changedValueMap && changedValueMap.autoCapitalizeToggle) &&
              initialValueMap[4] ===
                (changedValueMap && changedValueMap.spellCheckToggle) &&
              initialValueMap[5] ===
                (changedValueMap && changedValueMap.forceInvalidToggle) && 
              initialValueMap[6] ===
                  (changedValueMap && changedValueMap.afterSavingValue))
            ) && (
              <button
                className="btn-cancel"
                data-testid = "btnCancel"
                onClick={() => {
                  setValue(preferenceFormState.roundingValue);
                  setCloudCalendarChosen(
                    preferenceFormState.cloudCalendarChosen
                  );
                  setCloudCalendarSwitch(
                    preferenceFormState.cloudCalendarSwitch
                  );
                  setAutoCapitalizeSwitch(
                    preferenceFormState.autoCapitalizeSwitch
                  );
                  setSpellCheckSwitch(preferenceFormState.spellCheckSwitch);
                  setForceInvalidSwitch(preferenceFormState.forceInvalidSwitch);
                  dispatch(setAfterSavingPreferenceAction(preferenceFormState.afterSavingValue));
                  
                  setChangedValueMap({
                    timerRounding: initialValueMap[0],
                    cloudCalendarOption: initialValueMap[1],
                    cloudCalendarToggle: initialValueMap[2],
                    autoCapitalizeToggle: initialValueMap[3],
                    spellCheckToggle: initialValueMap[4],
                    forceInvalidToggle: initialValueMap[5],
                    afterSavingValue: initialValueMap[6]
                  });
                  props.onRetainData({
                    timerRounding: initialValueMap[0],
                    cloudCalendarOption: initialValueMap[1],
                    cloudCalendarToggle: initialValueMap[2],
                    autoCapitalizeToggle: initialValueMap[3],
                    spellCheckToggle: initialValueMap[4],
                    forceInvalidToggle: initialValueMap[5],
                    afterSavingValue: initialValueMap[6]
                  });
                  dispatch(setUnsavedChanges(false));
                }}>
                {getCode("CANCEL")}
              </button>
            )}
            <button
              className="btn-save"
              data-testid = "btnSave"
              onClick={() => {
                saveTimerRounding();
              }}
              disabled={
                initialValueMap &&
                ( initialValueMap[0] ===
                  (changedValueMap && changedValueMap.timerRounding) &&
                initialValueMap[1] ===
                  (changedValueMap && changedValueMap.cloudCalendarOption) &&
                initialValueMap[2] ===
                  (changedValueMap && changedValueMap.cloudCalendarToggle) &&
                initialValueMap[3] ===
                  (changedValueMap && changedValueMap.autoCapitalizeToggle) &&
                initialValueMap[4] ===
                  (changedValueMap && changedValueMap.spellCheckToggle) &&
                initialValueMap[5] ===
                  (changedValueMap && changedValueMap.forceInvalidToggle) &&
                initialValueMap[6] ===
                  (changedValueMap && changedValueMap.afterSavingValue)  )
              }>
              {getCode("SAVE")}
            </button>
          </div>
        </div>
    </div>
  );
}
