import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRestrictedWords } from "../../../services/RestrictedWordsApi";
import * as NarrativeHelper from "../../common/AddTimePopup/NarrativeHelper";
import { setAutotextDetails, setAutotextDetailsShortcutAction } from "../../../actions/AutotextAction";
import { setHighlightRestrictedWords } from "../../../actions/AddTimeActions";
import { ToolTipWrapper } from "../../common/AddTimePopup/ToolTipWrapper";
import NarrativeWrapper from "../../common/AddTimePopup/Narrative/NarrativeWrapper";

const AutotextTextarea = ({ value, onChange,onReplaceAll, rowData, id, fieldType }) => {

//const highlightDefault = [{ highlight: "" }];
//const [ setRestrictedWords] = useState(highlightDefault);
const { allRestrictedWords } = useSelector(({ TimeManagementReducer }) => ({ allRestrictedWords: TimeManagementReducer.allRestrictedWords }));

const dispatch = useDispatch();
useEffect(() =>{
  dispatch(
    fetchAllRestrictedWords("clientId=null&subClientId=null&matterId=null")
  );
},[]);    

useEffect(() => {
    const { highlightJson} = NarrativeHelper.onLoadRestrictedWords(allRestrictedWords);
    highlightJson.map(rWord => (rWord.enhancement = ToolTipWrapper));    
    dispatch(setHighlightRestrictedWords(highlightJson));
    //setMaxChars(maxLimit);
}, [allRestrictedWords]);

const onChangeNarrative = (text, _enteredRestrictedWords) => {
 updateStore();   
};

const onNarrativeCorrection = (action, restrictedWordsNew, enteredRestrictedWordsNew) => {
  if (action === "Ignore All") {
      if (restrictedWordsNew) {
         //setRestrictedWords(restrictedWordsNew);
         dispatch(setHighlightRestrictedWords(restrictedWordsNew));
      }   
      updateStore();
   }
} 

const updateStore = () => {  
    let data;
    if(fieldType === "shortcut") {
      data = {
        ...rowData,
        enteredRestrictedWordsShortcut: getErrorWords(id),
      };
      dispatch(setAutotextDetailsShortcutAction(data));
    } else {
      data = {
        ...rowData,
        enteredRestrictedWordsFulltext: getErrorWords(id),
      };
      dispatch(setAutotextDetails(data));
    }  
}

const getErrorWords = (_id) => {
  let element = document.getElementById(_id);
  let reds = element && element.querySelector('.red');
  return reds;
};

return (
  <>
  <NarrativeWrapper
      module="Autotext"
      onChangeNarrative={onChangeNarrative}
      onReplaceAll={onReplaceAll}
      onNarrativeCorrection={onNarrativeCorrection}
      value={value}
      isNarrativeDisabled={false}
      setKeyPressed={() => {}}
      rows="1"
      id={id}
      onChange={onChange}
      containerDivClass="narrative-container-autotext"
      spellCheckToggle={"false"}
    />
  </> 
 );
}

export default AutotextTextarea;