import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./TimeManagenmentClientMatterTable.scss";
import utils from "../../../utils/jsUtils";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddTimePopup from "../../common/AddTimePopup/AddTimePopup";
import DraftTimePopup from "../../common/AddTimePopup/DraftTimePopup";
import GeneralTimePopup from "../../common/AddTimePopup/GeneralTimePopup";
import { AddTimeColDef, updateLiterals } from "./TMDataType";
import { buildQueryString } from "./TMDataType";
import { globalApi } from "../../../services/endpoints";
import { useSelector } from "react-redux";
import gStore from "../../../store/globalStore";
import storage from "../../../utils/storage";
import { AddTimeDefaultColumns, TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../config/vars";
import { setErrorMessage } from "../../../actions/AppAction";
import { useDispatch } from "react-redux";
import jsUtils from "../../../utils/jsUtils";
import { ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME, NO_OF_ROWS_IN_GRID, PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME } from "../../../config/messages";
import CustomTable from "../DataTable/CustomTable";
import _ from "lodash";
import { HeaderView } from "../../../config/literalCodes";
import DraggableColumnSelector from "../DraggableColumnSelector/DraggableColumnSelector";
import { Preferences } from "../../../services/PreferencesApi";

export default function TimeManagenmentClientMatterTable(props) {

  let history = useHistory();
  const delegateId = useSelector(
    (state) => state.TimeManagementReducer.delegateId
  );
  
  const { timeKeeperList } = useSelector(({TimeManagementReducer}) => ({ 
    timeKeeperList: TimeManagementReducer.timeKeeperList
   }));

   const {preferenceApiData , hasMultipleSubClients } = useSelector(
    ({ PreferenceReducer }) => ({
      preferenceApiData: PreferenceReducer.preferenceApiData,
      hasMultipleSubClients: PreferenceReducer.hasMultipleSubClients
    })
  );

  const [columnsData,setColumnsData]=useState([]);
  const [columnsDataBackup,setColumnsDataBackup]=useState([]);

  const [TMColDef, setTMColDef] = useState(AddTimeColDef);
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: [TOOLTIPS_VALUES.SEARCH],
    [TOOLTIPS_KEYS.TOOLTIP_STOP]: [TOOLTIPS_VALUES.STOP],
    [TOOLTIPS_KEYS.TOOLTIP_CLEARTIME]: [TOOLTIPS_VALUES.CLEAR_TIME],
    [TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_TIMER_ONLY]: [
      TOOLTIPS_VALUES.SHOW_ACTIVE_TIMER_ONLY,
    ],
    [TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_TIMERS]: [TOOLTIPS_VALUES.SHOW_ALL_TIMERS],
  });

  /** set tooltips from storage on screen load
   *  literals -variale to store the array of literals
   */
  const dispatch = useDispatch();
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [moveScrollToTop, setMoveScrollToTop] = useState(false);
  const [addNewPermission, setAddNewPermission] = useState(false); 

  const permissionsOfUser = useSelector((state) => state.AppReducer && state.AppReducer.fieldnames);
  useEffect(() => {
    let literals = storage.getObject("literals");

    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_PAUSE]: literals[TOOLTIPS_KEYS.TOOLTIP_PAUSE],
        [TOOLTIPS_KEYS.TOOLTIP_CLEARTIME]:
          literals[TOOLTIPS_KEYS.TOOLTIP_CLEARTIME],
        [TOOLTIPS_KEYS.TOOLTIP_STOP]: literals[TOOLTIPS_KEYS.TOOLTIP_STOP],
        [TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_TIMER_ONLY]:
          literals[TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_TIMER_ONLY],
        [TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_TIMERS]:
          literals[TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_TIMERS],
      });
      setCodeFromAPI(literals);
      updateLiterals();      
    }
  }, [localStorage.getItem("_literals")]);

  const getSubValidatedColumns=(_columns,_hasMultipleSubClients)=>{
    let listOfColumns=_columns;
    let subIndex = listOfColumns.findIndex((item)=>item.label.toUpperCase()==="SUB");
    if(subIndex!==-1){
      listOfColumns[subIndex]["disable"]=!_hasMultipleSubClients;
    }
    return listOfColumns;
  }

  useEffect(() => {
    
      let finalColDef = AddTimeColDef;
      if(!hasMultipleSubClients) {
        finalColDef = AddTimeColDef.filter(item => item.column.label && item.column.label.toUpperCase() !== "SUB");
      }
      let AddTimeColumns=preferenceApiData?.find((item)=>item.param_key==="ADD_TIME_COLUMNS");
      if(AddTimeColumns){
        let columns=[]

        try{
          columns=JSON.parse(AddTimeColumns.param_value);
        }
        catch(err){
          dispatch(setErrorMessage({severity: "error",
          message: "columns error",
          errorCode: 500,}))
        }
        if(columns && Array.isArray(columns.columnData)){
          let listOfColumns=getSubValidatedColumns(columns.columnData,hasMultipleSubClients);
          setColumnsData([...listOfColumns]);
           // as the state does not update when we reference the same array. we are strigifying and parsing it to make sure the state updates with the updated value
          let backupArrayString=JSON.stringify([...listOfColumns])
          let backupArrayParsed=JSON.parse(backupArrayString);
          setColumnsDataBackup(backupArrayParsed);

          let sortedColumnDef=jsUtils.getValidatedColumns(finalColDef,listOfColumns)
          sortedColumnDef=[...sortedColumnDef,AddTimeColDef[AddTimeColDef.length-1]]
          setColDef(sortedColumnDef);
        }
      }
      else{
        setColumnsData([...AddTimeDefaultColumns]);
        setColumnsDataBackup([...AddTimeDefaultColumns]);
        setColDef(finalColDef);
      }
    
  }, [hasMultipleSubClients,preferenceApiData]);

  useEffect(() => {
    const action = Preferences.getPreferenceSettings();
    dispatch(action);
}, []);

  /**
  *   gets the labels/tooltips/error codes from state codeFromAPI
  * @param {String} key - literals key
  * @returns the value associated for that particular key and if it does not find the key then return the key itself
  */
  const getCode = (key) => {
    // return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : literalCodes.codes[key] //Commented this line to display the key on UI so that the user knows it.
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };
  const timeKeeper = useSelector(
    (state) => state.TimeManagementReducer.timekeeper
  );

  TMColDef[TMColDef.length - 1].column.onClick = () => {
    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_DRAFT_TIME_ENTRY");
    setShowDraftTimeDialog(true);
  };
  
  let setScrollToTop = () => {
    // const scrollableBody = document.getElementsByClassName('p-datatable-scrollable-body');
    // if (scrollableBody && scrollableBody.length > 0) {
    //   scrollableBody[0].scrollTop = 0;
    // }
    setMoveScrollToTop(true);
  }

  const onTimeEntryClick = ({
    clientId,
    clientName,
    matterId,
    matterName,
    subClientId,
    subClientNumber,
    subClientName,
    billableDuration,
    clientNumber,
    matterCode
  }) => {

    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_TIME_ENTRY");
    setShowAddTimeDialog(true);
    setSelectedRowData({
      selectedClient: {
        id: clientId,
        description: clientName === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? getCode("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS") : clientName,
        code: clientNumber,
      },
      selectedMatter: {
        id: matterId,
        description: matterName === "INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS" ? getCode("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS") : matterName,
        code: matterCode,
      },
      selectedSubClient: {
        id: subClientId,
        description: subClientName,
        code: subClientNumber,
      },

      billableDuration: billableDuration,

      time: "00:00:00",
      status: "",
    });
  }
  /**
   * resets and empties the filters on the grid
   */

  TMColDef[TMColDef.length - 1].filter.onClick = () => {
    resetFilters();
    loadData(true);
    setIsResetButtonActive(true);
  };

   /**
   *  If the logged-in User is NOT a timekeeper and has not selected any 
   *  User in ‘Working on Behalf of’, then display message- “ Logged in User is not a Timekeeper”
    If the logged-in User is NOT a timekeeper and selected User in ‘Working on Behalf of’ is also NOT a timekeeper, 
    then display message- “ Working on behalf of User is not a Timekeeper”
   
   */

    const [addTimePermissions,setAddTimePermissions] = useState({timerEnable:false,addTime:false})

    const onApplyColumnChanges=(finalcolumns,selectColumns)=>{
      setLoading(true);
      let finalColDef = AddTimeColDef;
      if(!hasMultipleSubClients) {
        finalColDef = AddTimeColDef.filter(item => item.column.label && item.column.label.toUpperCase() !== "SUB");
      }
  
      let payload={params:[{param_key:"ADD_TIME_COLUMNS",param_value:JSON.stringify({"screenName":"AddTime","columnData": finalcolumns}),preference_menu_id:1}]};
      Preferences.post(payload).then((res)=>{
        let clonedArray=[...finalcolumns]
        document.getElementById("columnselectorbutton").click();

        // as the state does not update when we reference the same array. 
        //we are strigifying and parsing it to make sure the state updates with the updated value.
  
        let backupArrayString=JSON.stringify([...finalcolumns]);
        let backupArrayParsed=JSON.parse(backupArrayString);
        setColumnsDataBackup(backupArrayParsed);
  
        setColumnsData([...clonedArray]);
        let sortedColumnDef=jsUtils.getValidatedColumns(finalColDef,selectColumns);
        sortedColumnDef=[...sortedColumnDef,AddTimeColDef[AddTimeColDef.length-1]]

        setColDef(sortedColumnDef);
        setLoading(false);
      }).catch((err)=>{
        console.log(err);
        setLoading(false);
      })
    }

  useEffect(() => {
 
    const permissionsUser = permissionsOfUser?.find( (item) => item.id==="clientmattersgrid" );
    const draftTimeEntryPermission = permissionsUser?.fields.find((item)=>item.id ==="drafttimentry")?.actionable;
    const activeFlagPermission = permissionsUser?.fields.find((item)=>item.id ==="activeFlag")?.actionable;
    const timerPermission = permissionsUser?.fields.find((item)=>item.id ==="timer")?.actionable;
    const addTimePermission = permissionsUser?.fields.find((item)=>item.id ==="addtime")?.actionable;
    setAddTimePermissions({timerEnable:timerPermission,addTime:addTimePermission})

    let featureList=getFeatureNames(timeKeeperList,delegateId) || [];
    const generalTimeRolePermissionLoggedInUser=permissionsOfUser?.find((item) => item.id==="generaltimeentries")?.fields.find((item)=>item.id ==="generaltimentry")?.actionable || false;
    const generalTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME)!==-1
    const addTimeFeaturePermission=featureList.findIndex((item)=>item===PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME) !==-1
    const isLoggedInUserSelected =(timeKeeperList && timeKeeperList[0]?.userDelegateId === delegateId) 
    TMColDef[TMColDef.length - 1].column.renderElement = () => {
      return (
        <div style={{display: "flex", float:"right"}}>
          <DraggableColumnSelector
          columnSelectionData={columnsData}
          columnSelectionDataBackup={columnsDataBackup}
          onApply={onApplyColumnChanges}
          />
          {timeKeeper && (((timeKeeperList[0]?.userDelegateId === delegateId) && draftTimeEntryPermission) || (addTimeFeaturePermission)) &&
            <button
              className={"p-button p-component p-button-success p-mr-2 time-button"}
              onClick={() => {
                setShowDraftTimeDialog(true);
              }}
            >
              <span className="pi pi-plus p-c p-button-icon-left"></span>
              <span className="p-button-label p-c">{jsUtils.getLiteralValue("DRAFT_TIME")}</span>
            </button>
          }
          {((!isLoggedInUserSelected && generalTimeFeaturePermission) || (isLoggedInUserSelected && generalTimeRolePermissionLoggedInUser)) &&
            <button
              className={"p-button p-component p-button-success p-mr-2 time-button"}
              onClick={() => {
                setShowGeneralTimeDialog(true);
              }}
            >
              <span className="pi pi-plus p-c p-button-icon-left"></span>
              <span className="p-button-label p-c">{jsUtils.getLiteralValue("GENERAL_TIME")}</span>
            </button>
          }
        </div>
      )
    };
    setAddNewPermission(!draftTimeEntryPermission);

    TMColDef[TMColDef.length - 2].filter.disabled = activeFlagPermission

  }, [permissionsOfUser, timeKeeper, delegateId, timeKeeperList,columnsData]);

  const [colDef, setColDef] = useState(TMColDef);
  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerAPIInProgress, setTimerAPIInProgress] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({
    selectedClient: { id: "", description: "", code: "" },
    selectedMatter: { id: "", description: "", code: "" },
    selectedSubClient: { id: "", description: "", code: "" },
    timerStartDatetime: null,
    billableDuration: "",
    time: "00:00:00",
    status: "",
  });

  const [scrollNoMore, setScrollNoMore] = useState(false);
  const [DataFromAPI, setDataFromAPI] = useState([]);
  const [activeTimerId, setTimerId] = useState(0);

  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });

  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [isResetButtonActive, setIsResetButtonActive] = useState(true);

  /**
    *  the state is updated with the new filters
    * @param {String} key -  column name
    * @param {String} value - searched text or toggle value
    * @param {Number} index -index of the filter column 
    */

  let setFilters = (key, value, index) => {
    let state = colDef;
    colDef[index].filter.val = value;
    if (index === colDef.length - 2 && value === true) {
      colDef[index].filter.tooltip =
        tooltips[TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_TIMERS];
    } else {
      colDef[index].filter.tooltip =
        tooltips[TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_TIMER_ONLY];
    }
    setColDef([...state]);
  };

  /*
  * removes all the filters from grid and adds default values
  */

  let resetFilters = () => {
    let state = colDef;
    colDef.forEach((item)=>{
      if(item.column.columnName==="active timer"){
        item.filter.val=false;
      }
      else{
        item.filter.val="";
      }
    })
    sort.index = -1;
    setSort({ index: -1, isAsc: true, propName: "" });
    setSortByField("");
    setSortNameField("");
    setColDef([...state]);
    setScrollToTop();
    setIsResetButtonActive(true);
  };
  const [showAddTimeDialog, setShowAddTimeDialog] = useState(false);
  const [showDraftTimeDialog, setShowDraftTimeDialog] = useState(false);
  const [showGeneralTimeDialog, setShowGeneralTimeDialog] = useState(false);
  const [firstCallView,setFirstCallView]=useState(true);
  const getFeatureNames=(list,userId)=>{
    let featureNames=[];
    if(!list || list.length === 0) return [];
    list.forEach((item)=>{
      if(item.userDelegateId===userId){
        featureNames.push(item.featureName)
      }
    })
    return featureNames;
  }
  /*
  * On component load or change in delegate in work on behalf we call the client matters api to load the grid
  */
  useEffect(() => {

      if (delegateId && permissionsOfUser && timeKeeperList) {
        let selectedKeeper=timeKeeperList.find((item)=>item.userDelegateId === delegateId);
        if(selectedKeeper && selectedKeeper.timekeeper){
          gStore.activeTimerId = null;
          setSort({ index: -1, isAsc: true, propName: "" });
          loadData(true);
          setScrollToTop();
        }
        else if(selectedKeeper && !selectedKeeper.timekeeper){
          setDataLoaded(true);
          setLoading(false);
          setDataFromAPI([]);
          setNoItemsFoundMessage(true);
          setNoItemsFoundMessageText({
            titleText: jsUtils.getLiteralValue("NOT_TIMEKEEPER")
          }); 
        }
      }
  }, [delegateId, permissionsOfUser, timeKeeper, timeKeeperList]);

  //api call on load of time management to get user preferences
  useEffect(() => {
    
    return resetFilters;
  }, []);

  /*
  * Gstore is a state that is maintained for tracking the running timer 
  * updateGstore updates the gstore with the current timer
    */
  const updateGstore = (
    rowId,
    _delegateIdUpdated,
    loggedInUserId,
    timeDuration,
    clientId,
    subClientId,
    matterId,
    status,
    timerId
  ) => {
    return (
      (gStore.activeTimerId = rowId),
      (gStore.delegateId = _delegateIdUpdated),
      (gStore.loggedInUserId = loggedInUserId),
      (gStore.timeDuration = timeDuration),
      (gStore.activeTimerAPIPostInput = {
        clientId: clientId.toString(),
        subClientId: subClientId,
        matterId: matterId,
        attorneyId: _delegateIdUpdated,
        loggedInUserId: loggedInUserId,
        timerStatus: status,
        timerId: timerId,
      })
    );
  };

  const setDataToSession = (clientItem) => {
    jsUtils.setDataToSessionStorage(clientItem);
  }


  /**
    *  Funcstion that loads the grid with sets of client and matter 
    * @param {boolean} reset - true for the first page load and false for 2nd and more onwards
    *  @param {Number} page 
    *  @param {Array} formattedJson 
    */
  const invokeClientMatterAPI = (searchParams, page, reset, formattedJson, _pageOffset, sortName, sortby,headerViewTrackerName) => {
    //setLoading(true);
    globalApi
      .matters(delegateId,
        searchParams,
        timeKeeperList && timeKeeperList[0] ? timeKeeperList[0].userDelegateId : null,
        headerViewTrackerName,        
        sortName,
        sortby,
        reset ? 1 : page,
        reset ? 0 : _pageOffset,
        )
      .then((res) => {
        setTimerId(0);
        setLoadingError(false);

        if (res && res.clientMattersData) {
          if (res.nextPageOffset) {
            setPageOffset(res.nextPageOffset);
          }

          const dataWithId = res.clientMattersData.map((rowData) => {
            return { ...rowData, rowId: jsUtils.getRandomNumber(), timeDuration: rowData.timeDuration || "00:00:00" }
          });

          let findActiveTimer = dataWithId.find((rowData) => rowData.timerStatus && (rowData.timerStatus.toLowerCase() === "started" || rowData.timerStatus.toLowerCase() === "resumed"))
          findActiveTimer && setDataToSession(findActiveTimer)
          findActiveTimer && updateGstore(
            findActiveTimer.rowId,
            delegateId,
            timeKeeperList[0].userDelegateId,
            findActiveTimer.timeDuration,
            findActiveTimer.clientId,
            findActiveTimer.subClientId,
            findActiveTimer.matterId,
            findActiveTimer.timerStatus,
            findActiveTimer.timerId
          );
          /**
        * if reset is true reset the data in the state with the response data as the api call is for 1st page of list
        * else if reset is false that means the res is from lazy loading and is from 2nd page or higher.So append the 
        * data to existing state
       */
          if (reset) {
            setDataFromAPI(dataWithId);

            if (dataWithId.length === 0) {
              setNoItemsFoundMessage(true);
              setNoItemsFoundMessageText({
                titleText: getCode('CLIENT_MATTER_NOT_FOUND'),
                buttonText: getCode('DRAFT_TIME'),
              }); 

            }
            else {
              if (dataWithId.length < 20) {
                setScrollNoMore(true);
              }
              else {
                setScrollNoMore(false);
              }

            }

          } else {
            if (dataWithId.length < 20) {
              setScrollNoMore(true);
            }
            else {
              setScrollNoMore(false);
            }
            setDataFromAPI([...DataFromAPI, ...dataWithId]);
          }
        }
      })
      .catch((err) => {
        console.log("clientmatters API failed for delegateID ", delegateId, err);
      })
      .finally(() => {
        setDataLoaded(true);
        setLoading(false);
      });

  }
  /**
   *  client matter api that loads the grid with sets of client and matter 
   * @param {boolean} reset - true for the first page load and false for 2nd and more onwards
   */

  const loadData = (reset = true, sortName="", sortby="") => {
    
    setLoading(true);
    setDataLoaded(true);

    let page = 1;
    if(reset){
      setPageOffset(0);
    }

    let searchParams = buildQueryString(page,delegateId,timeKeeperList && timeKeeperList[0] ? timeKeeperList[0].userDelegateId : null,sortName,sortby);

    let formattedJson = { data: [] };

    let featureList = getFeatureNames(timeKeeperList,delegateId)
    const addTimeFeaturePermissionLoggedInUser= permissionsOfUser?.find((item) => item.id==="clientmattersgrid")?.fields.find((item)=>item.id ==="addtime")?.display || false;
    const addTimeFeaturePermission=featureList.findIndex((item)=>item==="MG-Practice-MD-TM-FE-AddTime-MngTime") !==-1;

    if ( timeKeeper && ((timeKeeperList[0]?.userDelegateId === delegateId) && addTimeFeaturePermissionLoggedInUser) || ( (timeKeeperList[0]?.userDelegateId !== delegateId) && addTimeFeaturePermission) ) 
    {

       invokeClientMatterAPI(searchParams, page, reset, formattedJson, reset ? 0 : pageOffset, sortName,sortby,firstCallView?HeaderView.AddTimeViewApiCallHeaderName:null)
       setFirstCallView(false);

    }
    else{
          setDataLoaded(true);
          setLoading(false);
          setDataFromAPI([]);
          setNoItemsFoundMessage(true);
          setNoItemsFoundMessageText({
            titleText: jsUtils.getLiteralValue("NOT_TIMEKEEPER")
          }); 
    } 
    
  };

  /**
   *  starts a new timer or resumes the existing timer from pause state
   * @param {object} rowData - contains the rowData for the selected timer
   * @param {*} status - contains the timer status 
   */
  const start_or_resume = async (rowData, status = "resumed") => {
    if (!timerAPIInProgress) {
      setTimerAPIInProgress(true);
      await pause("paused", rowData);
      let apiData = {
        clientId: rowData.clientId.toString(),
        subClientId: rowData.subClientId,
        matterId: rowData.matterId,
        attorneyId: delegateId,
        loggedInUserId: timeKeeperList[0].userDelegateId,
        timerStatus: status,
        timerId: rowData.timerId,
      };

      // gStore.activeTimerAPIPostInput=apiData;
      // gStore.activeTimerId=rowData.rowId;
      /*
      *  updates the grid with the latest timer status 
      */
      await update_timer_api(
        apiData,
        rowData.rowId,
        "currentTimer",
        rowData
      ).then(
        (res) => {
          status === "started"
            ? updateRowData(
              gStore.activeTimerId,
              rowData.timeDuration,
              status,
              null,
              res.timerId
            )
            : updateRowData(
              gStore.activeTimerId,
              rowData.timeDuration,
              status,
              null,
              rowData.timerId
            );
          jsUtils.setDataToSessionStorage(rowData)
          setTimerAPIInProgress(false);
        }
      ).catch((error)=> {
       
        if(error.responseCode===500){
          setTimerAPIInProgress(false);
          jsUtils.setDataToSessionStorage(rowData)
          loadData(true);
        }

      });
    }
  };

  /**
    *  pauses the running timer
    * @param {Object} rowData - contains the rowData for the selected timer
    * @param {String} status - contains the timer status 
    */
  const pause = async (status, rowData) => {
    if (gStore.activeTimerId) {
      let apiData = { ...gStore.activeTimerAPIPostInput, timerStatus: status };
      let timerRecord = jsUtils.getDataFromSessionStorage();
      if (timerRecord) {
        const now = new Date();
        let additionalTimeInSeconds = Math.round(now.getTime() / 1000) - timerRecord.fetchedTimeInSecs
        let updatedTime = jsUtils.updatedTime(timerRecord.timeDuration, additionalTimeInSeconds)
        gStore.timeDuration = updatedTime;
      }
      /*
      * updates the grid with the latest timer status for the current rowData 
      */
      await update_timer_api(
        apiData,
        gStore.activeTimerId,
        "currentTimer",
        rowData
      ).then(
        () => {

          let oldTimerId = gStore.activeTimerId;
          gStore.activeTimerId = 0;

          updateRowData(
            oldTimerId,
            gStore.timeDuration,
            status,
            null,
            gStore.activeTimerAPIPostInput.timerId
          );
        }
      ).catch((error)=> {
       
        if(error.responseCode===500){
          gStore.activeTimerId = 0;
          loadData(true);
        }

      });
    }

  };

  /**
   *  - stops/delete the timer
   * @param {string} status - contains the status "stopped"/"deleted"
    * @param {object} rowData - contains the details of the current row that is operated on  
    */
  const stop_delete = async (status, rowData) => {
    if (!timerAPIInProgress) {
      setTimerAPIInProgress(true);

      // Checks if it is the current running timer or a old timer
      if (gStore.activeTimerId === rowData.rowId) {
        let apiData = {
          ...gStore.activeTimerAPIPostInput,
          timerStatus: status,
          timerId: rowData.timerId,
        };
        let oldTimerId = gStore.activeTimerId;
        await update_timer_api(
          apiData,
          gStore.activeTimerId,
          "currentTimer",
          rowData
        ).then(
          (res) => {

            if (sessionStorage.getItem("timerKeyLazy")) {
              sessionStorage.removeItem("timerKeyLazy")
            }

            if (status === "stopped") {
              gStore.activeTimerId = 0;
            }
            status === "stopped"
              ? updateRowData(
                oldTimerId,
                gStore.timeDuration,
                status,
                null,
                rowData.timerId,
                res.timerStartDatetime
              )
              : updateRowData(oldTimerId, gStore.timeDuration, status);
            setTimerAPIInProgress(false);
          }
        ).catch((error)=>{
          if(error.responseCode===500){
          gStore.activeTimerId = 0;
          setTimerAPIInProgress(false);
          loadData(true);
        }
        })
      } else {
        let apiData = {
          clientId: rowData.clientId.toString(),
          subClientId: rowData.subClientId,
          matterId: rowData.matterId,
          attorneyId: delegateId,
          loggedInUserId: timeKeeperList[0].userDelegateId,
          timerStatus: status,
          timerId: rowData.timerId,
        };
        await update_timer_api(
          apiData,
          rowData.rowId,
          "pastTimer",
          rowData
        ).then(
          (res) => {
            status === "stopped"
              ? updateRowData(
                rowData.rowId,
                rowData.timeDuration,
                status,
                null,
                rowData.timerId,
                res.timerStartDatetime
              )
              : updateRowData(rowData.rowId, rowData.timeDuration, status);
            setTimerAPIInProgress(false);
          }
        ).catch((error)=>{
          if(error.responseCode===500){
          gStore.activeTimerId = 0;
          setTimerAPIInProgress(false);
          loadData(true);
        }
        })
      }
    }
  };

  /**
    *  it'll update gStore and rowData as well.
    * @param {object} rowData - contains the rowData for the selected timer
    * @param {object} apiData - contains the details for the timer client matter loggedinuser status details
    * @param {string} Timer - Check for the timer state(current or past)
    * @param {Number} timerRowId - contains the timerId
    */
  const update_timer_api = (apiData, timerRowId, Timer, rowData) => {

    return globalApi
      .updateTimerStatus(apiData)
      .then(
        (res) => {
          if (Timer === "currentTimer") {
            if (apiData.timerStatus === "deleted") {
              gStore.timeDuration = "00:00:00";
              gStore.activeTimerId = 0;
              gStore.activeTimerAPIPostInput = {
                clientId: "",
                subClientId: "",
                matterId: "",
                attorneyId: "",
                loggedInUserId: "",
                timerStatus: "",
              };
              rowData.timeDuration = "00:00:00";
              TMColDef[3].filter.val && loadData();
            } else {
              gStore.activeTimerId = timerRowId;
              res && res.timerId && (apiData["timerId"] = res.timerId);
              gStore.activeTimerAPIPostInput = apiData;
            }
          } else {
            if (apiData.timerStatus === "deleted") {
              rowData.timeDuration = "00:00:00";
              TMColDef[3].filter.val && loadData();
            }
          }
          return res;
        }
      )
      .catch((err)=> {
       
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
        throw err;
      });
  };

  /**
   *  updates the grid with current status of the rowdata
   * @param {Number} rowId - contains the rowId for the row to be updated
   * @param {String} timeduration - contains the duration of the timer
   * @param {Number} timerId - contains the timerId
   * @param {String} status - contains the status of the timer
   * @param {Array} data - contains array of clientmatter items in grid
   * @param {String} timerStartdatetime - contains the timer Start time
   */
  const updateRowData = (
    rowId,
    timeduration = "00:00:00",
    status,
    data,
    timerId,
    timerStartdatetime
  ) => {
    let dataIndex = DataFromAPI.findIndex(rowData => rowData.rowId === rowId);
    if (status === "stopped") {
      let client = { id: "", description: "", code: "" };
      let matter = { id: "", description: "", code: "" };
      let billableDuration = "";
      let subClient = { id: "", description: "", code: "" };
      let ethicalWallFlag = null;
      // let dataIndex= DataFromAPI.findIndex(rowData => rowData.rowId === rowId);
      if (dataIndex !== -1) {
        const _data = DataFromAPI[dataIndex];

        client = {
          id: _data.clientId,
          description: _data.ethicalWallFlag ? jsUtils.getLiteralValue("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS") : _data.clientName,
          code: _data.clientNumber,
        };
        subClient = {
          id: _data.subClientId,

          // changed number to name
          description: _data.subClientName,
          
          code: _data.subClientNumber,
        };
        matter = {
          id: _data.matterId,
          description: _data.ethicalWallFlag ? jsUtils.getLiteralValue("INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS") : _data.matterName,
          code: _data.matterCode,
        };

        billableDuration = _data.billableDuration;
        ethicalWallFlag = _data.ethicalWallFlag;
      }
      setSelectedRowData({
        selectedClient: client,
        selectedMatter: matter,
        selectedSubClient: subClient,
        billableDuration: billableDuration,
        timerStartDatetime: timerStartdatetime,
        time: timeduration,
        status: status,
        ethicalWallFlag: ethicalWallFlag
      });
      setShowAddTimeDialog(true);
    }
    setDataFromAPI(
      DataFromAPI.map((rowData) => {
        if (rowData.rowId === rowId) {
          timeduration && (rowData.timeDuration = timeduration);
          rowData.timerStatus = status;
          rowData.timerId = timerId;
          timerId && (rowData.timerId = timerId);
        }
        return rowData;
      })
    );

    // DataFromAPI[dataIndex].timeDuration=timeduration;
    // DataFromAPI[dataIndex].timerStatus=status;
    // DataFromAPI[dataIndex].timerId=timerId;

    // setDataFromAPI(DataFromAPI);
  };

  /** 
    *  sorts the columns of the grid individually
   * @param {Array} data - array of objects for grid of time entry
   * @param {Object} localSort - contains the sort object
   */
  const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc ? "asc" : "desc";

      let sortName = "";
      switch (localSort.propName) {
        case 'clientIdWithName':
          sortName = "clients.client_name"
          break;
        case 'subClientNumber':
          sortName = "sub.description"
          
          break;
        case 'matterIdWithDesc':
          sortName = "matters.description"
          break;
        default:
          sortName = ""
      }

      setSortByField(sortBy);
      setSortNameField(sortName);
      if(sortName !== ""){
        loadData(true, sortName,sortBy)
      }
      
    }
  };

  /**
   * client matter api is called with the selected filters as query params
   * @param {String} value - searched text or toggle value
    * @param {Number} index -index of the filter column 
    */
  const onFilterChange = (key, value, index) => {
   
    setPageOffset(0);
    setScrollNoMore(false);
    utils.debounce(loadData, 400, true, sortNameField, sortByField);
    gStore.activeTimerId = 0;
    setFilters(key, value, index);
    setScrollToTop();
    let resetSortParams = {
      index: -1,
      isAsc: true,
      propName: "",
    }
    const defaultValue = ["","","",false];
    let filterStatus = jsUtils.checkIfCustomTableFilterChanged(colDef,defaultValue)
    let sortStatus =  _.isEqual(sort, resetSortParams);
    setIsResetButtonActive(filterStatus && sortStatus);

  };

  const onSaveHandlerGeneralTime = (afterSavingPreference) => {
    if(afterSavingPreference === "keep_te_open") {
      return;
    } else if(afterSavingPreference === "return_to_addTime") {
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME"); 
      resetFilters();
      loadData(true);
      setShowGeneralTimeDialog(false);
    } else if(afterSavingPreference === "show_manageTime") {
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME");  
      history.push("/SureTime/ManageTime");
      setShowGeneralTimeDialog(false);
    }
  }

  const onSaveHandlerAddTime = (afterSavingPreference) => {
    if(afterSavingPreference === "keep_te_open") {
      return;
    } else if(afterSavingPreference === "return_to_addTime") {
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME");  
    } else if(afterSavingPreference === "show_manageTime") {
      document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_MANAGE_TIME");  
      history.push("/SureTime/ManageTime");
    } 
    
    resetFilters();
    loadData(true);
    setShowDraftTimeDialog(false);
    setShowAddTimeDialog(false);
  }

  return (
    <div>
      {showDraftTimeDialog && (
        <DraftTimePopup
          show={showDraftTimeDialog}
          closed={() => {
            setShowDraftTimeDialog(false);
            setSelectedRowData({
              selectedClient: { id: "", description: "", code: "" },
              selectedMatter: { id: "", description: "", code: "" },
              time: "00:00:00",
              selectedSubClient: { id: "", description: "", code: "" },
              billableDuration: "",
              timerStartDatetime: null,
              status: "",
            });
            resetFilters();
            loadData(true);
          }}
          onSaveHandler={afterSavingPreference => 
            onSaveHandlerAddTime(afterSavingPreference)
          }
          data={selectedRowData}
          pageTitle={props.pageTitle}
        />
      )}
      {showGeneralTimeDialog && (
        <GeneralTimePopup
          show={showGeneralTimeDialog}
          closed={() => {
            setShowGeneralTimeDialog(false);
            loadData(true);
          }}
          onSaveHandler={(afterSavingPreference) => {
            onSaveHandlerGeneralTime(afterSavingPreference);            
          }}
          data={selectedRowData}
          pageTitle={props.pageTitle}
        />
      )}
      {showAddTimeDialog && (
        <AddTimePopup
          show={showAddTimeDialog}
          closed={() => {
            document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_ADD_TIME");    
            setSelectedRowData({
            selectedClient: { id: "", description: "", code: "" },
            selectedMatter: { id: "", description: "", code: "" },
            time: "00:00:00",
            selectedSubClient: { id: "", description: "", code: "" },
            billableDuration: "",
            timerStartDatetime: null,
            status: ""
            });
            resetFilters();
            loadData(true);
            setShowAddTimeDialog(false);            
          }}
          onSaveHandler={afterSavingPreference => 
            onSaveHandlerAddTime(afterSavingPreference)
          }
          data={selectedRowData}
          pageTitle={"ATTORNEYFLOW_TIME_ENTRY"}
        />
      )}
      <div className="datatable-filter-demo af-addtime-grid">
        <div className="card">
          {delegateId && (
            <CustomTable
              rows={NO_OF_ROWS_IN_GRID}
              colDef={colDef}
              parentMethods={{
                start_or_resume,
                pause,
                stop_delete,
                onTimeEntryClick,
                isActionable: () => {
                  return addTimePermissions.timerEnable;
                },
                enableAddTime: () => {
                  return addTimePermissions.addTime;
                },

              }}
              data={DataFromAPI}
              activeTimerId={activeTimerId}
              dataLoaded={dataLoaded}
              updateTimerData={true}
              isLoading={loading}
              loadingError={loadingError}
              noItemsFoundMessage={noItemsFoundMessage}
              noItemsFoundMessageText={noItemsFoundMessageText}
              onFilterChange={onFilterChange}
              onLazyScroll={() => {
                if (loading === false && !scrollNoMore) {
                  loadData(false, sortNameField, sortByField);
                }
              }}
              enableRowExpansion={false}
              onSort={(index, isAsc, propName) => {
                setSort({ index, isAsc, propName });
                sortData({ index, isAsc, propName });
                setIsResetButtonActive(false);
              }}
              rowExpansionElement={(rowItem) => {
                return (
                  <ManageTimeCard Carditem={rowItem} />
                );
              }}
              sort={sort}
              addNewButton={addNewPermission}
              scrollNoMore={scrollNoMore}
              moveScrollToTop={moveScrollToTop}
              resetScrollFlag={() => setMoveScrollToTop(false)}
              resetButtonActive={isResetButtonActive}
            />
          )}
        </div>
      </div>
    </div>
  );
}
