import { TRANSFER_TE_FAILED, TRANSFER_TE_SUCCESSFUL, SET_SHOWATTORNEYLIST_POPUP_FLAG, SET_CHECKEDBILLABLE, SET_CHECKEDNONBILLABLE, SET_CHECKEDUNRELEASED, SET_CHECKEDSTATE, SET_DATE, SET_ETHICALWALLUSERIDS, SET_TRANSFERFEATURENAME, RESET_TRANSFER_TE_STATUS } from "./ActionType";
export var transferTimeEntriesSuccessful = function transferTimeEntriesSuccessful() {
  return {
    type: TRANSFER_TE_SUCCESSFUL,
    data: {
      status: "successful",
      timeStamp: new Date()
    }
  };
};
export var transferTimeEntriesFailed = function transferTimeEntriesFailed() {
  return {
    type: TRANSFER_TE_FAILED,
    data: {
      status: "failed",
      timeStamp: new Date()
    }
  };
};
export var resetTransferTimeEntriesStatus = function resetTransferTimeEntriesStatus() {
  return {
    type: RESET_TRANSFER_TE_STATUS,
    data: {
      status: null,
      timeStamp: new Date()
    }
  };
};
export var setShowAttorneyListPopupFlag = function setShowAttorneyListPopupFlag(flag) {
  return {
    type: SET_SHOWATTORNEYLIST_POPUP_FLAG,
    data: flag
  };
};
export var setCheckedBillable = function setCheckedBillable(payload) {
  return {
    type: SET_CHECKEDBILLABLE,
    data: payload
  };
};
export var setCheckedNonBillable = function setCheckedNonBillable(payload) {
  return {
    type: SET_CHECKEDNONBILLABLE,
    data: payload
  };
};
export var setCheckedUnreleased = function setCheckedUnreleased(payload) {
  return {
    type: SET_CHECKEDUNRELEASED,
    data: payload
  };
};
export var setCheckedStateDayView = function setCheckedStateDayView(checkbillable, checknonbillable, checkunreleased, date) {
  return {
    type: SET_CHECKEDSTATE,
    data: {
      checkBillable: checkbillable,
      checkNonBillable: checknonbillable,
      checkUnreleased: checkunreleased,
      date: date
    }
  };
};
export var setDateSelectedDayView = function setDateSelectedDayView(date) {
  return {
    type: SET_DATE,
    data: date
  };
};
export var setEthicalWallUsers = function setEthicalWallUsers(userIds) {
  return {
    type: SET_ETHICALWALLUSERIDS,
    data: userIds
  };
};
export var setTransferFeatureName = function setTransferFeatureName(featureList) {
  return {
    type: SET_TRANSFERFEATURENAME,
    data: featureList
  };
};