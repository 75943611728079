import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import storage from "../../../utils/storage";
import {TOOLTIPS_KEYS,TOOLTIPS_VALUES} from "../../../config/vars";

export default function ProfilePasswordField({
  inputPassword,
  onChangePassword,
  placeholder
}) {
  const [field, setField] = useState(true);
  const [inputPwd, setInputPwd] = useState();
  const [tooltips,setTooltips]=useState({
    [TOOLTIPS_KEYS.TOOLTIP_SHOW_PASSWORD]:[TOOLTIPS_VALUES.SHOW_PASSWORD],
    [TOOLTIPS_KEYS.TOOLTIP_HIDE_PASSWORD]:[TOOLTIPS_VALUES.HIDE_PASSWORD]
  })

  useEffect(() => {
    setInputPwd(inputPassword);
  }, [inputPassword]);

  /** set tooltips from storage on screen load
  * @param literals -variale to store the array of literals
  */
 
  useEffect(()=>{
    let literals  = storage.getObject("literals");
    if(literals)
    {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_SHOW_PASSWORD]:literals[TOOLTIPS_KEYS.TOOLTIP_SHOW_PASSWORD],
        [TOOLTIPS_KEYS.TOOLTIP_HIDE_PASSWORD]:literals[TOOLTIPS_KEYS.TOOLTIP_HIDE_PASSWORD]    
      })
    }
  },[]);

  return (
    <>
      {field ? (
        <span className="p-input-icon-left signin-field">
            <InputText
              type="password"
              className="p-inputtext p-component p-password-input"
              value={inputPwd || ""}
              onChange={(event) => {
                const caret = event.target.selectionStart
                const element = event.target
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret
                  element.selectionEnd = caret
                })
                onChangePassword(event.target.value)
              }}
              placeholder={placeholder}
              autoComplete="new-password"
              data-testid="passwordField"
            />
            <img
              alt=""
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_SHOW_PASSWORD]}
              className="af-password-look"
              src="/images/svg/eye.svg"
              onClick={() => {
                setField(false);
              }}
            />
          </span>
      ) : (
        <span className="p-input-icon-left signin-field">
            <InputText
              type="text"
              className="p-inputtext p-component p-password-input"
              value={inputPwd || ""}
              onChange={(event) => {
                const caret = event.target.selectionStart
                const element = event.target
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret
                  element.selectionEnd = caret
                })
                onChangePassword(event.target.value)
              }}
              placeholder={placeholder}
              autoComplete="new-password"
              data-testid="passwordField"
            />
            <img
              alt=""
              className="af-password-hide"
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_HIDE_PASSWORD]}
              src="/images/svg/eye-cross.svg"
              onClick={() => {
                setField(true);
              }}
            />
        </span>
      )}
    </>
  );
}