import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "../../../services/authService";
import { resetAuthDetails } from "../../../actions/AuthAction";
import lmsUrlLinks from "../../../lms-data.json";
import "./MenuContent.scss";
import storage from "../../../utils/storage";
import { setSelectedTimeKeeperFeature } from "../../../actions/TimeManagementAction";
import { ResetReducers } from "../../../actions/GlobalAction";
import jwtDecoder from "jwt-decode";

function MenuContent({ items }) {
  let links = [];
  let history = useHistory();

  const goToTimeManagement = (url) => {
    if (url.toLowerCase().indexOf("conflictmanagement") !== -1) {
      return window.location.href = window.location.origin + url;
    }
    return history.push(url);
  };

  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [isModuleBlocked, setIsModuleBlocked] = useState(false);
  const [moduleAccessInfoMsg, setModuleAccessInfoMsg] = useState([]);
  const [moduleAccessInfo, setModuleAccessInfo] = useState({ "moduleAccessInfo": {} });
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };

  /** set tooltips from storage on screen load
  * @param literals -variale to store the array of literals
  */
  useEffect(() => {
    fetch('./client-codes.json', { cache: 'no-store' })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setModuleAccessInfo(data);
      checkModuleAccess(data); 
    })
    .catch(err => console.log("failed to client-codes file ",err));

    let literals = storage.getObject("literals");
    if (literals) {
      setCodeFromAPI(literals);
    }
  }, []);

  const contextIconPath = items && items.moduleGroupSmallIconURL;
  const contextIconName = contextIconPath && contextIconPath.split("/");
  const contextIcon =
    contextIconName && contextIconName[contextIconName.length - 1];
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  const icon = "/images/landing/" + contextIcon;
  if (items) {
    if (items.moduleGroupID === 1) {
      links = lmsUrlLinks.practice;
    } else if (items.moduleGroupID === 4) {
      links = lmsUrlLinks.analytics;
    } else if (items.moduleGroupID === 3) {
      links = lmsUrlLinks.finance;
    }
  }

  const openWindowInSameTab = (e, url, target) => {
    e.preventDefault();
    let newwindow = window.open(url, target);       
    AuthService.validateAccessToken().then(res => {      
      // if(newwindow) {
      //   newwindow.location.href = url;
      // }
      if (window.focus) {
        newwindow.focus();       
      }
    }).catch(err => {
      console.log("validate access token api call failed ",err);
    });
  }

  useEffect(() => {
    checkModuleAccess(moduleAccessInfo); 
    console.log("JSON data: ",JSON.stringify(moduleAccessInfo));
  },[items]);

  const checkModuleAccess = (data) => {
    let moduleName = items?.moduleGroupName?.toLowerCase();
    try {
       if(data?.moduleAccessInfo?.show) {
          
          let message = data?.moduleAccessInfo?.hiddenMessage;
          let clientIDs = data?.moduleAccessInfo?.show;
          if(moduleName === "mg-finance" || moduleName === "mg-practice" || moduleName === "mg-analytics") { 
            const decodedToken = jwtDecoder(localStorage.getItem("token"));
            const loggedInClientID = decodedToken["custom:clientid"];
            console.log("Client ID : ", loggedInClientID);
              if(clientIDs?.some(id => id 
                    && id.trim().toLowerCase() === (loggedInClientID !== "" && loggedInClientID.trim().toLowerCase()))) {
                  //module access will NOT be blocked as id present in json    
                  setIsModuleBlocked(false);       
                  setModuleAccessInfoMsg([]);
              } else {
                setIsModuleBlocked(true);
                setModuleAccessInfoMsg(message?.split('\n'));    
              }            
          } else {
            //blocking module not applicable for this module
            setIsModuleBlocked(false);
            setModuleAccessInfoMsg([]);
          } 
        } else {
            //blocking won't be applied and all modules will be visible because valid json is not available
            setIsModuleBlocked(false);
            setModuleAccessInfoMsg([]);
        }
    } catch (err) {
        console.log("failed to check module access info for module : "+moduleName+" ", err); 
        setIsModuleBlocked(false);
        setModuleAccessInfoMsg([]);     
    }
  };
  
  if(isModuleBlocked) {
    return (
      <>
        {moduleAccessInfoMsg && moduleAccessInfoMsg.map(line => <div>{line} </div> )} 
      </>
    );
  } else {
  return (
    <>
      <h2>
        {items && getCode(items.moduleGroupName)}
        <img
          src={icon}
          alt="circle"
          className="af-item-icon"
        ></img>
      </h2>

      {/* <p>{items.moduleGroupDescription}</p> */}
      <div className="af-twocolumns">
        <div className="af-twocolumns-child">
          <ul className="sub-menu">
            {items && items.modules && items.modules.length !== 0 ? (
              items.modules.map((childitems, index) => {
                if (childitems.moduleID === 24) {
                  return (
                    <li key={index}>
                      <span
                        onClick={() => {
                          AuthService.signOut(
                            AuthReducer.email,
                            localStorage.getItem("refreshToken")
                          ).then(() => {
                            dispatch(resetAuthDetails());
                            dispatch(setSelectedTimeKeeperFeature("", ""));
                            sessionStorage.removeItem("token");
                            storage.clearLocalStorge();
                            history.push("/");
                            dispatch(ResetReducers());
                          });
                        }}
                      >
                        {getCode(childitems.moduleName)}
                      </span>
                    </li>
                  );
                } else {
                  return (
                    <li key={index}>
                      <span onClick={(e) => {
                        let tokenUrl = `${childitems.url.indexOf("?") === -1 ? "?" : "&"}token=${localStorage.getItem("token")}&accesstoken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}`;
                        childitems.is_lms_link && childitems.is_lms_link.toLowerCase() === "y" ?
                          (childitems.moduleName.toLowerCase() === 'financials' ?
                            openWindowInSameTab(e, (childitems.url).trim(), childitems.url.split('//')[1].split('.')[0]) :
                            openWindowInSameTab(e, (childitems.url).trim() + tokenUrl, childitems.url.split('//')[1].split('.')[0]))
                          : goToTimeManagement(childitems.url)
                      }
                      }>
                        {getCode(childitems.moduleName)}
                      </span>
                    </li>
                  );
                }
              })
            ) : (
              <></>
            )}
            {links.map((childitems, index) => {
              if (index < 10) {
                return (
                  <li key={index}>
                    <span
                      onClick={(evt) =>
                        window.open(childitems.url, childitems.target)
                      }
                    >
                      {getCode(childitems.name)}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div className="af-twocolumns-child">
          <ul className="sub-menu">
            {links.map((childitems, index) => {
              if (index > 9) {
                return (
                  <li key={index}>
                    <span
                      onClick={(evt) =>
                        window.open(childitems.url, childitems.target)
                      }
                    >
                      {getCode(childitems.name)}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </>
  )
};
}

export default MenuContent;
