import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { baseUrl } from "../config";
import { HeaderView } from "../config/literalCodes";
import jsUtils from "../utils/jsUtils";
import { Fetch } from "./fetch";
var pathName = "/core/attorney/api/v1/timeentry";
export var monthViewApi = {
  monthView: function monthView(payload) {
    return Fetch(pathName + "/month", _objectSpread({}, payload), "post", "monthView", true, true, null, baseUrl, jsUtils.getHeader(null, HeaderView.MonthViewApiCallHeaderName, "monthView"));
  },
  budget: function budget(payload) {
    return Fetch(pathName + "/month/budget", _objectSpread({}, payload), "post", "budget", true, true);
  },
  graph: function graph(payload) {
    return Fetch(pathName + "/month/graph", _objectSpread({}, payload), "post", "graph", true, true);
  }
};