import React, { useEffect, useState , useRef } from "react";
import { Toast } from "primereact/toast";
import "../../../../../Account/Preferences/PreferencesPracticeGrid.scss"
import "./GeneralScreen.scss"
import Spinner from "../../../../../common/Spinner/Spinner";
import jsUtils from "../../../../../../utils/jsUtils";
import { setErrorMessage } from "../../../../../../actions/AppAction";
import { useSelector, useDispatch } from "react-redux";
import { setUnsavedChanges } from "../../../../../../actions/ConfirmationAction";
import { Preferences } from "../../../../../../services/PreferencesApi";
import { setPreferenceSettingsAction } from "../../../../../../actions/PreferenceAction";
import { setRoundingMethod } from "../../../../../../actions/TimeManagementAction";
import { setPageHelpLink } from "../../../../../../actions/LandingPageAction";
import { helpPageUrl } from "../../../../../../config";
import { HELP_LINKS } from "../../../../../../config/vars";

export default function GeneralScreen(props) {
    const [value, setValue] = useState("NORMAL");
    const [loader, setLoader] = useState(true);
    const [changedValueMap, setChangedValueMap] = useState([]);
    const [initialValueMap, setInitialValueMap] = useState([]);

 
    const dispatch = useDispatch();
    const toast = useRef(null);

    const preferenceValueFromApi = useSelector(
      (state) =>
        state.PreferenceReducer && state.PreferenceReducer.preferenceApiData
    );

    useEffect(() => {
    dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.SYSTEM_CONFIG_GENERAL}`))
  }, []);

    useEffect(() => {
      if (props.retainTemporaryData) {
        setValue(props.retainTemporaryData.timerRounding);
        setLoader(false);
        setChangedValueMap(props.retainTemporaryData);
      } else {
        Preferences.get()
          .then((res) => {
            setLoader(false);
            dispatch(setPreferenceSettingsAction(res));
            let valueRounding = res.preferencesData.find(
              (item) => item.param_key === "BILLABLE_DURATION_ROUNDING_METHOD"
            );
            valueRounding && setValue(valueRounding.param_value);
            props.onRetainData({
              timerRounding: valueRounding.param_value
            });
          })
          .catch((err) => {
            setLoader(false);
            dispatch(
              setErrorMessage({
                severity: "error",
                message: err && jsUtils.getLiteralValue(err.message),
                errorCode: err.responseCode,
              })
            );
          });
      }
    }, [props.retainTemporaryData]);

    useEffect(() => {
      if (preferenceValueFromApi) {
        let valueRounding = preferenceValueFromApi.find(
          (item) => item.param_key === "BILLABLE_DURATION_ROUNDING_METHOD"
        );
       
        setInitialValueMap([
          valueRounding && valueRounding.param_value
        ]);
       
      }
    }, [preferenceValueFromApi]);

    useEffect(() => {
      let changeDetection =
        initialValueMap &&
        (initialValueMap[0] ===
          (changedValueMap && changedValueMap.timerRounding)
        );
      if (changeDetection) {
        dispatch(setUnsavedChanges(false));
      }
    }, [changedValueMap, initialValueMap]);

    const generateDynamicPayload = () => {

      let createPayload = [];

      if (
        initialValueMap &&
        initialValueMap[0] !== (changedValueMap && changedValueMap.timerRounding)
      ) {
        createPayload.push({
          param_key: "BILLABLE_DURATION_ROUNDING_METHOD",
          param_value: value,
          preference_menu_id: props.practiceObj,
        });
      }
      return createPayload;
    };
    const saveTimerRounding = () => {
      setLoader(true);
  
      let payload = {
        params: generateDynamicPayload(),
      };
      Preferences.post(payload)
        .then((res) => {
          dispatch(setUnsavedChanges(false));
          setLoader(false);
          dispatch(setRoundingMethod(value));
          dispatch(
            setErrorMessage({
              severity: "success",
              message: res && jsUtils.getLiteralValue(res.message),
              errorCode: res.responseCode,
            })
          );
          // props.onRetainData(value)
          dispatch(Preferences.getPreferenceSettings());
        })
        .catch((err) => {
          setLoader(false);
          dispatch(
            setErrorMessage({
              severity: "error",
              message: err && jsUtils.getLiteralValue(err.message),
              errorCode: err.responseCode,
            })
          );
        });
    };

    const resetScreen=()=>{
      setValue(initialValueMap[0]);
      dispatch(setUnsavedChanges(false));
      setChangedValueMap({timerRounding:initialValueMap[0]})
    }
   
    const onRadiobuttonSelection = (selectedValue) => {
      dispatch(setUnsavedChanges(true));
      setValue(selectedValue)
     // dispatch(setAfterSavingPreferenceAction(selectedValue));
     props.onRetainData({
      ...props.retainTemporaryData,
      timerRounding: selectedValue,
    });
    };


  return (
    <div className="General-screen">
    <div className="loader-class ">{loader ? <Spinner /> : ""}</div>
      <Toast ref={toast} />
      <div className="General-grid">
      <div className="pref-data">
      <div className="pref-title">{jsUtils.getLiteralValue("TIME_MANAGEMENT")}</div>
      <div className="pref-data-rows">
        <div className="pref-row-practice"></div>
         <TimerRadioButtons onRadiobuttonSelection={onRadiobuttonSelection}  afterSavingValue={value}/>
         </div>
         </div>
         <div className="btn-pannel">
            {!(
              initialValueMap &&
              ( initialValueMap[0] ===
                (changedValueMap && changedValueMap.timerRounding) )
            ) && (
              <button
                className="btn-cancel"
                onClick={() => { resetScreen()}
                }>
                {jsUtils.getLiteralValue("CANCEL")}
              </button>
            )}
            <button
              className="btn-save"
              onClick={() => {
                saveTimerRounding();
              }}
              disabled={
                initialValueMap &&
                ( initialValueMap[0] === (changedValueMap && changedValueMap.timerRounding) )
              }>
              {jsUtils.getLiteralValue("SAVE")}
            </button>
          </div>
        
      </div>

    </div>
    
  );
}


const TimerRadioButtons = ({
  afterSavingValue,
  onRadiobuttonSelection,
})=>{

  return(
    <div className="pref-row-new">
    <div className="row-title">{jsUtils.getLiteralValue("TIMER_ROUNDING")}</div>
    <div className="row-radio-btn">
      <input
        onChange={() => {
          onRadiobuttonSelection("UP")
        }}
        type="radio"
        value="UP"
        name="rounding"
        checked={afterSavingValue === "UP" ? true : false}
        autocomplete="off"
      />
      <div className="row-radio-element"> {jsUtils.getLiteralValue("UP")} </div>
      <input
         onChange={() => {
          onRadiobuttonSelection("DOWN")
        }}
        type="radio"
        value="DOWN"
        name="rounding"
        checked={afterSavingValue === "DOWN" ? true : false}
        autocomplete="off"
      />{" "}
      <div className="row-radio-element"> {jsUtils.getLiteralValue("DOWN")}</div>
      <input
         onChange={() => {
          onRadiobuttonSelection("NORMAL")
        }}
        type="radio"
        value="NORMAL"
        name="rounding"
        checked={afterSavingValue === "NORMAL" ? true : false}
        autocomplete="off"
      />{" "}
      <div className="row-radio-element">
        {" "}
        {jsUtils.getLiteralValue("NORMAL")}
      </div>
    </div>
  </div>

  );
}