import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import Spinner from "../../../common/Spinner/Spinner";
import { Calendar } from "primereact/calendar";
import "./EditUser.scss";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { userApi } from "../../../../services/userapis";
import moment from "moment";
import RadioButton from "../../../common/RadioButton/RadioButton";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from "lodash";
import storage from "./../../../../utils/storage";
import {
  HELP_LINKS,
  TOOLTIPS_KEYS,
  TOOLTIPS_VALUES,
} from "../../../../config/vars";
import { setErrorMessage } from "../../../../actions/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { helpPageUrl } from "../../../../config";
import jsUtils from "../../../../utils/jsUtils";
import validationUtils from "../../../../utils/validationUtils";
import Avatar from "react-avatar";

let initialUserFormState =
{
  "username": "",
  "employee_id": null,
  "firstname": "",
  "lastname": "",
  "sys_firm_id": 1,
  "enabled": "yes",
  "effective_start_date": null,
  "effective_end_date": null,
  "email": "",
  "mobileno": "",
  "middlename": "",
  "preferredname": "",
  "lms_security_profile": null,
  "attorney_code": null,
  "sys_staff_id": null,
  "sys_office_id": null,
  "sys_department_id": null,
  "billing_staff_id": null,
  "gl_sub_account": null,
  "is_timekeeper": false
};

let initialSelectedValuesState =
{
  "security_profile_val": "Blank",
  "office_val": "Blank",
  "department_val": "Blank",
  "reporting_staff_val": "Blank",
  "billing_staff_val": "Blank",
  "gl_sub_account_val": "Blank"
};
function EditUser(props) {
  const [TimeDialog, setTimeDialog] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [specUserLoading, setSpecUserLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [timeKeeper, setTimeKeeper] = useState(false);
  const [usernameStatus, setUsernameStatus] = useState(false);
  const [userStartDate, setUserStartDate] = useState(null);
  const [userEndDate, setUserEndDate] = useState(null);
  const [notes, setNotes] = useState("");
  const [userRolesForCopy, setUserRolesForCopy] = useState([]);
  const [copyRole, setCopyRole] = useState("");
  const [updateRole, setUpdateRoles] = useState([]);
  const [attornyRoles, setAttornyRoles] = useState([]);
  const [attornyRolesBackup, setAttornyRolesBackup] = useState([]);
  const [copyStatus, setCopyStatus] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(0);
  const [ifEndDateChanged, setIfEndDateChanged] = useState(false);
  const [addUpdate, setAddUpdate] = useState(true);
  const [sysUserPersonaId, setSysUserPersonaId] = useState();
  const toast = useRef(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [isEditRole, setIsEditRole] = useState(false);
  const [changed, setChanged] = useState(false);
  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const [avatarName, setAvatarName] = useState("");
  const [avatarImg , setAvatarImg] = useState(null);
  const [sysPersonaId, setSysPersonaId] = useState(null);
  const dialogFuncMapClose = {
    closeConfirmation: setCloseConfirmation,
  };
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: [TOOLTIPS_VALUES.SELECT_DATE],
    [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: [TOOLTIPS_VALUES.SEARCH],
    [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: [TOOLTIPS_VALUES.CLOSE],
    [TOOLTIPS_KEYS.TOOLTIP_EDIT]: [TOOLTIPS_VALUES.EDIT],
    [TOOLTIPS_KEYS.TOOLTIP_SET_AS_TIMEKEEPER]: [
      TOOLTIPS_VALUES.SET_AS_TIMEKEEPER,
    ],
    [TOOLTIPS_KEYS.TOOLTIP_REMOVE_AS_TIMEKEEPER]: [
      TOOLTIPS_VALUES.REMOVE_AS_TIMEKEEPER,
    ],
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP],
  });
  const dispatch = useDispatch();
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [traverseState, setTraverseState] = useState(null);
  const [DBCurrentDate, setDBCurrentDate] = useState("");
  const [userFormState, setUserFormState] = useState(initialUserFormState);
  const [selectedValuesState, setSelectedValuesState] = useState(initialSelectedValuesState);
  const [formErrorMessages, setFormErrorMessages] = useState([{ data: "" }]);
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);
  const [editRule, setEditRule] = useState(false);

  /** set tooltips from storage on screen load
   * @param literals -variale to store the array of literals
   */

  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]:
          literals[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE],
        [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: literals[TOOLTIPS_KEYS.TOOLTIP_SEARCH],
        [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: literals[TOOLTIPS_KEYS.TOOLTIP_CLOSE],
        [TOOLTIPS_KEYS.TOOLTIP_EDIT]: literals[TOOLTIPS_KEYS.TOOLTIP_EDIT],
        [TOOLTIPS_KEYS.TOOLTIP_SET_AS_TIMEKEEPER]:
          literals[TOOLTIPS_KEYS.TOOLTIP_SET_AS_TIMEKEEPER],
        [TOOLTIPS_KEYS.TOOLTIP_REMOVE_AS_TIMEKEEPER]:
          literals[TOOLTIPS_KEYS.TOOLTIP_REMOVE_AS_TIMEKEEPER],
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP],
      });
      setCodeFromAPI(literals);
    }
    document.title = jsUtils.getLiteralValue(props.pageTitle)
    let currentDate = jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setDBCurrentDate(currentDate);
    // setDatePlusOne(new Date(currentDate));    

  }, []);

  // Enabled save when change is detected, default disabled
  // useEffect(() => {
  //   changed ? setSaveButtonDisable(false) : setSaveButtonDisable(true);
  // }, [changed])

  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };

  const renderFooterClose = () => {
    return (
      <div>
        <Button
          label={getCode("LEAVE")}
          onClick={() => {
            if (traverseState) {
              setCloseConfirmation(false);
              setChanged(false)
              traverseState === "Down" ? traverseDown() : traverseUp()
            }
            else {
              props.closed();
              setTimeDialog(false);
            }
          }}
          className="p-button-text"
        />
        <Button
          label={getCode("STAY")}
          onClick={() => {
            setTraverseState(null);
            setChanged(true)
            setCloseConfirmation(false);
          }}
          autoFocus
        />
      </div>
    );
  };

  const onHideClose = (name) => {
    dialogFuncMapClose[`${name}`](false);
  };

  // Get user data, roles data
  useEffect(() => {
    loadUserData();
    if (props.action === "editUser") {
      getSpecificUser();      
      getRoles(props.selectedRowData);
    } else if (props.action === "addUser") {
      getRoles();
    }
  }, [props.selectedRowData]);

  /** 
  * @description Get all the roles assigned to user 
  */

  const getRoles = (userData) => {
    setRoleLoading(true);
    userApi
      .validRoles(userData && userData.user_id)
      .then((res) => {
        let formattedJson = { data: [] };
        if (res && res.personaData) {
          res.personaData.forEach((persona) => {
            let clientItem = {
              rowId: persona.sys_persona_id,
              persona_id: persona.sys_persona_id,
              name: persona.name,
              description: persona.description,
              label: persona.name,
              value: persona.name,
              role_start_date: persona.effective_start_date,
              role_end_date: persona.effective_end_date
            };
            formattedJson.data.push(clientItem);
          });
          setAttornyRoles(formattedJson.data);
          //Backup is used for set the cleared data again and while copying role operation
          setAttornyRolesBackup(formattedJson.data);
        }
      })
      .catch((err) => {
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
      })
      .finally(() => {
        setRoleLoading(false);
      });
  };

  const hideDialog = () => {
    if (changed) {
      setTraverseState(null);
      setCloseConfirmation(true);
    } else {
      props.closed();
      setTimeDialog(false);

    }
  };

  const [rolesSdate, setrolesSdate] = useState(null);

  useEffect(() => {
    setTimeDialog(props.show);
  }, [props.show]);

  const [rolesEdate, setrolesEdate] = useState(null);
  /** 
  * @description Loads company name to show on the edit screen
  */

  const loadUserData = () => {
    // set to loading
    setUserLoading(true);

    userApi
      .getUserDetails()
      .then((res) => {
        if (
          res &&
          res.userData &&
          res.userData.user &&
          res.userData.user.sys_firm_name
        ) {
          setCompanyName(res.userData.user.sys_firm_name);
        }
      })
      .catch((err) => {
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
        setUserLoading(false);
      })
      .finally(() => {
        setUserLoading(false);
      });
  };

  /** 
  * @description Loads all the user data and roles data show on the edit screen
  */

  const getSpecificUser = () => {
    // set to loading
    setSpecUserLoading(true);

    userApi
      .getSpecificUser(props.selectedRowData && props.selectedRowData.user_id)
      .then((res) => {
        if (res && res.userData && res.userData.user) {
          let _user = res.userData.user;
          //Edit User
          if (props.action !== "addUser" && _user) {
            setUserFormState(state => ({
              ...state,
              username: props.selectedRowData && props.selectedRowData.username,
              employee_id: _user.employee_id || "",
              lastname: _user.lastname,
              firstname: _user.firstname,
              middlename: _user.middle_name,
              preferredname: _user.preferred_name,
              email: _user.email,
              mobileno: _user.mobile
            }));
          }

          let sDate = res.userData.user.effective_start_date;
          let stDate = "";
          if (isValidDate(sDate)) {
            stDate = sDate.substring(0, 10).split("-");
            stDate = stDate[1] + "/" + stDate[2] + "/" + stDate[0];
          }
          setUserStartDate(new Date(stDate));
          setRoleStartDateValidation(new Date(stDate));

          let endDate = res.userData.user.effective_end_date;
          let edDate = "";
          if (isValidDate(endDate)) {
            edDate = endDate.substring(0, 10).split("-");
            edDate = edDate[1] + "/" + edDate[2] + "/" + edDate[0];
          }

          setUserEndDate(new Date(edDate));
          setRoleEndDateValidation(new Date(edDate));
          setTimeKeeper(res.userData.user.is_timekeeper ? true : false);
          setNotes(res.userData.user.notes ? res.userData.user.notes : "");

          let firstName = res.userData.user.firstname;
          let lastName = res.userData.user.lastname;
          setAvatarName(firstName + " " + lastName);
          setAvatarImg(res.userData.user.user_profile_pic_s3_url)
          // settiing this value = true as Both Reset and timekepper buttons to make disable as per requirement
          res.userData.user.username
            ? setUsernameStatus(false)
            : setUsernameStatus(true);
        }
        if (res && res.userData && res.userData.roles) {
          res.userData.roles.forEach((item)=>{
           item["oldRule"]=true;
          })
          setUpdateRoles(res.userData.roles);
        }
      })
      .catch((err) => {
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
        setSpecUserLoading(false);
      })
      .finally(() => {
        setSpecUserLoading(false);
      });
  };

  const getUpdateRole = (roles) => {
    let formattedJson = { role: [] };
    roles.sort(function (a, b) {
      if (a.Role === b.Role) {
        return 0;
      }
      if (typeof a.Role === typeof b.Role) {
        return a.Role < b.Role ? -1 : 1;
      }
      return typeof a.Role < typeof b.Role ? -1 : 1;
    })
      .sort(function (a, b) {
        return new Date(moment(b.From, "YYYY-MM-DD").format("YYYY-MM-DD")) - new Date(moment(a.From, "YYYY-MM-DD").format("YYYY-MM-DD"));
      })
      .map((role, i) => {
        let fromDateStatus = isValidDate(role.From);
        let toDateStatus = isValidDate(role.To);
        let editEnable = true;
        if (toDateStatus) {
          let toDateF = moment(
            role.To,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD");
          let today = moment().format("YYYY-MM-DD");
          editEnable = moment(
            new Date(toDateF)
          ).isSameOrAfter(today);
        }

        let fromDate = fromDateStatus ? moment(role.From, "YYYY-MM-DD").format("MM/DD/YYYY") : "";
        let toDate = toDateStatus ? moment(role.To, "YYYY-MM-DD").format("MM/DD/YYYY") : "";

        let roleItem = {
          index: i,
          Role: role.Role,
          RoleStartDate: role.RoleStartDate,
          RoleEndDate: role.RoleEndDate,
          FromDate: fromDateStatus ? fromDate : "",
          ToDate: toDateStatus ? toDate : "",
          From: role.From,
          To: role.To,
          operation: role.operation,
          sys_persona_id: role.sys_persona_id,
          sys_user_persona_id: role.sys_user_persona_id,
          editEnable: editEnable,
          oldRule:role.oldRule
        }

        formattedJson.role.push(roleItem);
      })
    return formattedJson.role;
  }

  const saveNewUser = () => {
    let payload = userFormState;
    let formattedJson = { data: [] };
    if (updateRole) {
      updateRole.forEach((role) => {
        let fromDateStatus = isValidDate(role.From);
        let toDateStatus = isValidDate(role.To);
        let clientItem = {
          sys_persona_id: role.sys_persona_id,
          effective_start_date: fromDateStatus
            ? moment(role.From, "YYYY-MM-DD").format("YYYY-MM-DD")
            : "",
          effective_end_date: toDateStatus
            ? moment(role.To, "YYYY-MM-DD").format("YYYY-MM-DD")
            : ""
        };
        formattedJson.data.push(clientItem);
      });
    }

    let addEndTimeDateStatus = isValidDate(userEndDate);

    let updateDate = {};

    if (ifEndDateChanged) {
      updateDate = {
        timekeeper: timeKeeper ? 1 : 0,
        effective_end_date: addEndTimeDateStatus
          ? moment(userEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "",
        sys_user_id: props.selectedRowData.user_id,
        roles: formattedJson && formattedJson.data,
      };
    }
    else {
      updateDate = {
        timekeeper: timeKeeper ? 1 : 0,
        sys_user_id: props.selectedRowData.user_id,
        notes: notes,
        roles: formattedJson && formattedJson.data,
      };
    }

    payload = {
      ...payload,
      mobileno: userFormState["mobileno"] && userFormState["mobileno"].replace(/\D/g, ''),
      effective_start_date: userFormState["effective_start_date"] ? moment(userFormState["effective_start_date"], "YYYY-MM-DD").format("YYYY-MM-DD") : null,
      effective_end_date: userFormState["effective_end_date"] ? moment(userFormState["effective_end_date"], "YYYY-MM-DD").format("YYYY-MM-DD") : null,
      roles: updateDate.roles
    }
    saveNewUserApi(payload);
  }

  /**
   * @description - Send data to backend to update info
   */
  const saveNewUserApi = (savePayload) => {
    setSaving(true);
    userApi
      .addNewUser(savePayload)
      .then((res) => {
        setFormErrorMessages([{ data: "" }]);
        setChanged(false);
        dispatch(
          setErrorMessage({
            severity: "success",
            message: getCode("USER_ADDED_SUCCESSFULLY"),
            errorCode: res.responseCode,
          })
        );
        props.bulkEdit && traverseDown(true)
        !props.bulkEdit && setTimeout(() => {
          props.closed();
          setTimeDialog(false);

        }, 3000);
      })
      .catch((err) => {
        console.log("save new user failed: ", err);
        setFormErrorMessages(err && err.message);
        let msg;
        if (Array.isArray(err.message)) {
          msg = getCode("USER_CREATION_FAILED");
        } else {
          msg = err.message;
        }

        setChanged(false)
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(msg),
            errorCode: err.responseCode,
          })
        );
      })
      .finally(() => {
        setSaving(false);
      });
  };

  /** 
  * @description save user data and role date prepaired
  */
  const updateUser = () => {

    let formattedJson = { data: [] };
    if (updateRole) {
      updateRole.forEach((role) => {
        let fromDateStatus = isValidDate(role.From);
        let toDateStatus = isValidDate(role.To);

        if (role.operation === "update") {
          let clientItem = {
            sys_persona_id: role.sys_persona_id,
            effective_start_date: fromDateStatus
              ? moment(role.From, "YYYY-MM-DD").format("YYYY-MM-DD")
              : "",
            effective_end_date: toDateStatus
              ? moment(role.To, "YYYY-MM-DD").format("YYYY-MM-DD")
              : "",
            operation: role.operation,
            sys_user_persona_id: role.sys_user_persona_id,
          };
          formattedJson.data.push(clientItem);
        } else if (role.operation === "create") {
          let clientItem = {
            sys_persona_id: role.sys_persona_id,
            effective_start_date: fromDateStatus
              ? moment(role.From, "YYYY-MM-DD").format("YYYY-MM-DD")
              : "",
            effective_end_date: toDateStatus
              ? moment(role.To, "YYYY-MM-DD").format("YYYY-MM-DD")
              : "",
            operation: role.operation,
          };
          formattedJson.data.push(clientItem);
        }
      });
    }

    let addEndTimeDateStatus = isValidDate(userEndDate);

    let updateDate = {};

    if (ifEndDateChanged) {
      updateDate = {
        timekeeper: timeKeeper ? 1 : 0,
        effective_end_date: addEndTimeDateStatus
          ? moment(userEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "",
        sys_user_id: props.selectedRowData.user_id,
        notes: notes,
        roles: formattedJson.data,
      };
    }
    else {
      updateDate = {
        timekeeper: timeKeeper ? 1 : 0,
        sys_user_id: props.selectedRowData.user_id,
        notes: notes,
        roles: formattedJson.data,
      };
    }
    updateUserApi(updateDate);
  };

  /**
   * @description - Send data to backend to update info
   */
  const updateUserApi = (updateDate) => {
    setSaving(true);
    setSaveButtonDisable(true);
    userApi
      .updateUser(updateDate)
      .then((res) => {
        setChanged(false)
        dispatch(
          setErrorMessage({
            severity: "success",
            message: res && getCode(res.message),
            errorCode: res.responseCode,
          })
        );
        props.bulkEdit && traverseDown(true)
        !props.bulkEdit && setTimeout(() => {
          props.closed();
          setTimeDialog(false);

        }, 3000);
      })
      .catch((err) => {
        setChanged(false)
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
      })
      .finally(() => {
        setSaving(false);
      });
  };
  let selectedUser = { id: "", description: "", label: "" };

  const [user, setUser] = useState({
    field: "users",
    isDisplay: true,
    isMandatory: true,
    displayLabel: "Users",
    values: [
      {
        id: selectedUser.id,
        label: selectedUser.label,
        desc: selectedUser.description,
        isDefault: true,
      },
    ],
  });

  /**
   * @description - User select a another user in "Apply same role as" to copy role from 
   * @param {String} value - selected role value set as label and disc
   * @param {String} field - users
   */

  const onClientSelection = (field, value) => {
    if (value) {
      setUser({
        field: "users",
        isDisplay: true,
        isMandatory: true,
        displayLabel: "Users",
        values: [
          {
            id: value.id,
            label: value.label,
            desc: value.description,
          },
        ],
      });
      setCopyRole();
      setrolesSdate();
      setrolesEdate();
    }

    getUserRolesForCopy(value.id);
    setCopyStatus(true);
  };

  async function getUserRolesForCopy(userid) {
    setSaving(true);
    userApi
      .getSpecificUser(userid)
      .then((data) => {
        let formattedJson = { data: [] };
        if (data && data.userData && data.userData.roles) {
          data.userData.roles.forEach((role) => {
            let clientItem = {
              label: role.Role,
              value: role.Role,
              Role: role.Role,
              From: role.From,
              To: role.To,
              Enabled: role.Enabled,
              sys_persona_id: role.sys_persona_id,
              operation: "create",
              RoleStartDate: role.RoleStartDate,
              RoleEndDate: role.RoleEndDate
            };
            formattedJson.data.push(clientItem);
          });

          //Remove expired roles from list to copy
          let today = moment().format("YYYY-MM-DD");
          let activeRoleList = formattedJson.data.filter(function (item) {
            if (isValidDate(item.To)) {
              let toDate = moment(item.To, "YYYY-MM-DD").format("YYYY-MM-DD");
              let status = moment(toDate).isSameOrAfter(today);
              return status;
            }
            else {
              return true;
            }
          });
          setUserRolesForCopy(activeRoleList);
        }
      })
      .catch((err) => {
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
      })
      .finally(() => {
        setSaving(false);
      });
  }

  /**
  * @description - Check if date is valid (like not empty/undefined/null)
  *  @param {Object} date - date for validation
  */
  const isValidDate = (date) => {
    let status = moment(
      moment(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      "DD/MM/YYYY",
      true
    ).isValid();
    return status;
  };

  /**
  * @description - Roles added into role grid after add role action, all the validation 
  */

  const addRole = () => {
    setError("");
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    if (copyStatus) {
      let newRoleList = [];
      if (userRolesForCopy.length === 0) {
        setError("No valid roles found");
        return
      }
      else if (updateRole && updateRole.length > 0) {
        newRoleList = userRolesForCopy.filter((role) => {
          let ifRoleExistAlready = _.find(updateRole, { Role: role.Role });
          if (ifRoleExistAlready) {
            let oldToDate = moment(ifRoleExistAlready.To, "YYYY-MM-DD").format("YYYY-MM-DD");
            let today = moment().format("YYYY-MM-DD");
            let futureDate = moment(oldToDate).isAfter(today);
            if (futureDate || !isValidDate(oldToDate)) {
              return false;
            }
            else {
              // let newFromDate = moment(role.From, "YYYY-MM-DD").format("YYYY-MM-DD");
              return moment(today).isAfter(oldToDate);
            }
          }
          else {
            return true;
          }
        });
      }
      else {
        newRoleList = userRolesForCopy;
      }

      setIsEditRole(false);
      newRoleList.forEach((role) => {
        let stDate = role.RoleStartDate.substring(0, 10).split("-");
        let today = moment().format("YYYY-MM-DD");
        let roleStartDate = stDate[0] + "/" + stDate[1] + "/" + stDate[2];
        let isTodayGreater = moment(today).isSameOrAfter(roleStartDate);
        role.From = isTodayGreater ? today : moment(stDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        role.To = "";
      });
      setUpdateRoles((prevState) => [...prevState, ...newRoleList]);
      setAttornyRoles("");
      setAttornyRoles(attornyRolesBackup);
      setrolesSdate();
      setrolesEdate();
      setCopyRole();
      setAddUpdate(true);
      setCopyStatus(false);
      setSysUserPersonaId();
      setRoleMinDateForEndDate(new Date(currentDate));
      setRoleStartDateValidation(null);
      setRoleEndDateValidation(new Date(currentDate));
      setRoleMaxStartDateValidation(null);
      setSaveButtonDisable(false);
    } else {
      let isValidStarDate = isValidDate(rolesSdate);
      if (isValidStarDate) {
        let startDate = moment(rolesSdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let endDate = moment(rolesEdate, "YYYY-MM-DD").format("YYYY-MM-DD");

        let isEndDGreater = moment(endDate).isAfter(startDate);
        let ifEndDValid = isValidDate(endDate);

        if (addUpdate) {
          if (rolesSdate === null) {
            setError(getCode("ENTER_START_DATE"));
            return;
          }
          if (!isEndDGreater && ifEndDValid) {
            setError(getCode("START_DATE_SHOULD_BE_PRIOR_TO_END_DATE"));
            return;
          }
        }

        let ifRoleExist = _.find(updateRole, { Role: copyRole });

        if (ifRoleExist && !update) {
          let toDateStatus = isValidDate(ifRoleExist.To);

          let newStartDate = moment(rolesSdate, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          );

          if (!toDateStatus) {
            setError(getCode("ROLE_ALREADY_EXISTS"));
            return;
          } else if (toDateStatus) {
            let toDate = moment(ifRoleExist.To, "YYYY-MM-DD").format("YYYY-MM-DD");
            let newDateGreater = true;
            if (isValidDate(toDate)) {
              newDateGreater = moment(newStartDate).isSameOrAfter(toDate);
            }
            if (newDateGreater === false) {
              setError(getCode("ROLE_ALREADY_EXISTS"));
              return;
            }
          } else {
            setError("");
          }
        }
        let personaId = null;
        attornyRoles.forEach((role) => {
          if (role.name === copyRole) {
            personaId = role.persona_id;
          }
        });
        let newRole = undefined;
        if (copyRole) {
          if (update) {
            newRole = {
              Role: copyRole,
              From: isValidDate(rolesSdate) ? rolesSdate : "",
              To: isValidDate(rolesEdate) ? rolesEdate : "",
              sys_persona_id: sysPersonaId,
              sys_user_persona_id: sysUserPersonaId,
              operation: update && sysUserPersonaId ? "update" : "create",
              RoleStartDate: updateRole[updateIndex].RoleStartDate,
              RoleEndDate: updateRole[updateIndex].RoleEndDate
            };
          }
          else {
            newRole = {
              Role: copyRole,
              From: isValidDate(rolesSdate) ? rolesSdate : "",
              To: isValidDate(rolesEdate) ? rolesEdate : "",
              sys_persona_id: personaId,
              sys_user_persona_id: sysUserPersonaId,
              operation: update && sysUserPersonaId ? "update" : "create",
              RoleStartDate: moment(roleStartDateValidation, "YYYY-MM-DD").format("YYYY-MM-DD"),
              RoleEndDate: moment(roleEndDateValidation, "YYYY-MM-DD").format("YYYY-MM-DD")
            };
          }
        }

        if (update) {
          setIsEditRole(false);
          setEditRule(false)
          setUpdate(false);
          setUpdateRoles((prevState) => {
            let role = [...prevState];
            role[updateIndex] = newRole;
            return role;
          });
          
        } else {
          setIsEditRole(false);
          if (newRole) {
            setUpdateRoles((prevState) => [...prevState, newRole]);
          }
        }
        setAttornyRoles("");
        setAttornyRoles(attornyRolesBackup);
        setrolesSdate();
        setrolesEdate();
        setCopyRole();
        setAddUpdate(true);
        setSysUserPersonaId();
        setRoleStartDateStatus(true);
        setRoleEndDateStatus(true);
        setRoleMinDateForEndDate(new Date(currentDate));
        setRoleStartDateValidation(null);
        setRoleEndDateValidation(new Date(currentDate));
        setRoleMaxStartDateValidation(null);
        setSaveButtonDisable(false);
      } else {
        setError(getCode("ENTER_START_DATE"));
      }
    }
  };

  /**
   * @description - Edit role click operation
   * @param {Object} role - Selected role for edit
   * @param {number} i - index 
   */

  const editRole = (role) => {
    if (role !== undefined) {
      setIsEditRole(true);
      let newRole = role.Role ? role.Role : "";
      let fromDate = role.From ? role.From : "";
      let endDate = role.To ? role.To : "";
      let userPersonaId = role.sys_user_persona_id
        ? role.sys_user_persona_id
        : "";
      role.sys_persona_id
        ? setSysPersonaId(role.sys_persona_id)
        : setSysPersonaId(null);
      let fromDateF = moment(fromDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      let endDateF = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD");

      let endDateStatus = isValidDate(endDate);

      let today = moment(DBCurrentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
      let isTodayGreaterOrSame = moment(fromDateF).isAfter(today);
      let isTodayGreaterOrSameEdate = moment(endDateF).isAfter(today);

      setSysUserPersonaId(userPersonaId);
      setAttornyRoles((prevState) => [...prevState, newRole]);
      setCopyRole(newRole);

      // Get min start date validation for edit role
      if (isValidDate(role.RoleStartDate)) {
        getMinRoleStartDateForEdit(role.RoleStartDate);
      }

       // Get min start date validation for edit role
      if(isValidDate(role.RoleEndDate) && isValidDate(userEndDate)){
        let _accessRoleEndDate = moment(role.RoleEndDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        let _userEndDate = moment(userEndDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        let _userEndDateIsLess = moment(_accessRoleEndDate).isAfter(_userEndDate);
        if(_userEndDateIsLess){
          setRoleEndDateValidation(new Date(userEndDate));
        }
        else{
          setRoleEndDateValidation(new Date(jsUtils.getTimeZoneFormatDate(_accessRoleEndDate)));
        }
      }
      else if(isValidDate(role.RoleEndDate)){
        let _accessRoleEndDate = moment(role.RoleEndDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        setRoleEndDateValidation(new Date(jsUtils.getTimeZoneFormatDate(_accessRoleEndDate)));
      }
      else if(isValidDate(userEndDate)){
        setRoleEndDateValidation(new Date(userEndDate));
      }
      else{
        setRoleEndDateValidation();
      }

       // Get min min end date
        let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
        let _currentDate = moment(currentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        let _userStartDate = moment(userStartDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        let _userStartDateIsLess = moment(_currentDate).isAfter(_userStartDate);
        if(_userStartDateIsLess){
          let isStartDateGreater = moment(fromDateF).isAfter(_currentDate);
          if(isStartDateGreater){
            // let startDatePlusOne = jsUtils.getDatePlusMinus(fromDateF,1);
            setRoleMinDateForEndDate(new Date(jsUtils.getTimeZoneFormatDate(fromDateF)));
          }
          else{
            setRoleMinDateForEndDate(new Date(jsUtils.getTimeZoneFormatDate(_currentDate)));
          }
        }
        else{
          setRoleMinDateForEndDate(new Date(jsUtils.getTimeZoneFormatDate(_userStartDate)));
        }

      let stDate = "";
      if (isValidDate(role.From)) {
        if (typeof role.From === "string") {
          stDate = role.From.substring(0, 10).split("-");
          stDate = stDate[1] + "/" + stDate[2] + "/" + stDate[0];
          setrolesSdate(new Date(stDate));
        }
        else {
          setrolesSdate(role.From);
        }
      }
      else {
        setrolesSdate("");
      }

      let edDate = "";
      if (isValidDate(role.To)) {
        if (typeof role.From === "string") {
          edDate = role.To.substring(0, 10).split("-");
          edDate = edDate[1] + "/" + edDate[2] + "/" + edDate[0];
          setrolesEdate(new Date(edDate));
        }
        else {
          setrolesEdate(role.To);
        }
      }
      else {
        setrolesEdate("");
      }

      setUpdate(true);
      if (isTodayGreaterOrSame) {
        setRoleStartDateStatus(false);
      } else {
        setRoleStartDateStatus(true);
      }
      if (!endDateStatus || isTodayGreaterOrSameEdate) {
        setRoleEndDateStatus(false);
      } else {
        setRoleEndDateStatus(true);
      }

      if(role.oldRule){
        setEditRule(true)
      }
      else{
        setEditRule(false);
      }
    }
  };

  /**
   * @description - Load the user list to copy the role 
   * @param {string} name - user name passed to get all the assinged roles for copy
   */
  async function loadUsers(name) {
    let data = await userApi.getUsersForRole(props.selectedRowData.user_id,name);
    let formattedJson = { data: [] };
    if (data && data.userData) {
      data.userData.forEach((usr) => {
        let userItem = {
          id: usr.sys_user_id,
          label: usr.email,
          description: usr.lastname + ", " + usr.firstname,
        };
        formattedJson.data.push(userItem);
      });
    }
    return formattedJson.data;
  }

  /**
   * @description - Load the user list to copy the role 
   * @param {string} name - user name passed to get all the assinged roles for copy
   */
  async function loadLookupList(searchText, fieldName, col1, col2) {
    let finalList = [];
    let data = await userApi.getLookupList(searchText, fieldName);
    finalList = data && data.list.map(item => ({ ...item, id: item[col1], label: item[col1], description: item[col2] }));
    return finalList;
  }

  const [roleStartDateStatus, setRoleStartDateStatus] = useState(props.roleStartDateIsDisabled);
  const [roleEndDateStatus, setRoleEndDateStatus] = useState(true);
  // const [datePlusOne, setDatePlusOne] = useState(new Date());
  const [roleMinDateForEndDate, setRoleMinDateForEndDate] = useState(null);
  const [roleStartDateValidation, setRoleStartDateValidation] = useState(null);
  const [roleEndDateValidation, setRoleEndDateValidation] = useState(null);
  const [roleMaxStartDateValidation, setRoleMaxStartDateValidation] = useState(null);

  useEffect(() => {
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setRoleMinDateForEndDate(new Date(currentDate));
    setRoleStartDateValidation(new Date(currentDate));
    setRoleEndDateValidation(new Date(currentDate));
  }, []);
  

  /**
   * @description - get the date + number of days for validation
   * @param {Object} date - adding number of days into the date value
   */
  const getDatePlusOne = (date) => {
    let dt = new Date(date);
    let newdate = new Date(dt);
    newdate.setDate(newdate.getDate() + 1);
    setRoleMinDateForEndDate(newdate);
  };

  /**
   * Add Min Max validations For roles
   * @param {*} value 
   */
  const setMinStartDateForRoles = () => {
    let currentDate = jsUtils.getCurrentServerDate(meUserData.user_timezone);
    let getRoleDetails = _.find(attornyRolesBackup, { name: copyRole });
    if (getRoleDetails) {
      let today = moment().format("YYYY-MM-DD");
      let stDate = getRoleDetails.role_start_date.substring(0, 10).split("-");
      let roleStartDate = stDate[0] + "/" + stDate[1] + "/" + stDate[2];
      let isTodayGreater = moment(today).isSameOrAfter(roleStartDate);
      if (isTodayGreater) {
        let userSDate = moment(userStartDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let isUserDateGreater = moment(userSDate).isSameOrAfter(today);
        isUserDateGreater ? setRoleStartDateValidation(userStartDate) : setRoleStartDateValidation(new Date(currentDate));
      }
      else {
        setRoleStartDateValidation(new Date(roleStartDate));
      }
    }
  }

  const setMaxEndDateForRoles = () => {
    let getRoleDetails = _.find(attornyRolesBackup, { name: copyRole });
    if (getRoleDetails) {
      if (isValidDate(getRoleDetails.role_end_date) && isValidDate(userEndDate)) {
        let endDate = getRoleDetails.role_end_date.substring(0, 10).split("-");
        let roleEndDate = endDate[0] + "/" + endDate[1] + "/" + endDate[2];
        let userEDate = moment(userEndDate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let isUserEndGreater = moment(userEDate).isSameOrAfter(roleEndDate);
        isUserEndGreater ? setRoleEndDateValidation(new Date(roleEndDate)) : setRoleEndDateValidation(new Date(userEndDate));
      }
      else if (isValidDate(getRoleDetails.role_end_date) && !isValidDate(userEndDate)) {
        let endDate = getRoleDetails.role_end_date.substring(0, 10).split("-");
        let roleEndDate = endDate[0] + "/" + endDate[1] + "/" + endDate[2];
        // let roleEndDateMinusOne = jsUtils.getDatePlusMinus(roleEndDate,-1);
        setRoleEndDateValidation(new Date(roleEndDate));
      }
      else if (isValidDate(userEndDate) && !isValidDate(getRoleDetails.role_end_date)) {
        setRoleEndDateValidation(userEndDate);
      }
      else {
        setRoleEndDateValidation(null);
      }
    }
  }
  useEffect(() => {
    setMinStartDateForRoles();
    setMaxEndDateForRoles();
  }, [userEndDate, userStartDate, copyRole]);

  const getMinRoleStartDateForEdit = (_roleStartDate) => {
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    let today = moment().format("YYYY-MM-DD");
    let stDate = _roleStartDate.substring(0, 10).split("-");
    let roleStartDate = stDate[0] + "/" + stDate[1] + "/" + stDate[2];
    let isTodayGreater = moment(today).isSameOrAfter(roleStartDate);
    isTodayGreater ? setRoleStartDateValidation(new Date(currentDate)) : setRoleStartDateValidation(new Date(roleStartDate));
  };

  // const getMinRoleEndDateForEdit = (_roleEndDate) => {
  //   let endDate = _roleEndDate.substring(0, 10).split("-");
  //   let roleEndDate = endDate[0] + "/" + endDate[1] + "/" + endDate[2];
  //   setRoleEndDateValidation(new Date(roleEndDate));
  // };

  useEffect(()=>{
      getMaxStartDate(rolesEdate,copyRole);
  },[rolesEdate,update,copyRole])

  const getMaxStartDate = (_rolesEdate,_copyRole) =>{
    let selectedRole=attornyRoles.find((role)=>role.name===_copyRole)
    if(_rolesEdate){
      const rolesEndDateMinusOne = jsUtils.getDatePlusMinus(_rolesEdate,-1);
      setRoleMaxStartDateValidation(rolesEndDateMinusOne);
    }
    else{
      if(selectedRole && selectedRole.role_end_date){
        let formattedEndDateRole = jsUtils.getTimeZoneFormatDate(selectedRole.role_end_date)
        const rolesEndDateMinusOne = jsUtils.getDatePlusMinus(formattedEndDateRole,-1);
        setRoleMaxStartDateValidation(rolesEndDateMinusOne);
      }
      else{
        setRoleMaxStartDateValidation();
      }
    }
    
  }


  useEffect(()=>{
    if(isValidDate(rolesSdate)){
      getMinEndDate(rolesSdate,update,editRule);
    }
  },[rolesSdate,update,editRule])

  const getMinEndDate = (_rolesSdate,_update,_editRule) =>{
    // if(addUpdate){
      let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
      let currentFormattedDate = moment(currentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
      let rolesSDateFormatted=jsUtils.getTimeZoneFormatDate(_rolesSdate);
      if(_update && _editRule){
        let minEndDate=jsUtils.getMinDate(currentFormattedDate,rolesSDateFormatted,"edit")
        setRoleMinDateForEndDate(minEndDate);
      }
      else{
        let minEndDate=jsUtils.getMinDate(currentFormattedDate,rolesSDateFormatted,"add")
        setRoleMinDateForEndDate(minEndDate);
      }
    // }
  }

  const getEditButton = (rowData) => {
    return (
      <span
        title={
          tooltips[TOOLTIPS_KEYS.TOOLTIP_EDIT]
        }
        onClick={(e) => {
          setError("");
          e.preventDefault();
          if (rowData.editEnable) {
            // setChanged(true)
            setUser({
              field: "users",
              isDisplay: true,
              isMandatory: true,
              displayLabel: "Users",
              values: [
                {
                  id: "",
                  label: "",
                  desc: "",
                  isDefault: true,
                },
              ],
            })
            setAddUpdate(true);
            editRole(rowData);
            // setAddUpdate(false);
            setUpdateIndex(rowData.index);
          } else {
            setError(
              jsUtils.getLiteralValue("ROLE_CANNOT_BE_CHANGED"));
          }
        }}
        className="btn-edit">
        <img alt="" src="/images/svg/icon_edit.svg" />
      </span>
    );
  }

  /**
  * @description - Sends API call to reset passeord. This will send OTP on mail ID
  */
  const resetPassword = () => {
    setSaving(true);
    userApi
      .adminResetPassword(props.selectedRowData.user_id)
      .then((res) => {
        dispatch(
          setErrorMessage({
            severity: "success",
            message: res && getCode(res.message),
            errorCode: res.responseCode
          })
        );
      })
      .catch((err) => {
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const traverseDown = (onSaveClick) => {
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);

    setTraverseState("Down")
    if (props.userList.length > 0 && (props.editPosition + 1) < props.userList.length) {
      if (onSaveClick || !changed) {
        setError("");
        setUser({
          field: "users",
          isDisplay: true,
          isMandatory: true,
          displayLabel: "Users",
          values: [
            {
              id: selectedUser.id,
              label: selectedUser.label,
              desc: selectedUser.description,
              isDefault: true,
            },
          ],
        })
        setCopyRole("")
        setrolesSdate(null);
        setrolesEdate(null);
        setRoleStartDateStatus(true);
        setRoleEndDateStatus(true);
        setIsEditRole(false);
        // setDatePlusOne(new Date(currentDate));
        setRoleStartDateValidation(new Date(currentDate));
        props.onTraverseNext(props.userList[props.editPosition + 1]);
        props.setEditPosition(props.editPosition + 1)
      }
      else {
        setChanged(false);
        setCloseConfirmation(true)
      }
    }
  }


  const traverseUp = () => {
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);

    setTraverseState("Up")
    if (props.userList.length > 0 && (props.editPosition - 1) >= 0) {
      if (!changed) {
        setError("");
        setUser({
          field: "users",
          isDisplay: true,
          isMandatory: true,
          displayLabel: "Users",
          values: [
            {
              id: selectedUser.id,
              label: selectedUser.label,
              desc: selectedUser.description,
              isDefault: true,
            },
          ],
        })
        setCopyRole("")
        setrolesSdate(null);
        setrolesEdate(null);
        setRoleStartDateStatus(true);
        setRoleEndDateStatus(true);
        setIsEditRole(false);
        // setDatePlusOne(new Date(currentDate));
        setRoleStartDateValidation(new Date(currentDate));
        props.onTraverseNext(props.userList[props.editPosition - 1]);
        props.setEditPosition(props.editPosition - 1)

      }
      else {
        setChanged(false);
        setCloseConfirmation(true)
      }
    }
  }



  const onFormInputChange = (fieldNameKey, value) => {
    setFormErrorMessages(formErrorMessages.filter(item => item.field !== fieldNameKey));
    if (fieldNameKey === "attorney_code") {
      setUserFormState(state => ({
        ...state,
        [fieldNameKey]: value,
        "gl_sub_account_id": value
      }));
    } else
      if (fieldNameKey === "mobileno") {
        value = validationUtils.formatPhoneNumber(value);
        setUserFormState(state => ({
          ...state,
          [fieldNameKey]: value
        }));
      } else {
        setUserFormState(state => ({
          ...state,
          [fieldNameKey]: value
        }));
      }
  }

  const onDropdownSelection = (fieldNameKey, value, id, selectedValKey) => {
    setFormErrorMessages(formErrorMessages.filter(item => item.field !== fieldNameKey));
    setUserFormState(state => ({
      ...state,
      [fieldNameKey]: value[id]
    }));
    setSelectedValuesState(state => ({
      ...state,
      [selectedValKey]: `${value.id}, ${value.description}`
    }));
  }

  const getErrorMessage = (response, fieldName) => {
    if (Array.isArray(response) && fieldName) {
      return response.find(item => item.field === fieldName)?.message;
    } else if (!fieldName) {
      return response;
    }
  }
  return (
    <div className="af-modal-dialog-edituser">
      <Toast ref={toast} />
      {props !== null ? (
        <Dialog
          header=""
          closable={false}
          visible={TimeDialog}
          className="af-modal-dialog"
        >
          <div className="p-dialog-header">
            <a href={`${helpPageUrl}${HELP_LINKS.USER_ACCESS}`} target="_blank">
              <img
                title={tooltips[TOOLTIPS_KEYS.TOOLTIP_HELP]}
                className="af-help-addtime"
                src="/images/svg/Help_icon.svg"
                alt=""
              />
            </a>
            <div className="p-breadcrumb p-component" aria-label="Breadcrumb">
              <ul>
                <li className="">
                  <span className="p-menuitem-link">
                    <span className="p-menuitem-text">
                      {getCode("SETTINGS")}
                    </span>
                  </span>
                </li>
                <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
                <li className="">
                  <span className="p-menuitem-link">
                    <span className="p-menuitem-text">
                      {getCode("USER_ACCESS")}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
            <span className="p-dialog-title"></span>

            {props.bulkEdit && <div className="dialog-title" style={{ float: "right" }}>
              <span>{`${props.editPosition + 1} of ${props.userList.length}`}</span>
              <i
                className="pi pi-chevron-up"
                onClick={() => {
                  traverseUp();
                }}
              ></i>
              <i
                className="pi pi-chevron-down"
                onClick={() => {
                  traverseDown();
                }}
              ></i>
            </div>}

            <div className="p-dialog-header-icons">
              <button
                onClick={() => hideDialog()}
                type="button"
                className="p-dialog-header-icon p-dialog-header-close p-link"
                aria-label="Close"
                title={tooltips[TOOLTIPS_KEYS.TOOLTIP_CLOSE]}
              >
                <span className="p-dialog-header-close-icon pi pi-times"></span>
              </button>
            </div>
          </div>
          {userLoading && specUserLoading && roleLoading ? (
            <div className="usercard-spinner">
              <Spinner />
            </div>
          ) : (
            <div className="af-popup-body">
              <div className="p-grid edit-user-content">
                <div className="p-sm-12 p-md-12 p-lg-8">
                  <div className="at-usercol">
                    <div className="at-user-detail">
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("COMPANY")}<span className="req-star">*</span></label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="input-holder">
                          <InputText
                            value={companyName}
                            id="companyName"
                            type="text"
                            disabled
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("USERNAME")} <span className="req-star">*</span></label>{" "}
                          <label>{getCode("EMPLOYEE_ID")}</label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label><span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "username")} </span></label>
                          <label><span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "employee_id")} </span></label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="input-holder">
                          <InputText
                            value={userFormState["username"]}
                            id="UsernameTxt"
                            type="text"
                            onChange={e => onFormInputChange("username", e.currentTarget.value)}
                            disabled={props.userNameIsDisabled}
                            autoComplete="off"
                          />
                          <InputText
                            // value={
                            //   specificUser && specificUser.employee_id
                            //     ? specificUser.employee_id
                            //     : ""
                            // }
                            value={userFormState["employee_id"]}
                            id="EmpID"
                            type="text"
                            autoComplete="off"
                            disabled={props.action !== "addUser"}
                            onChange={e => onFormInputChange("employee_id", e.currentTarget.value)}
                          />
                        </div>

                      </div>
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("LAST_NAME")}<span className="req-star">*</span></label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "lastname")} </span>
                        <div className="input-holder">
                          <InputText
                            // value={
                            //   specificUser && specificUser.lastname
                            //     ? specificUser.lastname
                            //     : ""
                            // }
                            value={userFormState["lastname"]}
                            id="LastName"
                            type="text"
                            autoComplete="off"
                            onChange={e => onFormInputChange("lastname", e.currentTarget.value)}
                            disabled={props.action !== "addUser"}
                          />
                        </div>

                      </div>
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("FIRST_NAME")}<span className="req-star">*</span></label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "firstname")} </span>
                        <div className="input-holder">
                          <InputText
                            // value={
                            //   specificUser && specificUser.firstname
                            //     ? specificUser.firstname
                            //     : ""
                            // }
                            value={userFormState["firstname"]}
                            id="FirstName"
                            type="text"
                            autoComplete="off"
                            onChange={e => onFormInputChange("firstname", e.currentTarget.value)}
                            disabled={props.action !== "addUser"}
                          />
                        </div>

                      </div>

                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("MIDDLE_NAME")}</label>
                          <label>{getCode("PREFERRED_NAME")}</label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="input-holder">
                          <InputText
                            // value={
                            //   specificUser && specificUser.middle_name
                            //     ? specificUser.middle_name
                            //     : ""
                            // }
                            value={userFormState["middlename"]}
                            id="UsernameTxt"
                            type="text"
                            autoComplete="off"
                            onChange={e => onFormInputChange("middlename", e.currentTarget.value)}
                            disabled={props.action !== "addUser"}
                          />
                          <InputText
                            // value={
                            //   specificUser && specificUser.preferred_name
                            //     ? specificUser.preferred_name
                            //     : ""
                            // }
                            value={userFormState["preferredname"]}
                            id="preferredName"
                            type="text"
                            autoComplete="off"
                            onChange={e => onFormInputChange("preferredname", e.currentTarget.value)}
                            disabled={props.action !== "addUser"}
                          />
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("EMAIL_")}<span className="req-star">*</span></label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "email")} </span>
                        <div className="input-holder">
                          <InputText
                            // value={
                            //   specificUser && specificUser.email
                            //     ? specificUser.email
                            //     : ""
                            // }
                            value={userFormState["email"]}
                            id="emailTxt"
                            type="txt"
                            autoComplete="off"
                            onChange={e => onFormInputChange("email", e.currentTarget.value)}
                            disabled={props.action !== "addUser"}
                          />
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("MOBILE_NUMBER")}</label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "mobileno")} </span>
                        <div className="input-holder">
                          <InputText
                            // value={
                            //   specificUser && specificUser.mobile
                            //     ? specificUser.mobile
                            //     : ""
                            // }
                            value={userFormState["mobileno"]}
                            onChange={e => {
                              onFormInputChange("mobileno", e.currentTarget.value);
                            }}
                            id="mobileNumber"
                            maxlength="12"
                            type="txt"
                            autoComplete="off"
                            disabled={props.action !== "addUser"}
                          />
                        </div>
                      </div>
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("START_DATE")}<span className="req-star">*</span></label>{" "}
                          <label>{getCode("END_DATE")}</label>
                        </div>

                      </div>
                      <div className="sec-holder">
                        <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "effective_start_date")} </span>
                        <div className="input-holder">
                          <Calendar
                            tooltip={
                              tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]
                            }
                            tooltipOptions={{
                              className: "white-tooltip",
                              position: "top",
                              showDelay: 700,
                            }}
                            placeholder={getCode("MM_DD_YYYY")}
                            id="icon"
                            appendTo={document.body}
                            value={userStartDate}
                            onChange={(e) => {
                              onFormInputChange("effective_start_date", e.value);
                              setUserStartDate(e.value);
                              setChanged(true);
                              setRoleStartDateValidation(e.value);
                              setSaveButtonDisable(false);
                            }}
                            showIcon
                            className="adduser-startdate"
                            disabled={props.action !== "addUser"}
                            minDate={new Date(DBCurrentDate)}
                            readOnlyInput
                            showButtonBar
                            yearNavigator
                            yearRange="1970:2100"
                          />
                          <Calendar
                            tooltip={
                              tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]
                            }
                            tooltipOptions={{
                              className: "white-tooltip",
                              position: "top",
                              showDelay: 700,
                            }}
                            placeholder={getCode("MM_DD_YYYY")}
                            appendTo={document.body}
                            id="icon"
                            value={userEndDate}
                            onChange={(e) => {
                              onFormInputChange("effective_end_date", e.value);
                              setUserEndDate(e.value);
                              setIfEndDateChanged(true);
                              setChanged(true);
                              setRoleEndDateValidation(e.value)
                              setSaveButtonDisable(false);
                            }}
                            showIcon
                            className="endDate"
                            minDate={new Date(DBCurrentDate)}
                            readOnlyInput
                            showButtonBar
                            yearNavigator
                            yearRange="1970:2100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="at-user-detail col-two">
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>
                       <Avatar
                        size="55"
                        round={true}
                        className="af-avatar-list-items"
                        name={avatarImg ? null : avatarName}
                        src={avatarImg}
                        background="#f3f4f7"
                      />
                          </label>
                          {/* <div className="btn-holder" style={{ marginLeft: '60px'}}> */}
                          <label>
                            {props.action !== "addUser" &&
                              <button
                                className={`filterbutton ${usernameStatus ? "disabled" : ""
                                  }`}
                                style={{ width: '8.5em', height: '2em' }}
                                onClick={resetPassword}
                                disabled={usernameStatus}                            >
                                {getCode("RESET_PASSWORD")}
                              </button>
                            }
                            {/* </div> */}
                          </label>
                        </div>
                      </div>
                      <div className="sec-holder">
                        {userFormState["is_timekeeper"] &&
                          <>
                            <div className="label-holder">
                              <label>{""}</label>
                              <label>{getCode("ATTORNEY_NO")}<span className="req-star">*</span></label>
                            </div>
                          </>}
                        {!userFormState["is_timekeeper"] &&
                          <>
                            <div className="label-holder" style={{ height: '30px' }}>
                            </div>
                          </>}
                      </div>
                      <div className="sec-holder">
                        <div className="sec-holder">
                          <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "attorney_code")} </span>
                        </div>
                        <div className="input-holder">
                          <label style={{ paddingRight: '30px' }}>{getCode("TIMEKEEPER")}</label>{" "}
                          <InputSwitch
                            tooltip={
                              timeKeeper
                                ? tooltips[
                                TOOLTIPS_KEYS
                                  .TOOLTIP_REMOVE_AS_TIMEKEEPER
                                ]
                                : tooltips[
                                TOOLTIPS_KEYS.TOOLTIP_SET_AS_TIMEKEEPER
                                ]
                            }
                            tooltipOptions={{
                              className: "white-tooltip",
                              position: "top",
                              showDelay: 700,
                            }}
                            checked={timeKeeper}
                            onChange={(e) => {
                              onFormInputChange("is_timekeeper", e.value)
                              setTimeKeeper(e.value);
                              setChanged(true);
                            }}
                            disabled={props.action === "editUser"}
                          />
                          {userFormState["is_timekeeper"] && props.action === "addUser" &&
                            <InputText
                              value={userFormState["attorney_code"]}
                              onChange={e => onFormInputChange("attorney_code", e.currentTarget.value)}
                              id="attorneyNo"
                              type="text"
                              autoComplete="off"
                              style={{ marginRight: '40px' }}
                              disabled={props.action !== "addUser"}
                            />}
                        </div>
                      </div>
                      {props.action === "addUser" &&
                        <>
                          <div className="sec-holder">
                            <div className="label-holder">
                              <label>{getCode("LMS_SECURITY_PROFILE")} </label>
                            </div>
                          </div>
                          <div className="edit-user-role">
                            <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "lms_security_profile")} </span>
                            <RadioButton
                              columns={["Code", "Security Profile"]}
                              displayType="text"
                              textDisplayPropName="desc"
                              noScroll={true}
                              onChange={(field, value) => {
                                setError("");
                                onDropdownSelection("lms_security_profile", value, "id", "security_profile_val");
                                setChanged(true);
                              }}
                              listSource={async (searchText) => {
                                return await loadLookupList(searchText, "security_profiles", "lms_security_profile_id", "security_profile");
                              }}
                              data={{ selectedValue: selectedValuesState["security_profile_val"] }}
                            />
                          </div>
                        </>
                      }
                      {userFormState["is_timekeeper"] &&
                        <>
                          <div className="sec-holder">
                            <div className="label-holder dropdown-label-holder">
                              <label>{getCode("OFFICE")} <span className="req-star">*</span></label>
                            </div>
                          </div>
                          <div className="edit-user-role">
                            <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "sys_office_id")} </span>
                            <RadioButton
                              columns={["Code", "Office"]}
                              displayType="text"
                              textDisplayPropName="desc"
                              noScroll={true}
                              onChange={(field, value) => {
                                setError("");
                                onDropdownSelection("sys_office_id", value, "sys_firm_office_id", "office_val");
                                setChanged(true);
                              }}
                              listSource={async (name) => {
                                return await loadLookupList(name, "offices", "office_code", "office_name");
                              }}
                              data={{ selectedValue: selectedValuesState["office_val"] }}
                            />
                          </div>
                          <div className="sec-holder">
                            <div className="label-holder dropdown-label-holder">
                              <label>{getCode("DEPARTMENT")} <span className="req-star">*</span></label>
                            </div>
                          </div>
                          <div className="edit-user-role">
                            <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "sys_department_id")} </span>
                            <RadioButton
                              columns={["Code", "Department"]}
                              displayType="text"
                              textDisplayPropName="desc"
                              noScroll={true}
                              onChange={(field, value) => {
                                setError("");
                                onDropdownSelection("sys_department_id", value, "id", "department_val");
                                setChanged(true);
                              }}
                              listSource={async (name) => {
                                return await loadLookupList(name, "departments", "department_code", "department_name");
                              }}
                              data={{ selectedValue: selectedValuesState["department_val"] }}
                            />
                          </div>
                          <div className="sec-holder">
                            <div className="label-holder dropdown-label-holder">
                              <label>{getCode("REPORTING_STAFF_LEVEL")} <span className="req-star">*</span></label>
                            </div>
                          </div>
                          <div className="edit-user-role">
                            <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "billing_staff_id")} </span>
                            <RadioButton
                              columns={["Code", "Staff Level"]}
                              displayType="text"
                              textDisplayPropName="desc"
                              noScroll={true}
                              onChange={(field, value) => {
                                setError("");
                                onDropdownSelection("sys_staff_id", value, "id", "reporting_staff_val");
                                setChanged(true);
                              }}
                              listSource={async (name) => {
                                return await loadLookupList(name, "reporting_staff", "sys_staff_level_id", "level_name");
                              }}
                              data={{ selectedValue: selectedValuesState["reporting_staff_val"] }}
                            />
                          </div>
                          <div className="sec-holder">
                            <div className="label-holder dropdown-label-holder">
                              <label>{getCode("BILLING_STAFF_LEVEL")} <span className="req-star">*</span></label>
                            </div>
                          </div>
                          <div className="edit-user-role">
                            <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "billing_staff_id")} </span>
                            <RadioButton
                              columns={["Code", "Staff Level"]}
                              displayType="text"
                              textDisplayPropName="desc"
                              noScroll={true}
                              onChange={(field, value) => {
                                setError("");
                                onDropdownSelection("billing_staff_id", value, "id", "billing_staff_val");
                                setChanged(true);
                              }}
                              listSource={async (name) => {
                                return await loadLookupList(name, "billing_staff_id", "billing_staff_id", "level_name");
                              }}
                              data={{ selectedValue: selectedValuesState["billing_staff_val"] }}
                            />
                          </div>
                          <div className="sec-holder">
                            <div className="label-holder dropdown-label-holder">
                              <label>{getCode("DEFAULT_GL_SUBACCOUNT")} <span className="req-star">*</span></label>
                            </div>
                          </div>
                          <div className="sec-holder">
                            <div className="input-holder">
                              <InputText
                                value={userFormState["attorney_code"]}
                                type="text"
                                autoComplete="off"
                                style={{ marginRight: '40px' }}
                                disabled
                              />
                            </div>
                          </div> </>}
                    </div>
                  </div>
                </div>
                <div className="p-sm-12 p-md-12 p-lg-4">
                  <div className="roles-col">
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("APPLY_SAME_ROLES_AS")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder edit-user-role">
                        <RadioButton
                          columns={["Email", "Last Name, First Name"]}
                          displayType="text"
                          textDisplayPropName="desc"
                          noScroll={true}
                          onChange={(field, value) => {
                            setError("");
                            onClientSelection(field, value);
                            setUpdate(false);
                            setAddUpdate(false);
                            setChanged(true);
                          }}
                          listSource={async (searchText) => {
                            let list = await loadUsers(searchText);
                            return list;
                          }}
                          data={user}
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("OR_ADD_UPDATE_ROLE")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <span className="af-error-msg-display"> {getErrorMessage(formErrorMessages, "roles")} </span>
                      <div className="input-holder">
                        <Dropdown
                          className="add-update-roles"
                          value={copyRole}
                          appendTo={document.body}
                          options={attornyRoles}
                          onChange={(e) => {
                            setAddUpdate(true);
                            setCopyRole(e.value);
                            setrolesSdate();
                            setRoleStartDateStatus(false);
                            setRoleEndDateStatus(false);
                            setUpdate(false);
                            setError("");
                            setChanged(true);
                            setUser({
                              field: "users",
                              isDisplay: true,
                              isMandatory: true,
                              displayLabel: "Users",
                              values: [
                                {
                                  id: selectedUser.id,
                                  label: selectedUser.label,
                                  desc: selectedUser.description,
                                  isDefault: true,
                                },
                              ],
                            });
                            setCopyStatus(false);
                            setFormErrorMessages(formErrorMessages.filter(item => item.field !== "roles"));
                          }}
                          placeholder="Select Role"
                          disabled={isEditRole}
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>
                          {getCode("START_DATE")}
                          <span className="af-req-star"> *</span>
                        </label>
                        <label>{getCode("END_DATE")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <Calendar
                          tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                          tooltipOptions={{
                            className: "white-tooltip",
                            position: "top",
                            showDelay: 700,
                          }}
                          placeholder={getCode("MM_DD_YYYY")}
                          className="role-start-date"
                          id="icon1"
                          value={rolesSdate}
                          onChange={(e) => {
                            setrolesSdate(e.value);
                            getDatePlusOne(e.value);
                            setAddUpdate(false);
                            setChanged(true);
                            if(e.value === null){
                              setRoleMinDateForEndDate(new Date(DBCurrentDate));
                            }
                          }}
                          showIcon
                          appendTo={document.body}
                          minDate={roleStartDateValidation}
                          maxDate={roleMaxStartDateValidation}
                          readOnlyInput
                          disabled={roleStartDateStatus}
                          showButtonBar
                          yearNavigator
                          yearRange="1970:2100"
                        />
                        <Calendar
                          tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                          tooltipOptions={{
                            className: "white-tooltip",
                            position: "top",
                            showDelay: 700,
                          }}
                          placeholder={getCode("MM_DD_YYYY")}
                          className="role-end-date"
                          id="icon2"
                          value={rolesEdate}
                          onChange={(e) => {
                            setrolesEdate(e.value);
                            setChanged(true);
                            setAddUpdate(false);
                            if(e.value === null){
                              setRoleMaxStartDateValidation();
                            }
                          }
                          }
                          minDate={roleMinDateForEndDate}
                          maxDate={roleEndDateValidation}
                          showIcon
                          appendTo={document.body}
                          readOnlyInput
                          disabled={roleEndDateStatus}
                          showButtonBar
                          yearNavigator
                          yearRange="1970:2100"
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <button
                          className={`btn-full add-role-button ${addUpdate ? "disabled" : "enabled"}`}
                          onClick={addRole}
                          disabled={addUpdate}
                        >
                          {update
                            ? getCode("UPDATE_ROLE")
                            : getCode("ADD_ROLE")}
                        </button>
                      </div>
                    </div>

                    <div className="sec-holder">
                      <DataTable className="editUser-roles-table" value={getUpdateRole(updateRole)}>
                        <Column field="Role" header="Assigned Role"></Column>
                        <Column field="FromDate" header="From"></Column>
                        <Column field="ToDate" header="To"></Column>
                        <Column
                          body={(rowData) => getEditButton(rowData)}
                        >
                        </Column>
                      </DataTable>
                    </div>
                    <div className="role-grid-error">
                      {error && error !== "" ? error : ""}
                    </div>
                    <div className="btn-pannel btn-float-rght">
                      {saving && (
                        <div className="user-save-spinner">
                          <Spinner />
                        </div>
                      )}
                      <button
                        className={"btn-save"}
                        onClick={props.action === "addUser" ? saveNewUser : updateUser}
                        disabled={saveButtonDisable}
                      >
                        {getCode("SAVE")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="timerClass">
            <div className="timerClass-dialog">
              <Dialog
                closable={false}
                header="Confirmation"
                visible={closeConfirmation}
                modal
                footer={renderFooterClose()}
                onHide={() => onHideClose("closeConfirmation")}
              >
                <div className="confirmation-content">
                  <img
                    title=""
                    alt=""
                    className="af-warning"
                    src="/images/icon_warning.svg"
                  />
                  <div>
                    <span>{getCode("YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_STAY")}</span>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </Dialog>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
export default EditUser;