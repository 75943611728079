import React from "react";
import { InputText } from "primereact/inputtext";
import FieldState from "./AddTimePopup/AddTimePopup.State.ts";
import RadioDropDownComponent from "./RadioButton/RadioDropDownComponent";

const DraftCodesComponent = ({ fieldsData, delegateId, onCodesSelected, rateAmountValue, setRateAmountValue, loadCodes, showRateAmount, screen, getCode , tabOrder=0 }) => {
return (    
 <>
    {fieldsData.map((item, index) => {
                    let selected =
                      item.values && item.values.find((d) => d.isDefault);

                    return (
                      item.isDisplay && (
                        <div className="af-btn-sect" key={index}>
                          <h3>
                            {item.displayLabel}
                            {item.isMandatory && screen !== "bulkUpdate" && 
                              <span className="af-req-star">*</span>
                            }
                          </h3>

                          <span className="radio-msg">
                            {selected && selected.description}
                          </span>

                          <div className="af-error-msg-display">
                            {item.errorMessage}
                          </div>

                          <RadioDropDownComponent
                            tabOrder={tabOrder}
                            columns={["Code", "Description"]}
                            displayType="button"
                            textDisplayPropName="description"
                            onChange={(
                              field,
                              option,
                              ignoreIfAlreadySelected
                            ) => {
                              if (item.errorMessage)
                                FieldState.update(FieldState.addError, [
                                  [
                                    {
                                      field: item.field
                                        .toLowerCase()
                                        .replace(/ /g, ""),
                                      message: "",
                                    },
                                  ],
                                ]);
                              onCodesSelected(
                                field,
                                option,
                                ignoreIfAlreadySelected
                              );
                            }}
                            listSource={
                              item.field === "MAKE_NO_CHARGE"
                                ? null
                                : async (searchText, page) => {
                                  let list = await loadCodes(
                                    item.field,

                                    searchText,

                                    page,

                                    delegateId
                                  );
                                  return list.fields.length > 0
                                    ? list.fields[0].values
                                    : [];
                                }
                            }
                            data={item}
                          />
                          {item.field === "RATE_CODE" && showRateAmount && (
                            <div className="af-amount af-input-lookup">
                              <div className="af-amount-field">
                                {getCode("ENTER_AMOUNT")}
                              </div>{" "}
                              <div className="af-amount-field">
                                <InputText
                                  id="rateInputAmount"
                                  value={rateAmountValue}
                                  onChange={(event) =>
                                    setRateAmountValue(event.target.value)
                                  }
                                  type="number"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    );
                  })}  </>);
};

export default DraftCodesComponent;
