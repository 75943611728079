import React from "react";
import "./MenuItems.scss";
import lmsUrlLinks from "../../../lms-data.json";
import jsUtils from "../../../utils/jsUtils";
export default function MenuItems(props) {

   
  return (
    <div>
       {(props.datamenu > 0 || lmsUrlLinks.practice.length > 0 )? (
     <div> 
        {props.image && <img src={props.image} alt="circle" className="menu-Icon"></img>}
        <span className="module-name">{jsUtils.getLiteralValue(props.text)}</span>
      </div>
       ) : (
       ""
      )} 
    </div>
  );
}
