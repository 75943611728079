import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import React, { useState, useEffect, useRef } from "react";
import { ATColDef } from "./AutoTextColDef";
import { buildQueryString } from "./AutoTextColDef";
import { shortcut } from "../../../services/AutoTextApi";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import jsUtils from "../../../utils/jsUtils";
import permissionsState from "../../../store/ClientMatterRolesPermissions.ts";
import { useSubscribe } from "../../../store/ActionStore";
import { helpPageUrl } from "../../../config";
import _ from "lodash";
import {
  setSelectedTimeKeeperFeature
} from "../../../actions/TimeManagementAction";
import {
  AUTOTEXT_FIRM_FEATURE_NAME,
  AUTOTEXT_PERSONAL_FEATURE_NAME,
  MAX_CHARS_SHORTCUT,
  AUTOTEXT_PERSONAL_FEATURE_ID,
  NO_OF_ROWS_IN_GRID
} from "../../../config/messages";
import {
  HELP_LINKS,
  TOOLTIPS_KEYS,
  TOOLTIPS_VALUES,
} from "../../../config/vars";
import storage from "../../../utils/storage";
import { setErrorMessage } from "../../../actions/AppAction";
import Spinner from "../../common/Spinner/Spinner";
import { refreshStoreOnError } from "../../../actions/AppAction";
import { setUnsavedChanges } from "../../../actions/ConfirmationAction";
import CustomTable from "../../common/DataTable/CustomTable";
import { setAutotextAction } from "../../../actions/AutotextAction";
import ConfirmationBox from "../../common/ConfirmationBox/ConfirmationBox";
import { setBreadCrumb, setPageHelpLink } from "../../../actions/LandingPageAction";
import { HeaderView } from "../../../config/literalCodes";

export const textBoxValuesWhileEditing = {
  //
};
export const textBoxValueEdited = (value, propName, rowId) => {
  if (!textBoxValuesWhileEditing[rowId]) {
    textBoxValuesWhileEditing[rowId] = {};
  }
  textBoxValuesWhileEditing[rowId][propName] = value;
};

export default function AutoTextTable() {
  const [showRWValidationMsg, setShowRWValidationMsg] = useState(false);
  const dispatch = useDispatch();

  const delegateId = useSelector(
    (state) => state.TimeManagementReducer.delegateId
  );

  const timeKeeperFeatureId = useSelector(
    (state) => state.TimeManagementReducer.timeKeeperFeatureId
  );

  useEffect(() => {
    //  dispatch(fetchTimeKeeperListAction(userID));
    return () => {
      resetFilters();
    };
  }, []);

  const [colDef, setColDef] = useState(ATColDef);
  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [moveScrollToTop, setMoveScrollToTop] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [signingOut] = useState(false);
  const [firstCallView,setFirstCallView]=useState(true);
  const [scrollNoMore, setScrollNoMore] = useState(false);
  const [DataFromAPI, setDataFromAPI] = useState([]);
  const [DataFromAPIBackup, setDataFromAPIBackup] = useState([]);
  const itemdata = useSelector((state) => state.LandingPageReducer.items);
  const loadingClient = useSelector(
    (state) => state.TimeManagementReducer.loading
  );
  
  const [addNewPermission, setAddNewPermission] = useState(false);
  const [isResetButtonActive, setIsResetButtonActive] = useState(true);
  // const loadingTimeKeeper = useSelector(
  //   (state) => state.TimeManagementReducer.loadingTimeKeeper
  // );
  const [countEditOrNewRow, setCountEditOrNewRow] = useState(0)
  const [newAutotextTempRowEnable, setNewAutotextTempRowEnable] = useState(
    true
  );

  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_SHOW_FIRM_AUTOTEXT_ONLY]: [
      TOOLTIPS_VALUES.SHOW_FIRM_AUTOTEXT_ONLY,
    ],
    [TOOLTIPS_KEYS.TOOLTIP_SHOW_PERSONAL_AUTOTEXT_ONLY]: [
      TOOLTIPS_VALUES.SHOW_PERSONAL_AUTOTEXT_ONLY,
    ],
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP],
  });

  /** set tooltips from storage on screen load
   * literals -variale to store the array of literals
   */
  const [codeFromAPI, setCodeFromAPI] = useState([]);

  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");

  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_SHOW_FIRM_AUTOTEXT_ONLY]:
          literals[TOOLTIPS_KEYS.TOOLTIP_SHOW_FIRM_AUTOTEXT_ONLY],
        [TOOLTIPS_KEYS.TOOLTIP_SHOW_PERSONAL_AUTOTEXT_ONLY]:
          literals[TOOLTIPS_KEYS.TOOLTIP_SHOW_PERSONAL_AUTOTEXT_ONLY],
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP],
      });
      setCodeFromAPI(literals);
    }

  }, []);

  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };
 // const items = [getCode("ACCOUNT"), getCode("AUTOTEXT")]; // unused 

  useEffect(()=>{
    document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_AUTOTEXT");
  },[])

  useEffect(() => {
    
      dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.AUTOTEXT}`))
     // setActiveIndex(1);
     dispatch(setBreadCrumb(["MG-Account","MG-Account-MD-Autotext"]))


  }, [])

  /**
   * update the work on behalf of dropdown list with the delegates those have delegation for that particular feature
   */
  useEffect(() => {
    itemdata &&
      itemdata[0] &&
      itemdata[0].landingPageData.moduleGroups.forEach((groupObj) => {
        groupObj.modules.forEach((moduleObj) => {
          if (moduleObj.moduleID === 12) {
            let featureId = "";
            moduleObj.menus.forEach((menu) => {
              if (menu.featureName === AUTOTEXT_FIRM_FEATURE_NAME) {
                featureId = menu.featureId;
              }
            });
            if (colDef[2].filter.val === true) {
              dispatch(
                setSelectedTimeKeeperFeature(
                  AUTOTEXT_FIRM_FEATURE_NAME,
                  featureId
                )
              );
            } else {
              dispatch(
                setSelectedTimeKeeperFeature(
                  AUTOTEXT_PERSONAL_FEATURE_NAME,
                  AUTOTEXT_PERSONAL_FEATURE_ID
                )
              );
            }
          }
        });
      });
  }, [itemdata, colDef[2].filter.val]);

  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });

  /**
    *  the state is updated with the new filters
    * @param {String} key -  column name
    * @param {String} value - searched text or toggle value
    * @param {Number} index -index of the filter column 
    */
  let setFilters = (key, value, index) => {
    let state = [...colDef];
    state[index].filter.val = value;
    if (index === colDef.length - 2 && value === true) {
      colDef[index].filter.tooltip =
        tooltips[TOOLTIPS_KEYS.TOOLTIP_SHOW_PERSONAL_AUTOTEXT_ONLY];
    } else {
      colDef[index].filter.tooltip =
        tooltips[TOOLTIPS_KEYS.TOOLTIP_SHOW_FIRM_AUTOTEXT_ONLY];
    }
    setColDef([...state]);
  };

  let setScrollToTop = () => {
    setMoveScrollToTop(true);
  }
  /*
  * removes all the filters from grid and adds default values
  */

  let resetFilters = () => {
    let state = [...colDef];
    state[0].filter.val = "";
    state[1].filter.val = "";
    state[2].filter.val = false;
    sort.index = -1;
    setSort({ index: -1, isAsc: true, propName: "" });
    setColDef([...state]);
    setNewAutotextTempRowEnable(true);
    setScrollToTop();
    setIsResetButtonActive(true);
  };

  /*
  * On component load we call the autotext api to load the grid
  */
  useEffect(() => {
    !dataLoaded && loadData(true);
  }, [dataLoaded, delegateId]);

  const toast = useRef(null);

  let permissions;
  useSubscribe([permissionsState]);

  useEffect(() => {
    permissions = permissionsState.autotextPermissions
    if (permissions && permissions[1] && permissions[1].actionable) {
      setAddNewPermission(false);
    }
    else {
      ATColDef[3].column.className = "p-button-success p-mr-2 time-button timer-disable-hide";
      setAddNewPermission(true);
    }
  }, [timeKeeperFeatureId]);

  ATColDef[ATColDef.length - 1].filter.onClick = () => {
    resetFilters();
    loadData(true);
    setIsResetButtonActive(true);
  };

  ATColDef[ATColDef.length - 1].column.onClick = () => {
    if (!loading) {
      if (newAutotextTempRowEnable) {
        const newRow = {
          rowId: jsUtils.getRandomNumber(), //sonar hotspot fix
          shortcut: "",
          fulltext: "",
          firmlevel: colDef[2].filter.val || false,
          editAll: true,
          editMode: 1,
          tempRow: true,
        };
        setNewAutotextTempRowEnable(false);
        addRow(newRow);
      }
    }
  };

  /**
    * manage time api that loads the grid with created time entries  
    * @param {boolean} reset - true for the first page load and false for 2nd and more onwards
    */
  const loadData = async (reset = true, sortName="", sortby="") => {
    let permissionsTemp = permissionsState.autotextPermissions;
    let firmpermissionsTemp = permissionsState.firmautotextPermissions;
    if (ATColDef[2].filter.val) {
      if (
        firmpermissionsTemp !== undefined &&
        firmpermissionsTemp[1] !== undefined
      ) {
        firmpermissionsTemp &&
          !firmpermissionsTemp[1].actionable &&
          (ATColDef[3].column.onClick = () => { }) &&
          (ATColDef[3].column.className =
            "p-button-success p-mr-2 time-button timer-disable-hide");

        if (!firmpermissionsTemp[1].actionable) {
          setAddNewPermission(true);
        }
        if (firmpermissionsTemp[1].actionable) {
          setAddNewPermission(false);
        }
      }
      if (permissionsTemp !== undefined && permissionsTemp[1] !== undefined) {
        permissionsTemp &&
          !permissionsTemp[1].actionable &&
          // (ATColDef[3].column.onClick = () => { }) &&
          (ATColDef[3].column.className =
            "p-button-success p-mr-2 time-button timer-disable-hide");

        if (!permissionsTemp[1].actionable) {
          setAddNewPermission(true);
        }
      }
    } else {
      if (
        firmpermissionsTemp !== undefined &&
        firmpermissionsTemp[1] !== undefined
      ) {
        firmpermissionsTemp &&
          !firmpermissionsTemp[1].actionable &&
          (ATColDef[3].column.onClick = () => { }) &&
          (ATColDef[3].column.className =
            "p-button-success p-mr-2 time-button");
      }
      if (permissionsTemp !== undefined && permissionsTemp[1] !== undefined) {
        permissionsTemp &&
          !permissionsTemp[1].actionable &&
          (ATColDef[3].column.onClick = () => { }) &&
          (ATColDef[3].column.className =
            "p-button-success p-mr-2 time-button");
        if (!permissionsTemp[1].actionable) {
          setAddNewPermission(true);
        }
        if (permissionsTemp[1].actionable) {
          setAddNewPermission(false);
        }
      }
    }

    // set to loading
    // if (!dataLoaded || reset) {
    setLoading(true);
    setDataLoaded(true);
    let page = 1;
    // build query string
    if (reset) {
      setPageNumber(1);
      if(DataFromAPI && DataFromAPI[0] && DataFromAPI[0].editMode && DataFromAPI[0].tempRow)
      { 
        setNewAutotextTempRowEnable(false);
      }
      else
      {
        setNewAutotextTempRowEnable(true);
      }
    } else {
      // page = pageNumber + 1;
      // setPageNumber(pageNumber + 1);
    }

    /**
     * build query string
      */
   
    let searchParams = buildQueryString(
      reset ? 1 : pageNumber,
      ...colDef.map((d) => d.filter.val),
      sortName,
      sortby
    );

    shortcut
      .get(searchParams, firstCallView ? HeaderView.AutotextViewApiCallHeaderName : null, sortName, sortby)
      .then((data) => {
        setLoadingError(false);
        setDataLoaded(true);

        /**
         * if reset is true reset the data in the state with the response data as the api call is for 1st page of list
         * else if reset is false that means the res is from lazy loading and is from 2nd page or higher.So append the 
         * data to existing state
        */

        if (reset) {
          let captureTemporaryEditedData = DataFromAPI && DataFromAPI[0] && DataFromAPI[0].editMode && DataFromAPI[0].tempRow?[DataFromAPI[0]]:[];
          setDataFromAPI(JSON.parse(JSON.stringify([...captureTemporaryEditedData,...data])));
          setDataFromAPIBackup(JSON.parse(JSON.stringify([...captureTemporaryEditedData,...data])));
          // sortData(JSON.parse(JSON.stringify(formattedJson.data)));
          if (data.length === 0) {
            setNoItemsFoundMessage(true);
            setNoItemsFoundMessageText({
              titleText: "No autotext records found!!",
              buttonText: "Add New",
            });
          }
          else {
            page = 1
            if (data.length < (page * NO_OF_ROWS_IN_GRID)) {
              setScrollNoMore(true);
              //setPageNumber(pageNumber)
            } else {
              setPageNumber(page + 1);
              setScrollNoMore(false);
              // setReset(false);
            }

          }
          // setScrollNoMore(false);
        } else {
          setDataFromAPI(
            JSON.parse(JSON.stringify([...DataFromAPIBackup, ...data]))
          );
          setDataFromAPIBackup(
            JSON.parse(JSON.stringify([...DataFromAPIBackup, ...data]))
          );
          page = pageNumber
          if ((data.length + DataFromAPIBackup.length) < (pageNumber * NO_OF_ROWS_IN_GRID)) {
            setScrollNoMore(true)
          } else {
            setScrollNoMore(false);
            setPageNumber(page + 1);
          }
          // sortData([...DataFromAPI, ...data], sort)
        }
      })
      .catch((err) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoadingError(err || "Error");
      })
      .finally(() => {
        setLoading(false);
        setDataLoaded(true);
      });
      setFirstCallView(false);
    //}
  };

  /** 
  *  sorts the columns of the grid individually
  * @param {Array} data - array of objects for grid of time entry
  * @param {Object} localSort - contains the sort object
  */
   const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc? "asc":"desc";
      
      let sortName = "";
        switch(localSort.propName) {        
          case 'shortcut':
            sortName = "shortcut"
            break;
          case 'fulltext':
            sortName = "full_text"
            break;
          case 'firmlevel':
            sortName = "firmlevel"
            break;
        }

      setSortByField(sortBy);
      setSortNameField(sortName);

      loadData(true, sortName,sortBy)
    }
  };

  /**
    * manage time api is called with the selected filters as query params
    * @param {String} key - column name
     * @param {String} value - searched text or toggle value
     * @param {Number} index -index of the filter column 
     */

  const onFilterChange = (key, value, index) => {
    if(key==="firmlevel" && DataFromAPI[0] && DataFromAPI[0].tempRow)
    {
      let tempArray=DataFromAPI;
      tempArray.shift();
      setDataFromAPI(tempArray);
    }
    setPageNumber(1);
    setScrollNoMore(false);
    setSort({ index: -1, isAsc: true, propName: "" });
    jsUtils.debounce(loadData, 400, true, sortNameField, sortByField);
    setFilters(key, value, index);
    setScrollToTop();
    let resetSortParams = {
      index: -1,
      isAsc: true,
      propName: "",
    }
    const defaultValue = ["","","",true];
    let filterStatus = jsUtils.checkIfCustomTableFilterChanged(colDef,defaultValue)
    let sortStatus =  _.isEqual(sort, resetSortParams);
    setIsResetButtonActive(filterStatus && sortStatus);
  };

  /**
   * it updates the cell
   * @param {String} propName - column name
   * @param {String} newData - edited or updated data 
   */
  const updateACellOrProp = (rowId, propName, newData) => {
    if(newData.indexOf("\n") !==-1)
      return;
    if (propName === "shortcut") {
      newData = newData.toLowerCase();
      if (newData.length > 30) {
        let data = newData.slice(0, 30);
        newData = data;
      }
    }
    if (propName === "fulltext") {
      if (newData.length > 2000) {
        let data = newData.slice(0, 2000);
        newData = data;
      }
    }

    jsUtils.updateACellOrProp(
      rowId,
      propName,
      newData,
      DataFromAPI,
      setDataFromAPI
    );
  };

  /**
   *  delete the shortcut 
   * @param {Object} rowdata - current selected rowdata 
   */
  const deleteRow = async (rowData) => {
    setLoading(true);
    let errorUpdating = false;
    await shortcut
      .delete(rowData.rowId, rowData)
      .then((res) => {
        setLoading(false);
        errorUpdating = true;
        dispatch(
          setErrorMessage({
            severity: "success",
            message: res && getCode("AUTOTEXT_DELETED_SUCCESSFULLY"),
            errorCode: res.responseCode,
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
        dispatch(
          setErrorMessage({
            severity: "error",
            message: err && getCode(err.message),
            errorCode: err.responseCode,
          })
        );
      });
    if (errorUpdating) {
      jsUtils.deleteRow(
        rowData.rowId,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        setDataFromAPIBackup
      );
    }
  };

  /**
   *  add a new empty row
   * @param {Object} rowToAdd - new empty row 
   */
  const addRow = (rowToAdd) => {
    setCountEditOrNewRow(countEditOrNewRow + 1);
    dispatch(setUnsavedChanges(true))
    jsUtils.addRow(
      rowToAdd,
      DataFromAPI,
      DataFromAPIBackup,
      setDataFromAPI,
      setDataFromAPIBackup
    );
  };

  /**
   * make a row edittable/non editable
   * @param {Number} rowId - current rowId of the edited row
   * @param {Number} editModeValue - value that has 1/0 where 1 is for editable and 0 for non-editable
   */
  const editMode = (rowId, editModeValue) => {
    setCountEditOrNewRow(countEditOrNewRow + 1);
    dispatch(setUnsavedChanges(true))
    jsUtils.editMode(
      rowId,
      editModeValue,
      DataFromAPI,
      DataFromAPIBackup,
      setDataFromAPI,
      setDataFromAPIBackup
    );

  };

  const getErrorWords = (id) => {
    let element = document.getElementById(id);
    let reds = element && element.querySelector('.red');
    return reds;
  };
  /**
   *  save a autotext
   * @param {Number} rowId
   */
  const save = async (rowId) => {
    dispatch(setAutotextAction({ autotextAction: { action: "save", rowId: rowId } }));
    if (getErrorWords("full_"+rowId) || getErrorWords("shrt_"+rowId)) {
      setShowRWValidationMsg(true);
      return;
    }
    // if (rowId > 100000) {
    //   if ((autotextDetails && autotextDetails.enteredRestrictedWordsFulltext) ||  autotextDetailsShortcut.enteredRestrictedWordsShortcut) {
    //     setShowRWValidationMsg(true);
    //     return;
    //   }
    // } else {
    //   if (autotextDetails && autotextDetails.enteredRestrictedWordsFulltext) {
    //     setShowRWValidationMsg(true);
    //     return;
    //   }
    // }
    let updatedRow = {
      ...DataFromAPI.find((element) => element.rowId === rowId),
    };
    let errorUpdating = false;
    // make the pai call
    let postData = {
      shortcut: updatedRow.shortcut,
      fulltext: updatedRow.fulltext,
      firmlevel: colDef[2].filter.val ? true : false,
    };

    /**
     * update a shortcut only when it is above the max chars which is 30
     */
    if (updatedRow.shortcut.length < MAX_CHARS_SHORTCUT) {
      setLoading(true);
      if (updatedRow.tempRow) {
        await shortcut
          .post(postData)
          .then((d) => {
            if ((countEditOrNewRow - 1) === 0) {
              dispatch(setUnsavedChanges(false))
            }
            setCountEditOrNewRow(countEditOrNewRow - 1);
            setLoading(false);
            updatedRow.rowId = d.id;
            updatedRow.editAll = false;
            setNewAutotextTempRowEnable(true);
            dispatch(
              setErrorMessage({
                severity: "success",
                message: d && getCode("AUTOTEXT_ADDED_SUCCESSFULLY"),
                errorCode: d.responseCode,
              })
            );
          })
          .catch((err) => {
            setLoading(false);
            dispatch(
              setErrorMessage({
                severity: "error",
                message: err && getCode(err.message),
                errorCode: err.responseCode,
              })
            );
            errorUpdating = true;
          });
        updatedRow.tempRow = false;
      } else {
        updatedRow.firmlevel = colDef[2].filter.val ? true : false;

        await shortcut.put(updatedRow.rowId, updatedRow).then((res) => {
          setLoading(false);
          if ((countEditOrNewRow - 1) === 0) {
            dispatch(setUnsavedChanges(false))
          }
          setCountEditOrNewRow(countEditOrNewRow - 1);
          dispatch(
            setErrorMessage({
              severity: "success",
              message: res && getCode("AUTOTEXT_UPDATED_SUCCESSFULLY"),
              errorCode: res.responseCode,
            })
          );
        }).catch((err) => {
          setLoading(false);
          dispatch(
            setErrorMessage({
              severity: "error",
              message: err && getCode(err.message),
              errorCode: err.responseCode,
            })
          );
          errorUpdating = true;
        });
      }

      if (!errorUpdating) {
        updatedRow.editMode = 0;

        jsUtils.updateRowData(
          rowId,
          updatedRow,
          DataFromAPI,
          DataFromAPIBackup,
          setDataFromAPI,
          setDataFromAPIBackup
        );
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: getCode("AUTOTEXT_CANNOT_BE_SAVED"),
        detail: getCode("REPLACE_VALUE_CANNOT_LONGER_THAN_30_CHARACTERS"),
        life: 5000,
      });
    }
  };

  /**
   *  remove the newRow or cancel the changes to the current row 
   * @param {Object} rowItem - contains the details of the selected row
   */
  const cancel = async (rowItem) => {
    if (rowItem.tempRow) {

      if ((countEditOrNewRow - 1) === 0) {
        dispatch(setUnsavedChanges(false))
      }
      setCountEditOrNewRow(countEditOrNewRow - 1);
      jsUtils.deleteRow(
        rowItem.rowId,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        setDataFromAPIBackup
      );
      setNewAutotextTempRowEnable(true);
    } else {
      //
      editMode(rowItem.rowId, 0);
      let oldRow = DataFromAPIBackup.find(
        (element) => element.rowId === rowItem.rowId
      );
      if ((countEditOrNewRow - 1) === 0) {
        dispatch(setUnsavedChanges(false))
      }
      setCountEditOrNewRow(countEditOrNewRow - 1);
      oldRow.editMode = 0;
      jsUtils.updateRowData(
        rowItem.rowId,
        oldRow,
        DataFromAPI,
        DataFromAPIBackup,
        setDataFromAPI,
        null
      );
    }
  };

  const onSetshowRWValidationMsg = (flag) => {
    setShowRWValidationMsg(flag);
  }

  if (signingOut) {
    return (
      <div className="spinner-popup">
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className='af-autotext-page'>
        <Toast ref={toast} />

        {showRWValidationMsg && (
        <ConfirmationBox
          message={
            getCode(
              "YOUR_NARRATIVE_INCLUDES_RESTRICTED_WORDS_YOU_SHOULD_REPLACE_ALL_RESTRICTED_WORD_ERRORS_BEFORE_SAVING"
            )
          }
          showRWValidationMsg={showRWValidationMsg}
          setPopupVisibleToggle={(flag)=>{onSetshowRWValidationMsg(flag)}}
          showOkButton = {true}

        />
      )}

        

        {loadingClient === false &&
          //  loadingTimeKeeper === false &&
          
            <div className="p-tabview p-component">
              <div className="datatable-filter-demo">
                <div className="card af-autotext-grid">
                  {delegateId && (
                    <CustomTable
                      rows={NO_OF_ROWS_IN_GRID}
                      colDef={colDef}
                      parentMethods={{
                        updateRowData: jsUtils.updateRowData,
                        updateACellOrProp,
                        addRow,
                        deleteRow,
                        editMode,
                        save,
                        cancel,
                      }}
                      data={DataFromAPI}
                      dataLoaded={dataLoaded}
                      isLoading={loading}
                      enableRowExpansion={false}
                      loadingError={loadingError}
                      noItemsFoundMessage={noItemsFoundMessage}
                      noItemsFoundMessageText={noItemsFoundMessageText}
                      onFilterChange={onFilterChange}
                      onLazyScroll={() => {
                        if (loading === false && !scrollNoMore) {
                          loadData(false,sortNameField, sortByField);
                        }
                      }}
                      onSort={(index, isAsc, propName) => {
                        setSort({ index, isAsc, propName });
                        sortData({ index, isAsc, propName });
                        setIsResetButtonActive(false);
                      }}
                      sort={sort}
                      addNewButton={addNewPermission}
                      pageNumber={pageNumber}
                      scrollNoMore={scrollNoMore}
                      moveScrollToTop={moveScrollToTop}
                      resetScrollFlag={() => setMoveScrollToTop(false)}
                      resetButtonActive={isResetButtonActive}
                    />
                  )}
                </div>
              </div>
            </div>
          
        }
      </div>
    );
  }
}
