import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import React, { useState, useEffect } from "react";
import { UserColDef, buildQueryString } from "./UserColDef";
import EditUser from "./EditUser";
import UserCard from "./UserCard";
import { userApi } from "../../../../services/userapis";
import utils from "../../../../utils/jsUtils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { refreshStoreOnError } from "../../../../actions/AppAction";
import { NO_OF_ROWS_IN_GRID } from "../../../../config/messages";
import CustomTable from "../../../common/DataTable/CustomTable";
import jsUtils from "../../../../utils/jsUtils";
import _ from "lodash";
import {HeaderView } from "../../../../config/literalCodes";

export default function UsersTable(props) {
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [loadingCard, setLoadingCard] = useState(false)

  const[moveScrollToTop , setMoveScrollToTop] = useState(false);
  const delegateId = useSelector(
    (state) => state.TimeManagementReducer.delegateId
  );
  const [checkAll, setCheckAll] = useState(false);
  const [editPosition,setEditPosition] = useState(0);
  const [usersEditList,setUsersEditList] = useState([]);
  const [bulkEdit,setBulkEdit] = useState(false);

  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [firstCallView,setFirstCallView]=useState(true);
  const [isResetButtonActive, setIsResetButtonActive] = useState(true);

  /**
   *   gets the labels/tooltips/error codes from state codeFromAPI
   * @param {String} key - literals key
   * @returns the value associated for that particular key and if it does not find the key then return the key itself
   */
 
  const dispatch = useDispatch();

  useEffect(() => {
    if (delegateId !== "") {
      userApi
        .getPermissions(delegateId)
        .then((d) => {
          let fieldNames = [];
          d.roles.forEach((data, index) => {
            if (data.ui_display_config !== null) {
              data.ui_display_config.uiPageJson.permissions.components.forEach(
                (fields, i) => {
                  fieldNames.push(fields);
                }
              );
            }
          });

          let fieldEdit = fieldNames && fieldNames.find(name => name.id === "usergird") && fieldNames.find(name => name.id === "usergird").fields.find(name => name.id === "edit")
          if (fieldEdit && !fieldEdit.actionable) {
            let state = colDef;
            colDef[colDef.length - 1].data.className = 'uGrid-btn timer-disable-hide'
            setColDef([...state]);
          }
          else {
            let state = colDef;
            colDef[colDef.length - 1].data.className = 'uGrid-btn'
            setColDef([...state]);
          }
        });
    }
  }, [delegateId])

  /**
    *  - checks and unchecks the header/rowData of ready entries for releasing
    * @param {Boolean} deselectColumn - toggle to select and deselect column
    * @param {object} rowItem - contains the ready item checked for being released
   */
   const bulkSelectToggle = (deselectColumn, rowItem) => {
    let data = DataFromAPI;
    if (deselectColumn ) {
      if(UserColDef[0].column.val)
      {  
      let state = UserColDef;
      state[0].column.val = !state[0].column.val;
      setColDef([...state]);
      setCheckAll(false);
      }
      data.forEach((item) => {
        if (item.rowId === rowItem.rowId) {
          item.isChecked = rowItem.isChecked;
        }
      });

    } else {
      if (!UserColDef[0].column.val) {
        data.forEach((item) =>item.isChecked = true);
        setCheckAll(true);
      } else {
        data.forEach((item) =>item.isChecked = false);
        setCheckAll(false);
      }
    let state = UserColDef;
    state[0].column.val = !state[0].column.val;
    setColDef([...state]);
    }
    
    setDataFromAPI(JSON.parse(JSON.stringify(data)));
    setDataFromAPIBackup(JSON.parse(JSON.stringify(data)));
  };


  const edit_click = (rowItem) => {
    !bulkEdit && setEditUserPopup(true);
    setPopupNav("User Access");
    //setAction("Edit");
    // document.title = "AttorneyFlow - User Access";
    setSelectedRowData({
      username: rowItem.username,
      user_id: rowItem.user_id,
    });
  };

  const resetSelectToggle=()=>{
    let state = UserColDef;
      state[0].column.val = false;
      setColDef([...state]);
      setCheckAll(false);
  }

  const onBulkEdit=(data)=>{
    setEditUserPopup(true)
    edit_click(data[0])
    setBulkEdit(true)
    setUsersEditList(data)
    resetSelectToggle()
  }

  const onTraverseNext=(rowdata)=>{
    edit_click(rowdata);
  }

  UserColDef[UserColDef.length - 1].filter.onClick = () => {
      resetFilters();
      loadData(true);
    setIsResetButtonActive(true);
  };

  UserColDef[UserColDef.length - 1].column.onClick = UserColDef[
    UserColDef.length - 1
  ].data.click = () => {
    setShowAddUserPopup(true);
  };

  const [colDef, setColDef] = useState(UserColDef);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });
  const [EditUserPopup, setEditUserPopup] = useState(false);
  const [popupNav, setPopupNav] = useState("");
  const [closeAllCardsRefresh, setCloseAllCardsRefresh] = useState(0)
  let setScrollToTop = () => {

    setMoveScrollToTop(true);
  }
  let setFilters = (key, value, index) => {
    let state = colDef;
    colDef[index].filter.val = value;
    setColDef([...state]);
  };
  let resetFilters = () => {
    let state = colDef;
    colDef[0].filter.val = "";
    colDef[1].filter.val = "";
    colDef[2].filter.val = "";
    colDef[3].filter.val = "";
    colDef[4].filter.val = "";
    colDef[5].filter.val = "";
    colDef[6].filter.val = "";
    sort.index = -1;
    setSort({ index: -1, isAsc: true, propName: "" });
    setColDef([...state]);
    setScrollToTop();
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setIsResetButtonActive(true);
  };

  const onFilterChange = (key, value, index) => {
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setPageNumber(1);
    // document.getElementsByClassName("p-datatable-scrollable-body").scrollTop = 0;
    setScrollNoMore(false);
    utils.debounce(loadData, 400, true, sortNameField, sortByField);
    setFilters(key, value, index);
    setScrollToTop();
    let resetSortParams = {
      index: -1,
      isAsc: true,
      propName: "",
    }
    const defaultValue = ["","","","","","",""];
    let filterStatus = jsUtils.checkIfCustomTableFilterChanged(colDef,defaultValue)
    let sortStatus =  _.isEqual(sort, resetSortParams);
    setIsResetButtonActive(filterStatus && sortStatus);
  };

  const [DataFromAPI, setDataFromAPI] = useState([]);
  const [loadingError, setLoadingError] = useState(false);
  const [DataFromAPIBackup, setDataFromAPIBackup] = useState([]);
  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [scrollNoMore, setScrollNoMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);

  useEffect(() => {
    loadData(true);
  }, []);

  useEffect(() => {
    return ()=>{
    resetFilters();
    resetSelectToggle();}
  }, []);

  /** Loads list of user based on the page number
   * @param {Boolean} reset - if true it will load first page
   */

  const loadData = (reset = true, sortName="", sortby="") => {
    // set to loading
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setLoading(true);
    setDataLoaded(true);

    let page = 1;
    // build query string
    if (reset) {
      setPageNumber(1);
      // document.getElementsByClassName("p-datatable-scrollable-body").scrollTop = 0;
    } 
    let searchParams = buildQueryString(page, sortName, sortby);

    userApi
      .users(searchParams, firstCallView ? HeaderView.AccessViewCallHeaderName : null, reset ? 1 : pageNumber, sortName, sortby)
      .then((res) => {        
        setLoadingError(false);
        let formattedJson = { data: [] };
        setDataLoaded(true);
        if (res && res.userData) {
          res.userData.forEach((user) => {
            // let usrId = user.employee_id ? user.employee_id : ' - ';
            // let nameWithId = user.lastname + ", " + user.firstname + " (" + usrId + ")";
            let nameWithId = user.lastname + ", " + user.firstname;
            let clientItem = {
              rowId: user.sys_user_id,
              user_id: user.sys_user_id,
              username: user.username ? user.username : "",
              effective_start_date: user.effective_start_date,
              email: user.email ? user.email : "",
              preferred_name: user.preferred_name ? user.preferred_name : "",
              //mobile: user.mobile ? validationUtils.formatPhoneNumber(user.mobile) : "",
              mobile: user.mobile ? user.mobile : "",
              cognito_user_id: user.cognito_user_id ? user.cognito_user_id : "",
              user_initials: user.user_initials,
              full_name: user.firstname + " " + user.lastname,
              UserNameWithId: nameWithId,
              profileImg:user.user_profile_pic_s3_url
            };
            checkAll
                  ? (clientItem["isChecked"] = true)
                  : (clientItem["isChecked"] = false);
            formattedJson.data.push(clientItem);
          });
          if (reset) {
            setDataFromAPI(JSON.parse(JSON.stringify(formattedJson.data)));
            setDataFromAPIBackup(
              JSON.parse(JSON.stringify(formattedJson.data))
            );
            if (formattedJson.data.length === 0) {
              setNoItemsFoundMessage(true);
              setNoItemsFoundMessageText({
                titleText: "User Not Found!",
                buttonText: null,
              });
            }
            else {
              page = 1;
              if (formattedJson.data.length < (page * NO_OF_ROWS_IN_GRID)) {
                setScrollNoMore(true);
                //setPageNumber(pageNumber)
              } else {
                setPageNumber(page + 1);
                setScrollNoMore(false);
                // setReset(false);
              }

            }
            // setScrollNoMore(false);
          } else {
            setDataFromAPI(
              JSON.parse(
                JSON.stringify([...DataFromAPIBackup, ...formattedJson.data])
              )
            );
            setDataFromAPIBackup(
              JSON.parse(
                JSON.stringify([...DataFromAPIBackup, ...formattedJson.data])
              )
            );
            //  formattedJson.data.length === 0 && setScrollNoMore(true);
            page = pageNumber;
            if ((formattedJson.data.length + DataFromAPIBackup.length) < (pageNumber * NO_OF_ROWS_IN_GRID)) {
              setScrollNoMore(true)
            } else {
              setScrollNoMore(false);
              setPageNumber(page + 1);
            }
            // sortData([...DataFromAPIBackup, ...formattedJson.data], sort);
          }
        }
      })
      .catch((error) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoadingError(error || "Error");
        setDataFromAPI([]);
        setDataFromAPIBackup([]);
      })
      .finally(() => {
        setLoading(false);
        setDataLoaded(true);
      });
      setFirstCallView(false);
  };

  /** Sort list of user based on the sorting paramters
   * @param {Array} localSort - Sorting parameters
   * @param {Object} data - Users data for sorting
   */
  const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc? "asc":"desc";
      
      let sortName = "";
        switch(localSort.propName) {        
          case 'UserNameWithId':
            sortName = "name"
            break;
          case 'preferred_name':
            sortName = "preferredname"
            break;
          case 'cognito_user_id':
            sortName = "username"
            break;
          case 'email':
            sortName = "email"
            break;
          case 'mobile':
            sortName = "mobileno"
            break;
        }

      setSortByField(sortBy);
      setSortNameField(sortName);

      loadData(true, sortName,sortBy)
    }
  };

  const onLoadingcard = (loadCard) => {
    setLoadingCard(loadCard)
  }

  return (
    <div>
      {EditUserPopup && (
        <EditUser

          show={EditUserPopup}
          header={popupNav}
          closed={() => {
            document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_USERS")
            setEditUserPopup(false);
            resetFilters();
            loadData(true);
            document.getElementsByClassName("p-datatable-scrollable-body").scrollTop = 0;
            setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
            setBulkEdit(false);
            setEditPosition(0);
            setUsersEditList([])

          }}
          pageTitle={"ATTORNEYFLOW_USER_ACCESS"}
          bulkEdit={bulkEdit}
          userList={usersEditList}
          onTraverseNext={onTraverseNext}
          setEditPosition={setEditPosition}
          editPosition={editPosition}
          selectedRowData={selectedRowData}
          action={"editUser"}
          userNameIsDisabled={true}
          roleStartDateIsDisabled={true}
        />
      )}
      {showAddUserPopup && (
        <EditUser

          show={showAddUserPopup}
          header={popupNav}
          closed={() => {
            document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_USERS")
            setShowAddUserPopup(false);
            resetFilters();
            loadData(true);
            document.getElementsByClassName("p-datatable-scrollable-body").scrollTop = 0;
            setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
            setBulkEdit(false);
            setEditPosition(0);
            setUsersEditList([])

          }}
          pageTitle={"ATTORNEYFLOW_USER_ACCESS"}
          bulkEdit={bulkEdit}
          userList={usersEditList}
          onTraverseNext={onTraverseNext}
          setEditPosition={setEditPosition}
          editPosition={editPosition}
          selectedRowData={selectedRowData}
          action={"addUser"}
          userNameIsDisabled={false}
          roleStartDateIsDisabled={false}
        />
      )}
      <div className="datatable-filter-Users">
        <div className="card af-users-datagrid">
          <CustomTable
            rows={NO_OF_ROWS_IN_GRID}
            colDef={colDef}
            parentMethods={{
              edit_click,
              bulkSelectToggle,
              onBulkEdit,
              setShowAddUserPopup
            }}
            data={DataFromAPI}
            dataLoaded={dataLoaded}
            isLoading={loading}
            loadingError={loadingError}
            noItemsFoundMessage={noItemsFoundMessage}
            noItemsFoundMessageText={noItemsFoundMessageText}
            closeAllRefresh={closeAllCardsRefresh}
            loadingCard={loadingCard}
            onFilterChange={onFilterChange}
            onLazyScroll={() => {
              if (loading === false && !scrollNoMore) {
                loadData(false, sortNameField, sortByField);
              }
            }}
            rowExpansionElement={(rowItem) => {
              return (
                <div>
                  <UserCard userid={rowItem.user_id} onLoadingCard={onLoadingcard} />
                </div>
              );
            }}
            onSort={(index, isAsc, propName) => {
              setSort({ index, isAsc, propName });
              sortData({ index, isAsc, propName });
              setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
              setIsResetButtonActive(false);
            }}
            sort={sort}
            pageNumber={pageNumber}
            scrollNoMore={scrollNoMore}
            moveScrollToTop={moveScrollToTop}
            resetScrollFlag={()=>setMoveScrollToTop(false)}
            resetButtonActive={isResetButtonActive}
          />
        </div>
      </div>
    </div>
  );
}
