export var codes = {
  "12_HOURS": "12 hours",
  "15_MINUTES_(DEFAULT)": "15 minutes (Default)",
  ACCESS: "Access",
  ACCOUNT: "Account",
  ACCOUNT_LOCKED_OUT: "The account has been locked out, please reset your password",
  ACCOUNT_MENU: "Account menu",
  ACKNOWLEDGE: "Acknowledge",
  ACTIVE_ONLY: "Active Only",
  ACTIVE_TIMER: "Active Timer",
  ACTIVITY: "Activity",
  ACTIVITY_CODE: "Activity Codes",
  ADD: "Add",
  ADD_NEW: "Add New",
  ADD_ROLE: "Add Role ",
  ADD_RULE: "Add Rule",
  ADD_TIME: "Add Time",
  ADD_TIME_ENTRY: "Add Time Entry",
  ALL: "All",
  ALL_FOUR_FIELDS_REQUIRED: "All four fields are required to save",
  ALL_SELECTED_TIME_ENTRIES_HAVE_ETHICAL_WALLS_AND_CANNOT_BE_EDITED: "All selected time entries have ethical walls and cannot be edited",
  AMOUNT: "Amount",
  AMOUNT_HEADER: "Amount",
  APPLICATION_ID: "Application (client) ID",
  APPLY_SAME_ROLES_AS: "Apply same roles as:",
  APPLY_THIS_TIME_ENTRY_DAILY_FROM_THE_DATE_SPECIFIED_ABOVE_TO_THIS_DATE_BELOW: "Apply this time entry daily from the date specified above to this date below:",
  ARE_YOU_SURE_YOU_WANT_TO_COMBINE_THE_SELECTED_TIME_ENTRIES: "Are you sure you want to combine the selected time entries?",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE: "Are you sure you want to delete?",
  ARE_YOU_SURE_YOU_WANT_TO_RELEASE_THE_SELECTED_TIME_ENTRIES: "Are you sure you want to release the selected time entries?",
  ARE_YOU_SURE_YOU_WANT_TO_RELEASE_THE_SELECTED_TIME_ENTRY: "Are you sure you want to release the selected time entry?",
  ASSIGNED_ROLE: "Assigned Role",
  AT: "at",
  ATTACHMENT_LIMIT_VALIDATION_MESSAGE: "Some of the time entries cannot be combined because the total file attachments exceed the maximum count or size",
  ATTORNEY: "attorney",
  ATTORNEYFLOW_: "SurePoint -",
  ATTORNEYFLOW_ADD_TIME: "SureTime - Add Time",
  ATTORNEYFLOW_AUTOTEXT: "SurePoint - Autotext",
  ATTORNEYFLOW_BULK_UPDATE: "SureTime - Bulk Update",
  ATTORNEYFLOW_DAY_VIEW: "SureTime - Day View",
  ATTORNEYFLOW_DELEGATES: "SurePoint - Delegates",
  ATTORNEYFLOW_DRAFT_TIME_ENTRY: "SureTime - Draft Time Entry",
  ATTORNEYFLOW_GENERAL_TIME_ENTRY: "SureTime - General Time Entry",
  ATTORNEYFLOW_HOME: "SurePoint - Home",
  ATTORNEYFLOW_LOG_IN: "SurePoint - Log In",
  ATTORNEYFLOW_MANAGE_TIME: "SureTime - Manage Time",
  ATTORNEYFLOW_MONTH_VIEW: "SureTime - Month View",
  ATTORNEYFLOW_MY_PROFILE: "SurePoint - My Profile",
  ATTORNEYFLOW_PREFERENCES: "SurePoint - Preferences",
  ATTORNEYFLOW_ROLES: "SurePoint - Roles",
  ATTORNEYFLOW_ROLE_ACCESS: "SurePoint - Role Access",
  ATTORNEYFLOW_SYSTEM: "SurePoint - System",
  ATTORNEYFLOW_TIME_ENTRY: "SureTime - Time Entry",
  ATTORNEYFLOW_USERS: "SurePoint - Users",
  ATTORNEYFLOW_USER_ACCESS: "SurePoint - User Access",
  ATTORNEY_NO: "Attorney #",
  AT_LEAST_ONE_DAY_MUST_BE_CHECKED: "At least one day must be selected",
  AT_LEAST_ONE_DAY_OF_WEEK_MUST_BE_CHECKED: "At least one day of week must be checked",
  AUTHENTICATION_CODE_SENT: "Authentication Code Sent",
  AUTOMATIC: "Automatic",
  AUTOMATIC_SESSION_TIMEOUT: "Automatic Session Timeout",
  AUTOTEXT: "Autotext",
  AUTOTEXT_ADDED_SUCCESSFULLY: "Autotext added successfully",
  AUTOTEXT_CANNOT_BE_SAVED: "Autotext cannot be saved",
  AUTOTEXT_DELETED_SUCCESSFULLY: "Autotext deleted successfully",
  AUTOTEXT_UPDATED_SUCCESSFULLY: "Autotext updated successfully",
  AUTO_CAPITALIZE: "Auto Capitalize",
  A_K_A: "a.k.a",
  BAD_REQUEST: "Bad Request",
  BILLABLE: "Billable",
  BILLABLE_AMOUNT: "Billable Amount:",
  BILLABLE_DURATION: "Billable Duration",
  BILLABLE_DURATION_ROUNDING_METHOD: "(value which is being sent in input API in preference api )",
  BILLABLE_HEADER: "Billable (hrs)",
  BILLABLE_HOURS: "Billable Hrs:",
  BILLABLE_HOURS_MONTH: " Billable Hours",
  BILLING_STAFF_LEVEL: "Billing Staff Level",
  BLANK_SELECTED: "blank selected",
  BULK_CHANGE: "Bulk Change",
  BULK_CHANGE_SELECTED_TIME_ENTRIES: "Bulk Change Selected Time Entries",
  BULK_EDIT_CHECKBOX_VALIDATION_MESSAGE: "Some of the time entries cannot be bulk changed due to an ethical wall, their current status, or them being a general time entry. Do you want to change the other time entries?",
  BULK_EDIT_FAILED: "Bulk update failed.",
  BULK_EDIT_SUCCESS: "Time update was successful.",
  BY: "by",
  B_LABEL: "B",
  CANCEL: "Cancel",
  CHANGE: "Change",
  "CHANGING_DEFAULT_SESSION_TIMEOUT_!": "Changing Default Inactivity Timeout",
  CHARACTER_LENGTH: "Character Length",
  CHARACTER_LENGTH_MAINTENANCE: "Character Length Maintenance",
  CHARACTER_LIMIT_ADDED_SUCCESSFULLY: "Character limit added successfully",
  CHARACTER_LIMIT_ALREADY_EXISTS: "Character limit already exists",
  CHARACTER_LIMIT_UPDATED_SUCCESSFULLY: "Character limit updated successfully",
  CHARS_REMAINING: "chars remaining",
  CITY: "City",
  CITY_CODE: "City Code",
  CLE: "Continuing Legal Education (CLE)",
  CLEAR: "Clear",
  CLEAR_TIME: "Clear time",
  CLIENT: "Client",
  CLIENT_MATTER_NOT_FOUND: "Client/Matter Not Found",
  CLIENT_MUST_BE_ENTERED: "Client must be entered.",
  CLIENT_NUMBER: "Client (number)",
  CLIENT_SECRET: "Client Secret",
  CLIENT_UPDATED_SUCCESSFULLY: "Client updated successfully",
  CLOSE: "Close",
  CLOUD_CALENDAR: "Cloud Calendar",
  CLOUD_CAL_CANNOT_BE_SET: "Cloud calendar preference cannot be set",
  CM_ATTACHMENT_DELETED: "Attachment Deleted",
  CM_BUTTON_LABEL_ADD: "Add",
  CM_BUTTON_LABEL_ADD_ATTACHMENT: "Add Attachment",
  CM_BUTTON_LABEL_ADD_KEYWORD: "Add Keyword",
  CM_BUTTON_LABEL_ADD_PARTY: "Add Party",
  CM_BUTTON_LABEL_ADD_RELATED_PARTY: "Add Related Party",
  CM_BUTTON_LABEL_BUILD_SEARCH: "Build Search",
  CM_BUTTON_LABEL_RETURN: "Return",
  CM_BUTTON_LABEL_SAVE_APPLY_SEARCHES: "Save & Apply to Searches",
  CM_BUTTON_LABEL_SAVE_CRITERIA_AND_SEARCH: "Save Criteria and Search",
  CM_BUTTON_LABEL_SAVE_PARTY_SOURCES: "Save Party Sources",
  CM_BUTTON_LABEL_SAVE_SEARCH_ENTITIES: "Save Search Entities",
  CM_BUTTON_LABEL_SEARCH: "Search",
  CM_BUTTON_LABEL_START_CONFLICT_SEARCH: "Start Conflict Search",
  CM_BUTTON_LABEL_UPDATE: "Update",
  CM_BUTTON_LABEL_UPDATE_SEARCH: "Update Search",
  CM_CONFIG_INVALID_END_DATE: "The End Date must fall after Start Date to proceed",
  CM_CONFIG_INVALID_START_DATE: "Start Date cannot be past date",
  CM_CONFIG_MISSING_FORMAL_SEARCH_RESULT_ID: "Formal Search Result Id Is Missing",
  CM_EXPORT_PARTIES_RESULTS_CSV: "Export Parties & Results to CSV",
  CM_EXPORT_PARTIES_RESULTS_EXCEL: "Export Parties & Results to Excel",
  CM_EXPORT_PARTIES_RESULTS_PDF: "Export Parties & Results to PDF",
  CM_FILE_TYPE_NOT_SUPPORTED: "This file type is not supported",
  CM_LABEL_1099_NAME: "1099 Name",
  CM_LABEL_ACCEPTED: "Accepted",
  CM_LABEL_ACTION: "Action",
  CM_LABEL_ACTIONS: "Actions",
  CM_LABEL_ACTION_TYPE: "Action Type",
  CM_LABEL_ACTIVE: "Active",
  CM_LABEL_ACTIVITY_CODE: "Activity Code",
  CM_LABEL_ADDRESS: "Address",
  CM_LABEL_ADDRESS_LINE_1: "Address Line 1",
  CM_LABEL_ADDRESS_LINE_2: "Address Line 2",
  CM_LABEL_ADDRESS_LINE_3: "Address Line 3",
  CM_LABEL_ADDRESS_LINE_4: "Address Line 4",
  CM_LABEL_ADDRESS_LINE_5: "Address Line 5",
  CM_LABEL_ADDRESS_LINE_6: "Address Line 6",
  CM_LABEL_ALL: "All",
  CM_LABEL_ALL_VALID: "All / Valid",
  CM_LABEL_ALSO_KNOWN_AS: "Also Known As",
  CM_LABEL_APPLIED: "Applied",
  CM_LABEL_APPLY: "Apply",
  CM_LABEL_APPLY_REMOVE: "Apply / Remove",
  CM_LABEL_AREA_OF_LAW: "Area of Law",
  CM_LABEL_ARE_YOU_SURE_YOU_WANT_TO_DELETE_ATTACHMENT: "Are you sure you want to delete this attachment?",
  CM_LABEL_ASSOCIATION: "Association",
  CM_LABEL_ATTACHMENTS: "Attachments",
  CM_LABEL_ATTACHMENT_FILE_NAME: "Attachment (max 100 MB)",
  CM_LABEL_ATTACHMENT_MAX_SIZE: "Attachment cannot be larger than 100 MB",
  CM_LABEL_ATTACHMENT_TITLE: "Attachment Title",
  CM_LABEL_ATTENTION_LINE: "Attention Line",
  CM_LABEL_AUTHORIZATION: "Authorization",
  CM_LABEL_AUTOMATICALLY_APPLY_WAIVERS: "Automatically Apply Waivers",
  CM_LABEL_AVAILABLE_WAIVERS: "Available Waivers",
  CM_LABEL_BILLING_ATTORNEY: "Billing Attorney",
  CM_LABEL_BILLING_NOTES: "Billing Notes",
  CM_LABEL_BOOK_NUMBER: "Book Number",
  CM_LABEL_BOX_NUMBER: "Box Number",
  CM_LABEL_BOX_NUMBER_1: "Box Number 1",
  CM_LABEL_BOX_NUMBER_2: "Box Number 2",
  CM_LABEL_BOX_NUMBER_3: "Box Number 3",
  CM_LABEL_BOX_NUMBER_4: "Box Number 4",
  CM_LABEL_BULK_ACTION: "Bulk Action",
  CM_LABEL_BULK_ACTIONS: "Bulk actions",
  CM_LABEL_BULK_ACTION_SELECTED_RESULTS: "Bulk Action Selected Results",
  CM_LABEL_BUTTON_TRANSFER_PARTIES_RESULTS: "Transfer Parties & Results",
  CM_LABEL_BY: "By",
  CM_LABEL_CASE: "Case",
  CM_LABEL_CASE_DESCRIPTION: "Case Description",
  CM_LABEL_CASE_NOTES: "Case Notes",
  CM_LABEL_CASE_TYPE: "Case Type",
  CM_LABEL_CATEGORY: "Category",
  CM_LABEL_CELL_PHONE_NUMBER: "Cell Phone Number",
  CM_LABEL_CITY: "City",
  CM_LABEL_CLIENT: "Client",
  "CM_LABEL_CLIENT_(ID)": "Client (ID)",
  CM_LABEL_CLIENT_CLOSE_DATE: "Client Close Date",
  CM_LABEL_CLIENT_ID: "Client (ID)",
  CM_LABEL_CLIENT_LAST_NAME: "Client Last Name",
  CM_LABEL_CLIENT_NAME: "Client Name",
  CM_LABEL_CLIENT_OPEN_DATE: "Client Open Date",
  CM_LABEL_CLIENT_STATUS: "Client Status",
  CM_LABEL_CLOSED_DATE: "Closed Date",
  CM_LABEL_COMPANY: "Company",
  CM_LABEL_COMPANY_NAME: "Company Name",
  CM_LABEL_COMPLETE: "COMPLETE",
  CM_LABEL_CONFLICT_ACTION_ATTACHMENTS: "Conflict Actions & Attachments",
  CM_LABEL_CONFLICT_WAIVER_ATTACHMENTS: "Conflict Waiver & Attachments",
  CM_LABEL_COST_CODE_ONE: "Cost Code One",
  CM_LABEL_COST_CODE_THREE: "Cost Code Three",
  CM_LABEL_COST_CODE_TWO: "Cost Code Two",
  CM_LABEL_COST_NARRATIVE: "Cost Narrative",
  CM_LABEL_COUNTRY_CODE: "Country Code",
  CM_LABEL_COVER_PAGE_NAME: "Cover Page Name",
  CM_LABEL_CREATED_BY: "Created By",
  CM_LABEL_CREATED_DATE: "Created Date",
  CM_LABEL_CREATED_ON: "Created On",
  CM_LABEL_CREATED_USER: "Created User",
  CM_LABEL_DATABASE: "Database",
  CM_LABEL_DATA_SYNC: "Data Sync",
  CM_LABEL_DECLINED: "Declined",
  CM_LABEL_DESCRIPTION: "Description",
  CM_LABEL_DESTROY: "Destroy",
  CM_LABEL_DESTROY_AUTHORIZATION: "Destroy Authorization",
  CM_LABEL_DESTROY_DATE: "Destroy Date",
  CM_LABEL_DESTROY_LOCATION: "Destroy Location",
  CM_LABEL_DRAFT: "Draft",
  CM_LABEL_DRAFT_CLIENT: "Draft Client",
  CM_LABEL_DRAFT_CLIENT_MATTER: "Draft Client & Matter",
  CM_LABEL_DRAFT_MATTER: "Draft Matter",
  CM_LABEL_DUE_DATE: "Due Date",
  CM_LABEL_EDIT_SEARCH_CRITERIA: "Edit Search Criteria",
  CM_LABEL_EMAIL_ADDRESS: "Email Address",
  CM_LABEL_ENTER_STANCE: "enter stance",
  CM_LABEL_EXPORT_PARTIES_RESULTS: "Export Parties and Search Results",
  CM_LABEL_FACILITY: "Facility",
  CM_LABEL_FAX_NUMBER: "Fax Number",
  CM_LABEL_FILE_DESCRIPTION: "Description",
  CM_LABEL_FILE_ID: "File ID",
  CM_LABEL_FILE_SIZE: "File Size",
  CM_LABEL_FIRM_LEVEL: "Firm Level",
  CM_LABEL_FIRST_CHAIR: "1st Chair",
  CM_LABEL_FIRST_OPEN_DATE: "First Open Date",
  CM_LABEL_FORMAL_SEARCH_PARAMETERS: "Formal Search Parameters",
  CM_LABEL_FOUND_IN: "Found in",
  CM_LABEL_FOURTH_CHAIR: "4th Chair",
  CM_LABEL_GL_ACCOUNT: "GL Account",
  CM_LABEL_GL_JOURNAL_NUMBER: "GL Journal Number",
  CM_LABEL_GL_SUB_ACCOUNT: "GL Sub Account",
  CM_LABEL_GROUP_NARRATIVE: "Group Narrative",
  CM_LABEL_HIGH: "High",
  CM_LABEL_HISTORY: "History",
  CM_LABEL_HITS: "Hits",
  CM_LABEL_HOME_PHONE_NUMBER: " Home Phone Number",
  CM_LABEL_HOURS: "Hours",
  CM_LABEL_ID: "ID",
  CM_LABEL_INCLUDE_RISK_SCRORE: "Include Risk Score",
  CM_LABEL_INDIVIDUAL: "Individual",
  CM_LABEL_INFORMAL_SEARCH: "Informal Search",
  CM_LABEL_INFORMAL_SEARCH_NAME: "Informal Search Name",
  CM_LABEL_INTERNATIONAL_PHONE_NUMBER: "International Phone Number",
  CM_LABEL_INVOICE_PRINT: "Invoice Print",
  CM_LABEL_INVOICE_STATEMENT_PRINT: "Invoice Statement Print",
  CM_LABEL_IPMS: "IPMS",
  CM_LABEL_JOURNAL_ENTRY: "Journal Entry",
  CM_LABEL_JOURNAL_ENTRY_AMOUNT: "Journal Entry Amount",
  CM_LABEL_JOURNAL_ENTRY_REFERENCE: "Journal Entry Reference",
  CM_LABEL_JURISDICTION: "Jurisdiction",
  CM_LABEL_KEYWORD: "Keyword",
  CM_LABEL_LAST_ADDRESS_TYPE: "Last Address Type",
  CM_LABEL_LAST_BILLED_DATE: "Last Billed Date",
  CM_LABEL_LAST_CHANGED_DATE: "Last Changed Date",
  CM_LABEL_LAST_CHANGED_USER: "Last Changed User",
  CM_LABEL_LAST_CLOSE_DATE: "Last Close Date",
  CM_LABEL_LAST_DATE_TIME_BILL: "Last Date Time Bill",
  CM_LABEL_LAST_FOUND: "Last Found",
  CM_LABEL_LAST_FOUND_ON: "Last Found On",
  CM_LABEL_LAST_NAME: "Last Name",
  CM_LABEL_LAST_PAYMENT_AMOUNT: "Last Payment Amount",
  CM_LABEL_LAST_PAYMENT_DATE: "Last Payment Date",
  CM_LABEL_LAST_RECEIPT_DATE: "Last Reciept Date",
  CM_LABEL_LAST_RE_OPEN_DATE: "Last Re-Open Date",
  CM_LABEL_LAST_TIME_REPORTED: "Last Time Reported",
  CM_LABEL_LAST_TIME_REPORTED_BY: "Last Time Reported By",
  CM_LABEL_LAST_TIME_REPORTED_DATE: "Last Time Reported Date",
  CM_LABEL_LMS_SOURCE: "Source",
  CM_LABEL_LOCATION: "location",
  CM_LABEL_LOCATION_CODE: "Location Code",
  CM_LABEL_LOW: "Low",
  CM_LABEL_MATCH: "Match",
  CM_LABEL_MATCH_SCORE: "Match score",
  CM_LABEL_MATTER: "Matter",
  "CM_LABEL_MATTER_(ID)": "Matter (ID)",
  CM_LABEL_MATTER_CLOSED_DATE: "Matter Closed date",
  CM_LABEL_MATTER_CLOSE_DATE: "Matter Close Date",
  CM_LABEL_MATTER_ID: "Matter (ID)",
  CM_LABEL_MATTER_LEVEL_NARRATIVE: "Matter Level Narrative",
  CM_LABEL_MATTER_NAME: "Matter Name",
  CM_LABEL_MATTER_OPEN_DATE: "Matter Open date",
  CM_LABEL_MATTER_STATUS: "Matter Status",
  CM_LABEL_MATTER_TYPE: "Matter Type",
  CM_LABEL_MIDDLE_NAME: "Middle Name",
  CM_LABEL_MOST_RECENT_ACTION: "Most Recent Action",
  CM_LABEL_NARRATIVE: "Narrative",
  CM_LABEL_NARRATIVE_DESCRIPTION: "Narrative Description",
  CM_LABEL_NARRATIVE_POINTER: "Narrative Pointer",
  CM_LABEL_NEW_SEARCH: "New Search",
  CM_LABEL_NO_WAIVERS_FOUND: "No Waivers Found",
  CM_LABEL_OFFSITE: "Offsite",
  CM_LABEL_OFFSITE_AUTHORIZATION: "Offsite Authorization",
  CM_LABEL_OFFSITE_DATE: "Offsite Date",
  CM_LABEL_OFFSITE_LOCATION: "Offsite Location",
  CM_LABEL_ON: "On",
  CM_LABEL_ONSITE: "Onsite",
  CM_LABEL_ONSITE_AUTHORIZATION: "Onsite Authorization",
  CM_LABEL_ONSITE_DATE: "Onsite Date",
  CM_LABEL_ONSITE_LOCATION: "Onsite Location",
  CM_LABEL_ONSITE_TEMP: "Onsite-Temp",
  CM_LABEL_ON_FILE: "On File",
  CM_LABEL_OPENED_DATE: "Opened Date",
  CM_LABEL_OPEN_PAYABLES: "Open Payables",
  CM_LABEL_OPEN_RISK: "Open Risk",
  CM_LABEL_OPEN_SEARCHES: "Open Searches",
  CM_LABEL_ORIGINAL_ATTORNEY: "Originating Attorney",
  CM_LABEL_ORIGINATING_ATTORNEY: "Originating Attorney",
  CM_LABEL_PARAMETERS_LAST_UPDATE: "Parameters last update",
  CM_LABEL_PARTIES: "Parties",
  CM_LABEL_PARTY: "Party",
  CM_LABEL_PARTY_CATEGORY: "Party Category",
  CM_LABEL_PARTY_DATA: "Party Data",
  CM_LABEL_PARTY_SOURCES: "Party Sources",
  CM_LABEL_PARTY_TYPE: "Party Type",
  CM_LABEL_PARTY_TYPES: "Party Types",
  CM_LABEL_PHONE_NUMBER: "Phone Number",
  CM_LABEL_POSTED_DURING_YEAR_PERIOD: "Posted During Year/Period",
  CM_LABEL_POSTED_TO_YEAR_PERIOD: "Posted To Year/ Period",
  CM_LABEL_PRACTICE_TYPE: "Practice Type",
  CM_LABEL_PROSPECTIVE_EXSITING_CLIENT_MATTER: "Existing Client & Matter",
  CM_LABEL_PROSPECTIVE_NEW_MATTER_EXSITING_CLIENT: "Existing Client & Draft Matter",
  CM_LABEL_PROSPECTIVE_TO_EXISTING: "Prospective to Existing",
  CM_LABEL_RATE_CODE: "Rate Code",
  CM_LABEL_REASON: "Reason",
  CM_LABEL_RECORD: "Record",
  CM_LABEL_RELATIONSHIP: "Relationship",
  CM_LABEL_RELATIONSHIP_CODE: "Relationship Code",
  CM_LABEL_RELATIONSHIP_CODE_1: "Relationship Code 1",
  CM_LABEL_RELATIONSHIP_CODE_2: "Relationship Code 2",
  CM_LABEL_RELATIONSHIP_CODE_3: "Relationship Code 3",
  CM_LABEL_RELATIONSHIP_CODE_4: "Relationship Code 4",
  CM_LABEL_RELATIONSHIP_CODE_5: "Relationship Code 5",
  CM_LABEL_REL_CODE_1: "Rel. Code 1",
  CM_LABEL_REL_CODE_2: "Rel. Code 2",
  CM_LABEL_REL_CODE_3: "Rel. Code 3",
  CM_LABEL_REL_CODE_4: "Rel. Code 4",
  CM_LABEL_REL_CODE_5: "Rel. Code 5",
  CM_LABEL_REMOVE: "Remove",
  CM_LABEL_REPORTED_FEE_AMOUNT: "Reported Fee Amount",
  CM_LABEL_REQUESTED_BY: "Requested By",
  CM_LABEL_RERUN: "Re-run",
  CM_LABEL_RERUN_SEARCH: "Re-run This Search",
  CM_LABEL_RESERVATION_ATTORNEY: "Reservation Attorney",
  CM_LABEL_RESET: "Reset",
  CM_LABEL_RESPONSIBLE_ATTORNEY: "Responsible Attorney",
  CM_LABEL_RESULTS: "Results",
  CM_LABEL_RETENTION_REVIEW_AUTH: "Retention Review Auth.",
  CM_LABEL_RETENTION_REVIEW_DATE: "Retention Review Date",
  CM_LABEL_RISK: "Risk",
  CM_LABEL_SAVE: "Save",
  CM_LABEL_SAVED_ON: "Saved On",
  CM_LABEL_SAVED_SEARCHES: "Saved Searches",
  CM_LABEL_SCORING: "Scoring",
  CM_LABEL_SEARCHED_BY: "Searched by",
  CM_LABEL_SEARCH_CLIENT_STATUS: "Client Status",
  CM_LABEL_SEARCH_CLOSED_FILES: "Search Closed Files",
  CM_LABEL_SEARCH_CRITERIA: "Search Criteria",
  CM_LABEL_SEARCH_ENABLED: "Search Enabled",
  CM_LABEL_SEARCH_ENTITIES: "Search Entities",
  CM_LABEL_SEARCH_MATTER_STATUS: "Matter Status",
  CM_LABEL_SEARCH_OPTIONS: "Search Options",
  CM_LABEL_SEARCH_RESULTS_FOR: "Search Results for",
  CM_LABEL_SEARCH_TERM: "Search Term",
  CM_LABEL_SEARCH_TERM_OPERATOR: "but not",
  CM_LABEL_SEARCH_TYPE: "Search Type",
  CM_LABEL_SECOND_CHAIR: "2nd Chair",
  CM_LABEL_SELECT_STANCE_TYPE: "select stance type",
  CM_LABEL_SERVICE_CODE: "Service Code",
  CM_LABEL_SETTINGS_FOR: "Settings for",
  CM_LABEL_SITE: "Site",
  CM_LABEL_SOURCE: "Source",
  CM_LABEL_STANCE: "Stance",
  CM_LABEL_STANCES: "Stances",
  CM_LABEL_STANCE_TYPE: "Stance Type",
  CM_LABEL_STATE: "State",
  CM_LABEL_STATUS: "Status",
  CM_LABEL_STATUS_CODE: "Status Code",
  CM_LABEL_SUBFILE_DESCRIPTION: "Subfile Description",
  CM_LABEL_SUBFILE_MAINTENANCE_DESCRIPTION: "Subfile Maintenance Desc",
  CM_LABEL_SUBFILE_MASTER_BOX_DESCRIPTION: "Subfile Master Box Desc",
  CM_LABEL_SUBFILE_MEMO: "Subfile Memo",
  "CM_LABEL_SUB_(ID)": "Sub (ID)",
  CM_LABEL_SUB_CLIENT: "Sub Client",
  CM_LABEL_SUB_ID: "Sub (ID)",
  CM_LABEL_SUB_MATTER: "Sub Matter",
  CM_LABEL_SUMMARY: "Summary",
  CM_LABEL_SUPER_CLIENT: "Super Client",
  CM_LABEL_SURECLEAR_SETTINGS: "SureClear Settings",
  CM_LABEL_TASK_CODE: "Task Code",
  CM_LABEL_TEMPORARY: "Temporary",
  CM_LABEL_THIRD_CHAIR: "3rd Chair",
  CM_LABEL_TIME: "Time",
  CM_LABEL_TIME_DETAIL_USER_DEFINED: "Time Detail User Defined",
  CM_LABEL_TIME_DETAIL_USER_DEFINED_2: "Time Detail User Defined 2",
  CM_LABEL_TIME_DETAIL_USER_DEFINED_3: "Time Detail User Defined 3",
  CM_LABEL_TIME_NARRATIVE: "Time Narrative",
  CM_LABEL_TITLE: "Title",
  CM_LABEL_TITLE_NAME: "Title Name",
  CM_LABEL_TRANSACTION_DATE: "Transaction Date",
  CM_LABEL_TRANSFER_TO: "Transfer to",
  CM_LABEL_UDF: "UDF",
  CM_LABEL_UDF2: "UDF2",
  CM_LABEL_USE_NEAR_PROXIMITY: "Use Near Proximity",
  CM_LABEL_VALID: "Valid",
  CM_LABEL_VENDOR: "Vendor",
  CM_LABEL_VENDOR_ADDRESS_ID: "Vendor Address ID",
  CM_LABEL_VENDOR_ID: "Vendor ID ",
  CM_LABEL_VENDOR_NAME: "Vendor Name",
  CM_LABEL_VENDOR_SEARCH_CODE: "Vendor Search Code",
  CM_LABEL_VENDOR_USER_DEFINED_1: "Vendor User Defined 1",
  CM_LABEL_VENDOR_USER_DEFINED_2: "Vendor User Defined 2",
  CM_LABEL_VENDOR_USER_DEFINED_3: "Vendor User Defined 3",
  CM_LABEL_VERIFIED_DESTROYED: "Verified Destroyed",
  CM_LABEL_VIEW_DETAILS: "View Details",
  CM_LABEL_WAIVER: "Waiver",
  CM_LABEL_WAIVER_NO_RECORDS: "Waiver not found",
  CM_LABEL_WEBSITE: "Website",
  CM_LABEL_WEB_SITE: "Web Site",
  CM_LABEL_WEIGHTING: "Weighting",
  CM_LABEL_WORKING_ATTORNEY: "Working Attorney",
  CM_LABEL_YRS: "yrs",
  CM_LABEL_ZIP_CODE: "Zip Code",
  CM_MSG_ADD_WAIVER_LEAVE_CONFIRMATION: "Clicking Leave discards any changes and navigates to the next screen.",
  CM_MSG_ADD_WAIVER_STAY_CONFIRMATION: "Clicking Stay returns user to Conflict Actions & Attachments screen where they can manually review changes and save.",
  CM_MSG_CLIENT_MATTER_SEARCH_EXISTS: "A search for this client-matter already exists with a Not Started status. Please use the existing search instead.",
  CM_MSG_DRAFT_CLIENT_EXISTS: "A matching client already exists. Consider performing a New Matter on Existing Client search to determine if your draft client has been added to the system.",
  CM_MSG_DRAFT_CLIENT_MATTER_EXISTS: "A matching client-matter already exists. Consider performing an Existing Client and Matter search to determine if your draft client-matter has been added to the system.",
  CM_MSG_EXISTING_CLIENT_NEW_MATTER_EXISTS: "A matching matter already exists for this client. Perform an Existing Client and Matter search instead.",
  CM_MSG_FORMAL_UPDATE_SEARCH: "Search submitted. Refresh the screen to update the status ",
  CM_MSG_INFORMAL_SEARCH_RESULTS_NOT_FOUND: "Informal search results are not saved. Waivers can be applied to formal searches only.",
  CM_MSG_PARTIES_NOT_FOUND: "No Parties Found",
  CM_MSG_PARTY_ALREADY_EXITS: "Party already exists",
  CM_MSG_PARTY_PAST_END_DATE: "Party cannot be changed",
  CM_MSG_PARTY_SAVED: "Party saved",
  CM_MSG_SEARCH_NAME_ALREADY_EXISTS_WANT_TO_REPLACE_EXISTING_SEARCH: "A search with this name already exists. Do you want to replace your existing search?",
  CM_PLACEHOLDER_ENTER_ACTION: "enter action",
  CM_PLACEHOLDER_ENTER_DRAFT_CLIENT: "enter a draft client",
  CM_PLACEHOLDER_ENTER_DRAFT_MATTER: "enter a draft matter",
  CM_PLACEHOLDER_ENTER_MATTER: "enter a matter",
  CM_PLACEHOLDER_ENTER_NAME: "enter name",
  CM_PLACEHOLDER_ENTER_SYNONYM: "enter synonyms separated by a semicolon",
  CM_PLACEHOLDER_ENTER_WORD: "enter word",
  CM_PLACEHOLDER_EXCLUDE_SEARCH_TERM: "Enter terms to exclude from search. (Optional)",
  CM_PLACEHOLDER_NO_FILE_CHOSEN: "No file chosen",
  CM_PLACEHOLDER_SEARCH_AOL: "search by area of law",
  CM_PLACEHOLDER_SEARCH_BY_ACTION: "search by action",
  CM_PLACEHOLDER_SEARCH_BY_ACTION_TYPE: "search by action type",
  CM_PLACEHOLDER_SEARCH_BY_PARTY: "search by party",
  CM_PLACEHOLDER_SEARCH_BY_SYNONYM: "search by synonym",
  CM_PLACEHOLDER_SEARCH_EXCLUSION: "search by exclusion",
  CM_PLACEHOLDER_SEARCH_PARTY_TYPE: "search by party type",
  CM_PLACEHOLDER_SEARCH_PRACTICE_TYPE: "search by practice type",
  CM_PLACEHOLDER_SEARCH_STANCE: "search by stance",
  CM_PLACEHOLDER_SEARCH_STANCE_TYPE: "search by stance type",
  CM_PLACEHOLDER_SEARCH_TERM: "Enter person or company name to search for. Enclose in double quotations to search for an exact phrase.",
  CM_PLACEHOLDER_SELECT_ACTION_TYPE: "select action type",
  CM_PLACEHOLDER_USER_PROXIMITY: "enter number of words",
  CM_POSTED_ON: "Posted on",
  CM_UNSUPPORTED_FILE_TYPE: "Unsupported File Type",
  COLUMN: "Column",
  COMBINE: "Combine",
  COMBINE_CHECKBOX_VALIDATION_MESSAGE: "Some of the time entries cannot be combined due to an ethical wall or their current status. Do you want to combine the other time entries?",
  COMBINE_ENTRY_SELECTION_QUESTION: "Which one of the following time entries will be the combined entry?",
  COMBINE_SELECTED_TIME_ENTRIES: "Combine Selected Time Entries",
  COMBINE_TE_SUCCESS: "Time entries were successfully combined",
  COMING_SOON: "c o m i n g  s o o n",
  COMPANY: "Company",
  CONFIRMATION: "Confirmation",
  CONFIRM_NEW_PASSWORD: "Confirm New Password",
  CONNECTION_ERROR: "Connection Error",
  CONTINUE: "Continue",
  COPY: "Copy",
  COST_MANAGEMENT: "Cost Management",
  COST_ENTRY: "Cost Entry",
  CREATED_ON: "Created on",
  CURRENT_PASSWORD: "Current Password",
  CURRENT_PASSWORD_IS_NOT_CORRECT: "Current password is not correct.",
  DASHBOARD_ALREADY_EXISTS_FOR_PROVIDED_DATE_RANGE: "Dashboard already exists for provided date range",
  DASHBOARD_ENDDATE_AFTER_ROLE: "Dashboard’s end date cannot be after the role’s end date",
  DASHBOARD_END_DATE_CANNOT_BE_LATER_THAN_ROLE_END_DATE: "Dashboard's end date cannot be later than Role's end date",
  DASHBOARD_STARTDATE_BEFORE_ROLE: "Dashboard’s start date cannot be prior to the role’s start date",
  DASHBOARD_START_DATE_CANNOT_BE_PRIOR_TO_ROLE_START_DATE: "Dashboard's start date cannot be prior to current date",
  DATA: "Data",
  DATA_IMPORT: "Data Import",
  DATA_IMPORT_IN_PROGRESS: "Data import in progress...",
  DATA_IMPORT_TEMPLATES: "Data Import Templates",
  DATA_SYNC_PARTY_TYPE_AND_STANCE_CONFIRMATION: "You are making changes to the data that is available for conflict searches, including what is searched as a party and how party data from LMS is mapped to SurePoint Cloud. These changes will apply only to new searches or searches with a Not Started status.",
  DATA_UPLOAD_FAILED_DUE_TO_THE_ERRORS_BELOW_PLEASE_CORRECT_AND_REUPLOAD: "Data upload failed due to the errors below. Please correct the errors in your template and re-upload the data",
  DATA_UPLOAD_IS_SUCCESSFUL: "Data upload is successful~fr",
  DATE: "Date",
  DATE_FROM: "Date From",
  DATE_TO: "Date To",
  DAY: "day.",
  DAYS: "days.",
  DAYS_NOT_IN_RANGE_ONLY_INITIAL_ENTRY_WILL_BE_CREATED: " Days not in range. Only initial entry will be created ",
  DAYS_OF_THE_WEEK_DO_NOT_FALL_IN_THE_DATE_RANGE: " Days of the week do not fall in the selected date range ",
  DAYS_OF_WEEK: "Days of week",
  DAYS_OF_WEEK_AND_END_DATE_MUST_BE_CHECKED_OR_REPEAT_TIME_ENTRY_CLEARED: "End date and days of week must be provided to repeat time entry",
  DAYS_OF_WEEK_MUST_BE_CHECKED_OR_REPEAT_TIME_ENTRY_CLEARED: "End date and days of week must be provided to repeat time entry",
  DEFAULT_GL_SUBACCOUNT: "Default GL Sub-account",
  DELEGATE: "Delegate",
  DELEGATES_NOT_FOUND: "Delegates not found",
  DELEGATE_ADDED_SUCCESSFULLY: "Delegate added successfully",
  DELEGATE_ALREADY_EXISTS: "Delegate already exists",
  DELEGATE_CANNOT_BE_CHANGED: "Delegate cannot be changed",
  DELEGATE_IS_NOT_SYNCED_WITH_LMS: "Delegate is not synced with LMS",
  DELEGATE_THIS_IS_BUTTON_TEXT: "Delegate  (* this is button text)",
  DELEGATE_UPDATED_SUCCESSFULLY: "Delegate updated successfully",
  DELEGATE_DELETED_SUCCESSFULLY: "Delegate deleted successfully",
  DELETE: "Delete",
  DELETE_CHECKBOX_VALIDATION_MESSAGE: "Some of the time entries cannot be deleted due to their current status. Do you want to delete the other time entries?",
  DELETE_SELECTED_TIME_ENTRIES: "Delete Selected Time Entries",
  DEPARTMENT: "Department",
  DICTATE: "Dictate",
  DIRECTORY_ID: "Directory (tenant) ID",
  DOWN: "Down ",
  DOWNLOAD_TEMPLATE: "Download Template",
  DRAFT_TIME: "Draft Time",
  DRAFT_TIME_ENTRY: "Draft Time Entry",
  DUPLICATE_ENTRY_FOUND: "Duplicate entry found",
  DUPLICATE_ENTRY_FOUND_IN_COGNITO: "Duplicate entry found in Cognito",
  DURATION: "Duration",
  DURATION_: "Duration ",
  DURATION_MAX: "Duration (Max)",
  DURATION_MIN: "Duration (Min)",
  DURATION_MUST_BE_ENTERED: "Duration must be entered",
  EDIT: "Edit",
  EDIT_CHECKBOX_VALIDATION_MESSAGE: "Some of the time entries cannot be updated due to an ethical wall or their current status. Do you want to update the other time entries?",
  EDIT_GENERAL_TIMEENTRY_VALIDATION_MESSAGE: "Some of the time entries cannot be updated due to them being a general time entry. To update individual general time entries, use the Edit icon next to them on the time entries list. Do you want to update the other time entries?",
  EDIT_ROLE: "Edit Role",
  EDIT_USER_ACCESS: "Edit User Access",
  EMAIL: "Email",
  EMAIL_: "Email ",
  EMPLOYEE_ID: "Employee ID",
  END_DATE: "End Date",
  ENTER_AMOUNT: "Enter Amount",
  ENTER_A_START_DATE: "Enter a start date",
  ENTER_A_START_DATE_FOR_THE_DASHBOARD: "Enter a start date for the dashboard",
  ENTER_A_START_DATE_FOR_THE_RULE: "Enter a start date for the rule",
  ENTER_A_VALID_END_DATE: "Enter a valid end date",
  ENTER_A_VALID_END_DATE_FOR_ROLE: "Enter a valid end date for role",
  ENTER_RECEIVED_AUTHENTICATION_CODE_OR_CLICK_THE_REFRESH_ICON_TO_GET_ONE: "Enter received authentication code, or click the refresh icon to get one",
  ENTER_START_DATE: "Enter Start Date",
  ENTER_THE_CLIENT_OR_LEAVE_BLANK: "Enter the client or leave blank",
  ENTER_THE_MATTER_OR_LEAVE_BLANK: "Enter the matter or leave blank",
  ENTER_THE_SUB_CLIENT_OR_LEAVE_BLANK: "Enter the sub client or leave blank",
  ENTER_UP_TO_3_CHARECTERS: "Enter up to 3 characters",
  ENTER_UP_TO_10_CHARACTERS: "Enter up to 50 characters",
  ENTER_YOUR_EMAIL_ADDRESS_TO_RECEIVE_YOUR_AUTHENTICATION_CODE: "Enter your email address to receive your authentication code",
  ERROR: "Error",
  ERROR_IN_SAVING_FIELDS: "Error in saving fields",
  ERROR_SENDING_VERIFICATION_CODE_TO_REGISTERED_EMAIL_ADDRESS: "Error sending verification code to registered email address",
  EXPORT_SELECTED_RESULTS_TO_CSV: "Export Selected Results to CSV",
  EXPORT_SELECTED_RESULTS_TO_EXCEL: "Export Selected Results to Excel",
  EXPORT_SELECTED_RESULTS_TO_PDF: "Export Selected Results to PDF",
  EXPORT_SELECTED_TIME_ENTRIES_TO_CSV: "Export Selected Time Entries to CSV",
  EXPORT_SELECTED_TIME_ENTRIES_TO_EXCEL: "Export Selected Time Entries to Excel",
  EXPORT_SELECTED_TIME_ENTRIES_TO_PDF: "Export Selected Time Entries to PDF",
  EXTENDED_SESSION_TIMEOUT_WARNING: "By changing this setting, you attest to being responsible for the security of your workstation connected to a SurePoint Technologies cloud application with the extended session timeout being in place. To maintain PCI, HIPAA, and other industry security best practices, you also attest that your workstation has a screen lock or screensaver enabled with a 15 minute or less timeout that requires a password to unlock.",
  FEATURE: "Feature",
  FIRM: "Firm",
  FIRST_NAME: "First Name",
  FORCE_INVALID_STATUS_FOR_SPELLING_ERRORS: "Force invalid status for spelling errors",
  FOUND_IN: "Found In",
  FROM: "From",
  GENERAL_TIME: "General Time",
  GENERAL_TIME_ENTRY: "General Time Entry",
  GOOGLE: "Google",
  HELP: "Help",
  HH: "hh",
  HIDE_PASSWORD: "Hide password",
  HOURS: "hours.",
  HOURS_HEADER: "Hours",
  IGNORE_ALL: "Ignore All",
  IMPORT_TEMPLATE_TO_ENSURE_SUCCESSFUL_DATA_IMPORT: "import template to ensure successful data import.",
  IMPORT_USERS: "Import Users",
  INACTIVITY_TIMEOUT: "Inactivity Timeout",
  INCLUDE_TENTATIVE_MEETINGS_WHEN_PREDICTING_TIME_ENTRIES: "Include tentative meetings when predicting time entries",
  INCORRECT_DETAILS_PROVIDED: "Incorrect Details Provided",
  INFORMAL_RESULT_EXCEEDED_3000_SIZE: "Your search returned more than 3,000 results. Please provide additional or more specific search criteria to narrow down the results.",
  INFO_IS_HIDDEN_DUE_TO_ETHICAL_WALLS: "Info is hidden due to an ethical wall",
  INTERNAL_ERROR: "Internal error",
  INVALID_AUTOTEXT: "Invalid autotext",
  INVALID_CREDENTIALS: "Invalid Credentials",
  INVALID_EMAIL_OR_PASSWORD: "Invalid Email or Password",
  INVALID_HOURS_TOTAL_ENTERED_FOR_THIS_DAY_EXCEEDS: "Invalid hours. Total entered for this day exceeds",
  INVALID_ID_SUPPLIED: "Invalid ID supplied",
  INVALID_INPUT: "Invalid input",
  INVALID_OPERATION: "Invalid operation",
  INVALID_PARAM_PREVIOUS_CODE_ID: "Invalid RelationshipCode Selected",
  INVALID_PAST_ENDDATE_ACTIONS: "Actions cannot be changed",
  INVALID_PAST_ENDDATE_ASSOCIATION: "Association cannot be changed",
  INVALID_PAYLOAD: "Invalid payload",
  INVALID_PAYLOAD_MODE_IS_MISSING: "Invalid payload: mode is missing",
  INVALID_SESSION: "Invalid Session",
  INVALID_TIME_ENTRIES_WILL_NOT_BE_RELEASED_YOU_WANT_TO_RELEASE_THE_SELECTED_READY_TIME_ENTRIES: "Invalid time entries will not be released. Are you sure you want to release the selected Ready time entries",
  INVALID_TOKEN: "Invalid request token",
  INVALID_USERNAME: "Invalid username",
  KEYWORD: "keyword",
  LABEL_EXCLUSION: "Exclusion",
  LABEL_SYNONYM: "Synonym",
  LABEL_SYNONYMS: "Synonyms",
  LABEL_WORD: "Word",
  LABEL_WORD_CHAR_EXCLUSIONS: "Word & Character Exclusions",
  LAST_NAME: "Last Name ",
  LAST_UPDATED_ON: "Last updated on",
  LEAVE: "Leave",
  LENGTH_LIMIT: "Length Limit",
  LIMIT: "Limit",
  LIMIT_VALIDATION_MESSAGE: "Entered limit value should be between 1 and 5000",
  LMS_SECURITY_PROFILE: "LMS Security Profile",
  LOGGED_IN_USERID_MISSING: "Logged in userId missing",
  LOGGED_OUT: "Logged out",
  LOGIN: "Log In",
  LOGIN_SYSTEM_TAB: "Login",
  MAKE_NO_CHARGE: "Make No Charge",
  MANAGE_TIME: "Manage Time",
  MATTER: "Matter",
  MATTER_DESCRIPTION: "Matter Description",
  MATTER_MUST_BE_ENTERED: "Matter must be entered.",
  MESSAGE: "Message",
  MESSAGE_CANNOT_BE_PROCESSED: "Message cannot be processed",
  "MG-Account": "Account",
  "MG-Account-MD-Autotext": "Autotext",
  "MG-Account-MD-Autotext-FE-Autotext": "Work with personal autotext",
  "MG-Account-MD-Autotext-FE-Autotext-Firm": "Work with firm autotext",
  "MG-Account-MD-Autotext-MN-Autotext": "Autotext",
  "MG-Account-MD-Autotext-MN-Autotext-Firm": "Firm Autotext",
  "MG-Account-MD-Conflict": "Conflict Management",
  "MG-Account-MD-Conflict-MN-Accociations": "Associations",
  "MG-Account-MD-Conflict-MN-Area-of-law": "Areas of Law",
  "MG-Account-MD-Conflict-MN-Conflict-Actions": "Conflict Actions",
  "MG-Account-MD-Conflict-MN-Party-type": "Party Types",
  "MG-Account-MD-Conflict-MN-Search-Engines": "Search Engine",
  "MG-Account-MD-Conflict-MN-Stances": "Stances",
  "MG-Account-MD-Delegates": "Delegates",
  "MG-Account-MD-Delegates-FE-Conflict": "Manage configuration settings",
  "MG-Account-MD-Delegates-FE-Delegates": "Add and edit delegates",
  "MG-Account-MD-Delegates-MN-Delegates": "Delegates",
  "MG-Account-MD-Logout": "Log Out",
  "MG-Account-MD-Logout-MN-Logout": "Log Out",
  "MG-Account-MD-Preferences": "Preferences",
  "MG-Account-MD-Preferences-FE-TimeMng": "Set Practice preferences",
  "MG-Account-MD-Preferences-MN-Analytics": "Analytics",
  "MG-Account-MD-Preferences-MN-Budget": "Budget",
  "MG-Account-MD-Preferences-MN-Finance": "Finance",
  "MG-Account-MD-Preferences-MN-Mobile": "Mobile",
  "MG-Account-MD-Preferences-MN-Practice": "Practice",
  "MG-Account-MD-Preferences-MN-Practice-FE-AutoCap": "Auto capitalization",
  "MG-Account-MD-Preferences-MN-Practice-FE-CloudCal": "Calendar integration",
  "MG-Account-MD-Preferences-MN-Practice-FE-SplChk": "Spell check",
  "MG-Account-MD-Preferences-MN-Practice-FE-TimerRnd": "Timer rounding",
  "MG-Account-MD-Preferences-MN-System": "System",
  "MG-Account-MD-Preferences-MN-System-FE-InacTimeout": "Inactivity timeout",
  "MG-Account-MD-Profile": "My Profile",
  "MG-Account-MD-Profile-MN-Profile": "My Profile",
  "MG-Accounts-Description": "Accounts-Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "MG-Analytics": "Analytics",
  "MG-Analytics-Description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "MG-Budgeting": "Budgeting",
  "MG-Budgeting-Description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "MG-Finance": "Finance",
  "MG-Finance-Description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "MG-Finance-MD-Billing": "Billing",
  "MG-Practice": "Practice",
  "MG-Practice-Description": "Practice-Description : Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "MG-Practice-MD-Conflict": "SureClear",
  "MG-Practice-MD-Conflict-FE-Add-Action-Attachments": "Add actions and attachments to formal search results",
  "MG-Practice-MD-Conflict-FE-Add-Waivers-Attachments": "Add waivers and attachments",
  "MG-Practice-MD-Conflict-FE-Export-Formal-Search-Results": "Export formal search results",
  "MG-Practice-MD-Conflict-FE-Export-Informal-Search-Results": "Export informal search results",
  "MG-Practice-MD-Conflict-FE-Formal-Informal-Waiver": "Add parties, perform formal searches, and review results",
  "MG-Practice-MD-Conflict-FE-Informal-Search": "Perform informal searches",
  "MG-Practice-MD-Conflict-MN-Formal-Search": "Formal Search",
  "MG-Practice-MD-Conflict-MN-InFormal-Search": "Informal Search",
  "MG-Practice-MD-Conflict-MN-Waivers": "Waivers",
  "MG-Practice-MD-Docketing": "Docketing",
  "MG-Practice-MD-Expenses": "Expenses",
  "MG-Practice-MD-TM": "SureTime",
  "MG-Practice-MD-TM-FE-Add-Mng-GeneralTime": "Add and manage general time entries",
  "MG-Practice-MD-TM-FE-AddTime-MngTime": "Add and manage time entries",
  "MG-Practice-MD-TM-FE-AddTime-MngTime-ReleaseTime": "Release time entries",
  "MG-Practice-MD-TM-MN-AddTime": "Add Time",
  "MG-Practice-MD-TM-MN-DayView": "Day View",
  "MG-Practice-MD-TM-MN-ManageTime": "Manage Time",
  "MG-Practice-MD-TM-MN-MonthView": "Month View",
  "MG-Settings": "Settings",
  "MG-Settings-Description": "Settings-Description : Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "MG-Settings-MD-Access": "Access",
  "MG-Settings-MD-Access-FE-Users-Roles": "Manage role profiles and assign to users",
  "MG-Settings-MD-Access-MN-EthicalWalls": "Ethical Walls",
  "MG-Settings-MD-Access-MN-Roles": "Roles",
  "MG-Settings-MD-Access-MN-Users": "Users",
  "MG-Settings-MD-Conflict": "SureClear",
  "MG-Settings-MD-System": "System",
  "MG-Settings-MD-System-FE-Add-Mng-Narrative-Ctrls": "Add and manage narrative controls",
  "MG-Settings-MD-System-FE-MS-Calendar-Integration": "Configure calendar integration",
  "MG-Settings-MD-System-MN-MSCalIntg": "Configuration",
  "MG-Settings-MD-System-MN-Messages": "Messages",
  "MG-Settings-MD-System-MN-Narrative-Ctrls": "Narrative Controls",
  MIDDLE_NAME: "Middle Name",
  MISSING: "Missing",
  MISSING_MANDATORY_PARAMETER: "Missing mandatory parameter",
  MISSING_TIME: "Missing Time",
  MM: "mm",
  MM_DD_YYYY: "mm/dd/yyyy",
  MOBILE_NUMBER: "Mobile #",
  MOBILE_NUMBER2: "Mobile Number",
  MODULE: "Module",
  MSG_ACTION_NO_RECORDS: "Actions not found",
  MSG_ACTION_SAVED_AND: "Action saved and",
  MSG_AOL_ASSOCIATION_EXISTS: "Area of law already has at least one active association with a party type ",
  MSG_AOL_END_DATE_NOT_IN_PRACTICE_TYPE_DATE_RANGE: "Area of law's end date cannot be later than practice type's end date",
  MSG_AOL_EXISTS: "Area of law already exists for the provided date range",
  MSG_AOL_NO_RECORDS: "Area of law not found",
  MSG_AOL_PAST_END_DATE: "Area of law cannot be changed",
  MSG_AOL_PREV_END_DATE_NOT_MATCHING: "The Start Date must fall after the existing Area of Law End Date to proceed.",
  MSG_AOL_SAVED: "Area of law saved",
  MSG_ASSOCIATION_END_DATE_NOT_IN_MIN_END_DATE_RANGE: "Association’s end date cannot be later than Party Type’s end date",
  MSG_ASSOCIATION_EXISTS: "Association already exists for the provided date range",
  MSG_ASSOCIATION_NO_RECORDS: "Associations not found",
  MSG_ASSOCIATION_PAST_END_DATE: "Association cannot be changed",
  MSG_ASSOCIATION_PREV_END_DATE_NOT_MATCHING: "The Start Date must fall after the existing Association End Date to proceed.",
  MSG_ASSOCIATION_SAVED: "Association saved",
  MSG_CONFLICT_ACTION_EXISTS: "Action already exists for the provided date range",
  MSG_CONFLICT_ACTION_PAST_END_DATE: "Action cannot be changed",
  MSG_CONFLICT_SEARCH_SAVED: "Conflict Search Saved",
  MSG_CONFLIT_ACTION_END_DATE_NOT_IN_ACTION_TYPE_DATE_RANGE: "Action end date cannot be later than Action Type's end date",
  MSG_CONFLIT_ACTION_PREV_END_DATE_NOT_MATCHING: "The Start Date must fall after the existing Action End Date to proceed.",
  MSG_DOCUMENT_DELETED: "Document deleted",
  MSG_DOCUMENT_FETCHED: "Document fetched",
  MSG_DOCUMENT_SAVED: "Document saved",
  MSG_ENTER_ACTION: "Enter an action",
  MSG_ENTER_ACTION_INFO: "Enter the action information",
  MSG_ENTER_ACTION_SELECT_START_DATE: "Enter an action and select a start date",
  MSG_ENTER_PARTY_TYPE: "Enter a party type",
  MSG_ENTER_PARTY_TYPE_INFO: "Enter the party type information",
  MSG_ENTER_STANCE: "Enter an stance",
  MSG_ENTER_STANCE_INFO: "Enter the stance information",
  MSG_ENTER_STANCE_SELECT_START_DATE: "Enter an Stance and select a start date",
  MSG_EXCLUSION_EXISTS: "Exclusion already exists for the provided date range",
  MSG_EXCLUSION_NO_RECORDS: "Exclusions not found",
  MSG_EXCLUSION_PAST_END_DATE: "Exclusion cannot be changed",
  MSG_EXCLUSION_SAVED: "Exclusion saved",
  MSG_FORMAL_CONFLICT_SEARCHES: "There are formal conflict searches in progress. These search engine changes will not apply to Processing searches, but they will apply to future searches going forward. Are you sure you want to proceed?",
  MSG_FORMAL_SEARCH_RESULT_ACTION_SAVED: "Action Saved",
  MSG_FORMAL_SEARCH_RESULT_STANCE_SAVED: "Stance Saved",
  MSG_INFORMAL_SEARCH_3K_RESULTS: "Your search returned more than 3000 results. Please provide additional or more specific search criteria to narrow down the results.",
  MSG_MAX_LIMIT_REACHED_FOR_ATTACHMENTS: "Maximum limit for the attachments is reached",
  MSG_NO_OPEN_SEARCHES_FOUND: "No Open Searches Found",
  MSG_NO_SEARCHES_FOUND: "No searches found",
  MSG_ON: "on",
  MSG_PARTY_EXISTS_END_DATE_RANGE: "Party already exists for the provided date range",
  MSG_PARTY_SOURCE_CHANGES_SAVED: "Party Sources changes saved",
  MSG_PARTY_TYPE_ASSOCIATION_EXISTS: "Party type end date cannot be prior to the associated area of law's end date",
  MSG_PARTY_TYPE_EXISTS: "Party type already exists for the provided date range",
  MSG_PARTY_TYPE_NO_RECORDS: "Party Types not found",
  MSG_PARTY_TYPE_PAST_END_DATE: "Party Type cannot be changed",
  MSG_PARTY_TYPE_PREV_END_DATE_NOT_MATCHING: "The Start Date must fall after the existing Party Type End Date to proceed.",
  MSG_PARTY_TYPE_SAVED: "Party type saved",
  MSG_RERUN_SAVED: "Re-run Saved",
  MSG_RESULTS_WERE_UPDATED: "results were updated",
  MSG_RESULTS_WITH_ETHICAL_WALLS: "Results with ethical walls cannot be actioned. Do you want to apply the action to the other results?",
  MSG_RE_RUN_SAVED: "Re-run Saved",
  MSG_SEARCHED_BY: "searched by",
  MSG_SEARCH_ALL_SAVED: "Status Changed Successfully",
  MSG_SEARCH_ALL_STATUS_CHANGE: "Changing the status prevents additional activities against this formal search and cannot be undone. Are you sure you want to proceed?",
  MSG_SEARCH_ENGINE_APPLY_CHANGES: "These search engine changes will apply only to new searches or searches in Not Started status.",
  MSG_SEARCH_ENTITY_PARTY_CONF_SETTING_SAVE: "Search settings saved",
  MSG_SEARCH_OPTION_ASSOCIATION_EXISTS: "Association already exists and is active",
  MSG_SEARCH_PARAMETER_ADDED: "Formal search parameters saved",
  MSG_SEARCH_PARTIES_FOR: "Search Parties for",
  MSG_SELECTED_ATTACHMENTS_ARE_TOO_LARGE: "Total selected attachments are too large. A maximum of 10 MB can be attached at a time",
  MSG_SELECT_ACTION_TYPE: "Select an action type",
  MSG_SELECT_ACTION_TYPE_ENTER_ACTION: "Select an action type and enter an action",
  MSG_SELECT_AOL: "Select an area of law",
  MSG_SELECT_AOL_PARTY_TYPE: "Select an area of law and party type",
  MSG_SELECT_ASSOCIATION_INFO: "Select the association information",
  MSG_SELECT_PARTY_TYPE: "Select a party type",
  MSG_SELECT_PARTY_TYPE_START_DATE: "Select a party type and start date",
  MSG_SELECT_STANCE_TYPE: "Select an stance type",
  MSG_SELECT_STANCE_TYPE_ENTER_STANCE: "Select an action type and enter an Stance",
  MSG_SELECT_START_DATE: "Select a start date",
  MSG_SETTINGS_SEARCH_IN_PROGRESS: "There are formal conflict searches in progress. These search engine changes will not apply to In Progress searches, but they will apply to future searches going forward",
  MSG_SIGNED_URL_FETCHED: "Signed url fetched",
  MSG_STANCES_NO_RECORDS: "Stance not found",
  MSG_STANCES_PAST_END_DATE: "Stance cannot be changed",
  MSG_STANCE_END_DATE_NOT_IN_STANCE_TYPE_DATE_RANGE: "Stance end date cannot be later than Stance Type's end date",
  MSG_STANCE_EXISTS: "Stance already exists for the provided date range.",
  MSG_STANCE_PAST_END_DATE: "Stance cannot be changed",
  MSG_STANCE_PREV_END_DATE_NOT_MATCHING: "The Start Date must fall after the existing Stance End Date to proceed.",
  MSG_STANCE_TYPE_SAVED: "Stance changes saved",
  MSG_SYNONYM_ADDED_SUCCESSFULLY: "Synonym added successfully",
  MSG_SYNONYM_EXISTS: "This Synonym already exists",
  MSG_SYNONYM_NO_RECORDS: "Synonyms not found",
  MSG_SYNONYM_PAST_END_DATE: "Synonyms cannot be changed",
  MSG_SYNONYM_PREV_END_DATE_NOT_MATCHING: "The Start Date must fall after the existing Synonym End Date to proceed.",
  MSG_SYNONYM_SAVED: "Synonyms saved",
  MSG_SYNONYM_UPDATED_SUCCESSFULLY: "Synonyms updated successfully",
  MSG_UNSAVED_CHANGE: "You have unsaved changes on the screen. If you leave, your changes will be lost. Do you want to save",
  MSG_WAIVER_ALREADY_EXISTS: "Waiver already exists. Review the existing active attachments and then change the details for the new waiver before attaching, if needed.",
  MSG_WAIVER_APPLICATION_UPDATED: "Waiver application updated",
  MSG_WAIVER_SAVED: "Waiver saved",
  MSG_WAIVER_UPDATED: "Waiver updated",
  MS_CALENDAR_INTEGRATION: "MS Calendar Integration",
  MULTIPLE_DASHBOARDS_CANNOT_BE_ADDED_FOR_PROVIDED_DATE_RANGE: "Multiple dashboards cannot be added for provided date range",
  MULTIPLE_RULES_CANNOT_BE_ADDED_FOR_PROVIDED_DATE_RANGE: "Multiple rules cannot be added for provided date range",
  MY_PROFILE: "My Profile",
  NAME: "Name",
  NARRATIVE: "Narrative",
  NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT: "Narrative exceeds maximum character limit. Provide a shorter narrative.",
  NETWORK_ERROR: "Network Error",
  NEW: "New",
  NEW_PASSWORD: "New Password",
  NEW_PASSWORD_AND_CONFIRM_NEW_PASSWORD_MUST_BE_THE_SAME: "New Password and Confirm New Password must be the same",
  NO: "No",
  NONE: "None",
  NON_BILLABLE: "Non-billable",
  NON_BILLABLE_HOURS: "Non-billable Hrs:",
  "NON_BILLABLE_HOURS_MONTH ": "Non-billable Hours",
  NON_WORKING_DAY: "Non Working Day",
  NORMAL: "Normal",
  NOTES: "Notes",
  NOT_FOUND: "Not found",
  NOT_TIMEKEEPER: "You are not a timekeeper",
  NO_AUTOTEXT_RECORDS_FOUND: "No autotext records found ",
  NO_AUTOTEXT_RECORD_FOUND: "No autotext record found",
  NO_CHANGES_TO_SAVE_TIME_ENTRIES_UNCHANGED: "No changes to save. Time entries unchanged.",
  NO_DATA_FOUND: "No data found",
  NO_DELEGATE_RECORD_FOUND: "No delegate's record found",
  NO_READY_TIME_ENTRIES_SELECTED: "No ready time entries selected",
  NO_SELECTED_DAYS_IN_SELECTED_DATE_RANGE: "No selected days in selected date range",
  NO_TIME_ENTRY_RECORDS_FOUND: "No time entry records found",
  NO_USERS_AVAILABLE: "no users available",
  NUMBER: "Number",
  NWD_LABEL: "NWD",
  N_LABEL: "N",
  OBJECT_ID: "Object ID",
  OFFICE: "Office",
  ONETIME_VERIFICATION_CODE_SENT_TO_REGISTERED_EMAIL_ADDRESS: "One-time verification code sent to registered email address",
  ONE_OR_MORE_MANDATORY_PARAMETERS_ARE_MISSING: "One or more mandatory parameters are missing",
  ONE_TIME_VERIFICATION_CODE_SENT_TO_REGISTERED_EMAIL_ADDRESS: "One-time verification code sent to registered email address",
  OR_ADD_UPDATE_ROLE: "Or add/update role:",
  OR: "or",
  OUTLOOK: "Outlook",
  PARAMETER_ACTION_IS_MISSING: "Parameter action is missing",
  PARAMETER_ACTION_TYPE_ID_IS_MISSING: "Parameter action type id is missing",
  PARAMETER_AREA_OF_LAW_ID_IS_MISSING: "Parameter area of law id is missing",
  PARAMETER_AREA_OF_LAW_ID_VALUE_IS_MISSING: "Parameter area of law id value is missing",
  PARAMETER_AREA_OF_LAW_IS_MISSING: "Parameter areaofLaw is missing",
  PARAMETER_ASSOCIATION_ID_IS_MISSING: "Parameter associationId is missing",
  PARAMETER_ASSOCIATION_ID_VALUE_IS_MISSING: "Parameter association id value is missing",
  PARAMETER_ATTORNEYID_IS_MISSING: "Parameter attorneyId is missing",
  PARAMETER_CLIENT_IS_MISSING: "Parameter client is missing",
  PARAMETER_DELEGATEUSERID_IS_MISSING: "Parameter delegateUserId is missing",
  PARAMETER_END_DATE_IS_MISSING: "Parameter endDate is missing",
  PARAMETER_END_DATE_VALUE_IS_MISSING: "Parameter end date value is missing",
  PARAMETER_EXCLUSION_WORD_MISSING: "Parameter word is missing",
  PARAMETER_FIELD_IS_MISSING: "Parameter field is missing",
  PARAMETER_FIRMLEVEL_IS_MISSING: "Parameter firmlevel missing",
  PARAMETER_ID_IS_NOT_A_NUMBER: "Parameter id is not a number",
  PARAMETER_LANGUAGEID_IS_MISSING: "Parameter languageId is missing",
  PARAMETER_MATTERID_IS_MISSING: "Parameter matterId is missing",
  PARAMETER_PAGE_IS_MISSING: "Parameter page is missing",
  PARAMETER_PARTY_TYPE_ID_IS_MISSING: "Parameter partyTypeId is missing",
  PARAMETER_PARTY_TYPE_ID_VALUE_IS_MISSING: "Parameter party type id value is missing",
  PARAMETER_PRACTICE_TYE_IS_MISSING: "Parameter practiceTypeId is missing",
  PARAMETER_RATEID_IS_MISSING: "Parameter rateId is missing",
  PARAMETER_SELECTEDDATE_IS_MISSING: "Parameter selectedDate is missing",
  PARAMETER_SHORTCUT_IS_MISSING: "Parameter shortCut Missing",
  PARAMETER_START_DATE_IS_MISSING: "Parameter startDate is missing",
  PARAMETER_STATE_VALUE_IS_INVALID: "Parameter state value is invalid",
  PARAMETER_SUBCLIENTID_IS_MISSING: "Parameter subClientId is missing",
  PARAMETER_SYNONYM_ID_IS_MISSING: "Parameter synonym id is missing",
  PARAMETER_SYNONYM_ID_VALUE_IS_MISSING: "Parameter synonym id value is missing",
  PARAMETER_SYNONYM_IS_MISSING: "Parameter synonym is missing",
  PARAMETER_TIMEENTRYIDS_IS_MISSING: "Parameter timeEntryIds is missing",
  PARAMETER_TIMERID_IS_MISSING: "Parameter timerId is missing",
  PARAMETER_WORD_IS_MISSING: "Parameter word is missing",
  PASSWORDS_MUST_BE_AT_LEAST_8_CHARACTERS_LONG_WITH_AT_LEAST_1_NUMBER_1_UPPERCASE_LETTER_AND_1_LOWERCA: "Passwords must be at least 8 characters long with at least 1 number, 1 uppercase letter, and 1 lowercase letter",
  PASSWORD_8_CHARACTERS_1_NUMBER_1_UPPERCASE_1_LOWERCASE: "Password must be at least 8 characters long with at least 1 number, 1 uppercase letter, and 1 lowercase letter",
  PASSWORD_14_CHARACTERS_1_NUMBER_1_UPPERCASE_1_LOWERCASE: "Password must be at least 14 characters long with at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 symbol",
  PASSWORD_CANNOT_BE_THE_SAME_AS_YOUR_LAST_5_USED_PASSWORDS: "Password cannot be the same as your last 5 used passwords",
  PASSWORD_CANNOT_CONTAIN_SPACES: "Password cannot contain spaces",
  PASSWORD_CANNOT_CONTAIN_YOUR_COMPANY_NAME_OR_YOUR_NAME_OR_EMAIL: "Password cannot contain your company name or your name or email",
  PASSWORD_IS_TOO_COMMON_PLEASE_USE_A_DIFFERENT_PASSWORD: "Password is too common. Please use a different password.",
  PASSWORD_RESET_EMAIL_SENT: "Password reset email sent",
  PASSWORD_RESET_FAILED: "Password reset failed",
  PASSWORD_RESET_FAILED_MAKE_SURE_PASSWORD_MEETS_REQUIREMENTS: "Password reset failed. Make sure password meets requirements.",
  PASSWORD_UPDATED_SUCCESSFULLY: "Password updated successfully",
  PASSWORD_UPDATED_SUCCESSFULLY_PLEASE_LOG_IN_USING_YOUR_NEW_PASSWORD: "Password updated successfully. Please log in using your new password.",
  PAUSE: "Pause",
  PERMISSION_REQUEST_MESSAGE: "Some of the transactions cannot be actioned.Do you want to proceed with the remaining transactions?",
  PERMISSION_RESTRICT_MESSAGE: "The transactions cannot be actioned",
  PERSONAL: "Personal",
  PERSONAL_FIRM: "Personal / Firm",
  PHASE: "Phase",
  PHASE_CODE: "Phase Codes",
  PLACEHOLDER_ENTER_AOL: "enter area of law",
  PLACEHOLDER_ENTER_CLIENT: "enter a client",
  PLACEHOLDER_ENTER_PARTY_TYPE: "enter party type",
  PLACEHOLDER_SEELCT_AOL: "select an area of law",
  PLACEHOLDER_SEELCT_PARTY_TYPE: "select a party type",
  PLACEHOLDER_SELECT_PRACTICE_TYPE: "select a practice type",
  PLEASE_DOWNLOAD_THE: "Please download the",
  PLEASE_PROVIDE_A_VALID_MODULE_GROUP_ID: "Please provide a valid module group ID",
  PLEASE_PROVIDE_A_VALID_MODULE_ID: "Please provide a valid module ID",
  PLEASE_PROVIDE_CORRECT_DETAILS: "Please provide correct details",
  PRACTICE: "Practice",
  PREFERENCES: "Preferences",
  PREFERENCES_SAVED: "Preferences saved",
  PREFERRED_NAME: "Preferred Name",
  PROVIDE_A_VALID_ROLE_ID: "Provide a valid role ID",
  PROVIDE_A_VALID_USER_ID: "Provide a valid user ID",
  PROVIDE_A_VALUE_FOR_ALL_MANDATORY_FIELDS: "Provide a value for all mandatory fields",
  PAYEE: "Payee",
  QUANTITY: "Quantity",
  RATE: "Rate",
  RATE_CODE: "Rate Code",
  RECORDED_DURATION: "Recorded Duration",
  RECORD_CREATED_SUCCESSFULLY_CHANGED_ASSIGNMENT: "Role created successfully. Note: An assigned role had an end date set so the assignment was set to the same end date.",
  RECORD_UPDATED_SUCCESSFULLY: "Record updated successfully",
  RECORD_UPDATED_SUCCESSFULLY_CHANGED_ASSIGNMENT: "Role updated successfully. Note: An assigned role had an end date set so the assignment was set to the same end date.",
  REFRESH: "Refresh",
  RELATIONSHIP_CODE_1: "Stance",
  RELATIONSHIP_CODE_2: "Relationship Code 2",
  RELATIONSHIP_CODE_3: "Notes",
  RELATIONSHIP_CODE_4: "Relationship Code 4",
  RELATIONSHIP_CODE_5: "Relationship Code 5",
  RELEASE: "Release",
  RELEASED: "Released",
  RELEASE_CHECKBOX_VALIDATION_MESSAGE: "Some of the time entries cannot be released due to their current status or them being a general time entry. Do you want to release the other time entries?",
  RELEASE_SELECTED: "Release Selected",
  RELEASE_SELECTED_TIME_ENTRIES: "Release Selected Time Entries",
  REMOVE_AS_TIMEKEEPER: "Remove as timekeeper",
  REPEAT_TIME_ENTRY: "Repeat time entry",
  REPEAT_COST_ENTRY: "Repeat Cost entry",
  REPLACE: "Replace",
  REPLACE_ALL: "Replace All",
  REPLACE_VALUE_CANNOT_LONGER_THAN_30_CHARACTERS: "Replace value cannot longer than 30 characters",
  REPORTING_STAFF_LEVEL: "Reporting Staff Level",
  RESEND_CODE: "Resend code",
  RESET: "Reset",
  RESET_MY_PASSWORD: "Reset My Password",
  RESET_PASS: "Reset My Password   ",
  RESET_PASSWORD: "Reset Password",
  RESTRICTED_WORD: "Restricted Word",
  RESTRICTED_WORDS: "Restricted Words",
  RESTRICTED_WORD_ADDED_SUCCESSFULLY: "Restricted word added successfully",
  RESTRICTED_WORD_ALREADY_EXISTS: "Restricted word already exists",
  RESTRICTED_WORD_MAINTENANCE: "Restricted Word Maintenance",
  RESTRICTED_WORD_UPDATED_SUCCESSFULLY: "Restricted word updated successfully",
  RESUME: "Resume",
  ROLE: "Role",
  ROLES: "Roles",
  ROLE_ACCESS: "Role Access",
  ROLE_ADDED_SUCCESSFULLY: "Role added successfully",
  ROLE_ALREADY_EXISTS: "Role already exists",
  ROLE_ALREADY_EXISTS_AND_CANNOT_BE_ADDED: "Role already exists and cannot be added",
  ROLE_ALREADY_EXISTS_AND_IS_ACTIVE: "Role already exists and is active",
  ROLE_ASSIGNED_TO_USER_BEFORE_START_DATE: "Role is already assigned to user before start date",
  ROLE_CANNOT_BE_CHANGED: "Role cannot be changed",
  ROLE_CANNOT_BE_CREATED_AS_SAME_ROLE_WITHOUT_END_DATE_ALREADY_EXISTS: "Role cannot be created as same role without end date already exists",
  ROLE_DELETED_SUCCESSFULLY: "Role deleted successfully",
  ROLE_IS_ALREADY_ASSIGNED_AND_CANNOT_BE_ADDED: "Role is already assigned and cannot be added",
  ROLE_UPDATE_WAS_SUCCESSFUL: "Role update was successful",
  ROUNDED_DURATION: "Rounded Duration",
  ROW: "Row",
  RULES_ARE_MANDATORY: "Role must include access to at least one screen",
  RULES_START_DATE_CANNOT_BE_PRIOR_TO_CURRENT_DATE: "Rule's start date cannot be prior to current date",
  RULE_ALREADY_EXISTS_FOR_PROVIDED_DATE_RANGE: "Rule already exists for provided date range",
  RULE_END_DATE_CANNOT_BE_LATER_THAN_ROLE_END_DATE: "Rule's end date cannot be later than Role's end date",
  RULE_START_DATE_CANNOT_BE_PRIOR_TO_ROLE_START_DATE: "Rule's start date cannot be prior to Role's start date",
  SAME_ROLES_MUST_HAVE_DIFFERENT_EFFECTIVE_DATE_RANGES: "Same roles must have different effective date ranges",
  SAME_ROLE_ALREADY_ACTIVE: "Same role already active",
  SAVE: "Save",
  "SAVE_&_RELEASE": "Save & Release",
  SAVE_WITH_ERRORS: "Save with errors",
  SCREEN_ENDDATE_AFTER_ROLE: "Rule’s end date cannot be after the role’s end date ",
  SCREEN_STARTDATE_BEFORE_ROLE: "Rule’s start date cannot be prior to the role’s start date",
  SEARCH: "Search",
  SEARCH_BY_CLIENT_OR_ITS_ID: "search by client or its id",
  SEARCH_BY_CLIENT_OR_ITS_NUMBER: "search by client or its number",
  SEARCH_BY_CRITERIA: "search by criteria",
  SEARCH_BY_DELEGATE_NAME: "search by delegate name",
  SEARCH_BY_EMAIL_ADDRESS: "search by email address",
  SEARCH_BY_EMPLOYEE_ID_FIRST_OR_LAST_NAME: "search by first or last name",
  SEARCH_BY_FEATURE: "search by feature",
  SEARCH_BY_KEYWORD: "search by keyword",
  SEARCH_BY_MATTER_OR_ITS_ID: "search by matter or its id",
  SEARCH_BY_MATTER_OR_ITS_NUMBER: "search by matter or its number",
  SEARCH_PARAMETER: "Search Parameter",
  SEARCH_BY_STATUS_NAME: "search by status",
  SELECTED_DAYS_NOT_IN_RANGE: "Some Selected Days not in range. Only Initial entry and repeated time entries that fall in the range will be created.",
  SELECTED_DAYS_NOT_IN_RANGE_ONLY_INITIAL_ENTRY_AND_REPEATED_TIME_ENTRIES_THAT_FALL_IN_THE_RANGE_WILL_: "Some Selected Days not in range. Only Initial entry and repeated time entries that fall in the range will be created.",
  SELECTED_TIME_ENTRIES_CANNOT_BE_BULK_CHANGED: "Selected time entries cannot be bulk changed",
  SELECTED_TIME_ENTRIES_CANNOT_BE_COMBINED: "Selected time entries cannot be combined",
  SELECTED_TIME_ENTRIES_CANNOT_BE_COMBINED_AS_THEY_HAVE_ETHICAL_WALLS: "Selected time entries cannot be combined as they have ethical walls",
  SELECTED_TIME_ENTRIES_CANNOT_BE_DELETED: "Selected time entries cannot be deleted",
  SELECTED_TIME_ENTRIES_CANNOT_BE_EDITED: "Selected time entries cannot be edited",
  SELECTED_TIME_ENTRIES_CANNOT_BE_TRANSFERED: "Selected time entries cannot be transferred",
  SELECTED_TIME_ENTRIES_CANNOT_BE_TRANSFERED_AS_THEY_HAVE_ETHICAL_WALLS: "Selected time entries cannot be transfered as they have ethical walls",
  SELECTED_TIME_ENTRIES_CAN_NOT_BE_BULK_CHANGED_AS_THEY_HAVE_ETHICAL_WALLS: "Selected time entries can not be bulk changed as they have ethical walls.",
  SELECTED_TIME_ENTRIES_DO_NOT_MATCH: "Selected time entries do not match",
  SELECTED_TIME_ENTRIES_RELEASED: "Selected time entries released",
  SELECTED_TIME_ENTRY_DELETED: "Selected time entry deleted",
  SELECT_AN_ACCESS_TYPE: "select an access type",
  SELECT_A_FEATURE: "select a feature",
  SELECT_A_MODULE: "select a module",
  SELECT_A_SUITE: "select a suite",
  SELECT_A_TIMEKEEPER: "Select a timekeeper",
  SELECT_A_USER: "select a user",
  SELECT_DATE: "Select date",
  SELECT_FEATURE: "Select feature",
  SELECT_TIMEKEEPER: "select timekeeper",
  SELECT_USER: "Select user",
  SERVER_ERROR: "Server Error",
  SERVICE: "Service",
  SERVICE_CODE: "Service",
  SETTING: "Setting ",
  SETTINGS: "Settings",
  SET_AS_TIMEKEEPER: "Set as timekeeper",
  SHORTCUT: "shortcut",
  SHORTCUT_DOESNT_EXIST: "Shortcut doesn't exist",
  SHOW_ACTIVE_ROLES_ONLY: "Show active roles only",
  SHOW_ACTIVE_TIMER_ONLY: "Show active timer only",
  SHOW_ALL_ROLES: "Show all roles",
  SHOW_ALL_TIMERS: "Show all timers",
  SHOW_FIRM_AUTOTEXT_ONLY: "Show firm autotext only",
  SHOW_PASSWORD: "Show password",
  SHOW_PERSONAL_AUTOTEXT_ONLY: "Show personal autotext only",
  SING_IN: "Sign in",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SOMETHING_WENT_WRONG_TRY_AGAIN_LATER: "Something went wrong. Try again later.",
  SOME_OF_THE_REPEATED_TIME_ENTRIES_HAVE_ERRORS_AND_WILL_BE_CREATED_AS_INVALID_STATUS_ENTRIES: "Some of the repeated time entries have errors and will be created as invalid status entries",
  SPBrandingHeader: "Unlock Higher Performance",
  SPBrandingHeaderDescription: "Your way to greater levels of efficiency and financial performance",
  SPELL_CHECK: "Spell Check",
  START: "Start",
  START_DATE: "Start Date",
  START_DATE_CANNOT_BE_LATER_THAN_END_DATE: "Start date cannot be later than end date",
  START_DATE_CANNOT_BE_LATER_THAN_ROLE_END_DATE: "Start date cannot be later than role's end date",
  START_DATE_CANNOT_BE_PRIOR_TO_CURRENT_DATE: "Start date cannot be prior to current date",
  START_DATE_CANNOT_BE_SAME_AS_OR_LATER_THAN_USERS_END_DATE: "Start date cannot be same as or later than user's end date",
  START_DATE_LESS_END_DATE: "Start Date should be prior to End date",
  START_DATE_SHOULD_BE_PRIOR_TO_END_DATE: "Start Date should be prior to End date",
  STATUS: "Status",
  STATUS_IS_SET_TO_INVALID_FOR_A_FUTURE_DATE: "Status is set to \"invalid\" for a future date",
  STAY: "Stay",
  STOP: "Stop",
  SUB: "Sub",
  "SUB-CLIENT": "Sub-Client",
  SUCCESS: "SUCCESS",
  SUCCESSFULLY_CHANGED_PASSWORD_OF_THE_USER: "Successfully changed password of the user",
  SUGGESTED_REPLACEMENT: "Suggested Replacement",
  SUITE: "Suite",
  SWITCH_DURATION_FORMAT: "Switch duration format",
  SWITCH_SORT_ORDER: "Switch sort order",
  SYSTEM_CONFIGURATION: "System Configuration",
  TASK: "Task",
  TASK_CODE: "Sub-Matters",
  TEMPORARY_PASSWORD_HAS_EXPIRED_OR_IS_INVALID_CONTACT_YOUR_SECURITY_ADMINISTRATOR: "Temporary password has expired or is invalid. Contact your security administrator to request a new password.",
  "TEST For Trigger": "TESTING",
  THE_HOUR_INCREMENTS_DONT_COMPLY_WITH_THE_CLIENT_MATTER: "The hour increments don't comply with the client-matter.",
  TIME: "Time",
  TIMED_OUT: "Request timed out",
  TIMEKEEPER: "Timekeeper",
  TIMEKEEPER_WITHOUT_ADD_MNG_TIME_FEATURE: "This user is a timekeeper and requires at least one role with the 'Add and manage time entries' feature",
  TIMER_ROUNDING: "Timer Rounding ",
  TIME_ENTRIES_WITH_ETHICAL_WALLS_CANNOT_BE_CHANGED_DO_YOU_WANT_TO_CHANGE_THE_OTHER_TIME_ENTRIES: "Time entries with ethical walls cannot be changed. Do you want to change the other time entries?",
  TIME_ENTRIES_WITH_ETHICAL_WALLS_CANNOT_BE_COMBINED_DO_YOU_WANT_TO_COMBINE_THE_OTHER_TIME_ENTRIES: "Time entries with ethical walls cannot be combined.Do you want to combine the other time entries?",
  TIME_ENTRIES_WITH_ETHICAL_WALLS_CANNOT_BE_EDITED_DO_YOU_WANT_TO_EDIT_THE_OTHER_TIME_ENTRIES: "Time entries with ethical walls cannot be edited. Do you want to edit the other time entries?",
  TIME_ENTRIES_WITH_ETHICAL_WALLS_CANNOT_BE_TRANSFERED_DO_YOU_WANT_TO_TRANSFER_THE_OTHER_TIME_ENTRIES: "Time entries with ethical walls cannot be transfered.Do you want to transfer the other time entries?",
  TIME_ENTRY: "Time Entry",
  TIME_ENTRY_ADDED_SUCCESSFULLY: "Time entry added successfully",
  TIME_ENTRY_DELETED_BY_ANOTHER_APPLICATION: "Time entry has already been deleted through another application",
  TIME_ENTRY_DELETED_SUCCESSFULLY: "Time entry deleted successfully",
  TIME_ENTRY_DELETION_FAILED: "Time entry deletion failed",
  TIME_ENTRY_NOT_FOUND: "Time entry not found",
  TIME_ENTRY_RELEASED_BY_ANOTHER_APPLICATION: "Time entry has already been released through another application",
  TIME_ENTRY_RELEASE_FAILED: "Time entry release failed",
  TIME_ENTRY_SAVED: "Time entry saved",
  TIME_ENTRY_SAVED_AND_RELEASED: "Time entry saved and released",
  TIME_ENTRY_UPDATED_SUCCESSFULLY: "Time entry updated successfully",
  TIME_MANAGEMENT: "SureTime",
  TO: "To",
  TOOLTIP_TEXT: "Tooltip text",
  TOTAL: " Total",
  TO_THE_FOLLOWING_DATE_ON_THE_SELECTED_DAYS: "To the following date on the selected days",
  TRANSFER: "Transfer",
  TRANSFER_CHECKBOX_VALIDATION_MESSAGE: "Some of the time entries cannot be transferred due to an ethical wall or their current status. To transfer individual time entries, use the Transfer icon next to them on the time entries list. Do you want to transfer the Ready and Invalid time entries?",
  TRANSFER_SELECTED_TIME_ENTRIES: "Transfer Selected Time Entries",
  TRANSFER_SELECTED_TIME_ENTRIES_2: "Transfer Selected Time Entries",
  TRANSFER_TE_SUCCESS: "Time entries were successfully transferred",
  TRANSFER_TIME_ENTRY: "Transfer Time Entry",
  TTIME_ENTRY_RELEASED_SUCCESSFULLY: "Time entry released successfully",
  TURNED_ON: "Turned On",
  TURNED_OFF: "Turned Off",
  TYPE: "Type",
  TYPE_OR_SPEAK_YOUR_NARRATIVE: "Type or speak your narrative",
  TYPE_OR_SPEAK_YOUR_NARRATIVE_5000_CHARS_REMAINING: "Type or speak your narrative (5000 chars remaining)",
  UDF2: "Time Detail User Defined 2",
  UDF2_LITERAL: "UDF 2",
  UDF3: "Time Detail User Defined 3",
  UDF3_LITERAL: "UDF 3",
  UNABLE_TO_COMBINE_TE: "Selected time entries cannot be combined",
  UNABLE_TO_RETRIEVE_ROLES: "Unable to retrieve roles",
  UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN: "Unable to save time entry. Try again.",
  UNABLE_TO_TRANSFER_TE: "Selected time entries cannot be transfered",
  UNABLE_TO_UPDATE: "Unable to update",
  UNAUTHORIZED: "Unauthorized",
  UNAUTHORIZED_USER: "Unauthorized user",
  UNKNOWN_ERROR: "Unknown Error",
  UNRELEASED: "Unreleased",
  UNRELEASED_TIME: "Unreleased Time",
  UP: "Up",
  UPDATED_BY: "Updated by",
  UPDATE_EACH_SELECTED_TIME_ENTRY: "Update Each Selected Time Entry",
  UPDATE_ON: "Updated on",
  UPDATE_PASSWORD: "Update Password",
  UPDATE_ROLE: "Update Role",
  UPDATE_RULE: "Update Rule",
  UPDATE_SELECTED_ROLES: "Update Selected Roles",
  UPDATE_SELECTED_TIME_ENTRIES: "Update Selected Time Entries",
  UPDATE_SELECTED_USERS: "Update Selected Users",
  UPDATE_SUCCESSFUL: "Update successful.",
  UPLOAD_DATA: "Upload Data",
  USER: "User",
  USERNAME: "Username ",
  USERS: "Users",
  USERS_END_DATE_HAS_OCCURRED_ROLES_CANNOT_BE_ADDED_OR_UPDATED: "User's end date has occurred. Roles cannot be added or updated.",
  USER_ACCESS: "User Access",
  USER_ADDED_SUCCESSFULLY: "User added successfully",
  USER_CREATION_FAILED: "User creation failed",
  USER_END_DATE_CANNOT_BE_PRIOR_TO_ROLE_START_DATE: "User's end date cannot be prior to role's start date",
  USER_NOT_FOUND: "User not found",
  USER_PASSWORD_EXPIRED: "User Password is expired, please reset your password to login",
  USER_UPDATION_FAILED: "User updation failed",
  USE_TEMP_PASSWORD_SENT_BY_ADMIN: "Use the temporary password sent by your security administrator",
  U_LABEL: "U",
  "User is not authorized to access this resource with explicit deny": "Your security administrator has updated your user role. You must log in again to continue.",
  VALIDATION_EXCEPTION: "Validation exception",
  VALIDATION_FAILED: "Validation Failed",
  VERIFICATION_CODE_INVALID_REQUEST_NEW_CODE: "Verification code has expired or is invalid. Request a new code.",
  WAIVER_SAVED_ATTACHED_TO_SEARCHES: "Waiver saved and attached to selected searches",
  WARNING: "Warning",
  WEEKLY_HEADER: "Weekly",
  WITH: "With",
  WORKING_ON_BEHALF_OF: "Working on behalf of",
  WORKING_ON_BEHALF_OF_USER_IS_NOT_A_TIMEKEEPER: "Working on behalf of user is not a timekeeper",
  WORK_ON_BEHALF_OF: "Work on behalf of",
  YES: "Yes",
  YOUR_ACCOUNT_DOES_NOT_CURRENTLY_HAVE_ACCESS_CONTACT_YOUR_SECURITY_ADMINISTRATOR_FOR_HELP: "Your account does not currently have access. Contact your security administrator for help.",
  YOUR_ACKNOWLEDGMENT_IS_BEING_LOGGED_BY_SUREPOINT_TECHNOLOGIES: "Your acknowledgment is being logged by SurePoint Technologies.",
  YOUR_NARRATIVE_INCLUDES_RESTRICTED_WORDS_YOU_SHOULD_REPLACE_ALL_RESTRICTED_WORD_ERRORS_BEFORE_SAVING: "Your narrative includes restricted words. You should replace all restricted word errors before saving.",
  YOUR_PASSWORD_HAS_EXPIRED: "Your password has expired",
  YOUR_PASSWORD_WAS_JUST_CHANGED: " Your password was just changed. Please wait a few minutes and try again.",
  YOUR_PASSWORD_WILL_EXPIRE_IN: "Your password will expire in",
  YOUR_PASSWORD_WILL_EXPIRE_TODAY: "Your password will expire today.",
  YOU_ARE_ABOUT_TO_BE_LOGGED_OUT_DUE_TO_INACTIVITY: "You are about to be logged out due to inactivity",
  YOU_ARE_NOT_AUTHORISED_TO_ACCESS_THIS_PAGE: "You are not authorized to access this page.",
  YOU_HAVE_ERRORS_ON_THE_SCREEN: "You have errors on the screen",
  YOU_HAVE_UNSAVED_CHANGES_ON_SCREEN_YOU_WILL_LOSE_CHANGES_IF_YOU_CONTINUE: "You have unsaved changes on screen. You will lose changes if you continue",
  YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_SAVE: "You have unsaved changes on the screen. If you leave, your changes will be lost. Do you want to save?",
  YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_STAY: "You have unsaved changes on the screen. If you leave, your changes will be lost. Do you want to stay?",
  YTD_HEADER: "YTD"
};
export var trcHeader = {
  "addTime": {
    moduleName: "MG-Practice-MD-TM",
    featureName: "MG-Practice-MD-TM-MN-AddTime"
  },
  "manageTime": {
    moduleName: "MG-Practice-MD-TM",
    featureName: "MG-Practice-MD-TM-MN-AddTime"
  },
  "monthView": {
    moduleName: "MG-Practice-MD-TM",
    featureName: "MG-Practice-MD-TM-MN-AddTime"
  },
  "Delegate": {
    moduleName: "MG-Account-MD-Delegates",
    featureName: "MG-Account-MD-Delegates-FE-Delegates"
  },
  "Autotext": {
    moduleName: "MG-Account-MD-Autotext",
    featureName: "MG-Account-MD-Autotext-FE-Autotext"
  },
  "Preference": {
    moduleName: "MG-Account-MD-Preferences",
    featureName: "MG-Account-MD-Preferences-MN-Practice"
  },
  "My Profile": {
    moduleName: "MG-Account-MD-Profile",
    featureName: "MG-Account-MD-Profile-MN-Profile"
  },
  "Restricted Word": {
    moduleName: "MG-Settings-MD-System",
    featureName: "MG-Settings-MD-System-FE-Add-Mng-Narrative-Ctrls"
  },
  "LogOut": {
    moduleName: "MG-Account-MD-Logout",
    featureName: "MG-Account-MD-Logout-MN-Logout"
  },
  "Access": {
    moduleName: "MG-Settings-MD-Access",
    featureName: "MG-Settings-MD-Access-FE-Users-Roles"
  }
}; /* ************************************************** Headers for view specific api calls *********************************************/
export var HeaderView = {
  AddTimeViewApiCallHeaderName: "addTime",
  ManageTimeViewApiCallHeaderName: "manageTime",
  LandingPageViewApiCallHeaderName: "menus",
  MonthViewApiCallHeaderName: "monthView",
  DelegatesViewApiCallHeaderName: "Delegate",
  AutotextViewApiCallHeaderName: "Autotext",
  PreferenceViewApiCallHeaderName: "Preference",
  MyprofileViewApiCallHeaderName: "My Profile",
  RestrictedWordsViewApiCallHeaderName: "Restricted Word",
  AccessViewCallHeaderName: "Access",
  RolesViewCallHeaderName: "Roles",
  LoginViewCallHeaderName: "Login"
}; /* ************************************************** Headers for button specific api calls *********************************************/
export var ButtonCall = {
  DeleteTimeEntryAPICallButtonHeaderName: "delete time entry",
  TransferAPICallButtonHeaderName: "transfer time entry",
  CombineAPICallButtonHeaderName: "combine time entry",
  ExportAPICallButtonHeaderName: "export time entry",
  TranscriptAPICallButtonHeaderName: "transcript",
  BulkEditAPICallButtonHeaderName: "bulk edit time entry",
  GeneralTimeSaveAPICallButtonHeaderName: "general time entry save",
  RegularTimeSaveAPICallButtonHeaderName: "regular time entry save",
  AdvancedSearchAPICallButtonHeaderName: "advanced Search",
  ReleaseTimeSaveAPICallButtonHeaderName: "release time entry",
  TimerAPICallButtonHeaderName: "Timer",
  SavePreferenceButtonHeaderName: "Save Preference",
  UpdatePasswordButtonHeaderName: "Update Password",
  SaveDelegateButtonHeaderName: "Save Delegate",
  SaveAutoTextButtonHeaderName: "Save Autotext",
  DeleteAutoTextButtonHeaderName: "Delete Autotext",
  LogOutButtonHeaderName: "LogOut",
  SaveUserButtonHeaderName: "Save User",
  UpdatePersonaButtonHeaderName: "Update Persona",
  SavePersonaButtonHeaderName: "Save Persona",
  AddRestrictedWordButtonHeaderName: "Add Restricted word",
  UpdateRestrictedWordButtonHeaderName: "Update restricted word",
  LoginButtonHeaderName: "Login",
  DeleteDelegateButtonHeaderName: "Delete Delegate"
};