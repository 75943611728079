import React from "react";
import { InputText } from "primereact/inputtext";
import FieldState from "./AddTimePopup/AddTimePopup.State.ts";
import DurationState from "../../store/DurationState.ts";
import { TOOLTIPS_KEYS } from "../../config/vars";

const DurationComponent = ({
  getCode,
  validateDurationField,
  durationToggleClick,
  toggleClick,
  onChangeMM,
  onChangeHH,
  onChangeDuration,
  setChanged,
  tooltips,
  screen,
  TabOrder = 0
}) => {
  return (
    <>
      <div className="af-duration-header">
        {durationToggleClick ? (
          <div className="af-time-label-duration">
            <label htmlFor="time">
              {" "}
              {getCode("DURATION")} {screen !== "bulkUpdate" && <span className="af-req-star">*</span>}
            </label>
          </div>
        ) : (
          <div className="recoded-duration af-time-post">
            <div className="af-time-label">
              <label htmlFor="time">
                {" "}
                {getCode("RECORDED_DURATION")}{" "}
                {screen !== "bulkUpdate" && <span className="af-req-star">*</span>}
              </label>
            </div>
            <div className="af-time-label-bd af-time-post">
              <label htmlFor="time">
                {" "}
                {getCode("BILLABLE_DURATION")}{" "}
                {screen !== "bulkUpdate" && <span className="af-req-star">*</span>}
              </label>
            </div>
          </div>
        )}

        <div className="af-bil-label">
          <label htmlFor="time">
            {" "}
            <span
              className="toggle-button" onClick={toggleClick} 
              onKeyPress={toggleClick} tabIndex={0}
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]}>
              <i className=" pi pi-arrow-left"></i>

              <i className=" pi pi-arrow-right"></i>
            </span>
          </label>
        </div>
      </div>
      <div className="af-input-lookup">
        {!durationToggleClick ? (
          <div className="billable-duration-col">
            <div className="hour-col">
              <InputText
                tabIndex={TabOrder}
                id="hourstxt"
                type="number"
                value={DurationState.hh}
                onKeyPress={(e) =>
                  e.key === "e" || e.key === "E" ? e.preventDefault() : e
                }
                onChange={onChangeHH}
                onBlur={validateDurationField}
                autoComplete="off"
              />{" "}
              <p>{getCode("HH")}</p> <span className="sp-coln">:</span>
            </div>

            <div className="min-col">
              <InputText
                tabIndex={TabOrder}
                id="mintxt"
                type="number"
                value={DurationState.mm}
                onKeyPress={(e) =>
                  e.key === "e" || e.key === "E" ? e.preventDefault() : e
                }
                onChange={onChangeMM}
                onBlur={validateDurationField}
                autoComplete="off"
              />{" "}
              <p>{getCode("MM")}</p>
              <span className="sp-eql">=</span>
            </div>

            <div className="bil-col">
              {" "}
              <InputText
                tabIndex={TabOrder}
                value={DurationState.duration}
                disabled
                id="billTxt"
                type="text"
                autoComplete="off"
              />{" "}
              <p>x.xx</p>
            </div>
          </div>
        ) : (
          <div className="duration-col">
            <span className="duration-input-margin">
              <InputText
                tabIndex={TabOrder}
                type="number"
                id="durationtxt"
                value={DurationState.duration}
                placeholder="0.00"
                onKeyPress={(e) =>
                  e.key === "e" || e.key === "E" ? e.preventDefault() : e
                }
                onChange={onChangeDuration}
                onBlur={() => {
                  validateDurationField();
                }}
                autoComplete="off"
              />
            </span>
            <div className="af-timer-btn-panel">
              {DurationState.increments.map((d) => (
                <button
                   tabIndex={TabOrder}
                  className="p-button p-component af-btn-default"
                  onClick={() => {
                    setChanged(true);
                    FieldState.update(FieldState.setDurationError);
                    DurationState.update(DurationState.onIncrement, [d]);
                    validateDurationField();
                  }}
                  key={d}>
                  <span className="p-button-label p-c">
                    +{(d / 60).toFixed(2)}
                  </span>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DurationComponent;
