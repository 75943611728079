import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../../../../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import React, { useState, useEffect } from "react";
import { CharacterLengthColDef, buildQueryString } from "./CharacterLengthColDef";
import CharacterLengthMiddleBodyControl from "./CharacterLengthMiddleBodyControl";
import utils from "../../../../../../utils/jsUtils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { refreshStoreOnError, setErrorMessage } from "../../../../../../actions/AppAction";
import { MAX_MESSAGE_LIMIT, NO_OF_ROWS_IN_GRID } from "../../../../../../config/messages";
import CustomTable from "../../../../../common/DataTable/CustomTable";
import jsUtils from "../../../../../../utils/jsUtils";
import "../RestrictedWords/RestrictedWordsGrid.scss"
import { InputSwitch } from "primereact/inputswitch";
import CharacterLengthCard from "./CharacterLengthCard"
import DialogControlHeader from "../../../../../common/DialogControl/DialogControlHeader";
import RestrictedWordsLeftBodyControl from "../RestrictedWords/RestrictedWordsLeftBodyControl";
import DialogControl from "../../../../../common/DialogControl/DialogControl";
import RestrictedWordsRightBodyControl from "../RestrictedWords/RestrictedWordsRightBodyControl";
import { addTime } from "../../../../../../services/AddTimeApi";
import { RestrictedWords } from "../../../../../../services/RestrictedWordsApi";
import moment from "moment";
import { helpPageUrl } from "../../../../../../config";
import { HELP_LINKS } from "../../../../../../config/vars";
import { setPageHelpLink } from "../../../../../../actions/LandingPageAction";
import { characterLength } from "../../../../../../services/CharacterLengthApi";

export default function CharacterLengthGrid(props) {
  
  const [narrativeGridToggle , setNarrativeGridToggle] = useState("firm");
  const[moveScrollToTop , setMoveScrollToTop] = useState(false);
  const delegateId = useSelector(
    (state) => state.TimeManagementReducer.delegateId
  );

  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [colDef, setColDef] = useState(CharacterLengthColDef);
  const [visible,setVisible]=useState(false);
  const [isResetButtonActive, setIsResetButtonActive] = useState(true);
  const [DBCurrentDate, setDBCurrentDate] = useState("");
  const [clientSelected, setClientSelected] = useState({
    field: "client",

    isDisplay: true,

    isMandatory: true,

    displayLabel: jsUtils.getLiteralValue("CLIENT"),

    values: [
      {
        id: "",

        label: "",

        desc: "",

        code: "",

        isDefault: true,
      },
    ],
  });

  const [subClientSelected, setSubClientSelected] = useState({
    field: "subClient",

    isDisplay: true,

    isMandatory: true,

    displayLabel: jsUtils.getLiteralValue("SUB"),

    values: [
      {
        id: "",

        label: "",

        desc: "",

        code: "",

        isDefault: true,
      },
    ],
  });

  const [matterSelected, setMatterSelected] = useState({
    field: "matter",

    isDisplay: true,

    isMandatory: true,

    displayLabel: jsUtils.getLiteralValue("MATTER"),

    values: [
      {
        id: "",

        label: "",

        desc: "",

        code: "",
      },
    ],
  });

  const [limit,setLimit]=useState("");
  const [type,setType]=useState("");
  const [startDate,setStartDate]=useState(null);
  const [endDate,setEndDate]=useState(null);  
  const [narrativeValue,setNarrativeValue]=useState("");
  const [noOfChars,setNoOfChars]=useState(100);
  const [mode,setMode]=useState("");
  const [selectedRowId,setSelectedRowId]=useState("");
  const [enableSaveButton,setEnableSaveButton]=useState(false);
  const [changed,setChanged]=useState(false);
  const [displayConfirmation,setDisplayConfirmation] = useState(false);
  const [startDateDisable,setStartDateDisable]=useState(false);
  const [onClientSelectedRefresh,setOnClientSelectedRefresh]=useState(0);

  const loadClients = (searchText, page = 1, pageOffset = 0) => {
    return addTime.clients(delegateId, page, searchText, pageOffset);
  };

  /**
  *  makes an api call to get all the subClients on basis of the search text and the page   
  * @param {String} searchText - contains the user typed searchtext
  * @param {Number} page - contains the page number 
  * @return It returns a promise that contains the success or failure object
  */
  const loadSubClients = (searchText, page = 1, pageOffset=0) => {
    return addTime.subClients(
      clientSelected.values[0].id,
      delegateId,
      page,
      searchText,
      pageOffset
    );
  };

  /**
  *  makes an api call to get all the matters on basis of the search text and the page   
  * @param {String} searchText - contains the user typed searchtext
  * @param {Number} page - contains the page number 
  * @return It returns a promise that contains the success or failure object
  */
  const loadMatters = (searchText, page = 1, pageOffset = 0) => {
    return addTime.matters(
      subClientSelected.values[0].id,
      delegateId,
      page,
      searchText,
      pageOffset
    );
  };

  const [dataLoaded, setDataLoaded] = useState(false);
  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });
  const [closeAllCardsRefresh, setCloseAllCardsRefresh] = useState(0)
  const [DataFromAPI, setDataFromAPI] = useState([]);
  const [loadingError, setLoadingError] = useState(false);
  const [DataFromAPIBackup, setDataFromAPIBackup] = useState([]);
  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [scrollNoMore, setScrollNoMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  /**
   *   gets the labels/tooltips/error codes from state codeFromAPI
   * @param {String} key - literals key
   * @returns the value associated for that particular key and if it does not find the key then return the key itself
   */
 
  const dispatch = useDispatch();
  
  const edit_click = (rowItem) => {
    setClientSelected({
      ...clientSelected,

      values: [
        {
          id: rowItem.client_id,

          label: rowItem.client_name,

          desc: rowItem.client_name,

          code: rowItem.client_code,

          isDefault: true,
        },
      ],
    });

    // enter sub selected values also when the changes are made in api

    setSubClientSelected({
      ...subClientSelected,
      values:[{
        id: rowItem.sub_client_id,

          label: rowItem.sub_client_name || "",

          desc: rowItem.sub_client_name || "",

          code: rowItem.sub_client_no || "",

          isDefault: true,
      }]
    })

    setMatterSelected({
      ...matterSelected,

      values: [
        {
          id: rowItem.matter_id,

          label: rowItem.matter_name || "",

          desc: rowItem.matter_name || "",

          code: rowItem.matter_code || "",

          isDefault: true,
        },
      ],
    });

    

    if(rowItem.type && rowItem.type.toLowerCase()==="warning")
    {
    setType({state:"Warning"})
    }
    else{
      setType({state:"Error"})
    }

    if(rowItem.effective_start_date){
      let startdate=rowItem.effective_start_date?.substring(0, 10)?.split("-") || null;
        startdate = startdate && ( startdate[1] + "/" + startdate[2] + "/" + startdate[0]);
        startdate && setStartDate(new Date(rowItem.effective_start_date));
        if(startdate && ((new Date(rowItem.effective_start_date)).setHours(0,0,0,0) <= (new Date()).setHours(0,0,0,0))){
          setStartDateDisable(true);
        }
    }

    if(rowItem.effective_end_date){
      let enddate=rowItem.effective_end_date?.substring(0, 10)?.split("-") || null;
        enddate = enddate && ( enddate[1] + "/" + enddate[2] + "/" + enddate[0]);
        enddate && setEndDate(new Date(rowItem.effective_end_date));
    }

    setLimit(rowItem.limitValue);
    setNarrativeValue(rowItem.errorMessage);
    setNoOfChars(100-(rowItem.errorMessage.length));
    setMode("edit");
    setSelectedRowId(rowItem.rowId);
    setVisible(true);
    setEnableSaveButton(false)
  };


  CharacterLengthColDef[CharacterLengthColDef.length - 1].filter.onClick = () => {
      resetFilters();
      loadData(true);
      setIsResetButtonActive(true);
  };

  let setScrollToTop = () => {
    setMoveScrollToTop(true);
  }


  let setFilters = (key, value, index) => {
    let state = colDef;
    colDef[index].filter.val = value;
    setColDef([...state]);
  };


  let resetFilters = () => {
    let state = colDef;
    // if (narrativeGridToggle !== "firm"){
      colDef.forEach((item)=>item.filter.val="");
      // colDef[0].filter.val = "";
      // colDef[1].filter.val = "";
      // colDef[2].filter.val = "";
      // colDef[3].filter.val = "";
      // colDef[4].filter.val = "";
      // colDef[5].filter.val = "";
      // colDef[6].filter.val = "";
      // colDef[7].filter.val = "";
    // }
    // else{
    //   colDef[0].filter.val = "";
    //   colDef[1].filter.val = "";
    //   colDef[2].filter.val = "";
    //   colDef[3].filter.val = "";
    //   colDef[4].filter.val = "";
      
    // }
    
    sort.index = -1;
    setSort({ index: -1, isAsc: true, propName: "" });
    setColDef([...state]);
    setScrollToTop();
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setIsResetButtonActive(true);
  };

  const onFilterChange = (key, value, index) => {
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setPageNumber(1);
    setScrollNoMore(false);
    utils.debounce(loadData, 400, true, sortNameField, sortByField);
    setFilters(key, value, index);
    setScrollToTop();

    let resetSortParams = {
      index: -1,
      isAsc: true,
      propName: "",
    }
    let defaultValue = [];
    if (narrativeGridToggle !== "firm"){
      defaultValue = ["","","","","","","",""];
    }
    else{
      defaultValue = ["","","","",""];
    }

    let filterStatus = jsUtils.checkIfCustomTableFilterChanged(colDef,defaultValue)
    let sortStatus =  _.isEqual(sort, resetSortParams);
    setIsResetButtonActive(filterStatus && sortStatus);
  };

  const { hasMultipleSubClients,meUserData} = useSelector(
    ({ PreferenceReducer }) => ({
      hasMultipleSubClients: PreferenceReducer.hasMultipleSubClients,
      meUserData:PreferenceReducer.meUserData
    })
  );

  useEffect(() => {
      let columnDefination = narrativeGridToggle === "firm" ? CharacterLengthColDef.filter(item => item.visible.includes("firm")):CharacterLengthColDef.filter(item => item.visible.includes("cmLevel"));
      if(hasMultipleSubClients){
        setColDef(columnDefination);
      }
      else{
        let columnsWithoutSubField=columnDefination.filter(item=>item.data.propName!=="subClient");
        setColDef(columnsWithoutSubField);
      }
    if(delegateId){
        loadData(true);
    }
    dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.CHARACTER_LENGTH}`))
  }, [delegateId , narrativeGridToggle,hasMultipleSubClients]);


  useEffect(() => {
    const currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    const currentDateFormat =  currentDate && moment(`${currentDate.slice(0, 10)}`).format("MM/DD/YYYY");
    setDBCurrentDate(currentDateFormat);

    return ()=>{
    resetFilters();
    setColDef(CharacterLengthColDef.filter(item => item.visible.includes("firm")))
}
  }, [meUserData]);

  /** Loads list of user based on the page number
   * @param {Boolean} reset - if true it will load first page
   */

  const loadData = (reset = true, sortName="", sortby="") => {
    // set to loading
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setLoading(true);
    setDataLoaded(true);

    let page = 1;
    // build query string
    if (reset) {
      setPageNumber(1);
      
    } else {
      // page = pageNumber + 1;
      // setPageNumber(pageNumber + 1);
    }
    let searchParams = buildQueryString(sortName, sortby,narrativeGridToggle);

    characterLength
      .post(searchParams, reset ? 1 : pageNumber, sortName, sortby)
      .then((res) => {
        setLoadingError(false);
        let formattedJson = { data: [] };
        setDataLoaded(true);
        if (res && res.message) {
          res.message.forEach((word) => {
            let effective_start_date =
            word.effective_start_date ? moment(`${word.effective_start_date.slice(0, 10)}`).format("MM/DD/YYYY") :
              moment(
                word.effective_start_date,
                "YYYY-MM-DD"
              ).format("MM/DD/YYYY");
          let effective_end_date =
            word.effective_end_date ? moment(`${word.effective_end_date.slice(0, 10)}`).format("MM/DD/YYYY") :
              moment(
                word.effective_end_date,
                "YYYY-MM-DD"
              ).format("MM/DD/YYYY");
          let fromtDateStatus =
            word.effective_start_date ? moment(moment(`${word.effective_start_date.slice(0, 10)}`).format("MM/DD/YYYY"),
              "MM/DD/YYYY",
              true).isValid() :
              moment(
                moment(word.effective_start_date, "YYYY-MM-DD").format(
                  "MM/DD/YYYY"
                ),
                "MM/DD/YYYY",
                true
              ).isValid();
          let toDateStatus =
            word.effective_end_date ? moment(moment(`${word.effective_end_date.slice(0, 10)}`).format("MM/DD/YYYY"),
              "MM/DD/YYYY",
              true).isValid() :
              moment(
                moment(word.effective_end_date, "YYYY-MM-DD").format(
                  "MM/DD/YYYY"
                ),
                "MM/DD/YYYY",
                true
              ).isValid();
            let item = {
              client_id:word.sys_client_id,
              client_name:word.client_name,
              client_code:word.client_number,

              matter_id:word.matter_id,
              matter_name:word.matter_description,
              matter_code:word.matter_number,

              sub_client_id:word.sys_sub_client_id,
              sub_client_name:word.sub_client_name,
              sub_client_no:word.sub_client_no,

              clientIdWithName: word.client_name && word.client_number ?word.client_name+" ("+word.client_number+")":"",
              matterIdWithDesc:word.matter_description && word.matter_number ? word.matter_description+" ("+word.matter_number+")":"",
              subClient:word.sub_client_no,
              rowId:word.restricted_words_id,

              limitValue:word.suggested_replacement,
              
              type: word.type==="W"?"Warning":word.type==="E"?"Error":"",
              effective_start_date: fromtDateStatus ? effective_start_date : "",
              effective_end_date: toDateStatus ? effective_end_date : "",
              errorMessage:word.error_message
            };
            formattedJson.data.push(item);
          });
          if (reset) {
            setDataFromAPI(JSON.parse(JSON.stringify(formattedJson.data)));
            setDataFromAPIBackup(
              JSON.parse(JSON.stringify(formattedJson.data))
            );
            if (formattedJson.data.length === 0) {
              setNoItemsFoundMessage(true);
              setNoItemsFoundMessageText({
                titleText: jsUtils.getLiteralValue("NO_DATA_FOUND"),
                buttonText: null,
              });
            }
            else {
              page = 1;
              if (formattedJson.data.length < (page * NO_OF_ROWS_IN_GRID)) {
                setScrollNoMore(true);
                
              } else {
                setPageNumber(page + 1);
                setScrollNoMore(false);
               
              }

            }
            
          } else {
            setDataFromAPI(
              JSON.parse(
                JSON.stringify([...DataFromAPIBackup, ...formattedJson.data])
              )
            );
            setDataFromAPIBackup(
              JSON.parse(
                JSON.stringify([...DataFromAPIBackup, ...formattedJson.data])
              )
            );
           
            page = pageNumber;
            if ((formattedJson.data.length + DataFromAPIBackup.length) < (pageNumber * NO_OF_ROWS_IN_GRID)) {
              setScrollNoMore(true)
            } else {
              setScrollNoMore(false);
              setPageNumber(page + 1);
            }
           
          }
        }
      })
      .catch((error) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoadingError(error || "Error");
        setDataFromAPI([]);
        setDataFromAPIBackup([]);
      })
      .finally(() => {
        setLoading(false);
        setDataLoaded(true);
      });
  };

  /** Sort list of user based on the sorting paramters
   * @param {Array} localSort - Sorting parameters
   * @param {Object} data - Users data for sorting
   */
  const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc? "asc":"desc";
      
      let sortName = "";

        switch(localSort.propName) {        
          case 'clientIdWithName':
            sortName = "client"
            break;

          case 'subClient':
            sortName = "subClient"
            break;

          case 'matterIdWithDesc':
            sortName = "matter"
            break;

          case "limitValue":
            sortName = "suggestedReplacement"
            break;

          case 'type':
            sortName = "type"
            break;

          case 'effective_start_date':
            sortName = "startDate"
            break;

          case 'effective_end_date':
            sortName = "endDate"
            break;
        }

      setSortByField(sortBy);
      setSortNameField(sortName);

      loadData(true, sortName,sortBy)
    }
  };

  const onHideDialog=(forceClose=false)=>{
    if(!changed || forceClose)
      {
      resetFilters();
      setNarrativeGridToggle("firm");
      setClientSelected({...clientSelected,values:[{id:"",desc:"",label:"",code:"",isDefault:true}]});
      setSubClientSelected({...subClientSelected,values:[{id:"",desc:"",label:"",code:"",isDefault:true}]});
      setMatterSelected({...matterSelected,values:[{id:"",desc:"",label:"",code:""}]});
      setType(null);
      setStartDate(null);
      setEndDate(null);
      setNarrativeValue("");
      setNoOfChars(MAX_MESSAGE_LIMIT);
      setVisible(false);
      setMode("");
      loadData(true);
      setLimit("");
      setEnableSaveButton(false);
      setStartDateDisable(false);
      }
      else{
        setDisplayConfirmation(true);
      }
}

const getHeader =()=>{
    return <DialogControlHeader
          helpPageUrl={`${helpPageUrl}${HELP_LINKS.CHARACTER_LENGTH_MAINTENANCE}`}
          toolTipHelp={jsUtils.getLiteralValue("HELP")}
          breadCrumbs={[jsUtils.getLiteralValue("SETTINGS"),jsUtils.getLiteralValue("CHARACTER_LENGTH_MAINTENANCE")]}
          hideDialog ={()=>{
            onHideDialog();
          }}
    />
  }


  const onEnableSaveButton=(_startDate,_type,_narrativeValue,_limit)=>{
    if(((narrativeGridToggle!=="firm" && clientSelected.values[0].id) ||  (narrativeGridToggle==="firm" && !clientSelected.values[0].id))  && _startDate && _type  && _narrativeValue.length && _limit.length)
      {
        setEnableSaveButton(true);
      }
      else
      {
        setEnableSaveButton(false);
      }
  }

  useEffect(()=>{
    onEnableSaveButton(startDate,type,narrativeValue,limit);
  },[narrativeGridToggle])

  /**
   * whenever there is a client change we call the subclient api and if there is only one subclient we set it to subclient field.
   */
   useEffect(() => {
    setLoading(true);
    onClientSelectedRefresh > 0 &&
      loadSubClients("", 1)
        .then((res) => {
          setLoading(false);
          if (res && res.subClients && res.subClients.length === 1) {
            setSubClientSelected({
              ...subClientSelected,
              values: [
                {
                  id: res.subClients[0].subClientId,
                  desc: res.subClients[0].subClientName,
                  label: res.subClients[0].subClientNumber,
                  code: res.subClients[0].subClientNumber
                },
              ],
            });
          } else {
            setSubClientSelected({
              ...subClientSelected,
              values: [{ id: "", desc: "", label: "" }],
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setSubClientSelected({
            ...subClientSelected,
            values: [{ id: "", desc: "", label: "" }],
          });
        });
  }, [onClientSelectedRefresh]);

   /**
  *  on selection of a client from the dropdown we update client field with the selected value 
  *  after setting the client it resets the subclient and matter    
  * @param {Object} field - contains selected field object 
  * @param {Object} value - contains the selected client value 
  */
    const onClientSelection = (field, value) => {
      if (value) {

        onEnableSaveButton(startDate,type,narrativeValue,limit)

        setClientSelected({
          ...clientSelected,
  
          values: [
            {
              id: value.clientId,
  
              label: value.client_name,
  
              desc: value.client_name,
  
              code: value.code,
  
              isDefault: true,
            },
          ],
        });
        setOnClientSelectedRefresh(onClientSelectedRefresh+1);
        setSubClientSelected({...subClientSelected,values: [{ id: "", desc: "", label: "" }] })
        setMatterSelected({ ...matterSelected, values: [{ id: "", desc: "", label: "" }] });
  
        setChanged(true);
      }
    };
  
    /**
    *  on selection of a subclient from the dropdown we update subclient field with the selected value 
    *  after setting the subclient it resets matter field 
    * @param {Object} field - contains selected field object 
    * @param {Object} value - contains the selected subclient value 
    */
    const onSubClientSelection = (field, value) => {


      setSubClientSelected({
        ...subClientSelected,
  
        values: [
          {
            id: value.subClientId,
  
            desc: value.subClientName,
  
            label: value.subClientNumber,
  
            code: value.subClientNumber,
          },
        ],
      });
      setMatterSelected({ ...matterSelected, values: [{ id: "", desc: "", label: "" }] });

      setChanged(true);
      //
    };
  
    /**
    *  on selection of a matter from the dropdown we update matter field with the selected value 
    * @param {Object} field - contains selected field object 
    * @param {Object} value - contains the selected subclient value 
    */
    const onMatterSelection = (field, value) => {

      setMatterSelected({
        ...matterSelected,
  
        values: [
          {
            id: value.matterId,
  
            desc: value.description,
  
            label: value.description,
  
            code: value.code,
          },
        ],
      });

      setChanged(true);
      //
    };


    const onTypeChange=(val)=>{
        onEnableSaveButton(startDate,val,narrativeValue,limit);
        setType(val);
        setChanged(true);    
    }

    const onStartDateChange=(val)=>{
      onEnableSaveButton(val,type,narrativeValue,limit);
      setStartDate(val);
      setChanged(true);
    }

    const onEndDateChange=(val)=>{
      onEnableSaveButton(startDate,type,narrativeValue,limit);
      setEndDate(val);
      setChanged(true);
    }

    const onNarrativeChange=(val)=>{
      if(val.length<=MAX_MESSAGE_LIMIT)
      {
        onEnableSaveButton(startDate,type,val,limit);
        if(mode!=="edit")
        {
          setNarrativeValue(val);
          let charsLength=MAX_MESSAGE_LIMIT-val.length;
          setNoOfChars(charsLength);
          setChanged(true);
        }
      
      }
    }

    const onLimitChange=(value)=>{
        onEnableSaveButton(startDate,type,narrativeValue,value);
        setLimit(value);
        setChanged(true);
    }

    const onSave=()=>{
      if(!Number(limit) ||(Number(limit)<1) || (Number(limit)>5000))
      {
        dispatch(setErrorMessage({ severity: 'error', message: jsUtils.getLiteralValue("LIMIT_VALIDATION_MESSAGE"), errorCode: 500, life: 5000 }));
      }
      else{
       const payLoad={
        isFirm:narrativeGridToggle==="firm"?"Y":"N",
        isLimit:"Y",
        limitValue: limit,
        restrictedWord:"*limit",
        suggestedReplacement:limit,
        clientId: clientSelected.values[0].id,
        subClientId: subClientSelected.values[0].id,
        matterId: matterSelected.values[0].id,
        client:clientSelected.values[0].code,
        subClient:subClientSelected.values[0].code,
        matter:matterSelected.values[0].code,
        type: type?.state?.slice(0,1)?.toUpperCase(),
        message: narrativeValue,
        startDate:startDate? moment(new Date(startDate).toISOString()).format("YYYY-MM-DD"):"",
        endDate: endDate? moment(new Date(endDate).toISOString()).format("YYYY-MM-DD"):""
        }
        if(mode==="edit")
        {
          payLoad["restrictedWordId"]=selectedRowId;

          let selectedData=DataFromAPI.find((item)=> item.rowId === selectedRowId );
          if(selectedData){
            let checkDateMatch=moment(new Date(selectedData.effective_start_date).toISOString()).format("YYYY-MM-DD") === moment(new Date(startDate).toISOString()).format("YYYY-MM-DD");
            if(checkDateMatch){
              delete payLoad.startDate;
            }
          }


          RestrictedWords.put(payLoad).then((res)=>{
            setChanged(false);
            dispatch(setErrorMessage({ severity: 'success', message: jsUtils.getLiteralValue(res?.message), errorCode: 200, life: 5000 }));
          }).catch((err)=>{
            dispatch(setErrorMessage({ severity: 'error', message: jsUtils.getLiteralValue(err?.message), errorCode: 500, life: 5000 }));
          })
        }
        else{
        RestrictedWords.add(payLoad).then((res)=>{
          setChanged(false);
          dispatch(setErrorMessage({ severity: 'success', message: jsUtils.getLiteralValue(res?.message), errorCode: 200, life: 5000 }));
        }).catch((err)=>{
          dispatch(setErrorMessage({ severity: 'error', message: jsUtils.getLiteralValue(err?.message), errorCode: 500, life: 5000 }));
        })
      }
    }
    }


  const getLeftBodySection=(toggleVal,setToggle,_onClientSelection,_clientSelected,_matterSelected,_onMatterSelection,_subClientSelected,_onSubClientSelection,_loadClients,_loadSubClients,_loadMatters,_mode,_showSubClient)=>{
    return <RestrictedWordsLeftBodyControl
            toggleVal={toggleVal}
            setToggle={setToggle}
            clientSelected={_clientSelected}
            onClientSelection={_onClientSelection}
            onSubClientSelection={_onSubClientSelection}
            onMatterSelection={_onMatterSelection}
            subClientSelected={_subClientSelected}
            matterSelected={_matterSelected}
            loadClients={_loadClients}
            loadSubClients={_loadSubClients}
            loadMatters={_loadMatters}
            mode={_mode}
            showSubClient={_showSubClient}
          />
  }

  const getRightBodySection=(_narrativeValue,_onNarrativeChange,_noOfChars,_onSave,_enableSaveButton,_mode)=>{
    return <RestrictedWordsRightBodyControl
            narrativeValue={_narrativeValue}
            onNarrativeChange={_onNarrativeChange}
            noOfChars={_noOfChars}
            onSave={_onSave}
            enableSaveButton={_enableSaveButton}
            mode={_mode}
          />
  }

  const getMiddleBodySection=(restrictedWord,onRestrictedWordChange,suggestedReplacement,onSuggestedReplacementChange,_type,_onTypeChange,_startDate,_endDate,_onStartDateChange,_onEndDateChange,_limit,_onLimitChange,_mode,_startDateDisable , _DBCurrentDate)=>{
    return <CharacterLengthMiddleBodyControl
            limitValue={_limit}
            onLimitValueChange={_onLimitChange}
            type={_type}
            onTypeChange={_onTypeChange}
            startDate={_startDate}
            onStartDateChange={_onStartDateChange}
            endDate={_endDate}
            onEndDateChange={_onEndDateChange}
            mode={_mode}
            startDateDisable={_startDateDisable}
            minDate={_DBCurrentDate}
          />
  }

  return (
    <div className="narrative-control-grid">
        <div className="toggle-header">
        <div className="toggle-div">
              <span className="text">{jsUtils.getLiteralValue("FIRM")} &nbsp;</span>
            <InputSwitch
            checked={narrativeGridToggle==="cmLevel"}
            onChange={(e) =>{ if(e.value){setNarrativeGridToggle("cmLevel")} else{setNarrativeGridToggle("firm")} resetFilters()}}
            />
            <span className="text">&nbsp;&nbsp; {jsUtils.getLiteralValue("CLIENT")}/{jsUtils.getLiteralValue("MATTER")}</span>
            </div>
        </div>
        {visible && <DialogControl
          visible={visible}
          setVisible={setVisible}
          getHeader={getHeader}
          toggleVal={narrativeGridToggle==="cmLevel"}
          setToggle={(e)=>{

            if(mode!=="edit")
            {
              setClientSelected({
                ...clientSelected,values: [{id: "",label: "",desc: "",code: "",isDefault: true}]
              });
              setSubClientSelected({
                ...subClientSelected,values: [{id: "",label: "",desc: "",code: "",isDefault: true}]
              });
              setMatterSelected({
                ...matterSelected,values: [{id: "",label: "",desc: "",code: ""}]
              });
            }

            if(e.value){
              mode !=="edit" && setNarrativeGridToggle("cmLevel")
            } 
            else{ 
              mode !=="edit" && setNarrativeGridToggle("firm")
            }
            
          }}

          getLeftBodySection={getLeftBodySection}
          getMiddleBodySection={getMiddleBodySection}
          getRightBodySection={getRightBodySection}
          clientSelected={clientSelected}
          onClientSelection={onClientSelection}
          onSubClientSelection={onSubClientSelection}
          onMatterSelection={onMatterSelection}
          subClientSelected={subClientSelected}
          matterSelected={matterSelected}
          loadClients={loadClients}
          loadSubClients={loadSubClients}
          loadMatters={loadMatters}
          type={type}
          onTypeChange={onTypeChange}
          startDate={startDate}
          onStartDateChange={onStartDateChange}
          endDate={endDate}
          onEndDateChange={onEndDateChange} 
          narrativeValue={narrativeValue}
          onNarrativeChange={onNarrativeChange}
          noOfChars={noOfChars}
          mode={mode}
          onSave={onSave}
          limit={limit}
          onLimitChange={onLimitChange}
          enableSaveButton={enableSaveButton}
          displayConfirmation={displayConfirmation}
          setDisplayConfirmation={setDisplayConfirmation}
          setChanged={setChanged}
          hideDialog={onHideDialog}
          startDateDisable={startDateDisable}
          DBCurrentDate={DBCurrentDate}
          showSubClient={hasMultipleSubClients}
      />}
      
    
      {!visible && <div className="datatable-filter-Users">
      <div className="card af-rw-datagrid">
          <CustomTable
            rows={NO_OF_ROWS_IN_GRID}
            colDef={colDef}
            parentMethods={{
              edit_click,
              setVisible,
              DBCurrentDate
            }}
            data={DataFromAPI}
            dataLoaded={dataLoaded}
            isLoading={loading}
            loadingError={loadingError}
            noItemsFoundMessage={noItemsFoundMessage}
            noItemsFoundMessageText={noItemsFoundMessageText}
            closeAllRefresh={closeAllCardsRefresh}
            loadingCard={false}
            onFilterChange={onFilterChange}
            onLazyScroll={() => {
              if (loading === false && !scrollNoMore) {
                loadData(false, sortNameField, sortByField);
              }
            }}
            rowExpansionElement={(data) => {
              return (
                <div>
                  {/* <UserCard userid={rowItem.user_id} onLoadingCard={onLoadingcard} /> */}
                  <CharacterLengthCard rowData={data} />
                </div>
              );
            }}
            onSort={(index, isAsc, propName) => {
              setSort({ index, isAsc, propName });
              sortData({ index, isAsc, propName });
              setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
              setIsResetButtonActive(false);
            }}
            sort={sort}
            pageNumber={pageNumber}
            scrollNoMore={scrollNoMore}
            moveScrollToTop={moveScrollToTop}
            resetScrollFlag={()=>setMoveScrollToTop(false)}
            resetButtonActive={isResetButtonActive}
          />
        </div>
      </div>}
    </div>
  );
}
