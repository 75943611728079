import { useEffect, useState } from "react";
import { ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME, ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME, PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME } from "../../config/messages";
import jsUtils from "../../utils/jsUtils";

export default function useSelectedFeatureMenus(DataFromAPI,permissionsOfUser,onBulkEdit,onBulkUpdate,onBulkRelease,onBulkDelete,onBulkTransfer,onCombineClick,onExportClick,getAccessInfo,getFeatureNames,timeKeeperList=[],delegateId){
    const [selectedMenus,setSelectedMenus]=useState([]);
    const [enableDropDownMenu,setEnableDropDownMenu]=useState(false);

    useEffect(()=>{
        let enableDropDown=DataFromAPI?.filter((item)=>item.isChecked===true)?.length > 1;
        if(enableDropDown){
        let generalTimeEntryPresent=DataFromAPI.findIndex((item)=>item.isChecked && item.isGeneral===true)!== -1 || false;
        let regularTimeEntryPresent=DataFromAPI.findIndex((item)=>item.isChecked && item.isGeneral===false)!== -1 || false;

        const permissionsUser = permissionsOfUser?.find( (item) => item.id==="managetimegrid" );
        const releasePermission = permissionsUser?.fields.find((item)=>item.id ==="releasetime")?.actionable;
        const editPermission = permissionsUser?.fields.find((item)=>item.id ==="edittime")?.actionable;
        const clonePermission = permissionsUser?.fields.find((item)=>item.id ==="clonetime")?.actionable;
        const deletePermission = permissionsUser?.fields.find((item)=>item.id ==="deletetime")?.actionable;

        const loggedInUserAddTimePermissionCombined = editPermission && clonePermission && deletePermission;
        let featureList=getFeatureNames(timeKeeperList,delegateId) || [];
        
        const generalTimeRolePermissionLoggedInUser = permissionsOfUser?.find((item) => item.id==="generaltimeentries")?.fields.find((item)=>item.id ==="generaltimentry")?.actionable || false;
        const generalTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME)!==-1;
        const addTimeFeaturePermission=featureList.findIndex((item)=>item===PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME) !==-1;
        const releaseTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME)!==-1;

        let {regularEntryActionable,generalEntryActionable} = getAccessInfo(generalTimeRolePermissionLoggedInUser,generalTimeFeaturePermission,addTimeFeaturePermission ,editPermission,timeKeeperList[0]?.userDelegateId === delegateId,generalTimeEntryPresent,regularTimeEntryPresent)
        let _selectedTimeEntries = DataFromAPI.filter(item => item.isChecked ) || [];
                const ManageTimeItems= [
                    {
                    key:"showCount",
                    label: `${jsUtils.getLiteralValue("SELECTED_TIME_ENTRIES")} ${_selectedTimeEntries.length}`,
                    showMe: true,
                    className:"show-count"
                },{
                    key:"Update",
                    label: jsUtils.getLiteralValue("UPDATE_EACH_SELECTED_TIME_ENTRY"),
                    icon: 'icon-icon_edit',
                    showMe:timeKeeperList[0]?.userDelegateId === delegateId? regularTimeEntryPresent && editPermission: regularTimeEntryPresent && addTimeFeaturePermission,
                    command: () => {
                        let selectedEntries=[];
                        let isTEValid=false;
                        DataFromAPI.forEach((item) => {
                            if(item.isChecked ){
                            if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && item.isChecked && !item.ethicalWallFlag && !item.isGeneral) {
                                
                                selectedEntries.push(item); 
                                
                            }
                            else{
                                isTEValid=true;
                            }
                            }
                            });
                        onBulkEdit(selectedEntries,isTEValid)
                    }
                },
                {
                    key:"Bulk Update",
                    label: jsUtils.getLiteralValue("BULK_CHANGE_SELECTED_TIME_ENTRIES"),
                    icon: 'icon-icon_bulkupdatetimeentry',
                    showMe:timeKeeperList[0]?.userDelegateId === delegateId? regularTimeEntryPresent && editPermission: regularTimeEntryPresent && addTimeFeaturePermission,
                    command: () => {
                        let selectedEntries=[];
                        let isTEValid=false;
                        DataFromAPI.forEach((item) => {
                            //general timeentry validation to be added
                            if(item.isChecked ){
                            if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && !item.ethicalWallFlag && !item.isGeneral) {
                                regularEntryActionable && selectedEntries.push(item); 
                            }
                            else{
                                isTEValid=true;
                            }
                            }
                            });
                        onBulkUpdate(selectedEntries,isTEValid);
                    }
                },
                {
                    key:"Delete",
                    label: jsUtils.getLiteralValue('DELETE_SELECTED_TIME_ENTRIES'),
                    icon: 'icon-icon_trash',
                    showMe:timeKeeperList[0]?.userDelegateId === delegateId? (generalTimeRolePermissionLoggedInUser && generalTimeEntryPresent) || (regularTimeEntryPresent && deletePermission): (generalTimeFeaturePermission && generalTimeEntryPresent) || (addTimeFeaturePermission && regularTimeEntryPresent),
                    className:"lineSeparator",
                    command: () => {
                        let selectedEntries={timeEntryIds:[],draftTimeEntries:[],generalTimeEntryIds:[]};
                        let isTEValid=false;
                        DataFromAPI.forEach((item) => {
                            if(item.isChecked ){
                            if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid" )) {    
                                if(!item.isGeneral)   
                                {
                                item.is_draft_time ==="Y"? regularEntryActionable && selectedEntries.draftTimeEntries.push(item.timeEntryId):regularEntryActionable && selectedEntries.timeEntryIds.push(item.timeEntryId);
                                } 
                                else
                                {
                                generalEntryActionable && selectedEntries.generalTimeEntryIds.push(item.timeEntryId);
                                }                        
                            }
                            else{
                                isTEValid=true
                            }
                            }
                            });
                        onBulkDelete(selectedEntries,isTEValid)
                    }
                },
                {
                    key:"Release",
                    label: jsUtils.getLiteralValue('RELEASE_SELECTED_TIME_ENTRIES'),
                    icon: 'icon-icon_release',
                    showMe:timeKeeperList[0]?.userDelegateId === delegateId ? releasePermission && regularTimeEntryPresent : releaseTimeFeaturePermission && regularTimeEntryPresent,
                    className:"lineSeparator",
                    command: () => {
                        let selectedEntries=[];
                        let isTEValid=false;
                        DataFromAPI.forEach((item) => {
                            //general timeentry validation to be added
                            if(item.isChecked ){
                            if ((item.status.toLowerCase() === "ready") && !item.isGeneral) {
                                    selectedEntries.push(item.timeEntryId);
                            }
                            else{
                                isTEValid=true;
                            }
                            }                          
                            });
                        onBulkRelease(selectedEntries,isTEValid)
                    }
                },
                
                {
                    key:"Combine",
                    label: jsUtils.getLiteralValue('COMBINE_SELECTED_TIME_ENTRIES'),
                    icon: 'icon-icon-combineTimeEntry',
                    showMe:timeKeeperList[0]?.userDelegateId === delegateId? (generalTimeRolePermissionLoggedInUser && generalTimeEntryPresent) || (regularTimeEntryPresent && loggedInUserAddTimePermissionCombined ): (generalTimeFeaturePermission && generalTimeEntryPresent) || (addTimeFeaturePermission && regularTimeEntryPresent),
                    className:"lineSeparator",
                    command: () => {
                        let selectedEntries=[];
                        let isTEValid=false;
                        DataFromAPI.forEach((item) => {
                            //general timeentry validation to be added
                            if(item.isChecked ){
                            if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && item.isChecked && !item.ethicalWallFlag ) {
                                if(!item.isGeneral){
                                regularEntryActionable && selectedEntries.push(item);
                                }
                                else{
                                    generalEntryActionable && selectedEntries.push(item);
                                }
                                }
                                else{
                                isTEValid=true;
                                }
                            }               
                            });
                        onCombineClick(selectedEntries,isTEValid,!regularEntryActionable && regularTimeEntryPresent)
                    }
                },
                {   
                    key:"Transfer",
                    label: jsUtils.getLiteralValue('TRANSFER_SELECTED_TIME_ENTRIES'),
                    icon: 'icon-icon-transfertimeentry',
                    showMe:timeKeeperList[0]?.userDelegateId === delegateId? (generalTimeRolePermissionLoggedInUser && generalTimeEntryPresent) || (regularTimeEntryPresent && loggedInUserAddTimePermissionCombined ): (generalTimeFeaturePermission && generalTimeEntryPresent) || (addTimeFeaturePermission && regularTimeEntryPresent),
                    command: () => {
                        let selectedEntries=[];
                        let isTEValid=false;
                        DataFromAPI.forEach((item) => {
                            //general timeentry validation to be added
                            if(item.isChecked ){
                            if ((item.status.toLowerCase() === "ready" || item.status.toLowerCase()==="invalid") && !item.ethicalWallFlag ) {
                                
                                if(!item.isGeneral){
                                regularEntryActionable && selectedEntries.push(item);
                                }
                                else{
                                    selectedEntries.push(item);
                                }
                            }
                                else
                                {
                                isTEValid=true;
                                }
                                
                            }                     
                            });

                        onBulkTransfer(selectedEntries,isTEValid,!regularEntryActionable && regularTimeEntryPresent,!generalEntryActionable && generalTimeEntryPresent);
                    }
                },
                {
                    key:"ExportExcel",
                    label: jsUtils.getLiteralValue('EXPORT_SELECTED_TIME_ENTRIES_TO_EXCEL'),
                    icon: 'icon-export_excel iconmargin',
                    showMe:true,
                    command: () => {
                        let selectedEntries=[];

                        DataFromAPI.forEach((item) => {
                            if (item.isChecked) {

                                selectedEntries.push(item.timeEntryId);
                            
                            }                         
                            });
                        onExportClick(selectedEntries,"excel")
                    }
                },
                {
                    key:"ExportCSV",
                    label: jsUtils.getLiteralValue('EXPORT_SELECTED_TIME_ENTRIES_TO_CSV'),
                    icon: 'icon-export_csv iconmargin',
                    showMe:true,
                    command: () => {
                        let selectedEntries=[];
                        DataFromAPI.forEach((item) => {
                            if (item.isChecked) {

                                selectedEntries.push(item.timeEntryId);
                                
                            }                         
                            });
                        onExportClick(selectedEntries,"csv")
                    }
                },
                {
                    key:"ExportPdf",
                    label: jsUtils.getLiteralValue('EXPORT_SELECTED_TIME_ENTRIES_TO_PDF'),
                    icon: 'icon-export_pdf iconmargin',
                    showMe:true,
                    command: () => {
                        let selectedEntries=[];

                        DataFromAPI.forEach((item) => {
                            if (item.isChecked) {

                                selectedEntries.push(item.timeEntryId);
                            
                            }                         
                            });
                        onExportClick(selectedEntries,"pdf")
                    }
                }]

            setSelectedMenus(ManageTimeItems.filter((item)=>item.showMe===true));
        }
        setEnableDropDownMenu(enableDropDown);

    },[DataFromAPI]);

    return [selectedMenus,enableDropDownMenu]

}