import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../components/common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import "./ManageTime.scss";
import React, { useState, useEffect, useRef } from "react";
import { buildQueryString, MTColDef } from "./MTColDef";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import ManageTimeCard from "./ManageTimeCard";
import { manageTime } from "../../services/ManageTimeApi";
import jsUtils from "../../utils/jsUtils";
import permissionsState from "../../store/ClientMatterRolesPermissions.ts";
import { useSubscribe } from "../../store/ActionStore";
import storage from "../../utils/storage";
import { useDispatch } from "react-redux";
import { setWoboAction } from "./.././../actions/AppAction";
import { refreshStoreOnError } from "../../actions/AppAction";
import { ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME,  NO_OF_ROWS_IN_GRID, PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME } from "../../config/messages";
import CustomTable from "../common/DataTable/CustomTable";
import AdvancedSearch from "./AdvancedSearch";
import { HeaderView } from "../../config/literalCodes";
import { manageTimeTableSortMap } from "../../config/vars";
import { resetTransferTimeEntriesStatus } from "./../../actions/DayViewAction";
import DraggableColumnSelector from "../common/DraggableColumnSelector/DraggableColumnSelector";
import DropDownMenuV2 from "../common/DropDownMenuV2/DropDownMenuV2";
import useSelectedFeatureMenus from "./useSelectedFeatureMenus";

export default function ManageTimeTable({editClick,cloneClick,setDeleteData,releaseClick,setDisplayConfirmation,setValidationMessage,setConfirmAction,onBulkEdit,onBulkUpdate,onBulkRelease,onBulkDelete,onBulkTransfer,onCombineClick,onTransferClick,onLoadingcard,onExportClick,refreshCounter=0,columnDef=[],setColumnDef=()=>{},DataFromAPI=[],setDataFromAPI=()=>{},bulkSelectToggle,gridReset,checkAll,operation,setOperation=()=>{},totalTECount,loading,setLoading=()=>{},setTotalTECount,getFeatureNames=()=>{},getAccessInfo,columnsData,columnsDataBackup,onApplyColumnChanges,setCheckBoxRefreshCounter=()=>{},setCheckAll=()=>{}}) {

  const [searchCriteria, setSearchCriteria] = useState(null);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isAnyFilterApllied, setIsAnyFilterApllied] = useState(false);

  const { delegateId, timeKeeperList } = useSelector(({TimeManagementReducer}) => ({ 
    delegateId: TimeManagementReducer.delegateId,
    timeKeeperList: TimeManagementReducer.timeKeeperList,
   }));
  const dateSelectedDayView = useSelector(state => state.DayViewReducer.date);
  
  const { permissionsOfUser, wobo } = useSelector(({AppReducer}) => ({
     permissionsOfUser: AppReducer.fieldnames ,
     wobo: AppReducer.wobo
    }));
  useSubscribe([permissionsState]);
  const [firstCallView,setFirstCallView]=useState(true);
  
  const [entriesInfo, setEntriesInfo] = useState("");
  const resetSelectToggle = () => {
    let state = columnDef;
    state[0].column.val = false;
    setColumnDef([...state]);
    setCheckAll(false);
  }

  const [totalSearchedTECount, setTotalSearchedTECount] = useState(0);
  // const [operation, setOperation] = useState(null);
  const [apiCalled, setApiCalled] = useState(null);

  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [fromDate] = useState(null);
  const [toDate] = useState(null);
  const [closeAllCardsRefresh, setCloseAllCardsRefresh] = useState(0)
  const [loadingCard, setLoadingCard] = useState(false)
  const dispatch = useDispatch();
 
  const[moveScrollToTop , setMoveScrollToTop] = useState(false);
  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");

  const [scrollNoMore, setScrollNoMore] = useState(false);
  const [activeTimerId] = useState(0);
  const [pageOffset, setPageOffset] = useState(0)

  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });

  const [selectedMenus,enableDropDownMenu]=useSelectedFeatureMenus(DataFromAPI,permissionsOfUser,onBulkEdit,onBulkUpdate,onBulkRelease,onBulkDelete,onBulkTransfer,onCombineClick,onExportClick,getAccessInfo,getFeatureNames,timeKeeperList,delegateId)
  let setScrollToTop = () => {
    setMoveScrollToTop(true);
  }

   /**
    *  the state is updated with the new filters
    * @param {String} value - searched text or toggle value
    * @param {Number} index -index of the filter column 
    */
  let setFilters = (value, index) => {
    let state = columnDef;
    columnDef[index].filter.val = value;
    setColumnDef([...state]);
  };

  /*
  * On component load or change in delegate in work on behalf we call the client matters api to load the grid
  */

  useEffect(() => {
    MTColDef[0].column.val = false;
    setCheckBoxRefreshCounter(0);
    setCheckAll(false);

    if (delegateId !== "" && delegateId !== null) {
      setSort({ index: -1, isAsc: true, propName: "" });
      setScrollToTop();
      loadData(true, searchCriteria);
      setScrollToTop();
    }

  }, [delegateId, refreshCounter ,gridReset]);

  useEffect(()=>{
    let colDef=[...columnDef];
    if(colDef.length){
      colDef[colDef.length-1].column.renderElement = ()=>{
  
        return <div style={{display: "flex", float:"right"}}>
           <DraggableColumnSelector
          columnSelectionData={columnsData}
          columnSelectionDataBackup={columnsDataBackup}
          onApply={onApplyColumnChanges}
          />
          <DropDownMenuV2
              enableDropDown={enableDropDownMenu}
              menuItems={selectedMenus|| [{
                key:"default",
                label: "default",
                icon: "default",
                command: () => {}
            }]}
        />  
        </div>}
     setColumnDef(colDef)
    }

},[selectedMenus,columnsData,enableDropDownMenu])


  // commented to improve the performance
  useEffect(()=> {
      //clean up
      return(() => {
        setOperation(null);
        setApiCalled(null);
        setEntriesInfo("");
        dispatch(setWoboAction({ action: ""}));
        dispatch(resetTransferTimeEntriesStatus());
      });
  }, []);

const toast = useRef(null);
 useEffect(() => { 
  if (operation ) {
    if (operation === "delete" || operation === "transfer" || operation === "combine") {
      if (apiCalled === "pageLoad") {
        setEntriesInfo(`${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      } else if (apiCalled === "search") {
        setEntriesInfo(`${totalSearchedTECount} ${jsUtils.getLiteralValue("OF")} ${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      }
    } else if (operation === "search") {
      if(isAnyFilterApllied) {
        setEntriesInfo(`${totalSearchedTECount} ${jsUtils.getLiteralValue("OF")} ${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      } else {
        setEntriesInfo(`${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      }      
    } else if (operation === "reset") {
      setEntriesInfo(`${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
    } else if (wobo && ( wobo.action === "woboChanged" || wobo.action === "woboRemoved")) {
      if (apiCalled === "pageLoad") {
        setEntriesInfo(`${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      } else if (apiCalled === "search") {
        setEntriesInfo(`${totalSearchedTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      }
      dispatch(setWoboAction({ action: ""}));
    } else {
      if (apiCalled === "pageLoad") {
        setEntriesInfo(`${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      } else if (apiCalled === "search") {
        setEntriesInfo(`${totalSearchedTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
      }
    }
  } else {
    if (apiCalled === "pageLoad") {
      setEntriesInfo(`${totalTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
    } else if (apiCalled === "search") {
      setEntriesInfo(`${totalSearchedTECount} ${jsUtils.getLiteralValue("ENTRIES")}`);
    }
  } 
  
 }, [operation, totalSearchedTECount, totalTECount, wobo, apiCalled, isAnyFilterApllied]);

 const getCodeValue=(codeKey,_rowData)=>{
 let value= _rowData.timeEntryDetail?.find((item)=>item.fieldName===codeKey)?.values?.display_value || "";
 return value;
 }

  const manageTimeApiCall=(reset,searchParams,sortName,sortby)=>{
    manageTime
        .get(delegateId, reset ? 1 : pageNumber, searchParams, fromDate, toDate, reset ? 0 : pageOffset, "managetime", dateSelectedDayView, sortName, sortby,firstCallView?HeaderView.ManageTimeViewApiCallHeaderName:null)
        .then((res) => {
          setLoadingError(false);
          if(res.totalCount >= 0) {
            setTotalTECount(res.totalCount);
            setApiCalled("pageLoad");
                
          } else if(res.searchTotalCount >= 0) {
            setTotalSearchedTECount(res.searchTotalCount); 
            if(totalTECount === 0){
              setTotalTECount(res.searchTotalCount);
            }
            setApiCalled("search");         
          }           
          res.nextPageOffset ? setPageOffset(res.nextPageOffset) : setPageOffset(0);

          setDataLoaded(true);

          res &&
            res.timeentries &&
            res.timeentries.forEach((item) => {
              item["rowId"] = item.timeEntryId;
              item["city"] = getCodeValue("CITY_CODE",item);
              item["rate"] = getCodeValue("RATE_CODE",item);
              item["phase"] = getCodeValue("PHASE_CODE",item);
              item["service"] = getCodeValue("SERVICE_CODE",item);
              item["task"] = getCodeValue("TASK_CODE",item);
              item["makenocharge"] = getCodeValue("MAKE_NO_CHARGE",item);
              item["activity"] = getCodeValue("ACTIVITY_CODE",item);
              item["udf2"] = getCodeValue("UDF2",item);
              item["udf3"] = getCodeValue("UDF3",item);
              item["generaltime"] = item.isGeneral?"Yes":"No";
              reset && setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
              
                if(checkAll && !reset ){
                  (item["isChecked"] = true)
                }
                else{
                  (item["isChecked"] = false);
                }

            });
            
          /**
           * if reset is true reset the data in the state with the response data as the api call is for 1st page of list
           * else if reset is false that means the res is from lazy loading and is from 2nd page or higher.So append the 
           * data to existing state
          */
          if (reset) {
              setDataFromAPI(res.timeentries);
            // sortData(JSON.parse(JSON.stringify(res.timeentries)));
            if (res.timeentries.length === 0) {
              setNoItemsFoundMessage(true);
              setNoItemsFoundMessageText({
                titleText: jsUtils.getLiteralValue("NO_TIME_ENTRY_RECORDS_FOUND") !== "NO_TIME_ENTRY_RECORDS_FOUND" ? jsUtils.getLiteralValue("NO_TIME_ENTRY_RECORDS_FOUND") : storage.getObject("literals")["NO_TIME_ENTRY_RECORDS_FOUND"],
                buttonText: null,
              });
            }
            // setScrollNoMore(false);
            else {
              if (res.nextPageOffset) {
                if (res.timeentries.length < 20) {
                  setScrollNoMore(true);
                }
                else {
                  setScrollNoMore(false);
                }
              }
              else {
                page = 1
                if (res.timeentries.length < (page * NO_OF_ROWS_IN_GRID)) {
                  setScrollNoMore(true);
                  //setPageNumber(pageNumber)
                } else {
                  setPageNumber(page + 1);
                  setScrollNoMore(false);
                  // setReset(false);
                }
              }
            }
          } else {
            setDataFromAPI([...DataFromAPI, ...res.timeentries]);
            // setCheckBoxRefreshCounter(checkboxRefreshCounter+1);
            if (res.nextPageOffset) {
              if (res.timeentries.length < 20) {
                setScrollNoMore(true);
              }
              else {
                setScrollNoMore(false);
              }
            }
            else {
              page = pageNumber
              if ((res.timeentries.length + DataFromAPI.length) < (pageNumber * NO_OF_ROWS_IN_GRID)) {
                setScrollNoMore(true)
              } else {
                setScrollNoMore(false);
                setPageNumber(page + 1);
              }

            }
          }
        })
        .catch((error) => {
          dispatch(refreshStoreOnError({ refresh: new Date() }));
          setLoadingError(error || "Error");
            if(operation === "search" && reset) {
              setTotalSearchedTECount(0);
            }                   
        })
        .finally(() => {
          setLoading(false);
          setDataLoaded(true);
        });
        setFirstCallView(false);
  }

  /**
   * manage time api that loads the grid with created time entries  
   * @param {boolean} reset - true for the first page load and false for 2nd and more onwards
   */
  const loadData = (reset = true, params, x, onreset, sortName = "", sortby = "") => {

    setLoading(true);
    setDataLoaded(true);
    if (reset) {
      setPageNumber(1);
    } else {
      // page = pageNumber + 1;
      // setPageNumber(pageNumber + 1);
    }

    /**
     * build query string
      */

    let searchParams;
    if (params) {
      searchParams = params;
    } else {
      if (onreset) {
        searchParams = null;
      }
      else if (isSearchClicked) {
        searchParams = searchCriteria;
      }
      else {
        searchParams = buildQueryString(delegateId, sortName, sortby);
      }
    }
    if (searchParams && searchParams.sortName !== "" || searchParams && searchParams.sortby !== "") {
      searchParams.sort = sortName;
      searchParams.sort_type = sortby;
    }


    if(timeKeeperList[0]?.userDelegateId === delegateId){
      const generalTimeRolePermissionLoggedInUser=permissionsOfUser?.find((item) => item.id==="generaltimeentries")?.fields.find((item)=>item.id ==="generaltimentry")?.actionable || false;
      const addTimeFeaturePermissionLoggedInUser= permissionsOfUser?.find((item) => item.id==="managetimegrid")?.fields.find((item)=>item.id ==="edittime")?.display || false;
      const _timekeeper=timeKeeperList[0].timekeeper;
      if( (_timekeeper && addTimeFeaturePermissionLoggedInUser ) || generalTimeRolePermissionLoggedInUser )
      {
        manageTimeApiCall(reset,searchParams,sortName,sortby)
      }
      else
      { 
        setDataLoaded(true);
        setLoading(false);
        setDataFromAPI([]);
        setNoItemsFoundMessage(true);
        setNoItemsFoundMessageText({
          titleText: jsUtils.getLiteralValue("NOT_TIMEKEEPER")
        }); 
      }

    }
    else{
      const featureList=getFeatureNames(timeKeeperList,delegateId) || [];
      const generalTimeFeaturePermission=featureList.findIndex((item)=>item===ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME)!==-1;
      const addManageFeatureTimePermission=featureList.findIndex((item)=>item===PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME)!==-1;
      if(generalTimeFeaturePermission || addManageFeatureTimePermission)
      {
        manageTimeApiCall(reset,searchParams,sortName,sortby)
      }
      else{
        setDataLoaded(true);
        setLoading(false);
        setDataFromAPI([]);
        setNoItemsFoundMessage(true);
        setNoItemsFoundMessageText({
          titleText: jsUtils.getLiteralValue("NOT_TIMEKEEPER")
        }); 
      }

    }
  };

  /** 
   *  sorts the columns of the grid individually
   * @param {Array} data - array of objects for grid of time entry
   * @param {Object} localSort - contains the sort object
   */
  const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc ? "asc" : "desc";
      let sortName=manageTimeTableSortMap[localSort.propName] || "";
      setSortByField(sortBy);
      setSortNameField(sortName);
      if(sortName !== ""){
        resetSelectToggle()
        loadData(true, searchCriteria, undefined, false, sortName, sortBy)
      }
      
    }
  };

  /**
   * manage time api is called with the selected filters as query params
   * @param {String} key - column name
    * @param {String} value - searched text or toggle value
    * @param {Number} index -index of the filter column 
    */


  const onAdvSearchFilterChange = (obj, index, onreset = false, searchParamsMap = {}) => {
    setIsAnyFilterApllied(Object.keys(searchParamsMap).length > 0);
    setSearchCriteria(obj);
    setIsSearchClicked(true);
    resetSelectToggle();
    setCheckBoxRefreshCounter(0);
    setCheckAll(false)
    setPageNumber(1)
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setScrollNoMore(false);
    setFilters(obj, index);
    jsUtils.debounce(loadData, 400, true, obj, 0, onreset, sortNameField, sortByField);
    setScrollToTop();
  }

    return (
    <div>
      <Toast ref={toast} />
      {  <AdvancedSearch
        delegateUserId={delegateId}
        onAdvSearchFilterChange={onAdvSearchFilterChange}
        entriesInfo={entriesInfo}
        onClickTracker={(action) => setOperation(action)}
      />}
      
      <div className="datatable-filter-MT">
        <div className="card">
          {delegateId && (
            <CustomTable
              moduleName={"managetime"}
              rows={NO_OF_ROWS_IN_GRID}
              colDef={columnDef}
              parentMethods={{
                editClick,
                cloneClick,
                setDeleteData,
                releaseClick,
                bulkSelectToggle,
                setDisplayConfirmation,
                setValidationMessage,
                setConfirmAction,
                onBulkEdit,
                onBulkUpdate,
                onBulkRelease,
                onBulkDelete,
                onBulkTransfer,
                onCombineClick,
                onTransferClick,
                onExportClick
              }}
              data={DataFromAPI}
              activeTimerId={activeTimerId}
              dataLoaded={dataLoaded}
              isLoading={loading}
              closeAllRefresh={closeAllCardsRefresh}
              loadingCard={loadingCard}
              rowExpansionElement={(data) => {
                return (
                  <div>
                    <ManageTimeCard Carditem={data} onLoadingCard={onLoadingcard} />
                  </div>
                );
              }}
              loadingError={loadingError}
              noItemsFoundMessage={noItemsFoundMessage}
              noItemsFoundMessageText={noItemsFoundMessageText}
              onFilterChange={()=>{}}
              onLazyScroll={() => {
                if (loading === false && !scrollNoMore) {
                  loadData(false, searchCriteria, undefined, false, sortNameField, sortByField);
                }
              }}
              onSort={(index, isAsc, propName) => {
                setSort({ index, isAsc, propName });
                sortData({ index, isAsc, propName });
              }}
              sort={sort}
              pageNumber={pageNumber}
              scrollNoMore={scrollNoMore}
              moveScrollToTop={moveScrollToTop}
              resetScrollFlag={() => setMoveScrollToTop(false)}
            />
          )}
        </div>
      </div>
      
    </div>
  );
}
