import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import Spinner from "../Spinner/Spinner";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import "./DataTable.scss";
import moment from "moment";
// import jsUtils from "../../../utils/jsUtils";
import storage from "../../../utils/storage";
import { useSelector } from "react-redux";
import Row from "./Row";

const getCode = (key) => {
  return storage.getObject("literals") && storage.getObject("literals")[key] ? storage.getObject("literals")[key] : key
};


function CustomTable({
    moduleName,
    isLoading,
    noItemsFoundMessage,
    colDef,
    data,
    dataLoaded,
    noItemsFoundMessageText,
    parentMethods,
    onFilterChange,
    onLazyScroll,
    sort,
    onSort,
    enableRowExpansion = true,
    addNewButton,
    closeAllRefresh,
    scrollNoMore,
    loadingCard = false,
    rowExpansionElement,
    rowClicked,
    rows,
    moveScrollToTop,
    resetScrollFlag,
    resetButtonActive = false
}) {

    //const pageSize = rows; 
    const [searchParams, setSearchParams] = useState([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
    ]);
    const [rowExpansionId, setRowExpansionId] = useState({});
    const internalRowClicked = (rowItem) => {
        if (!loadingCard && !isLoading && enableRowExpansion) {
            if (!rowExpansionId[rowItem.rowId]) {
                setRowExpansionId({ ...rowExpansionId, [rowItem.rowId]: true });
            } else {
                setRowExpansionId({ ...rowExpansionId, [rowItem.rowId]: false });
            }
        }
    };
    const delegateId = useSelector(
        (state) => state.TimeManagementReducer.delegateId
      );
    useEffect(() => {
        if (closeAllRefresh) {
          setRowExpansionId({})
        }
      }, [closeAllRefresh])
    useEffect(()=>{
            bodyScroll.current.scrollTop=0 ;
            resetScrollFlag();
        
    },[moveScrollToTop , delegateId,sort])
    useEffect(() => {
        setSearchParams(colDef.map((d) => d.filter?.val));
    }, [colDef]);
    const [filterRefresh] = useState(false);
    const [ ,setExpandedRows] = useState({});
    const resetFilters = () => {
        setSearchParams(["", "", "", "", "", "", ""]);
        onSort(-1, true, "");
    };
    //console.log("data table", data);
    let columnClicked = (columnIndex, propName) => {
        // console.log(columnIndex, propName, "sort before", sort);
        if (sort.index === -1) onSort(columnIndex, true, propName);
        else if (sort.index === columnIndex) {
            onSort(columnIndex, !sort.isAsc, propName);
            // if (sort.isAsc) onSort(columnIndex, false, propName);
            // else onSort(-1, true, propName);
        } else if (sort.index > -1 && sort.index !== columnIndex) {
            onSort(columnIndex, true, propName);
        }
    };

    useEffect(() => {
        if (closeAllRefresh) {
            setExpandedRows({})
        }
    }, [closeAllRefresh])

    const getKeyValue =(inputArr)=>{
       inputArr.forEach((option)=>{
            if(option.label){
                option.label = getCode(option.label)
            }
        })
        return inputArr;
    }
    const loadFilterElement = (filter, index, dataFilterElement) => {
        switch (filter.type) {
            case "textbox":
                return (
                    <div className={"p-input-icon-right"} style={{ width: '100%' }}><InputText
                        title={filter.title}
                        className={filter.className}
                        placeholder={getCode(filter.label)}
                        value={searchParams[index]}
                        onChange={(e) => {
                            let state = searchParams;
                            state[index] = e.currentTarget.value;
                            setSearchParams([...state]);
                            onFilterChange(dataFilterElement.propName, e.currentTarget.value, index);
                        }}
                    /><i className="pi pi-search"></i></div>
                );
            case "reset":
                return (
                    <span className="p-column-title">
                        <Button
                            icon="pi pi-times"
                            style={filter.style}
                            className={filter.className}
                            label={getCode(filter.label)}
                            onClick={() => {
                                filter.type === "reset" && resetFilters();
                                filter.onClick && filter.onClick();
                            }}
                            disabled={resetButtonActive}
                        />
                    </span>
                );

            // case "button":
            //     return (
            //         <span className="p-column-title">
            //             <Button
            //                 icon="pi pi-times"
            //                 style={filter.style}
            //                 className={filter.className}
            //                 label={filter.label}
            //                 onClick={() => {
            //                     filter.type === "reset" && resetFilters();
            //                     filter.onClick && filter.onClick();
            //                 }}
            //             />
            //         </span>
            //     );
            case "date":
                return (
                    <div className="calendar-filter">
                        <Calendar
                            appendTo={document.body}
                            tooltip={filter.title}
                            tooltipOptions={{
                                className: "white-tooltip",
                                position: "top",
                                showDelay: 700,
                            }}
                            placeholder="mm/dd/yyyy"
                            showIcon
                            id="filterCalendar"
                            readOnlyInput
                            yearNavigator
                            yearRange="1600:6030"
                            value={filter.val}
                            showButtonBar
                            className="p-column-filter"
                            onChange={(e) =>
                                onFilterChange(
                                    data.propName,
                                    e.value
                                        ? moment(new Date(e.value.toString()).toISOString()).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                        : "",
                                    index
                                )
                            }
                        />
                    </div>
                );
            case "dropdown":
                return (
                    <span>
                        <Dropdown
                            appendTo={document.body}
                            value={filter.val}
                            options={getKeyValue(filter.options)}
                            onChange={(e) => {
                                onFilterChange(dataFilterElement.propName, e.value, index);
                            }}
                            optionLabel={filter.label}
                            placeholder={getCode(filter.placeholder)}
                        />
                    </span>
                );
            case "switch":
                return (
                    <InputSwitch
                        tooltip={filter.tooltip}
                        tooltipOptions={{
                            className: "white-tooltip",
                            position: "top",
                            showDelay: 700,
                        }}
                        onChange={(e) => {
                            onFilterChange(dataFilterElement.propName, e.value, index);
                        }}
                        disabled={filter.disabled}
                        checked={filterRefresh ? false : filter.val}
                    />
                );
            default:
                return "";
        }
    };

    const loadHeaderElement = (column, index, dataHeaderElement) => {
        switch (column.type) {
            case "string":
                return (
                    <div
                        onClick={() => {
                            columnClicked(index, dataHeaderElement.propName);
                        }}
                        data-testid="customTableHeaderTitle"
                    >
                        <span className="p-column-title">{getCode(column.label)}</span>
                        {column.icon === "present" &&
                            sort.index !== -1 &&
                            index === sort.index ? (
                            <span
                                title="Switch sort order"
                                className={
                                    sort.isAsc
                                        ? "p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"
                                        : "p-sortable-column-icon pi pi-fw pi-sort-amount-down"
                                }
                            ></span>
                        ) : column.icon === "present" ? (
                            <span
                                title="Switch sort order"
                                className={"p-sortable-column-icon pi pi-fw pi-sort-alt"}
                            ></span>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            case "button":
                return (
                    <span className="af-newbutton">
                        <span className="p-column-title">
                            {column.iconName ?
                                <Button
                                    onClick={() => column.onClick("", "", "")}
                                    label={getCode(column.label)}
                                    icon={column.iconName}
                                    className={column.className}
                                    data-testid={`dataId_${getCode(column.label)}`}
                                /> :
                                <div className={"p-input-icon-left"} style={{ width: '100%' }}>
                                    <img src={"/images/svg/icon_w_release.svg"} style={column.className==="p-button-success p-mr-2 time-button time-button-img timer-disable"?{opacity:"0.4"}:{opacity:"1"}} className={"custom-style-icon-css"}></img>
                                    <Button
                                        onClick={() => column.onClick("", "", "")}
                                        label={getCode(column.label)}
                                        className={column.className}
                                    />
                                </div>
                            }
                        </span>
                    </span>
                );
            case "checkbox":
                return (
                    <div className="MT-checkbox-div">
                        <span>
                            <input
                                className="MT-checkbox"
                                type="checkbox"
                                checked={column.val}
                                onChange={() => {
                                    column.onClick(column,parentMethods);
                                }}
                                autocomplete="off"
                                data-testid="headerCheckbox"
                            />
                        </span>
                    </div>
                );
            default:
                return (
                    <span>
                        {column.renderElement &&
                            column.renderElement(column, parentMethods,data,moduleName)}
                    </span>
                );
        }
    };

    // const expandRowOnClick = (rowData) => {
    //     if (expandedRows[[rowData["rowId"]]]) {
    //         delete expandedRows[[rowData["rowId"]]];
    //         setExpandedRows({ ...expandedRows });
    //     } else {
    //         //expandedRows[[rowData["rowId"]]] = true;
    //         //expandedRows={...expandedRows , [rowData["rowId"]] : true }
    //         setExpandedRows({ ...expandedRows, [rowData["rowId"]]: true });
    //     }
    // };

    const loadBodyElement = (rowData, dataBodyElement) => {
        if (dataBodyElement.type === "string") {
            if ((!dataBodyElement.edit || !rowData.editMode) && !rowData.editAll) {
                return rowData[dataBodyElement.propName];
            } 
            else if (dataBodyElement.toggleComp === "custom") {
                return dataBodyElement.renderToggleComp(rowData, parentMethods);
            }
        } else if (dataBodyElement.type === "button") {
            return (
                <Button
                    title={dataBodyElement.tooltip}
                    onClick={(e) => {
                        e.stopPropagation();
                        dataBodyElement.click(rowData);
                    }}
                    className={dataBodyElement.className}
                    label={dataBodyElement.label}
                    icon={dataBodyElement.iconName}
                />
            );
        } else if (dataBodyElement.type === "custom") {
            return dataBodyElement.renderElement(rowData, parentMethods);
        } else {
            return rowData[dataBodyElement.propName];
        }
    };

    // const [
    //     updateComponentonInfinciteScroll,
    //     setupdateComponentonInfinciteScroll,
    // ] = useState(true);
    // const onlazyScrollLoad = (event) => {
    //     setfirst(event.first);
    //     if (data.length > event.first + event.rows / 2) {
    //         setupdateComponentonInfinciteScroll(false);
    //         setupdateComponentonInfinciteScroll(true);
    //         return null;
    //     }
    //     if (scrollNoMore) {
    //         setupdateComponentonInfinciteScroll(false);
    //         setupdateComponentonInfinciteScroll(true);
    //     }
    //     return onLazyScroll();
    // };

    //const [virtualData, setvirtualData] = useState(data);
    //const [first, setfirst] = useState(0);
    // useEffect(() => {
    //     let startIndex = 0;
    //     let endIdex = data.length;
    //     startIndex = first > 0 ? first + 1 : 0;
    //     let slicedData = updateTimerData ? timerControlUpdate(data.slice(startIndex, endIdex)) : data.slice(startIndex, endIdex);
    //     console.log("entrySlice", data)

    //     setvirtualData(slicedData);
    // }, [data, first]);

    // const timerControlUpdate = (slicedData) => {
    //     //if you find a timer data with started do operation else return slicedData
    //     let runningTimer = slicedData.find((item) => item.timerStatus === "started" || item.timerStatus === "resumed")
    //     let runningTimerIndex = slicedData.findIndex((item) => item.timerStatus === "started" || item.timerStatus === "resumed")

    //     if (runningTimer) {
    //         let timerRecord = jsUtils.getDataFromSessionStorage();
    //         if (timerRecord) {
    //             const now = new Date();
    //             let additionalTimeInSeconds = Math.round(now.getTime() / 1000) - timerRecord.fetchedTimeInSecs;

    //             let updatedTime = jsUtils.updatedTime(timerRecord.timeDuration, additionalTimeInSeconds);
    //             slicedData[runningTimerIndex].timeDuration = updatedTime;
    //             console.log("data", slicedData)
    //             return slicedData;
    //         }
    //         else {
    //             console.log("data no timer", slicedData);
    //             return slicedData;
    //         }
    //     }
    //     else {
    //         console.log("data no timer", slicedData);
    //         return slicedData;
    //     }

    // }
    // const rowExpansionTemplate = (rowExpansionData) => {
    //     return rowExpansionElement && rowExpansionElement(rowExpansionData);
    // };

    // const loadingSpinner = () => {
    //   return (
    //     <div
    //       style={{
    //         position: "absolute",
    //         width: "100%",
    //         textAlign: "center",
    //         top: "150px",
    //         zIndex: 10,
    //       }}
    //     >
    //       <Spinner />
    //     </div>
    //   );
    // };
    const emptyMessage = () => {
        return (
            <div
                style={{
                    position: "absolute",
                    textAlign: "center",
                    top: "50%",
                    // width: "1000px",
                    left: "40%",
                    zIndex: 10,
                }}
            >
                {noItemsFoundMessage && data.length === 0 && (
                    <div className="af-centerAlignNoItemsFound">
                        <div
                            className="af-clientDataNotFound"
                            style={{ width: "100%", textAlign: "left" }}
                        >
                            <div className="icon-col">
                                <img src="/images/svg/icon_sad.svg" alt=""></img>
                            </div>
                            <div className="text-col">
                                <>
                                    <div className="af-empty-error">
                                        {noItemsFoundMessageText
                                            ? noItemsFoundMessageText.titleText
                                            : "Not Found!"}
                                    </div>  

                                    {noItemsFoundMessageText.buttonText && (
                                        <div
                                            className={addNewButton ? "timer-disable" : ""}
                                            onClick={colDef[colDef.length - 1].column.onClick}
                                        >
                                            {/* <span className={draftpermission ? 'timer-disable af-newbutton' : 'af-newbutton'}> */}
                                            <span className="af-newbutton">
                                                <span className="p-column-title">
                                                    <Button
                                                        // disabled={draftpermission}
                                                        onClick={() => {
                                                            console.log("Draft Time Entry in datatable");
                                                        }}
                                                        label={
                                                            noItemsFoundMessageText
                                                                ? noItemsFoundMessageText.buttonText
                                                                : "Add"
                                                        }
                                                        icon="pi pi-plus"
                                                        className="p-button-success p-mr-2 time-button af-nodata-class"
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                )}{" "}
            </div>
        );
    };

    // const loadingText = () => {
    //     return <span className="loading-text"></span>;
    // };
    const headerDiv = React.useRef(null);
    const bodyScroll = React.useRef(null);
    return (
        <>
        {isLoading || !dataLoaded ? (
                    <div
                        style={{
                            position: "sticky",
                            width: "100%",
                            //textAlign: "center",
                            top: "50%",
                            height:"0px",
                           // left:"50%",
                            zIndex: 10,
                        }}
                    >
                        {isLoading && <Spinner />}
                    </div>
                ) : (
                    emptyMessage()
                )}
            <div ref={headerDiv}
                style={{
                    position: "relative",
                    overflow:'hidden',
                }}
            className={`p-datatable p-component p-datatable-customers`}>
            <table>
                <thead className="p-datatable-thead">
                    <tr>
                        {colDef &&
                            colDef.map((headerItems, index) => {
                                const { data: _data, column } = headerItems;
                                return (
                                    <th key={index} className="p-sortable-column" style={column.style}>{loadHeaderElement(column, index, _data)}</th>)
                            })}

                    </tr>
                    {moduleName !== "managetime" && moduleName !== "dayview" && moduleName !== "managecost" && <tr>
                        {colDef &&
                            colDef.map((headerItems, index) => {
                                
                                const { data: _data, filter } = headerItems;
                                return (
                                    <th key={index} className={`p-filter-column`}>{loadFilterElement(filter, index, _data)}</th>)
                            })}
                    </tr> }
                </thead>
            </table>
            </div>
            <div  data-testid="customTableScrollDiv"
            ref={bodyScroll}
                style={{
                    position: "relative",   
                    height: '500px',
                    overflowY: 'scroll'
                }}
                className={`p-datatable p-component p-datatable-customers `}
                data-scrollselectors=".p-datatable-scrollable-body, .p-datatable-unfrozen-view .p-datatable-body"
                onScroll={(e) => {
                    const target = e.target;
                    // console.log("scrollHeight=>" , target.scrollHeight)
                    // console.log("scrollTop=>" , Math.ceil( target.scrollTop))
                    // console.log("clientHeight=>" , target.clientHeight)
                   
                   
                    //console.log("is scrolling triggering on body??");
                    //let theaderCont = document.getElementById("headertable");
                    headerDiv.current.scrollLeft = target.scrollLeft;
                    if ((target.scrollHeight - Math.ceil( target.scrollTop)-10) <= target.clientHeight){ 
                        onLazyScroll();
                        // console.log("scrollHeight=>" , target.scrollHeight)
                        // console.log("scrollTop=>" , Number(target.scrollTop.toCeiling()))
                        // console.log("clientHeight=>" , target.clientHeight)
                    }
                    // if (
                    //     tbody != null &&
                    //     tbody.scrollHeight - (tbody.offsetHeight + tbody.scrollTop) < 100
                    // ) {
                    //     onLazyScroll();
                    // }
                }}>
                
            <table>
                    <tbody id="tbody" 
                    className="p-datatable-tbody">
                    {
                        data.map((rowItem, index) => {
                            return <Row key={index}
                            rowItem={rowItem}
                            colDef={colDef}
                            rowExpansionId={rowExpansionId}
                            loadBodyElement={loadBodyElement}
                            rowClicked={rowClicked}
                            internalRowClicked={internalRowClicked}
                            enableRowExpansion={enableRowExpansion}
                            rowExpansionElement={rowExpansionElement}
                            index={index}
                            />
                        })
                    }
                    {/* {colDef &&
                        colDef.map((headerItems, index) => {
                            const { data, filter } = headerItems;
                            return (
                                <td className={`p-filter-column`}>{loadBodyElement(filter, index, data)}</td>)
                        })} */}
                    
                </tbody>
            </table>
            {/* <DataTable
                value={scrollNoMore ? data : virtualData}
                //value={data}
                scrollable
                stripedRows
                onRowClick={(e) => {
                    !loadingCard && enableRowExpansion && expandRowOnClick(e.data);
                }}
                scrollHeight="510px"
                expandedRows={expandedRows}
                // virtualScrollDelay={0}
                emptyMessage={emptyMessage}
                lazy={data.length !== 0 ? true : false}
                rows={scrollNoMore ? data.length : pageSize}
                onPage={true}
                loading={
                    updateComponentonInfinciteScroll && isLoading
                }
                virtualScroll
                virtualRowHeight={45}
                onVirtualScroll={onlazyScrollLoad}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="rowId"
                totalRecords={
                    scrollNoMore && data.length < pageNumber * pageSize
                        ? data.length
                        : (pageNumber) * pageSize
                }
            >
                {colDef.map((col, index) => {
                    const { data, column, filter } = col;
                    return (
                        <Column
                            header={loadHeaderElement(column, index, data)}
                            headerClassName="p-sortable-column"
                            style={column.style}
                            filter={filter.show}
                            filterPlaceholder={filter.label}
                            filterElement={loadFilterElement(filter, index, data)}
                            field={data.propName}
                            loadingBody={loadingText}
                            body={(rowData) => loadBodyElement(rowData, data)}
                        />
                    );
                })}
            </DataTable> */}
        </div>
        </>
    );
}

export default CustomTable;
