import { SET_AUTH_DETAILS, RESET_AUTH_DETAILS } from "./ActionType";
export var setAuthDetails = function setAuthDetails(cognitoUser, email) {
  return {
    type: SET_AUTH_DETAILS,
    data: {
      user: cognitoUser,
      email: email
    }
  };
};
export var resetAuthDetails = function resetAuthDetails() {
  return {
    type: RESET_AUTH_DETAILS
  };
};