import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { SET_SESSION_TIMEOUT, SET_PREFERENCE_SETTINGS, SET_USER_ME_DATA, SET_PREFERENCE_API_DATA } from "../../actions/ActionType";
import initialState from "../../store/InitialState";
var PreferenceReducer = function PreferenceReducer() {
  var _action$data$find;
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.PreferenceReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    data: []
  };
  switch (action && action.type) {
    case SET_SESSION_TIMEOUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        userSessionTimeout: action.data.userSessionTimeout
      });
    case SET_PREFERENCE_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        preferenceSettings: action.data,
        expiryReminder: action.value && action.value.passwordResetNotification,
        expiryReminderMessage: action.value && action.value.passwordResetNotificationValue,
        hasMultipleSubClients: Array.isArray(action.data) ? (_action$data$find = action.data.find(function (item) {
          return item.param_key === "HAS_MULTIPLE_SUB_CLIENTS";
        })) === null || _action$data$find === void 0 ? void 0 : _action$data$find.param_value : false
      });
    case SET_USER_ME_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        meUserData: action.data,
        firstName: action.data.firstname,
        lastName: action.data.lastname,
        userName: action.data.username
      });
    case SET_PREFERENCE_API_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        preferenceApiData: action.data
      });
    default:
      return state;
  }
};
export default PreferenceReducer;