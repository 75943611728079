import ActionStore from "../../../store/ActionStore";

class Field {
  fieldId = 0;
  order = "1"
  field = "";
  isDisplay = "";
  isMandatory = "";
  errorMessage = ""; // if it's not empty, means error to be shown on the field
  displayLabel = "";
  values: Code[] = [];
}

class Code {

  id = "";
  label = "";
  description = "";
  displayCode = "";
  isDefault = false;
}
class Error {
  field = "";
  message = "";
}
class AddTimeState extends ActionStore {
  #initialStatePayload: Field[] = [];
  narrativeError = "";
  clientError = "";
  matterError = "";
  durationError = "";
  dateError = "";
  get payload() {
    return this.#initialStatePayload;
  }
  resetPayLoad = () => {
    this.#initialStatePayload = [];
  };

  replaceCodeType = (fieldParam) => {

    let codeExists = this.#initialStatePayload.find((d) => d.displayLabel === fieldParam.displayLabel);

    if (codeExists !== undefined) {
      this.#initialStatePayload.forEach((item, index, list) => {
        if (item.field === fieldParam.field) {
          list[index] = fieldParam;
        }
      })
    } else {

      this.#initialStatePayload.push(fieldParam);
      this.setPayload(this.#initialStatePayload);

    }

  }
  /* removes field passed from list of all fields and saves in form payload
   * @param object
   */
  removeField = (entity) => {
    if(entity && entity.field && entity.dependentParent){
    const updatedInitialStatePayload = this.#initialStatePayload.filter(d => d.field !== entity.field);
     this.setPayload(updatedInitialStatePayload);
    }
  }

  setPayload = (data: Field[]) => {
    data && data.sort((a, b) => { return Number(a.order) - Number(b.order) });    
    this.#initialStatePayload = data || [];
  };

  setPayloadForGeneral = (data: Field[]) => {
    data && data.sort((a, b) => { return Number(b.order) - Number(a.order) });
    this.#initialStatePayload = data || [];
  };

  updatePayload = (field, data, ignoreIfAlreadySelected = false) => {
    let FieldToBeUpdated = this.#initialStatePayload.find((item) => item.field === field);
    if (FieldToBeUpdated) {
      if(FieldToBeUpdated.field==="MAKE_NO_CHARGE"){
        let task = FieldToBeUpdated.values.slice(0, 4).find((d) => d.id === data.id) || { id: null, isDefault: null };
        task.isDefault=true;
        FieldToBeUpdated.values.forEach(
          (d) => d.id !== (task.id || 0) && (d.isDefault = false)
        );
      }
      else
      {
      let task = FieldToBeUpdated.values.slice(0, 4).find((d) => d.id === data.id) || { id: null, isDefault: null };
      if (task && task.id) {
        if (task.isDefault) {
          // if it's already selected
          if (ignoreIfAlreadySelected) {
            // and if it's supposed to be ignord
            // do nothing
          } else {
            task.isDefault = true;
          }
        } else {
          task.isDefault = false;
        }
        if (!(ignoreIfAlreadySelected && task.isDefault)) {
          task.isDefault = !task.isDefault;
        }
        FieldToBeUpdated.values.forEach(
          (d) => d.id !== (task.id || 0) && (d.isDefault = false)
        );
      } else {
        FieldToBeUpdated.values.forEach((d) => (d.isDefault = false));

        data.isDefault = true;

        FieldToBeUpdated.values.unshift(data);
        FieldToBeUpdated.values.pop();
      }
    }
  }
  };
  setSelectedPayload = (timeEntryDetail) => {
    this.#initialStatePayload.forEach((item) => {
      item?.values?.forEach((itemValue) => {
        itemValue.isDefault = false;
      });
    });

    timeEntryDetail.forEach((item) => {
      this.#initialStatePayload.forEach((itemState) => {
        if (item.fieldId === itemState.fieldId) {
          let searchValue = itemState.values.slice(0, 4).find(
            (valueItem) => valueItem.id === item.values.sys_field_value_id
          );
          if (searchValue) {
            searchValue.isDefault = true;
          } else {
            let valueSelected = {
              id: item.values.sys_field_value_id,
              label: item.values.display_value,
              description: item.values.description,
              displayCode: item.values.display_value,
              isDefault: true,
            };
            itemState.values.unshift(valueSelected);
            if(itemState.values && itemState.values.length > 4){
            itemState.values.pop();
            }
          }
        }
      });
    });
  };

  setClientError = (val = "") => {
    this.clientError = val;
  };
  setMatterError = (val = "") => {
    this.matterError = val;
  };
  setNarrativeError = (val = "") => {
    this.narrativeError = val;
  };
  setDurationError = (val = "") => {
    this.durationError = val;
  };
  setDateError = (val = "") => {
    this.dateError = val;
  };

  addError = (errors: Error[]) => {
    //this.#initialStatePayload.forEach(d => d.errorMessage = "");
    errors.forEach((d) => {
      let field = this.#initialStatePayload.find(
        (e) =>
          e.field.toLowerCase().replace("_", "") === d.field ||
          (e.field.toLowerCase() === "udf2" && d.field === "time_usercode2") ||
          (e.field.toLowerCase() === "udf3" && d.field === "time_usercode3") ||
          (e.field.toLowerCase() === "rate_code" && d.field === "amount") ||
          (e.field.toLowerCase() === "task_code" && d.field === "submatter") ||
          (e.field.toLowerCase() === "activity_code" && d.field === "time_usercode1") ||
          (e.field.toLowerCase() === "phase_code" && d.field.toLowerCase() === "phase") ||
          (e.field.toLowerCase() === "city_code" && d.field.toLowerCase() === "city") ||
          (e.field.toLowerCase() === "service_code" && d.field === "service") 
      );
      if (field) {
        field.errorMessage = d.message;
      } else if (d.field === "client") {
        this.setClientError(d.message);
      } else if (d.field === "matter") {
        this.setMatterError(d.message);
      } else if (d.field === "narrative") {
        this.setNarrativeError(d.message);
      } else if (d.field === "hours") {
        this.setDurationError(d.message);
      } else if (d.field === "date") {
        this.setDateError(d.message);
      }
    });
  };
  removeError = () => {
    this.setClientError("");
    this.setMatterError("");
    this.setNarrativeError("");
    this.setDurationError("");
    this.setDateError("");
    this.payload.forEach((item) => {
      item.errorMessage = "";
    });
  };
}

export default new AddTimeState();
