import React, { useState, useEffect } from "react";
//import { useDispatch, useSelector } from "react-redux";
// import { applyColumnSelection } from "../../../redux/actions/ConflictManagement/Practice/fsSearchResultsActions";
import {
  OverLayPanelMenu,
  CheckBox,
  DraggableCheckBox,
} from "@attorneyflow-mf/styleguide";
import { Button } from "primereact/button";
import "./DraggableColumnSelector.scss"
import jsUtils from "../../../utils/jsUtils";
// import commonUtils from "../../../utils/commonUtils";

export default function DraggableColumnSelector({
    columnSelectionData,
    columnSelectionDataBackup,
    onApply,
 
}){

  const [menuItems, setMenuItems] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectColumns, setselectColumns] = useState([]);
  const [unselectColumns, setunSelectColumns] = useState([]);


  useEffect(()=>{
    setColumns([...columnSelectionData]);
    setselectColumns([...columnSelectionData.filter((x) => x.col_enable)]);
    setunSelectColumns([
      ...columnSelectionData.filter((x) => !x.col_enable),
    ])
  },[columnSelectionData])

  const populateMenuItemsTemplates = () => {
    const selectedColumnsBlock = selectColumns.map((col, index) => {
    //   if (
    //     !subClientDisplayStatus &&
    //     col.label == commonUtils.getIntlMessage("SUB")
    //   ) {
    //     return false;
    //   }
      return (
        <div className={col.disable?"disableField":""}>
        <DraggableCheckBox
          checked={col.col_enable}
          id={`column${index + 1}`}
          indexValue={index}
          label={jsUtils.getLiteralValue(col.key)}
          name="columns"
          onSelectionChange={onSelectionChangeToNone}
          value={col.columnName}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragStart={onDragStart}
          showOrder={true}
        />
        </div>
      );
    });

    const sortedUnSelectedColumns = unselectColumns?.sort(function (a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const unSelectedColumnsBlock = sortedUnSelectedColumns?.map(
      (col, index) => {
        return (
          <div className={col.disable?"disableField":""}>
          <CheckBox
            checked={col.col_enable}
            id={`unselected_column${index + 1}`}
            label={jsUtils.getLiteralValue(col.key)}
            name="columns"
            onSelectionChange={onSelectionChange}
            value={col.columnName}
          />
          </div>
        );
      }
    );
    return (
      <div>
        <div style={{ display: "block", maxHeight: "400px", overflow: "auto" }}>
          {selectedColumnsBlock}
          {unSelectedColumnsBlock}
        </div>
        <div className="overlaypanel-footer-section">
          <Button
            label={"Reset"}
            icon={"pi pi-times"}
            onClick={(e) => onCancelColumnSelection()}
            className="p-button-rounded-reset"
          />
          <Button
            label={"Apply"}
            icon={"pi pi-check"}
            onClick={(e) => onApplyColumnSelection()}
            className="p-button-rounded-apply"
            disabled= {columns.length === 0 ? true : false}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setMenuItems(populateMenuItemsTemplates());
  }, [selectColumns, unselectColumns]);

 

  const onSelectionChangeToNone = (evt) => {
    let record_index = selectColumns?.findIndex(
      (c) => c.columnName === evt.value
    );
    if (record_index !== -1) {
      let existingCol = selectColumns;
      existingCol.splice(record_index, 1);
      setselectColumns(existingCol);
      let unSelectColumns = unselectColumns;
      let valueToBeAdded = columns.find((x) => x.columnName === evt.value);
      valueToBeAdded.col_enable = false;
      unSelectColumns.push(columns.find((x) => x.columnName === evt.value));
      setunSelectColumns(unSelectColumns);
    }

    //setselectColumns(selectColumns);
    setMenuItems(populateMenuItemsTemplates());
  };
  const onSelectionChange = (evt) => {
    let record_index = unselectColumns?.findIndex(
      (c) => c.columnName === evt.value
    );
    if (record_index !== -1) {
      let existingCol = unselectColumns;
      existingCol.splice(record_index, 1);
      setunSelectColumns(existingCol);
      let selectcolumns = selectColumns;
      let valueToBeAdded = columns.find((x) => x.columnName === evt.value);
      valueToBeAdded.col_enable = true;
      selectcolumns.push(columns.find((x) => x.columnName === evt.value));
      setselectColumns(selectcolumns);
    }
    // setColumns(columns);
    setMenuItems(populateMenuItemsTemplates());
  };

  const onApplyColumnSelection = () => {
    let finalColumns = [...selectColumns,...unselectColumns];
    onApply(finalColumns,selectColumns);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDragStart = (e, valueProp) => {
    console.log("DragStart", e);
    console.log("DragStart param", valueProp);
    e.dataTransfer.setData("valuePropName", valueProp);
  };

  const onDrop = (e, dropIndex) => {
    const propNameStartedDragged = e.dataTransfer.getData("valuePropName");
    const draggedItemIndex = selectColumns.findIndex(
      (x) => x.columnName === propNameStartedDragged
    );
    if (draggedItemIndex !== -1 && draggedItemIndex !== dropIndex) {
      let draggedItem = selectColumns[draggedItemIndex];
      let existList = selectColumns;
      existList.splice(draggedItemIndex, 1);
      existList.splice(dropIndex, 0, draggedItem);
      setselectColumns(existList);
      setMenuItems(populateMenuItemsTemplates());
    }

    // console.log("Started to drag the ", propNameStartedDragged);
    // console.log("Dropped at index", dropIndex);
  };

  const onCancelColumnSelection = () => {
    //Cancel Selection
    //setColumns([...cloneSearchResultsCoulmnSelection]);
    let array=columnSelectionDataBackup;
    setunSelectColumns([
      ...array.filter((x) => !x.col_enable),
    ]);
    setselectColumns([
      ...array.filter((x) => x.col_enable),
    ]);
  };

  return (
    <OverLayPanelMenu
      enableDropDown={false}
      content={menuItems}
      hidden={false}
      icon="pi-table"
      onHide={onCancelColumnSelection}
    />
  );
}

