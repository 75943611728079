import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import {TOOLTIPS_VALUES} from "../../../config/vars";

export default function PasswordField({
  inputPassword,
  onChangePassword,
  placeholder,
  calendarCustom
}) {
  const [field, setField] = useState(true);
  const [inputPwd, setInputPwd] = useState('');

  useEffect(() => {
    setInputPwd(inputPassword);
  }, [inputPassword]);

  return (
    <div>
      {field ? (
        <span className="password-field">
          <span>
           { calendarCustom ? "":<img
              alt=""
              className="af-password"
              src="/images/svg/icon_lock.svg"
            />}

            <InputText
              type="password"
              autoComplete="new-password"
              className={`p-inputtext p-component p-password-input ${calendarCustom ? "custom-calendar-input" : ""}`}
              value={inputPwd || ''}
              onChange={(event) => {
                const caret = event.target.selectionStart
                const element = event.target
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret
                  element.selectionEnd = caret
                })
                onChangePassword(event.target.value);
              }}
              placeholder={placeholder}
            />
          </span>
          <span className={(calendarCustom ? "calendar-css" : "")}>
            <img
              alt=""
              title={TOOLTIPS_VALUES.SHOW_PASSWORD}
              className="af-password-look"
              src="/images/svg/eye.svg"
              onClick={() => {
                setField(false);
              }}
              data-testid="eyeButton"
            />
          </span>
        </span>
      ) : (
        <span className="password-field">
          <span >
          { calendarCustom ? "":<img
              alt=""
              className="af-password"
              src="/images/svg/icon_lock.svg"
            />}

            <InputText
              type="text"
              autoComplete="new-password"
              className={`p-inputtext p-component p-password-input ${calendarCustom ? "custom-calendar-input" : ""}`}
              value={inputPwd}
              onChange={(event) => {
                const caret = event.target.selectionStart
                const element = event.target
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret
                  element.selectionEnd = caret
                })
                onChangePassword(event.target.value)
              }}
              placeholder={placeholder}
            />
          </span>
          <span className={(calendarCustom ? "calendar-css" : "")}>
            <img
              alt=""
              title={TOOLTIPS_VALUES.HIDE_PASSWORD}
              className="af-password-look"
              src="/images/svg/eye-cross.svg"
              onClick={() => {
                setField(true);
              }}
              data-testid="crossedEyeButton"
            />
          </span>
        </span>
      )}
    </div>
  );
}
