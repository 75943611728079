//Tooltips keys
export var TOOLTIPS_KEYS = {
  TOOLTIP_ACCOUNT_MENU: "ACCOUNT_MENU",
  TOOLTIP_HELP: "HELP",
  TOOLTIP_SEARCH: "SEARCH",
  TOOLTIP_SHOW_ACTIVE_TIMER_ONLY: "SHOW_ACTIVE_TIMER_ONLY",
  TOOLTIP_SHOW_ALL_TIMERS: "SHOW_ALL_TIMERS",
  TOOLTIP_SWITCH_SORT_ORDER: "SWITCH_SORT_ORDER",
  TOOLTIP_START: "START",
  TOOLTIP_PAUSE: "PAUSE",
  TOOLTIP_RESUME: "RESUME",
  TOOLTIP_STOP: "STOP",
  TOOLTIP_CLEARTIME: "CLEAR_TIME",
  TOOLTIP_SELECT_DATE: "SELECT_DATE",
  TOOLTIP_CLEAR: "CLEAR",
  TOOLTIP_SWITCH_DURATION_FORMAT: "SWITCH_DURATION_FORMAT",
  TOOLTIP_DICTATE: "DICTATE",
  TOOLTIP_CLOSE: "CLOSE",
  TOOLTIP_COPY: "COPY",
  TOOLTIP_EDIT: "EDIT",
  TOOLTIP_DELETE: "DELETE",
  TOOLTIP_SHOW_ACTIVE_ROLES_ONLY: "SHOW_ACTIVE_ROLES_ONLY",
  TOOLTIP_SHOW_ALL_ROLES: "SHOW_ALL_ROLES",
  TOOLTIP_SHOW_FIRM_AUTOTEXT_ONLY: "SHOW_FIRM_AUTOTEXT_ONLY",
  TOOLTIP_SHOW_PERSONAL_AUTOTEXT_ONLY: "SHOW_PERSONAL_AUTOTEXT_ONLY",
  TOOLTIP_SET_AS_TIMEKEEPER: "SET_AS_TIMEKEEPER",
  TOOLTIP_REMOVE_AS_TIMEKEEPER: "REMOVE_AS_TIMEKEEPER",
  TOOLTIP_SAVE: "SAVE",
  TOOLTIP_CANCEL: "CANCEL",
  TOOLTIP_SHOW_PASSWORD: "SHOW_PASSWORD",
  TOOLTIP_HIDE_PASSWORD: "HIDE_PASSWORD",
  TOOLTIP_TURN_ON_THIS_DELEGATE: "TURN_ON_THIS_DELEGATE",
  TOOLTIP_TURN_OFF_THIS_DELEGATE: "TURN_OFF_THIS_DELEGATE"
}; //values
export var TOOLTIPS_VALUES = {
  ACCOUNT_MENU: "Account Menu",
  HELP: "Help",
  SEARCH: "Search",
  SHOW_ACTIVE_TIMER_ONLY: "Show active timer only",
  SHOW_ALL_TIMERS: "Show all timers",
  SWITCH_SORT_ORDER: "Switch sort order",
  START: "Start",
  PAUSE: "Pause",
  RESUME: "Resume",
  STOP: "Stop",
  CLEAR_TIME: "Clear time",
  SELECT_DATE: "Select date",
  CLEAR: "Clear",
  SWITCH_DURATION_FORMAT: "Switch duration format",
  DICTATE: "Dictate",
  CLOSE: "Close",
  SAVE: "save",
  CANCEL: "Cancel",
  SHOW_PASSWORD: "Show password",
  HIDE_PASSWORD: "Hide password",
  RESEND_CODE: "Resend Code",
  COPY: "Copy",
  EDIT: "Edit",
  DELETE: "Delete",
  SHOW_ACTIVE_ROLES_ONLY: "Show active roles only",
  SHOW_ALL_ROLES: "Show all roles",
  SHOW_FIRM_AUTOTEXT_ONLY: "Show firm autotext only",
  SHOW_PERSONAL_AUTOTEXT_ONLY: "Show personal autotext only",
  SET_AS_TIMEKEEPER: "Set as timekeeper",
  REMOVE_AS_TIMEKEEPER: "Remove as timekeeper",
  TURN_ON_THIS_DELEGATE: 'Turn on this delegate',
  TURN_OFF_THIS_DELEGATE: 'Turn off this delegate'
};
export var HELP_LINKS = {
  ADD_TIME: "AddTime",
  AUTOTEXT: "Autotext",
  DELEGATES: "Delegates",
  DRAFT_TIME_ENTRY: "DraftTimeEntry",
  LANDING_PAGE: "Home",
  MANAGE_TIME: "ManageTime",
  MY_PROFILE: "MyProfile",
  PREFERENCE_PRACTICE: "PreferencesPractice",
  ROLES_ACCESS: "RoleAccess",
  ROLES: "Roles",
  USER_ACCESS: "UserAccess",
  USERS: "Users",
  TIME_ENTRY: "TimeEntry",
  DAY_VIEW: "DayView",
  MONTH_VIEW: "MonthView",
  BULK_CHANGE: "TimeEntryBulkChange",
  SYSTEM_CONFIG_CALENDAR: "SystemConfigCalendarIntegration",
  GENERAL_TIME_ENTRY: "GeneralTimeEntry",
  RESTRICTED_WORDS: "RestrictedWords",
  RESTRICTED_WORD_MAINTENANCE: "RestrictedWordMaintenance",
  CHARACTER_LENGTH: "CharacterLength",
  CHARACTER_LENGTH_MAINTENANCE: "CharacterLengthMaintenance",
  ETHICAL_WALLS: "EthicalWalls",
  ETHICAL_WALLS_MAINTENANCE: "EthicalWallExceptionMaintenance",
  SYSTEM_CONFIG_GENERAL: "SystemConfigGeneral"
};
export var DayViewDefaultColumns = [{
  "icon": "present",
  "type": "string",
  "label": "Client",
  "style": {
    "width": "150px",
    "wordBreak": "break-word"
  },
  "columnName": "client",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Sub",
  "style": {
    "width": "120px"
  },
  "columnName": "sub",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Matter",
  "style": {
    "width": "170px",
    "wordBreak": "break-word"
  },
  "columnName": "matter",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Date",
  "style": {
    "width": "120px"
  },
  "columnName": "date",
  "col_enable": true
}, {
  "id": "status",
  "icon": "present",
  "type": "string",
  "label": "Status",
  "style": {
    "width": "150px"
  },
  "columnName": "status",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Automatic",
  "style": {
    "width": "150px"
  },
  "columnName": "automatic",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Duration",
  "style": {
    "width": "150px"
  },
  "columnName": "duration",
  "col_enable": false
},
//*************************************************************** */
{
  "icon": "present",
  "type": "string",
  "label": "City",
  "style": {
    "width": "150px"
  },
  "columnName": "city",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Narrative",
  "style": {
    "width": "150px"
  },
  "columnName": "narrative",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Phase",
  "style": {
    "width": "150px"
  },
  "columnName": "phase",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Service",
  "style": {
    "width": "150px"
  },
  "columnName": "service",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Task",
  "style": {
    "width": "150px"
  },
  "columnName": "task",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Rate",
  "style": {
    "width": "150px"
  },
  "columnName": "rate",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Make No Charge",
  "style": {
    "width": "150px"
  },
  "columnName": "makenocharge",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "General time",
  "style": {
    "width": "150px"
  },
  "columnName": "generaltime",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Activity",
  "style": {
    "width": "150px"
  },
  "columnName": "activity",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Udf2",
  "style": {
    "width": "150px"
  },
  "columnName": "udf2",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Udf3",
  "style": {
    "width": "150px"
  },
  "columnName": "udf3",
  "col_enable": false
}];
export var ManageTimeDefaultColumns = [{
  "icon": "present",
  "type": "string",
  "label": "Client",
  "style": {
    "width": "150px",
    "wordBreak": "break-word"
  },
  "columnName": "client",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Sub",
  "style": {
    "width": "120px"
  },
  "columnName": "sub",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Matter",
  "style": {
    "width": "170px",
    "wordBreak": "break-word"
  },
  "columnName": "matter",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Date",
  "style": {
    "width": "120px"
  },
  "columnName": "date",
  "col_enable": true
}, {
  "id": "status",
  "icon": "present",
  "type": "string",
  "label": "Status",
  "style": {
    "width": "150px"
  },
  "columnName": "status",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Automatic",
  "style": {
    "width": "150px"
  },
  "columnName": "automatic",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Duration",
  "style": {
    "width": "150px"
  },
  "columnName": "duration",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "City",
  "style": {
    "width": "150px"
  },
  "columnName": "city",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Narrative",
  "style": {
    "width": "150px"
  },
  "columnName": "narrative",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Phase",
  "style": {
    "width": "150px"
  },
  "columnName": "phase",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Service",
  "style": {
    "width": "150px"
  },
  "columnName": "service",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Task",
  "style": {
    "width": "150px"
  },
  "columnName": "task",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Rate",
  "style": {
    "width": "150px"
  },
  "columnName": "rate",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Make No Charge",
  "style": {
    "width": "150px"
  },
  "columnName": "makenocharge",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "General Time",
  "style": {
    "width": "150px"
  },
  "columnName": "generaltime",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Activity",
  "style": {
    "width": "150px"
  },
  "columnName": "activity",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Udf2",
  "style": {
    "width": "150px"
  },
  "columnName": "udf2",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Udf3",
  "style": {
    "width": "150px"
  },
  "columnName": "udf3",
  "col_enable": true
}];
export var ManageCostDefaultColumns = [{
  "icon": "present",
  "type": "string",
  "label": "Client",
  "key": "CLIENT",
  "style": {
    "width": "150px",
    "wordBreak": "break-word"
  },
  "columnName": "client",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Sub",
  "key": "SUB",
  "style": {
    "width": "120px"
  },
  "columnName": "sub",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Matter",
  "key": "MATTER",
  "style": {
    "width": "170px",
    "wordBreak": "break-word"
  },
  "columnName": "matter",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Date",
  "key": "DATE",
  "style": {
    "width": "120px"
  },
  "columnName": "date",
  "col_enable": true
}, {
  "id": "status",
  "icon": "present",
  "type": "string",
  "label": "Status",
  "key": "STATUS",
  "style": {
    "width": "150px"
  },
  "columnName": "status",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Automatic",
  "key": "AUTOMATIC",
  "style": {
    "width": "150px"
  },
  "columnName": "automatic",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Attachment",
  "key": "ATTACHMENT",
  "style": {
    "width": "150px"
  },
  "columnName": "attachment",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Amount",
  "key": "AMOUNT",
  "style": {
    "width": "150px"
  },
  "columnName": "amount",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "City",
  "key": "CITY_CODE",
  "style": {
    "width": "150px"
  },
  "columnName": "city",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Narrative",
  "key": "NARRATIVE",
  "style": {
    "width": "150px"
  },
  "columnName": "narrative",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Quantity",
  "key": "QUANTITY",
  "style": {
    "width": "150px"
  },
  "columnName": "quantity",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Payee",
  "key": "PAYEE",
  "style": {
    "width": "150px"
  },
  "columnName": "payee",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Phase",
  "key": "PHASE_CODE",
  "style": {
    "width": "150px"
  },
  "columnName": "phase",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Service",
  "key": "SERVICE_CODE",
  "style": {
    "width": "150px"
  },
  "columnName": "service",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Task",
  "key": "TASK_CODE",
  "style": {
    "width": "150px"
  },
  "columnName": "task",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Rate",
  "key": "RATE_CODE",
  "style": {
    "width": "150px"
  },
  "columnName": "rate",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Make No Charge",
  "key": "MAKE_NO_CHARGE",
  "style": {
    "width": "150px"
  },
  "columnName": "makenocharge",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Activity",
  "key": "ACTIVITY_CODE",
  "style": {
    "width": "150px"
  },
  "columnName": "activity",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Udf2",
  "key": "UDF2",
  "style": {
    "width": "150px"
  },
  "columnName": "udf2",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Udf3",
  "key": "UDF3",
  "style": {
    "width": "150px"
  },
  "columnName": "udf3",
  "col_enable": true
}];
export var AddTimeDefaultColumns = [{
  "icon": "present",
  "type": "string",
  "label": "Client",
  "style": {
    "width": "150px",
    "wordBreak": "break-word"
  },
  "columnName": "client",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Sub",
  "style": {
    "width": "120px"
  },
  "columnName": "sub",
  "col_enable": false
}, {
  "icon": "present",
  "type": "string",
  "label": "Matter",
  "style": {
    "width": "170px",
    "wordBreak": "break-word"
  },
  "columnName": "matter",
  "col_enable": true
}, {
  "icon": "present",
  "type": "string",
  "label": "Active timer",
  "style": {
    "width": "150px"
  },
  "columnName": "active timer",
  "col_enable": true
}];
export var manageTimeTableSortMap = {
  clientIdWithName: "cc.client_name",
  subClient: "csc.description",
  matterIdWithDesc: "cm.description",
  date: "tt.timeentry_datetime",
  status: "tt.status",
  isPredictive: "tt.is_predictive",
  billableDuration: "tt.billable_duration",
  narrative: "narrative",
  rate: "RATE_CODE",
  phase: "PHASE_CODE",
  task: "TASK_CODE",
  activity: "ACTIVITY_CODE",
  city: "CITY_CODE",
  service: "SERVICE_CODE",
  udf2: "UDF2",
  udf3: "UDF3",
  makenocharge: "MAKE_NO_CHARGE",
  cle: "CLE",
  generaltime: "isGeneral"
};
export var dayViewTableSortMap = {
  clientIdWithName: "cc.client_name",
  subClient: "csc.description",
  matterIdWithDesc: "cm.description",
  date: "tt.timeentry_datetime",
  status: "tt.status",
  isPredictive: "tt.is_predictive",
  billableDuration: "tt.billable_duration",
  narrative: "narrative",
  rate: "RATE_CODE",
  phase: "PHASE_CODE",
  task: "TASK_CODE",
  activity: "ACTIVITY_CODE",
  city: "CITY_CODE",
  service: "SERVICE_CODE",
  udf2: "UDF2",
  udf3: "UDF3",
  makenocharge: "MAKE_NO_CHARGE",
  cle: "CLE",
  generaltime: "isGeneral"
};
export var AddCostDefaultColumns = [{
  "icon": "present",
  "type": "string",
  "label": "Client",
  "style": {
    "width": "150px",
    "wordBreak": "break-word"
  },
  "columnName": "client",
  "col_enable": true,
  "key": "CLIENT"
}, {
  "icon": "present",
  "type": "string",
  "label": "Sub",
  "style": {
    "width": "120px"
  },
  "columnName": "sub",
  "col_enable": false,
  "key": "SUB"
}, {
  "icon": "present",
  "type": "string",
  "label": "Matter",
  "style": {
    "width": "170px",
    "wordBreak": "break-word"
  },
  "columnName": "matter",
  "col_enable": true,
  "key": "MATTER"
}];
export var manageCostTableSortMap = {
  clientIdWithName: "cc.client_name",
  subClient: "csc.description",
  matterIdWithDesc: "cm.description",
  date: "ct.costentry_datetime",
  status: "ct.status",
  isPredictive: "tt.is_predictive",
  billableDuration: "tt.billable_duration",
  narrative: "narrative",
  rate: "RATE_CODE",
  phase: "PHASE_CODE",
  task: "TASK_CODE",
  activity: "ACTIVITY_CODE",
  city: "CITY_CODE",
  service: "SERVICE_CODE",
  udf2: "UDF2",
  udf3: "UDF3",
  makenocharge: "MAKE_NO_CHARGE",
  cle: "CLE",
  generaltime: "isGeneral"
};