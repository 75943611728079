import React from "react";
import "./Icon.scss"
export default function Icon({classNameParentDiv,classNameIcon,title}){
return <div className={classNameParentDiv}>
    <i
    className={classNameIcon}
    title={title}
  ></i>
 </div>

}