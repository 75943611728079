import React, { useEffect, useState } from "react";
import jsUtils from "../../utils/jsUtils";
import moment from 'moment';
import './MonthView.scss';
import './fullCalendar.scss';
import { TabView, TabPanel } from 'primereact/tabview';
import { MonthCalendar } from '@attorneyflow-mf/styleguide';
import CompositeBar from "../common/Charts/CompositeBar";


const MonthViewTotals = ({ gridValue, amountData, hoursData, onCalendarChange, tableData, amountTabStatus, graphAmountData, graphHoursData, isGraphLoading }) => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [amountBudget, setAmountBudget] = useState();
  const [hoursBudget, setHoursBudget] = useState();
  const [showAmount, setShowAmount] = useState(false);

  useEffect(() => {
    if (gridValue) {
      setCalendarDate(gridValue);
    }
  }, [gridValue]);

  useEffect(() => {
    if (amountData && hoursData) {
      setAmountBudget(amountData);
      setHoursBudget(hoursData);
    }
  }, [amountData, hoursData]);

  const onDateChange = (newDate) => {
    onCalendarChange(newDate);
  }

  useEffect(() => {
    !amountTabStatus ? setShowAmount(false) : setShowAmount(true);
  }, [amountTabStatus]);

  // const numberWithCommas = (num) => {
  //     let a = "/\B(?=(", b= "\d{3})+", c ="(?!\d))/g";
  //     return num && num.toString().replace(a+b+c, ",");
  // }

  const getFormattedNumber=(number)=>{
    if(number){
    const numberFormatter = Intl.NumberFormat('en-US',{minimumFractionDigits:2});
    const formattedNumber = numberFormatter.format(number);
    return formattedNumber;
    }
  }

  const renderHours = () => {
    return tableData.map((data, index) => {
      let weeklyTotal = ((data.weeklyTotal) / 60).toFixed(2);
      let weekMonthTotal = ((data.weekMonthTotal) / 60).toFixed(2);
      return (
        !isGraphLoading && <div className=" af-mv-totals-hours p-grid" key={"hoursTotals"+index}>
          <div className="p-col"> {data.weekDisplayLable}  </div>
          <div className="p-col"> {getFormattedNumber(weeklyTotal)} </div>
          <div className="p-col"> {getFormattedNumber(weekMonthTotal)}  </div>
        </div>
      )
    })
  }

  const renderTotal = () => {
    let weeklyHours = ((tableData.map(item => item.weeklyTotal).reduce((weekPre, weekNext) => weekPre + weekNext, 0)) / 60).toFixed(2);
    let monthlyHours = ((tableData.map(item => item.weekMonthTotal).reduce((monthPre, monthNext) => monthPre + monthNext, 0)) / 60).toFixed(2);
    return (
      !isGraphLoading && <>
        <div className="p-col af-mv-totals-header-label"> {jsUtils.getLiteralValue("TOTAL")}</div>
        <div className="p-col af-mv-totals-header-label"> {getFormattedNumber(weeklyHours)} </div>
        <div className="p-col af-mv-totals-header-label"> {getFormattedNumber(monthlyHours)}  </div>
      </>
    )
  }

  const getTab = (title, columnheader, data, graphData,isAmountTab=false) => {
    let MonthValues = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return (
      <TabPanel header={jsUtils.getLiteralValue(title)}>
        <div className=" p-grid af-mv-totals-header">
          <div className="p-col af-mv-totals-header-label">{jsUtils.getLiteralValue(columnheader)}</div>
          <div className=" p-col af-mv-totals-header-label">{gridValue && moment(gridValue).format('MMM') + "."}</div>
          <div className="p-col af-mv-totals-header-label">{jsUtils.getLiteralValue("YTD")}</div>
        </div>
        {!isGraphLoading && data && data.map((item, index) => {
          let month = item.month;
          let ytd = item.ytd;
          return (
            <div className=" af-mv-totals-hours p-grid" key={"totalhours"+index}>
              <div className="p-col"> {item.name}</div>
              <div className=" p-col">{isAmountTab && "$"}{getFormattedNumber(month)}</div>
              <div className="p-col ">{isAmountTab && "$"}{getFormattedNumber(ytd)}</div>
            </div>
          )
        })}
        {<CompositeBar labels={MonthValues} datasetsSource={graphData} isGraphLoading={isGraphLoading}/>}
      </TabPanel>
    )
  }
  return (
    <div className="af-mv-totals-parent">
      <div className="af-weekly-month-total">
        <div className="p-grid af-monthyear-row">
            <MonthCalendar            
            value={calendarDate}
            onChange={onDateChange}
            />

        </div>

        <div className=" p-grid af-mv-totals-header">
          <div className="p-col af-mv-totals-header-label">
            {jsUtils.getLiteralValue("BILLABLE_HEADER")}
          </div>
          <div className=" p-col af-mv-totals-header-label">
            {jsUtils.getLiteralValue("WEEKLY_HEADER")}

          </div>
          <div className="p-col af-mv-totals-header-label">
            {moment(gridValue).format('MMM') + "."}
          </div>
        </div>
        {renderHours()}
        <div className=" p-grid af-mv-totals-total">
          {renderTotal()}
        </div>
      </div>

      <div className="tabview-demo">
        <div className="card">
          <TabView>
            {getTab("HOURS_HEADER", "HOURS_COLUMN", hoursBudget, graphHoursData)}
            {(showAmount && getTab("AMOUNT_HEADER", "AMOUNT_COLUMN", amountBudget, graphAmountData, true)) || <TabPanel headerClassName="hide-tab" />}
          </TabView>
        </div>
      </div>
    </div>
  )
}

export default MonthViewTotals;