import React from 'react'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { Chart as PChart } from 'primereact/chart';
import Spinner from '../Spinner/Spinner';

function CompositeBar({ labels, datasetsSource, isGraphLoading }) {
    const stackedOptions = {
        maintainAspectRatio: false,
        aspectRatio: 1.65,
        responsive: true,
        barPercentage: 1,
        barThickness: 30,
        borderColor: '#0f9ff1',
        borderWidth: 1,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                position: 'bottom',
                labels: {
                    color: '#495057',
                    pointStyle: 'rect',
                    usePointStyle: true
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#495057',
                    font: {
                        size: 10
                    }
                },
                grid: {
                    color: '#fff'
                }
            },
            y: {
                display: false,
                stacked: true,
                ticks: {
                    color: '#fff',
                    display: false,
                },
                grid: {
                    color: '#fff'
                }
            }
        }
    };
    let stackedData = {
        labels: labels,
        datasets: datasetsSource.map((source) => {
            return {
                type: 'bar',
                label: source.label,
                backgroundColor: source.color,
                data: source.data
            }
        })
    };
  return (
      <div style={{ height: '193px', paddingTop: '15px' }} data-testid = "component-testId">
          {isGraphLoading ? <Spinner/> :       
           <PChart type="bar" data={stackedData} options={stackedOptions}/>}
      </div>
  )
}

export default CompositeBar