import React from 'react';
const OptionsData = ({ code, name}) => {
  return (
    
      <div className = 'af-dropdown'>
                        <div className='af-code af-dropdown-value'>
                          {code} 
                          </div>
                          <div className = 'af-name af-dropdown-value'>
                            {name}
                          </div>
    </div>
    
  );
};

export default OptionsData;
