import React from 'react'
import "./Header.scss";

const Header = ({Redirect}) => {
    
    return (
        <>  
          <img
            alt=""
            className="logo-image"
            src="/images/svg/SurePoint_Logo_Trademark.svg"
            onClick={() => Redirect("/")}
        /></>
    )
}

export default Header
