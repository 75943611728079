import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import MultiSelectDropDown from '../common/DropDown/MultiSelectDropDown';
import { MultiSelectDropDown6 } from "@attorneyflow-mf/styleguide";
import CalendarInput from '../common/Calendar/CalendarInput';
import { useDispatch, useSelector } from 'react-redux';
import { manageTime } from '../../services/ManageTimeApi';
import ButtonComponent from '../common/Button/ButtonComponent';
import moment from "moment";
import OptionsData from './OptionsData';
import { draftTimeApi } from "../../services/DraftTimeApi";
import { resetMangeTimeStore, setDataOnManageTimePageUnMount } from '../../actions/ManageTimeAction';
import jsUtils from "../../utils/jsUtils";

let formInitialState = {
  clients: null,
  subClients: null,
  attorneyId: null,
  matters: null,
  //fromDuration:0.0,
  //toDuration: null,
  status: null,
  fromDate: null,
  toDate: null,
  narrative:"",
 // fromDate: "",
  fromTo: "",    
  cityCodes: null,
  phaseCodes: null,
  fromDuration: "",
  toDuration: "",
  serviceCodes: null,
  taskCodes: null,
  rateCodes: null,
  makeNoCharge: null,
  predictive: null,
  activityCodes: null,
  udf2Codes: null,
  udf3Codes: null,
  sort : "",
  sort_type : "",
  fromAmount:"",
  toAmount:"",
  quantity:"",
  payee:""
};
const IdKeyMap = {
  clients: 'clientId',
  subClients: 'subClientNumber',
  matters: 'matterId',
  cityCodes: 'display_value',
  phaseCodes: 'display_value',
  serviceCodes: 'display_value',
  taskCodes: 'display_value',
  rateCodes: 'display_value',
  activityCodes: 'display_value',
  udf2Codes: 'display_value',
  udf3Codes: 'display_value',
}

let initialParams = {
  clients: null,
  subClients: null,
  attorneyId: null,
  matters: null,
  fromDuration: null,
  toDuration: null,
  status: null,
  fromDate: null,
  toDate: null,
  narrative: "",
  sort : "",
  sort_type : "",
  fromAmount:"",
  toAmount:"",
  quantity:"",
  payee:""
}

let initialFieldLabels = {
  rateCode:"Rate",
  cityCode: "City",
  phaseCode: "Phase",
  taskCode: "Task",
  activityCode: "Activity",
  serviceCode: "Service",
  makeNoCharge: "Make No Charge",
  udf2: "UDF2",
  udf3: "UDF3"
}

const fieldNameMaps = {
  cityCodes: 'city_code',
  phaseCodes: 'phase_code',
  serviceCodes: 'service_code',
  taskCodes: 'task_code',
  rateCodes: 'rate_code',
  activityCodes: 'activity_code',
  makeNoCharge: 'make_no_charge',
  udf2Codes: 'udf2',
  udf3Codes: 'udf3',
  predictive: 'predictive',
  gte: 'gte'
}

let _pageOffset = 0;

const AdvancedSearch = ({ delegateUserId, onClose, onAdvSearchFilterChange, entriesInfo, onClickTracker}) => {
    const [fromDate] = useState(null);
    const [toDate] = useState(null);
    const [formState, setFormState] = useState(formInitialState);
    const [advSearchToggle, setAdvSearchToggle] = useState(false);
    const [searchCriteraRefresh, setSearchCriteriaRefresh] = useState(null);
    const [searchCriteria, setSearchCriteria] = useState(initialParams);
    const ManageTimeReducer = useSelector((state) => (state.ManageTimeReducer));   
    const [fieldLabel,setFieldLabel] = useState(initialFieldLabels);
    const [searchParamsMap, setSearchParamsMap] = useState({});
    const [formLabels, setFormLabels] = useState({});
    const [isPageLoaded, setIsPageLoaded] = useState(false);    
    const [clients, setClients] = useState([]);
    const [subClients, setSubClients] = useState([]);
    const [matters, setMatters] = useState([]);
    const [fieldName, setFieldName] = useState("");    
    const [clientsTotal, setClientsTotal] = useState([]);
    const [subClientsTotal, setSubClientsTotal] = useState([]);
    const [mattersTotal, setMattersTotal] = useState([]);
    const [lazyLoading, setLazyLoading] = useState(false);
    const [isLazyApplied, setIsLazyApplied] = useState(true);
    const [resetDisable,setResetDisable] = useState(true);
    const [searchDisable,setSearchDisable] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
      resetFormAndSearchParams();
      loadTimeEntriesPayload();          
      setIsPageLoaded(true);
      return(() => {
        dispatch(setDataOnManageTimePageUnMount(true));
        resetFormAndSearchParams();
      });
    },[]);

    useEffect(()=>{
      let valueArray=Object.values(formState) || [];
      let valueSelected=valueArray.findIndex((item)=>item!==null && item.toString()!=="") !== -1;
      if(valueSelected){
        setResetDisable(false);
        setSearchDisable(false);
      }
      else{
        setResetDisable(true);
        setSearchDisable(true);
      }
    },[JSON.parse(JSON.stringify(formState))])

    useEffect(() => {      
      if(isPageLoaded){
       onClickReset();
       dispatch(resetMangeTimeStore());
      } 
     },[delegateUserId, dispatch]);

    const setLabels = (fieldLabels) => {
      setFormLabels({
        ...fieldLabels,
        clients: jsUtils.getLiteralValue("CLIENT"),
        subClients: jsUtils.getLiteralValue("SUB"),
        matters: jsUtils.getLiteralValue("MATTER"),
        fromDuration: jsUtils.getLiteralValue("DURATION_MIN"),
        toDuration: jsUtils.getLiteralValue("DURATION_MAX"),
        status: jsUtils.getLiteralValue("STATUS"),
        fromDate: jsUtils.getLiteralValue("DATE_FROM"),
        toDate: jsUtils.getLiteralValue("DATE_TO"),
        narrative: jsUtils.getLiteralValue("NARRATIVE"),        
        predictive: jsUtils.getLiteralValue("AUTOMATIC"),     
        gte: jsUtils.getLiteralValue("GENERAL_TIME"),
        fromAmount: jsUtils.getLiteralValue("AMOUNT_MIN"),
        toAmount: jsUtils.getLiteralValue("AMOUNT_MAX"),
        quantity: jsUtils.getLiteralValue("QUANTITY"),
        payee: jsUtils.getLiteralValue("PAYEE")
      });     
    }
   
/**
  *  api call to get all the fields and their corresponding values and the fieldstate and duration states 
*/
  const loadTimeEntriesPayload = async () => {
    await draftTimeApi.getDraftConfig(delegateUserId).then((res) => {
      if (res && res.fields) {
        let rate_code = res.fields.find((item) => item.field === "RATE_CODE");
        let city_code = res.fields.find((item) => item.field === "CITY_CODE");
        let phase_code = res.fields.find((item) => item.field === "PHASE_CODE");
        let task_code = res.fields.find((item) => item.field === "TASK_CODE");
        let activity_code = res.fields.find((item) => item.field === "ACTIVITY_CODE");
        let service_code = res.fields.find((item) => item.field === "SERVICE_CODE");
        let make_no_charge = res.fields.find((item) => item.field === "MAKE_NO_CHARGE");
        let udf2 = res.fields.find((item) => item.field === "UDF2");
        let udf3 = res.fields.find((item) => item.field === "UDF3");

        let fieldParams = {
          rateCode: rate_code && rate_code.displayLabel,
          cityCode: city_code && city_code.displayLabel,
          phaseCode: phase_code && phase_code.displayLabel,
          taskCode: task_code && task_code.displayLabel,
          activityCode: activity_code && activity_code.displayLabel,
          serviceCode: service_code && service_code.displayLabel,
          makeNoCharge: make_no_charge && make_no_charge.displayLabel,
          udf2: udf2 && udf2.displayLabel,
          udf3: udf3 && udf3.displayLabel,
        };

        let fieldLabels = {
          cityCodes: fieldParams.cityCode,
          phaseCodes: fieldParams.phaseCode,
          serviceCodes: fieldParams.serviceCode,
          taskCodes: fieldParams.taskCode,
          rateCodes: fieldParams.rateCode,
          makeNoCharge: fieldParams.makeNoCharge,
          activityCodes: fieldParams.activityCode,
          udf2Codes: fieldParams.udf2,
          udf3Codes: fieldParams.udf3
        };

        setFieldLabel(fieldParams);
        setLabels(fieldLabels);
      }         
    });
  };
 
    const setFormValues = (fieldNameKey, selectedList, index) => { 
      if(fieldNameKey === "clients") {
        setFormState(state => ({
          ...state,
          [fieldNameKey]: selectedList,
          "subClients": null,
          "matters": null
        }));
      } else if (fieldNameKey === "subClients") {
        setFormState(state => ({
          ...state,
          [fieldNameKey]: selectedList,
          "matters": null
        }));
      } else {
      setFormState(state => ({
        ...state,
        [fieldNameKey]: selectedList
      }));    
      }
    }

    const onFocus = (datasetName, listKey) => {
      if(ManageTimeReducer[listKey].length <= 1 || ManageTimeReducer.manegeTimePageUnmount)
      {
        dispatch(manageTime.getLookupList(delegateUserId, datasetName, listKey));        
      }    
    };    
   
    const setSearchedParamsBreadcrumbs = (key, list, fieldNameKey) => {
      if(!list) return;
      if(list && list.length === 0) {
        delete searchParamsMap[formLabels[key]];
        return;
      }
        if(fieldNameKey === null) { 
         searchParamsMap[formLabels[key]] = list.map(value => value ? value : 'Blank').join(", ");         
        } else {         
         searchParamsMap[formLabels[key]] = list.map(entity =>  entity &&  entity[fieldNameKey] ? entity &&   entity[fieldNameKey] : 'Blank').join(", ");   
        } 
    };

    const setSearchedParamsValues = (key, value) => {
      if(!value) {
        delete searchParamsMap[formLabels[key]];
        return;
      }
      searchParamsMap[formLabels[key]] = value ;         
    };


    const getSelectedValueList = (list, code, desc) => {
        return list && list.length > 0 ? list.map(obj => ({ 'code': obj && obj[code], 'desc': obj && obj[desc] === 'Blank'? '' : obj && obj[desc] })) : [];
    };

    const onClickSearch = () => {     
      let arr = [];
      let fieldsArray = [];
      for (const [key, value] of Object.entries(formState)) {  
       switch(key) {         
          case 'cityCodes':
            fieldsArray.push({"fieldName":"city_code","fieldValue": getSelectedValueList(value, 'display_value', 'description')}); 
            setSearchedParamsBreadcrumbs(key, value, 'display_value');
            break;
          case 'phaseCodes':
            fieldsArray.push({"fieldName":"phase_code","fieldValue": getSelectedValueList(value, 'display_value', 'description')});       
            setSearchedParamsBreadcrumbs(key, value, 'display_value');      
            break;
          case 'serviceCodes':
            fieldsArray.push({"fieldName":"service_code","fieldValue": getSelectedValueList(value, 'display_value', 'description')}); 
            setSearchedParamsBreadcrumbs(key, value, 'display_value');                      
            break;
          case 'taskCodes': 
            fieldsArray.push({"fieldName":"task_code","fieldValue": getSelectedValueList(value, 'display_value', 'description')}); 
            setSearchedParamsBreadcrumbs(key, value, 'display_value');                      
            break;
          case 'rateCodes': 
            fieldsArray.push({"fieldName":"rate_code","fieldValue": getSelectedValueList(value, 'display_value', 'description')}); 
            setSearchedParamsBreadcrumbs(key, value, 'display_value');                         
            break;
          case 'makeNoCharge': 
            fieldsArray.push({"fieldName":"make_no_charge","fieldValue": value ? value : []});   
            setSearchedParamsBreadcrumbs(key, value, null);                     
            break;
          case 'gte':   
          case 'predictive': 
            searchCriteria[key] = value && value.length > 0 ? value.map(item => item === 'Yes'? 1 : 0) : [];
            setSearchedParamsBreadcrumbs(key, value, null);
            break;
          case 'activityCodes': 
            fieldsArray.push({"fieldName":"activity_code","fieldValue": getSelectedValueList(value, 'display_value', 'description')}); 
            setSearchedParamsBreadcrumbs(key, value, 'display_value');                      
            break;
          case 'udf2Codes':
            fieldsArray.push({"fieldName":"udf2","fieldValue": getSelectedValueList(value, 'display_value', 'description')}); 
            setSearchedParamsBreadcrumbs(key, value, 'display_value');                      
            break;
          case 'udf3Codes':
            fieldsArray.push({"fieldName":"udf3","fieldValue": getSelectedValueList(value, 'display_value', 'description')}); 
            setSearchedParamsBreadcrumbs(key, value, 'display_value');                      
            break;
          case 'status':
            searchCriteria[key]= value ? value : []; 
            setSearchedParamsBreadcrumbs(key, value, null);
            break;  
          case 'narrative':     
          case 'fromDuration': 
          case 'toDuration': 
          case 'toAmount':
          case 'fromAmount':
          case 'quantity':
          case 'payee':
            searchCriteria[key]= value; 
            searchParamsMap[formLabels[key]] = value; 
            setSearchedParamsValues(key, value);
            break;
          case 'fromDate': 
          case 'toDate':
          let date = null; 
          if (value) {
            date = moment(new Date(value).toISOString()).format("YYYY-MM-DD HH:mm:ss");
            searchParamsMap[formLabels[key]] = moment(new Date(value).toISOString()).format("MM/DD/YYYY"); 
          } 
          searchCriteria[key]= date;                     
            break;
          case 'clients':
          searchCriteria[key]= getSelectedValueList(value, 'code', 'client_name');       
          setSearchedParamsBreadcrumbs(key, value, 'description');
            break;   
          case 'subClients':
            arr = [];           
            value && value.length > 0 && value.forEach(obj => IdKeyMap[key] && arr.push(obj[IdKeyMap[key]] === "Blank"? {"code":"","desc":""} : {"code":obj.subClientNumber,"desc":obj.subClientName}));  
            searchCriteria[key]= arr;       
            setSearchedParamsBreadcrumbs(key, value, 'subClientNumber');
            break; 
          case 'matters':            
          searchCriteria[key]= getSelectedValueList(value, 'code', 'description');       
          setSearchedParamsBreadcrumbs(key, value, 'desc');
            break; 
          default : 
           console.log("default ", key, value);           
        } 
        setAdvSearchToggle(false);      
      }
     
      setSearchParamsMap(searchParamsMap);
      searchCriteria['fields'] = fieldsArray;
      searchCriteria['attorneyId'] = delegateUserId;
      setSearchCriteriaRefresh(searchCriteria);
      onClickTracker("search");
      onAdvSearchFilterChange(searchCriteria, 1, false, searchParamsMap);     
    }

    const resetFormAndSearchParams = () => {  
      setSearchParamsMap({});
      setFormState(formInitialState);
    }

    const onClickReset = () => {  
      //setPageOffset(0);
      resetFormAndSearchParams();
      let params = { 
        clients: null,
        subClients: null,
        attorneyId: null,
        matters: null,
        fromDuration: null,
        toDuration: null,
        status: null,
        fromDate: null,
        toDate: null,
        narrative: "",
        sort : "",
        sort_type : "",
        fromAmount:"",
        toAmount:"",
        quantity:"",
        payee:""
      };
      setSearchCriteria(params);
      onAdvSearchFilterChange(null, 1, true, {});
    }  
   
    const getKeyByValue = (map, value) => {
      return Object.keys(map).find(key => formLabels[key] === value);
    }

   const onCloseSearchParams = (key, val) => {
     //remove from breadcrumbs    
     delete searchParamsMap[key];
     let keyFromLabelMap = getKeyByValue(formLabels, key);
     //remove from fields section
     let name = fieldNameMaps[keyFromLabelMap];
     let fields = searchCriteraRefresh.fields && searchCriteraRefresh.fields.filter(obj => obj.fieldName !== name);
     searchCriteraRefresh.fields = fields;
     //remove field from formState
     formState[getKeyByValue(formLabels, key)] = null;
     setFormState(formState);
     //remove from search criteria 
     searchCriteraRefresh[keyFromLabelMap] = null;
     onClickTracker("search");
     onAdvSearchFilterChange(searchCriteraRefresh, 1, false, searchParamsMap); 
   }

   
  /**
    *  makes an api call to get all the clients on basis of the search text and the pageOffsetKey   
    * @param {String} searchText - contains the user typed searchtext
    * @param {Number} pageOffsetKey - contains the pageOffsetKey  
    * @return It returns a promise that contains the success or failure object
  */
   const loadDropDownItems = (searchText, searchParams, datasetName, pageOffset = 0) => {
    return manageTime.getLazyLookupList(delegateUserId, searchText, searchParams, datasetName, pageOffset);
   };

  

  useEffect(() => {
    setLazyLoading(false);
    //console.log("isLazyApplied: ",isLazyApplied);
  }, [isLazyApplied]);

  const onFocusLazyHandler = () => {
    //console.log("onFocusLazyHandler ... ");
    setIsLazyApplied(true);
  }

  const onLazyLoad = (event, datasetName, listKey) => {
    
    let fieldChange = datasetName !== fieldName;
    setFieldName(datasetName);
    let finalList;    
    if (fieldChange) {
      setIsLazyApplied(true);
    }

    let searchParams;
    let clientsSelected = formState.clients ? formState.clients.map(obj => 
      ({ 'code': obj && obj.code, 'desc': obj && obj.client_name === 'Blank'? '' : obj.client_name })) : [];
    let arr = [];           
    formState.subClients && formState.subClients.length > 0 && formState.subClients.forEach(obj => arr.push(obj.subClientNumber === "Blank"? "" : obj.subClientNumber));
    let subClientsSelected =  arr;
    
    if(listKey === "clients") {
       finalList = clientsTotal;
       searchParams = {};       
    } else if(listKey === "subClients") {
       finalList = subClientsTotal;
       searchParams = { "clients":  clientsSelected };
    } else if(listKey === "matters") {
       finalList = mattersTotal;
      searchParams = { "clients": clientsSelected, "subClients": subClientsSelected };
    } 
    
    if (finalList && (event.last >= finalList.length) || fieldChange) {
      setLazyLoading(true);      
      loadDropDownItems(
        event.filter,
        searchParams,
        datasetName,
        datasetName !== fieldName ? 0 : _pageOffset
      )
        .then((res) => {     
          if(res[listKey].length === 0 && !fieldChange) {
            setIsLazyApplied(false);
          } else { 
          _pageOffset = res.nextPageOffset;
          if(fieldChange) {
            //setFreshDropDownList(listKey, finalList, res, event);     
            setDropDownList(listKey, null, res, event);
          } else { 
           setDropDownList(listKey, finalList, res, event);     
          }
          setIsLazyApplied(true);
         }
          setLazyLoading(false);
        })
        .catch((err) => {
          setLazyLoading(false);
        });
     }    
     else {
       setLazyLoading(true);
       setDropDownList(listKey, finalList, null, event);      
       setLazyLoading(false);     
    }
  };

  const setDropDownListOnFilter = (listKey, totalList, event) => {
    if (listKey === "clients") {      
      if(formState.clients && formState.clients.length > 0) {
        totalList = totalList.filter(obj => obj && obj.code && obj.code.trim() && !formState.clients.find(client => client.clientId === obj.clientId));
        totalList = [...formState.clients, ...totalList];
      } else { 
        totalList = totalList.filter(obj => obj && obj.code && obj.code.trim());
      }
      totalList = totalList.map(client => ({...client, "description": client.client_name ? client.client_name : client.code }) );
      setClientsTotal(totalList);     
      setClients(totalList);        
    } else if (listKey === "subClients") { 
      if(formState.subClients && formState.subClients.length > 0) {
        totalList = totalList.filter(obj => obj && obj.subClientNumber && obj.subClientNumber.trim() !== ""
        && !formState.subClients.find(sub => obj.subClientNumber === sub.subClientNumber && obj.subClientId ===sub.subClientId));
        totalList = [...formState.subClients, ...totalList];
      } else {     
        totalList = totalList.filter(obj => obj && obj.subClientNumber && obj.subClientNumber.trim() !== "");
      }
      setSubClientsTotal(totalList);           
      setSubClients(totalList);
    } else if (listKey === "matters") {
      if(formState.matters && formState.matters.length > 0) {
        totalList = totalList.filter(obj => obj && obj.code && !formState.matters.find(matter => matter.matterId === obj.matterId));
        totalList = [...formState.matters, ...totalList];
      } else {
      totalList = totalList.filter((obj) => obj && obj.code);
      }
      totalList = totalList.map( matter => ({...matter, "desc": matter.description ? matter.description : matter.code }) );
      setMattersTotal(totalList);
      setMatters(totalList);
    }
  }; 

  const setDropDownList = (listKey, finalList, res, event) => {     
    let totalList;
    if (listKey === "clients") {    
      totalList = res ? (finalList ? [...finalList, ...res.clients] : res.clients) : finalList;      
      if(formState.clients && formState.clients.length > 0) {
        totalList = totalList.filter(obj => obj && obj.code && obj.code.trim() && !formState.clients.find(client => client.clientId === obj.clientId));
        totalList = [...formState.clients, ...totalList];
      } else {
        totalList = totalList.filter(obj => obj && obj.code && obj.code.trim());
        totalList = [{clientId: '', code: '', client_name: 'Blank'}, ...totalList];
      }  
      totalList = totalList.map(client => ({...client, "description": client.client_name ? client.client_name : client.code }) );
      setClientsTotal(totalList);
      setClients(totalList); 
    } else if (listKey === "subClients") {     
      totalList = res ? (finalList && finalList.length > 0 ? [...finalList, ...res.subClients] : res.subClients) : finalList; 
      if(formState.subClients && formState.subClients.length > 0) {
        totalList = totalList.filter(obj => obj && obj.subClientNumber && obj.subClientNumber.trim() !== "" && obj.subClientNumber.trim() !== 'Blank' &&
        !formState.subClients.find(sub => obj.subClientNumber === sub.subClientNumber && obj.subClientId ===sub.subClientId));
        totalList = [...formState.subClients, ...totalList];
      } else {     
        totalList = totalList.filter(obj => obj && obj.subClientNumber && obj.subClientNumber.trim() !== "" && obj.subClientNumber.trim() !== 'Blank');
        totalList = [{clientId: '', subClientId: '', subClientNumber: 'Blank'}, ...totalList];
      }
     
      setSubClientsTotal(totalList);            
      setSubClients(totalList);      
    } else if (listKey === "matters") {      
      totalList = res ? (finalList? [...finalList, ...res.matters] : res.matters) : finalList;   
      if(formState.matters && formState.matters.length > 0) {
        totalList = totalList.filter(obj => obj && obj.code && !formState.matters.find(matter => matter.matterId && matter.matterId === obj.matterId) );
        totalList = [...formState.matters, ...totalList];
      } else {
      totalList = totalList.filter(obj => obj && obj.code);
      totalList = [{subClientId: '', matterId: '', code: '', description: 'Blank'}, ...totalList];
      }
      totalList = totalList.map( matter => ({...matter, "desc": matter.description ? matter.description : matter.code }) );
      setMattersTotal(totalList);      
      setMatters(totalList);
    }
  };
  
  const onFilterHandler = (event, datasetName, listKey) => {
    
    let fieldChange = datasetName !== fieldName;
    setFieldName(datasetName);
    if (fieldChange) {
      setIsLazyApplied(true);
    }

    let searchParams;
    let clientsSelected = formState.clients ? formState.clients.map(obj => 
      ({ 'code': obj && obj.code, 'desc': obj && obj.client_name === 'Blank'? '' : obj.client_name })) : [];
    let arr = [];           
    formState.subClients && formState.subClients.length > 0 && formState.subClients.forEach(obj => arr.push(obj.subClientNumber === "Blank"? "" : obj.subClientNumber));
    let subClientsSelected =  arr;
    
    if(listKey === "clients") {
       searchParams = {};       
    } else if(listKey === "subClients") {
       searchParams = { "clients":  clientsSelected };
    } else if(listKey === "matters") {
      searchParams = { "clients": clientsSelected, "subClients": subClientsSelected };
    } 
    
    setIsLazyApplied(true);
    setLazyLoading(true);
    loadDropDownItems(event.filter, searchParams, datasetName, 0).then(res => {
      _pageOffset = res.nextPageOffset;
      setDropDownListOnFilter(listKey, res[listKey], event);  
      setLazyLoading(false);        
    }).catch(err => {
      setLazyLoading(false);
    });
  }
  return (
      <>
        {advSearchToggle && (
          <div className="advanced-search-card">
            <span className="advanced-search-cross" onClick={() => setAdvSearchToggle(false)}>
              <button
                type="button"
                onClick={() => setAdvSearchToggle(false)}
                className="p-link"
                aria-label="Close">
                <span className="pi pi-times" onClick={onClose}></span>
              </button>
            </span>
            <div>
              <div className="p-fluid p-grid">
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                  {isLazyApplied ? <MultiSelectDropDown6
                     label={formLabels.clients}
                     value={formState.clients}
                     options={clients}
                     onChange={e => setFormValues("clients", e.value, 1)}
                     onFilter={e => onFilterHandler(e, "client", "clients")}
                     optionLabel="description"
                     filterBy="code,client_name"
                     itemTemplate={(options) => (
                       <OptionsData
                         code={options.code}
                         name={options.client_name}
                       />
                     )}
                     virtualScrollerOptions={{
                      lazy: true,
                      onLazyLoad: e => onLazyLoad(e, "client", "clients"),
                      itemSize: 20,// clientsTotal.length === 0 ? 20 : clientsTotal.length,
                      showLoader: true,
                      loading: lazyLoading,
                      delay: 300    
                    }}
                   />:
                  <MultiSelectDropDown6
                     label={formLabels.clients}
                     value={formState.clients}
                     options={clients}
                     onChange={e => setFormValues("clients", e.value, 1)}
                     onFocus= {onFocusLazyHandler}
                     onFilter={e => onFilterHandler(e, "client", "clients**")}
                     optionLabel="description"
                     filterBy="code,client_name"
                     itemTemplate={(options) => (
                       <OptionsData
                         code={options.code}
                         name={options.client_name}
                       />
                     )}                                         
                   />}
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">                  
                     {isLazyApplied ? <MultiSelectDropDown6
                      label={formLabels.subClients}
                      value={formState.subClients}
                      options={subClients}
                      onChange={(e) => setFormValues("subClients", e.value, 1)}
                      onFilter={e => onFilterHandler(e, "sub", "subClients")}
                      optionLabel="subClientNumber"
                      filterBy="subClientNumber"                      
                      virtualScrollerOptions={{
                        lazy: true,
                        onLazyLoad: e => onLazyLoad(e, "sub", "subClients"),
                        itemSize: 20,//subClientsTotal.length === 0 ? 20 : subClientsTotal.length,
                        showLoader: true,
                        loading: lazyLoading,
                        delay: 300                      
                      }}
                    /> : 
                    <MultiSelectDropDown6
                      label={formLabels.subClients}
                      value={formState.subClients}
                      options={subClients}
                      onChange={(e) => setFormValues("subClients", e.value, 1)}
                      onFilter={e => onFilterHandler(e, "sub", "subClients")}
                      onFocus= {onFocusLazyHandler}
                      optionLabel="subClientNumber"
                      filterBy="subClientNumber"
                    /> }
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                  {isLazyApplied ? <MultiSelectDropDown6
                      label={formLabels.matters}
                      value={formState.matters}
                      options={matters}
                      onChange={(e) => setFormValues("matters", e.value, 1)}
                      onFilter={e => onFilterHandler(e, "matter", "matters")}
                      optionLabel="desc"
                      filterBy="code,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.code}
                        name = {options.description}/>                            
                      )}
                      virtualScrollerOptions={{
                        lazy: true,
                        onLazyLoad: e => onLazyLoad(e, "matter", "matters"),
                        itemSize: 20,//mattersTotal.length === 0 ? 20 : mattersTotal.length,
                        showLoader: true,
                        loading: lazyLoading,
                        delay: 300                      
                      }}
                    /> :
                    <MultiSelectDropDown6
                      label={formLabels.matters}
                      value={formState.matters}
                      options={matters}
                      onChange={(e) => setFormValues("matters", e.value, 1)}
                      onFilter={e => onFilterHandler(e, "matter", "matters")}
                      onFocus= {onFocusLazyHandler}
                      optionLabel="desc"
                      filterBy="code,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.code}
                        name = {options.description}/>                            
                      )}
                    /> }
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.status}
                      value={formState.status}
                      options={ManageTimeReducer.status}
                      onChange={(e) => setFormValues("status", e.value, 1)}
                      optionLabel="value"                      
                      tabIndex="4"
                    />
                  </span>
                </div>

                <div className="p-field p-col-12 p-sm-6">
                  <span className="p-float-label">
                    <span className="p-float-label">
                      <span className="af-adv-sreach-label">{formLabels.narrative}</span>
                      <InputText
                        id="inputtext"
                        value={formState.narrative}
                        onChange={(e) => setFormValues("narrative", e.currentTarget.value, 1)}
                        autoComplete="off"
                        aria-label="narrative"
                       // tabIndex="5"
                      />
                    </span>
                  </span>
                </div>

                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <span className="p-float-label">
                      <CalendarInput
                        label={formLabels.fromDate}
                        maxDate={toDate}
                        value={formState.fromDate}
                        onChange={(e) =>
                          setFormValues("fromDate", new Date(e.value), 1)
                        }
                        onClearButtonClick={() => {
                          setFormValues("fromDate", null, 1);
                          delete searchParamsMap[formLabels.fromDate];
                        }}
                        data-testid="from-date-cal"
                      //  tabIndex="6"
                      />
                    </span>
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <span className="p-float-label">
                      <CalendarInput
                        label={formLabels.toDate}
                         minDate={fromDate}
                        value={formState.toDate}
                        onChange={(e) =>
                          setFormValues("toDate", new Date(e.value), 1)
                        }
                        onClearButtonClick={() => {
                          setFormValues("toDate", null, 1);
                          delete searchParamsMap[formLabels.toDate];
                        }}
                      //  tabIndex="7"
                      />
                    </span>
                  </span>
                </div>

                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.cityCodes}
                      value={formState.cityCodes}
                      options={ManageTimeReducer.cityCodes}
                      onChange={(e) => setFormValues("cityCodes", e.value, 1)}
                      onFocus={() => onFocus("city", "cityCodes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/>      
                       )}
                      tabIndex="8"
                      data-testid="city_id"
                    />
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.phaseCodes}
                      value={formState.phaseCodes}
                      options={ManageTimeReducer.phaseCodes}
                      onChange={(e) => setFormValues("phaseCodes", e.value, 1)}
                      onFocus={() => onFocus("phase", "phaseCodes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/>      
                        )}
                      tabIndex="9"
                    />
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <span className="p-float-label">
                      <span className="af-adv-sreach-label">
                        {formLabels.fromDuration}
                      </span>
                      <InputText
                       type = 'number'
                        id="inputtext"
                        value={formState.fromDuration}
                        onChange={(e) =>{
                           setFormValues("fromDuration", e.currentTarget.value, 1);                          
                        }}
                        autoComplete="off"
                        //tabIndex="10"
                      />
                    </span>
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <span className="p-float-label">
                      <span className="af-adv-sreach-label">
                        {formLabels.toDuration}
                      </span>
                      <InputText
                        type = 'number'
                        id="inputtext"
                        value={formState.toDuration}
                        onChange={(e) =>{                          
                            setFormValues("toDuration", e.currentTarget.value, 1)
                        }
                        }
                        autoComplete="off"
                       // tabIndex="11"
                      />
                    </span>
                  </span>
                </div>

                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.serviceCodes}
                      value={formState.serviceCodes}
                      options={ManageTimeReducer.serviceCodes}
                      onChange={(e) =>
                        setFormValues("serviceCodes", e.value, 1)
                      }
                      onFocus={() => onFocus("service", "serviceCodes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/>      
                       )}
                      tabIndex="12"
                    />
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.taskCodes}
                      value={formState.taskCodes}
                      options={ManageTimeReducer.taskCodes}
                      onChange={(e) => setFormValues("taskCodes", e.value, 1)}
                      onFocus={() => onFocus("task", "taskCodes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/> 
                      )}
                      tabIndex="13"
                    />
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.rateCodes}
                      value={formState.rateCodes}
                      options={ManageTimeReducer.rateCodes}
                      onChange={(e) => setFormValues("rateCodes", e.value, 1)}
                      onFocus={() => onFocus("rate", "rateCodes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/>                        
                      )}
                      tabIndex="14"
                    />
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.makeNoCharge}
                      value={formState.makeNoCharge}
                      options={ManageTimeReducer.makeNoCharge}
                      onChange={(e) =>
                        setFormValues("makeNoCharge", e.value, 1)
                      }
                      optionLabel="value"
                      tabIndex="15"
                    />
                  </span>
                </div>

                <div className="p-field-nested p-grid p-col-12 p-sm-3">                  
                      <div className="p-col-6 p-sm-6" style={{ paddingTop: '0px'}}>
                        <span className="p-float-label-nested">
                          <MultiSelectDropDown
                            label={formLabels.predictive}
                            value={formState.predictive}
                            options={ManageTimeReducer.predictive}
                            onChange={(e) => setFormValues("predictive", e.value, 1)}
                            optionLabel="value"
                            tabIndex="16"
                          />
                        </span>
                      </div>
                      <div className="p-col-6 p-sm-6" style={{ paddingTop: '0px'}}>
                        <span className="p-float-label-nested">
                        {<MultiSelectDropDown
                            label={formLabels.gte}
                            value={formState.gte}
                            options={ManageTimeReducer.gte}
                            onChange={(e) => setFormValues("gte", e.value, 1)}
                            optionLabel="value"
                            tabIndex="16"
                          />}
                        </span>
                      </div>                  
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={fieldLabel.activityCode}
                      value={formState.activityCodes}
                      options={ManageTimeReducer.activityCodes}
                      onChange={(e) =>
                        setFormValues("activityCodes", e.value, 1)
                      }
                      onFocus={() => onFocus("activity", "activityCodes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/> 
                      )}
                      tabIndex="17"
                    />
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.udf2Codes}
                      value={formState.udf2Codes}
                      options={ManageTimeReducer.udf2Codes}
                      onChange={(e) => setFormValues("udf2Codes", e.value, 1)}
                      onFocus={() => onFocus("udf2", "udf2Codes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/> 
                      )}
                      tabIndex="18"
                    />
                  </span>
                </div>
                <div className="p-field p-col-12 p-sm-3">
                  <span className="p-float-label">
                    <MultiSelectDropDown
                      label={formLabels.udf3Codes}
                      value={formState.udf3Codes}
                      options={ManageTimeReducer.udf3Codes}
                      onChange={(e) => setFormValues("udf3Codes", e.value, 1)}
                      onFocus={() => onFocus("udf3", "udf3Codes")}
                      optionLabel="description"
                      filterBy="display_value,description"
                      itemTemplate={(options) => (
                        <OptionsData
                        code = {options.display_value}
                        name = {options.description}/> 
                      )}
                      tabIndex="19"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="date-field">
         {!advSearchToggle && <div className="p-grid af-adv-search-param">
            <div className="p-col-1">{jsUtils.getLiteralValue("SEARCH_PARAMETER")}:</div>
            <div className="p-col-8">
            {Object.keys(searchParamsMap).map(function (key, val) {
                return (
                  <div className="af-search-key-value" key={`${key}_param`}>
                    <span className="af-search-key" key={key}>
                      {" "}
                    {/* {key.replace(val, '')}{" "} */}
                    {key}{" "}
                    </span>
                    : <span className="af-search-value"> {searchParamsMap[key]} </span>
                    <span
                      className="pi pi-times"
                      data-testid="close-search-params"
                      onClick={() => {
                        onCloseSearchParams(key, val);
                      }}></span>
                  </div>
                );
              })}
            </div>
            <div className="p-col-2" style={{ padding: '0.8rem 0.2rem', textAlign: 'right'}}>
               {entriesInfo}
            </div>
            <div className="p-col-1">
              <ButtonComponent
                label={jsUtils.getLiteralValue("SEARCH")}
                className="advanced-search-button"
                onButtonClick={() => setAdvSearchToggle(true)}
                disabled={false}
                downArrow={true}
                visible={!advSearchToggle}
              />
            </div>           
          </div>}
          {<div className="p-grid af-adv-search-param">           
            <div className="p-grid af-adv-search-param"> 
            { advSearchToggle && <><div className="p-col-7"></div><div className="p-col-2" style={{ paddingTop: '1.5rem', textAlign: 'right'}}>
            {entriesInfo}
            </div></>}
              <div className="p-col-1">
                <ButtonComponent
                  label={jsUtils.getLiteralValue("RESET")}
                  className="reset-button"
                  onButtonClick={() => { onClickReset(); onClickTracker("reset"); }}
                  disabled={resetDisable}
                  downArrow={false}
                  visible={advSearchToggle}
                />
              </div>
              <div className="p-col-2">
                <ButtonComponent
                  label={jsUtils.getLiteralValue("SEARCH")}
                  className="search-button"
                  onButtonClick={onClickSearch}
                  disabled={searchDisable}
                  downArrow={false}
                  visible={advSearchToggle}
                />
              </div>
            </div>
          </div>}
        </div>
      </>
    );
}

export default AdvancedSearch;
