import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React from "react";
import "./DialogWithTableSelection.scss"

function DialogWithTableSelection({ 
  visible,
  setVisible, 
  onOkay,
  columnData,
  rowData,
  selectedListData,
  setSelectedListData,
  onCancel,
  labelYes,
  labelNo,
  message,
  header,
  selectionMode,
  dataKey
  }){

    const renderFooter = () => {
        return (
          <div>
            <Button
              label={labelNo}
              icon="pi pi-times"
              onClick={()=>{
              onCancel()
              }}
              className="p-button-text"
            />
            <Button
              label={labelYes}
              icon="pi pi-check"

              onClick={()=>{
                selectedListData && onOkay();
              }}
              className={selectedListData ? "p-okay-button": "p-okay-button button-disable"}
            />
          </div>
        );
      };
    return <div className="af-parentheader">
      <div className="af-confirmation">
      <div className="af-confirmation-dialog">
        <Dialog
          closable={false}
          header={header}
          visible={visible}
          modal
          footer={renderFooter()}
          onHide={()=>setVisible(false)}>
          <div className="combine-list">
            <span>{message}</span>
              <DataTable value={rowData} selection={selectedListData} onSelectionChange={e => {setSelectedListData(e.value)}} dataKey={dataKey}>
                    <Column selectionMode={selectionMode} headerStyle={{width: '3em'}}></Column>
                    {
                      columnData.map(column=><Column field={column.field} header={column.header}></Column>)
                    
                    }
              </DataTable>
          </div>
        </Dialog>
      </div>
    </div>
    </div>
}
export default DialogWithTableSelection;

