import React, { useEffect } from 'react';
import jsUtils from '../../utils/jsUtils';

const ErrorPage = ({ onErrorPageHandler }) => {
    useEffect(() => {
        document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_");
        onErrorPageHandler(true);
        return (() => {
            onErrorPageHandler(false);
        });
    },[]);

    return (
        <div style={{ width: '100%', height: '100%', minHeight: '300px', padding: "10px 10px 0 20px", display: "flex", justifyContent: 'center', alignItems: 'center' } }>
            <span>{jsUtils.getLiteralValue("YOU_ARE_NOT_AUTHORISED_TO_ACCESS_THIS_PAGE")}</span>
        </div>
    );
};

export default ErrorPage;

