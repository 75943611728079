import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../TimeManagement/TimeManagement.scss";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../common/BreadCrumbComp/BreadCrumbComp.scss";
import { useHistory } from "react-router-dom";
import UsersTable from "../settings/Access Management/user/UsersTable";
import RolesTable from "../settings/Access Management/Roles/RolesTable";
import { setSelectedTimeKeeperFeature } from "../../actions/TimeManagementAction";
import { HELP_LINKS } from "../../config/vars";
import { helpPageUrl } from "../../config";
import Spinner from "../common/Spinner/Spinner";
import jsUtils from "../../utils/jsUtils";
import { setBreadCrumb, setPageHelpLink } from "../../actions/LandingPageAction";
import ErrorPage from "../common/ErrorPage";

function AccessManagement(props) {
  /** set tooltips from storage on screen load
   * @param literals -variale to store the array of literals
   */
  let history=useHistory()
  const itemdata = useSelector((state) => state.LandingPageReducer.items);
 
  useEffect(() => {
    document.title = jsUtils.getLiteralValue(pageTitle);
    
    dispatch(setBreadCrumb(["MG-Settings", "MG-Settings-MD-Access"]))
    dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.USERS}`))
  }, []);

  useEffect(() => {
    const moduleName = itemdata[0] ?.landingPageData?.moduleGroups?.find((groupObj)=> groupObj.moduleGroupName ==="MG-Settings")?.modules.find((moduleObj)=>moduleObj.moduleName === "MG-Settings-MD-Access")
      let isUsers=moduleName?.menus?.find((item)=>item.menuName==="MG-Settings-MD-Access-MN-Users");
      let isRoles=moduleName?.menus?.find((item)=>item.menuName==="MG-Settings-MD-Access-MN-Roles");
      // let isEthicalWalls=moduleName?.menus?.find((item)=>item.menuName==="MG-Settings-MD-Access-MN-EthicalWalls");
    switch ( props?.match?.params?.MODE?.toLowerCase().trim())
    
    {
      // case "ethicalwalls":
      //   if(isEthicalWalls){
      //     dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.ETHICAL_WALLS}`))
      //     setpageTitle("SurePoint - Ethical Walls");
      //     history.push(isEthicalWalls.url)
      //   }
      //   else{
      //     history.push("/System/unauthorized")
      //   }
      //  break;
       
       case "users":
        if(isUsers){
       dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.USERS}`))
       setpageTitle("ATTORNEYFLOW_USERS");
       history.push(isUsers.url) 
      }
        else{
          history.push("/System/unauthorized")
        }
       break;

       case "roles":
        if(isRoles){
          dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.ROLES}`))
          setpageTitle("ATTORNEYFLOW_ROLES");
       history.push(isRoles.url) 
      }
        else{
          history.push("/System/unauthorized")
        }
       break;
        case "unauthorized":
          setpageTitle(`${jsUtils.getLiteralValue("ATTORNEYFLOW_")}`);
          break;
       default:
        if(isUsers){
          dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.USERS}`))
          setpageTitle("ATTORNEYFLOW_USERS");
          history.push(isUsers.url) 
        }
        
        // else{
        //   if(isEthicalWalls){
        //     dispatch(setPageHelpLink(`${helpPageUrl}${HELP_LINKS.RESTRICTED_WORDS}`))
        //     setpageTitle("SurePoint - Ethical Walls");
        //     history.push(isEthicalWalls.url)
        //   }
        //   else{
        //     history.push("/Access")
        //   }
        // }
         
      }
  }, [props?.match?.params?.MODE,itemdata])


  const dispatch = useDispatch();
  const loadingClient = useSelector(
    (state) => state.TimeManagementReducer.loading
  );
  const loadingTimeKeeper = useSelector(
    (state) => state.TimeManagementReducer.loadingTimeKeeper
  );
  const error = useSelector((state) => state.TimeManagementReducer.error);
  const [signingOut] = useState(false);
  const [pageTitle, setpageTitle] = useState("ATTORNEYFLOW_USERS");

  useEffect(() => {
    document.title = jsUtils.getLiteralValue(pageTitle);
  }, [pageTitle]);


  
  useEffect(() => {
    const moduleName = itemdata[0] ?.landingPageData?.moduleGroups?.find((groupObj)=> groupObj.moduleGroupName ==="MG-Settings")?.modules.find((moduleObj)=>moduleObj.moduleName === "MG-Settings-MD-Access")
      
    let featureNameList=[];
    let featureIdList=[];
  if (props?.match?.params?.MODE?.toLowerCase().trim() === "users"){
    let menu = moduleName?.menus?.find((item)=> item.menuName ==="MG-Settings-MD-Access-MN-Users");
    let menuFeatures = menu?.features;
    if(menuFeatures) {
      menuFeatures.forEach((featureItem)=>{
      featureNameList.push(featureItem.featureName);
      featureIdList.push(featureItem.featureId)
    })
   }
  }
    // else if (props?.match?.params?.MODE?.toLowerCase().trim() === "ethicalwalls"){
    //   let menu = moduleName?.menus?.find((item)=> item.menuName ==="MG-Settings-MD-Access-MN-EthicalWalls");
    //   let menuFeatures = menu?.features;
    //   if(menuFeatures) {
    //     menuFeatures.forEach((featureItem)=>{
    //     featureNameList.push(featureItem.featureName);
    //     featureIdList.push(featureItem.featureId)
    //   })
    //  }
    // }
  
    dispatch(setSelectedTimeKeeperFeature(
      featureNameList,
      featureIdList
    ))
      
    }, [itemdata , props?.match?.params?.MODE]);

  if (signingOut) {
    return (
      <div className="spinner-popup">
        <Spinner />
      </div>
    );
  } else {
    return (
      <>
        {loadingClient === false &&
          loadingTimeKeeper === false &&
          error === "" ? (
            <div className="p-tabview p-component">
            {  props?.match?.params?.MODE?.toLowerCase() === "roles" ? <RolesTable pageTitle={pageTitle}/>
            // :  props?.match?.params?.MODE?.toLowerCase() === "ethicalwalls" ? <EthicalWallTable pageTitle={pageTitle} />
            :  props?.match?.params?.MODE?.toLowerCase() === "users" ? <UsersTable pageTitle={pageTitle} /> 
            : <div><ErrorPage onErrorPageHandler={props.onErrorPageHandler}/></div>
            }
            </div>
        ) : (
          <div>{jsUtils.getLiteralValue("NETWORK_ERROR")}</div>
        )}
      </>
    );
  }
}
export default AccessManagement;
