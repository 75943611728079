import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import Spinner from "../../../common/Spinner/Spinner";
import "./EditRoles.scss";
import moment from "moment";
import { userApi } from "../../../../services/userapis";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import storage from "./../../../../utils/storage";
import { HELP_LINKS, TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../../config/vars";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../../../actions/AppAction";
import _ from "lodash";
import { helpPageUrl,FEATURES } from "../../../../config";
import { refreshStoreOnError } from "../../../../actions/AppAction";
import jsUtils from "../../../../utils/jsUtils";
import { TabView, TabPanel } from 'primereact/tabview';

function EditRoles(props) {
  const [TimeDialog, setTimeDialog] = useState(false);
  const [roleStartDate, setRoleStartDate] = useState(null);
  const [roleEndDate, setRoleEndDate] = useState(null);
  const [rulesStartDate, setRulesStartDate] = useState(null);
  const [rulesEndDate, setRulesEndDate] = useState(null);
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [rolesGrid, setRolesGrid] = useState([]);
  const [dashboardGrid, setDashboardGrid] = useState([]);
  const [updatedRulesData, setUpdatedRulesData] = useState([]);
  const [updatedDashboardsData, setUpdatedDashboardsData] = useState([]);
  const [cloneRole, setCloneRole] = useState([]);

  const [suite, setSuite] = useState([]);
  const [dashboards, setDashboards] = useState([]);
  const [selectedDashboardSDate, setSelectedDashboardSDate] = useState(null);
  const [selectedDashboardEDate, setSelectedDashboardEDate] = useState(null);
  const [backupSuite, setBackupSuite] = useState([]);
  const [module, setModule] = useState([]);
  const [backupModule, setBackupModule] = useState([]);
  const [feature, setFeature] = useState([]);
  const [backupFeature, setBackupFeature] = useState([]);
  const [access, setAccess] = useState([]);
  const [backupAccess, setBackupAccess] = useState([]);
  const [selectedSuite, setSelectedSuite] = useState();
  const [selectedModule, setSelectedModule] = useState();
  const [selectedFeature, setSelectedFeature] = useState();
  const [selectedAccess, setSelectedAccess] = useState();
  const [selectedSysMenuId, setSelectedSysMenuId] = useState();
  const [selectedSysPersonaMenuId, setSelectedSysPersonaMenuId] = useState();
  const [isOldRule, setIsOldRule] = useState(false);
  const [selectedDashboardId, setSelectedDashboardId] = useState();


  const [suiteLoading, setSuiteLoading] = useState(false);
  const [dashboardsLoading, setDashboardsLoading] = useState(false);
  const [modulesLoading, setModulesLoading] = useState(false);
  const [featureLoading, setFeatureLoading] = useState(false);

  const [addRoleStatus, setAddRoleStatus] = useState(true);
  const [addDashboardStatus, setAddDashboardStatus] = useState(false);

  const [sDateUStatus, setSDateUStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useState(true);
  const [oneRole, setOneRole] = useState(true);
  const [roleName, setRoleName] = useState("");
  const [hRoleSDate, sethRoleSDate] = useState(true);
  const [saveStatus, setSaveStatus] = useState(true);

  const [update, setUpdate] = useState(false);
  const [dashboardEditState, setDashboardEditState] = useState(null);
  const [editRule, setEditRule] = useState(false);
  const [editRuleDashboard,setEditRuleDashboard] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(0);
  const [selectedDashboardGridIndex, setSelectedDashboardGridIndex] = useState();
 

  const toast = useRef(null);
  const [error, setError] = useState("");

  const [changed, setChanged] = useState(false);
  const [closeConfirmation, setCloseConfirmation] = useState(false);

  const [reqType, setReqType] = useState("post");
  const [DBCurrentDate, setDBCurrentDate] = useState("");
  const dialogFuncMapClose = {
    closeConfirmation: setCloseConfirmation,
  };

  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_EDIT]: [TOOLTIPS_VALUES.EDIT],
    [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: [TOOLTIPS_VALUES.SELECT_DATE],
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP]

  })

  const [traverseState, setTraverseState] = useState(null);
  const [earliestStartDate, setEarliestStartDate] = useState(null);
  const [latestEndDate, setLatestEndDate] = useState(null);
  const [invalidRolesStartDate, setInvalidRolesStartDate] = useState(false);
  const [invalidRolesEndDate, setInvalidRolesEndDate] = useState(false);
  const [dashboardGridStartDatesDoNotComply, setDashboardGridStartDatesDoNotComply] = useState(false);
  const [dashboardGridEndDatesDoNotComply, setDashboardGridEndDatesDoNotComply] = useState(false);
  const [roleStartDateCanged, setRoleStartDateCanged] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);
  /** set tooltips from storage on screen load
   * @param literals -variale to store the array of literals
   */
   
 useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_EDIT]: literals[TOOLTIPS_KEYS.TOOLTIP_EDIT],
        [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: literals[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE],
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP]

      })
      setCodeFromAPI(literals);
    }
    document.title = jsUtils.getLiteralValue(props.pageTitle)
  }, []);

  const [minEndDate, setMinEndDate] = useState(null);
  const [maxStartDate, setMaxStartDate] = useState(null);
  const [minStartDate, setMinStartDate] = useState(null);

  const [ruleMaxEndDate, setRuleMaxEndDate] = useState(null);
  const [ruleMinEndDate, setRuleMinEndDate] = useState(null);
  const [ruleMaxStartDate, setRuleMaxStartDate] = useState(null);
  const [ruleMinStartDate, setRuleMinStartDate] = useState(null);

  const [dashboardMaxEndDate, setDashboardMaxEndDate] = useState(null);
  const [dashboardMinEndDate, setDashboardMinEndDate] = useState(null);
  const [dashboardMaxStartDate, setDashboardMaxStartDate] = useState(null);
  const [dashboardMinStartDate, setDashboardMinStartDate] = useState(null);

  const [dashStartDateUStatus,setDashStartDateUStatus] = useState(null);

  useEffect(() => {
    // On load of screen set all the validatons as users timezone current date
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setDBCurrentDate(currentDate);
    setMinEndDate(new Date(currentDate));
    setRuleMinEndDate(new Date(currentDate));
    setDashboardMinEndDate(new Date(currentDate));
      setMinStartDate(new Date(currentDate));
      setRuleMinStartDate(new Date(currentDate));
      setDashboardMinStartDate(new Date(currentDate));
    setEarliestStartDate(new Date(currentDate))
  }, [])

  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };


  useEffect(() => {
    evaluateEditedRoleStatus()
  }, [selectedDashboardEDate,selectedDashboardSDate,selectedDashboardId])

  useEffect(()=>{
    if(props.action === "Edit"){
      setMaxStartDate(new Date(earliestStartDate))
    }
  },[earliestStartDate]);

  // useEffect(()=>{
  //   let currentDatePlusOne = jsUtils.getDatePlusMinus(DBCurrentDate,1);
  //   // let _today =  moment(DBCurrentDate,"YYYY-MM-DD").format("YYYY-MM-DD"); 
  //   // let _roleStartDate = moment(roleStartDate,"YYYY-MM-DD").format("YYYY-MM-DD");
  //   // let isRoleStartDateSameAsCurrentDate = moment(_roleStartDate).isSame(today);

  //   if(props.action === "Edit" ){
  //     setMinStartDate(new Date(DBCurrentDate))
  //   }
  //   else{
  //     setMinStartDate(new Date(currentDatePlusOne));
  //   }
  // },[DBCurrentDate])


  /**
   * Date validation On Change of Roles Start Date
   */

  useEffect(()=>{
    getMinEndDate();
  },[roleStartDate,latestEndDate])

  const getMinEndDate = () =>{
    
    let _roleStartDatePlusOne = jsUtils.getDatePlusMinus(roleStartDate,1);

    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    let today = moment(currentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
    let _roleStartDatePulsOneFormat = moment(_roleStartDatePlusOne, "YYYY-MM-DD").format("YYYY-MM-DD");
    let isTodayGreater = moment(today).isSameOrAfter(_roleStartDatePulsOneFormat);

    let _roleStartDate = moment(roleStartDate,"YYYY-MM-DD").format("YYYY-MM-DD");
    let isRoleStartDateSameAsCurrentDate = moment(_roleStartDate).isSame(today);

    let _latestEndDate = moment(new Date(latestEndDate),"YYYY-MM-DD").format("YYYY-MM-DD");
   
    if (isTodayGreater || !isValidDate(roleStartDate) || (props.action ==="Edit" && isRoleStartDateSameAsCurrentDate)) {
      let letIfLatestEndDateGreateThanCurrentDate = moment(_latestEndDate).isAfter(today);
      if(letIfLatestEndDateGreateThanCurrentDate){
        setMinEndDate(new Date(latestEndDate));
      }
      else{
        setMinEndDate(new Date(currentDate));
      }
    } else {
      let letIfLatestEndDateGreateThanRoleStartDatePlusOne = moment(_latestEndDate).isAfter(_roleStartDatePulsOneFormat);
      if(letIfLatestEndDateGreateThanRoleStartDatePlusOne){
        setMinEndDate(new Date(latestEndDate));
      }
      else{
        if(props.action==="Edit"){
          setMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_roleStartDate)));
        }
        else{
          setMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_roleStartDatePlusOne)));
        }
      }
    }
  }

  /**
   * Date validation On Change of Roles End Date
   */

  useEffect(()=>{
    getMaxStartDate();
    getRuleMaxEndDate();
    getDashboardMaxEndDate();
    CalenderValidationBasedonOnRolesAndRulesDates(rolesGrid,null);
  },[roleEndDate,earliestStartDate]);


  const getMaxStartDate = () =>{
    if(isValidDate(roleEndDate)){
      let roleEndDateMinusOne = jsUtils.getDatePlusMinus(roleEndDate,-1);
      setMaxStartDate(roleEndDateMinusOne);
    }
  }

  const getRuleMaxEndDate=()=>{
    setRuleMaxEndDate(roleEndDate)
  }

  const getDashboardMaxEndDate=()=>{
    setDashboardMaxEndDate(roleEndDate)
  }

  /**
   * Date validation On Change of Rules Start Date
   */

  useEffect(()=>{
    getRuleMinEndDate(rulesStartDate,update,editRule);
  },[rulesStartDate,update,editRule])
  

  const getRuleMinEndDate = (_rulesStartDateparam,_update,_editRule) => {
    if(isValidDate(_rulesStartDateparam)){
      let _rulesStartDatePlusOne = jsUtils.getDatePlusMinus(_rulesStartDateparam,1);
      _rulesStartDatePlusOne = moment(_rulesStartDatePlusOne, "YYYY-MM-DD").format("YYYY-MM-DD");
      let _today = moment(DBCurrentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
      let todayIsGreater = moment(_today).isSameOrAfter(_rulesStartDatePlusOne);

      let _rulesStartDate =  moment(_rulesStartDateparam,"YYYY-MM-DD").format("YYYY-MM-DD");
      // let isRuleStartDateSameAsCurrentDate = moment(_rulesStartDate).isSame(_today);
      if(todayIsGreater){
        setRuleMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_today)));
      }
      else{
        // isRuleStartDateSameAsCurrentDate &&
        if(_update && _editRule){
          setRuleMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_rulesStartDate)));
        }
        else{
          setRuleMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_rulesStartDatePlusOne)));
        }
      }
    }
  }

    /**
   * Date validation On Change of Dashboard Start Date
   */

     useEffect(()=>{
      getDashboardMinEndDate(selectedDashboardSDate,dashboardEditState,editRuleDashboard);
    },[selectedDashboardSDate,dashboardEditState,editRuleDashboard])
  
    const getDashboardMinEndDate = (_selectedDashboardSDate,_dashboardEditState,_editRuleDashboard) => {
      if(isValidDate(_selectedDashboardSDate)){
        let _selectedDashboardSDatePlusOne = jsUtils.getDatePlusMinus(_selectedDashboardSDate,1);
        _selectedDashboardSDatePlusOne = moment(_selectedDashboardSDatePlusOne, "YYYY-MM-DD").format("YYYY-MM-DD");
        let _today = moment(DBCurrentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        let todayIsGreater = moment(_today).isSameOrAfter(_selectedDashboardSDatePlusOne);
        if(todayIsGreater){
          setDashboardMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_today)));
        }
        else{
          if(_dashboardEditState==="update" && _editRuleDashboard){
            setDashboardMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_selectedDashboardSDate)));
          }
          else{
            setDashboardMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_selectedDashboardSDatePlusOne)));
          }
          
        }
      }
    }

  /**
   * Date validation On Change of Rules End Date
   */
  useEffect(()=>{
    getRuleMaxStartDate();
  },[rulesEndDate])

  const getRuleMaxStartDate = () => {
    if(isValidDate(rulesEndDate)){
      let dateMinusOne = jsUtils.getDatePlusMinus(rulesEndDate,-1);
      setRuleMaxStartDate(dateMinusOne);
    }
  }


  /**
   * Date validation On Change of Dashboard End Date
   */
   useEffect(()=>{
    getDashboardMaxStartDate();
  },[selectedDashboardEDate])

  const getDashboardMaxStartDate = () => {
    if(isValidDate(selectedDashboardEDate)){
      let dateMinusOne = jsUtils.getDatePlusMinus(selectedDashboardEDate,-1);
      setDashboardMaxStartDate(dateMinusOne);
    }
  }

  /**
   * Date validation On Change of Rules Grid Dates
   */
  useEffect(()=>{
    CalenderValidationBasedonOnRolesAndRulesDates(rolesGrid,dashboardGrid)
  },[rolesGrid,latestEndDate,earliestStartDate,dashboardGrid])


  const CalenderValidationBasedonOnRolesAndRulesDates =  (_rolesGrid, _dashboardGrid) => {
    var futureDateForValidation = moment().add(999999, 'day');
    let largestRuleEndDate = moment().format("YYYY-MM-DD");
    let leastRuleStartDate = moment(futureDateForValidation, "YYYY-MM-DD").format("YYYY-MM-DD"); 

    let allEndDateAreEmpty = true;
    if(_rolesGrid){
      _rolesGrid.forEach((role) => {
        const ruleStartDate = moment(role.From, "YYYY-MM-DD").format("YYYY-MM-DD");
        const ruleEndDate = moment(role.To, "YYYY-MM-DD").format("YYYY-MM-DD");
        const ruleStartDateIsLess = moment(leastRuleStartDate).isAfter(ruleStartDate);
        if(ruleStartDateIsLess){
          leastRuleStartDate = ruleStartDate;
        }
        let ruleEndDateIsGreater;
        if(isValidDate(ruleEndDate)){
          allEndDateAreEmpty = false;
          ruleEndDateIsGreater =  moment(ruleEndDate).isAfter(largestRuleEndDate);
          if(ruleEndDateIsGreater){
            largestRuleEndDate = ruleEndDate;
          }
        }
      })
    }

     if(_dashboardGrid) {
      _dashboardGrid.forEach((dashboard) => {
        const dashboardStartDate = moment(dashboard.effective_start_date, "MM/DD/YYYY").format("YYYY-MM-DD");
        const dashboardEndDate = moment(dashboard.effective_end_date, "MM/DD/YYYY").format("YYYY-MM-DD");
        const dashboardStartDateIsLess = moment(leastRuleStartDate).isAfter(dashboardStartDate);
        if(dashboardStartDateIsLess){
          leastRuleStartDate = dashboardStartDate;
        }
        let dashboardEndDateIsGreater;
        if(isValidDate(dashboardEndDate)){
          allEndDateAreEmpty = false;
          dashboardEndDateIsGreater =  moment(dashboardEndDate).isAfter(largestRuleEndDate);
          if(dashboardEndDateIsGreater){
            largestRuleEndDate = dashboardEndDate;
          }
        }
      });
      }

    if(allEndDateAreEmpty){
      if(isValidDate(roleStartDate)){
        let _RoleStartDatePlusOne = jsUtils.getDatePlusMinus(roleStartDate,1);
        let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
        let currentDateIsGreater =  moment(currentDate).isSameOrAfter(_RoleStartDatePlusOne);
        
        let today = moment(currentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        let _roleStartDateIfAllEndDateEmpty = moment(roleStartDate,"YYYY-MM-DD").format("YYYY-MM-DD");
        let isRoleStartDateSameAsCurrentDate = moment(_roleStartDateIfAllEndDateEmpty).isSame(today);

        if(currentDateIsGreater || (props.action ==="Edit" && isRoleStartDateSameAsCurrentDate)){
          let maxDate=jsUtils.getMaxDate(currentDate,latestEndDate)
          setMinEndDate(new Date(maxDate));
        }
        else{
          if(props.action === "Edit"){
            let maxDate=jsUtils.getMaxDate(_roleStartDateIfAllEndDateEmpty,latestEndDate)
            setMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(maxDate)));
          }
          else{
            let maxDate=jsUtils.getMaxDate(_RoleStartDatePlusOne,latestEndDate)
            setMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(maxDate)));
          }
         
        }
      }
      else{
        let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
        let _datePlusOne = jsUtils.getDatePlusMinus(currentDate,1);
        setMinEndDate(new Date(_datePlusOne));
      }
    }
    else{
      let _latestEndDate = moment(new Date(latestEndDate),"YYYY-MM-DD").format("YYYY-MM-DD");
      let _largestRuleEndDate = moment(largestRuleEndDate,"YYYY-MM-DD").format("YYYY-MM-DD");
      let letIfLatestEndDateGreateThanLargestRuleEndDate = moment(_latestEndDate).isAfter(_largestRuleEndDate);
      if(letIfLatestEndDateGreateThanLargestRuleEndDate){
        setMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(_latestEndDate)));
      }
      else{
        setMinEndDate(new Date(jsUtils.getTimeZoneFormatDate(largestRuleEndDate)));
      }
    }

    if(props.action === "Edit"){
      if(isValidDate(new Date(earliestStartDate))){
        let _leastRuleStartDate = moment(new Date(leastRuleStartDate),"YYYY-MM-DD").format("YYYY-MM-DD");
        let _earliStartDate = moment(new Date(earliestStartDate), "YYYY-MM-DD").format("YYYY-MM-DD");
       
        let isEarliStartDateIsLess= moment(_leastRuleStartDate).isAfter(_earliStartDate);
    
        if(isEarliStartDateIsLess){
          setMaxStartDate(new Date(earliestStartDate));
        }
        else{
          setMaxStartDate(new Date(jsUtils.getTimeZoneFormatDate(leastRuleStartDate)))
        }
      }
      else{
        setMaxStartDate(new Date(jsUtils.getTimeZoneFormatDate(leastRuleStartDate)))
      } 
    }    
  }

  const renderFooterClose = () => {
    return (
      <div>
        <Button
          label={getCode("LEAVE")}
          onClick={() => {
            if (traverseState) {
              setCloseConfirmation(false);
              setChanged(false)
              traverseState === "Down" ? traverseDown() : traverseUp()
            }
            else {
              props.closed();
              setTimeDialog(false);
            }
          }}
          className="p-button-text"
          data-testid="leave"
        />
        <Button
          label={getCode("STAY")}
          onClick={() => {
            setTraverseState(null);
            setChanged(true)
            setCloseConfirmation(false);
          }}
          autoFocus
          data-testid="stay"
        />
      </div>
    );
  };

  const onHideClose = (name) => {
    dialogFuncMapClose[`${name}`](false);
  };

  const hideDialog = () => {
    if (changed) {
      setTraverseState(null);
      setCloseConfirmation(true);
    } else {
      props.closed();
      setTimeDialog(false);

    }
  };

  const dispatch = useDispatch();


  useEffect(() => {
    setTimeDialog(props.show);
  }, [props.show]);

  useEffect(() => {
    if (props.action === "Edit" || props.action === "Clone") {
      getRoleDetails(props.personaId);
    }
    getSuites();
    if(FEATURES?.IS_SISENSE_DASHBOARD_ENABLED){
      getDashboards();
    }
  }, [props.action, props.personaId]);


  /** 
 * @description Loads roles data and modify based on the edit, add and clone operation
 * @param {Number} personaId - Roles details based on the persona ID
 */
  const getRoleDetails = (personaId) => {
    if (props.action === "Edit") {
      setReqType("put");
    }
    if (props.action === "Add") {
      setReqType("post");
    }
    if (props.action === "Clone") {
      setSaveStatus(false);
    }
    setLoading(true);
    userApi
      .roleCardSort(personaId, "createdDate")
      .then((res) => {
        if (res && res.persona_details) {
          let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
          setRoleName(res.persona_details[0].name);          
          let earliest_start_date = res.persona_details[0].earliest_start_date;
          let valid_earliest_start_date = "";
          if (isValidDate(earliest_start_date)) {
            valid_earliest_start_date = earliest_start_date.substring(0, 10).split("-");
            valid_earliest_start_date = valid_earliest_start_date[1] + "/" + valid_earliest_start_date[2] + "/" + valid_earliest_start_date[0];
          }
          setEarliestStartDate(valid_earliest_start_date);

          if(isValidDate(res.persona_details[0].latest_end_date)){
            let latest_end_date = res.persona_details[0].latest_end_date;
            latest_end_date = latest_end_date.substring(0, 10).split("-");
            latest_end_date = latest_end_date[1] + "/" + latest_end_date[2] + "/" + latest_end_date[0];
            setLatestEndDate(latest_end_date);
          }
          let sDate = res.persona_details[0].effective_start_date;
          let stDate = "";
          if (isValidDate(sDate)) {
            stDate = sDate.substring(0, 10).split("-");
            stDate = stDate[1] + "/" + stDate[2] + "/" + stDate[0];
          }

          let eDate = res.persona_details[0].effective_end_date;
          let edDate = "";
          if (isValidDate(eDate)) {
            edDate = eDate.substring(0, 10).split("-");
            edDate = edDate[1] + "/" + edDate[2] + "/" + edDate[0];
          }

          sDate = isValidDate(sDate) ? moment(sDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "";
          eDate = isValidDate(eDate) ? moment(eDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "";

          let todayDate = moment(currentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
          let sDateF = moment(sDate, "YYYY-MM-DD").format("YYYY-MM-DD");
          let isTodayGreater = moment(todayDate).isAfter(sDateF);
          if (props.action === "Edit") {
            let editStDate = sDate.substring(0, 10).split("-");
            editStDate = editStDate[0] + "/" + editStDate[1] + "/" + editStDate[2];
            if(isTodayGreater){
               setRuleMinStartDate(new Date(jsUtils.getTimeZoneFormatDate(todayDate)));
               setDashboardMinStartDate(new Date(jsUtils.getTimeZoneFormatDate(todayDate)));
            }
            else{
              setRuleMinStartDate(new Date(editStDate));
              setDashboardMinStartDate(new Date(editStDate));
            }
          }
          if (props.action === "Clone") {
            let _startDate = sDate.substring(0, 10).split("-");
            _startDate = _startDate[0] + "/" + _startDate[1] + "/" + _startDate[2];
            if(isTodayGreater){
              setRoleStartDate(new Date(currentDate))
            }
            else{
              setRoleStartDate(new Date(_startDate));
            }
            setRoleEndDate();
          } else {
            isValidDate(sDate) && setRoleStartDate(new Date(stDate));
            isValidDate(eDate) && setRoleEndDate(new Date(edDate));
          }

          let formattedJson = { rules: [],dashboards:[] };
          let cloneJson = { rules: [],dashboards:[] };
          if(res.persona_details[0] && res.persona_details[0].rules) {
           res.persona_details[0].rules.forEach((rule) => {
            let fromD;
            let toD;
            if (props.action === "Clone") {
              let _startDate = sDate.substring(0, 10).split("-");
              _startDate = _startDate[0] + "/" + _startDate[1] + "/" + _startDate[2];
              if(isTodayGreater){
                fromD = new Date(currentDate);
              }
              else{
                fromD = new Date(_startDate);
              }
              toD = "";
            } else {
              fromD = isValidDate(rule.FromDate) ? moment(`${rule.FromDate.slice(0, 10)}`, "YYYY-MM-DD").format("YYYY-MM-DD") : "";
              toD = isValidDate(rule.ToDate) ? moment(`${rule.ToDate.slice(0, 10)}`, "YYYY-MM-DD").format("YYYY-MM-DD") : "";
            }
            let ruleItem = {
              Suite: rule.Suite,
              Module: rule.Module,
              Feature: rule.Feature,
              Access: rule.Access,
              From: fromD,
              To: toD,
              SysMenuId: rule.SysFeatureId,
              SysPersonaMenuId: rule.SysPersonaFeatureId,
              oldRule: true,
            };
            if (props.action === "Clone") {
              // Remove duplicate rules when copying the role (spc-5982)
              let ifDuplicateRule = _.find(formattedJson.rules,
                {
                  'Suite': rule.Suite,
                  'Module': rule.Module,
                  'Feature': rule.Feature,
                  'Access': rule.Access
                });
              if(!ifDuplicateRule){
                formattedJson.rules.push(ruleItem);
              }
            }
            else {
              formattedJson.rules.push(ruleItem);
            }


            if (props.action === "Clone") {
              let sDateStatus = isValidDate(fromD);
              let eDateStatus = isValidDate(toD);

              let start_date = sDateStatus
                ? moment(fromD, "YYYY-MM-DD").format("YYYY-MM-DD")
                : "";

              let end_date = eDateStatus
                ? moment(toD, "YYYY-MM-DD").format("YYYY-MM-DD")
                : "";

              let cloneItem = {
                sys_feature_id: rule.SysFeatureId,
                sys_access_permission: rule.Access,
                effective_start_date: start_date,
                effective_end_date: end_date,
                enabled: "y",
              };

              // Remove duplicate rules when copying the role (spc-5982)
              let ifDuplicateRule = _.find(cloneJson.rules,
                {
                  'sys_feature_id': rule.SysFeatureId,
                  'sys_access_permission': rule.Access,
                  'effective_start_date': start_date,
                  'effective_end_date': end_date,
                  'enabled': "y",
                });
                if(!ifDuplicateRule){
                  cloneJson.rules.push(cloneItem);
                }
            }
          });
        }  

          setRolesGrid(formattedJson.rules);
          setDashboardGrid(formattedJson.dashboards);
          let matchingRuleJson = { rule: [] };
          formattedJson.rules.forEach(() => {
            matchingRuleJson.rule.push(null);
          });
          setUpdatedRulesData(matchingRuleJson.rule);
          setCloneRole(cloneJson.rules);

          let dashboardList = res.persona_details[0]?.dashboards;
          if(dashboardList) {
            dashboardList.forEach((dashboard) => {
            let fromD;
            let toD;
            if (props.action === "Clone") {
              let _startDate = sDate.substring(0, 10).split("-");
              _startDate = _startDate[0] + "/" + _startDate[1] + "/" + _startDate[2];
              if(isTodayGreater){
                fromD = new Date(currentDate);
              }
              else{
                fromD = new Date(_startDate);
              }
              toD = "";
            } else {
              fromD = isValidDate(dashboard.effective_start_date) ? moment(`${dashboard.effective_start_date.slice(0, 10)}`, "YYYY-MM-DD").format("YYYY-MM-DD") : "";
              toD = isValidDate(dashboard.effective_end_date) ? moment(`${dashboard.effective_end_date.slice(0, 10)}`, "YYYY-MM-DD").format("YYYY-MM-DD") : "";
            }
            let dashboardItem = {
              ...dashboard,
              effective_start_date: fromD &&moment(fromD,"YYYY-MM-DD").format("MM/DD/YYYY"),
              effective_end_date: toD && moment(toD,"YYYY-MM-DD").format("MM/DD/YYYY"),
              oldRule: true,
            };
            
            if(props.action === "Clone"){
               // Remove duplicate dashboard when copying the role (spc-5982)
              let ifDuplicateDashboard = _.find(formattedJson.dashboards,
              {
                'module_name_key': dashboardItem.module_name_key
              });
              if(!ifDuplicateDashboard) {
                formattedJson.dashboards.push(dashboardItem)
              }
            }
            else{
              formattedJson.dashboards.push(dashboardItem)
            }
           
           
            if (props.action === "Clone") {
              let cloneItem = {
                sys_module_id: dashboard.sys_module_id,
                effective_start_date: moment(fromD,"YYYY-MM-DD").format("YYYY-MM-DD"),
                effective_end_date: toD ? moment(toD,"YYYY-MM-DD").format("YYYY-MM-DD") : "",
              };

              // Remove duplicate dashboard when copying the role (spc-5982)
              let ifDuplicateDashboard = _.find(cloneJson.dashboards,
              {
                'sys_module_id': cloneItem.sys_module_id
              });
              if(!ifDuplicateDashboard){
                cloneJson.dashboards.push(cloneItem);
              }
            }

          });
            }
          setDashboardGrid(formattedJson.dashboards);
          props.action === "Clone" && setUpdatedDashboardsData(cloneJson.dashboards)
        }
      })
      .catch(() => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /** 
  * @description Get all the available suits on page loads
  */
  const getSuites = () => {
    if (props.action === "Edit") {
      setReqType("put");
    }
    if (props.action === "Add") {
      setReqType("post");
    }
    setLoading(true);
    setSuiteLoading(true);
    userApi
      .getSuites()
      .then((res) => {
        let formattedJson = { suite: [] };
        if (res && res.suites) {
          res.suites.forEach((suiteitem) => {
            let suiteItemData = {
              Id: suiteitem.sys_module_group_id,
              Name: suiteitem.moduleGroupName,
              label: suiteitem.moduleGroupName,
              value: suiteitem.moduleGroupName,
            };
            formattedJson.suite.push(suiteItemData);
          });
          setSuite(formattedJson.suite);
          setBackupSuite(formattedJson.suite);
        }
      })
      .catch((err) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
      })
      .finally(() => {
        setSuiteLoading(false);
        setLoading(false);
      });
  };


  
  /** 
  * @description Get list of dashboards
  */
   const getDashboards = () => {
     if (props.action === "Edit") {
       setReqType("put");
      }
      if (props.action === "Add") {
        setReqType("post");
      }
      setLoading(true);
      setDashboardsLoading(true);
      userApi
      .dashboards()
      .then((res) => {
        let formattedJson = { dashboards: [] };
        if (res && res.personaData) {
          res.personaData.forEach((item) => {
            let dashboardsData = {
             ...item,
             value:item.sys_module_id,
             label:item.name,
             effective_start_date: moment(item.effective_start_date,"YYYY-MM-DD").format("MM/DD/YYYY"),
             effective_end_date: item.effective_end_date &&  moment(item.effective_end_date,"YYYY-MM-DD").format("MM/DD/YYYY"),
            };
            formattedJson.dashboards.push(dashboardsData);
          });
          setDashboards(formattedJson.dashboards);
        }
      })
      .catch(() => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
      })
      .finally(() => {
        setDashboardsLoading(false);
        setLoading(false);
      });
  };

  /** 
  * @description Get module based on the suite selection
  * @param {Array} suiteId - Send suite Id to get the modules
  */
  const getModules = (suiteId) => {
    setLoading(true);
    setModulesLoading(true);
    userApi
      .getModules(suiteId)
      .then((res) => {
        let formattedJson = { modules: [] };
        if (res && res.modules) {
          res.modules.forEach((moduleitem) => {
            let moduleItemData = {
              Id: moduleitem.sys_module_id,
              Name: moduleitem.module_name,
              label: moduleitem.module_name,
              value: moduleitem.module_name,
            };
            formattedJson.modules.push(moduleItemData);
          });
          setModule(formattedJson.modules);
          setBackupModule(formattedJson.modules);
        }
      })
      .catch((err) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
      })
      .finally(() => {
        setModulesLoading(false);
        setLoading(false);
      });
  };

  /** 
  * @description Get feature based on the module selection
  * @param {Number} id - Send module id to get the feature
  */
  const getFeatures = (id) => {
    setLoading(true);
    setFeatureLoading(true);
    userApi
      .getFeatures(id)
      .then((res) => {
        let formattedJson = { menus: [] };
        if (res && res.menus) {
          res.menus.forEach((menusitem) => {
            let menusItemData = {
              Id: menusitem.sys_feature_id,
              Name: getCode(menusitem.menuName),
              label: getCode(menusitem.menuName),
              value: getCode(menusitem.menuName),
              access: menusitem.access
            };
            formattedJson.menus.push(menusItemData);
          });
          setFeature(formattedJson.menus);
          setBackupFeature(formattedJson.menus);
        }
      })
      .catch((err) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
      })
      .finally(() => {
        setFeatureLoading(false);
        setLoading(false);
      });
  };

  /** 
  * @description get module based on the selection of suite ID
  * @param {Array} value - selected suite name
  */
  const getSuiteId = (value) => {
    let getsuite = _.find(suite, { value: value });
    getModules(getsuite.Id);
  };

  /** 
  * @description get feature based on the selection of module ID
  * @param {String} value - selected module name
  */
  const getModuleId = (value) => {
    let getmodule = _.find(module, { value: value });
    getFeatures(getmodule.Id);
  };

  /** 
  * @description get selected sys menu id based on the selection of feature ID
  * @param {String} value - Selected feature name
  */
  const getSysMenuId = (value) => {
    let getsysmenu = _.find(feature, { value: value });
    setSelectedSysMenuId(getsysmenu.Id);
  };

  /** 
  * @description seperate Access data from API call
  * @param {String} value - selected feature name
  */
  const setFeatureAccess = (value) => {
    let getAccess = _.find(feature, { value: value });
    let options = [];
    getAccess.access.access.forEach((val) => {
      let optionVal = val === "FULL" || val === "Full" ? "Full" : "Inquiry";
      options.push({
        label: optionVal,
        value: optionVal
      })
    });
    setBackupAccess(options);
    setAccess(options);
  };


  /**
   * @description - Check if date is valid (like not empty/undefined/null)
   * @param {Object} date - date for validation
   */
  const isValidDate = (date) => {
    let status = moment(
      moment(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      "DD/MM/YYYY",
      true
    ).isValid();
    return status;
  };


  const roleStartDateStatus = (date) => {
    if (props.action === "Edit" && isValidDate(date)) {
      let todayDate = moment(DBCurrentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
      let earliStartDate = moment(new Date(earliestStartDate), "YYYY-MM-DD").format("YYYY-MM-DD");
      let isTodayGreater = moment(todayDate).isAfter(earliStartDate);
      let _roleStartDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");

      let isStartDateSameAsCurrentDate = moment(_roleStartDate).isSame(todayDate);
      if(isStartDateSameAsCurrentDate){
        return true;
      }
      else if (earliestStartDate === null) {
        return false;
      }
      else  if (isTodayGreater) {
          return true;
      }
      else {
        return false
      }  
    }
    else {
      return false;
    }
  }

  const checkIfRuleAndDashboardDatesComplyWithRoleDates = (_rolesGrid, _dashboardGrid,_roleStartDate,_roleEndDate) => {
    const roleSDate = new Date(_roleStartDate);
    const roleEDate = new Date(_roleEndDate);
    const newSelectedSDate = moment(roleSDate).format("YYYY-MM-DD");
    const newSelectedEDate = moment(roleEDate).format("YYYY-MM-DD");
    // if (_rolesGrid?.length){
    //   //reset flags
    //   setInvalidRolesStartDate(false);
    //   setInvalidRolesEndDate(false);
    // }
    if (_dashboardGrid?.length){
      //reset flags
      setDashboardGridStartDatesDoNotComply(false);
      setDashboardGridEndDatesDoNotComply(false);
    }
    
    if(_rolesGrid && _rolesGrid.length) {
      setInvalidRolesStartDate(false);
      setInvalidRolesEndDate(false);
    _rolesGrid.forEach((role) => {
      const ruleStartDate = moment(role.From, "YYYY-MM-DD").format("YYYY-MM-DD");
      const ruleEndDate = moment(role.To, "YYYY-MM-DD").format("YYYY-MM-DD");
      const ruleStartDateIsLess = moment(newSelectedSDate).isAfter(ruleStartDate);
      const ruleEndDateIsGreater = _roleEndDate && moment(ruleEndDate).isAfter(newSelectedEDate);
      if (ruleStartDateIsLess) {
        setInvalidRolesStartDate(true);
        return
      }
      if (ruleEndDateIsGreater) {
        setInvalidRolesEndDate(true);
        return
      }
    })
   }
    if(_dashboardGrid) {
    _dashboardGrid.forEach((dashboard) => {
      const dashboardStartDate = moment(dashboard.effective_start_date, "MM/DD/YYYY").format("YYYY-MM-DD");
      const dashboardEndDate = moment(dashboard.effective_end_date, "MM/DD/YYYY").format("YYYY-MM-DD");
      const dashboardStartDateIsLess = moment(newSelectedSDate).isAfter(dashboardStartDate);
      const dashboardEndtDateIsGreater = _roleEndDate && moment(dashboardEndDate).isAfter(newSelectedEDate);
      if (dashboardStartDateIsLess) {
        setDashboardGridStartDatesDoNotComply(true);
        return
      }
      if (dashboardEndtDateIsGreater) {
        setDashboardGridEndDatesDoNotComply(true);
        return
      }
    });
    }
  }

  /**
  * @description - This will validate the if Roles Start date is after the all the listed rules 
  * Onchange of roles start Date and Rules Start date 
  */
  useEffect(() => {
    checkIfRuleAndDashboardDatesComplyWithRoleDates(rolesGrid, null,roleStartDate, roleEndDate);
  }, [rolesGrid])

  useEffect(() => {
    checkIfRuleAndDashboardDatesComplyWithRoleDates(null, dashboardGrid,roleStartDate, roleEndDate);
  }, [dashboardGrid])

  useEffect(() => {
    checkIfRuleAndDashboardDatesComplyWithRoleDates(rolesGrid, dashboardGrid,roleStartDate, roleEndDate);
  }, [ roleStartDate, roleEndDate])


 const onResetMinMaxDates=(_meUserData,_roleStartDate,_roleEndDate)=>{
  let currentDate = _meUserData.user_timezone && jsUtils.getCurrentServerDate(_meUserData.user_timezone);
  let minimumStartDate=jsUtils.getMaxDate(currentDate,_roleStartDate);
  setDashboardMinStartDate(jsUtils.getTimeZoneFormatDate(minimumStartDate));
  setDashboardMinEndDate(jsUtils.getTimeZoneFormatDate(jsUtils.getDatePlusMinus(minimumStartDate,1)));
  setRuleMinStartDate(jsUtils.getTimeZoneFormatDate(minimumStartDate));
  setRuleMinEndDate(jsUtils.getTimeZoneFormatDate(jsUtils.getDatePlusMinus(minimumStartDate,1)));


  if(_roleEndDate){
    setDashboardMaxStartDate(jsUtils.getTimeZoneFormatDate(jsUtils.getDatePlusMinus(_roleEndDate,-1)));
    setDashboardMaxEndDate(_roleEndDate);
    setRuleMaxStartDate(jsUtils.getTimeZoneFormatDate(jsUtils.getDatePlusMinus(_roleEndDate,-1)));
    setRuleMaxEndDate();
  }
  else{
    setDashboardMaxStartDate();
    setDashboardMaxEndDate();
    setRuleMaxStartDate();
    setRuleMaxEndDate();
  }
 }

  /**
   * @description - Add roles into grid
   */
  const addRole = () => {
    let roleData = {
      roles: [
        {
          Suite: selectedSuite,
          Module: selectedModule,
          Feature: selectedFeature,
          Access: selectedAccess,
          From: rulesStartDate,
          To: rulesEndDate,
          SysMenuId: selectedSysMenuId,
          SysPersonaMenuId: selectedSysPersonaMenuId,
          oldRule: isOldRule
        },
      ],
    };

    let sDateStatus = isValidDate(rulesStartDate);
    let eDateStatus = isValidDate(rulesEndDate);

    let start_date = sDateStatus
      ? moment(rulesStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      : "";

    let end_date = eDateStatus
      ? moment(rulesEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
      : "";
    let roleDataForSave;
    if (props.action === "Clone") {
      roleDataForSave = {
        roles: [
          {
            sys_feature_id: selectedSysMenuId,
            sys_access_permission:
              selectedAccess === "Full" ? "FULL" : "INQUIRY",
            effective_start_date: start_date,
            effective_end_date: end_date,
            enabled: "y",
          },
        ],
      };
    } else if (props.action === "Edit") {
      if (update) {
        roleDataForSave = {
          roles: [
            {
              sys_persona_feature_id: selectedSysPersonaMenuId,
              sys_feature_id: selectedSysMenuId,
              sys_access_permission:
                selectedAccess === "Full" ? "FULL" : "INQUIRY",
              effective_start_date: start_date,
              effective_end_date: end_date,
              enabled: "y",
              operation: update && selectedSysPersonaMenuId ? "update" : "create",
            },
          ],
        };
      }
      else {
        roleDataForSave = {
          roles: [
            {
              sys_feature_id: selectedSysMenuId,
              sys_access_permission:
                selectedAccess === "Full" ? "FULL" : "INQUIRY",
              effective_start_date: start_date,
              effective_end_date: end_date,
              enabled: "y",
              operation: update ? "update" : "create",
            },
          ],
        };
      }
    } else {
      roleDataForSave = {
        roles: [
          {
            sys_persona_feature_id: selectedSysPersonaMenuId,
            sys_feature_id: selectedSysMenuId,
            sys_access_permission:
              selectedAccess === "Full" ? "FULL" : "INQUIRY",
            effective_start_date: start_date,
            effective_end_date: end_date,
            enabled: "y",
          },
        ],
      };
    }

    if (update) {
      setUpdate(false);
      setEditRule(false);
      if (props.action === "Clone") {
        setCloneRole((prevState) => {
          let role = [...prevState];
          role[updateIndex] = roleDataForSave.roles[0];
          return role;
        });
      } else {
        setUpdatedRulesData((prevState) => {
          let role = [...prevState];
          role[updateIndex] = roleDataForSave.roles[0];
          return role;
        });
      }
      setRolesGrid((prevState) => {
        let role = [...prevState];
        role[updateIndex] = roleData.roles[0];
        return role;
      });
    } else {
      if (props.action === "Clone") {
        setCloneRole((prevState) => [...prevState, ...roleDataForSave.roles]);
      }
      else {
        setUpdatedRulesData((prevState) => [...prevState, ...roleDataForSave.roles]);
      }
      setRolesGrid((prevState) => [...prevState, ...roleData.roles]);
    }
    
    if (roleName.trim() !== "" && roleStartDate) {
      setSaveStatus(false);
    }
    setOneRole(false);
    setAccess();
    setModule();
    setFeature();
    setSuite(backupSuite);
    setRulesStartDate();
    setRulesEndDate();
    setSelectedSuite();
    setIsOldRule();
    setSelectedModule();
    setSelectedFeature();
    setSelectedAccess();
    setAddRoleStatus(true);
    setSelectedSysPersonaMenuId();
    onResetMinMaxDates(meUserData,roleStartDate,roleEndDate);
  };
  

  /**
   * @description - Add dashboard into grid
   */
   const addDashboard = () => {
     const isUpdate = dashboardEditState==="update";
  
    let sDateStatus = isValidDate(selectedDashboardSDate);
    let eDateStatus = isValidDate(selectedDashboardEDate);

    let start_date_for_grid = sDateStatus
      ? moment(selectedDashboardSDate).format("MM/DD/YYYY")
      : "";

    let end_date_for_grid = eDateStatus
      ? moment(selectedDashboardEDate).format("MM/DD/YYYY")
      : "";

      let start_date_for_api = sDateStatus
      ? moment(selectedDashboardSDate).format("YYYY-MM-DD")
      : "";

    let end_date_for_api = eDateStatus
      ? moment(selectedDashboardEDate).format("YYYY-MM-DD")
      : "";

      let dashboardGridData = {
       
      };
  
    let dashboardDataForAPI;
    if (props.action === "Clone") {
      dashboardDataForAPI = {
        dashboards: [
          {
            sys_module_id:selectedDashboardId,
            effective_start_date: start_date_for_api,
            effective_end_date: end_date_for_api,
          },
        ],
      };


      dashboardGridData = {
        dashboards: [
          { 
            ...dashboardGrid[selectedDashboardGridIndex],
            module_name_key:_.find(dashboards,{sys_module_id:selectedDashboardId})?.name,
            sys_module_id:selectedDashboardId,
            effective_start_date: start_date_for_grid,
            effective_end_date: end_date_for_grid,
          },
        ],
      }

    } else if (props.action === "Edit") {

      if (isUpdate) {

        dashboardGridData = {
          dashboards: [
            { 
              ...dashboardGrid[selectedDashboardGridIndex],
              effective_start_date: start_date_for_grid,
              effective_end_date: end_date_for_grid,
            },
          ],
        }
        dashboardDataForAPI = {
          dashboards: [
            {

              sys_module_id:selectedDashboardId,
              effective_start_date: start_date_for_api,
              effective_end_date: end_date_for_api,
              sys_persona_external_module_id: dashboardGrid[selectedDashboardGridIndex]?.sys_persona_external_module_id,
              operation: isUpdate ? "update" : "create",
            },
          ],
        };
      }
      else {
        dashboardDataForAPI = {
          dashboards: [
            {
              sys_module_id:selectedDashboardId,
              effective_start_date: start_date_for_api,
              effective_end_date: end_date_for_api,
              operation: isUpdate ? "update" : "create",
            },
          ],
        };

        dashboardGridData = {
          dashboards: [
            { 
              module_name_key:_.find(dashboards,{sys_module_id:selectedDashboardId})?.name,
              sys_module_id:selectedDashboardId,
              ...dashboardGrid[selectedDashboardGridIndex],
              effective_start_date: start_date_for_grid,
              effective_end_date: end_date_for_grid,
            },
          ],
        }
      }
    } else {
      dashboardDataForAPI = {
        dashboards: [
          {
            sys_module_id:selectedDashboardId,
            effective_start_date: start_date_for_api,
            effective_end_date: end_date_for_api,
          },
        ],
      };

      dashboardGridData = {
        dashboards: [
          { 
            module_name_key:_.find(dashboards,{sys_module_id:selectedDashboardId})?.name,
            sys_module_id:selectedDashboardId,
            effective_start_date: start_date_for_grid,
            effective_end_date: end_date_for_grid,
          },
        ],
      }
    }

    if (isUpdate) {
      setDashboardEditState(null);
      setEditRuleDashboard(false);
      //setEditRule(false);
      setUpdatedDashboardsData((prevState) => {
            let data = [...prevState];
            data[selectedDashboardGridIndex] = dashboardDataForAPI.dashboards[0];
            return data;
       });
      // if (props.action === "Clone") {
      //   setUpdatedDashboardsData((prevState) => {
      //     let data = [...prevState];
      //     data[selectedDashboardGridIndex] = dashboardDataForAPI.dashboards[0];
      //     return data;
      //   });

      // } else {
      //   setUpdatedDashboardsData((prevState) => {
      //     let data = [...prevState];
      //     data[selectedDashboardGridIndex] = dashboardDataForAPI.dashboards[0];
      //     return data;
      //   });
      // }
      
      setDashboardGrid((prevState) => {
        let data = [...prevState];
        data[selectedDashboardGridIndex] = dashboardGridData?.dashboards?.[0];
        return data;
      });
    } else {
      // if (props.action === "Clone") {
      //   setCloneRole((prevState) => [...prevState, ...(dashboardDataForAPI.dashboards)]);
      // }
      // else {
        setUpdatedDashboardsData((prevState) => [...(prevState||[]), ...dashboardDataForAPI.dashboards]);
      // }
      setDashboardGrid((prevState) => [ ...(prevState||[]), ...dashboardGridData.dashboards]);
    }
    if (roleName.trim() !== "") {
      setSaveStatus(false);
    }
    setOneRole(false);
    setAccess();
    setModule();
    setFeature();
    setSuite(backupSuite);
    setRulesStartDate();
    setRulesEndDate();
    setIsOldRule();
    setSelectedModule();
    setSelectedFeature();
    setSelectedAccess();
    setAddRoleStatus(true);
    setSelectedSysPersonaMenuId();
    setSelectedDashboardId();
    setSelectedDashboardEDate();
    setSelectedDashboardSDate();
    evaluateEditedRoleStatus();
    setDashStartDateUStatus(false);
    onResetMinMaxDates(meUserData,roleStartDate,roleEndDate);
  };
  /**
 * @description - Validation for add role button based on the selected values
 * @param {String} change - start date check
 */
  const getRoleStatus = (change) => {
    if (
      (selectedSuite !== undefined || change === "suite") &&
      (selectedModule !== undefined || change === "module") &&
      (selectedFeature !== undefined || change === "feature") &&
      (selectedAccess !== undefined || change === "access") &&
      ((rulesStartDate !== undefined && rulesStartDate !== null) || change === "sdate")
    ) {
      setAddRoleStatus(false);
    }
    else {
      setAddRoleStatus(true);
    }
  };

  const evaluateEditedRoleStatus =(field,newValue)=>{
    const sDate = field==="selectedDashboardSDate"?newValue: selectedDashboardSDate;
    const eDate = field==="selectedDashboardEDate"?newValue: selectedDashboardEDate;
    const dashboardId = field==="selectedDashboardId"?newValue: selectedDashboardId;
    const momentStartDate = moment(sDate)
    //const momentEarliestStartDate  = earliestStartDate ? moment(new Date(earliestStartDate), "YYYY-MM-DD").format("YYYY-MM-DD") :  moment(new Date()).format("YYYY-MM-DD");
    const momentEndDate = eDate && moment(eDate)
    const isStartDateValid = momentStartDate.isValid();
    const isEndDateValid = !momentEndDate ||( momentEndDate.isValid() && momentEndDate.isSameOrAfter(momentStartDate));
    const areRequiredFieldsNotNull = dashboardId!==null && dashboardId!==undefined  && !!sDate;
  
    setAddDashboardStatus(areRequiredFieldsNotNull && isStartDateValid && isEndDateValid);

  }


  /** 
  * @description save roles data and rule list
  */
  const savePersonaChanges = () => {

    if(!rolesGrid?.length){
      setError(getCode("RULES_ARE_MANDATORY"));
    }
    else if (invalidRolesStartDate) {
      setError(getCode("SCREEN_STARTDATE_BEFORE_ROLE"));
    }
    else if (invalidRolesEndDate) {
      setError(getCode("SCREEN_ENDDATE_AFTER_ROLE"));
    }
    else if(dashboardGridStartDatesDoNotComply){
      setError(getCode("DASHBOARD_STARTDATE_BEFORE_ROLE"));
    }
    else if(dashboardGridEndDatesDoNotComply){
      setError(getCode("DASHBOARD_ENDDATE_AFTER_ROLE"));
    }
    else {
      setError("");
      setLoading(true);
      let fromDateStatus = isValidDate(roleStartDate);
      let toDateStatus = isValidDate(roleEndDate);
      let effective_start_date = fromDateStatus
        ? moment(roleStartDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "";
      let effective_end_date = toDateStatus
        ? moment(roleEndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : "";

      const removeNullFromRoles = (arrayToClean) => {
        const cleanedArray = [];
        arrayToClean.forEach((val) => {
          if (val !== null && val !== undefined) {
            cleanedArray.push(val);
          }
        });

        return cleanedArray;
      }

      let cleanedRulesData = removeNullFromRoles(updatedRulesData);
      let cleanedDashboardData = removeNullFromRoles(updatedDashboardsData);

      let data;
      if (props.action === "Clone") {
        data = {
          name: roleName,
          enabled: "y",
          effective_start_date: effective_start_date,
          effective_end_date: effective_end_date,
          rules: cloneRole,
          dashboards:cleanedDashboardData

        };
      } else if (props.action === "Edit") {
        if(roleStartDateCanged){
          data = {
            sys_persona_id: props.personaId ? props.personaId : null,
            enabled: "y",
            effective_start_date: effective_start_date,
            effective_end_date: effective_end_date,
            rules: cleanedRulesData,
            dashboards:cleanedDashboardData
          };
        }
        else{
          data = {
            sys_persona_id: props.personaId ? props.personaId : null,
            enabled: "y",
            effective_end_date: effective_end_date,
            rules: cleanedRulesData,
            dashboards:cleanedDashboardData

          };
        }
     
      } else {
        data = {
          name: roleName,
          enabled: "y",
          effective_start_date: effective_start_date,
          effective_end_date: effective_end_date,
          rules: cleanedRulesData,
          dashboards:cleanedDashboardData

        };
      }

      userApi
        .addPersona(data, reqType)
        .then((res) => {
          if (res.message) {
            setChanged(false);
            dispatch(setErrorMessage({ severity: 'success', message: (res && getCode(res.message)), errorCode: res.responseCode }));
            props.bulkEdit && traverseDown(true)
            !props.bulkEdit && setTimeout(() => {
              props.closed();
              setTimeDialog(false);
            }, 3000);
          }
        })
        .catch((err) => {
          dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: err.responseCode }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

const editRoleForDashboard = (dashboard) => {
  if (dashboard) {
    setSelectedDashboardId(dashboard.sys_module_id);
    dashboard.effective_start_date && setSelectedDashboardSDate(new Date(dashboard.effective_start_date))
    dashboard.effective_end_date && setSelectedDashboardEDate(new Date(dashboard.effective_end_date))
    
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    let _today = moment(currentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
    let fromDateF = moment(new Date(dashboard.effective_start_date), "YYYY-MM-DD").format("YYYY-MM-DD");
    let isDashStartDateEditable;
    if(props.action === "Edit"){
      isDashStartDateEditable = moment(fromDateF).isAfter(_today);
    }
    else{
      isDashStartDateEditable = moment(fromDateF).isSameOrAfter(_today);
    }

    if(isDashStartDateEditable){
      setDashStartDateUStatus(false)
    }
    else{
      setDashStartDateUStatus(true);
    }

    if (dashboard.oldRule ) {
      setEditRuleDashboard(true);
    } else {
      setEditRuleDashboard(false);
    }
  }
};

  /**
 * @description - Edit role click operation
 * @param {Object} role - Selected role for edit
 */
  const editRole = (role) => {
    if (role !== undefined) {
      let fromDate = isValidDate(role.From);
      let endDate = isValidDate(role.To);
      let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
      let _today = moment(currentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
      let fromDateF = moment(role.From, "YYYY-MM-DD").format("YYYY-MM-DD");
      let isStartDateEditable;
      if(props.action === "Edit"){
        isStartDateEditable = moment(fromDateF).isAfter(_today);
      }
      else{
        isStartDateEditable = moment(fromDateF).isSameOrAfter(_today);
      }
     
      if(isStartDateEditable){
        setSDateUStatus(false)
      }
      else{
        setSDateUStatus(true);
      }


      let moduleOption = [
        {
          label: role.Module,
          value: role.Module,
        },
      ];
      let featureOption = [
        {
          label: role.Feature,
          value: role.Feature,
        },
      ];
      let accessOption = [
        {
          label:
            role.Access === "FULL" || role.Access === "Full"
              ? "Full"
              : "Inquiry",
          value:
            role.Access === "FULL" || role.Access === "Full"
              ? "Full"
              : "Inquiry",
        },
      ];

      if (role.oldRule !== true) {

        let firstModule = function () {
          return new Promise(function (resolve, reject) {
            let getsuite = _.find(suite, { value: role.Suite });
            setModulesLoading(true);
            userApi
              .getModules(getsuite.Id)
              .then((res) => {
                let formattedJson = { modules: [] };
                if (res && res.modules) {
                  res.modules.forEach((moduleitem) => {
                    let moduleItemData = {
                      Id: moduleitem.sys_module_id,
                      Name: moduleitem.module_name,
                      label: moduleitem.module_name,
                      value: moduleitem.module_name,
                    };
                    formattedJson.modules.push(moduleItemData);
                  });
                  setModule(formattedJson.modules);
                  setBackupModule(formattedJson.modules);
                  resolve(formattedJson.modules);
                }
              })
              .catch((err) => {
                dispatch(refreshStoreOnError({ refresh: new Date() }));
                setLoading(false);
                reject("rejected");
              })
              .finally(() => {
                setModulesLoading(false);
              });
          })
        }

        let secondFeature = function (mod) {
          return new Promise(function (resolve, reject) {
            let getmodule = _.find(mod, { value: role.Module });
            userApi
              .getFeatures(getmodule.Id)
              .then((res) => {
                let formattedJson = { menus: [] };
                if (res && res.menus) {
                  res.menus.forEach((menusitem) => {
                    let menusItemData = {
                      Id: menusitem.sys_feature_id,
                      Name: getCode(menusitem.menuName),
                      label: getCode(menusitem.menuName),
                      value: getCode(menusitem.menuName),
                      access: menusitem.access
                    };
                    formattedJson.menus.push(menusItemData);
                  });
                  setFeature(formattedJson.menus);
                  setBackupFeature(formattedJson.menus);
                  resolve(formattedJson.menus);
                }
              })
              .catch((err) => {
                dispatch(refreshStoreOnError({ refresh: new Date() }));
                setLoading(false);
                reject("rejected");
              })
              .finally(() => {
                setFeatureLoading(false);
              });
          })
        }

        setSuite(backupSuite);
        setLoading(true);
        /*
        * Get modules and featured loaded once user clicks on edit icon of rules
        * User can change the selction
        */
        firstModule().then(function (_backupModule) {
          _backupModule.length === 0 ? setModule(moduleOption) : setModule(_backupModule);
          return secondFeature(_backupModule);
        }).then(function (_backupFeature) {
          _backupFeature.length === 0 ? setFeature(featureOption) : setFeature(_backupFeature);
          let getAccess = _.find(_backupFeature, { value: role.Feature });
          let options = [];
          getAccess.access.access.forEach((val) => {
            let optionVal = val === "FULL" || val === "Full" ? "Full" : "Inquiry";
            options.push({
              label: optionVal,
              value: optionVal
            })
          });
          setBackupAccess(options);
          if(options.length === 0){
            setAccess(accessOption);
          }
          else{
            setAccess(options);
          }

          setSelectedSuite(role.Suite);
          setIsOldRule(role.oldRule)
          setSelectedModule(role.Module);
          setSelectedFeature(role.Feature);
          setSelectedAccess(
            role.Access === "FULL" || role.Access === "Full" ? "Full" : "Inquiry"
          );
          setSelectedSysMenuId(role.SysMenuId);
          setSelectedSysPersonaMenuId(role.SysPersonaMenuId);

          let stDate = "";
          if (fromDate) {
            if (typeof role.From === "string") {
              stDate = role.From.substring(0, 10).split("-");
              stDate = stDate[1] + "/" + stDate[2] + "/" + stDate[0];
              setRulesStartDate(new Date(stDate))
            }
            else {
              setRulesStartDate(role.From)
            }
          }
          else {
            setRulesStartDate("");
          }

          let edDate = "";
          if (endDate) {
            if (typeof role.To === "string") {
              edDate = role.To.substring(0, 10).split("-");
              edDate = edDate[1] + "/" + edDate[2] + "/" + edDate[0];
              setRulesEndDate(new Date(edDate))
            }
            else {
              setRulesEndDate(role.To)
            }
          }
          else {
            setRulesEndDate("");
          }

          if (role.oldRule && role.oldRule === true) {
            setEditRule(true);
          } else {
            setEditRule(false);
          }
          setUpdate(true);
          setChanged(true);
          setLoading(false);
        });
      }
      else {
        setSuite(backupSuite);
        backupModule.length === 0 ? setModule(moduleOption) : setModule(backupModule);
        backupFeature.length === 0 ? setFeature(featureOption) : setFeature(backupFeature);
        backupAccess.length === 0 ? setAccess(accessOption) : setAccess(backupAccess);

        setSelectedSuite(role.Suite);
        setIsOldRule(role.oldRule)
        setSelectedModule(role.Module);
        setSelectedFeature(role.Feature);
        setSelectedAccess(
          role.Access === "FULL" || role.Access === "Full" ? "Full" : "Inquiry"
        );
        setSelectedSysMenuId(role.SysMenuId);
        setSelectedSysPersonaMenuId(role.SysPersonaMenuId);

        let stDate = "";
        if (fromDate) {
          if (typeof role.From === "string") {
            stDate = role.From.substring(0, 10).split("-");
            stDate = stDate[1] + "/" + stDate[2] + "/" + stDate[0];
            setRulesStartDate(new Date(stDate))
          }
          else {
            setRulesStartDate(role.From)
          }
        }
        else {
          setRulesStartDate("");
        }

        let edDate = "";
        if (endDate) {
          if (typeof role.To === "string") {
            edDate = role.To.substring(0, 10).split("-");
            edDate = edDate[1] + "/" + edDate[2] + "/" + edDate[0];
            setRulesEndDate(new Date(edDate))
          }
          else {
            setRulesEndDate(role.To)
          }
        }
        else {
          setRulesEndDate("");
        }

        if (role.oldRule && role.oldRule === true) {
          setEditRule(true);
        } else {
          setEditRule(false);
        }
        setUpdate(true);
        setChanged(true);
      }
    }
  };

  const traverseDown = (onSaveClick) => {
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setTraverseState("Down")
    if (props.rolesList.length > 0 && (props.editPosition + 1) < props.rolesList.length) {
      if (onSaveClick || !changed) {
        setRoleStartDate(null);
        setRoleEndDate(null);
        setRulesStartDate(null);
        setRulesEndDate(null);
        setRolesGrid([]);
        setUpdatedRulesData([]);
        setCloneRole([]);

        setSuite([]);
        setBackupSuite([]);
        setModule([]);
        setBackupModule([]);
        setFeature([]);
        setBackupFeature([]);
        setAccess([]);
        setBackupAccess([]);

        setSelectedSuite();
        setSelectedModule();
        setSelectedFeature();
        setSelectedAccess();
        setSelectedSysMenuId();
        setSelectedSysPersonaMenuId();
        setIsOldRule(false);

        setAddRoleStatus(true);
        setRuleMinEndDate();
        setSDateUStatus(false);
        setDashStartDateUStatus(false)
        setSave(true);
        setOneRole(true);
        setRoleName("");
        sethRoleSDate(true);
        setSaveStatus(true);
        setUpdate(false);
        setEditRule(false);
        setEditRuleDashboard(false);
        setUpdateIndex(0);
        setRuleMinStartDate(new Date(currentDate));
        setDashboardMinStartDate(new Date(currentDate));
        setError("");
        setReqType("post");
        setActiveIndex(0);
        props.onTraverseNext(props.rolesList[props.editPosition + 1]);
        props.setEditPosition(props.editPosition + 1)
      }
      else {
        setChanged(false);
        setCloseConfirmation(true)
      }
    }
  }
  const traverseUp = () => {
    let currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setTraverseState("Up")
    if (props.rolesList.length > 0 && (props.editPosition - 1) >= 0) {
      if (!changed) {
        setRoleStartDate(null);
        setRoleEndDate(null);
        setRulesStartDate(null);
        setRulesEndDate(null);
        setRolesGrid([]);
        setUpdatedRulesData([]);
        setCloneRole([]);

        setSuite([]);
        setBackupSuite([]);
        setModule([]);
        setBackupModule([]);
        setFeature([]);
        setBackupFeature([]);
        setAccess([]);
        setBackupAccess([]);

        setSelectedSuite();
        setSelectedModule();
        setSelectedFeature();
        setSelectedAccess();
        setSelectedSysMenuId();
        setSelectedSysPersonaMenuId();
        setIsOldRule(false);

        setAddRoleStatus(true);
        setSDateUStatus(false);
        setDashStartDateUStatus(false);
        setSave(true);
        setOneRole(true);
        setRoleName("");
        sethRoleSDate(true);
        setSaveStatus(true);
        setUpdate(false);
        setEditRule(false);
        setEditRuleDashboard(false);
        setUpdateIndex(0);
        setRuleMinStartDate(new Date(currentDate));
        setDashboardMinStartDate(new Date(currentDate));
        setError("");
        setReqType("post");
        setActiveIndex(0);
        props.onTraverseNext(props.rolesList[props.editPosition - 1]);
        props.setEditPosition(props.editPosition - 1)

      }
      else {
        setChanged(false);
        setCloseConfirmation(true)
      }
    }
  }

  const getScreenEditTab = () => {
    return (
      <div className="role-Edit-row">
        <table className="Addrole-table">
          <thead>
            <tr>
              <th width="12%">
                <label>
                  {getCode("SUITE")}
                  <span className="af-req-star"> *</span>
                </label>
              </th>
              <th width="20%">
                <label>
                  {getCode("MODULE")}
                  <span className="af-req-star"> *</span>
                </label>
              </th>
              <th width="24%">
                <label>
                  {getCode("FEATURE")}
                  <span className="af-req-star"> *</span>
                </label>
              </th>
              <th width="12%">
                <label>
                  {getCode("ACCESS")}
                  <span className="af-req-star"> *</span>
                </label>
              </th>
              <th width="12%">
                <label>
                  {getCode("START_DATE")}
                  <span className="af-req-star"> *</span>
                </label>
              </th>
              <th width="12%">
                <label>{getCode("END_DATE")}</label>
              </th>
              <th width="8%"> </th>
            </tr>
            <tr>
              <th>
                <Dropdown
                  className="select-suite af-editroles-select"
                  appendTo={document.body}
                  value={selectedSuite}
                  options={suite}
                  onChange={(e) => {
                    setSelectedSuite(e.value);
                    getSuiteId(e.value);
                    // setRuleMinStartDate(new Date(DBCurrentDate));
                    setSave(false);
                    setChanged(true);
                    setSelectedModule();
                    setSelectedFeature();
                    setSelectedAccess();
                    getRoleStatus("suite");
                  }}
                  placeholder={getCode("SELECT_A_SUITE")}
                  disabled={props.action === "Clone" ? false : editRule ? true : false}
                />
              </th>
              <th>
                <Dropdown
                  className="select-module"
                  value={selectedModule}
                  options={module}
                  appendTo={document.body}
                  onChange={(e) => {
                    setSelectedModule(e.value);
                    getModuleId(e.value);

                    setSave(false);
                    setChanged(true);
                    setSelectedFeature();
                    setSelectedAccess();
                    getRoleStatus("module");
                  }}
                  placeholder={getCode("SELECT_A_MODULE")}
                  disabled={props.action === "Clone" ? false : editRule ? true : false}
                />
              </th>
              <th>
                <Dropdown
                  className="select-feature"
                  value={selectedFeature}
                  options={feature}
                  appendTo={document.body}
                  onChange={(e) => {
                    setSelectedFeature(e.value);
                    getSysMenuId(e.value);

                    setFeatureAccess(e.value)
                    setSave(false);
                    setChanged(true);
                    setSelectedAccess();
                    getRoleStatus("feature");
                  }}
                  placeholder={getCode("SELECT_A_FEATURE")}
                  disabled={props.action === "Clone" ? false : editRule ? true : false}
                />
              </th>
              <th>
                <Dropdown
                  className="select-access"
                  value={selectedAccess}
                  appendTo={document.body}
                  options={access}
                  onChange={(e) => {
                    setSelectedAccess(e.value);
                    getRoleStatus("access");
                    setSave(false);
                    setChanged(true);
                  }}
                  placeholder={getCode("SELECT_AN_ACCESS_TYPE")}
                  disabled={props.action === "Clone" ? false : editRule ? true : false}
                />
              </th>
              <th>
                <Calendar
                  tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                  tooltipOptions={{ className: 'white-tooltip', position: 'bottom', showDelay: 700 }}
                  placeholder={getCode("MM_DD_YYYY")}
                  id="icon"
                  appendTo={document.body}
                  value={rulesStartDate}
                  onChange={(e) => {
                    setRulesStartDate(e.value);
                    e.value === null
                      ? setAddRoleStatus(true)
                      : getRoleStatus("sdate");
                    setError("");
                    if (e.value !== null) {
                      setSave(false);
                      setChanged(true);
                    }
                  }}
                  showIcon
                  className="role-suite-startDate"
                  disabled={
                    props.action === "Clone" ? false : update && sDateUStatus
                  }
                  minDate={ruleMinStartDate}
                  maxDate={ruleMaxStartDate}
                  readOnlyInput
                  showButtonBar
                  yearNavigator
                  yearRange="1970:2100"
                />
              </th>
              <th>
                <Calendar
                  tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                  tooltipOptions={{
                    className: "white-tooltip",
                    position: "bottom",
                    showDelay: 700,
                  }}
                  placeholder={getCode("MM_DD_YYYY")}
                  id="icon"
                  appendTo={document.body}
                  value={rulesEndDate}
                  onChange={(e) => {
                    setRulesEndDate(e.value);
                    setSave(false);
                    setChanged(true);
                    setAddRoleStatus(false);
                  }}
                  showIcon
                  className="role-suite-endDate"
                  minDate={ruleMinEndDate}
                  maxDate={ruleMaxEndDate}
                  readOnlyInput
                  showButtonBar
                  disabled={false}
                  yearNavigator
                  yearRange="1970:2100"
                />
              </th>
              <th>
                <button
                  className={`form-btn ${
                    update ? "update-role" : "add-role"
                  }  ${addRoleStatus ? "disabled" : "enabled"}  `}
                  disabled={addRoleStatus}
                  onClick={addRole}
                >
                  {update ? getCode("UPDATE_RULE") : getCode("ADD_RULE")}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {rolesGrid &&
              rolesGrid.map((role, i) => {
                let fromDateStatus = isValidDate(role.From);
                let toDateStatus = isValidDate(role.To);
                let editEnable = true;
                if (toDateStatus) {
                  let toDateF = moment(role.To, "YYYY-MM-DD").format(
                    "YYYY-MM-DD"
                  );
                  let today = moment(DBCurrentDate,"YYYY-MM-DD").format("YYYY-MM-DD");
                  if(role.oldRule){
                    editEnable = moment(toDateF).isAfter(today);
                  }
                  else{
                    editEnable = moment(toDateF).isSameOrAfter(today);
                  }
                  
                }

                let fromDate = moment(role.From, "YYYY-MM-DD").format(
                  "MM/DD/YYYY"
                );

                let toDate = moment(role.To, "YYYY-MM-DD").format("MM/DD/YYYY");

                return (
                  <tr key={i}>
                    <td width="12%">{role.Suite}</td>
                    <td>{role.Module}</td>
                    <td>{role.Feature}</td>
                    <td>
                      {role.Access === "FULL" || role.Access === "Full"
                        ? "Full"
                        : "Inquiry"}
                    </td>
                    <td>{fromDateStatus ? fromDate : ""}</td>
                    <td>{toDateStatus ? toDate : ""}</td>
                    <td>
                      {editEnable && <span
                        className="btn-edit"
                        onClick={(e) => {
                          setError("");
                          e.preventDefault();
                          if (editEnable) {
                            editRole(role);
                            setUpdateIndex(i);
                            setAddRoleStatus(true);
                          } else {
                            setError("Roles end date expired. Can't edit");
                          }
                        }}
                      >
                        <img
                          title={tooltips[TOOLTIPS_KEYS.TOOLTIP_EDIT]}
                          alt="edit"
                          className="af-role-edit-icon"
                          src="/images/svg/icon_edit.svg"
                        />
                      </span>
                    }
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="role-grid-error">
          {error && error !== "" ? error : ""}
        </div>
        <div className="btn-pannel">
          {loading && (
            <div className="role-save-spinner">
              <Spinner />
            </div>
          )}
          <button className="btn-save" disabled={saveStatus} onClick={savePersonaChanges}>
            {getCode("SAVE")}
          </button>
        </div>
      </div>
    );
  };
  const getDashboardEditTab = () => {
    return (
      <div className="role-Edit-row">
        <table className="Addrole-table">
          <thead>
            <tr>
              <th width="68%">
                <label>
                  {getCode("DASHBOARD")}
                  <span className="af-req-star"> *</span>
                </label>
              </th>
           
              <th width="12%">
                <label>
                  {getCode("START_DATE")}
                  <span className="af-req-star"> *</span>
                </label>
              </th>
              <th width="12%">
                <label>{getCode("END_DATE")}</label>
              </th>
              <th width="8%"> </th>
            </tr>
            <tr>
              <th>
                <Dropdown
                  className="select-dashboard af-editroles-select"
                  appendTo={document.body}
                  value={selectedDashboardId}
                  options={dashboards}
                  onChange={(e) => {
                    setSelectedDashboardId(e.value);
                    setSave(false);
                    setChanged(true);
                  }}
                  disabled={
                    props.action === "Clone" ? false : dashboardEditState==="update" ? true : false
                  }
                />
              </th>
             
              <th> 
                <Calendar
                  tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                  tooltipOptions={{
                    className: "white-tooltip",
                    position: "bottom",
                    showDelay: 700,
                  }}
                  placeholder={getCode("MM_DD_YYYY")}
                  id="icon"
                  appendTo={document.body}
                  value={selectedDashboardSDate}
                  onChange={(e) => {
                    setSelectedDashboardSDate(e.value);
                    e.value === null
                      ? setAddRoleStatus(true)
                      : getRoleStatus("sdate");
                    setError("");
                    if (e.value !== null) {
                      setSave(false);
                      setChanged(true);
                    }
                  }}
                  showIcon
                  className="role-suite-startDate"

                  // disabled={roleStartDateStatus(selectedDashboardSDate)}
                  disabled={ props.action === "Clone" ? false : dashStartDateUStatus}
                 
                  minDate={dashboardMinStartDate}
                  maxDate={dashboardMaxStartDate}
                  readOnlyInput
                  showButtonBar
                  yearNavigator
                  yearRange="1970:2100"
                />
              </th>
              <th>
                <Calendar
                  tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                  tooltipOptions={{
                    className: "white-tooltip",
                    position: "bottom",
                    showDelay: 700,
                  }}
                  placeholder={getCode("MM_DD_YYYY")}
                  id="icon"
                  appendTo={document.body}
                  value={selectedDashboardEDate}
                  onChange={(e) => {
                    setSelectedDashboardEDate(e.value);
                  //  setDashboardGridEndDatesDoNotComply(false);
                    setSave(false);
                    setChanged(true);
                  }}
                  showIcon
                  className="role-suite-endDate"
                  minDate={dashboardMinEndDate}
                  maxDate={dashboardMaxEndDate}
                  readOnlyInput
                  showButtonBar
                  disabled={false}
                  yearNavigator
                  yearRange="1970:2100"
                />
              </th>
              <th>
                <button
                  className={`form-btn ${
                    dashboardEditState==="update" ? "update-role" : "add-role"
                  }  ${!addDashboardStatus   ? "disabled" : "enabled"}  `}
                  disabled={!addDashboardStatus }
                  onClick={addDashboard}
                >
                { dashboardEditState==="update" ? getCode("UPDATE") : getCode("ADD") }
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {dashboardGrid &&
              dashboardGrid.map((dashboard, i) => {
  
                const startDateValid = moment(dashboard.effective_start_date, 'MM/DD/YYYY',true).isValid()
                const endDateValid = moment(dashboard.effective_end_date, 'MM/DD/YYYY',true).isValid()
                let isDashboardEditEnabled = true;
                if (endDateValid && dashboard.effective_end_date) {
                  let toDateF = moment(new Date(dashboard.effective_end_date), "YYYY-MM-DD").format("YYYY-MM-DD");
                  let today = moment(DBCurrentDate,"YYYY-MM-DD").format("YYYY-MM-DD");

                  if(dashboard.oldRule){
                    isDashboardEditEnabled = moment(toDateF).isAfter(today);
                  }
                  else{
                    isDashboardEditEnabled = moment(toDateF).isSameOrAfter(today);
                  }
                }
                

                return (
                  <tr key={i}>
                    <td width="12%">{dashboard.module_name_key}</td>

                    <td>{startDateValid ?dashboard.effective_start_date:""}</td>
                    <td>{endDateValid? dashboard.effective_end_date:""}</td>
                    <td> 
                      { isDashboardEditEnabled && <span
                          className="btn-edit"
                          onClick={(e) => {
                            setError("");
                            e.preventDefault();
                            if (isDashboardEditEnabled) {
                              editRoleForDashboard(dashboard);
                              setSelectedDashboardGridIndex(i);
                              setDashboardEditState("update");
                            } else {
                              setError("Permissions end date expired. Can't edit");
                            }
                          }}
                        >
                          <img
                            title={tooltips[TOOLTIPS_KEYS.TOOLTIP_EDIT]}
                            alt="edit"
                            className="af-role-edit-icon"
                            src="/images/svg/icon_edit.svg"
                          />
                        </span>
                      }
                    </td>
                  </tr>
                );
              })}

          </tbody>
        </table>
        <div className="role-grid-error">
          {error && error !== "" ? error : ""}
        </div>
        <div className="btn-pannel">
          {loading && (
            <div className="role-save-spinner">
              <Spinner />
            </div>
          )}
          <button className="btn-save" disabled={saveStatus} onClick={savePersonaChanges}>
            {getCode("SAVE")}
          </button>

        </div>
      </div>
    );
  };


  const onRoleStartDateChange=(e)=>{
    setRoleStartDate(e.value);
    setRuleMinStartDate(e.value);
    setDashboardMinStartDate(e.value);
    setError("");
    if (e.value !== null) {
      setSave(false);
      sethRoleSDate(false);
      setChanged(true);
      if ((oneRole === false && props.action === "Add" && roleName!=="")) {
        setSaveStatus(false);
      }
      if (props.action === "Edit") {
        setSaveStatus(false);
        setRoleStartDateCanged(true);
      }
    } else {
      setSaveStatus(true);
    }
  }

  const onRoleEndDateChange=(e)=>{
      setRoleEndDate(e.value);
      setSave(false);
      setChanged(true);
      if (props.action === "Edit") {
        setSaveStatus(false);
      }
  }

  const onRoleNameChange=(e) => {
    setRoleName(e.currentTarget.value);
    if (props.action === "Add") {
      if (
        save === false &&
        oneRole === false &&
        hRoleSDate === false &&
        e.currentTarget.value.trim() !== ""
      ) {
        setSaveStatus(false);
      } else {
        setSaveStatus(true);
      }
    }
    setChanged(true);
  }


  return (
    <div className="af-modal-dialog-addtime">
      <Toast ref={toast} />
      {props !== null ? (
        <Dialog
          header=""
          closable={false}
          visible={TimeDialog}
          className="af-modal-dialog"
        >
          <div className="p-dialog-header">
            <a
              href={`${helpPageUrl}${HELP_LINKS.ROLES_ACCESS}`}
              target="_blank"
            >
              <img
                title={tooltips[TOOLTIPS_KEYS.TOOLTIP_HELP]}
                className="af-help-addtime"
                src="/images/svg/Help_icon.svg"
                alt=""
              />
            </a>
            <div className="p-breadcrumb p-component" aria-label="Breadcrumb">
              <ul>
                <li className="">
                  <span className="p-menuitem-link">
                    <span className="p-menuitem-text">
                      {getCode("SETTINGS")}
                    </span>
                  </span>
                </li>
                <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
                <li className="">
                  <span className="p-menuitem-link">
                    <span className="p-menuitem-text">
                      {getCode("ROLE_ACCESS")}
                    </span>
                  </span>
                </li>
              </ul>
            </div>
            <span className="p-dialog-title"></span>
            {props.bulkEdit && (
              <div className="dialog-title" style={{ float: "right" }}>
                <span>{`${props.editPosition + 1} of ${
                  props.rolesList.length
                }`}</span>
                <i
                  className="pi pi-chevron-up"
                  onClick={() => {
                    traverseUp();
                  }}
                  data-testid="traverseUp"
                ></i>
                <i
                  className="pi pi-chevron-down"
                  onClick={() => {
                    traverseDown();
                  }}
                  data-testid="traverseDown"
                ></i>
              </div>
            )}
            <div className="p-dialog-header-icons">
              <button
                onClick={() => hideDialog()}
                type="button"
                className="p-dialog-header-icon p-dialog-header-close p-link"
                aria-label="Close"
                title={tooltips[TOOLTIPS_KEYS.TOOLTIP_CLOSE]}
              >
                <span className="p-dialog-header-close-icon pi pi-times"></span>
              </button>
            </div>
          </div>
          <div className=" edit-roles-poup">
            {(dashboardsLoading || suiteLoading) && modulesLoading && featureLoading ? (
              <div className="usercard-spinner">
                <Spinner />
              </div>
            ) : (
              ""
            )}
            <div className="role-searchFilter">
              <table className="role-table">
                <tbody>
                  <tr>
                    <th>
                      <label>
                        {getCode("ROLE")}
                        <span className="af-req-star"> *</span>
                      </label>
                    </th>
                    <th>
                      <label>
                        {getCode("START_DATE")}
                        <span className="af-req-star"> *</span>
                      </label>
                    </th>
                    <th>
                      <label>{getCode("END_DATE")}</label>
                    </th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>
                      <InputText
                        value={roleName}
                        id="roleName"
                        type="text"
                        name="rolesTxt"
                        maxLength="50"
                        onChange={onRoleNameChange}
                        disabled={props.action === "Edit" ? true : false}
                        autoComplete="off"
                        data-testid="roleName"
                      />
                    </td>
                    <td width="12%">
                      <Calendar
                        tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                        tooltipOptions={{
                          className: "white-tooltip",
                          position: "bottom",
                          showDelay: 700,
                        }}
                        placeholder={getCode("MM_DD_YYYY")}
                        id="icon"
                        value={roleStartDate}
                        appendTo={document.body}
                        onChange={onRoleStartDateChange}
                        showIcon
                        className="startDate"
                        maxDate={maxStartDate}
                        minDate={minStartDate}
                        readOnlyInput
                        showButtonBar
                        yearNavigator
                        yearRange="1970:2100"
                        disabled={roleStartDateStatus(roleStartDate)}
                        data-testid="roleStartDate"
                      />
                    </td>
                    <td width="12%">
                      <Calendar
                        tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]}
                        tooltipOptions={{
                          className: "white-tooltip",
                          position: "bottom",
                          showDelay: 700,
                        }}
                        placeholder={getCode("MM_DD_YYYY")}
                        id="icon"
                        appendTo={document.body}
                        value={roleEndDate}
                        onChange={onRoleEndDateChange}
                        showIcon
                        className="endDate"
                        minDate={minEndDate}
                        readOnlyInput
                        showButtonBar
                        yearNavigator
                        yearRange="1970:2100"
                      />
                    </td>
                    <td width="8%"></td>
                  </tr>
                </tbody>
              </table>
            </div>
              <div className="tabview-demo">
                {
                !FEATURES?.IS_SISENSE_DASHBOARD_ENABLED ? getScreenEditTab() :
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                  >
                    <TabPanel header={jsUtils.getLiteralValue("Screens")}>
            

                    {getScreenEditTab()}
                  
                    </TabPanel>
                    <TabPanel header={jsUtils.getLiteralValue("Dashboards")}>
                    {getDashboardEditTab()}
                    </TabPanel>
                  </TabView>
                }
              </div>

            
            </div>

          <div className="af-confirmation">
            <div className="af-confirmation-dialog">
              <Dialog
                closable={false}
                header="Confirmation"
                visible={closeConfirmation}
                modal
                footer={renderFooterClose()}
                onHide={() => onHideClose("closeConfirmation")}
              >
                <div className="confirmation-content">
                  <img
                    title=""
                    alt=""
                    className="af-warning"
                    src="/images/icon_warning.svg"
                  />
                  <div>
                    <span>
                      {getCode(
                        "YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_STAY"
                      )}
                    </span>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </Dialog>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default EditRoles;