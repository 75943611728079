import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo } from "react";
import Countdown from "react-countdown";

const SessionOutCountdown = ({ message, sessionExpiredProp, popupVisibleHandler, onStay, onLogout, countdownRemainingTime }) => {  
  
  const onLogoutHandler = () => {
    onLogout();    
  };

  const onStayHandler = () => {
    onStay();   
  }

  const renderFooter = () => {
    return (
      <div>         
       <Button
          label="Log Out"
          icon="pi pi-times"
          onClick={onLogoutHandler} 
          className="p-button-text"        
        />   
        <Button
          label="Stay Logged In"
          icon="pi pi-check"
          onClick={onStayHandler}
          autoFocus
        /> 
      </div>
    );
  };
  
  
// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    onLogout();   
    return (<span></span>);
  } else {
    // Render a countdown pop up
    return (     
       <div className="af-confirmation">
       <div className="af-confirmation-dialog">
         <Dialog
           closable={false}
           header="Inactivity Timeout"
           visible={sessionExpiredProp}
           modal
           footer={renderFooter()}
           onHide={() => popupVisibleHandler(false)}>
           <div className="confirmation-content">
             <img
               title=""
               alt=""
               className="af-warning"
               src="/images/icon_warning.svg"
             />
             <span style={{ paddingLeft: '10px'}}>{`${message} in ${minutes} ${minutes > 1 ? "minutes": "minute"}, ${seconds} seconds.`}</span>
           </div>
         </Dialog>
       </div>
     </div>
    );
  }
};

  return (
    <>
    {sessionExpiredProp && <Countdown date={Date.now() + countdownRemainingTime} renderer={renderer} />}
    </>
  );
};

export default memo(SessionOutCountdown);
