export var MAX_CHARS_SHORTCUT = 31;
export var AUTOTEXT_FIRM_FEATURE_NAME = "MG-Account-MD-Autotext-FE-Autotext-Firm";
export var PRACTICE_ADDTIME_MANAGETIME_FEATURE_NAME = "MG-Practice-MD-TM-FE-AddTime-MngTime";
export var ADDTIME_MANAGETIME_GENERALTIME_FEATURE_NAME = "MG-Practice-MD-TM-FE-Add-Mng-GeneralTime";
export var ADDTIME_MANAGETIME_RELEASETIME_FEATURE_NAME = "MG-Practice-MD-TM-FE-AddTime-MngTime-ReleaseTime";
export var SETTINGS_ACCESS_USER_ROLES_FEATURE_NAME = "MG-Settings-MD-Access-FE-Users-Roles";
export var AUTOTEXT_PERSONAL_FEATURE_NAME = "MG-Account-MD-Autotext-FE-Autotext";
export var AUTOTEXT_PERSONAL_FEATURE_ID = 5;
export var MAX_CHAR_LIMIT = 5000;
export var NO_OF_ROWS_IN_GRID = 20;
export var REPEATED_TIME_ENTRY_ERROR = "SOME_OF_THE_REPEATED_TIME_ENTRIES_HAVE_ERRORS_AND_WILL_BE_CREATED_AS_INVALID_STATUS_ENTRIES";
export var MAX_CHAR_LIMIT_CLIENT_MATTER = 50;
export var USER_CREATION_FAILED = "User creation failed.";
export var MAX_ATTACHMENT_LIMIT = 50;
export var MAX_MESSAGE_LIMIT = 100;