import React from 'react';
import { useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import CalendarIntegrationInfo from "../Configuration/Tabs/CalendarIntegrationInfo/CalendarIntegrationInfo"
import jsUtils from "../../../../utils/jsUtils"
import "./ConfigurationScreen.scss"
import GeneralScreen from './Tabs/GeneralScreen/GeneralScreen';
import { useSelector } from 'react-redux';
import ConfirmationDialogBox from "../../../common/ConfirmationBox/ConfirmationDialogBox";

const ConfigurationScreen = () => {

    const ConfigurationTabMenus= [
          { id: 1, label: jsUtils.getLiteralValue('GENERAL') },
          { id: 2, label: jsUtils.getLiteralValue('MS_CALENDAR_INTEGRATION') }  
      ]
      
      const [activeItem, setActiveItem] = useState(1);
      const [retainTemporaryData, setRetainTemporaryData] = useState(null);
      const [showUnsavedChanges , setShowUnsavedChanges] = useState(false);
      const [tabEvent , setTabEvent] = useState(null);
      const PreferenceMenuObj = useSelector((state) => state.LandingPageReducer.PreferenceMenuObj);
      const unsavedChanges = useSelector((state) => state.ConfirmationReducer.unsavedChanges);

      const tabChange = (evt , _unsavedChanges) => {
        if( _unsavedChanges )
        {
            setShowUnsavedChanges(true);
            setTabEvent(evt);
        
        }
        else{
            setActiveItem(evt.value.id);
            setRetainTemporaryData(null);
        }
       
    }

    const onRetainTemporaryData = (data) => {
        setRetainTemporaryData(data);
      }
    
    const loadDynamicTabComponent = () => {
        switch (activeItem) {
            case 1:
               return <GeneralScreen onRetainData={onRetainTemporaryData} practiceObj={PreferenceMenuObj && PreferenceMenuObj[0] && PreferenceMenuObj[0].id} retainTemporaryData={retainTemporaryData} />
            case 2:
                return <CalendarIntegrationInfo />
            default:
                return <GeneralScreen onRetainData={onRetainTemporaryData} practiceObj={PreferenceMenuObj && PreferenceMenuObj[0] && PreferenceMenuObj[0].id} retainTemporaryData={retainTemporaryData}/>    
        }
    }

    return (
        <div className="config-container">
           
            <div>
                    <TabMenu
                    model={ConfigurationTabMenus}
                    activeItem={activeItem === 1 ? ConfigurationTabMenus[0]:ConfigurationTabMenus[1]}
                    onTabChange={(e) => {if(e.value.id != activeItem ){tabChange(e , unsavedChanges)}}}
                    />
            </div>
            <div className="tab-body-container">
                {loadDynamicTabComponent()}
            </div>
            <ConfirmationDialogBox
              visible={showUnsavedChanges}
              message={jsUtils.getLiteralValue("YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_STAY")}
              header="Confirmation"            
              onConfirm={()=>{
                setShowUnsavedChanges(false); 
              }}
              onCancel={()=>{
                  
                tabChange(tabEvent , false);
                setShowUnsavedChanges(false);
                
              }}
              disableCancelButton={false}
              disableConfirmButton={false}
              confirmButtonLabel={jsUtils.getLiteralValue("STAY")}
              confirmButtonClassName={"yes-button"}
              cancelButtonClassName={"p-button-text no-button"}
              cancelButtonLabel={jsUtils.getLiteralValue("LEAVE")}
              imageClassName={"af-warning"}
              imageSrc={"/images/icon_warning.svg"}
              icon={" "}
          />
        </div>
    )
}

export default ConfigurationScreen;