import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import _ from "lodash"; /**
                        * Expands abbreviations to match the word given in the
                        * Dictionary. Only works for the last word.
                        *
                        * @param {string} value
                        * @param {Map<string, string>} dictionary
                        */
export var getAutoCorrectedValue = function getAutoCorrectedValue(value, dictionary) {
  // Drop the last space. Double spaces are not
  // sent here
  if (value.charAt(value.length - 1) === " ") {
    var lIndex = value.length - 2;
    var sIndex = lIndex;
    var lastWord = ""; // Collect the last word
    while (sIndex >= 0) {
      var char = value.charAt(sIndex);
      if (char === " " || char === "\n") {
        break;
      }
      lastWord = char + lastWord;
      --sIndex;
    }
    var sentence = value.trim(); // See if the word is in the Dic
    var word = dictionary && dictionary[lastWord];
    if (word && typeof word !== "string") {
      word = word[0];
    } //  const word = obj['fulltext']
    var isModified = !_.isUndefined(word);
    if (isModified) {
      var leading = value.substr(0, sIndex + 1);
      var trailing = value.substr(lIndex + 1, value.length);
      sentence = leading + word + trailing;
    }
    return {
      isModified: isModified,
      sentence: sentence
    };
  } else {
    return {
      isModified: false,
      sentence: value
    };
  }
};
export var getAutoCorrectedValueExt = function getAutoCorrectedValueExt(value, dictionary) {
  // Drop the last space. Double spaces are not
  // sent here
  var persistWithlineBreaks = value;
  value = value.replace(/(\r\n|\n|\r|[.,?!])/gi, " ");
  var narrativeWordList = value.split(" ");
  var enteredAutotext = narrativeWordList.filter(function (word) {
    return dictionary.hasOwnProperty(word);
  });
  var narrative = value;
  var isModified = false;
  if (enteredAutotext.length > 0) {
    isModified = true;
    var word = enteredAutotext[0];
    var regex = new RegExp('\\b' + word + '\\b');
    var findWordSIndex = value.search(regex);
    var findwordLIndex = findWordSIndex + word.length;
    var txWord = dictionary && dictionary[word];
    var leading = persistWithlineBreaks.substr(0, findWordSIndex);
    var trailing = persistWithlineBreaks.substr(findwordLIndex + 1, value.length);
    narrative = leading + " " + txWord + " " + trailing;
    return {
      isModified: isModified,
      sentence: narrative
    };
  } else {
    return {
      isModified: false,
      sentence: value
    };
  }
};
export var getAutoCorrectedValueExtWithWord = function getAutoCorrectedValueExtWithWord(value, dictionary, lastWord, lastWordStartIndex) {
  // Drop the last space. Double spaces are not
  var persistWithlineBreaks = value;
  var lastCharInValue = lastWord === null || lastWord === void 0 ? void 0 : lastWord.slice(-1);
  value = value.replace(/(\r\n|\n|\r|[.,?!])/gi, " ");
  var enteredAutotext = dictionary.hasOwnProperty(lastWord);
  var narrative = value.trim();
  var isModified = false;
  if (enteredAutotext && !(lastCharInValue.includes("?") || lastCharInValue.includes(".") || lastCharInValue.includes(",") || lastCharInValue.includes("!"))) {
    isModified = true; // let word = enteredAutotext[0];
    var word = lastWord;
    var findwordLIndex = lastWordStartIndex + word.length;
    var txWord = dictionary && dictionary[word];
    var leading = persistWithlineBreaks.substr(0, lastWordStartIndex);
    var trailing = persistWithlineBreaks.substr(findwordLIndex + 1, value.length); // narrative = leading + " "+ txWord + " " + trailing;
    narrative = leading + txWord + " " + trailing;
    return {
      isModified: isModified,
      sentence: narrative.trim()
    };
  } else {
    return {
      isModified: false,
      sentence: value
    };
  }
}; /**
   * checks if narrative text contains restricted words and returns array of restricted words present in text area
   * @param {string} narrativeText 
   * @param {Array} restrictedWords
   * @param {Array} enteredRestrictedWords
   */ /*
      export const checkIsRestrictedWord = (
      narrativeText,
      restrictedWords,
      enteredRestrictedWords
      ) => {
      narrativeText = narrativeText.replace(/(\r\n|\n|\r|[.,?!])/gi, " ");
      let narrativeWordList = narrativeText.split(" ");
      enteredRestrictedWords = narrativeWordList.filter(word => 
       restrictedWords.some(obj => word && obj.restrictedWord && obj.restrictedWord.toUpperCase() === word.toUpperCase())
      );
      return [...new Set(enteredRestrictedWords)];
      }; */ /**
            * replaces clicked words with suggested Replacement word
            * @param {string} narrativeText 
            * @param {Array} restrictedWords
            * @param {Array} enteredRestrictedWords
            */
export var replaceWordsInNarrative = function replaceWordsInNarrative(narrativeText, selectedWord, replaceTo) {
  if (selectedWord.restrictedWord && selectedWord.restrictedWord.match(/[^a-zA-Z0-9]/gi) && selectedWord.restrictedWord.length === 1) {
    narrativeText = narrativeText.split(selectedWord.restrictedWord.trim()).join(replaceTo);
  } else if (selectedWord.restrictedWord && selectedWord.restrictedWord.match(/[^a-zA-Z0-9]/gi) && selectedWord.restrictedWord.length > 1) {
    //for case-insensitive replace all
    narrativeText = narrativeText.replace(new RegExp(selectedWord.restrictedWord.trim(), "gi"), replaceTo);
  } else {
    var regEx = new RegExp("\\b(".concat(selectedWord.restrictedWord.trim(), ")\\b"), "gi");
    narrativeText = narrativeText.replace(regEx, replaceTo);
  }
  return {
    narrativeTextNew: narrativeText
  };
}; /**
   * replaces clicked words with suggested Replacement word
   * @param {string} narrativeText 
   * @param {Object} selectedWord
   * @param {string} replaceTo
   * @param {Array} enteredRestrictedWords
   */ // export const replaceWordAtIndexInNarrative = (
//   narrativeText,
//   selectedWord,
//   replaceTo,
//   enteredRestrictedWords
// ) => {
//   let newValue = narrativeText;
//   let selectedRWord = selectedWord && selectedWord.restrictedWord;
//   if (newValue && selectedWord) {
//     newValue = replaceAt(narrativeText, selectedWord.beginIndex, selectedWord.endIndex, replaceTo);
//   }
//   enteredRestrictedWords = enteredRestrictedWords.filter(
//     (word) => word && selectedRWord && word.toUpperCase() !== selectedRWord.toUpperCase()
//   );
//   return {
//     narrativeTextNew: newValue,
//     enteredRestrictedWordsNew: enteredRestrictedWords,
//   };
// };
/**
* replaces clicked words with suggested Replacement word
* @param {string} narrativeText 
* @param {Array} restrictedWords
* @param {Array} enteredRestrictedWords
* @param {Object} selectedWord
*/
export var ignoreWordsInNarrative = function ignoreWordsInNarrative(narrativeText, restrictedWords, selectedWord) {
  narrativeText = narrativeText.replace(/(\r\n|\n|\r|[.,?!])/gi, " ");
  var narrativeWordList = narrativeText.split(" ");
  var selectedRWord = selectedWord && selectedWord.restrictedWord.trim();
  narrativeWordList.forEach(function () {
    restrictedWords = restrictedWords.filter(function (obj) {
      return selectedRWord && obj.restrictedWord && obj.restrictedWord.trim().toUpperCase() !== selectedRWord.toUpperCase();
    });
  });
  return {
    restrictedWordsNew: restrictedWords
  };
}; /** adds escape character
   * @param {String}
   */
var addEscapeCharacter = function addEscapeCharacter(str) {
  return str.replace(/[(]/g, "\\(").replace(/[)]/g, "\\)").replace(/[/]/g, "\\/").replace(/[[]/g, "\\[").replace(/[|]/g, "\\|").replace(/[$]/g, "\\$").replace(/[\^]/g, "\\^").replace(/[+]/g, "\\+").replace(/[*]/g, "\\*").replace(/[%]/g, "\\%").replace(/[?]/g, "\\?");
}; /** converts API response into UI compatible Json 
   * @param {Array} allRestrictedWords
   */
export var onLoadRestrictedWords = function onLoadRestrictedWords(allRestrictedWords) {
  var highlightJson = [{
    "highlight": ""
  }];
  var errorSet, warningSet, errorSet2, warningSet2;
  var maxLimit = 5000;
  var maxLimitType = "E";
  var maxLimitMsg = "";
  if (allRestrictedWords && allRestrictedWords.length > 0) {
    allRestrictedWords.forEach(function (wordSet) {
      if (wordSet.type === "error") {
        errorSet = wordSet.data.map(function (obj) {
          if (obj.restrictedWord && obj.restrictedWord.match(/[^a-zA-Z0-9]/gi) && obj.restrictedWord.length === 1) {
            return applyRegexForSingleSpecialChar(obj, "error", "red");
          } else if (obj.restrictedWord && obj.restrictedWord.match(/[^a-zA-Z0-9]/gi) && obj.restrictedWord.length > 1) {
            return applyRegexForWordWithSpecialChar(obj, "error", "red");
          } else {
            return applyRegexForNormalWord(obj, "error", "red");
          }
        });
        errorSet2 = wordSet.data.filter(function (obj) {
          return obj.restrictedWord && obj.restrictedWord.match(/[^a-zA-Z0-9]/gi) && obj.restrictedWord.length > 1;
        });
        errorSet2 = errorSet2.map(function (obj) {
          return applyRegexForFirstWordSpecialChar(obj, "error", "red");
        });
      }
      if (wordSet.type === "warning") {
        warningSet = wordSet.data.map(function (obj) {
          if (obj.restrictedWord && obj.restrictedWord.match(/[^a-zA-Z0-9]/gi) && obj.restrictedWord.length === 1) {
            return applyRegexForSingleSpecialChar(obj, "warning", "yellow");
          } else if (obj.restrictedWord && obj.restrictedWord.match(/[^a-zA-Z0-9]/gi) && obj.restrictedWord.length > 1) {
            return applyRegexForWordWithSpecialChar(obj, "warning", "yellow");
          } else {
            return applyRegexForNormalWord(obj, "warning", "yellow");
          }
        });
        warningSet2 = wordSet.data.filter(function (obj) {
          return obj.restrictedWord && obj.restrictedWord.match(/[^a-zA-Z0-9]/gi) && obj.restrictedWord.length > 1;
        });
        warningSet2 = warningSet2.map(function (obj) {
          return applyRegexForFirstWordSpecialChar(obj, "warning", "yellow");
        });
      }
      maxLimit = wordSet.maxLimit && Number(wordSet.maxLimit) || 5000;
      maxLimitType = wordSet.type === "W" ? "W" : "E";
      maxLimitMsg = wordSet.errorMessage;
    });
    highlightJson = [].concat(_toConsumableArray(errorSet), _toConsumableArray(warningSet), _toConsumableArray(errorSet2), _toConsumableArray(warningSet2));
  }
  return {
    highlightJson: highlightJson,
    maxLimit: maxLimit,
    maxLimitType: maxLimitType,
    maxLimitMsg: maxLimitMsg
  };
};
var applyRegexForNormalWord = function applyRegexForNormalWord(obj, typeValue, classNameValue) {
  return _objectSpread(_objectSpread({}, obj), {}, {
    type: typeValue,
    highlight: new RegExp("\\b(".concat(addEscapeCharacter(obj.restrictedWord), ")\\b"), "gi"),
    className: classNameValue
  });
};
var applyRegexForWordWithSpecialChar = function applyRegexForWordWithSpecialChar(obj, typeValue, classNameValue) {
  return _objectSpread(_objectSpread({}, obj), {}, {
    type: typeValue,
    highlight: new RegExp("[\\s\n](".concat(addEscapeCharacter(obj.restrictedWord), ")[\\s\n]"), "gi"),
    className: classNameValue
  });
};
var applyRegexForFirstWordSpecialChar = function applyRegexForFirstWordSpecialChar(obj, typeValue, classNameValue) {
  return _objectSpread(_objectSpread({}, obj), {}, {
    type: typeValue,
    highlight: new RegExp("(^)(".concat(addEscapeCharacter(obj.restrictedWord), ")[\\s\n]"), "gi"),
    className: classNameValue
  });
};
var applyRegexForSingleSpecialChar = function applyRegexForSingleSpecialChar(obj, typeValue, classNameValue) {
  return _objectSpread(_objectSpread({}, obj), {}, {
    type: typeValue,
    highlight: obj.restrictedWord,
    className: classNameValue
  });
}; /** get last word and index
   * @param {event} Object
   */
export var getLastWordAndIndex = function getLastWordAndIndex(event) {
  var lastWord = "";
  var lastWordStartIndex = 0;
  if (event && event.target) {
    var endIndex = event.target.selectionStart;
    var stringUpToCurser = event.target.value.slice(0, endIndex); //let replaceWithSpace = stringUpToCurser.replace(/(\r\n|\n|\r|[.,?!])/gi, " ");
    var replaceWithSpace = stringUpToCurser.replace(/(\r\n|\n|\r)/gi, " ");
    var allWordsUpToCursor = replaceWithSpace.trim().split(" ");
    lastWord = allWordsUpToCursor[allWordsUpToCursor.length - 1];
    lastWordStartIndex = endIndex - (lastWord.length + 1);
  }
  return {
    lastWord: lastWord,
    lastWordStartIndex: lastWordStartIndex
  };
}; /** get sentence case
   * @param {str} String
   */
var replaceChar = function replaceChar(origString, _replaceChar, index) {
  var newStringArray = origString.split("");
  newStringArray[index] = _replaceChar;
  var newString = newStringArray.join("");
  return newString;
};
export var sentenceCase = function sentenceCase(str) {
  var formattedStr = str;
  var specialChars = ['!', '?', '.', '\n'];
  for (var i = 0; i < str.length; i++) {
    if (i === 0) {
      formattedStr = replaceChar(formattedStr, str[i].toUpperCase(), i);
    } else if (specialChars.includes(str[i])) {
      if (str[i + 1] && /[a-zA-Z]/.test(str[i + 1])) {
        if (str[i + 2] === " ") {
          formattedStr = replaceChar(formattedStr, str[i + 1].toUpperCase(), i + 1);
        }
      } else if (str[i + 2] && /[a-zA-Z]/.test(str[i + 2])) {
        formattedStr = replaceChar(formattedStr, str[i + 2].toUpperCase(), i + 2);
      }
    }
  }
  return {
    formattedStr: formattedStr
  };
};