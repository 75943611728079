import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { TRANSFER_TE_FAILED, TRANSFER_TE_SUCCESSFUL, SET_SHOWATTORNEYLIST_POPUP_FLAG, SET_CHECKEDBILLABLE, SET_CHECKEDNONBILLABLE, SET_CHECKEDUNRELEASED, SET_CHECKEDSTATE, SET_DATE, SET_ETHICALWALLUSERIDS, SET_TRANSFERFEATURENAME, RESET_TRANSFER_TE_STATUS } from "../actions/ActionType";
import initialState from "../store/InitialState";
var DayViewReducer = function DayViewReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.DayViewReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case TRANSFER_TE_SUCCESSFUL:
    case TRANSFER_TE_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        transferTE: action.data,
        showAttorneyListPopup: false
      });
    case RESET_TRANSFER_TE_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        transferTE: action.data
      });
    case SET_SHOWATTORNEYLIST_POPUP_FLAG:
      return _objectSpread(_objectSpread({}, state), {}, {
        showAttorneyListPopup: action.data
      });
    case SET_CHECKEDBILLABLE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          checkBillable: action.data
        });
      }
    case SET_CHECKEDNONBILLABLE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          checkNonBillable: action.data
        });
      }
    case SET_CHECKEDUNRELEASED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          checkUnreleased: action.data
        });
      }
    case SET_DATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          date: action.data
        });
      }
    case SET_CHECKEDSTATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          checkBillable: action.data.checkBillable,
          checkNonBillable: action.data.checkNonBillable,
          checkUnreleased: action.data.checkUnreleased,
          date: action.data.date
        });
      }
    case SET_ETHICALWALLUSERIDS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          ethicalWallUserIds: action.data
        });
      }
    case SET_TRANSFERFEATURENAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          transferFeatureName: action.data
        });
      }
    default:
      return state;
  }
};
export default DayViewReducer;