import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import Spinner from "../../common/Spinner/Spinner";
import { Calendar } from "primereact/calendar";
import "./MyProfile.scss";
import { InputSwitch } from "primereact/inputswitch";
import Avatar from "react-avatar";
import { InputText } from "primereact/inputtext";
import { userApi } from "../../../services/userapis";
import moment from "moment";
import { Toast } from "primereact/toast";
import ProfilePasswordField from "./../../common/ProfilePasswordField/ProfilePasswordField";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../../services/authService";
import { resetAuthDetails } from "../../../actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../utils/storage";
import { setErrorMessage } from "../../../actions/AppAction";
import { HELP_LINKS, TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../config/vars";
import { helpPageUrl } from '../../../config';
import * as literalCodes from '../../../config/literalCodes';
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { setSelectedTimeKeeperFeature } from "../../../actions/TimeManagementAction";
import { ResetReducers } from "../../../actions/GlobalAction";
import jsUtils from "../../../utils/jsUtils";
import { setSessionTimeout, setUserPreferenceDetailsAction } from "../../../actions/PreferenceAction";
import ImageUploader from "../../common/ImageUploader/ImageUploader";
import { generalTimeApi } from "../../../services/GeneralTimeApi";
import axios from "axios";
import ConfirmationDialogBox from "../../common/ConfirmationBox/ConfirmationDialogBox";
import { globalApi } from "../../../services/endpoints";
import { setDelegatesOnLogin } from "../../../actions/LandingPageAction";
import { GetSetDelegateData } from "@attorneyflow-mf/styleguide";


function MyProfile() {
  const [userLoading, setUserLoading] = useState(false);
  const history = useHistory();
  const toast = useRef(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sessionValue , setSessionValue] = useState("");
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [, setErrorPasswordDisplay] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [reseting, setReseting] = useState(false);
  const dispatch = useDispatch();
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: [TOOLTIPS_VALUES.CLOSE],
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP]
  })
  const [userDetails, setUserDetails] = useState([]);
  const [userStartDate, setUserStartDate] = useState("");
  const [roleDetails, setRoleDetails] = useState([]);
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [timeoutValue, setTimeoutValue] = useState("15");
  const [updatedDate , setUpdatedDate] = useState("");
  const [time , setTime] = useState("");
  const [avatarName, setAvatarName] = useState("");
  const [firstCallView,setFirstCallView]=useState(true);
  const InactivityData = useSelector((state) => state.PreferenceReducer.preferenceSettings );
  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const dialogFuncMapClose = {
    closeConfirmation: setCloseConfirmation,
  };
  const [changed, setChanged] = useState(false);
  const [userId, setUserId] = useState("");
  const [imageUrl,setImageUrl] = useState(null);
  const [showDeleteConfirmation,setShowDeleteConfirmation] = useState(false);
  const renderFooterClose = () => {
    return (
      <div>
        <Button
          label={getCode("LEAVE")}
          onClick={() => {
            history.goBack();
          }}
          className="p-button-text"
          data-testid="leaveButton"
        />
        <Button
          label={getCode("STAY")}
          onClick={() => {
            setCloseConfirmation(false);
          }}
          autoFocus
          data-testid="stayButton"
        />
      </div>
    );
  };

  const onHideClose = (name) => {
    dialogFuncMapClose[`${name}`](false);
  };

  useEffect(() => {
    loadUserProfileData();
  }, []);

  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key

  };
  const getCodeLabel = (key) => {
    return literalCodes.codes[key] ? literalCodes.codes[key] : key

  };

  /** set tooltips from storage on screen load
  * @param literals -variale to store the array of literals
  */
  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setCodeFromAPI(literals);
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: literals[TOOLTIPS_KEYS.TOOLTIP_CLOSE],
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP]
      })
    }
  }, []);

  useEffect(()=>{
    document.title=jsUtils.getLiteralValue("ATTORNEYFLOW_MY_PROFILE");
  },[])

  const saveImage=(payload)=>{
    userApi.saveImageUrl(payload)
    .then(res => {
        setUserLoading(false);
        globalApi.delegates().then(response => {
          GetSetDelegateData(response);
          dispatch(setDelegatesOnLogin(response))
        })
        .catch(error=>{
            console.log("error delegates", error);
        });

        dispatch(setErrorMessage({ severity: 'success', message: jsUtils.getLiteralValue(res.message), errorCode: 200, life: 5000 }));
        loadUserProfileData();
       })
    .catch(()=>{
        dispatch(setErrorMessage({ severity: 'error', message: jsUtils.getLiteralValue("Error"), errorCode: 500, life: 5000 }));
        setUserLoading(false);
       })
  }

  const onUploadImage=(imageFile,_userId)=>{
    setUserLoading(true)
    const request_body = [{
        filename: imageFile.filename,
        title: imageFile.title,
        folder: 'user_profile_images'
      }];
      generalTimeApi.addAttachment({ files: request_body }).then(response => {
        if (response && response.success && response.signedUrl?.length > 0) {
          const { put_signed_url, key, dms_source } = response.signedUrl[0];
          try {
            var config = {
              method: 'put',
              url: put_signed_url,
              headers: {
                "Content-Type": imageFile.contentType
              },
              data: imageFile.file
            };
            axios(config).then(function (res_data) {
              if (res_data?.status === 200){
                delete imageFile.file;
                imageFile.put_signed_url = put_signed_url;
                imageFile.s3Key =  key;
                imageFile.dms_source = dms_source;
                //Update List
                saveImage({"sys_user_id": _userId,
                "image_url": dms_source});

                
              }
            }).catch(function (error) {
              setUserLoading(false);
            });
          } catch (e) {
            setUserLoading(false);
          }
        }
      }).catch((err) => {
        setUserLoading(false);

        setAttachmentsLoading(false);
      });
  }

  const onRemovePhoto=()=>{

    setUserLoading(true)
    saveImage({"sys_user_id":userDetails.sys_user_id,"image_url":null})
  }

  const dialogFooter = (
   
    <div className="footer-buttons">
      <Button
        label={getCode("CANCEL")}
        icon="pi pi-times"
        onClick={(e) => {
          setDisplayConfirmation(false);
        }}
        className="p-button-text"
        data-testid="cancleButton"
      />
      <Button
        label={getCode("ACKNOWLEDGE")}
        icon="pi pi-check"
        onClick={(e) => {
          saveSessionTimeout("720");
          setDisplayConfirmation(false);
        }}
        className="yes-button"
        autoFocus
        data-testid="acknowledgeButton"
      />
    </div>
  );

  const saveSessionTimeout = async (valueSelected) => {
    setReseting(true);
    let data = {
      params: [
        {
            param_key: "AUTOMATIC_SESSION_TIMEOUT",
            param_value: valueSelected
        }
    ]
    };
    await userApi
      .updatePassword(data)
      .then((res) => {
        dispatch(setErrorMessage({ severity: 'success', message: (res && getCode(res.message)), errorCode: res.responseCode }));
        setTimeoutValue(valueSelected);
        dispatch(setSessionTimeout(valueSelected));
        dispatch(userApi.getUserPreferenceDetails());
        //reloads page and navigate to home page to apply new timeout config
        history.push("/");
        window.location.reload();        
        //setRefresh(refresh+1);
      })

      .catch((err) => {
        dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: err.responseCode }));
      })
      .finally(() => {
        setReseting(false);
      });
};

  useEffect(() =>{ let sessionTimeout = InactivityData && InactivityData.find(
    (item) => item.param_key === "AUTOMATIC_SESSION_TIMEOUT"
  );
  if(sessionTimeout){ 
  setTimeoutValue(sessionTimeout.param_value);
  if(sessionTimeout.updated_date){
  setSessionValue(sessionTimeout.updated_date);
  let upDate = sessionTimeout.updated_date.substring(0, 10).split("-");
  let upOnDate = upDate[1] + "/" + upDate[2] + "/" + upDate[0];
  let upTime = sessionTimeout.updated_date.substring(11, 19);
  setUpdatedDate(upOnDate);
  setTime(upTime);
}}
},[InactivityData])


  const updatePassword = (oldPass) => {
    setChanged(true);
    setPassword(oldPass);
    checkPassword(oldPass)
      ? setErrorPasswordDisplay(false)
      : setErrorPasswordDisplay(true);
  };

  const updateNewPassword = (newPass) => {
    setChanged(true);
    setNewPassword(newPass);
    checkPassword(newPass) ? setErrorDisplay(false) : setErrorDisplay(true);
  };

  const updateNewMfaPassword = (newPass) => {
    setChanged(true);
    setNewPassword(newPass);
    checkMfaPassword(newPass) ? setErrorDisplay(false) : setErrorDisplay(true);

  }

  const updateConfirmPassword = (updatedPassword) => {
    setChanged(true);
    setConfirmPassword(updatedPassword);
  };

  const checkPassword = (inputtxt) => {
    let a = "(?=.*[a-z])", b = "(?=.*[A-Z])", c = "(?=.*[0-9])", d = ".{8";
    var passw = new RegExp("^" + a + b + c + d + ",}$"); //hotspot fix
    return inputtxt.match(passw) ? true : false;
  };

  const checkMfaPassword = (inputtxt) => {
    let a = "(?=.*[a-z])", b = "(?=.*[A-Z])", c = "(?=.*[0-9])", d= "(?=.*?[#?!@$%^&*-])" ,e = ".{14";
    var passw = new RegExp("^" + a + b + c + d + e + ",}$");
    return inputtxt.match(passw) ? true : false;
  };

  const hideDialog = () => {
    if (changed) {
      setCloseConfirmation(true);
    } else {
      history.goBack();
    }
  };

  const getUserInitials = (userDataInitials) => {
    let initials = "";
    if(userDataInitials){
        if(userDataInitials.firstname && userDataInitials.lastname){
          initials = (userDataInitials.firstname || '') + " "+ (userDataInitials.lastname || '');
        }        
      }
      setAvatarName(initials && initials.trim());
  }

const setUserPhotoUrl=(_user)=>{
  if(_user.user_profile_pic_s3_url){
    setImageUrl(_user.user_profile_pic_s3_url)
  }
  else{
    getUserInitials(_user);
    setImageUrl(null);
  }
}

  const loadUserProfileData = () => {
    setUserLoading(true);
    userApi
      .getUserProfileDetails(firstCallView ? literalCodes.HeaderView.MyprofileViewApiCallHeaderName : null)
      .then((res) => {
        dispatch(setUserPreferenceDetailsAction(res));
        if (res && res.userData && res.userData.user) {
          setUserDetails(res.userData.user);
          setUserId(res.userData.user.sys_user_id)
          if(isValidDate(res.userData.user.effective_start_date)){
              let stDate = res.userData.user.effective_start_date.substring(0, 10);
              let stDateSplit = stDate.split("-")
              let stDateFinal = stDateSplit[1] + "/" + stDateSplit[2] + "/" + stDateSplit[0];
              setUserStartDate(new Date(stDateFinal))
          }
          setUserPhotoUrl(res.userData.user);
        }

        if (res && res.userData && res.userData.roles) {

          let formattedJson = { role: [] };
            res.userData.roles
              .sort(function (a, b) {
                if (a.Role === b.Role) {
                  return 0;
                }
                if (typeof a.Role === typeof b.Role) {
                  return a.Role < b.Role ? -1 : 1;
                }
                return typeof a.Role < typeof b.Role ? -1 : 1;
              })
              .sort(function (a, b) {
                return new Date(moment(b.From, "YYYY-MM-DD").format("YYYY-MM-DD")) - new Date(moment(a.From, "YYYY-MM-DD").format("YYYY-MM-DD"));
              })
              .map((role, i) => {
                let fromDateStatus = isValidDate(role.From);
                let toDateStatus = isValidDate(role.To);

                let fromDate = role.From ?
                  moment(`${role.From.slice(0, 10)}`).format("MM/DD/YYYY") :
                  moment(
                    role.From,
                    "YYYY-MM-DD"
                  ).format("MM/DD/YYYY");

                let toDate = role.To ?
                  moment(`${role.To.slice(0, 10)}`).format("MM/DD/YYYY") :
                  moment(role.To, "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  );

                  let roleItem = {
                    role : role.Role,
                    from: fromDateStatus ? fromDate : "",
                    to : toDateStatus ? toDate : ""
                  }
                  formattedJson.role.push(roleItem);
              })

          setRoleDetails(formattedJson.role);
        }
       
        if (res && res.userData && res.userData.preference) {
        let sessionTimeout = res.userData.preference.find(
          (item) => item.param_key === "AUTOMATIC_SESSION_TIMEOUT"
        );
        if(sessionTimeout){ 
        setSessionValue(sessionTimeout.updated_date);
        setTimeoutValue(sessionTimeout.param_value);
        let upDate = sessionTimeout.updated_date.substring(0, 10).split("-");
        let upOnDate = upDate[1] + "/" + upDate[2] + "/" + upDate[0];
        let upTime = sessionTimeout.updated_date.substring(11, 19);
        setUpdatedDate(upOnDate);
        setTime(upTime);}}
      })
      .catch((err) => {
        setUserLoading(false);
        dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: err.responseCode }));
      })
      .finally(() => {
        setUserLoading(false);
      });
      setFirstCallView(false);
  };

  const resetPassword = async () => {
    setReseting(true);
    let data = {
      username: userDetails && userDetails.username ? userDetails.username : "",
      email: userDetails && userDetails.email ? userDetails.email : "",
      old_password: password,
      new_password: newPassword
    };
    await userApi
      .updatePassword(data)
      .then((res) => {
        dispatch(setErrorMessage({ severity: 'success', message: (res && getCode(res.message)), errorCode: res.responseCode }));
        setTimeout(() => {
          AuthService.signOut(data.email, localStorage.getItem("refreshToken")).then(() => {
            dispatch(resetAuthDetails());
            dispatch(ResetReducers());
            dispatch(setSelectedTimeKeeperFeature("",""));
            sessionStorage.removeItem("token");
            storage.clearLocalStorge();
            history.push("/");
          });
        }, 4000);

      })
      .catch((err) => {
        dispatch(setErrorMessage({ severity: 'error', message: (err && getCode(err.message)), errorCode: err.responseCode }));
      })
      .finally(() => {
        setReseting(false);
      });
  };

  const isValidDate = (date) => {
    let status = moment(
      moment(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      "DD/MM/YYYY",
      true
    ).isValid();
    return status;
  };
  return (
    <div className="af-modal-dialog-profile">
      <Toast ref={toast} />
      <Dialog
        header=""
        visible={true}
        className="af-modal-dialog"
        closable={false}
        onHide={hideDialog}
      >
        <div className="p-dialog-header">
          <div className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
              <li className="">
                <span className="p-menuitem-link">
                  <span className="p-menuitem-text">
                    {getCode("MY_PROFILE")}
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <span id="pr_id_1_header" className="p-dialog-title"></span>
          <div className="p-dialog-header-icons">
            <button
              onClick={hideDialog}
              type="button"
              className="p-dialog-header-icon p-dialog-header-close p-link"
              aria-label="Close"
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_CLOSE]}
            >
              <span className="p-dialog-header-close-icon pi pi-times"></span>
            </button>
          </div>
        </div>
        {userLoading ? (
          <div className="usercard-spinner">
            <Spinner />
          </div>
        ) : (
          <div className="af-popup-body af-myprofile">
            <a href={`${helpPageUrl}${HELP_LINKS.MY_PROFILE}`} target="_blank">
              <img
                title={tooltips[TOOLTIPS_KEYS.TOOLTIP_HELP]}
                className="af-help-profile"
                src="/images/svg/Help_icon.svg"
                alt=""
              />
            </a>

            {/* <span style={{display:"flex"}} className="p-dialog-header-icons">
              <button type="button" className="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close" title="abc">
                <span className="p-dialog-header-close-icon pi pi-times"></span>
                </button>
              </span> */}

            <div className="p-grid edit-user-content">
              <div className="p-sm-12 p-md-12 p-lg-8">
                <div className="at-usercol">
                  <div className="at-user-detail">
                    <div className="sec-holder">
                      <div className="label-holder">
                        {/* <label>Company </label> */}
                        <label>{getCode("COMPANY")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <InputText
                          value={
                            userDetails && userDetails.sys_firm_name
                              ? userDetails.sys_firm_name
                              : ""
                          }
                          id="companyName"
                          type="text"
                          disabled={true}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("USERNAME")} </label>
                        <label>{getCode("EMPLOYEE_ID")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <InputText
                          value={
                            userDetails && userDetails.username
                              ? userDetails.username
                              : ""
                          }
                          id="UsernameTxt"
                          type="text"
                          disabled={true}
                          autoComplete="off"
                        />
                        <InputText
                          value={
                            userDetails && userDetails.employee_id
                              ? userDetails.employee_id
                              : ""
                          }
                          id="EmpID"
                          type="text"
                          disabled={true}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("LAST_NAME")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <InputText
                          value={
                            userDetails && userDetails.lastname
                              ? userDetails.lastname
                              : ""
                          }
                          id="LastName"
                          type="text"
                          disabled={true}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("FIRST_NAME")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <InputText
                          value={
                            userDetails && userDetails.firstname
                              ? userDetails.firstname
                              : ""
                          }
                          id="FirstName"
                          type="text"
                          disabled={true}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("MIDDLE_NAME")}</label>{" "}
                        <label>{getCode("PREFERRED_NAME")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <InputText
                          value={
                            userDetails && userDetails.middle_name
                              ? userDetails.middle_name
                              : ""
                          }
                          id="UsernameTxt"
                          type="text"
                          disabled={true}
                          autoComplete="off"
                        />
                        <InputText
                          value={
                            userDetails && userDetails.preferred_name
                              ? userDetails.preferred_name
                              : ""
                          }
                          id="preferredName"
                          type="text"
                          disabled={true}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("EMAIL_")} </label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <InputText
                          value={
                            userDetails && userDetails.email
                              ? userDetails.email
                              : ""
                          }
                          id="emailTxt"
                          type="txt"
                          disabled={true}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("MOBILE_NUMBER")} </label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <InputText
                          value={
                            userDetails && userDetails.mobile
                              ? userDetails.mobile
                              : ""
                          }
                          id="mobileNumber"
                          type="txt"
                          disabled={true}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="label-holder">
                        <label>{getCode("START_DATE")}</label>
                      </div>
                    </div>
                    <div className="sec-holder">
                      <div className="input-holder">
                        <Calendar
                          placeholder="mm/dd/yyyy"
                          id="icon"
                          value={userStartDate}
                          showIcon
                          className="startDate"
                          disabled={true}
                          appendTo={document.body}
                          readOnlyInput
                          showButtonBar
                          //monthNavigator
                          yearNavigator
                          yearRange="1600:6030"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="at-user-detail col-two">
                    <div className="sec-holder">
                      <div className="label-holder avatar-photo">
                        <Avatar
                          size="130"
                          round={true}
                          className="af-avatar-list-items"
                          name={avatarName}
                          src={imageUrl}
                          background="#f3f4f7 !important"
                        />
                        <div>
                        <ImageUploader
                          maxFileSize={2} // size in MB
                          supportedImageFormats={['image/png', 'image/jpeg']}
                          sizeErrorMessage={jsUtils.getLiteralValue("UNSUPPORTED_IMAGE_SIZE_MESSAGE")} 
                          supportedImageFormatErrorMessage={jsUtils.getLiteralValue("UNSUPPORTED_IMAGE_FORMAT_MESSAGE")}
                          onUploadImage={onUploadImage}
                          userId={userId}
                        />
                        {imageUrl && <div className="removePhoto" onClick={()=>{setShowDeleteConfirmation(true)}} data-testid="removePhotoLink">{jsUtils.getLiteralValue("REMOVE_PHOTO")}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="radio-wrapper">
                      <div className="sec-holder">
                        <div className="label-holder">
                          <label>{getCode("TIMEKEEPER")}</label>{" "}
                          <div className="input-radio">
                            {" "}
                            <InputSwitch
                              checked={
                                userDetails && userDetails.is_timekeeper
                                  ? true
                                  : false
                              }
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div className="inactivity-section">
                          <div className="label-holder">
                            <label>{getCode("INACTIVITY_TIMEOUT")}</label>
                          </div>
                          <div className="row-radio-btn-system">
                            <input
                              onChange={() => {
                                saveSessionTimeout("15");
                              }}
                              type="radio"
                              value="15_MINUTES_(DEFAULT)"
                              name="session"
                              checked={timeoutValue === "15" ? true : false}
                              autocomplete="off"
                              data-testid="sessionRadioButton"
                            />
                            <span className="row-radio-element-system">
                              {" "}
                              {getCode("15_MINUTES_(DEFAULT)")}{" "}
                            </span>
                            <input
                              onChange={() => {
                                setDisplayConfirmation(true);
                              }}
                              type="radio"
                              value="12_HOURS"
                              name="session"
                              checked={timeoutValue === "720" ? true : false}
                              autocomplete="off"
                              data-testid="sessionRadioButton"
                            />{" "}
                            <span className="row-radio-element-system">
                              {" "}
                              {getCode("12_HOURS")}
                            </span>
                          </div>
                          {sessionValue ? (
                            <div className="last-updated-info">
                              {getCode("LAST_UPDATED_ON")} {updatedDate}{" "}
                              {getCode("at")} {time}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-sm-12 p-md-12 p-lg-4">
                <div className="roles-col">
                  <div className="sec-holder">
                    <div className="label-holder">
                      <label>
                        {getCode("CURRENT_PASSWORD")}
                        <span className="af-req-star"> *</span>
                      </label>
                    </div>
                  </div>
                  <div className="sec-holder">
                    <div className="input-holder passwordf">
                      <ProfilePasswordField
                        inputPassword={password}
                        onChangePassword={updatePassword}
                        placeholder=""
                        leftIcon={false}
                      />
                    </div>
                    {/* {errorPasswordDisplay ? (
                      <div className="validation-error">
                        {" "}
                        {getCode("PASSWORD_8_CHARACTERS_1_NUMBER_1_UPPERCASE_1_LOWERCASE")}
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="sec-holder">
                    <div className="label-holder">
                      <label>
                        {getCode("NEW_PASSWORD")}
                        <span className="af-req-star"> *</span>
                      </label>
                    </div>
                  </div>
                  <div className="sec-holder">
                    <div className="input-holder passwordf">
                      <ProfilePasswordField
                        inputPassword={newPassword}
                        onChangePassword={
                          localStorage.getItem("MFAuser")
                            ? updateNewMfaPassword
                            : updateNewPassword
                        }
                        placeholder=""
                        leftIcon={false}
                      />
                    </div>
                    {errorDisplay ? (
                      <div className="validation-error">
                        {" "}
                        {localStorage.getItem("MFAuser")
                          ? getCodeLabel(
                              "PASSWORD_14_CHARACTERS_1_NUMBER_1_UPPERCASE_1_LOWERCASE"
                            )
                          : getCodeLabel(
                              "PASSWORD_8_CHARACTERS_1_NUMBER_1_UPPERCASE_1_LOWERCASE"
                            )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="sec-holder">
                    <div className="label-holder">
                      <label>
                        {getCode("CONFIRM_NEW_PASSWORD")}{" "}
                        <span className="af-req-star"> *</span>
                      </label>
                    </div>
                  </div>
                  <div className="sec-holder">
                    <div className="input-holder passwordf">
                      <ProfilePasswordField
                        inputPassword={confirmPassword}
                        onChangePassword={updateConfirmPassword}
                        placeholder=""
                        leftIcon={false}
                      />
                    </div>
                    {confirmPassword && newPassword !== confirmPassword ? (
                      <div className="validation-error">
                        {getCode(
                          "NEW_PASSWORD_AND_CONFIRM_NEW_PASSWORD_MUST_BE_THE_SAME"
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="sec-holder">
                    <div className="input-holder">
                      <button
                        className="btn-full add-role-button"
                        onClick={()=>{
                          if(newPassword.includes(" ")){
                                dispatch(setErrorMessage({ severity: 'error', message: jsUtils.getLiteralValue("PASSWORD_CANNOT_CONTAIN_SPACES"), errorCode: 500 }))
                              }
                            else{
                              resetPassword();
                         }
                      }}
                        disabled={ errorDisplay || (newPassword !== confirmPassword) || password==="" || newPassword ==="" || confirmPassword ===""}
                      >
                        {getCode("UPDATE_PASSWORD")}
                      </button>
                    </div>
                  </div>

                  <div className="sec-holder">
                    <DataTable
                      className="myprofile-roles-table"
                      value={roleDetails}
                    >
                      <Column field="role" header="Assigned Roles"></Column>
                      <Column field="from" header="From"></Column>
                      <Column field="to" header="To"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          
            <ConfirmationDialogBox
              visible={showDeleteConfirmation}
              message={jsUtils.getLiteralValue("DELETE_PHOTO_VALIDATION")}
              header="Confirmation"            
              onConfirm={()=>{
                  onRemovePhoto();
                  setShowDeleteConfirmation(false);
              }}
              onCancel={()=>{
                  setShowDeleteConfirmation(false);
              }}
              disableCancelButton={false}
              disableConfirmButton={false}
              confirmButtonLabel={jsUtils.getLiteralValue("YES")}
              confirmButtonClassName={"yes-button"}
              cancelButtonClassName={"p-button-text no-button"}
              cancelButtonLabel={jsUtils.getLiteralValue("NO")}
              imageClassName={"af-warning"}
              imageSrc={"/images/icon_warning.svg"}
          />

            {reseting && (
              <div className="user-save-spinner">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </Dialog>
      <div className="af-confirmation">
  
  <div className="af-confirmation-dialog">
  <div className="Confirmation-popup">
<Dialog
closable={false}
header="Confirmation"
visible={displayConfirmation}
modal
footer={dialogFooter}
onHide={() => setDisplayConfirmation(false)}
>
<div className="confirmation-content">
<div>
<img
  title=""
  alt=""
  className="af-warning"
  src="/images/icon_warning.svg"
/>
</div>
<div ><div>{getCode("CHANGING_DEFAULT_SESSION_TIMEOUT_!")}</div><div className="confirmation-msg">{getCode("EXTENDED_SESSION_TIMEOUT_WARNING")}</div><div className="confirmation-msg">{getCode("YOUR_ACKNOWLEDGMENT_IS_BEING_LOGGED_BY_SUREPOINT_TECHNOLOGIES")}</div></div>
</div>
</Dialog>  
</div>
    <Dialog
      closable={false}
      header="Confirmation"
      visible={closeConfirmation}
      modal
      footer={renderFooterClose()}
      onHide={() => onHideClose("closeConfirmation")}
    >
      <div className="confirmation-content">
        <img
          title=""
          alt=""
          className="af-warning"
          src="/images/icon_warning.svg"
        />
        <div>
          <span>
            {getCode(
              "YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_STAY"
            )}
          </span>
        </div>
      </div>
    </Dialog>
  </div>
</div>
    </div>
  );
}
export default MyProfile;
