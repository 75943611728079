import React, { useState } from 'react';
import jsUtils from '../../../utils/jsUtils';

import moment from 'moment';
import ConfirmationDialogBox from '../ConfirmationBox/ConfirmationDialogBox';

 const ImageUploader =({maxFileSize,supportedImageFormats,sizeErrorMessage,supportedImageFormatErrorMessage,onUploadImage,userId})=> {
  const hiddenFileInput = React.useRef(null);
  const [showErrorPopup,setShowErrorPopup]=useState(false);
  const [errorMessage,setErrorMessage]=useState("");

  const onValidateFile = (file)=>{
    let sizeInMegabytes=file.size/1024;
    if(supportedImageFormats.findIndex((format) => format === file.contentType) === -1){
        setShowErrorPopup(true);
        setErrorMessage(supportedImageFormatErrorMessage);
    }
    else if(sizeInMegabytes > maxFileSize){
      setShowErrorPopup(true);
      setErrorMessage(sizeErrorMessage);
    }
    else{
        onUploadImage(file,userId);
    }
  }

  const onFilesSelect = (e) => {
    let file;
    var target = e.target || e.srcElement;
    
      file = target.files.length > 0 ? target.files[0] : undefined;
    if (file) {
      var _fileInfo = {
        id: jsUtils.getRandomNumber() * 0.0000000001,//Math.random(),
        filename: "profilepic",
        contentType: file.type,
        size: file.size / 1024,
        file: file, //file.name.includes('.msg') ? reader.result.replace("application/octet-stream", "application/vnd.ms-outlook") : formData,
        created_on: moment(new Date()).format("MM/DD/YYYY"),
        title: "profilepic",
      };
      onValidateFile(_fileInfo);
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    onFilesSelect(e)
  };

  return (
    <>
      <div className="af-btn-panel">
        <button
            className="p-button p-component af-btn-default"
            data-testid="save"
            onClick={handleClick}>
            <span className="p-button-label p-c">
            {jsUtils.getLiteralValue("UPLOAD_PHOTO")}
            </span>
        </button>
      </div>
      <input type="file"
             ref={hiddenFileInput}
             onChange={handleChange}
             style={{display:'none'}} 
             data-testid="fileInput"
      /> 
      <ConfirmationDialogBox
        visible={showErrorPopup}
        message={errorMessage}
        header="Confirmation"            
        onConfirm={()=>{
            setShowErrorPopup(false);
        }}
        disableCancelButton={true}
        confirmButtonLabel={jsUtils.getLiteralValue("OK")}
        confirmButtonClassName={"yes-button"}
        imageClassName={"af-warning"}
        imageSrc={"/images/icon_warning.svg"}
      />
    </>
  );
};
export default ImageUploader;