import React, { useState } from "react";
import { setSelectedRestrictedWord } from "../../../../actions/AppAction";
import { setAutotextValue } from "../../../../actions/AutotextAction";
import { setHighlightRestrictedWords } from "../../../../actions/AddTimeActions";
import { useSelector } from "react-redux";
import Narrative from "./Narrative";
import { useDispatch } from "react-redux";

export default function NarrativeWrapper ({
    module,
    onChangeNarrative,
    onReplaceAll,
    value,
    isNarrativeDisabled,
    onNarrativeCorrection,
    setKeyPressed,
    rows,
    id,
    onChange,
    containerDivClass,  
    spellCheckToggle,
    resetTabToCrossButtonNarrative,
    rwActionChanges = () => {}
}){

    const dispatch = useDispatch();
    const [rwAction, setRwAction] = useState("");
    const { selectedWord } = useSelector(({ AppReducer }) => ({ selectedWord: AppReducer.selectedWord}));
    const { highlightRestrictedWords } = useSelector(({ TimeManagementReducer }) => ({ highlightRestrictedWords: TimeManagementReducer.highlightRestrictedWords}));
    const AutotextReducer = useSelector(( state ) => state.AutotextReducer );
   

    const rwActionHandler =(action)=>{
        setRwAction(action);
    }
    
     const setSelectedRestrictedWordHandler =(data) =>{
      dispatch(setSelectedRestrictedWord(data));
      rwActionChanges(true);
     }
    
     const setAutotextValueHandler =(autoTextId, dataValue)=>{
       dispatch(setAutotextValue(autoTextId, dataValue));
     }
    const setHighlightRestrictedWordsHandler =(RWdata)=>{
      dispatch(setHighlightRestrictedWords(RWdata));
    }
    
    return(
        <Narrative
        module={module}
        id={id}
        onReplaceAll={onReplaceAll}
        onChangeNarrative={onChangeNarrative}
        onNarrativeCorrection={onNarrativeCorrection}
        value={value}
        isNarrativeDisabled={isNarrativeDisabled}
        setKeyPressed={setKeyPressed}
        rows={rows}
        onChange={onChange}
        containerDivClass={containerDivClass}
        spellCheckToggle = {spellCheckToggle}
        rwAction = {rwAction}
        rwActionHandler = {rwActionHandler}
        setSelectedRestrictedWordHandler = {setSelectedRestrictedWordHandler}
        setAutotextValueHandler = {setAutotextValueHandler}
        setHighlightRestrictedWordsHandler = {setHighlightRestrictedWordsHandler}
        selectedWord={selectedWord}
        highlightRestrictedWords={highlightRestrictedWords}
        AutotextReducer={AutotextReducer}
        resetTabToCrossButtonNarrative={resetTabToCrossButtonNarrative}
      />
    );

} 