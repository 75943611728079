import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import '../DropDownMenu/DropDownMenu.scss'


export default function DropDownMenuV2({ enableDropDown, menuItems=[] }) {
    const menu = useRef(null);

    return (
        <div>
            <div className="dropDownMenu">
                {enableDropDown && <Menu
                    className="Drop-down-menu"
                    model={menuItems}
                    popup
                    ref={menu}
                    appendTo={document.body}
                />}
                <Button
                    className={enableDropDown ? "enableDropDown" : "disableDropDown"}
                    icon="pi pi-ellipsis-h"
                    onClick={(event) => enableDropDown && menu.current.toggle(event)}
                    aria-controls="popup_menu"
                    aria-haspopup
                />
            </div>
        </div>
    );
}