import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import jsUtils from "../../../../../../utils/jsUtils";

export default function RestrictedWordsRightBodyControl({narrativeValue,onNarrativeChange,noOfChars,onSave,enableSaveButton,mode}){

    return <div className="af-popup-narrative">
                <label>{jsUtils.getLiteralValue("MESSAGE")} <span className="af-req-star">*</span></label>
                <div className="af-input-lookup ">
                    <p id="narrativeTextbox" className={"af-msg"}>
                        ({noOfChars} {jsUtils.getLiteralValue("CHARS_REMAINING")})
                    </p>
                </div>

            <div className="af-narrative">
                <InputTextarea
                    rows={12}
                    value={narrativeValue}
                    disabled={mode==="edit"}
                    onChange={(e)=>{onNarrativeChange(e.target.value)}}
                    />
                    
            </div>

            <div className="af-btn-panel">
                <button
                    className="p-button p-component af-btn-default"
                    data-testid="save"
                    disabled={!enableSaveButton}
                    onClick={() => {
                        onSave()
                    }}>
                    <span className="p-button-label p-c">
                    {jsUtils.getLiteralValue("SAVE")}
                    </span>
                </button>
            </div>

        </div>
}