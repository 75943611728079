/// API urls
export var env_name = "prod";
export var baseUrl = "https://tm.api.surepoint.cloud";
export var accessUrl = "https://ce.api.surepoint.cloud";
export var helpPageUrl = "https://help.surepoint.cloud/docs/Default.htm#cshid=";
export var domainUrl = ".surepoint.cloud";
export var commonUrl = "https://common.api.surepoint.cloud";
export var moduleAccessInfo = {
  "show": ["ZP"],
  "hiddenMessage": "These options are not currently available in SurePoint Cloud.\n Please log into LMS+ through your firm's dedicated URL instead."
};
export var FEATURES = {
  IS_SISENSE_DASHBOARD_ENABLED: true
};