import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownPrime } from "@attorneyflow-mf/styleguide";
import { dayViewApi } from "../../services/dayViewApi";
import jsUtils from "../../utils/jsUtils";

const AttorneySelectionBox = ({ showPopup, onCancel }) => {
  const [selectedAttorney, setSelectedAttorney] = useState("");
  const [woboList, setWoboList] = useState([]);
  const { timeKeeperList, selectedTimeEntries, delegateId, ethicalWallUserIds,transferFeatureName } = useSelector(
    ({ TimeManagementReducer, DayViewReducer }) => ({
      timeKeeperList: TimeManagementReducer.timeKeeperList,
      selectedTimeEntries: TimeManagementReducer.selectedTimeEntries,
      delegateId:TimeManagementReducer.delegateId,
      ethicalWallUserIds:DayViewReducer.ethicalWallUserIds,
      transferFeatureName:DayViewReducer.transferFeatureName
    })
  );

  const getTransferTimeKeepersList=(_timeKeeperList,delegateID) => {
    let uniqueTimeKeeperList=[];
    let temporaryIdList=[];
    _timeKeeperList.forEach((item,index)=>{
      if(index !==0 && temporaryIdList.findIndex((temporaryId)=>temporaryId.userDelegateId===item.userDelegateId)=== -1){
        temporaryIdList.push(item)
      }
    })
    temporaryIdList.forEach((temporaryId)=>{
      let notFound=false
      transferFeatureName.forEach((feature)=>{
        if(_timeKeeperList.findIndex((item)=>(item.featureName===feature) && (item.userDelegateId === temporaryId.userDelegateId )) === -1)
        {
          notFound=true
        }
      })
      !notFound &&  uniqueTimeKeeperList.push(temporaryId)
    })

    let filteredTimekeepersList = uniqueTimeKeeperList &&
      uniqueTimeKeeperList.filter((item, index) => {
          if ((item.userDelegateId !== delegateID) && (!ethicalWallUserIds || (ethicalWallUserIds && (ethicalWallUserIds.indexOf(item.userDelegateId)===-1)))) {
            return item;
          }
        });    
    return filteredTimekeepersList || [];
  }

  useEffect(() => {
    let filteredTimekeepersList = getTransferTimeKeepersList(timeKeeperList,delegateId); 
    setWoboList(filteredTimekeepersList);
  },[transferFeatureName])
  
  const dispatch = useDispatch();
  const onTransfer = () => {
    let payload = {
      ...selectedTimeEntries,
      attorneyCode: selectedAttorney.attorneyCode,
      userId:selectedAttorney.userDelegateId,
      delegateUserId:delegateId
    };   

      dispatch(dayViewApi.transferTE(payload));
  
    
  };
  
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={onCancel}
          className="p-button-text"
        />
        <Button
          label="Transfer"
          icon="pi pi-check"
          disabled={!selectedAttorney}
          onClick={onTransfer}
          className="transfer-button"
        />
      </div>
    );
  };

  return (
    <div className="attorneylist-popup">
      <div className="attorneylist-popup-dialog">
        <Dialog
          closable={false}
          visible={showPopup}
          modal
          footer={renderFooter()}
          header={((selectedTimeEntries.timeEntryIds && selectedTimeEntries.timeEntryIds.length) + (selectedTimeEntries.draftTimeEntryIds && selectedTimeEntries.draftTimeEntryIds.length) )<2 ? jsUtils.getLiteralValue("TRANSFER_TIME_ENTRY") : jsUtils.getLiteralValue("TRANSFER_SELECTED_TIME_ENTRIES_2")}
          >
          <div className="confirmation-content">
            <span style={{ paddingLeft: "10px" }}>
              <DropdownPrime
                appendTo={document.body}
                value={selectedAttorney}
                options={woboList}
                onChange={(e) => {
                  setSelectedAttorney(e.value);
                }}
                optionLabel="userDelegateFullNameCode"
                placeholder={jsUtils.getLiteralValue("SELECT_A_TIMEKEEPER")}
                filter
                filterInputAutoFocus={false}
              />
            </span>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default memo(AttorneySelectionBox);
