import React, { useState, useEffect, useRef, useCallback } from "react";
import { InputText } from "primereact/inputtext";

import { Calendar } from "primereact/calendar";

import { Button } from "primereact/button";

import "./AddTimePopup.scss";

import { Dialog } from "primereact/dialog";

import { useSelector } from "react-redux";

import { addTime } from "../../../services/AddTimeApi";
import { generalTimeApi } from "../../../services/GeneralTimeApi";
import { shortcut } from "../../../services/AutoTextApi";

import { AuthService } from "../../../services/authService";

import moment from "moment";
import * as NarrativeHelper from "./NarrativeHelper";


import { Toast } from "primereact/toast";
import FieldState from "./AddTimePopup.State.ts";
import DurationState from "../../../store/DurationState.ts";
import { helpPageUrl } from '../../../config';
import { useSubscribe } from "../../../store/ActionStore";
import { setErrorMessage } from "./../../../actions/AppAction";
import Spinner from "../Spinner/Spinner";
import { HELP_LINKS, TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../config/vars";
import storage from "../../../utils/storage";
import Transcribe from "../AWSTranscribe/Transcribe";
import { useDispatch } from "react-redux";
import ConfirmationBox from "./../ConfirmationBox/ConfirmationBox";
import { fetchAllRestrictedWords } from "../../../services/RestrictedWordsApi";
import { refreshStoreOnError } from "../../../actions/AppAction";
import { setDurationToggle } from "../../../actions/TimeManagementAction";
import jsUtils from "../../../utils/jsUtils";
import { Preferences } from "../../../services/PreferencesApi";
import RepeatTimeEntry from "./RepeatTimeEntry/RepeatTimeEntry";
import { ToolTipWrapper } from "./ToolTipWrapper";
import { setHighlightRestrictedWords } from "../../../actions/AddTimeActions";
import { FileUpload, FilePosition } from "@attorneyflow-mf/styleguide";
import axios from "axios";
import validationUtils from "../../../utils/validationUtils";
import { manageTime } from "../../../services/ManageTimeApi";
import NarrativeWrapper from "./Narrative/NarrativeWrapper";
import ConfirmationDialogBox from "../../common/ConfirmationBox/ConfirmationDialogBox";
import {CheckBox} from "@attorneyflow-mf/styleguide"
import RadioDropDownComponent from "../RadioButton/RadioDropDownComponent";
import ToggleButtonComponent from "../ToggleButtonComponent";

function GeneralTimePopup({ closed, show, onSaveHandler, data, pageTitle, mode}) {
  const {
    userDelegateRoundingMethod,
    delegateId,
    dateFormat,
    attorneyCode,
    allRestrictedWords,
    afterSavingPreference
  } = useSelector(({ TimeManagementReducer }) => ({
    userDelegateRoundingMethod:
      TimeManagementReducer.userDelegateRoundingMethod,
    delegateId: TimeManagementReducer.delegateId,
    dateFormat: TimeManagementReducer.dateFormat,
    attorneyCode: TimeManagementReducer.attorneyCode,
    allRestrictedWords: TimeManagementReducer.allRestrictedWords,
    afterSavingPreference: TimeManagementReducer.afterSavingPreference
  }));

  useSubscribe([FieldState, DurationState]);
  DurationState.setRounding(userDelegateRoundingMethod);

  const [TimeDialog, setTimeDialog] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const dialogFuncMap = {
    displayConfirmation: setDisplayConfirmation,
  };

  const [closeConfirmation, setCloseConfirmation] = useState(false);
  const dialogFuncMapClose = {
    closeConfirmation: setCloseConfirmation,
  };

  const [date, setDate] = useState("");

  const [narrativeValue, setNarrativeValue] = useState("");

  const [autoCorrectDictionary, setAutoCorrectDictionary] = useState(null);

  const [narrativeLabelClass, setNarrativeLabelClass] = useState("af-msg");

  const [noOfChars, setNoOfChars] = useState(0);

  const [toggleSpeechRecog, setToggleSpeechRecog] = useState(true);

  const [changed, setChanged] = useState(false);

  const [presignedURL, setpresignedURL] = useState("");



  const [durationToggleClick, setDurationToggleClick] = useState(true);
  const [rateCode, setRateCode] = useState(null);
  const [showRateAmount, setShowRateAmount] = useState(false);
  const [rateAmountValue, setRateAmountValue] = useState(null);
  const [transcribeReady, settranscribeReady] = useState(false);
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: [TOOLTIPS_VALUES.SELECT_DATE],
    [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: [TOOLTIPS_VALUES.SEARCH],
    [TOOLTIPS_KEYS.TOOLTIP_CLEAR]: [TOOLTIPS_VALUES.CLEAR],
    [TOOLTIPS_KEYS.TOOLTIP_DICTATE]: [TOOLTIPS_VALUES.DICTATE],
    [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: [TOOLTIPS_VALUES.CLOSE],
    [TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]: [TOOLTIPS_VALUES.SWITCH_DURATION_FORMAT],
    [TOOLTIPS_KEYS.TOOLTIP_HELP]: [TOOLTIPS_VALUES.HELP]

  });
  const [isNarrativeDisabled, setIsNarrativeDisabled] = useState(false);
  const [showRWValidationMsg, setShowRWValidationMsg] = useState(false);
  const [enteredRestrictedWords, setEnteredRestrictedWords] = useState([]);

  const [buttonClicked, setButtonClicked] = useState(() => { });
  const [maxChars, setMaxChars] = useState(5000);
  const [keyPressed, setKeyPressed] = useState(null);
  const recordDuration = useSelector((state) => state.TimeManagementReducer.loggedInUserToggle)
  const [autoCapStatus, setAutoCapStatus] = useState("true");
  const [repeatTimeEntryFlag, setRepeatTimeEntryFlag] = useState(false);
  const [checkboxDays, setCheckboxDays] = useState([]);
  const [endDateValue, setEndDateValue] = useState(null);

  const [spellCheckToggle, setSpellCheckToggle] = useState(false);
  const [forceInvalidStatusToggle, setForceInvalidStatusToggle] = useState(false);

  const [showErrorRepeatTimeEntry, setShowErrorRepeatTimeEntry] = useState(false);
  const [maximumLimitType ,setMaximumLimitType] = useState("E");
  const [maximumLimitMsg , setMaximumLimitMsg ] = useState("");
  const [showMaxLimitMsg , setShowMaxLimitMsg] = useState(false);
  const [ignoreClicked , setIgnoreClicked ] = useState(false);
  const [attachmentMsg , setAttachmentMsg ] = useState("");
  const [attachmentPopup , setAttachmentPopup ] = useState(false);
  const [DBCurrentDate, setDBCurrentDate] = useState("");

  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);
  /*
  //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
  const [showInformationalPopup,setShowInformationalPopup] = useState(false);
  //const [onClickCross, setOnClickCross] = useState(false);
  const [informationalMessage,setInformationalMessage] = useState("");
*/
  useEffect(() => {
    return () => {
      DurationState.update(DurationState.onDurationChange, [null, "", ""]);
      FieldState.update(FieldState.removeError);
    };
  }, []);

  /** Autocapitalize */

  const { isAuth, preferenceApiData } = useSelector(
    ({ AuthReducer, PreferenceReducer }) => ({
      isAuth: AuthReducer.isAuth,
      preferenceApiData: PreferenceReducer.preferenceApiData
    })
  );

  const permissionsOfUser = useSelector((state) => state.AppReducer && state.AppReducer.fieldnames);
  useEffect(() => {
    let permissions = permissionsOfUser.find((item) => item.id === "practice");
    if (isAuth && permissions) {
      const action = Preferences.getPreferenceSettings();
      dispatch(action);
    }
  }, [isAuth]);

  useEffect(() => {
    //let permissions = permissionsOfUser.find((item) => item.id === "practice");
    if (preferenceApiData) {
      let setting = preferenceApiData.find(
        (preference) => preference.param_key === "AUTO_CAPITALIZE"
      );
      setAutoCapStatus(setting && setting.param_value);

      let spellCheck_setting = preferenceApiData.find(
        (preference) => preference.param_key === "SPELL_CHECK"
      );
      if (spellCheck_setting) {
        let spell_values = spellCheck_setting.param_value;
        let spell_obj = JSON.parse(spell_values);
        setSpellCheckToggle(Object.keys(spell_obj)?.find((item)=> item === "spell_check_toggle") ? spell_obj.spell_check_toggle : true);
        setForceInvalidStatusToggle(spell_obj.force_invalid_status_toggle);
      }
    }
  }, [preferenceApiData]);

  const autoCapitalize = (value) => {
    if (keyPressed === "question_mark" || keyPressed === "exclamation" || keyPressed === "dot" || keyPressed === "enter") {
      const { formattedStr } = NarrativeHelper.sentenceCase(value);
      setNarrativeValue(formattedStr);
      setKeyPressed(null);
    }
  }


  /** set tooltips from storage on screen load
  * literals -variale to store the array of literals
  */
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const dispatch = useDispatch();
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };
  useEffect(() => {
    const currentDate = meUserData && meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    setDBCurrentDate(currentDate);
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE]: literals[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE],
        [TOOLTIPS_KEYS.TOOLTIP_SEARCH]: literals[TOOLTIPS_KEYS.TOOLTIP_SEARCH],
        [TOOLTIPS_KEYS.TOOLTIP_CLEAR]: literals[TOOLTIPS_KEYS.TOOLTIP_CLEAR],
        [TOOLTIPS_KEYS.TOOLTIP_DICTATE]: literals[TOOLTIPS_KEYS.TOOLTIP_DICTATE],
        [TOOLTIPS_KEYS.TOOLTIP_CLOSE]: literals[TOOLTIPS_KEYS.TOOLTIP_CLOSE],
        [TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]: literals[TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT],
        [TOOLTIPS_KEYS.TOOLTIP_HELP]: literals[TOOLTIPS_KEYS.TOOLTIP_HELP]
      })
      setCodeFromAPI(literals);
    }
    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_GENERAL_TIME_ENTRY");
    //fetches firm level restricted words
    dispatch(
      fetchAllRestrictedWords("clientId=null&subClientId=null&matterId=null")
    );
  }, []);

  useEffect(() => {
    if(maximumLimitType === "E"){
    if (maxChars - noOfChars === 0) {
      setNarrativeLabelClass("af-msg1");
      setToggleSpeechRecog(false);
      setNarrativeValue((prev) => {
        return prev;
      });
    } 
    // else if (maxChars - noOfChars < 0) {
    //   //resetTranscript();
    // }
  }}, []);
  useEffect(()=>{
    if (ignoreClicked){
      !Loading && onSave();
    }
  },[ignoreClicked])

  useEffect(() => {
    show &&
      shortcut.get("page=1&firmlevel=all").then((res) => {
        let autoDictonary = {};
        res && res.forEach((element) => {
          if (autoDictonary[element.shortcut]) {
            if (element.firmlevel !== 1) {
              let arr;
              if (typeof autoDictonary[element.shortcut] === "string") {
                arr = [autoDictonary[element.shortcut]];
              } else {
                arr = [...autoDictonary[element.shortcut]];
              }
              arr.push(element.fulltext);
              autoDictonary[element.shortcut] = arr;
            }
          } else {
            autoDictonary[element.shortcut] = element.fulltext;
          }
        });

        setAutoCorrectDictionary(autoDictonary);
      });

    setTimeDialog(show);
  }, [show]);

  const HandleClick = async (e) => {
    setChanged(true);
    setToggleSpeechRecog(!toggleSpeechRecog);
    if (toggleSpeechRecog) {
      //SpeechRecognition.startListening({ continuous: true });
      await AuthService.getPresinedUrlForAWSTranscribe().then((res) => {
        if (res.responseCode === 200) {
          setpresignedURL(res.presignedURL);
          settranscribeReady(true);
        }
      })
      setIsNarrativeDisabled(true);
    } else {
      setIsNarrativeDisabled(false);
      settranscribeReady(false);
      setpresignedURL("");

    }
  };

  const toggleClick = () => {
    setDurationToggleClick(!durationToggleClick);
  };

  const hOnChange = (value, rwAction, lastWord, lastWordStartIndex) => {
    let isAutoTextModified;
    if (keyPressed === "space") {
      const { isModified, sentence } = NarrativeHelper.getAutoCorrectedValueExtWithWord(
        value,
        autoCorrectDictionary,
        lastWord,
        lastWordStartIndex
      );
      isAutoTextModified = isModified;
      if (isModified) { // && maxChars - noOfChars - (newLength - prevLength) > 1) {
        value = sentence;
        setNoOfChars(value ? Number(value.length) : 0);
      }
    }
    if(maximumLimitType === "E"){
    if (Number(value.length) > maxChars) {
      setNarrativeLabelClass("af-msg1");
      if (
        keyPressed === "Ctrl+V" ||
        keyPressed === null ||
        (rwAction && rwAction === "Replace All")
      ) {
        setNoOfChars(value.length);
        //when user copy pastes text or replace all rw
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
        return;
      } else if (keyPressed === "space" && isAutoTextModified) {
        //when user types space for autotext
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
        return;
      } else if (keyPressed !== "Ctrl+V" && keyPressed !== "back_space") {
        return;
      }
    } else {
      setNoOfChars(value.length);
      setNarrativeLabelClass("af-msg");
      FieldState.update(FieldState.setNarrativeError);
    }}
    else if (maximumLimitType === "W"){
      setNoOfChars(value.length);
      setNarrativeLabelClass("af-msg");
    }


    setNarrativeValue(value);
    // autoCapStatus !== "false" ? autoCapitalize(value): null;
    if (autoCapStatus !== "false") {
      autoCapitalize(value);
    }
    setKeyPressed(null);
  };

  const onChange = (event) => {
    let text = event.target.value;
    setChanged(true);
    const { lastWord, lastWordStartIndex } = NarrativeHelper.getLastWordAndIndex(event);
    onChangeNarrative(text, [], null, lastWord, lastWordStartIndex);
  };

  useEffect(() => {
    
    if (mode === "edit" || mode==="clone") {
      let startdate = data.timerStartDatetime.substring(0, 10).split("-")
      startdate = startdate[1] + "/" + startdate[2] + "/" + startdate[0];
      if(mode==="clone")
      {
        let newDate = new Date(DBCurrentDate);
        setDate(newDate);

        setTimeout(() => {
          setAttachmentMsg("Attachments are not copied with time entries. You may need to attach files to the new entries.");
        setAttachmentPopup(true)
        }, 2000);
      }
      else{
        setDate(new Date(startdate));
      }
      setNarrativeValue(data.narrative);

      if (data && data.billableDuration > 0 && data.time === "0:0") {
        DurationState.update(
          DurationState.setHHMMDurationByBillableDuration,
          [data.billableDuration,
            null,
            null
          ])
      } else {
        let temp = data.time.split(":");
        DurationState.update(
          DurationState.setHHMMDurationByBillableDuration,
          [data.billableDuration,
          temp[0],
          temp[1]
          ]);
      }
    } else {
      let newDate = new Date(DBCurrentDate);
      setDate(newDate);
      DurationState.update(DurationState.onDurationChange, [null, 0, 0]);
    }
  }, [data , DBCurrentDate]);

  useEffect(() => {
    setDurationToggleClick(recordDuration)
  }, [])

  useEffect(() => {
    let togglePayload = { isRecordedDurationSelected: durationToggleClick }
    addTime.durationToogleSet(togglePayload).then((res) => {
      dispatch(setDurationToggle(durationToggleClick))

    }).catch((err) => {
      dispatch(refreshStoreOnError({ refresh: new Date() }));
    })
  }, [durationToggleClick])

  /**
 *  makes an api call to get all the individual field values on basis of the search text and the page   
 * @param {String} searchText - contains the user typed searchtext
 * @param {integer} page - contains the page number 
 * @return It returns a promise that contains the success or failure object
*/
  const loadCodes = (fieldName, searchText, page, delegateID) => {
    return generalTimeApi.draftConfigSearch(fieldName, page, searchText, delegateID);
  };

  /**
   *  - update the field with the selected value
   * @param {String} field - contains the field name
   * @param {Object} value - contains the selected code
   * @param {Boolean} ignoreIfAlreadySelected - true or false
   */
  const onCodesSelected = (field, value, ignoreIfAlreadySelected) => {
    console.log(field.split("_")[0], value.displayCode, value);
    FieldState.update(FieldState.updatePayload, [
      field,
      value,
      ignoreIfAlreadySelected,
    ]);
    let name = field.split("_")[0];
    if (name === "RATE") {
      setRateAmountValue("");
      setRateCode(value.id); //   // console.log(d);
      invokeRateCode(value.id, date, delegateId);
    }

    setChanged(true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };
  const onHideClose = (name) => {
    dialogFuncMapClose[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button
          label={getCode("STAY")}
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
          data-testid = "stay-id"
        />
        {(!repeatTimeEntryFlag || (repeatTimeEntryFlag && !(!endDateValue || checkboxDays.length === 0))) && <Button
          label={getCode("SAVE_WITH_ERRORS")}
          icon="pi pi-check"
          onClick={() => {
            onSave();
            onHide(name);
          }}
          autoFocus
          data-testid = "saveWithError"
        />}
      </div>
    );
  };

  const renderFooterClose = (name) => {
    return (
      <div>
        <Button
          label={getCode("LEAVE")}
          onClick={() => {
            hideDialog();
            onHideClose(name);
          }}
          className="p-button-text"
          data-testid = "leave-id"
        />
        <Button
          label={getCode("SAVE")}
          onClick={() => {
            onSave();
            onHideClose(name);
          }}
          autoFocus
          data-testid = "save-id"
        />
      </div>
    );
  };

  /**
   *  creates a payload object from the all the selected fields in the popup
   * @returns a payload object that contains all the selected fields data
   */
  const createPayload = () => {
    let savePayload = {
      dateTime: moment(new Date(date).toISOString()).format("YYYY-MM-DD"),
      recorded_duration_hrs: DurationState.hh, //parseInt(time.split(":")[0]) ||
      recorded_duration_mins: DurationState.mm, //parseInt(time.split(":")[1]) ||
      billing_duration: DurationState.duration,
      narrative: narrativeValue,
      attorney: attorneyCode,
      delegateUserId: delegateId,
      mode: "",
      // saveWithErrors: true,
      spellCheck: forceInvalidStatusToggle ? spellCheckToggle : false,
      // forceInvalid: forceInvalidStatusToggle,
      "attachments": attachments?.filter(ua => ua.id)?.map(a => {
        return {
          'filename': a.filename,
          'attachmentUrl': a.put_signed_url?.split('?')[0],
          's3Filename': a.dms_source,
          "operation": "create"
        }
      })
    };
    if (endDateValue) {
      savePayload["endDateTime"] = moment(new Date(endDateValue).toISOString()).format("YYYY-MM-DD");
      savePayload["is_recurring"] = true;
      savePayload["recurring_days"] = checkboxDays;
    }
    FieldState.payload.forEach((code) => {
      let selected =
        code.isDisplay === true &&
        code.values.find((d) => d.isDefault === true);
      if (selected) {
        // Continuing Legal Education
        if (code.field === "CLE") {
          savePayload["cleId"] = code.fieldId
          savePayload["cleValueId"] = selected.id;
          savePayload["cleCode"] = selected.displayCode.toString().slice(0, 1);
        }
        else {
          let label = code.field.toLowerCase().split("_");
          let valueId = label[0] + "ValueId";
          let id = label[0] + "Id";
          let displayCode = label[0] + "Code";
          savePayload[valueId] = selected.id;
          savePayload[id] = code.fieldId;
          savePayload[displayCode] = selected.displayCode;
        }
      }
    });
    if (showRateAmount) {
      savePayload["amount"] = parseInt(rateAmountValue);
    }
    else {
      savePayload["amount"] = null;
    }
    return savePayload;
  };
/*
  //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
  const onSetInformationalMessage=(res)=>{
    let amountInformation=res.informationalMessage.amount_information || "";
          let hoursInformation=res.informationalMessage.hours_information || "";
          let disclaimerInformation=res.informationalMessage.disclaimer || "";
          let message=amountInformation+"\n"+hoursInformation;
          if( disclaimerInformation && (disclaimerInformation !=="")){
            message=message+"\n"+"Disclaimer : "+disclaimerInformation;
          }
          setInformationalMessage(message);
          setShowInformationalPopup(true);
  }
  */
  /** 
  * To Save the time entry on save button clicked.
  * 
  * @param {Boolean} toggleSpeechRecog
  * 
  */

  const onSave = () => {
    if (!toggleSpeechRecog) {
      setToggleSpeechRecog(!toggleSpeechRecog);
      setIsNarrativeDisabled(false);
      settranscribeReady(false);
      setpresignedURL("");
    }
    setButtonClicked(() => saveApiCall);
    const { isNarrativeWordsValid, isNarrativeCharLimitValid } = validateNarrative();
    if (!isNarrativeWordsValid) {
      setShowRWValidationMsg(true);
    } else if (!isNarrativeCharLimitValid) {
      return;
    } else {
      saveApiCall();
    }
  }

  const saveApiCall = () => {
    let savePayload = createPayload();
    setLoading(true);
    
    if(mode!=="edit"){
      savePayload.mode = "save";
      generalTimeApi.save(savePayload)
      .then((res) => {
        setLoading(false);
        setChanged(false);
        let showInformationDialog=false;
        /*
            //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
        if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
          onSetInformationalMessage(res);
          showInformationDialog=true
          }
          */
        // if((onClickCross || mode==="clone") && !showInformationDialog ){          
        //      hideDialog();          
        // }
        if(!showInformationDialog) {
          onSaveHandler(afterSavingPreference);
        }

        dispatch(setErrorMessage({ severity: 'success', message: getCode("TIME_ENTRY_ADDED_SUCCESSFULLY"), errorCode: 200, life: 5000 }));
        resetNarrativeAndDuration();

      })
      .catch((err) => {
        setLoading(false);
        dispatch(setErrorMessage({ severity: 'error', message: getCode((err && err.message && err.message[0].message) || (err && err.message) || "UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));
      });
    }
    else{
      savePayload.mode = "edit";
      savePayload.clientId = data.clientId;
      savePayload.subClientId = data.subClientId;
      savePayload.matterId = data.matterId;
      savePayload.timeEntryId = data.timeEntryId;
      savePayload.narrativeId = data.narrativeId;

      generalTimeApi.edit(savePayload)
      .then((res) => {
        setLoading(false);
        setChanged(false);
        let showInformationDialog=false;
        /*
        //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
        if(res.informationalMessage && Object.keys(res.informationalMessage) && (Object.keys(res.informationalMessage).length >0)){
          onSetInformationalMessage(res);
          showInformationDialog=true;
          }
          */
        if(!showInformationDialog) {
            onSaveHandler(afterSavingPreference);
        }  
        
        resetNarrativeAndDuration();
        dispatch(setErrorMessage({ severity: 'success', message: getCode("TIME_ENTRY_UPDATED_SUCCESSFULLY"), errorCode: 200, life: 5000 }));
        //hideDialog();
      })
      .catch((err) => {
        setLoading(false);
        dispatch(setErrorMessage({ severity: 'error', message: getCode((err && err.message && err.message[0].message) || (err && err.message) || "UNABLE_TO_SAVE_TIME_ENTRY_TRY_AGAIN"), errorCode: "X", life: 5000 }));
      });
    }
   
  };

  const toast = useRef(null);

  const hideDialog = () => {
    if (!Loading) {
      closed();

      setTimeDialog(false);

      document.title = jsUtils.getLiteralValue(pageTitle);
    }
  };

  /**
   *  on change of selected rate value or date we make an api call to get the keyextendedflag
   * @param {String} newDate 
   */
  const checkRateAmount = async (newDate) => {
    invokeRateCode(rateCode, newDate, delegateId);
  };

  /**
  *  get keyextended rate value on selecting a rate value or date and set it to the state to display the amount field or not
  * @param {} idvalue 
  * @param {String} date 
  * @param {Number} delegateId 
  */
  const invokeRateCode = async (idvalue, _date, _delegateId) => {
    setLoading(true);
    await addTime
      .rateCode(
        idvalue,
        moment(new Date(_date).toISOString()).format("YYYY-MM-DD"),
        _delegateId
      )
      .then((res) => {

        if (res.keyExtendedAmountFlag) {
          setShowRateAmount(true);
        } else {
          setShowRateAmount(false);
        }
        setLoading(false);
      })
      .catch(() => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTimeEntriesPayload();
  }, []);

  /**
  *  api call to get all the fields and their corresponding values and the fieldstate and duration states 
*/
  const loadTimeEntriesPayload = async () => {
    setLoading(true)
    await generalTimeApi.getDraftConfig(delegateId).then((res) => {
      setLoading(false)
      FieldState.update(FieldState.setPayloadForGeneral, [res.fields]);
      if(mode === "edit" || mode==="clone"){
        FieldState.update(FieldState.setSelectedPayload, [
          data.timeEntryDetail,
        ]);
      }

      DurationState.update(DurationState.setMaxBillableDuration, [
        res.maxBillableDuration,
      ]);
      DurationState.update(DurationState.setMaxHoursPerDay, [
        res.maxHoursPerDay,
      ]);
      DurationState.update(DurationState.setIncrements, [[3, 6, 15, 30, 60]]);

    if(mode === "edit" || mode === "clone"){
      let temp = data.time.split(":");
      if (data && data.billableDuration > 0 && data.time === "0:0") {
        DurationState.update(
          DurationState.setHHMMDurationByBillableDuration,
          [data.billableDuration,
            null,
            null
          ])
      } else {
        DurationState.update(
          DurationState.setHHMMDurationByBillableDuration,
          [data.billableDuration,
          temp[0],
          temp[1]
          ]);
      }
    }
    });
    // });
  };

  //resets values of Narrative and Duration fileds to default values
  const resetNarrativeAndDuration = useCallback(() => {
    setNarrativeValue("");
    DurationState.update(DurationState.setDuration, ["0.00"]);
    DurationState.update(DurationState.setHH, [0]);
    DurationState.update(DurationState.setMM, [0]);
    setAttachments([]);    
  });

  useEffect(() => {
    setTimeDialog(show);
  }, [show]);

  const onChangeNarrative = (text, _enteredRestrictedWords, action, lastWord, lastWordStartIndex) => {

    hOnChange(text, action, lastWord, lastWordStartIndex);
    setEnteredRestrictedWords(_enteredRestrictedWords);
  };

  const onSetshowRWValidationMsg = (isVisible) => {
    setShowRWValidationMsg(isVisible);
    const { isNarrativeWordsValid } = validateNarrative();

    if (isNarrativeWordsValid) {
      buttonClicked();
    }
  };
  const onSetShowLimitMsg = (isVisible) =>{
    setShowMaxLimitMsg(isVisible);
  }
  useEffect(() => {
    const { highlightJson, maxLimit, maxLimitType , maxLimitMsg  } = NarrativeHelper.onLoadRestrictedWords(allRestrictedWords);
    //highlightJson.map(rWord => (rWord.component = ToolTipWrapper)); //2.1.1
    highlightJson.map(rWord => (rWord.enhancement = ToolTipWrapper));
    dispatch(setHighlightRestrictedWords(highlightJson));
    setMaxChars(maxLimit);
    setMaximumLimitType(maxLimitType);
    setMaximumLimitMsg(maxLimitMsg)
  }, [allRestrictedWords]);

  const onNarrativeCorrection = (action, restrictedWordsNew, enteredRestrictedWordsNew) => {
    if (action === "Ignore All") {
      if (restrictedWordsNew) {
        //setRestrictedWords(restrictedWordsNew);
        dispatch(setHighlightRestrictedWords(restrictedWordsNew));
      }  
      if (enteredRestrictedWordsNew)
        setEnteredRestrictedWords(enteredRestrictedWordsNew);
    }
  }

  /* checks if narrative as restricted words */
  const validateNarrative = () => {

    let isNarrativeWordsValid = true, isNarrativeCharLimitValid = true;
    if (
      narrativeValue !== "" &&
      enteredRestrictedWords &&
      enteredRestrictedWords.length > 0
    ) {
      isNarrativeWordsValid = false;
    }
    if(maximumLimitType === "E"){
    if ((maxChars - noOfChars) < 0) {
      isNarrativeCharLimitValid = false;
      FieldState.update(FieldState.setNarrativeError, [
        getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
      ]);
    }}
    else if(maximumLimitType === "W"){
      if ((maxChars- (maxChars- noOfChars)) > 5000) {
        isNarrativeCharLimitValid = false;
        FieldState.update(FieldState.setNarrativeError, [
          getCode('NARRATIVE_EXCEEDS_MAXIMUM_CHARACTER_LIMIT')
        ]);
      }
    }
    return { isNarrativeWordsValid, isNarrativeCharLimitValid };
  }

  const onChangeMM = (e) => {
    let minutes = e.currentTarget.value.toString();
    if (minutes.length > 2 || minutes > 59 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    FieldState.update(FieldState.setDurationError);
    DurationState.update(DurationState.onMinutesOrHoursChange, [
      DurationState.hh,
      minutes,
    ]);
  };

  const onChangeHH = (e) => {
    let hours = e.currentTarget.value.toString();
    if (hours.length > 2 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    FieldState.update(FieldState.setDurationError);
    DurationState.update(DurationState.onMinutesOrHoursChange, [
      hours,
      DurationState.mm,
    ]);
  };

  const onChangeDuration = (e) => {
    let duration = e.currentTarget.value.toString();
    if (duration.length > 7 || Number(e.currentTarget.value) < 0) return;
    setChanged(true);
    DurationState.update(DurationState.onDurationFieldChange, [duration]);
  };
  const onChangeCheckboxDays = (days) => {
    setShowErrorRepeatTimeEntry(false);
    setCheckboxDays(days)
  }

  const validateDateRangeWithWeekDays = (startDateString, endDateString, weekDays) => {
    let startDate = moment(new Date(startDateString).toISOString()).format("YYYY-MM-DD")

    let endDate = moment(new Date(endDateString).toISOString()).format("YYYY-MM-DD");

    const oneDayTotalMilliseconds = 1000 * 60 * 60 * 24;

    const diffInTimeInMilliseconds = (new Date(endDate)).getTime() - (new Date(startDate)).getTime();

    const totalDaysInBetween = Math.round(diffInTimeInMilliseconds / oneDayTotalMilliseconds);

    if (totalDaysInBetween > 6) {
      return true;
    }
    else {
      let weekDaysArrayInNumber = jsUtils.getWeekDayArrayInNumberFormats(weekDays) || [];

      let dayNumber = moment(new Date(startDate + " 00:00:00")).add(1, "days").day(); //Sun-Sat -> 0-6
      let firstDayOfRange = dayNumber; let lastDayOfRange = dayNumber;
      for (let i = 0; i < totalDaysInBetween; i++) {
        firstDayOfRange = ((dayNumber + i) % 7) < firstDayOfRange ? ((dayNumber + i) % 7) : firstDayOfRange;
        lastDayOfRange = ((dayNumber + i) % 7) > lastDayOfRange ? ((dayNumber + i) % 7) : lastDayOfRange;
      }
      let validationRule = true;
      weekDaysArrayInNumber.forEach((item) => {
        if ((item < firstDayOfRange || item > lastDayOfRange) && validationRule) {
          validationRule = false;
        }
      })
      return validationRule

    }

  }


  useEffect(()=> {
    if(mode){
      setAttachments(data?.attachments)
    }
  }, [mode]);

  const [attachments, setAttachments] = useState([]);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);

  //Fileupload Methods
  const onAddAttachment = (fileInfo) => {
    //Call DOCUMENT API SERVICE call
    const request_body = [{
      filename: fileInfo.filename,
      title: fileInfo.title,
      folder: 'timeentry'
    }];
    setAttachmentsLoading(true);
    generalTimeApi.addAttachment({ files: request_body }).then(response => {
      if (response && response.success && response.signedUrl?.length > 0) {
        const { put_signed_url, key, dms_source } = response.signedUrl[0];
        try {
          var config = {
            method: 'put',
            url: put_signed_url,
            headers: {
              "Content-Type": "application/octet-stream"
            },
            data: fileInfo.file
          };
          axios(config).then(function (res_data) {
            if (res_data?.status === 200){
              delete fileInfo.file;
              fileInfo.put_signed_url = put_signed_url;
              fileInfo.s3Key =  key;
              fileInfo.dms_source = dms_source;
              //Update List
              setAttachments([...attachments, fileInfo]);
              setAttachmentsLoading(false);
            }
          }).catch(function (error) {
            console.log(error);
            setAttachmentsLoading(false);
          });
        } catch (e) {
          setAttachmentsLoading(false);
          console.log('failed to upload attachment' + fileInfo.filename);
        }
      }
    }).catch((err) => {
      setAttachmentsLoading(false);
      console.log('Add attachment failed', err);
    });
  }

  const onDownloadAttachment = (dms_source) => {
    try {
      setAttachmentsLoading(true);
      generalTimeApi.downloadAttachment(dms_source,"timeentry").then((res) => {
        if (res.success && res.signed_url) {
          window.downloadAttachment(res.signed_url);
        }
        setAttachmentsLoading(false);
      }).catch((err)=>{
        setAttachmentsLoading(false);
        console.log('Download attachment failed!', err);  
      });
    }
    catch (error) {
      setAttachmentsLoading(false);
      console.log(error);
    }
  }

  const deleteAttachment = (attachmentId) => {
    setAttachmentsLoading(true);
    const attachment_index = attachments?.findIndex(a => a.attachment_id === attachmentId || a.id === attachmentId);
    if(attachment_index > -1){
      if(mode==="clone")
      {
        setAttachmentsLoading(true);
        let attachmentList=attachments;
        attachmentList.splice(attachment_index,1);
        setAttachments(attachmentList);
      }
      else
      {
        const { attachment_id,  put_signed_url} = attachments[attachment_index];
        let request_body;
        if (mode === 'edit' && attachment_id) {
          request_body = {
            "attachment_id": [attachment_id],
          }
        } else {
          request_body = {
            "folder": 'timeentry',
            "put_signed_url": put_signed_url?.split('?')[0]
          }
        }
        const gte_id = (attachment_id) ? data?.timeEntryId : 0;
        try {
          generalTimeApi.deleteAttachment(gte_id, request_body).then((res) => {
            if (res.success) {
                attachments.splice(attachment_index, 1);
                setAttachments([...attachments.filter(a=> a.attachment_id != attachmentId || a.id != attachmentId)]);
                attachment_id && dispatch(setErrorMessage({ severity: 'success', message: getCode('CM_ATTACHMENT_DELETED'), errorCode: 200, life: 5000 }));
                setAttachmentsLoading(false);
            }else{
              setAttachmentsLoading(false);
            }
          }).catch((err)=>{
            setAttachmentsLoading(false);
            console.log('Delete attachment failed!', err);
          });
        }
        catch (error) {
          setAttachmentsLoading(false);
          console.log(error);
        }
      }
    }
    
  }



  const resetTabToCrossButton = (evnt) => {    
    if(evnt.key==="Tab") {
      document.querySelector(".p-dialog-header-icons > button").focus();
      evnt.preventDefault();
    }
  }

  const resetTabToCrossButtonNarrative = (evnt) => {    
    if(Loading && evnt.key==="Tab") {
      document.querySelector(".p-dialog-header-icons > button").focus();
      evnt.preventDefault();
    }
  }

  return (
    <div className="af-modal-dialog-addtime general-time-container">
      <Toast ref={toast} />

      <Dialog
        onHide={() => { }}
        header=""
        closable={false}
        visible={TimeDialog}
        className="af-modal-dialog">
        <div className="p-dialog-header">
          <a
          data-testid="help-id"
            href={`${helpPageUrl}${HELP_LINKS.GENERAL_TIME_ENTRY}`}
            target="_blank">
            <img
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_HELP]}
              className="af-help-addtime"
              src="/images/svg/Help_icon.svg"
              alt=""
            />
          </a>
          <div className="p-breadcrumb p-component" aria-label="Breadcrumb">
            <ul>
              <li className="">
                <span className="p-menuitem-link">
                  <span className="p-menuitem-text">
                    {getCode("TIME_MANAGEMENT")}
                  </span>
                </span>
              </li>

              <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>

              <li className="">
                <span className="p-menuitem-link">
                  <span className="p-menuitem-text">
                    {getCode("GENERAL_TIME_ENTRY")}
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <span className="p-dialog-title"></span>
          <div className="p-dialog-header-icons">
            <button
              onClick={() => {
                if (!changed) {
                  hideDialog()
                }
                else {
                  //setOnClickCross(true);
                  setCloseConfirmation(true);
                }
              }}
              type="button"
              tabIndex={0}
              className="p-dialog-header-icon p-dialog-header-close p-link"
              aria-label="Close"
              title={tooltips[TOOLTIPS_KEYS.TOOLTIP_CLOSE]}>
              <span className="p-dialog-header-close-icon pi pi-times"></span>
            </button>
          </div>
        </div>
        {Loading && (
          <div className="af-spinner">
            <Spinner />
          </div>
        )}

        <div className="af-popup-body">
          <div className="p-grid">
            <div className="p-sm-12 p-md-12 p-lg-8">
              <div className="at-dbcol">
                <div className="af-popup-cnt af-client-bg">

                  <div className="af-lookup-cont">
                    <div style={{ display: "flex" }}>
                      <label htmlFor="icon">
                        {getCode("DATE")} <span className="af-req-star">*</span>
                      </label>

                    </div>

                    <div className="p-field">
                      <Calendar
                        tabIndex={0}
                        tooltip={tooltips[TOOLTIPS_KEYS.TOOLTIP_SELECT_DATE].toString()}
                        tooltipOptions={{
                          className: "white-tooltip",
                          position: "top",
                          showDelay: 700,
                        }}
                        maxDate={endDateValue}
                        placeholder={dateFormat}
                        dateFormat={
                          dateFormat &&
                          dateFormat
                            .toLowerCase()
                            .replace("yyyy", "yy")
                        }
                        readOnlyInput
                        yearNavigator
                        yearRange="1600:6030"
                        id="icon"
                        appendTo={document.body}
                        value={date}
                        showIcon={true}
                        onChange={(e) => {
                          setChanged(true);
                          setDate(e.value);
                          checkRateAmount(e.value);
                          FieldState.update(FieldState.setDateError);
                          document.querySelector('#icon > input').focus(); 
                        }}
                      />
                    </div>
                    {showErrorRepeatTimeEntry ? (!endDateValue || checkboxDays.length === 0) ? <div className="af-error-msg-display">
                      {jsUtils.getLiteralValue('DAYS_OF_WEEK_AND_END_DATE_MUST_BE_CHECKED_OR_REPEAT_TIME_ENTRY_CLEARED')}
                    </div> :
                      <div className="af-error-msg-display">
                        {jsUtils.getLiteralValue('SELECTED_DAYS_NOT_IN_RANGE')}
                      </div> : <></>
                    }
                    {mode !== "edit" &&
                    <div style={{ display: "flex", paddingLeft: "1rem" }}>
                      <div>
                        <CheckBox tabOrder={0} id={"repeattime"} onSelectionChange={() => { 
                          setShowErrorRepeatTimeEntry(false); 
                          setRepeatTimeEntryFlag(!repeatTimeEntryFlag); 
                          if(repeatTimeEntryFlag) { setEndDateValue(null); setCheckboxDays([]); } 
                          }}
                          checked={repeatTimeEntryFlag} />
                      </div>
                      <div style={{ color: "#0050c3" }}>
                        <label className={!repeatTimeEntryFlag ? "repeatTimeEntryLabel" : "repeatTimeEntryLabelSelected"} htmlFor={"repeattime"}>{jsUtils.getLiteralValue("REPEAT_TIME_ENTRY")}</label>
                      </div>
                    </div>
                    }
                    {
                    repeatTimeEntryFlag && (mode !== "edit") && 
                    <RepeatTimeEntry 
                      onChange={onChangeCheckboxDays} 
                      startDate={date} 
                      endDateValue={endDateValue} 
                      setEndDateValue={setEndDateValue} 
                      setShowErrorRepeatTimeEntry={setShowErrorRepeatTimeEntry} 
                      setChanged={setChanged} />
                    }
                  </div>
                  <div className="af-error-msg-display">
                    {/* {DurationState.error} */}
                    {FieldState.durationError}
                  </div>
                  <div className="af-lookup-cont af-time-post">
                    <div className="af-duration-header">
                      {durationToggleClick ? (
                        <div className="af-time-label-duration">
                          <label htmlFor="time">
                            {getCode("DURATION")}
                            <span className="af-req-star"> *</span>
                            </label>
                        </div>
                      ) : (
                        <div className="recoded-duration">
                          <div className="af-time-label">
                            <label htmlFor="time">
                              {" "}
                              {getCode("RECORDED_DURATION")}
                              <span className="af-req-star"> *</span>
                            </label>  
                          </div>
                          <div className="af-time-label-bd">
                            <label htmlFor="time">
                              {" "}
                              {getCode("ROUNDED_DURATION")}
                            </label>
                          </div>
                        </div>
                      )}

                     <ToggleButtonComponent toggleClick={toggleClick} title={tooltips[TOOLTIPS_KEYS.TOOLTIP_SWITCH_DURATION_FORMAT]} />
                    </div>

                    <div className="af-input-lookup">
                      {!durationToggleClick ? (
                        <div className="billable-duration-col">
                          <div className="hour-col">
                            <InputText
                              tabIndex={0}
                              data-testid = "hour-id"
                              id="hourstxt"
                              type="number"
                              value={DurationState.hh}
                              onKeyPress={(e) =>
                                e.key === "e" || e.key === "E"
                                  ? e.preventDefault()
                                  : e
                              }
                              onChange={onChangeHH}
                              onBlur={validationUtils.validateDurationFieldDraftTimeGT}
                              autoComplete="off"
                            />{" "}
                            <p>{getCode("HH")}</p>{" "}
                            <span className="sp-coln">:</span>
                          </div>

                          <div className="min-col">
                            <InputText
                            tabIndex={0}
                            data-testid = "min-id"
                              id="mintxt"
                              type="number"
                              value={DurationState.mm}
                              onKeyPress={(e) =>
                                e.key === "e" || e.key === "E"
                                  ? e.preventDefault()
                                  : e
                              }
                              onChange={onChangeMM}
                              onBlur={validationUtils.validateDurationFieldDraftTimeGT}
                              autoComplete="off"
                            />{" "}
                            <p>{getCode("MM")}</p>
                            <span className="sp-eql">=</span>
                          </div>

                          <div className="bil-col">
                            {" "}
                            <InputText
                             tabIndex={0}
                              value={DurationState.duration}
                              disabled
                              id="billTxt"
                              type="text"
                              autoComplete="off"
                            />{" "}
                            <p>x.xx</p>
                          </div>
                        </div>
                      ) : (
                        <div className="duration-col">
                          <span className="duration-input-margin">
                            <InputText
                            data-testid="duration-txt"
                              tabIndex={0}
                              type="number"
                              id="durationtxt"
                              value={DurationState.duration}
                              maxLength="7"
                              placeholder="0.00"
                              onKeyPress={(e) =>
                                e.key === "e" || e.key === "E"
                                  ? e.preventDefault()
                                  : e
                              }
                              onChange={onChangeDuration}
                              onBlur={validationUtils.validateDurationFieldDraftTimeGT}
                              autoComplete="off"
                            />
                          </span>

                          <div className="af-timer-btn-panel">
                            {DurationState.increments.map((d) => (
                              <button
                              data-testid = "duration-btn"
                                tabIndex={0}
                                className="p-button p-component af-btn-default"
                                onClick={() => {
                                  setChanged(true);
                                  FieldState.update(
                                    FieldState.setDurationError
                                  );
                                  DurationState.update(
                                    DurationState.onIncrement,
                                    [d]
                                  );
                                  validationUtils.validateDurationFieldDraftTimeGT();
                                }} key={`_${d}`}>
                                <span className="p-button-label p-c" key={`k${d}`}>
                                  +{(d / 60).toFixed(2)}
                                </span>
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* middle secion start */}
                <div className="af-popup-codetypes">
                  {FieldState.payload.map((item, index) => {
                    let selected =
                      item.values && item.values.find((d) => d.isDefault);

                    return (
                      item.isDisplay && (
                        <div className="af-btn-sect" key={index}>
                          <h3>
                            {item.displayLabel}
                            {item.isMandatory && (
                              <span className="af-req-star">*</span>
                            )}
                          </h3>

                          <span className="radio-msg">
                            {selected && selected.description}
                          </span>

                          <div className="af-error-msg-display">
                            {item.errorMessage}
                          </div>

                          <RadioDropDownComponent
                            tabOrder={0}
                            columns={["Code", "Description"]}
                            displayType="button"
                            textDisplayPropName="description"
                            onChange={(
                              field,
                              option,
                              ignoreIfAlreadySelected
                            ) => {
                              if (item.errorMessage)
                                FieldState.update(FieldState.addError, [
                                  [
                                    {
                                      field: item.field
                                        .toLowerCase()
                                        .replace(/ /g, ""),
                                      message: "",
                                    },
                                  ],
                                ]);
                              onCodesSelected(
                                field,
                                option,
                                ignoreIfAlreadySelected
                              );
                            }}
                            listSource={
                              item.field === "CLE"
                                ? null
                                : async (searchText, page) => {
                                  let list = await loadCodes(
                                    item.field,

                                    searchText,

                                    page,

                                    delegateId
                                  );
                                  return list.fields.length > 0
                                    ? list.fields[0].values
                                    : [];
                                }
                            }
                            data={item}
                          />
                          {item.field === "RATE_CODE" && showRateAmount && (
                            <div className="af-amount af-input-lookup">
                              <div className="af-amount-field">
                                {getCode("ENTER_AMOUNT")}
                              </div>{" "}
                              <div className="af-amount-field">
                                <InputText
                                  id="rateInputAmount"
                                  value={rateAmountValue}
                                  onChange={(event) =>
                                    setRateAmountValue(event.target.value)
                                  }
                                  type="number"
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
                {/* middle secion end */}
              </div>
            </div>
            <div className="p-sm-12 p-md-12 p-lg-4">
              <div className="af-popup-narrative">
                <label>{getCode("NARRATIVE")}</label>

                <div className="af-input-lookup ">
                  <p id="narrativeTextbox" className={narrativeLabelClass}>
                    {getCode("TYPE_OR_SPEAK_YOUR_NARRATIVE")} (
                    {maxChars - noOfChars} {getCode("CHARS_REMAINING")})
                  </p>

                  <div className="af-nar-btnpanel">
                    <button
                      tabIndex={0}
                      className="p-button p-component btn-Icon"
                      onClick={HandleClick}
                      data-testid="micButton">

                      {toggleSpeechRecog && !transcribeReady ? (
                        <img
                          title={tooltips[TOOLTIPS_KEYS.TOOLTIP_DICTATE]}
                          alt=""
                          src={"/images/svg/icon_mic.svg"}
                        />
                      ) : transcribeReady ? (
                        <img
                          title={tooltips[TOOLTIPS_KEYS.TOOLTIP_DICTATE]}
                          alt=""
                          src={"/images/u1513.svg"}
                        />
                      ) : (
                        <Spinner />
                      )}
                    </button>
                  </div>
                </div>

                <div className="af-error-msg-display">
                  {FieldState.narrativeError}
                </div>

                <div className="af-narrative">
                  <NarrativeWrapper
                    module="DraftTime"
                    onChangeNarrative={onChangeNarrative}
                    onNarrativeCorrection={onNarrativeCorrection}
                    value={narrativeValue}
                    isNarrativeDisabled={isNarrativeDisabled}
                    setKeyPressed={setKeyPressed}
                    rows={10}
                    onChange={onChange}
                    containerDivClass="narrative-container"
                    spellCheckToggle={spellCheckToggle}
                    resetTabToCrossButtonNarrative={(e) => resetTabToCrossButtonNarrative(e)}
                  />
                </div>

                {/* File Upload Component */}
                <FileUpload
                    addAttachment={onAddAttachment}
                    attachmentTitleRequired={false}
                    deleteAttachment={deleteAttachment}
                    disable={attachments?.length >= 50}
                    downloadAttachment={onDownloadAttachment}
                    attachments={attachments}
                    fileFormats={[
                      'image/png', 'image/jpg', 'image/jpeg', 'image/gif', //.png, .jpg, .jpeg, .gif
                      'application/pdf', //.pdf
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', //.docx, .doc
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint', //.pptx, .ppt
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv', //.xlsx, .xls, .csv
                      'text/plain', //.txt
                      'application/vnd.ms-outlook' //.msg
                    ]}
                    getIntlMessage={getCode}
                    position={FilePosition.RIGHT}
                    loading={attachmentsLoading}
                />

                
              </div>
              <div className="af-popup-save-button">
              <div className="af-btn-panel">
                  <button
                    tabIndex={0}
                    className="p-button p-component af-btn-default"
                    // data-testid="save"
                    disabled={Loading ? true : false}
                    onKeyDown={(e)=>{resetTabToCrossButton(e)}}
                    onClick={() => {
                      if (repeatTimeEntryFlag && (!endDateValue || checkboxDays.length === 0)) {
                        setShowErrorRepeatTimeEntry(true);
                        setDisplayConfirmation(true)
                      }
                      else if (repeatTimeEntryFlag && !validateDateRangeWithWeekDays(date, endDateValue, checkboxDays)) {
                        setShowErrorRepeatTimeEntry(true);
                        setDisplayConfirmation(true);
                      }
                      else if((maximumLimitType === "W" && (maxChars- noOfChars) < 0) && !ignoreClicked){
                        setShowMaxLimitMsg(true);
                     }

                      else {
                        !Loading && onSave();
                      }
                    }}
                    data-testid="saveButton"
                    >
                    <span className="p-button-label p-c">
                      {getCode("SAVE")}
                    </span>
                  </button>
                </div>
              </div>
    
            </div>
          </div>
        </div>
        <div className="timerClass">
          <div className="timerClass-dialog">
            <Dialog
              closable={false}
              header="Confirmation"
              visible={displayConfirmation}
              modal
              footer={renderFooter("displayConfirmation")}
              onHide={() => onHide("displayConfirmation")}>
              <div className="confirmation-content">
                <img
                  title=""
                  alt=""
                  className="af-warning"
                  src="/images/icon_warning.svg"
                />
                <span>{getCode("YOU_HAVE_ERRORS_ON_THE_SCREEN")}</span>
              </div>
            </Dialog>
          </div>
        </div>
        <div className="timerClass">
          <div className="timerClass-dialog">
            <Dialog
              closable={false}
              header="Confirmation"
              visible={closeConfirmation}
              modal
              footer={renderFooterClose("closeConfirmation")}
              onHide={() => onHideClose("closeConfirmation")}>
              <div className="confirmation-content">
                <img
                  title=""
                  alt=""
                  className="af-warning"
                  src="/images/icon_warning.svg"
                />
                <div>
                  <span>
                    {getCode(
                      "YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_SAVE"
                    )}
                  </span>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </Dialog>
      {showRWValidationMsg && (
        <ConfirmationBox
          message={
            getCode(
              "YOUR_NARRATIVE_INCLUDES_RESTRICTED_WORDS_YOU_SHOULD_REPLACE_ALL_RESTRICTED_WORD_ERRORS_BEFORE_SAVING"
            )
          }
          showRWValidationMsg={showRWValidationMsg}
          setPopupVisibleToggle={(flag)=>{onSetshowRWValidationMsg(flag)}}
          showOkButton = {true}

        />
      )}
       {showMaxLimitMsg && !ignoreClicked &&(
        <ConfirmationBox
          message={maximumLimitMsg}
          showRWValidationMsg={showMaxLimitMsg}
          setPopupVisibleToggle={(flag)=>{onSetShowLimitMsg(flag)}}
          setIgnoreClicked = {setIgnoreClicked}
          showIgnoreButton = {true}
          showCancelButton = {true}
        />
      )}
          {(attachmentPopup && mode === "clone") &&(  <ConfirmationBox
          message={attachmentMsg}
          showRWValidationMsg={attachmentPopup}
          setPopupVisibleToggle={(flag)=>{setAttachmentPopup(flag)}}
          showOkButton ={true}
          type={"attachmentError"}
        />
        )}
      {/*
      //SEBI-694, do not delete this code, if user want to see exceeded budget message then uncomment this code
        <span className="Confirmation-popup">
        {
        showInformationalPopup && (
          
          <ConfirmationDialogBox
            visible={showInformationalPopup}
            message={informationalMessage}
            header="Confirmation"            
            onConfirm={()=>{
              setShowInformationalPopup(false);
              // if(onClickCross || mode==="clone" || mode === "edit"){ 
              //   hideDialog();
              // }
              onSaveHandler(afterSavingPreference);
            }}
            disableConfirmButton={false}
            disableCancelButton={true}
            confirmButtonLabel={jsUtils.getLiteralValue("OK")}
            confirmButtonClassName={"yes-button"}
            imageClassName={"af-warning"}
            imageSrc={"/images/icon_warning.svg"}
          />
        )}
        
      </span>
      */}
      <Transcribe
        toggleSpeechRecog={toggleSpeechRecog}
        setTransValue={setNarrativeValue}
        narrativeValue={narrativeValue}
        presignedURL={presignedURL}
        maxChars={maximumLimitType==="E" ? maxChars : 5000}
        autoCorrectDictionary={autoCorrectDictionary}
      />
    </div>
  );
}

export default GeneralTimePopup;