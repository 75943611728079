
import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import './DropDownMenu.scss'
import { useEffect } from 'react';
import { useState } from 'react';
import permissionsState from "../../../store/ClientMatterRolesPermissions.ts";
import jsUtils from '../../../utils/jsUtils';
import { useSelector } from 'react-redux';


export default function DropDownMenu({data, onBulkEdit, screen}){
    const menu = useRef(null);
    const [enableDropDown,setEnableDropDown]= useState(false);
    const permissionsOfUser = useSelector((state) => state.AppReducer && state.AppReducer.fieldnames);
    const [editDisable,setEditDisable]=useState(false);

    useEffect(() => {
        if(screen==="managetime")
        {
            let permissions = permissionsOfUser.find( (item) => item.id==="managetimegrid" );
            permissions && permissions.fields.forEach((element) => {
                if (element.id === "edittime" && !element.actionable) {
                    setEditDisable(true);
                }     
            });

        }
        if(screen === "users")
        {
            let permissions = permissionsState.usersPermissions
            if (permissions !== undefined) {
            permissions.forEach((element) => {
                if (element.id === 'edit' && !element.actionable) {
                    setEditDisable(true)
                }
            });
        }
        }
        if(screen === "roles")
        {
            let permissions = permissionsState.usersPermissions
            if (permissions !== undefined) {
            permissions.forEach((element) => {
                if (element.id === 'edit' && !element.actionable) {
                    setEditDisable(true)
                }
            });
        }
        }
        
    },[permissionsOfUser,screen]);

    useEffect(()=>{
        let enableCheckCount=0;
       if(data && data.length > 0) {
        data.forEach((item) => {
            if (item.isChecked) {
                enableCheckCount+=1;
            }
          });
        }  
        if(enableCheckCount<2)
        {
            setEnableDropDown(false);
        }
        else  
        {  
            setEnableDropDown(true);
        }
    },[data]);

    const UsersItems = [
           
        {
            label: jsUtils.getLiteralValue('UPDATE_SELECTED_USERS'),
            icon: 'icon-icon_edit',
            command: () => {
                let selectedEntries=[];
                data.forEach((item) => {
                      if (item.isChecked) {
                            selectedEntries.push(item);
                      }
                    });
                onBulkEdit(selectedEntries)
            }
        }
    ];
    const RolesItems = [
           
        {
            label: jsUtils.getLiteralValue('UPDATE_SELECTED_ROLES'),
            icon: 'icon-icon_edit',
            command: () => {
                let selectedEntries=[];
                data.forEach((item) => {
                      if (item.isChecked) {
                            selectedEntries.push(item);
                      }
                    });
                onBulkEdit(selectedEntries)
            }
        }
    ];
    
    return (
      <div>
        <div className="dropDownMenu">
          {screen === "users"
            ? enableDropDown && (
                <Menu
                  className="Drop-down-menu"
                  model={!editDisable && UsersItems}
                  popup
                  ref={menu}
                  appendTo={document.body}
                />
              )
            : enableDropDown && (
                <Menu
                  className="Drop-down-menu"
                  model={!editDisable && RolesItems}
                  popup
                  ref={menu}
                  appendTo={document.body}
                />
              )}
          <Button
            className={enableDropDown ? "enableDropDown" : "disableDropDown"}
            icon="pi pi-ellipsis-h"
            onClick={(event) => enableDropDown && menu.current.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
            data-testid="ellipsisButton"
          />
        </div>
      </div>
    );
}
 