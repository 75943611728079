import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { baseUrl } from "../config";
import { ButtonCall } from "../config/literalCodes";
import jsUtils from "../utils/jsUtils";
import { Fetch } from "./fetch";
var pathName = "/core/narrative/api/v1/narrative/shortcuts";
export var shortcut = {
  get: function get(searchParams, headerViewTrackerName) {
    var sort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
    var sort_type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
    return Fetch(pathName + "?" + searchParams, null, "get", "getshortcuts", true, true, null, baseUrl, jsUtils.getHeader(null, headerViewTrackerName, "Autotext")).then(function (data) {
      return data && data.shortcuts && data.shortcuts.map(function (d) {
        return _objectSpread(_objectSpread({}, d), {}, {
          rowId: d.id,
          editMode: 0
        });
      });
    });
  },
  put: function put(id, data) {
    return Fetch(pathName + "/" + id, data, "put", "putshortcuts", false);
  },
  post: function post(data) {
    return Fetch(pathName, data, "post", "postshortcuts", false, true, null, baseUrl, jsUtils.getHeader(ButtonCall.SaveAutoTextButtonHeaderName, null, "Autotext"));
  },
  delete: function _delete(id, data) {
    return Fetch(pathName + "/" + id, data, "delete", "deleteshortcuts", false, true, null, baseUrl, jsUtils.getHeader(ButtonCall.DeleteAutoTextButtonHeaderName, null, "Autotext"));
  }
};