import React, { useEffect, useState } from "react"; 
import "./LandingPage.scss";
import MenuItems from "../common/MenuItems/MenuItems";
import MenuContent from "../common/MenuContent/MenuContent";
import { useDispatch, useSelector } from "react-redux";
import gStore from "../../store/globalStore";
import Spinner from "../common/Spinner/Spinner";
import idx from "idx";
import storage from "../../utils/storage";
import jsUtils from "../../utils/jsUtils";

const getModuleGroup = (data, position) => {
  const _moduleGroup = idx(data, (_) => _.landingPageData.moduleGroups.find(group => group.position === position));
  return _moduleGroup;
};

function LandingPage() {
  const [menu, setMenu] = useState("Homepage");
  const [itemselected, setitemselected] = useState([]);
  const itemdata = useSelector((state) => state.LandingPageReducer.items);
  const PasswordReminder = useSelector((state) => state.LandingPageReducer.expiryReminder );
  const dispatch = useDispatch();
  //const AuthReducer = useSelector((state) => state.AuthReducer);
  //const [codeFromAPI, setCodeFromAPI] = useState([]);

 /** set tooltips from storage on screen load
 literals -variale to store the array of literals
 */
/*
 useEffect(()=>{
    console.log("started loading of landing page");
    const loadLiterals = ()=>{
    console.log("loadLiterals called in landing page");
    let literals  = storage.getObject("literals");
    if(literals)
    {
     setCodeFromAPI(literals);     
     console.log("literals found in localstorage");
    }
    else{
     console.log("literals not found in localstorage");
    }
   
    // commenting this as it is cretaing issue wth redirection logic to SureClear.
    //history.push("/");
  }
  window.addEventListener("storage",()=>{
    console.log("storage changed");
    loadLiterals();
  });
  console.log("storage event listener added");
  loadLiterals()

  return ()=>{
    //on unmount
    console.log("storage event listener removed");
    window.removeEventListener("storage");
  }

 },[]); */


  const onItemSelect = (selecteditem) => {
    itemdata[0].landingPageData.moduleGroups.forEach((itemsGroup) => {
      if (itemsGroup.moduleGroupName === selecteditem) {
        setitemselected(itemsGroup);
        setMenu("menus");
        return itemsGroup;
      }
    });
  };  
  
  useEffect(() => {
    document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_HOME");
    gStore.loggedInUserId = "afdemo1";
  }, [dispatch , storage.getObject("literals")]);

  if (itemdata && itemdata.length !== 0) {

    return (
      PasswordReminder && localStorage.getItem("passwordReminderShow")==="0"  ?
      <></> :
      <div className="af-landing-page">
        <header>
          <div className="af-lp-header"></div>
        </header>

        <div className="lp-container">
          <div className="lp-left ">
            <div className="af-menu-wrapper">
              <Bubble //2-6
                moduleGroup={getModuleGroup(itemdata[0], 6)}
                position={6}
                color="clickable-btn btn-darkblue"
                onItemSelect= {onItemSelect}
              />
              <Bubble //1-4
                moduleGroup={getModuleGroup(itemdata[0], 4)}
                position={4}
                color="clickable-btn btn-lightblue"
                onItemSelect= {onItemSelect}
              />
              <Bubble //0-1
                moduleGroup={getModuleGroup(itemdata[0], 1)}
                position={1}
                color="clickable-btn btn-lighterblue"
                onItemSelect= {onItemSelect}
              />
            </div>
            <div className="af-menu-wrapper">
              <Bubble //3-5
                moduleGroup={getModuleGroup(itemdata[0], 5)}
                position={5}
                color="clickable-btn btn-darkblue"
                onItemSelect= {onItemSelect}
              />
              <Bubble //4-2
                moduleGroup={getModuleGroup(itemdata[0], 2)}
                position={2}
                color="clickable-btn btn-lightblue"
                onItemSelect= {onItemSelect}
              />
            </div>
            <div className="af-menu-wrapper">
              <Bubble //5-3
                moduleGroup={getModuleGroup(itemdata[0], 3)}
                position={3}
                color="clickable-btn btn-darkblue"
                onItemSelect= {onItemSelect}
              />
            </div>
          </div>

          <div className="lp-right">
            {menu === "Homepage" ? (
              <div id="subMenu">
                <h1>
                  {itemdata[0] &&
                    itemdata[0].landingPageData &&
                    jsUtils.getLiteralValue(itemdata[0].landingPageData.SPBrandingHeader)}
                </h1>
                <p>
                  {itemdata[0] &&
                    itemdata[0].landingPageData &&
                    jsUtils.getLiteralValue(
                      itemdata[0].landingPageData.SPBrandingHeaderDescription
                    )}
                </p>
              </div>
            ) : (
              <div id="defaultTxt">
                <MenuContent items={itemselected} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="spinner-popup">
        <Spinner />{" "}
      </div>
    );
  }
}

export const Bubble = ({ moduleGroup, color, onItemSelect }) => {
 
  const moduleLength = moduleGroup && moduleGroup.modules && moduleGroup.modules.length ;
  return (
    <div className={ moduleLength > 0 ? `${color} btnactive` : `${color}`}
      onClick={() => {
        moduleLength > 0 && onItemSelect(moduleGroup && moduleGroup.moduleGroupName );
      }}>
      <MenuItems
        datamenu={moduleLength}
        image={moduleGroup && moduleGroup.moduleGroupIconURL.replace(
          "S3://AttorneyFlow/icons/",
          "/images/landing/"
        )}
        text={moduleGroup && moduleGroup.moduleGroupName}
      />
    </div>
  );
};
export default LandingPage;