import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const ConfirmationDialogBox = ({ message,visible, header, onCancel, onConfirm, cancelButtonLabel,confirmButtonLabel,cancelButtonClassName,confirmButtonClassName,imageClassName,imageSrc,disableCancelButton=false,disableConfirmButton , icon}) => {

  const onCancelClick=(e)=>{
    onCancel();
    e.stopPropagation();
  }
  const onConfirmClick = (e) => {
    onConfirm();
    e.stopPropagation();
  }
  const dialogFooter = (
    <div>
      {!disableCancelButton && <Button
        label={cancelButtonLabel}
        icon={icon?icon:"pi pi-times"}
        onClick={onCancelClick}
        className={cancelButtonClassName}
        data-testid="confirmationCancleButton"
      />}

     {!disableConfirmButton && <Button
        label={confirmButtonLabel}
        icon={icon?icon:"pi pi-check"}
        onClick={onConfirmClick}
        className={confirmButtonClassName}
        data-testid="confirmationConfirmButton"
      />}
    </div>
  );

  return (
    <div className="af-confirmation">
      <div className="af-confirmation-dialog">
        <Dialog
          closable={false}
          header={header}
          visible={visible}
          modal
          footer={dialogFooter}
          onHide={()=>{}}>
          <div className="confirmation-content">
            <img
              title=""
              alt=""
              className={imageClassName}
              src={imageSrc}
            />
            <span>{message}</span>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ConfirmationDialogBox;
