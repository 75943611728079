import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";
import jsUtils from "../../../../../../utils/jsUtils";
import "./RestrictedWordsGrid.scss"
export default function RestrictedWordsMiddleBodyControl({restrictedWord,onRestrictedWordChange,suggestedReplacement,onSuggestedReplacementChange,type,onTypeChange,onStartDateChange,onEndDateChange,startDate,endDate,mode,startDateDisable , minDate}){

    return <div className="af-popup-codetypes rw-middle-section">
                <div className="af-btn-sect">
                    <label htmlFor="icon">{jsUtils.getLiteralValue("RESTRICTED_WORD")} <span className="af-req-star">*</span></label>
                        <InputText
                            tabIndex="2"
                            type="text"
                            disabled={mode==="edit"}
                            value={restrictedWord}
                            placeholder={jsUtils.getLiteralValue("ENTER_UP_TO_10_CHARACTERS")}
                            className="time-draft-input radioButtonInput af-draft-sub"
                            id="time-draft-input-matter"
                            maxLength="50"
                            onChange={(e)=>{onRestrictedWordChange(e.target.value)}}
                            autoComplete="off"/>
                </div>
                    
                <div className="af-btn-sect">
                    <label htmlFor="icon">{jsUtils.getLiteralValue("SUGGESTED_REPLACEMENT")} <span className="af-req-star">*</span></label>
                        <InputText
                            tabIndex="50"
                            type="text"
                            value={suggestedReplacement}
                            placeholder={jsUtils.getLiteralValue("ENTER_UP_TO_10_CHARACTERS")}
                            className="time-draft-input radioButtonInput af-draft-sub"
                            id="time-draft-input-matter"
                            maxLength="50"
                            onChange={(e)=>{onSuggestedReplacementChange(e.target.value)}}
                            autoComplete="off"/>
                </div>

                <div className="af-btn-sect" style={{ paddingTop: '0px',width:"91%"}}>
                    <label htmlFor="icon">{jsUtils.getLiteralValue("TYPE")} <span className="af-req-star">*</span></label>
                        <span className="p-float-label-nested">
                            <Dropdown value={type}
                                options={[{state:"Warning"},{state:"Error"}]} 
                                onChange={(e)=>{onTypeChange(e.value)}}
                                optionLabel="state" 
                                placeholder="" />
                        </span>
                 </div>  

                <div className="af-btn-sect" style={{ paddingTop: '0px',width:"91%",display:"flex"}}>
                    <div>
                        <label htmlFor="icon">{jsUtils.getLiteralValue("START_DATE")} <span className="af-req-star">*</span></label>
                            <span className="p-float-label-nested calendarRw">
                                <Calendar
                                    showIcon={true} 
                                    value={startDate}
                                    disabled={startDateDisable}
                                    onChange={(e)=>{onStartDateChange(e.value)}}
                                    minDate={new Date(minDate)}
                                    onClearButtonClick={(e)=>{onStartDateChange("")}}

                                    maxDate={endDate !==null ?new Date(jsUtils.getDatePlusMinus(endDate,-1)):null}
                                    tooltip={jsUtils.getLiteralValue("SELECT_DATE")}
                                    tooltipOptions={{
                                    className: "white-tooltip",
                                    position: "top",
                                    showDelay: 700,
                                    }}
                                    placeholder={jsUtils.getLiteralValue("MM_DD_YYYY")}
                                    //className="role-start-date"
                                    id="icon1"
                                    appendTo={document.body}
                                    readOnlyInput
                                    //disabled={}
                                    showButtonBar
                                    yearNavigator
                                    yearRange="1970:2100"
                                    />
                            </span>
                    </div>
                    <div>
                        <label htmlFor="icon">{jsUtils.getLiteralValue("END_DATE")}</label>
                            <span className="p-float-label-nested calendarRw">
                                <Calendar
                                    showIcon={true} 
                                    value={endDate}
                                    onChange={(e)=>{onEndDateChange(e.value)}}
                                    minDate={new Date(jsUtils.getMinDate(minDate,startDate,mode))}
                                    disabled={!((mode!=="edit") ||  !endDate || ( (new Date(endDate)).setHours(0,0,0,0) >= (new Date(minDate)).setHours(0,0,0,0)))}
                                     // maxDate={enDate}
                                    tooltip={jsUtils.getLiteralValue("SELECT_DATE")}
                                    tooltipOptions={{
                                        className: "white-tooltip",
                                        position: "top",
                                        showDelay: 700,
                                        }}
                                        placeholder={jsUtils.getLiteralValue("MM_DD_YYYY")}
                                        //className="role-start-date"
                                        id="icon1"
                                        appendTo={document.body}
                                        readOnlyInput
                                        //disabled={}
                                        showButtonBar
                                        yearNavigator
                                        yearRange="1970:2100"
                                    />
                            </span>
                    </div>
                </div>

            </div>
}