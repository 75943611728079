import React from "react";
import jsUtils from "../../../utils/jsUtils";
export default function DialogControlHeader({helpPageUrl,toolTipHelp,breadCrumbs,hideDialog}){
    return <div className="p-dialog-header">
    <a
      href={helpPageUrl}
      target="_blank">
      <img
        title={jsUtils.getLiteralValue("HELP")}
        className="af-help-addtime"
        src="/images/svg/Help_icon.svg"
        alt=""
      />
    </a>
    <div className="p-breadcrumb p-component" aria-label="Breadcrumb">
      <ul>
        <li className="">
          <span className="p-menuitem-link">
            <span className="p-menuitem-text">
              {breadCrumbs[0]}
            </span>
          </span>
        </li>

        <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>

        <li className="">
          <span className="p-menuitem-link">
            <span className="p-menuitem-text">
            {breadCrumbs[1]}
            </span>
          </span>
        </li>
      </ul>
    </div>
    <span className="p-dialog-title"></span>
    <div className="p-dialog-header-icons">
      <button
        onClick={() => {
          hideDialog()       
         }}
        type="button"
        className="p-dialog-header-icon p-dialog-header-close p-link"
        aria-label="Close"
      //   title={tooltips[TOOLTIPS_KEYS.TOOLTIP_CLOSE]}
        >
        <span className="p-dialog-header-close-icon pi pi-times"></span>
      </button>
    </div>
  </div>
}


