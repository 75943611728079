import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../../common/TimeManagenmentClientMatterTable/TimeManagenmentClientMatterTable.scss";
import React, { useState, useEffect } from "react";
import { RolesColDef, buildQueryString } from "./RolesColDef";
import EditRoles from "./EditRoles";
import RolesCard from "./RolesCard";
import { userApi } from "../../../../services/userapis";
import moment from "moment";
import utils from "../../../../utils/jsUtils";
import permissionsState from "../../../../store/ClientMatterRolesPermissions.ts";
import { useSubscribe } from "../../../../store/ActionStore";
import { useSelector } from "react-redux";
import { TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../../../../config/vars";
import storage from "../../../../utils/storage";
import { useDispatch } from "react-redux";
import { refreshStoreOnError } from "../../../../actions/AppAction";
import { NO_OF_ROWS_IN_GRID } from "../../../../config/messages";
import CustomTable from "../../../common/DataTable/CustomTable";
import jsUtils from "../../../../utils/jsUtils";
import _ from "lodash";
import { HeaderView } from "../../../../config/literalCodes";

export default function RolesTable(props) {
  const dispatch = useDispatch();
  let permissions;
  useSubscribe([permissionsState]);
  const [addNewPermission, setAddNewPermission] = useState(false);
  useEffect(() => {
    permissions = permissionsState.rolesPermissions;
    console.log("permissions in time role table", permissions)
    if (permissions !== undefined && permissions[0] !== undefined) {
      permissions && !permissions[0].actionable &&
        (RolesColDef[RolesColDef.length - 1].column.onClick = () => { }) && (RolesColDef[RolesColDef.length - 1].column.className = 'p-button-success p-mr-2 time-button timer-disable-hide')
      permissions && !permissions[0].actionable &&
        (RolesColDef[RolesColDef.length - 1].column.disabled = true);

      if (!permissions[0].actionable) {
        setAddNewPermission(true);
      }
    }
  }, []);

  RolesColDef[RolesColDef.length - 1].column.onClick = RolesColDef[
    RolesColDef.length - 1
  ].data.click = () => {
    setEditRolesPopup(true);
    setPopupNav("Add Role");
    setAction("Add");
  };
  RolesColDef[RolesColDef.length - 1].filter.onClick = () => {
      resetFilters();
      loadData(true);
    setIsResetButtonActive(true);
  };

  const [colDef, setColDef] = useState(RolesColDef);
  const [loadingCard, setLoadingCard] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({ index: -1, isAsc: true, propName: "" });
  const [EditRolesPopup, setEditRolesPopup] = useState(false);
  const [popupNav, setPopupNav] = useState("");
  const [action, setAction] = useState("");
  const [ ,setCodeFromAPI] = useState([]);
  const[moveScrollToTop , setMoveScrollToTop] = useState(false);
  const [selectedPersonaId, setSelectedPersonaId] = useState();
  const [closeAllCardsRefresh, setCloseAllCardsRefresh] = useState(0)
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_ROLES_ONLY]: [TOOLTIPS_VALUES.SHOW_ACTIVE_ROLES_ONLY],
    [TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_ROLES]: [TOOLTIPS_VALUES.SHOW_ALL_ROLES],
  })
  const [checkAll, setCheckAll] = useState(false);
  const [editPosition,setEditPosition] = useState(0);
  const [rolesEditList,setRolesEditList] = useState([]);
  const [bulkEdit,setBulkEdit] = useState(false)

  const [sortNameField, setSortNameField] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [firstCallView,setFirstCallView]=useState(true);
  const [isResetButtonActive, setIsResetButtonActive] = useState(true);
  const [DBCurrentDate, setDBCurrentDate] = useState("");
  /** set tooltips from storage on screen load
  * literals -variale to store the array of literals
  */

  useEffect(() => {
    let literals = storage.getObject("literals");
    if (literals) {
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_ROLES_ONLY]: literals[TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_ROLES_ONLY],
        [TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_ROLES]: literals[TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_ROLES],
      })

      setCodeFromAPI(literals);
    }

  }, []);

  /**
 *   gets the labels/tooltips/error codes from state codeFromAPI
 * @param {String} key - literals key
 * @returns the value associated for that particular key and if it does not find the key then return the key itself
 */
 

  let setScrollToTop = () => {
    // const scrollableBody = document.getElementsByClassName('p-datatable-scrollable-body');
    // if (scrollableBody && scrollableBody.length > 0) {
    //   scrollableBody[0].scrollTop = 0;
    // }
    setMoveScrollToTop(true);
  }

  let setFilters = (key, value, index) => {
    let state = colDef;
    colDef[index].filter.val = value;
    if (index === colDef.length - 2 && value === true) {
      colDef[index].filter.tooltip = tooltips[TOOLTIPS_KEYS.TOOLTIP_SHOW_ALL_ROLES]
    }
    else {
      colDef[index].filter.tooltip = tooltips[TOOLTIPS_KEYS.TOOLTIP_SHOW_ACTIVE_ROLES_ONLY]
    }
    setColDef([...state]);
  };

  let resetFilters = () => {
    let state = colDef;
    colDef[0].filter.val = "";
    colDef[1].filter.val = "";
    colDef[2].filter.val = "";
    colDef[3].filter.val = "";
    colDef[4].filter.val = true;
    sort.index = -1;
    setSort({ index: -1, isAsc: true, propName: "" });
    setColDef([...state]);
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
    setScrollToTop();
    setIsResetButtonActive(true);
  };

  const onFilterChange = (key, value, index) => {
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    setPageNumber(1);
    // document.getElementsByClassName("p-datatable-scrollable-body").scrollTop = 0;
    setScrollNoMore(false);
    utils.debounce(loadData, 400, true, false, sortNameField, sortByField);
    setFilters(key, value, index);
    setScrollToTop();
    let resetSortParams = {
      index: -1,
      isAsc: true,
      propName: "",
    }
    const defaultValue = ["","","",true];
    let filterStatus = jsUtils.checkIfCustomTableFilterChanged(colDef,defaultValue)
    let sortStatus =  _.isEqual(sort, resetSortParams);
    setIsResetButtonActive(filterStatus && sortStatus);
  };

  /**
    *  - checks and unchecks the header/rowData of ready entries for releasing
    * @param {Boolean} deselectColumn - toggle to select and deselect column
    * @param {object} rowItem - contains the ready item checked for being released
   */
   const bulkSelectToggle = (deselectColumn, rowItem) => {
    let data = DataFromAPI;
    if (deselectColumn ) {
      if(RolesColDef[0].column.val)
      {  
      let state = RolesColDef;
      state[0].column.val = !state[0].column.val;
      setColDef([...state]);
      setCheckAll(false);
      }
      data.forEach((item) => {
        if (item.rowId === rowItem.rowId) {
          item.isChecked = rowItem.isChecked;
        }
      });

    } else {
      if (!RolesColDef[0].column.val) {
        data.forEach((item) =>item.isChecked = true);
        setCheckAll(true);
      } else {
        data.forEach((item) =>item.isChecked = false);
        setCheckAll(false);
      }
    let state = RolesColDef;
    state[0].column.val = !state[0].column.val;
    setColDef([...state]);
    }
    
    setDataFromAPI(JSON.parse(JSON.stringify(data)));
    setDataFromAPIBackup(JSON.parse(JSON.stringify(data)));
  };

  const edit_click = (rowItem) => {
    !bulkEdit && setEditRolesPopup(true);
    setPopupNav("Edit Role");
    setAction("Edit");
    setSelectedPersonaId(rowItem.persona_id);
  };

  const resetSelectToggle=()=>{
    let state = RolesColDef;
      state[0].column.val = false;
      setColDef([...state]);
      setCheckAll(false);
  }

  const onBulkEdit=(data)=>{
    setEditRolesPopup(true)
    edit_click(data[0])
    setBulkEdit(true)
    setRolesEditList(data)
    resetSelectToggle()
  }

  const onTraverseNext=(rowdata)=>{
    edit_click(rowdata);
  }

  const clone_click = (rowItem) => {
    setEditRolesPopup(true);
    setPopupNav("Clone Role");
    setAction("Clone");
    setSelectedPersonaId(rowItem.persona_id);

  };

  const [DataFromAPI, setDataFromAPI] = useState([]);
  const [DataFromAPIBackup, setDataFromAPIBackup] = useState([]);
  const [noItemsFoundMessage, setNoItemsFoundMessage] = useState(false);
  const [noItemsFoundMessageText, setNoItemsFoundMessageText] = useState([]);
  const [scrollNoMore, setScrollNoMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const meUserData = useSelector((state) => state.PreferenceReducer.meUserData);
  useEffect(() => {
    const currentDate = meUserData.user_timezone && jsUtils.getCurrentServerDate(meUserData.user_timezone);
    const currentDateFormat = currentDate && moment(`${currentDate.slice(0, 10)}`).format("MM/DD/YYYY");
    setDBCurrentDate(currentDateFormat);
    return ()=>{
      resetFilters();
      resetSelectToggle();
    }
  }, []);

  useEffect(() => {
    loadData(true, true);
  }, []);

  /** Loads list of roles based on the page number
  * @param {Boolean} reset - if true it will load first page
  * @param {Boolean} resFilter - reset filter if true after data loading
  */
  const loadData = (reset = true, resFilter = false, sortName="", sortby="") => {
    setLoading(true);
    setDataLoaded(true);
    setCloseAllCardsRefresh(closeAllCardsRefresh + 1)
    if (resFilter) {
      resetFilters()
    }

    let page = 1;
    if (reset) {
      setPageNumber(1);
    } else {
      // page = pageNumber + 1;
      // setPageNumber(pageNumber + 1);
    }
    let searchParams = buildQueryString(page, sortName, sortby );
    userApi
      .roles(searchParams, firstCallView ? HeaderView.RolesViewCallHeaderName : null, reset ? 1 : pageNumber )
      .then((res) => {
        let formattedJson = { data: [] };
        setDataLoaded(true);
        if (res && res.personaData) {
          res.personaData.forEach((persona) => {
            let effective_start_date =
              persona.effective_start_date ? moment(`${persona.effective_start_date.slice(0, 10)}`).format("MM/DD/YYYY") :
                moment(
                  persona.effective_start_date,
                  "YYYY-MM-DD"
                ).format("MM/DD/YYYY");
            let effective_end_date =
              persona.effective_end_date ? moment(`${persona.effective_end_date.slice(0, 10)}`).format("MM/DD/YYYY") :
                moment(
                  persona.effective_end_date,
                  "YYYY-MM-DD"
                ).format("MM/DD/YYYY");
            let fromtDateStatus =
              persona.effective_start_date ? moment(moment(`${persona.effective_start_date.slice(0, 10)}`).format("MM/DD/YYYY"),
                "MM/DD/YYYY",
                true).isValid() :
                moment(
                  moment(persona.effective_start_date, "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  ),
                  "MM/DD/YYYY",
                  true
                ).isValid();
            let toDateStatus =
              persona.effective_end_date ? moment(moment(`${persona.effective_end_date.slice(0, 10)}`).format("MM/DD/YYYY"),
                "MM/DD/YYYY",
                true).isValid() :
                moment(
                  moment(persona.effective_end_date, "YYYY-MM-DD").format(
                    "MM/DD/YYYY"
                  ),
                  "MM/DD/YYYY",
                  true
                ).isValid();
            let clientItem = {
              rowId: persona.sys_persona_id,
              persona_id: persona.sys_persona_id,
              effective_start_date: fromtDateStatus ? effective_start_date : "",
              effective_end_date: toDateStatus ? effective_end_date : "",
              name: persona.name,
              enabled: persona.enabled,
            };
            checkAll
                  ? (clientItem["isChecked"] = true)
                  : (clientItem["isChecked"] = false);
            formattedJson.data.push(clientItem);
          });
          if (reset) {
            setDataFromAPI(JSON.parse(JSON.stringify(formattedJson.data)));
            setDataFromAPIBackup(
              JSON.parse(JSON.stringify(formattedJson.data))
            );

            if (formattedJson.data.length === 0) {
              setNoItemsFoundMessage(true);
              setNoItemsFoundMessageText({
                titleText: "Roles Not Found!",
                buttonText: "Add Role",
              });
            }
            else {
              page = 1;
              if (formattedJson.data.length < (page * NO_OF_ROWS_IN_GRID)) {
                setScrollNoMore(true);
                //setPageNumber(pageNumber)
              } else {
                setPageNumber(page + 1);
                setScrollNoMore(false);
                // setReset(false);
              }

            }
            // setScrollNoMore(false);
          } else {
            setDataFromAPI(
              JSON.parse(
                JSON.stringify([...DataFromAPIBackup, ...formattedJson.data])
              )
            );
            setDataFromAPIBackup(
              JSON.parse(
                JSON.stringify([...DataFromAPIBackup, ...formattedJson.data])
              )
            );
            page = pageNumber;
            if ((formattedJson.data.length + DataFromAPIBackup.length) < (pageNumber * NO_OF_ROWS_IN_GRID)) {
              setScrollNoMore(true)
            } else {
              setScrollNoMore(false);
              setPageNumber(page + 1);
            }
            // formattedJson.data.length === 0 && setScrollNoMore(true);
            // sortData([...DataFromAPIBackup, ...formattedJson.data], sort);
          }
        }
      })
      .catch((error) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        setDataFromAPI([]);
        setDataFromAPIBackup([]);
      })
      .finally(() => {
        setLoading(false);
        setDataLoaded(true);
      });
      setFirstCallView(false);
  };

  /** Sort list of roles based on the sorting paramters
   * @param {Object} localSort - Sorting parameters
   * @param {Array} data - Roles data for sorting
   */
   const sortData = (localSort) => {
    if (!localSort) localSort = sort;
    if (localSort.propName !== "" && localSort.index !== -1) {
      let sortBy = localSort.isAsc? "asc":"desc";
      
      let sortName = "";
        switch(localSort.propName) {        
          case 'name':
            sortName = "name"
            break;
          case 'effective_start_date':
            sortName = "effective_start_date"
            break;
          case 'effective_end_date':
            sortName = "effective_end_date"
            break;
          case 'enabled':
            sortName = "enabled"
            break;
        }

      setSortByField(sortBy);
      setSortNameField(sortName);

      loadData(true, false, sortName,sortBy)
    }
  };

  const onLoadingcard = (loadCard) => {
    setLoadingCard(loadCard)
  }

  return (
    <div>
      {EditRolesPopup && (
        <EditRoles
          show={EditRolesPopup}
          closed={() => {
            document.title = jsUtils.getLiteralValue("ATTORNEYFLOW_ROLES")
            setEditRolesPopup(false);
            loadData(true, true);
            resetFilters();
            setSelectedPersonaId(null);
            document.getElementById("tbody").scrollTop = 0;
            setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
            setBulkEdit(false);
            setEditPosition(0);
            setRolesEditList([]);
          }}
          header={popupNav}
          action={action}
          personaId={selectedPersonaId}
          pageTitle={"ATTORNEYFLOW_ROLE_ACCESS"}
          bulkEdit={bulkEdit}
          rolesList={rolesEditList}
          onTraverseNext={onTraverseNext}
          setEditPosition={setEditPosition}
          editPosition={editPosition}
        />
      )}

      <div className="datatable-filter-Roles">
        <div className="card af-roles-grid">
          <CustomTable
            rows={NO_OF_ROWS_IN_GRID}
            colDef={colDef}
            parentMethods={{
              edit_click,
              clone_click,
              setEditRolesPopup,
              setPopupNav,
              setAction,
              bulkSelectToggle,
              onBulkEdit,
              DBCurrentDate
            }}
            data={DataFromAPI}
            dataLoaded={dataLoaded}
            isLoading={loading}
            onFilterChange={onFilterChange}
            noItemsFoundMessage={noItemsFoundMessage}
            noItemsFoundMessageText={noItemsFoundMessageText}
            loadingCard={loadingCard}
            closeAllRefresh={closeAllCardsRefresh}
            onLazyScroll={() => {
              if (loading === false && !scrollNoMore) {
                loadData(false, false, sortNameField, sortByField);
              }
            }}
            rowExpansionElement={(rowItem) => {
              return (
                <div>
                  <RolesCard id={rowItem.rowId} onLoadingCard={onLoadingcard} />
                </div>
              );
            }}
            onSort={(index, isAsc, propName) => {
              setSort({ index, isAsc, propName });
              sortData({ index, isAsc, propName });
              setCloseAllCardsRefresh(closeAllCardsRefresh + 1);
              setIsResetButtonActive(false);
            }}
            sort={sort}
            addNewButton={addNewPermission}
            pageNumber={pageNumber}
            scrollNoMore={scrollNoMore}
            moveScrollToTop={moveScrollToTop}
            resetScrollFlag={()=>setMoveScrollToTop(false)}
            resetButtonActive={isResetButtonActive}
          />
        </div>
      </div>
    </div>
  );
}
