import React from 'react';

const ButtonComponent = ({ label, className, onButtonClick, disabled, downArrow, visible ,overrideClassName,}) => {
 
  if(!visible) {
  return <></>;
  }
  return (
    <div className={className} >
     
      <button
        data-testid="searchButton"
        className={overrideClassName ?className :`p-button p-component ${className? className+'-class' : `popup-btn-default`}`}
        onClick={() => onButtonClick(label)} disabled={disabled}>
           { downArrow && <i class="pi pi-angle-down" onClick={() => onButtonClick(label)}></i>}
        <span className="p-button-label p-c">{label}</span>
      </button>
    </div>
  );
};

export default ButtonComponent;