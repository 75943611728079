import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { SET_AUTOTEXT_DETAILS, SET_AUTOTEXT_ACTION, SET_AUTOTEXT_DETAILS_SHORTCUT, SET_AUTOTEXT_VALUE } from "../actions/ActionType";
import initialState from "../store/InitialState";
var AutotextReducer = function AutotextReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.AutotextReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case SET_AUTOTEXT_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        autotextDetails: action.data
      });
    case SET_AUTOTEXT_ACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        autotextAction: action.data.autotextAction
      });
    case SET_AUTOTEXT_DETAILS_SHORTCUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        autotextDetailsShortcut: action.data
      });
    case SET_AUTOTEXT_VALUE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.fieldId, action.data));
    default:
      return state;
  }
};
export default AutotextReducer;