import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
import { FETCH_ITEMS_REQUEST, FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE, SET_URL_ACCESS_MAP, SET_DELEGATES_ON_LOGIN, ALLOW_MOUNTING, SET_BREADCRUMB_TABITEMS_DATA, SET_PAGE_HELP_LINK } from "../../actions/ActionType";
import initialState from "../../store/InitialState";
var LandingPageReducer = function LandingPageReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.LandingPageReducer;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case FETCH_ITEMS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case FETCH_ITEMS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        items: action.data,
        error: "",
        PreferenceMenuObj: getPreferenceObjFromMenuList(action.data)
      });
    case FETCH_ITEMS_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        items: [],
        error: action.data
      });
    case SET_URL_ACCESS_MAP:
      return _objectSpread(_objectSpread({}, state), {}, {
        urlmap: action.data
      });
    case SET_DELEGATES_ON_LOGIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        delegates: action.data
      });
    case ALLOW_MOUNTING:
      return _objectSpread(_objectSpread({}, state), {}, {
        allowMounting: action.data
      });
    case SET_BREADCRUMB_TABITEMS_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        breadcrumbData: action.bCrumbData,
        tabItems: getTabItems(action.bCrumbData, state.items)
      });
    case SET_PAGE_HELP_LINK:
      return _objectSpread(_objectSpread({}, state), {}, {
        helpLink: action.data
      });
    default:
      return state;
  }
};
export function getPreferenceObjFromMenuList(dataList) {
  var _dataList$, _dataList$$landingPag, _dataList$$landingPag2, _dataList$$landingPag3, _dataList$$landingPag4, _dataList$$landingPag5;
  var PrefObjectList = [];
  var getMenuList = dataList && ((_dataList$ = dataList[0]) === null || _dataList$ === void 0 ? void 0 : (_dataList$$landingPag = _dataList$.landingPageData) === null || _dataList$$landingPag === void 0 ? void 0 : (_dataList$$landingPag2 = _dataList$$landingPag.moduleGroups) === null || _dataList$$landingPag2 === void 0 ? void 0 : (_dataList$$landingPag3 = _dataList$$landingPag2.find(function (x) {
    return x.moduleGroupName === "MG-Account";
  })) === null || _dataList$$landingPag3 === void 0 ? void 0 : (_dataList$$landingPag4 = _dataList$$landingPag3.modules) === null || _dataList$$landingPag4 === void 0 ? void 0 : (_dataList$$landingPag5 = _dataList$$landingPag4.find(function (y) {
    return y.moduleName === "MG-Account-MD-Preferences";
  })) === null || _dataList$$landingPag5 === void 0 ? void 0 : _dataList$$landingPag5.menus);
  if (getMenuList) {
    getMenuList.forEach(function (menuRecord) {
      PrefObjectList.push({
        id: menuRecord.menuId,
        path: menuRecord.url,
        label: menuRecord.menuName
      });
    });
  } else {
    PrefObjectList = [];
  }
  return PrefObjectList;
}
export function getTabItems(data, items) {
  var _items$, _items$$landingPageDa, _items$$landingPageDa2;
  var finalMenuList = [];
  var getMenuItems = items && ((_items$ = items[0]) === null || _items$ === void 0 ? void 0 : (_items$$landingPageDa = _items$.landingPageData.moduleGroups.find(function (x) {
    return x.moduleGroupName === data[0];
  })) === null || _items$$landingPageDa === void 0 ? void 0 : (_items$$landingPageDa2 = _items$$landingPageDa.modules.find(function (y) {
    return y.moduleName === data[1] && data[1] !== "MG-Account-MD-Autotext" && data[1] !== "MG-Account-MD-Delegates";
  })) === null || _items$$landingPageDa2 === void 0 ? void 0 : _items$$landingPageDa2.menus);
  if (getMenuItems) {
    getMenuItems.forEach(function (menuRecord) {
      finalMenuList.push({
        id: menuRecord.menuId,
        path: menuRecord.url,
        label: menuRecord.menuName
      });
    });
  } else {
    finalMenuList = [];
  } // finalMenuList.length<2 && finalMenuList.splice(0,finalMenuList.length)
  return finalMenuList;
}
export default LandingPageReducer;