import { SET_LOOKUP_LIST, RESET_MANAGETIMESTORE, SET_DATA_PAGE_UNMOUNT, SET_LOADING_FLAG } from "./ActionType";
export var setLookupList = function setLookupList(payload, listKey, pageOffsetKey) {
  return {
    type: SET_LOOKUP_LIST,
    data: payload,
    listKey: listKey,
    pageOffsetKey: pageOffsetKey,
    pageOffset: payload.nextPageOffset
  };
};
export var setLoadingFlag = function setLoadingFlag(payload) {
  return {
    type: SET_LOADING_FLAG,
    loading: payload
  };
};
export var resetMangeTimeStore = function resetMangeTimeStore() {
  return {
    type: RESET_MANAGETIMESTORE
  };
};
export var setDataOnManageTimePageUnMount = function setDataOnManageTimePageUnMount(payload) {
  return {
    type: SET_DATA_PAGE_UNMOUNT,
    data: payload
  };
};