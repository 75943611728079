import React from "react";
const Popup = ({
  selectedWord,
  showPopup,
  replaceTo,
  onChangeReplaceTo,
  onButtonClick,
  onClose,
  style,
  PlugInNarrative,
  closeIcon
}) => {

  
  return (
    <>
      {showPopup && (
        <div id="popup" style={style}>
           
          <div className="p-grid">
          <div className="col-sm-6">
          <div className={`popup-head type-${selectedWord && selectedWord.type}`}>{selectedWord && selectedWord.type.toUpperCase()}</div>
          </div>
           <div className="col-sm-6 p-col-align-right">
            <span className="cross">
              <button
                type="button"
                onClick={onClose}
                className="p-link"
                aria-label="Close">
               {PlugInNarrative?<span>
                <img
                  title=""
                  alt="close"
                  className="af-close"
                  src={closeIcon}
            />
               </span>:<span className="pi pi-times"></span>} 
              </button>
            </span>
            </div>
            
            <div className="col-sm-3" style={{ marginTop: '5px'}}> Change </div>
            <div
              className="col-sm-9"
              style={{ width: '100%', color: "#2da0eb", marginTop: '5px' , wordBreak:"break-word" }}>
              {selectedWord && selectedWord.restrictedWord}
            </div>
            <div className="col-sm-3" style={{ paddingLeft: "48px" }}> To </div>
            <div className="col-sm-9">
              <input
                value={replaceTo}
                id="replaceTo"
                type="text"
                onChange={(e) => onChangeReplaceTo(e.target.value)}
                autocomplete="off"
              />
            </div>
           <div className="p-sm-6 p-col-align-left p-col-fixed" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Button label="Replace All" onButtonClick={onButtonClick} />
            </div>
            {selectedWord && selectedWord.type==="warning" && <div className="p-sm-6 p-col-align-right p-col-fixed" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Button label="Ignore All" onButtonClick={onButtonClick} disabled={selectedWord && selectedWord.type === 'error'} />
            </div>}
          </div>
        </div>
      )}
    </>
  );
};

const Button = ({ label, onButtonClick, disabled }) => {
  return (
    <div className="popup-btn-panel">
      <button
        className="p-button p-component popup-btn-default"
        style={{ display: 'flex', alignItems: 'center', padding: '0 16px'}}
        onClick={() => onButtonClick(label)} disabled={disabled}>
        <span className="p-button-label p-c">{label}</span>
      </button>
    </div>
  );
};

export default Popup;
