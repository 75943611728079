import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MonthViewCalendar from "./MonthViewCalendar";
import MonthViewTotals from "./MonthViewTotals";
import jsUtils from "../../utils/jsUtils";
import './fullCalendar.scss';

import './MonthView.scss';
import { monthViewApi } from "../../services/monthViewApi";
import moment from "moment";
import Spinner from "../common/Spinner/Spinner";

const MonthView = () => {
    const [date, setDate] = useState(new Date());
    const [gridDate, setGridDate] = useState(null);
    const [tableData, settableData] = useState([]);
    const [timeData, setTimeData] = useState(null);

    const [monthlyTime, setMonthlyTime] = useState([]);

    const delegateId = useSelector((state) => state.TimeManagementReducer.delegateId);
    const [isLoading, setIsLoading] = useState(true);
    const [isGraphLoading, setIsGraphLoading] = useState(true);
    const [finalAmountData, setFinalAmountData] = useState([]);
    const [finalHoursData, setFinalHoursData] = useState([]);
    const [finalGraphAmountData, setfinalGraphAmountData] = useState([]);
    const [finalGraphHoursData, setfinalGraphHoursData] = useState([]);

    const [amountTabStatus, setAmountTabStatus] = useState(true);
    const [isSmallGrid, setisSmallGrid] = useState(0);

    function handleChange(newValue) {
        setDate(newValue);
    }

    function handleDateChange(newValue, args) {
        setGridDate(newValue);
        setTimeData(args);
    }

    useEffect(() => {
        if (delegateId !== "" && delegateId !== null && timeData) {
            const UTCOffset = moment(timeData.start).utcOffset();
            let fromDate = UTCOffset >= 0 || UTCOffset === -0 ?
                moment(new Date(timeData.start)).format("YYYY-MM-DD 00:00:00") :
                moment(new Date(timeData.start)).add(1, 'd').format("YYYY-MM-DD 00:00:00");
            let toDate = UTCOffset >= 0 || UTCOffset === -0 ?
                moment(new Date(timeData.end)).subtract(1, 'd').format("YYYY-MM-DD 00:00:00") :
                moment(new Date(timeData.end)).format("YYYY-MM-DD 00:00:00");
            let payload = {
                fromDate: fromDate,
                toDate: toDate,
                attorneyId: delegateId
            };
            setisSmallGrid(6);
            setIsLoading(true);
            setIsGraphLoading(true);

            monthViewApi.monthView(payload).then((res) => {
                setMonthlyTime(res.data);
                calculateTotals(res.data, fromDate, toDate);
                res.settings.show_billable_amount_on_month_view !== "1" && setAmountTabStatus(false);
            }).catch(() => {
                setIsLoading(false);
                setIsGraphLoading(false);
            })
            const simpleFromDate = moment(fromDate).format("YYYY-MM-DD");
            getHoursAmount(moment(new Date(simpleFromDate)).add(7, 'days').format("YYYY-MM"));
            getGraphData(moment(new Date(simpleFromDate)).add(7, 'days').format("YYYY-MM"));
        }
    }, [delegateId, timeData]);

    const getHoursAmount = (month_year) => {
        //let month_year = moment(new Date(timeData.start)).format("YYYY-MM");
        let payload = {
            month_year: month_year,
            delegateUserId: delegateId
        };
        setIsLoading(true);
        monthViewApi.budget(payload).then((res) => {
            dataFormat(res.message);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        })
    }
    const getGraphData = (month_year) => {
        //let month_year = moment(new Date(timeData.start)).format("YYYY-MM");
        let payload = {
            month_year: month_year,
            delegateUserId: delegateId
        };
        setIsGraphLoading(true);
        monthViewApi.graph(payload).then((res) => {
            formatGraphData(res.message);
            setIsGraphLoading(false);
        }).catch(() => {
            setIsGraphLoading(false);
        })
    }
    const formatGraphData = (data) => {
        //let MonthValues = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        let graphHoursData = {
            "non_billable": { label: jsUtils.getLiteralValue("Non-billable") + " (hrs)", color: "#0f9ff1", data: [] },
            "billable": { label: jsUtils.getLiteralValue("Billable") + " (hrs)", color: "#0050c3", data: [] }};
        let graphAmountData = {
            "non_billable": { label: jsUtils.getLiteralValue("Non-billable") + " ($)", color: "#0f9ff1", data: [] },
            "billable": { label: jsUtils.getLiteralValue("Billable") + " ($)", color: "#0050c3", data: [] }
        };
        let amounts = data?.amount; 
        if(amounts) {
        amounts.forEach(amt => {
           graphAmountData["non_billable"].data.push(amt.non_billable);
           graphAmountData["billable"].data.push(amt.billable);
        });
        }
        let hours =data?.hours;
        if(hours) {
        hours.forEach(amt => {
            graphHoursData["non_billable"].data.push(amt.non_billable);
            graphHoursData["billable"].data.push(amt.billable);
        });
        }
        setfinalGraphAmountData([graphAmountData.billable, graphAmountData.non_billable]);
        setfinalGraphHoursData([graphHoursData.billable, graphHoursData.non_billable]);
    }

    let amountData = [];
    let hoursData = [];
    const dataFormat = (data) => {
        let types = ["Budget", "Billable", "Non-billable", "Unreleased", "Over(-)/Under(+)"];
        types.forEach((type) => {
            hoursData.push({
                name: type,
                month: data.Month.Hours[type] || "",
                ytd: data.YTD.Hours[type]
            });
            amountData.push({
                name: type,
                month: data.Month.Amount[type] || "",
                ytd: data.YTD.Amount[type]
            });
        });
        setFinalAmountData(amountData);
        setFinalHoursData(hoursData);
    }

    const calculateTotals = (data, fromDate, toDate) => {
        // const startWeeknumber = moment(fromDate).week();
        // const lastWeeknumber = moment(toDate).week();
        const currentMonth = moment(gridDate).month();
        const finalData = [];
        let dateStart = fromDate;
        let weekCount = 0;
        while (moment(dateStart).isBefore(toDate)) {
            const weekNumber = moment(dateStart).week();
            finalData.push({
                weekNumber: moment(dateStart).week(),
                weekDisplayLable: moment(dateStart).day("Sunday").week(weekNumber).format("MM/DD").toString() + " - " + moment(dateStart).day("Saturday").week(weekNumber).format("MM/DD").toString(),
                weeklyTotal: 0,
                weekMonthTotal: 0
            })
            weekCount = weekCount + 1;
            dateStart = moment(dateStart).add(7, 'days');
        }
        data.forEach((entry) => {
            const getWeekNumber = moment(entry.date).week();
            const monthNumber = moment(entry.date).month();

            const findIndex = finalData && finalData.findIndex(x => x.weekNumber === getWeekNumber);
            if (findIndex !== -1) {
                finalData[findIndex].weeklyTotal = finalData[findIndex].weeklyTotal + entry.billableTime;
                finalData[findIndex].weekMonthTotal = finalData[findIndex].weekMonthTotal + (monthNumber === currentMonth ? entry.billableTime : 0);
            }
            // else {
            //     finalData.unshift({
            //         weekNumber: getWeekNumber,
            //         weekDisplayLable: moment(date).day("Sunday").week(getWeekNumber).format("MM/DD").toString() + " - " + moment(date).day("Saturday").week(getWeekNumber).format("MM/DD").toString(),
            //         weeklyTotal: entry.billableTime,
            //         weekMonthTotal: (monthNumber === currentMonth ? entry.billableTime : 0)
            //     })
            // }
        })
        settableData(finalData);
        setisSmallGrid(weekCount);
    }

    return (
        <div className="af-month-parent">
            <div className=" p-grid">
                <div className=" p-col-12   p-md-12 af-size-9 af-calendar-row  af-mv-calendar">
                    {isLoading && isGraphLoading && <div className="month-spinner"><Spinner /></div>}
                    <div className="af-mv-middle">
                    { <MonthViewCalendar
                            calendarDate={date}
                            calendarGridDate={gridDate}
                            onDateChange={handleDateChange}
                            monthlyTime={monthlyTime}
                            isLoading={isLoading}
                            isSmallGrid={isSmallGrid}
                        />}
                        <div className='af-mv-legend'>
                            <div className="divTableLabel af-mv-legend-element af-mv-unreleased">{jsUtils.getLiteralValue("U_LABEL")}</div>
                            <div className="af-legend-expand  ">{jsUtils.getLiteralValue("UNRELEASED_TIME")}</div>

                            <div className="divTableLabel af-mv-legend-element af-mv-missing">?</div>
                            <div className="af-legend-expand  "> {jsUtils.getLiteralValue("MISSING_TIME")}</div>

                            <div className="divTableLabel af-mv-legend-element af-mv-billable">{jsUtils.getLiteralValue("B_LABEL")}</div>
                            <div className="af-legend-expand "> {jsUtils.getLiteralValue("BILLABLE_HOURS_MONTH")}</div>

                            <div className="divTableLabel af-mv-legend-element af-mv-nonbillable">{jsUtils.getLiteralValue("N_LABEL")}</div>
                            <div className="af-legend-expand  ">{jsUtils.getLiteralValue("NON_BILLABLE_HOURS_MONTH ")}</div>
                            
                            <div className="divTableLabel af-mv-legend-element af-mv-nonworkingday">{jsUtils.getLiteralValue("NWD_LABEL")}</div>
                            <div className="af-legend-expand  ">{jsUtils.getLiteralValue("NON_WORKING_DAY")}</div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12  p-md-12 af-size-3 af-calendar-row af-mv-analytics">
                    <div className="af-calendar-column af-mv-left">
                        <MonthViewTotals
                            value={date}
                            gridValue={gridDate}
                            onCalendarChange={handleChange}
                            tableData={tableData}
                            amountData={finalAmountData}
                            hoursData={finalHoursData}
                            amountTabStatus={amountTabStatus}
                            graphAmountData={finalGraphAmountData}
                            graphHoursData={finalGraphHoursData}
                            isGraphLoading={isGraphLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default MonthView;