import { SET_ERROR_MESSAGE, SET_SIGNING_OUT, RESET_SIGNING_OUT, REFRESH_STORE_ON_ERROR, SET_CURRENT_TAB_TITLE, SET_CHECK_EMAIL, SET_SELECTED_RESTRICTED_WORD, SET_PERMISSION_OF_USER, SET_WOBO_ACTION } from "./ActionType";
export var setErrorMessage = function setErrorMessage(payload) {
  return {
    type: SET_ERROR_MESSAGE,
    data: payload
  };
};
export var setSigningOutStatus = function setSigningOutStatus(payload) {
  return {
    type: SET_SIGNING_OUT
  };
};
export var resetSigningOutStatus = function resetSigningOutStatus(payload) {
  return {
    type: RESET_SIGNING_OUT
  };
};
export var refreshStoreOnError = function refreshStoreOnError(payload) {
  return {
    type: REFRESH_STORE_ON_ERROR,
    data: payload
  };
};
export var setCurrentTabTitle = function setCurrentTabTitle(title) {
  return {
    type: SET_CURRENT_TAB_TITLE,
    data: title
  };
};
export var setCheckEmailAction = function setCheckEmailAction(response) {
  return {
    type: SET_CHECK_EMAIL,
    data: response
  };
};
export var setSelectedRestrictedWord = function setSelectedRestrictedWord(payload) {
  return {
    type: SET_SELECTED_RESTRICTED_WORD,
    data: payload
  };
};
export var permissionsOfUser = function permissionsOfUser(fieldnames) {
  return {
    type: SET_PERMISSION_OF_USER,
    data: fieldnames
  };
};
export var setWoboAction = function setWoboAction(payload) {
  return {
    type: SET_WOBO_ACTION,
    data: payload
  };
};