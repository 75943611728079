import React from "react";
import { Dialog } from "primereact/dialog";
import { memo } from "react";
import "./AutoTextTable.scss";

const RestrictedWordPopup = ({
  selectedWord,
  showPopup,
  replaceTo,
  onChangeReplaceTo,
  onButtonClick,
  onClose  
}) => {

  const renderFooter = () => {
    return (
      <div className="p-grid">
        <div
          className="p-sm-6"
          style={{ width: '50%' }}>
          <ButtonComponent label="Replace All" onButtonClick={onButtonClick} />
        </div>
        {selectedWord && selectedWord.type === "warning" && <div
        className="p-sm-6"
          style={{ width: '50%' }}>
          <ButtonComponent
            label="Ignore All"
            onButtonClick={onButtonClick}
            disabled={selectedWord && selectedWord.type === "error"}
          />
        </div>}
      </div>
    );
  };

  return (
    <div className="af-confirmation-Autotext">
      <div className="af-confirmation-Autotext-dialog">
        <Dialog
          closable={true}
          header={
            <span
              className={`type-${
                selectedWord && selectedWord.type
              }`}>
              {selectedWord &&
                selectedWord.type &&
                selectedWord.type.toUpperCase()}
            </span>
          }
          visible={showPopup}
          modal={false}
          footer={renderFooter()}
          onHide={onClose}
          //style={{ height: "280px", width: "320px" }}
          >
          <div className="confirmation-content">
            <>
              {showPopup && (
                <span id="autotext-popup">
                  <div className="p-grid">
                    <div
                      className="p-col-6"
                      style={{
                        width: "100px",
                        paddingLeft: "30px"
                      }}
                    >
                      Change
                    </div>

                    <div
                      className="p-col-6"
                      style={{
                       color: "#2da0eb",
                        paddingLeft: "1px",
                      }}
                    >
                      {selectedWord && selectedWord.restrictedWord}
                    </div>

                    <div className="p-grid">
                      <div
                        className="p-col-6"
                         style={{ width: "100px", paddingLeft: "73px" }}
                      >
                        To
                      </div>

                      <div
                        className="p-col-6"
                      >
                        <input
                          value={replaceTo}
                          id="replaceTo"
                          type="text"
                          onChange={(e) => onChangeReplaceTo(e.target.value)}
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </span>
              )}
            </>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

const ButtonComponent = ({ label, onButtonClick, disabled }) => {
  return (
    <div className="popup-btn-panel">
      <button
        className="p-button p-component popup-btn-default"
        style={{ display: 'flex', alignItems: 'center', padding: '0 16px'}}
        onClick={() => onButtonClick(label)} disabled={disabled}>
        <span className="p-button-label p-c">{label}</span>
      </button>
    </div>
  );
};

export default memo(RestrictedWordPopup);
