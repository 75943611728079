import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import LandingPageReducer from "../reducers/LandingPageReducer/LandingPageReducer";
import TimeManagementReducer from "../reducers/TimeManagementReducer/TimeManagementReducer";
import AuthReducer from "../reducers/AuthReducer/AuthReducer";
import AppReducer from "../reducers/AppReducer";
import ConfirmationReducer from "../reducers/ConfirmationReducer/ConfirmationReducer";
import PreferenceReducer from "../reducers/PreferenceReducer/PreferenceReducer";
import initialState from "./InitialState"; //import GlobalReducer from "../reducers/GlobalReducer";
import AcrossTabReducer from "../reducers/AcrossTabReducer";
import AutotextReducer from "../reducers/AutotextReducer";
import ManageTimeReducer from "../reducers/ManageTimeReducer";
import DayViewReducer from "../reducers/DayViewReducer";
var persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['AuthReducer', 'AcrossTabReducer']
};
var reducer = combineReducers({
  LandingPageReducer: LandingPageReducer,
  TimeManagementReducer: TimeManagementReducer,
  AuthReducer: AuthReducer,
  AppReducer: AppReducer,
  ConfirmationReducer: ConfirmationReducer,
  PreferenceReducer: PreferenceReducer,
  AcrossTabReducer: AcrossTabReducer,
  AutotextReducer: AutotextReducer,
  ManageTimeReducer: ManageTimeReducer,
  DayViewReducer: DayViewReducer
}); // GlobalReducer = (state, action) => {
//   return reducer(state, action)
// }
var GlobalReducer = function GlobalReducer(state, action) {
  if (action.type === 'RESET_REDUCERS') {
    state = undefined;
    storage.removeItem('persist:root');
  }
  return reducer(state, action);
};
var persistedReducer = persistReducer(persistConfig, GlobalReducer);
var store = createStore(persistedReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
var persistor = persistStore(store);
export { store, persistor };