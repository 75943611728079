import React, {useState, useEffect} from "react";
import "./ManageTime.scss";
import storage from './../../utils/storage';
import {addTime} from "../../services/AddTimeApi";
import { useSelector } from "react-redux";
import Spinner from "../common/Spinner/Spinner";
import jsUtils from "../../utils/jsUtils";
import NarrativeOnCard from "./narrativeonCard";
import UserDetailsOnCard from "./userDetailsonCard";
import { generalTimeApi } from "../../services/GeneralTimeApi";
export default function ManageTimeCard(props) {
 
 /** set tooltips from storage on screen load
  * @param literals -variale to store the array of literals
  */
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [unmappedMandatoryFields,setUnmappedMandatoryFields]=useState([])
  const [isLoading,setIsLoading] = useState(true);
  const delegateId = useSelector(
    (state) => state.TimeManagementReducer.delegateId
  );
  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key
  };

 const [createdDate,setCreatedDate]=useState("");
 const [createdTime,setCreatedTime]=useState("");
 const [createdName,setCreatedName]=useState("");
 const [upOnDate,setUpOnDate]=useState("");
 const [upTime,setUpTime]=useState("");
 const [upName,setUpName]=useState("");

  const setUserDetails=(cardItem)=>{
      let upDate = cardItem.updatedDate ? cardItem.updatedDate.substring(0, 10).split("-") : "";
      let upOnDate = upDate[1] + "/" + upDate[2] + "/" + upDate[0];
      let upTime = cardItem.updatedDate ? cardItem.updatedDate.substring(11, 19) : "";
      let upFirstName,upLastName,upName;
      if(cardItem.updatedBy && cardItem.updatedBy.trim().split(" ").length >1){
      upFirstName = cardItem.updatedBy ? cardItem.updatedBy.substring(0, cardItem.updatedBy.lastIndexOf(" ") + 1) : "";
      upLastName = cardItem.updatedBy ? cardItem.updatedBy.substring(cardItem.updatedBy.lastIndexOf(" ") + 1, cardItem.updatedBy.length) : "";
      upName = (upLastName.charAt(0).toUpperCase()+upLastName.slice(1) +", " + upFirstName)
      }
      else{
          if(cardItem.updatedBy){
          upName = cardItem.updatedBy.toLowerCase()==="powerpoint" ? "Presentation" : cardItem.updatedBy.toLowerCase()==="excel" ? "Spreadsheet": cardItem.updatedBy.toLowerCase()==="outlook" ? "Email" : cardItem.updatedBy.toLowerCase()==="word" ? "Document": cardItem.updatedBy;
          }
      }
      let createDate = cardItem.createdDate ? cardItem.createdDate.substring(0, 10).split("-"):"";
      let createdDate = createDate[1] + "/" + createDate[2] + "/" + createDate[0];
      let createdTime = cardItem.createdDate ? cardItem.createdDate.substring(11, 19) : "";
      let createdFirstName , createdLastName,createdName;
      if(cardItem.createdBy && cardItem.createdBy.trim().split(" ").length >1){
      createdFirstName = cardItem.createdBy ? cardItem.createdBy.substring(0, cardItem.createdBy.lastIndexOf(" ") + 1) : "";
      createdLastName = cardItem.createdBy ? cardItem.createdBy.substring(cardItem.createdBy.lastIndexOf(" ") + 1, cardItem.createdBy.length) : "";
      createdName = ( createdLastName.charAt(0).toUpperCase()+createdLastName.slice(1) +", "+ createdFirstName)
      }
      else{
        if(cardItem.createdBy){
        createdName = cardItem.createdBy.toLowerCase()==="powerpoint" ? "Presentation" : cardItem.createdBy.toLowerCase()==="excel" ? "Spreadsheet": cardItem.createdBy.toLowerCase()==="outlook" ? "Email" : cardItem.createdBy.toLowerCase()==="word" ? "Document": cardItem.createdBy;
        }
      }
      setCreatedDate(createdDate);
      setCreatedTime(createdTime);
      setCreatedName(createdName);
      setUpOnDate(upOnDate);
      setUpTime(upTime);
      setUpName(upName);
  }

  useEffect(()=>{
    let literals  = storage.getObject("literals");
    if(literals)
    {
      setCodeFromAPI(literals);
    }
    setUserDetails(props.Carditem);
  },[props.Carditem]);

  
  // const invokeTypeCodes = async (name, value, matterId) => {
  //   if (name === "PHASE") {
  //    return addTime
  //       .phaseCode(value, matterId, delegateId)
                
  //   } else if (name === "TASK") {
        
  //       return addTime
  //         .taskCode(value, matterId, delegateId)
          
  //     }

  // };

  const getUnmappedMandatoryFields=async(data)=>{
    let mandatorynotmapped=[];
    props.onLoadingCard(true)

    setIsLoading(true)
                await addTime.config(data.clientId,data.matterId,delegateId).then((res)=>{
                  props.onLoadingCard(false)
                  
                  let _taskCodeSelected = data.timeEntryDetail.find((item) => item.fieldName === "TASK_CODE");
                  let _activityCodeSelected = data.timeEntryDetail.find((item) => item.fieldName === "ACTIVITY_CODE");
                  
                  
                      if(res.fields)
                  {
                  // let _phaseCodeField =res.fields.find((codes)=>codes.field === "PHASE_CODE");
                  let _taskCodeField =res.fields.find((item)=>item.field === "TASK_CODE") || {field:"TASK_CODE",displayLabel:jsUtils.getLiteralValue("TASK_CODE")};
                  let _activityCodeField =res.fields.find((item)=>item.field === "ACTIVITY_CODE") || {field:"ACTIVITY_CODE",displayLabel:jsUtils.getLiteralValue("ACTIVITY_CODE")};
                  let phaseBillable=res.phaseBillableType;

                  if(phaseBillable === "P1")
                  {
                    if(!_taskCodeSelected)
                    {
                      mandatorynotmapped.push(_taskCodeField)
                    }
                    if(!_activityCodeSelected)
                    {
                      mandatorynotmapped.push(_activityCodeField)
                    }
                  }
                  else if(phaseBillable === "P")
                  {
                    if(!_taskCodeSelected)
                    {
                      mandatorynotmapped.push(_taskCodeField)
                    }
                  }
                  
                  let mandatoryFields=res.fields.filter((item)=>item.isMandatory===true && item.isDisplay===true)
                    
                    mandatoryFields.forEach((mandatoryItem)=>{
                      let fieldItem=mandatoryItem.field
                      // let fieldValue = getCode(mandatoryItem.field)
                      // let fieldItem = fieldValue.toLowerCase().replace("code","").trim();
                      let searchedField=data.timeEntryDetail.findIndex((item)=>fieldItem===item.fieldName)

                      if(searchedField===-1)
                      {
                        if(!mandatorynotmapped.find((fielditem)=>fielditem.field===mandatoryItem.field))
                        {
                        mandatorynotmapped.push(mandatoryItem)
                        }
                      }

                    })
                    console.log(mandatorynotmapped)
                    props.Carditem.isPredictive !== 1 && setUnmappedMandatoryFields(mandatorynotmapped);
                    setIsLoading(false)
                  }
                
                }).catch((error)=>{
                  props.onLoadingCard(false)
                  setIsLoading(false)
                })
  }

  const onDownloadAttachment = (dms_source) => {
    try {
      setIsLoading(true);
      generalTimeApi.downloadAttachment(dms_source,"costentry").then((res) => {
        if (res.success && res.signed_url) {
          window.downloadAttachment(res.signed_url);
        }
        setIsLoading(false);
      }).catch((err)=>{
        setIsLoading(false);
        console.log('Download attachment failed!', err);  
      });
    }
    catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(()=>{
  if(props.Carditem && props.Carditem.status && props.Carditem.status.toLowerCase() === "invalid" && props.Carditem.is_draft_time!="Y")
  {
    getUnmappedMandatoryFields(props.Carditem);
  }
  else
  {
    setIsLoading(false)
  }
  },[])

  let errors = props?.Carditem?.errors;
  return (
    <div className="MT-card">
      {isLoading && <div className="usercard-spinner">
        <Spinner/>
      </div>}
      
      {!isLoading && <div>
       {errors && errors.map(
          (error) =>{
            if(error.field === "hours" || error.field === "client" || error.field === "matter" || error.field === "date"){
              return <div style={{fontWeight:"600",marginLeft:"0.5rem"}}>
                <img
                  alt=""
                  style={{
                    width: "1.5rem",
                    height: "auto",
                    margin: "0 0.5rem",
                  }}
                  src={`/images/svg/icon_invalid.svg`}>
                  </img>
                {error.field==="hours"  && props.Carditem.billableDuration === 0?storage.getObject("literals") && storage.getObject("literals")["DURATION_MUST_BE_ENTERED"]?storage.getObject("literals")["DURATION_MUST_BE_ENTERED"]:"DURATION_MUST_BE_ENTERED":error.message}
                </div>
            }
          }
        )}
        <table>
          {props.Carditem.amount !== null && props.Carditem.status &&  props.Carditem.status.toLowerCase()!=="invalid" && (
            <tr>
              <th className="MT-card-img">&nbsp;</th>
              <th className="success-field">{getCode("AMOUNT")}</th>
 
              <td>{props.Carditem.amount }</td>
            </tr>
          )}
          {unmappedMandatoryFields.length > 0 && unmappedMandatoryFields.map((item)=>
            
              <tr>
                <th className="MT-card-img">
                  {" "}
                  <img
                    alt=""
                    style={{
                      width: "1.5rem",
                      height: "auto",
                      margin: "0 0.5rem",
                    }}
                    src={`/images/svg/icon_invalid.svg`}
                  ></img>
                </th>
                <th className="error-field"> {item.displayLabel}</th>
                <td>
                  {}
                </td>
              </tr>
            
          )}
          {!isLoading && props.Carditem.timeEntryDetail.length>0 &&
            props.Carditem.timeEntryDetail.map((item) =>
              item.fieldName &&
              errors &&
              errors.find(
                (error) =>
                  error.field.toLowerCase() ===
                    item.fieldName.toString().toLowerCase().trim() ||
                  (error.field.toLowerCase() === "time_usercode2" &&
                    item.fieldName.toLowerCase() === "udf2") ||
                  (error.field.toLowerCase() === "time_usercode3" &&
                    item.fieldName.toLowerCase() === "udf3") ||
                  (error.field.toLowerCase() === "amount"  &&
                    item.fieldName.toLowerCase() === "rate_code") ||
                    (error.field.toLowerCase() === "ratecode"  &&
                      item.fieldName.toLowerCase() === "rate_code") ||
                    (error.field.toLowerCase() === "submatter" &&
                    item.fieldName.toLowerCase() === "task_code") ||
                    (error.field.toLowerCase() === "time_usercode1" &&
                    item.fieldName.toLowerCase() === "activity_code") ||
                    (error.field.toLowerCase() === "servicecode" &&
                    item.fieldName.toLowerCase() === "service_code") ||
                    (error.field.toLowerCase() === "city" &&
                    item.fieldName.toLowerCase() === "city_code") ||
                    (error.field.toLowerCase() === "phasecode" &&
                    item.fieldName.toLowerCase() === "phase_code") ||
                    (error.field.toLowerCase() === "phase" &&
                    item.fieldName.toLowerCase() === "phase_code") ||
                    (error.field.toLowerCase() === "makenocharge") &&
                    item.fieldName.toLowerCase() === "make_no_charge"
                    
              ) ? (
                <tr>
                  <th className="MT-card-img">
                    {" "}
                    <img
                      alt=""
                      style={{
                        width: "1.5rem",
                        height: "auto",
                        margin: "0 0.5rem",
                      }}
                      src={`/images/svg/icon_invalid.svg`}
                    ></img>
                  </th>
                  <th className="error-field"> {item.displayLabel}</th>
                  <td>
                  {(
                      item.values.display_value )?
                      item.values.description +
                        " (" +
                        item.values.display_value +
                        ")":`${storage.getObject('literals')["BLANK_SELECTED"]?'('+storage.getObject('literals')["BLANK_SELECTED"]+')':"(BLANK_SELECTED)"}`}
                  </td>
                </tr>
              ) : (
                <tr>
                  <th className="MT-card-img">&nbsp;</th>
                  <th className="success-field">{item.displayLabel}</th>
 
                  <td>
                  {(
                      item.values.display_value) ?
                      item.values.description +
                        " (" +
                        item.values.display_value +
                        ")":`${storage.getObject('literals')["BLANK_SELECTED"]?'('+storage.getObject('literals')["BLANK_SELECTED"]+')':"(BLANK_SELECTED)"}`}
                  </td>
                </tr>
              )
          )}
        </table>
      </div>}
      {!isLoading && <div>
        <NarrativeOnCard
        cardItem={props.Carditem}
        errors={errors}
        />
        <UserDetailsOnCard
        cardItem={props.Carditem}
        createdDate={createdDate}
        createdTime={createdTime}
        createdName={createdName}
        upOnDate={upOnDate}
        upTime={upTime}
        upName={upName}
        />
      </div>}
    </div>
  );
}