import React from "react";

const ToggleButtonComponent = ({ toggleClick, title }) => {
  return (
    <div className="af-bil-label" >      
        <span
        data-testid = "toggle-id"
          className="toggle-button"
          onClick={toggleClick}
          onKeyPress={toggleClick}
          tabIndex={0}
          title={title}>
          <span className=" pi pi-arrow-left"></span>
          <span className=" pi pi-arrow-right"></span>
        </span>      
    </div>
  );
};

export default ToggleButtonComponent;
